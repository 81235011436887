import styled from 'styled-components';
import authBg from '../../../assets/images/backgrounds/auth-bg.png';

export const Register = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-primary-light);
  background-image: url(${authBg});
  background-position: bottom left;
  background-repeat: no-repeat;
`;

Register.Container = styled.div`
  /* Display & Box Model */
  padding: 3rem 0;
  padding-top: 7rem;

  > div {
    width: 94%;
  }

  @media (min-width: 480px) {
    > div {
      width: 85%;
    }
  }
`;

Register.Heading = styled.div`
  /* Colors */
  color: var(--color-white);
  h1 {
    /* Text */
    font-size: 3rem;
    font-family: 'Estate', 'Manrope', sans-serif;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 2rem;
    margin-top: 1rem;

    span {
      font-size: inherit;
      font-weight: 700;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 300;
    max-width: 500px;
  }

  > button {
    margin-top: 3rem;
    border-color: var(--color-white);
    color: var(--color-white);
    background-color: transparent;

    &:hover {
      border-color: var(--color-white);
      color: var(--color-primary-light);
      background-color: var(--color-white);
    }
  }

  @media (min-width: 960px) {
    h1 {
      margin-top: 6rem;
    }
  }

  @media (min-width: 1150px) {
    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;

Register.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

Register.Form = styled.form`
  background-color: var(--color-white);
  padding: 1.5rem 1.2rem;
  border-radius: 8px;
  max-width: 480px;
  min-width: 400px;

  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  button,
  p {
    width: 100%;
  }

  p {
    text-align: center;
  }

  input.form-error {
    border-color: var(--color-red);
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    span {
      line-height: 1.5;
    }

    a {
      color: var(--color-primary-light);
    }

    input {
      margin: 0;
    }
  }

  .field-error-message {
    font-size: 0.8rem;
    text-align: left;
    color: var(--color-red);
  }

  .link-button {
    margin: 0.5rem auto;
    width: fit-content;
    background-color: transparent;
    padding: 0;
    color: var(--color-primary-light);
    border: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (min-width: 480px) {
    padding: 2.5rem;
  }

  @media (min-width: 620px) {
    margin-top: 4rem;
  }
`;
