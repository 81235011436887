import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import FaqContent from './FaqContent';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqProviderBudgets() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />
      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question1'>
              <p>
                Como envio orçamentos para as propostas?
              </p>
            </a>
            <a href='#question2'>
              <p>
                Como saber que meu orçamento foi aprovado?
              </p>
            </a>
            <a href='#question4'>
              <p>
                Tive um orçamento aprovado, quais são os próximos passos?
              </p>
            </a>
            <a href='#question5'>
              <p>
                Existe algum modelo de orçamento que devo seguir?
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Como envio orçamentos para as propostas?
              </h2>
              <p>
                Após receber uma oportunidade de venda dentro da sua área de atuação, em seu <span>“Painel de Vendas”</span>, siga esses passos:
              </p>
              <ul>
                <li>
                  Acesse a oportunidade em que deseja enviar o orçamento
                </li>
                <li>
                  Leia todas as informações disponíveis

                </li>
                <li>
                  Clique no botão “Enviar um orçamento”
                </li>
                <li>
                  Preencha todos os campos atentamente
                </li>
                <li>
                  Detalhe ao máximo todas as informações referentes ao seu serviço e/ou produtos, como descrição do produto, abrangência de entrega, prazos e todas as informações necessárias.
                </li>
                <li>
                  Adicione fotos de referência
                </li>
                <li>
                  Caso a oportunidade conte com mais de uma categoria, selecione a que se refere ao seu produto e/ou serviço
                </li>
                <li>
                  Para complementar seu orçamento, você pode adicionar arquivos em PDF e/ou links
                </li>
                <li>
                  Para finalizar e enviar o orçamento, basta clicar no botão “Enviar orçamento”
                </li>

              </ul>
              <p>
                <span>Importante:</span> Para enviar orçamentos é necessário ter o perfil completo.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                Como saber que meu orçamento foi aprovado?
              </h2>
              <p>
                Você receberá um e-mail confirmando a venda do seu serviço com todas as informações sobre a venda e os próximos passos. Aparecerá uma mensagem assim: “Confirmação de Venda”.
              </p>
              <p>
                Também é possível acompanhar diretamente na área do fornecedor através do menu “Vendas confirmadas”.
              </p>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question4'>
                Tive um orçamento aprovado, quais são os próximos passos?
              </h2>
              <p>
                Após ter um orçamento aprovado pelo comprador, o próximo passo é enviar a sua Nota Fiscal com o  <span>“Valor do Repasse”</span> e  informe a <span>ID do evento "#"</span> no campo de descrição da nota fiscal.
              </p>
              <p>
                <span>Importante:</span> Após ter um orçamento aprovado, você deve honrar com a entrega do serviço e/ou produto, o não cumprimento será passível de multa.
                Sim, caso você queira voltar a integrar nossa comunidade de fornecedores, é possível realizar um novo cadastro.
              </p>
              <p>
                Verifique o <span>“Termos De Uso e Condições Gerais De Venda Pelos Fornecedores”</span> disponível em nosso site para mais informações.
              </p>
            </div>
          </div>

          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question5'>
                Existe algum modelo de orçamento que devo seguir?
              </h2>
              <p>
                A plataforma permite que os orçamentos sejam enviados diretamente através do preenchimento dos campos disponíveis para cada proposta. Os campos do formulário de orçamento incluem
              </p>
              <ul>
                <li>
                  <strong className='txt--bold'>Nome do item:</strong> aqui, é necessário descrever objetivamente o nome do produto ou serviço que será incluído no orçamento.
                </li>
                <li>
                  <strong className='txt--bold'>Imagens e links de referência:</strong> incluir imagens e fotos dos produtos e serviços é fundamental para que os clientes possam ter uma referência visual do que está sendo oferecido. Além disso, os links de referência podem ser úteis para complementar a descrição do serviço, como, por exemplo, adicionando links de vídeos em um orçamento para serviços de música ao vivo.
                </li>
                <li>
                  <strong className='txt--bold'>Precificação:</strong> Ao precificar seu serviço, é importante definir como será a cobrança. Isso pode ser feito escolhendo entre os seguintes modos: por <span>pacote</span>, por <span>pessoa</span>, por <span>pessoa</span>, por <span>hora</span> ou por <span>unidade</span>.
                  <p>
                    O tipo de serviço ou produto oferecido irá determinar qual modo é mais adequado. Por exemplo, a cobrança por <span>unidade</span> pode ser usada para <span>brindes personalizados</span>, enquanto a cobrança por <span>pessoa</span> é mais adequada para serviços de <stoong>alimentação e bebidas</stoong>. Já a cobrança por <span>hora</span> é comumente utilizada em serviços de <span>fotografia</span> e <span>vídeo</span>. A cobrança por pacote se aplica a orçamentos das categorias de <span>entretenimento</span>, como <span>DJ</span> e <span>Música ao vivo</span>.
                  </p>
                </li>
                <li>
                  <strong className='txt--bold'>Quantidade:</strong> É importante também definir a quantidade de itens que serão considerados em cada orçamento, pois isso pode influenciar no valor final. O valor de venda deve ser informado considerando o modo de cobrança escolhido. Lembre-se de que esse valor já inclui a <span>taxa de serviço</span>. Ao informar o valor, lembre-se de digitar <span>apenas números</span>, sem incluir símbolos monetários ou outras formatações.
                </li>
                <p>
                  <span>Importante:</span> Para ter um orçamento qualificado, siga atentamente as instruções acima.
                </p>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >



  );
}