import React, { useState } from 'react';
import { AngleLeft, AngleRight } from '../../Components/common/Icons/IconsFamily';
import { HomePage } from './styles';

function Comments() {

  const [currentIndex, setCurrentIndex] = useState(0);

  const comments = [
    {
      name: "Pamela Riuto da C&A",
      comment: "O atendimento de todos foi impecável. Desde o atendimento da Celebrar, inclusive o fornecedor que a todo o tempo estava ao meu dispor. O sabor e qualidade dos alimentos estava bom. A apresentação também.",
    },
    {
      name: "Sheila Sousa do Nubank",
      comment: "Vocês conseguiram uma cesta linda em pouquíssimo tempo, chegou toda inteira e bonita e no horário combinado. Arrasaram como sempre. Obrigada pelo carinho e dedicação de vocês.",
    },
    {
      name: "Larissa Cunha da Loggi",
      comment: "Gostei muito do material da meia e do copo, além disso recebemos antes da data prevista =) Obrigada!!! OBS: a Celebrar prestou um excelente atendimento!!!",
    },
    {
      name: "Gisele Arias da Singu",
      comment: "Perfeitos! Obrigada pela ajuda de sempre. A Celebrar muito prestativa e disposta a nos ajudar, mesmo com o evento tão em cima da hora. Obrigada",
    },
    {
      name: "André Alves da HSM",
      comment: "Foi tudo muito cuidado, parceria bastante firmada, atenção aos detalhes e certeza que fizemos o melhor.",
    },
    {
      name: "Isys Portela da Esfera Energia",
      comment: "A Celebrar é uma empresa que topa tudo com excelência, empatia e diversidade. Soluções rápidas e práticas a todo momento com profissionais e fornecedores incríveis e capazes de surpreender.",
    },
    {
      name: "Marcello da Engaged",
      comment: "Muito bom o coffee, a fornecedora ficou mais tempo que o contratado e tudo estava muito bom.",
    },
    {
      name: "Bruna Origuella da São Judas",
      comment: "Atendimento super rápido, e sempre com muito capricho.",
    },
  ]

  return (
    <HomePage.Comments >
      <div className="container">
        <h3>O QUE DIZEM SOBRE NÓS</h3>

        <HomePage.CommentsContent >
          <HomePage.BuyersLogos.Btn
            onClick={() => currentIndex > 0 && setCurrentIndex(currentIndex - 1)}
          >
            <AngleLeft
              color={currentIndex === 0 ? "var(--color-gray)" : "var(--color-primary-light)"}
            />
          </HomePage.BuyersLogos.Btn>

          {
            comments.map((comment, index) => {
              return(
                index === currentIndex && (
                  <HomePage.Comment key={index}>
                    <h6>{comment.name}</h6>
                    <p>{comment.comment}</p>
                  </HomePage.Comment>
                )
              )
            })
          }

          <HomePage.BuyersLogos.Btn
            type="button"
            onClick={() => currentIndex < comments.length -1 && setCurrentIndex(currentIndex + 1)}
          >
            <AngleRight
              color={ currentIndex < comments.length - 1 ? "var(--color-primary-light)" : "var(--color-gray)"}
            />
          </HomePage.BuyersLogos.Btn>
        </HomePage.CommentsContent>
      </div>
    </HomePage.Comments>
  )
}

export default Comments;