import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../../Components/common/Buttons/Button';
import { FormTxtInput } from '../../../Components/common/FormTextInput';
import { FormTxtInputWithStatus } from '../../../Components/InputWithStatus/FormTxtInputWithStatus';
import { useAsync } from '../../../utils';
import { useViaCep } from './hooks/useViaCep';
import { AddressStepStyled } from './styles';
import { Load } from '../../../Components/Loader/styles';
import Alert from '../../../Components/Alert';

export function RegistrationAddressStep({ handleContinueRegistration }) {
  const serverErrorAlertRef = useRef();

  const {
    run: runLoadData,
    data: loadedData,
    isLoading,
    isError,
  } = useAsync({ status: 'pending' });

  useEffect(() => {
    runLoadData(axios.get('/api/supplier/register-address').then(response => response.data)).catch(
      err => console.log(err.message)
    );
  }, [runLoadData]);

  const {
    maskedCEP,
    handleChangeCEP,
    validCepData,
    isCheckingCEP,
    isCheckedCEP,
    valueCEP,
    setValueCEP,
  } = useViaCep();

  useEffect(() => {
    if (loadedData) {
      const { street, state, city, complement, district, number, zip_code } = loadedData;
      setValueCEP(zip_code || '');
      setAddressData({ street, state, city, complement, district, number });
    }
  }, [loadedData, setValueCEP]);

  const [errors, setErrors] = useState({});
  const [addressData, setAddressData] = useState({});

  useEffect(() => {
    if (isCheckedCEP) {
      if (validCepData) {
        setErrors(errors => ({
          ...errors,
          cep: '',
          state: '',
          city: '',
          street: '',
          district: '',
        }));
        setAddressData(addressData => ({
          ...addressData,
          street: validCepData.logradouro,
          state: validCepData.uf,
          city: validCepData.localidade,
          district: validCepData.bairro,
        }));
      } else {
        setAddressData({});
        setErrors(errors => ({ ...errors, cep: 'CEP não encontrado' }));
      }
    }
  }, [isCheckedCEP, loadedData, validCepData]);

  function handleChangeAddressInput({ target }) {
    setAddressData(address => ({ ...address, [target.name]: target.value }));
  }

  const { run: runSubmit, isLoading: isSubmitting, isError: isErrorSubmit } = useAsync();

  function handleSubmit(event) {
    event.preventDefault();

    const errors = {};

    if (!maskedCEP) {
      errors.cep = 'Campo obrigatório';
    }
    if (!validCepData) {
      errors.cep = 'CEP não encontrado';
    }
    if (!addressData.state) {
      errors.state = 'Campo obrigatório';
    }
    if (!addressData.city) {
      errors.city = 'Campo obrigatório';
    }
    if (!addressData.street) {
      errors.street = 'Campo obrigatório';
    }
    if (!addressData.number) {
      errors.number = 'Campo obrigatório';
    }
    if (!addressData.district) {
      errors.district = 'Campo obrigatório';
    }

    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      return;
    }

    runSubmit(
      axios
        .post('/api/supplier/register-address', {
          zip_code: valueCEP,
          address: addressData.street,
          state: addressData.state,
          city: addressData.city,
          neighborhood: addressData.district,
          address_complement: addressData.complement,
          address_number: addressData.number,
        })
        .then(() => handleContinueRegistration())
    ).catch(err => console.log(err.message));
  }

  useEffect(() => {
    if (isError || isErrorSubmit) {
      serverErrorAlertRef.current.open();
    }
  }, [isErrorSubmit, isError]);

  if (isLoading) {
    return (
      <Load.FullPageContainer style={{ height: '60vh' }}>
        <Load.SmallIcon />
      </Load.FullPageContainer>
    );
  }

  return (
    <AddressStepStyled>
      <div>
        <form onSubmit={handleSubmit}>
          <h3>Localização</h3>
          <p>
            Precisamos saber onde sua empresa está localizada. Preencha com o mesmo endereço que
            você cadastrou na Receita Federal.
          </p>
          <div className="inputValues">
            <FormTxtInputWithStatus
              label="CEP da empresa *"
              placeholder="CEP"
              icon="user"
              value={maskedCEP}
              onChange={handleChangeCEP}
              isLoading={isCheckingCEP}
              isSuccess={validCepData}
              errorMessage={errors.cep}
            />
            <FormTxtInput
              name="state"
              label="Estado *"
              type="text"
              placeholder="UF"
              icon="credit-card"
              value={addressData.state || ''}
              disabled
              errorMessage={errors.state}
            />
            <FormTxtInput
              name="city"
              label="Cidade *"
              type="text"
              placeholder="Cidade"
              icon="credit-card"
              value={addressData.city || ''}
              onChange={handleChangeAddressInput}
              errorMessage={errors.city}
              disabled
            />
            <FormTxtInput
              name="district"
              label="Bairro *"
              type="text"
              placeholder="Bairro"
              icon="credit-card"
              value={addressData.district || ''}
              onChange={handleChangeAddressInput}
              errorMessage={errors.district}
              disabled
            />
            <FormTxtInput
              name="street"
              label="Rua *"
              type="text"
              placeholder="Rua"
              icon="credit-card"
              value={addressData.street || ''}
              onChange={handleChangeAddressInput}
              errorMessage={errors.street}
              disabled
            />
            <FormTxtInput
              name="number"
              label="Número *"
              placeholder="Número"
              icon="home"
              value={addressData.number || ''}
              onChange={handleChangeAddressInput}
              errorMessage={errors.number}
            />
            <FormTxtInput
              name="complement"
              label="Complemento"
              type="text"
              placeholder="Complemento"
              icon="credit-card"
              value={addressData.complement || ''}
              onChange={handleChangeAddressInput}
            />
          </div>
          <Button type="submit" disabled={isSubmitting}>
            Continuar
            {isSubmitting ? (
              <FeatherIcon icon="loader" className="spinner" size={16} color="white" />
            ) : (
              <FeatherIcon icon="arrow-right" className="icon" size={16} color="white" />
            )}
          </Button>
        </form>
      </div>
      <Alert status="danger" ref={serverErrorAlertRef}>
        Ocorreu um erro.
      </Alert>
    </AddressStepStyled>
  );
}
