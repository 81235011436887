import styled from 'styled-components';

export const AccountPage = styled.div`

`;

AccountPage.Sidebar = styled.aside `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    box-shadow: 0.5rem 0 1rem rgba( 0, 0, 0, 1);

    div{
        /* Display & Box Model */
        width: 20%;
        min-height: 100vh;

        /* Color */
        background-color: var(--color-white);

        /* Position */
        position: fixed;
    }
`;

AccountPage.Container = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;

    /* Color */
    background-color: var(--color-gray-light);
    
    /* Position */
    position: relative;
`;

AccountPage.BudgetDetails = styled.div `
    /* Display & Box Model */
    width: 100%;

    .budgets__grid{
        /* Display & Box Model */
        width: 100%;
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
        grid-gap: 1rem;
        padding: 1rem 0;

        /* Color */
        color: var(--color-black);
    }

    h2{
        /* Text */
        font-size: 2rem;
        font-weight: 300;
    }

    .budgets__price{
        h6{
            /* Text */
            font-size: 1.6rem;
            font-weight: 300;
        }
    }

    .budgets__price:nth-child(3){
        /* Color */
        color: var(--color-secondary);
    }

    .budgets__price:nth-child(4){
        /* Color */
        color: var(--color-primary);
    }

    .budgets__description{
        /* Display & Box Model */
        margin: 2rem auto;

        /* Text */
        line-height: 1.5;
    }

    .budgets__images{
        /* Display & Box Model */
        width: 100%;
        display: grid;
        grid-template-columns: repeat( 5, 1fr);
        grid-gap: 1rem;

        figure{
            width: 100%;
            height: 8rem;
            background-color: var(--color-gray);
        }
    }
`;

AccountPage.Content = styled.div `
    /* Display & Box Model */
    width: 100%;

    .budgets__grid{
        /* Display & Box Model */
        width: 100%;
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
        grid-gap: 1rem;
        padding: 1rem 0;

        /* Color */
        color: var(--color-black);
    }

    h2{
        /* Text */
        font-size: 2rem;
        font-weight: 300;
    }

    .budgets__price{
        h6{
            /* Text */
            font-size: 1.6rem;
            font-weight: 300;
        }
    }

    .budgets__price:nth-child(3){
        /* Color */
        color: var(--color-secondary);
    }

    .budgets__price:nth-child(4){
        /* Color */
        color: var(--color-primary);
    }

    .budgets__description{
        /* Display & Box Model */
        margin: 2rem auto;

        /* Text */
        line-height: 1.5;
    }

    .budgets__images{
        /* Display & Box Model */
        width: 100%;
        display: grid;
        grid-template-columns: repeat( 5, 1fr);
        grid-gap: 1rem;

        figure{
            width: 100%;
            height: 8rem;
            background-color: var(--color-gray);
        }
    }
`;