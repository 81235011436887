import React from "react";
import { Button } from "../common/Buttons/Button";
import { CategoriesAside } from "./CategoriesAside";
import { CategoriesImages } from "./CategoriesImages";
// Styles & Images
import { CategoriesBodyStyled } from "./Categories.styles.tw";
import { useEffect } from "react";
import { createFactory } from "react";
import { User } from "feather-icons-react/build/IconComponents";

export function CategoriesBody({
  categories,
  currentCategory,
  handleSubmit,
  supplierCategories,
  handleAddCategory,
  handleRemoveCategory,
  ...props
}) {
  const currentCategorySections = categories?.find(
    (category) => category.id === currentCategory
  )?.childrenCategories;

  useEffect(() => {
    CategoriesImages.map((e) => {
      if (e.categoryId == 31) {
        return console.log(e.url);
      }
    });
  }, [CategoriesImages]);

  return (
    <CategoriesBodyStyled>
      <section>
        <div className="">
          {currentCategorySections?.map((section) => (
            <div className="row" key={section.id}>
              <p className="txt--bold title-sub">{section.name}</p>
              {section.childrenCategories.map((category) => {
                const isAdded = props.addedCategories?.some((e) =>
                  e.id ? e.id === category.id : e === category.id
                );

                return (
                  <div className="col s12 m12 l6 xl6" key={category.id}>
                    <div className={`cardCategory`}>
                      <div className={`img-card ${isAdded ? "added" : ""}`}>
                        <img src={CategoriesImages.find(e => (e.categoryId === category.id)).url} alt={category.name} />
                      </div>
                      <p>{category.name}</p>
                      {category.supplierCount !== undefined ? (
                        <h4>
                          {" "}
                          <User size={16} /> {category.supplierCount}{" "}
                          {category.supplierCount === 1
                            ? "Fornecedor"
                            : "Fornecedores"}{" "}
                        </h4>
                      ) : null}
                      <div className="button-card">
                        {isAdded ? (
                          <Button
                            buttonSize="small"
                            variant="secondary"
                            onClick={() => handleRemoveCategory(category.id)}
                          >
                            Remover
                          </Button>
                        ) : (
                          <Button
                            buttonSize="small"
                            variant="purple"
                            onClick={() =>
                              handleAddCategory({
                                ...category,
                                parentId: section.id,
                              })
                            }
                          >
                            Adicionar
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}

          <div className="asideCategory">
            {/* <CategoriesAside
              selectedCategories={supplierCategories}
              handleRemoveCategory={handleRemoveCategory}
              handleSubmit={handleSubmit}
            /> */}
            {props.asideComponent}
          </div>
        </div>
      </section>
    </CategoriesBodyStyled>
  );
}
