import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../Components/common/Buttons/ButtonLink';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import { StyledTerms, TermsPage } from './styles';

export default function SiteTerms() {
  const section1ref = React.useRef(null);
  const section2ref = React.useRef(null);
  const section3ref = React.useRef(null);
  const section4ref = React.useRef(null);
  const section5ref = React.useRef(null);
  const section6ref = React.useRef(null);
  const section7ref = React.useRef(null);
  const section8ref = React.useRef(null);
  const section9ref = React.useRef(null);

  const scrollEffect = targetRef => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <TermsPage>
      <Header bgBlack />

      <TermsPage.Nav>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          1 - DEFINIÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section2ref)}>
          2 - FUNCIONALIDADE E ACESSO AO SITE
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section3ref)}>
          3 - RESPONSABILIDADES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section4ref)}>
          4 - COMPROMISSOS DO CLIENTE
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section5ref)}>
          5 - PROPRIEDADE INTELECTUAL E INDUSTRIAL
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section6ref)}>
          6 - PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section7ref)}>
          7 - ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section8ref)}>
          8 - DISPOSIÇÕES GERAIS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section9ref)}>
          9 - DISPOSIÇÕES FINAIS
        </ButtonLink>
      </TermsPage.Nav>

      <StyledTerms>
        <h1>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA</h1>
        <h1>TERMOS DE USO</h1>
        <section>
          <p>
            Estes termos de uso (“Termos”) regem seu acesso e uso, como pessoa física,, de
            aplicativos, sites de Internet, conteúdos, bens e também serviços (os “Serviços”)
            disponibilizados pela <b>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA.</b>, sociedade de
            responsabilidade limitada, estabelecida no Brasil, inscrita no CNPJ/MF sob o nº
            28.282.186/0001-38, com sede na Avenida Interlagos nº 800, Jardim Marajoara, na Cidade
            de São Paulo, Estado de São Paulo, CEP: 04660-000, adiante denominada Celebrar ou
            qualquer de suas afiliadas.
          </p>
          <p>
            Os <Link to="/termos/fornecedor">Termos de Venda</Link>, os{' '}
            <Link to="/termos/comprador">Termos de Compra</Link> e a{' '}
            <Link to="/termos/politica-privacidade">Política de Privacidade</Link> integram o
            presente Termo, sendo parte inseparável do presente Termo.
          </p>
          <p>
            A presente Plataforma destina-se a disponibilizar produtos e serviços vendidos
            diretamente pela Celebrar, bem como produtos e serviços vendidos diretamente por
            Fornecedores na Plataforma da Celebrar (“Plataforma”).
          </p>
          <p>
            Ao navegar nesta Plataforma, o visitante declara ter lido, compreendido e aceito os
            Termos de Uso que o regem e todos os outros alertas e avisos que possam aparecer
            vinculados a assuntos tratados nos referidos Termos.
          </p>
        </section>

        <section ref={section1ref}>
          <h2>1. DEFINIÇÕES</h2>

          <ul>
            <li style={{ listStyle: 'disc' }}>
              <b>Site:</b> Local no qual são coletadas as informações e disponibilizadas através de
              páginas virtuais disponibilizadas na Internet, sendo acessadas por meio de um
              computador ou por outro meio comunicacional.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Usuário:</b> todos aqueles que de alguma forma acessam o Plataforma da Celebrar.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Conta de Acesso:</b> Credencial digital formada pela combinação de login e senha,
              única e intransferível para cada Usuário do Plataforma, que dará permissão ao Usuário
              ingressar na área Meu Celebrar.co e realizar compras.
            </li>
          </ul>
        </section>

        <section ref={section2ref}>
          <h2>2. FUNCIONALIDADE E ACESSO AO SITE</h2>

          <p>
            <b>2.1.</b> O Usuário da Plataforma da Celebrar tem a sua disposição uma série de
            produtos e serviços podendo estes ser oferecidos: (i) diretamente pela Celebrar, através
            dos Fornecedores; ou (ii) diretamente por Fornecedores de seus próprios produtos e
            serviços na Plataforma da Celebrar.
          </p>
          <p>
            <b>2.2.</b> Ao navegar no Site fique atento para verificar se um produto ou serviço é
            vendido diretamente pela Celebrar ou diretamente por Fornecedores.
          </p>
          <p>
            <b>2.3.</b> Maioridade: O acesso ao Site da <b>CELEBRAR</b> é livre para todos, porém
            algumas funcionalidades podem exigir atos praticados por maiores de 18 (dezoito) anos,
            conforme legislação em vigor.
          </p>
          <p>
            2.3.1. Nos casos em que o usuário ainda não seja maior de idade, os representantes
            legais deverão supervisionar e representar as crianças e adolescentes com idade inferior
            a 16 (dezesseis) anos e assistir os adolescentes em idade superior a 16 (dezesseis) anos
            e inferior à 18 (dezoito) anos, sempre que aplicável.
          </p>
          <p>
            2.3.2. A <b>CELEBRAR</b> poderá recusar, não aprovar, cancelar ou limitar quaisquer
            solicitações, caso você não nos forneça dados corretos. A mesma providência será
            aplicada durante a utilização do Site. E, uma vez que seja constatado que houve
            utilização de forma fraudulenta, bem como que tentativa ou violação destes Termos ou
            quaisquer legislações vigentes.
          </p>
        </section>

        <section ref={section3ref}>
          <h2>3. RESPONSABILIDADES</h2>

          <p>
            <b>3.1.</b> O Site e suas funcionalidades são apresentados ao Usuário na maneira como
            estão disponíveis no momento, podendo passar por constantes melhorias e atualizações,
            obrigando-se a <b>CELEBRAR</b> a:
          </p>
          <ul>
            <li>
              <b>I.</b> Preservar a sua funcionalidade, com links não bloqueados e utilizando layout
              que facilita a usabilidade e navegabilidade, sempre que possível, exibindo as
              funcionalidades de maneira clara, completa, precisa e suficiente de modo que o Usuário
              possa perceber as operações realizadas, protegendo, por meio do estado da técnica
              disponível, os dados coletados pelas funcionalidades disponibilizadas;
            </li>
            <li>
              <b>II.</b> Envidar seus melhores esforços no sentido de manter as informações que
              disponibilizar em seu Plataforma precisas, atualizadas e completas, o quanto possível.
            </li>
          </ul>
          <p>
            <b>3.2.</b> A <b>CELEBRAR</b> coloca os seus esforços para a manutenção da
            disponibilidade contínua e permanente do site. No entanto, pode ocorrer, eventualmente,
            alguma indisponibilidade temporária, que pode decorrer de manutenção necessária, ou em
            casos de força maior, com falhas nos sistemas de comunicação e acesso à Internet ou
            fatos de terceiro que fogem da esfera de vigilância e responsabilidade do usuário.
          </p>
          <p>
            3.2.1. Se isso ocorrer, a <b>CELEBRAR</b> fará o que estiver ao seu alcance para
            restabelecer o acesso ao Site o mais breve possível, dentro das limitações técnicas de
            seus serviços e serviços de terceiros, dos quais depende para ficar online.
          </p>
          <p>
            <b>3.3.</b> A <b>CELEBRAR</b> poderá alterar os presentes Termos e demais regras e
            critérios estabelecidos, serviços prestados, campos adicionais de cadastro e inserção de
            dados, empresas responsáveis por sua intermediação e demais coisas que julgue
            necessárias, sempre cientificando-lhe, enquanto usuário, sobre a nova versão e
            disponibilizando-a para consulta.
          </p>
        </section>

        <section ref={section4ref}>
          <h2>4. COMPROMISSOS DO CLIENTE</h2>

          <p>
            <b>4.1. Integridade do Site.</b> Você como usuário se compromete a não acessar áreas de
            programação do Site, seu banco de dados, códigos fonte ou qualquer outro conjunto de
            dados disponíveis nestes ambientes, de modo que não realize ou permita realizar
            engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar,
            sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra
            maneira, dispor inapropriadamente das funcionalidades e informações do site.
          </p>
          <p>
            <b>4.2. Validade das informações.</b> É de sua responsabilidade o fornecimento de dados
            corretos, válidos e atualizados.
          </p>
          <p>
            <b>4.3. Conta de acesso.</b> Você deverá manter o sigilo dos dados de sua Conta de
            Acesso em relação a terceiros e utilizá-la de modo individual e intransferível, de modo
            que não disponibilize, divulgue e compartilhe sua senha ou qualquer mecanismo de
            autenticação com quem quer que seja. Em casos de suspeita de violação de conta, você
            deverá proceder com a troca e atualização o mais rápido possível.
          </p>
        </section>

        <section ref={section5ref}>
          <h2>5. PROPRIEDADE INTELECTUAL E INDUSTRIAL</h2>

          <p>
            <b>5.1. Marca.</b> O uso comercial da expressão Celebrar.co como marca, nome empresarial
            ou nome de domínio, bem como os conteúdos das telas do Plataforma da Celebrar.co, assim
            como os programas, bancos de dados, redes, arquivos que permitem que o Usuário acesse e
            use através de sua conta são de propriedade da Celebrar.co e estão protegidos pelas leis
            e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos
            industriais, sendo vedado o uso pelo Usuário, salvo mediante prévia autorização por
            escrito.
          </p>
          <p>
            5.1.1. Ao acessar a página da Celebrar, o Usuário declara que irá respeitar todos os
            direitos de propriedade intelectual e os decorrentes da proteção de marcas registradas
            da mesma, bem como de todos os direitos referentes a terceiros que porventura estejam,
            ou estiveram, de alguma forma, disponíveis no Site da Celebrar. O simples acesso ao Site
            da Celebrar não confere ao Usuário qualquer direito de uso dos nomes, títulos, palavras,
            frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, imagens,
            vídeos, símbolos, entre outras, que nele estejam, ou estiveram, disponíveis; muito menos
            dos Fornecedores relacionados no Plataforma da Celebrar.co.
          </p>
          <p>
            5.1.2. A reprodução dos conteúdos descritos anteriormente está proibida, salvo mediante
            prévia autorização por escrito da Celebrar.
          </p>
          <p>
            <b>5.2. Conteúdo.</b> Exceto se estabelecido em contrário nos Termos, todos os
            conteúdos, campanhas, imagens, vídeos, fotografias, descrições de produtos e marcas
            disponibilizadas no site em qualquer idioma ou forma, são de propriedade da{' '}
            <b>CELEBRAR</b> ou licenciadas. O uso do Site não gera qualquer presunção de
            transferência dessa propriedade ou licença.
          </p>
        </section>

        <section ref={section6ref}>
          <h2>6. PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</h2>

          <p>
            <b>6.1. Dados Pessoais.</b> A <b>CELEBRAR</b> possui documento próprio, denominado{' '}
            <Link to="/termos/politica-privacidade">Política de Privacidade</Link>, que regula o
            tratamento dos dados coletados no site, sendo parte integrante e inseparável dos
            presentes Termos.
          </p>
          <p>
            <b>6.2.</b> A <b>CELEBRAR</b> monitorará, por meios adequados, sua própria conformidade
            com as respectivas obrigações de proteção de Dados Pessoais.
          </p>
        </section>

        <section ref={section7ref}>
          <h2>7. ISENÇÃO E LIMITAÇÕES DE RESPONSABILIDADE</h2>

          <p>
            <b>7.1.</b> Você deve arcar com os meios necessários para navegar no Site, incluindo a
            posse de um equipamento que seja considerado adequado para acesso, bem como deverá
            contratar prestador de serviços de telecomunicações para fornecimento de conexão à
            Internet.
          </p>
          <p>
            <b>7.2.</b> Sem prejuízo de outras condições destes Termos, a <b>CELEBRAR</b> não se
            responsabiliza:
          </p>
          <ul>
            <li>
              <b>I.</b> Por problemas decorrentes de bugs, glitches ou funcionamentos indevidos que
              ocorrerem em seus dispositivos e equipamentos e sejam resultantes direta ou
              indiretamente do uso regular do site;
            </li>
            <li>
              <b>II.</b> Por qualquer dano direto ou indireto ocasionado por eventos de terceiros,
              como ataque de hackers, falhas no sistema, servidor ou na conexão à internet,
              inclusive por ações de softwares que possam, de algum modo, danificar ativos físicos
              ou lógicos ou a sua conexão em decorrência do acesso, utilização e navegação no site.
            </li>
            <li>
              <b>III.</b> Pela navegação nos links de terceiros eventualmente disponibilizados no
              site, de modo que é dever do usuário a leitura dos Termos de Uso e Política de
              Privacidade do link acessado, não sendo a <b>CELEBRAR</b> responsabilizada também pela
              verificação, controle, aprovação e garantia das informações ou dados disponibilizados
              em tais links para serviços de terceiros, não sendo, portanto, responsáveis por
              prejuízos, perdas ou danos ocorridos pela visita dos sites, de forma que cabe ao
              interessado verificar a confiabilidade das informações e dados ali exibidos antes de
              tomar alguma decisão ou praticar algum ato.
            </li>
            <li>
              <b>IV.</b> Pelas atitudes tomadas pelo usuário, com base, direta ou indiretamente, no
              site, informações, dados, relatórios, opiniões, sugestões e etc.
            </li>
          </ul>
          <p>
            <b>7.3.</b> Eventuais procedimentos de manutenção que acarretem a indisponibilidade do
            site por longos períodos serão informados por meio dos canais oficiais de comunicação,
            como e-mails e perfis oficiais em mídias sociais.
          </p>
        </section>
        <section ref={section8ref}>
          <h2>8. DISPOSIÇÕES GERAIS</h2>

          <p>
            <b>8.1.</b> <b>Atualização dos Termos.</b> Estes Termos estão sujeitos a constante
            melhoria e aprimoramento. Assim, a <b>CELEBRAR</b> se reserva ao direito de modificá-los
            a qualquer momento, introduzindo alterações ou atualizações conforme sua finalidade ou
            conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma
            que tenha força jurídica equivalente.
          </p>
          <p>
            8.1.1. Ocorrendo atualizações substanciais nestes Termos, a <b>CELEBRAR</b> notificará
            você pelas ferramentas disponíveis no site ou pelos meios de contato fornecidos em seu
            cadastro, ou seja, através de meios razoáveis. Caso o Utilizador não aceite os Termos da{' '}
            <b>CELEBRAR</b> alterados, pode deixar de usar o website, desde que não haja nenhuma
            obrigação pendente entre as Partes, devendo esta ser renegociada e/ou finalizada nos
            termos anteriormente pactuados.
          </p>
          <p>
            <b>8.2. Novação e renúncia.</b> A tolerância de eventual descumprimento de qualquer
            condição destes Termos não constituirá renúncia ou novação nem impedirá a{' '}
            <b>CELEBRAR</b> de exigir estas condições a qualquer tempo.
          </p>
          <p>
            <b>8.3. Nulidade</b>. Se alguma disposição destes Termos for julgada inaplicável ou sem
            efeito, o restante continuará a viger normalmente.
          </p>
          <p>
            <b>8.4. Comunicação</b>. Você reconhece que toda comunicação realizada por e-mail (ao
            endereço de e-mail informado no seu cadastro) é válida, eficaz e suficiente para a
            divulgação de qualquer assunto que se refira ao site
          </p>
          <p>8.4.1. As comunicações para a Celebrar serão enviadas para os seguintes endereços:</p>
          <ul>
            <li>
              I. Endereço: Av. Interlagos, nº 800 Jardim Marajoara, São Paulo/SP CEP 04660-000
            </li>
            <li>II. E-mail: camila@celebrar.com.br</li>
          </ul>

          <p>
            <b>8.5. Data e hora.</b> Todos os registros e guarda de dados realizados no ambiente do
            site serão realizados e catalogados com base no horário oficial de Brasília.
          </p>
          <p>
            <b>8.6. Lei aplicável e foro.</b> Estes Termos serão regidos e interpretados
            exclusivamente de acordo com as leis do Brasil. Qualquer reclamação, conflito ou
            controvérsia que surgir deste contrato ou a ele relacionada, inclusive que diga respeito
            a sua validade, interpretação ou exequibilidade, será solucionada exclusivamente pelos
            tribunais do foro de São Paulo/SP.
          </p>
        </section>

        <section ref={section9ref}>
          <h2>9. DISPOSIÇÕES FINAIS</h2>
          <p>
            <b>9.1.</b> Caso qualquer disposição destes Termos seja tida como ilegal, inválida ou
            inexequível total ou parcialmente, por qualquer legislação, essa disposição ou parte
            dela será, naquela medida, considerada como não existente para os efeitos destes Termos,
            mas a legalidade, validade e exequibilidade das demais disposições contidas nestes
            Termos não serão afetadas.
          </p>
          <p>
            <b>9.2.</b> Nesse caso, as partes substituirão a disposição ilegal, inválida ou
            inexequível, ou parte dela, por outra que seja legal, válida e exequível e que, na
            máxima medida possível, tenha efeito similar à disposição tida como ilegal, inválida ou
            inexequível para fins de conteúdo e finalidade dos presentes Termos.
          </p>
          <p>
            <b>9.3.</b> Estes Termos constituem a totalidade do acordo e entendimento das partes
            sobre este assunto e substituem e prevalecem sobre todos os entendimentos e compromissos
            anteriores sobre este assunto.
          </p>
        </section>

        <section>
          <p>
            Assim, com a concordância do usuário, estes termos regerão a relação contratual
            estabelecida entre as partes, podendo sempre ser consultada a data da última atualização
            ao final deste instrumento.
          </p>

          <p>São Paulo/SP, 13 de julho de 2022.</p>
        </section>

        <h1>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA</h1>
      </StyledTerms>

      <Footer />
    </TermsPage>
  );
}
