import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../Modal';
import axios from 'axios';
import { FooterContent, FooterStyled } from './styles';
import Alert from '../Alert';
import { Button } from '../common/Buttons/Button';
// Images
import instagram from '../../assets/images/home_page/instagram.png';
import linkedin from '../../assets/images/home_page/linkedin.png';

function Footer() {
  const modalRef = useRef();
  const alertRef = useRef();

  function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: '/api/get_in_touch',
      data: {
        contact_name: data.get('contact_name'),
        contact_email: data.get('contact_email'),
        message: data.get('message'),
        hidden_phone: data.get('hidden_phone'),
      },
    }).then(res => {
      if (res.status === 200) {
        modalRef.current.close();
        alertRef.current.open();
      }
    });
  }

  return (
    <FooterStyled>
      <div className="container">
        <div>
          <a href="https://fornecedor.celebrar.com.br/criar-conta">
            <h4>Seja um fornecedor</h4>
          </a>

          {/* <h5>Precisa de ajuda?</h5> */}
        </div>

        <div>
          <ul>
            <h6>SIGA-NOS</h6>

            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/soucelebrar/"
              >
                <img src={instagram} alt="Instagram" loading="lazy" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pt.linkedin.com/company/startupcelebrar"
              >
                <img src={linkedin} alt="Linkedin" loading="lazy" />
              </a>
            </li>
          </ul>

          {/* <Button variant="secondary" onClick={() => modalRef.current.open()}>
            Envie uma mensagem
          </Button> */}
        </div>
      </div>

      <FooterStyled.CNPJ>
        <p>Celebrar Tecnologia e Intermediações Ltda</p>
        <p>CNPJ: 28.282.186/0001-38</p>
        <p>contato@celebrar.com.br</p>
      </FooterStyled.CNPJ>

      <FooterStyled.Terms>
        <p>
          <li>
            <a href="https://www.celebrar.com.br/termos/plataforma/" target="_blank" rel="noopener noreferrer">
              {' '}
              Termos de uso da Plataforma{' '}
            </a>
          </li>
        </p>
        <p>
          <li>
            <a href="https://www.celebrar.com.br/termos/politica_de_privacidade/" target="_blank" rel="noopener noreferrer">
              {' '}
              Política de Privacidade{' '}
            </a>
          </li>
        </p>
        <p>
          <li>
            <a href="https://www.celebrar.com.br/termos/comprador/" target="_blank" rel="noopener noreferrer">
              {' '}
              Termos - Compradores{' '}
            </a>
          </li>
        </p>
        <p>
          <li>
            <a href="https://www.celebrar.com.br/termos/fornecedor/" target="_blank" rel="noopener noreferrer">
              {' '}
              Termos - Fornecedores{' '}
            </a>
          </li>
        </p>
      </FooterStyled.Terms>

      <Modal title="Entrar em contato" ref={modalRef}>
        <form onSubmit={handleSubmit} name="footer_contact_form">
          <h3 className="modal__title"> Precisa de ajuda? Entre em contato conosco </h3>
          <div className="input__field">
            <label htmlFor=""> Seu nome </label>
            <input className="input" name="contact_name" type="text" min="1" required />
          </div>

          <div className="input__field">
            <label htmlFor=""> Seu e-mail </label>
            <input className="input" name="contact_email" type="email" min="1" required />
          </div>

          <div className="input__field">
            <label htmlFor=""> Sua mensagem </label>
            <textarea
              className="input textarea"
              name="message"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>

          <button className="btn bg--primary" type="submit">
            <span> Enviar mensagem </span>
          </button>
        </form>
      </Modal>

      <Alert ref={alertRef}>
        <p>Sua mensagem foi enviada! Entraremos em contato em até 7 dias úteis. </p>
      </Alert>
    </FooterStyled>
  );
}

export default Footer;
