import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { TermsPage } from './styles';

require('dotenv').config();

function Terms(){

/*=====================================================
                Scroll smooth
======================================================*/

function scrollSectionAnimation(sectionId){

    let offsetY = 0,
        offsetSection = document.querySelector(`#${sectionId}`).offsetTop;

    const scrollAnimationInterval = setInterval( () => {

        window.scrollTo( 0, offsetY);
        
        offsetY < offsetSection ? offsetY += 30 : clearInterval(scrollAnimationInterval);

    }, 10 );

}

    return(
        <TermsPage >
            <Header bgBlack={ true } />

            <TermsPage.Nav >
                <a onClick={ () => scrollSectionAnimation("item1") }> 1 - DEFINIÇÕES </a>
                <a onClick={ () => scrollSectionAnimation("item2") }> 2 - FINALIDADE DO PLATAFORMA </a>
                <a onClick={ () => scrollSectionAnimation("item3") }> 3 - CADASTROS </a>
                <a onClick={ () => scrollSectionAnimation("item4") }> 4 - POLÍTICA DE CANCELAMENTO PELO COMPRADOR </a>
                <a onClick={ () => scrollSectionAnimation("item5") }> 5 - MULTAS POR DESCUMPRIMENTO DO FORNECEDOR </a>
                <a onClick={ () => scrollSectionAnimation("item6") }> 6 - OBRIGAÇÕES DO FORNECEDOR:</a>
                <a onClick={ () => scrollSectionAnimation("item7") }> 7 - OBRIGAÇÕES DA CELEBRAR</a>
                <a onClick={ () => scrollSectionAnimation("item8") }> 8 - SUBCONTRATAÇÃO</a>
                <a onClick={ () => scrollSectionAnimation("item9") }> 9 - INFORMAÇÕES</a>
                <a onClick={ () => scrollSectionAnimation("item10") }> 10 - CARACTERÍSTICAS PARA MELHOR NAVEGAÇÃO</a>
                <a onClick={ () => scrollSectionAnimation("item11") }> 11 - PRIVACIDADE</a>
                <a onClick={ () => scrollSectionAnimation("item12") }> 12 - DESVINCULAÇÃO TRABALHISTA</a>
                <a onClick={ () => scrollSectionAnimation("item13") }> 13 - QUESTÕES SECURITÁRIAS</a>
                <a onClick={ () => scrollSectionAnimation("item14") }> 14 - DIREITO DE FISCALIZAÇÃO</a>
                <a onClick={ () => scrollSectionAnimation("item15") }> 15 - DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</a>
                <a onClick={ () => scrollSectionAnimation("item16") }> 16 - PROPRIEDADE INTELECTUAL PARA O FORNECEDOR</a>
                <a onClick={ () => scrollSectionAnimation("item17") }> 17 - DO DIREITO DE IMAGEM DO FORNECEDOR</a>
                <a onClick={ () => scrollSectionAnimation("item18") }> 18 - CONDIÇÕES DE SUSTENTABILIDADE E CIDADANIA</a>
                <a onClick={ () => scrollSectionAnimation("item19") }> 19 - RESPONSABILIDADES DO USUÁRIO</a>
                <a onClick={ () => scrollSectionAnimation("item20") }> 20 - EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</a>
                <a onClick={ () => scrollSectionAnimation("item21") }> 21 - CONDIÇÕES COMERCIAIS</a>
                <a onClick={ () => scrollSectionAnimation("item22") }> 22 - PRODUTOS DE FORNECEDORES DIVULGADOS NO PLATAFORMA DA CELEBRAR.CO</a>
                <a onClick={ () => scrollSectionAnimation("item23") }> 23 - MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES</a>
                <a onClick={ () => scrollSectionAnimation("item24") }> 24 - DISPOSIÇÕES GERAIS</a>
            </TermsPage.Nav>

            <div className="container--form">
                <TermsPage.Resume >
                <TermsPage.Title> RESUMO </TermsPage.Title>

                <TermsPage.Subtitle> OBJETO </TermsPage.Subtitle>
                <p>Estabelecer os direitos e as obrigações das pessoas físicas ou jurídicas que se cadastrem na plataforma online celebrar.co como FORNECEDORs ou Compradores de serviços e regular a execução desses serviços.</p>

                <TermsPage.Subtitle> FORMA DE PAGAMENTO </TermsPage.Subtitle>
                <p>Os pagamentos serão realizados diretamente à FORNECEDORA, EXCLUSIVAMENTE por meio de transferência bancária.</p>
                <p>É Proibida a emissão de boletos contra a CELEBRAR (custo de cancelamento do boleto é responsabilidade da CONTRATADA).</p>
                <p>Remuneração da CELEBRAR: percentual sobre qualquer operação intermediada pela CELEBRAR, sempre indicada ao fornecedor no ato de adesão à plataforma. Esse valor poderá ser alterado mediante aviso prévio da Celebrar.</p>

                <TermsPage.Subtitle> RESCISÃO </TermsPage.Subtitle>
                <p>Rescisão imediata: mediante descumprimento de qualquer condição do Contrato não remediada após 10 (dez) dias do recebimento da notificação identificando tal falha.</p>

                <TermsPage.Subtitle> DESVINCULAÇÃO TRABALHISTA </TermsPage.Subtitle>
                <p>Não se estabelece, por força do presente CONTRATO, nenhum vínculo empregatício entre a CELEBRAR, os Compradores e o pessoal utilizado.</p>

                <TermsPage.Subtitle> SUBCONTRATAÇÃO </TermsPage.Subtitle>
                <p><b>A SUBCONTRATAÇÃO DOS SERVIÇOS OBJETO DESTE CONTRATO PELO FORNECEDOR É TERMINANTEMENTE PROIBIDA </b>, exceto quando houver autorização prévia, expressa e por escrito da CELEBRAR. Tal aprovação não desonerará, porém, em nenhuma hipótese, o Fornecedor de qualquer de suas obrigações.</p>

                <TermsPage.Subtitle> PRINCIPAIS OBRIGAÇÕES DO FORNECEDOR </TermsPage.Subtitle>
                <p>Cumprir com o disposto neste contrato e na legislação vigente</p>
                <p>Fornecer informações claras acerca dos produtos e serviços, bem como as condições de prestação dos serviços que serão comercializados pela Plataforma.</p>
                <p>Manter a relação de produtos e serviços cadastrados na Plataforma</p>
                <p>A providência e entrega dos produtos, são de responsabilidade do FORNECEDOR.</p>
                <p>Acidentes com os produtos durante o transporte para entrega ao Comprador são de responsabilidade do FORNECEDOR.</p>
                <p>Dever de disponibilizar profissionais especializados observando todas as prescrições e obrigações relativas à legislação laboral aplicável</p>
                <p>Estar legalmente habilitado para exercer as atividades oferecidas na plataforma,</p>
                <p>Estar devidamente credenciada e habilitada para exercer o ramo de sua atividade;</p>
                <p>Possuir todas as autorizações, licenças e autorizações exigidas por lei para prestar, viabilizar e/ou permitir a prestação dos Serviços oferecidos na Plataforma.</p>
                <p>Prestar os serviços de forma diligente, independente e zelosa, dentro dos mais altos padrões de qualidade nos prazos e termos definidos na contratação.</p>
                <p>Isentar e manter a CELEBRAR e seus Compradores livre de qualquer reclamação ou punição, resultante da inobservância das suas obrigações de qualquer natureza.</p>
                <p>Responsabilizar-se única e exclusivamente por todos os prejuízos, perdas, danos, indenizações, multas, condenações judiciais e administrativas, a que derem causa e quaisquer outras despesas incorridas indevidamente pela CELEBRAR e/ou terceiros.</p>

                <p>O presente Termos e Condições de Uso aplicam-se aos internautas, compradores e fornecedores que utilizam a o site www.celebrar.co para comprar ou comercializar produtos e serviços oferecidos no Plataforma através da empresa CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA., sociedade empresária limitada inscrita no CNPJ/MF sob o nº 28.282.186/0001-38, com sede na Avenida Interlagos nº 800, Jardim Marajoara, na Cidade de São Paulo, Estado de São Paulo, CEP: 04660-000, adiante denominada Celebrar.co bem como de Fornecedores do site na forma definida nestes Termos e Condições de Uso.</p>

                <p>O presente Plataforma destina-se a disponibilizar produtos e serviços vendidos diretamente pela Celebrar.co, bem como produtos e serviços vendidos diretamente por Fornecedores no Plataforma da Celebrar.co. (“Plataforma”).</p>

                <p>Ao navegar nesta Plataforma, o visitante declara ter lido, compreendido e aceito os Termos e Condições de Uso que o regem e todos os outros alertas e avisos que possam aparecer vinculados a assuntos tratados nos referidos Termos.</p>
                </TermsPage.Resume>
                <TermsPage.List >
                    <TermsPage.ListLI id="item1">
                        <TermsPage.ListTitle> DEFINIÇÕES </TermsPage.ListTitle>

                        <TermsPage.ListOL>
                            <TermsPage.ListItem >
                                Os termos abaixo descritos, quando empregados no presente Termos e Condições de Uso da Celebrar.co, terão os seguintes significados:
                            </TermsPage.ListItem>

                            <TermsPage.ListOL className="alphabetical">
                                <TermsPage.ListItem>
                                    Internautas: todos aqueles que de alguma forma acessam o Plataforma da Celebrar.co, Compradores ou não;
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    Comprador: toda a pessoa que adquire produtos ou serviços oferecidos pela Celebrar.co ou pelos Fornecedores, pagos ou não.
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    Usuário: toda pessoa que se cadastra no Plataforma da Celebrar.co como Comprador ou Fornecedor e recebe uma conta de acesso individual e exclusiva.
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    Conta de Acesso: Credencial digital formada pela combinação de login e senha, única e intransferível para cada Usuário do Plataforma, que dará permissão ao Usuário ingressar na área Meu Celebrar.co e realizar compras.
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    Plataforma: site celebrar.co, endereço virtual destinado para a disponibilização de produtos e serviços vendidos diretamente pela Celebrar.co, bem como produtos e serviços vendidos diretamente por Fornecedores no Plataforma da Celebrar.co
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    Fornecedor: Pessoa Jurídica que anuncia e comercializa seus produtos ou serviços por meio do Plataforma da Celebrar.co, únicos responsáveis pela composição e cumprimento da oferta, entrega, suporte, atendimento ao Comprador e procedimentos que estejam diretamente ligados com a entrega dos produtos e/ou serviços respectivos.
                                </TermsPage.ListItem>

                                <TermsPage.ListItem>
                                    As definições aqui descritas também poderão ser usadas no plural ao longo desse Termos.
                                </TermsPage.ListItem>
                            </TermsPage.ListOL>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item2">
                        <TermsPage.ListTitle> FINALIDADE DO PLATAFORMA </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O Comprador do Plataforma da Celebrar.co tem a sua disposição uma série de produtos e serviços podendo estes ser oferecidos: (i) diretamente pela Celebrar.co, através dos Fornecedores; ou (ii) diretamente por Fornecedores de seus próprios produtos e serviços no Plataforma da Celebrar.co.
                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Produtos oferecidos diretamente pela Celebrar.co: as condições de venda, preço e entrega destes produtos são definidas diretamente pela Celebrar.co, que se responsabiliza por elas.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Produtos oferecidos diretamente por Fornecedores de produtos no Plataforma da Celebrar.co: as condições de venda, preço e entrega destes produtos são definidos diretamente pelo Fornecedor, identificado no anúncio.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Serviços oferecidos diretamente pela Celebrar.co: as condições de venda, preço e execução destes serviços são definidas diretamente pela Celebrar.co, que se responsabiliza por elas.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Serviços oferecidos diretamente por Fornecedores de produtos no Plataforma da Celebrar.co: as condições de venda, preço e execução destes serviços são definidos diretamente pelo Fornecedor, identificado no anúncio.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Ao navegar no Plataforma fique atento para verificar se um produto ou serviço é vendido diretamente pela Celebrar.co ou diretamente por Fornecedores.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Comprador está ciente e de acordo que a Celebrar.co não se responsabiliza pela existência, entrega, qualidade, quantidade, vícios ou defeitos dos produtos e serviços vendidos no Plataforma diretamente por Fornecedores, devendo o Comprador, nestes casos, contatar diretamente o Fornecedor. A Celebrar.co fará a intermediação entre o Comprador e o Fornecedor para que eles diretamente resolvam eventual problema.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item3">
                        <TermsPage.ListTitle> CADASTROS </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O Usuário precisa ter capacidade legal para contratar, isto é, ser maior de 18 anos ou emancipado. Sendo menor de idade, seu responsável legal terá de arcar com as consequências dos atos praticados em nome do Comprador.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Usuário que se cadastrar no Plataforma da Celebrar.co.com utilizando dados de terceiros poderá incorrer em prática de crimes, sem prejuízo de eventual responsabilização por legislação específica.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Caso a Celebrar.co constate qualquer inexatidão nas informações prestadas pelo Usuário ou ilegalidade em sua conduta, poderá, a seu exclusivo critério, suspender ou cancelar a correspondente Conta de Acesso. Tal medida, contudo, não cessa nem diminui a responsabilidade do Comprador quanto à exatidão e veracidade dos dados que fornece.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A qualquer momento a Celebrar.co poderá cancelar ou ainda recusar o cadastro de um Usuário, mesmo que já previamente aceito, em casos de desconformidade com as regras e políticas destes Termos e Condições de Uso ou incorporadas a este por referência. Cadastro em duplicidade feitos por um mesmo Comprador poderão também ser cancelados.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                COMPRADOR

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Para ser um Comprador do Plataforma da Celebrar.co basta que o Internauta preencha o formulário de cadastro disponível no site ao selecionar um produto ou serviço que tenha interesse.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Para que o Comprador possa concluir sua compra, faz-se necessário o cadastramento no Plataforma da Celebrar.co. O Comprador deverá preencher todos os campos obrigatórios com informações exatas e verdadeiras, responsabilizando-se, inclusive, em atualizar as informações de cadastro sempre que ocorrer alguma alteração. O Comprador responde legalmente perante a veracidade e autenticidade das informações prestadas no Plataforma, isentando a Celebrar.co de qualquer responsabilidade neste sentido.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                FORNECEDOR

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Para ser Fornecedor da celebrar e ter seus produtos ou serviços anunciados no site realização deste Contrato, o Fornecedor acessará o Plataforma, se cadastrará e cadastrará os produtos e serviços que serão disponibilizados no Site.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Com as informações prestadas pelo Fornecedor, a CELEBRAR publicará na Plataforma da Celebrar as informações dos produtos e serviços prestadas pelo Fornecedor, de modo a disponibilizá-los aos Compradores.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item4">
                        <TermsPage.ListTitle> POLÍTICA DE CANCELAMENTO PELO COMPRADOR </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O cancelamento de um pedido de Produto ou Serviço por parte do Comprador está sujeito à Política de Cancelamento que o Fornecedor definir para tal Produto ou Serviço, sendo que essa política estará descrita na página de anúncio do Produto ou Serviço, e se enquadrará em uma dessas hipóteses:

                                <TermsPage.ListOL className="alphabetical" >
                                    <TermsPage.ListItem >
                                        Flexível - Fornecedor oferece reembolso integral para cancelamentos com mais de 7 dias antes do evento e nenhum reembolso para cancelamentos feitos com menos de 7 dias antes da entrega.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Moderada - Fornecedor oferece reembolso integral para cancelamentos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Rigorosa - Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Conservadora - Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 30 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 30 dias antes da entrega.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>

                                Em qualquer evento de cancelamento, independentemente do prazo, será devido pelo Comprador o valor integral da taxa de serviço Celebrar.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item5">
                        <TermsPage.ListTitle> MULTAS POR DESCUMPRIMENTO DO FORNECEDOR </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Será aplicada uma multa ao FORNECEDOR nos seguintes casos:

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Falha na entrega de Serviços exatamente da forma como foram contratados, sendo que, caso tratar-se de Serviços de fornecimento de bens de qualquer natureza, não entrega de exatamente o tipo e quantidade de bens contratados: multa de 20% (vinte por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamento de entrega de Serviços até 5 dias antes do prazo contratado: multa de 50% (cinquenta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamento de entrega de Serviços entre 5 e 3 dias antes do prazo contratado: multa de 80% (oitenta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamento de entrega de Serviços entre 72 e 24 horas antes do prazo contratado: multa de 100% (cem por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Atraso na entrega dos Serviços, ou atraso superior a 10 minutos na montagem de quaisquer estruturas necessárias na data do evento do Comprador em que os Serviços serão prestados: multa de 5X (cinco vezes) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Falta de profissionalismo por parte do Fornecedor na execução dos Serviços: multa de 50% (cinquenta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Falta de ética por parte do Fornecedor na execução dos Serviços: multa de até 5 vezes (cinco vezes) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item6">
                        <TermsPage.ListTitle> OBRIGAÇÕES DO FORNECEDOR: </TermsPage.ListTitle>
                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O FORNECEDOR obriga-se a:

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Cumprir com o disposto neste Contrato e na legislação vigente;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Fornecer informações claras e precisas acerca dos produtos e serviços, bem como as condições de prestação dos serviços que serão disponibilizados para venda na Plataforma da Celebrar;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Manter sempre atualizada a relação de produtos e serviços cadastrados na Plataforma da Celebrar, especialmente em relação a disponibilidade ou não destes produtos e serviços;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Fornecer os produtos e/ou serviços anunciados de forma diligente, independente e zelosa, sempre dentro dos padrões de qualidade exigidos pela CELEBRAR e pelo Comprador, observando as datas, os prazos e as condições acordadas;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        prestar informações claras e precisas acerca dos produtos e serviços, bem como as condições de prestação dos serviços em negociação e honrar com todo e qualquer compromisso ao fornecer informações ou interagir comercialmente com a CELEBRAR via API WhatsApp integrada com a Plataforma,
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Providenciar e responsabilizar-se pela a entrega dos produtos;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Garantir a disponibilidade de todos os produtos e serviços ofertados no Orçamento para o prazo previsto na solicitação de Orçamento.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Disponibilizar à Contratante uma equipe de profissionais especializados quando houver prestação de serviços;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Estar legalmente habilitada para exercer as atividades oferecidas na Plataforma da Celebrar;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Responsabilizar-se inteiramente pela prestação do serviço nos termos e condições oferecidos na Plataforma da Celebrar;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cumprir com todas as obrigações decorrentes da mão-de-obra empregada na prestação dos serviços oferecidos pelo FORNECEDOR através da Plataforma da Celebrar, sejam elas de que natureza for, inclusive natureza fiscal, tributária, previdenciária, trabalhista, civil, penal, ambiental ou administrativa;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        cumprir integralmente o CONTRATO, por si, seus sócios quotistas, representantes, empregados, ou quaisquer pessoas a ela vinculada, sob pena de rescisão imediata do CONTRATO;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        responder e fornecer informações solicitadas pela Plataforma acerca da relação comercial, operacional e financeira ocorrida entre Fornecedor e Comprador sempre que solicitado pela CELEBRAR.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Envidar seus melhores esforços na consecução dos Serviços, bem como, executar os Serviços dentro da boa técnica utilizada para as atividades aplicáveis à mesma espécie.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Observar todas as prescrições relativas às Leis Trabalhistas, Previdenciárias Assistenciais e securitárias relativas ao pessoal empregado, contratado ou subcontratado do FORNECEDOR, assumindo todas as obrigações como única empregadora e responsável, com total isenção da CELEBRAR e de seus Compradores.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Manter a CELEBRAR isenta de qualquer atuação, notificação, intimação, reclamação e/ou condenação decorrente da (i) compra e venda dos Produtos e/ou Serviços, bem como decorrentes de vícios redibitórios, evicção e defeitos nos Produtos vendidos; (ii) reparação dos danos causados aos consumidores por defeitos relativos à prestação dos serviços ofertados, bem como por informações insuficientes ou inadequadas sobre sua fruição e riscos; e
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Aceitar a denunciação à lide caso a CELEBRAR seja envolvida em algum processo, judicial ou administrativo, referente à Intermediação e/ou compra e venda dos produtos e/ou serviços.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cumprir, durante a execução dos Serviços, objeto deste CONTRATO, toda a legislação aplicável à espécie, seja federal, estadual ou municipal, bem como todas as determinações e resoluções dos órgãos da Administração Pública competentes e demais entidades de fiscalização, responsabilizando-se, única e integralmente, por todo e qualquer ônus decorrente da inobservância destes preceitos.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Utilizar, na execução dos Serviços sob tutela do presente CONTRATO, somente empregados em situação regular quanto ao vínculo empregatício.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Isentar e manter a CELEBRAR e seus Compradores livre de qualquer reclamação ou punição, resultante da inobservância das suas obrigações e deveres para com seus prepostos e/ou funcionários, contratados ou subcontratados, cumprindo o pontual recolhimento das contribuições dos tributos incidentes sobre a prestação de Serviços que for de sua competência tributária.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Fornecer à CELEBRAR e aos seus Compradores, se e quando solicitado por estes, cópia de qualquer documento comprobatório de regularidade do vínculo trabalhista com entre Fornecedor e seus funcionários, dentro do prazo estipulado pela CELEBRAR para a sua entrega, que não será inferior a 5 dias úteis
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Autorizar formalmente, se necessário, que a CELEBRAR ou seus Compradores retenha, para garantir eventuais pagamentos a que for obrigada para com os funcionários do FORNECEDOR ou para se ressarcir de valores a que tenha direito por força deste CONTRATO, qualquer valor que ainda tenha direito o FORNECEDOR a receber.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Independente do disposto no item acima, a CELEBRAR reterá os tributos necessários que, por lei, forem definidos como de sua competência.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Arcar com os custos de alimentação e transporte do seu pessoal.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Refazer ou corrigir, às suas expensas, os Serviços que tenham sido prestados pela mesma com erro ou imperfeição técnica, respondendo, ainda, por eventuais falhas e/ou erros na execução das atividades constatados no prazo de até 01 (um) ano após a conclusão dos Serviços e mediante expressa comunicação da CELEBRAR e/ou de seus Compradores à FORNECEDORA. Á irregularidade deverá ser sanada no prazo máximo de 03 (três) meses. Na impossibilidade da correção, o FORNECEDOR deverá indenizar a CELEBRAR e/ou seus Compradores pelo dobro do valor correspondente a ditos reparos.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Dar a correta destinação dos resíduos gerados por meio da prestação de Serviços, objeto do presente instrumento, devendo cumprir as determinações e indicações de locais oferecidos pela CELEBRAR e, ao final da prestação de Serviços, realizar o descarte dos detritos em conformidade com a legislação ambiental e demais normas correlatas aplicáveis.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        responsabilizar-se única e exclusivamente por todos os prejuízos, perdas, danos, indenizações, multas, condenações judiciais e administrativas a que derem causa e quaisquer outras despesas incorridas, decorrentes de quaisquer ações e/ou omissões de seus empregados, prepostos, contratados e/ou subcontratados, em decorrência da execução do objeto do presente CONTRATO causados tanto à CELEBRAR quanto a terceiros.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Observar os regimentos e normas internas da CELEBRAR e de seus Compradores;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        observar e respeitar, durante a execução dos Serviços, os direitos autorais e de propriedade intelectual e industrial da Contratante e de terceiros;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        não fazer, direta ou indiretamente, qualquer pagamento, donativo ou concessão a (i) funcionário público, (ii) administrador, funcionário ou fornecedor da Contratante, ou (iii) qualquer terceiro, que possa constituir uma violação à legislação aplicável (o que inclui, entre outros, a lei norte-americana contra práticas de corrupção no exterior, conhecida como Foreign Corrupt Practices Act, à Lei Federal nº 12.846/13 e, no que forem aplicáveis, os seguintes tratados internacionais: a Convenção Interamericana Contra a Corrupção (Convenção da OEA), a Convenção das Nações Unidas Contra a Corrupção (Convenção das Nações Unidas), a Convenção sobre o Combate da Corrupção de Funcionários Públicos Estrangeiros em Transações Comerciais Internacionais (Convenção da OCDE).
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Afastar, imediatamente, após comunicação por escrito da CELEBRAR, qualquer dos seus empregados ou prepostos, bem como contratados ou subcontratados, cuja permanência nos Serviços for julgada inconveniente em relação à execução do CONTRATO, a critério da CELEBRAR, bastando a esta, unicamente, fundamentar o motivo pelo qual requer o competente afastamento.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR garante que oferecerá treinamento à CELEBRAR e seus Comprador, sem ônus, caso for necessário para a operação dos Serviços, objeto do CONTRATO.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Sem prejuízo das demais obrigações acima mencionadas, o FORNECEDOR declara e garante que: 

                                <TermsPage.ListOL className="alphabetical">
                                    <TermsPage.ListItem >
                                        É sociedade legalmente constituída e existente de acordo com a lei brasileira, está devidamente credenciada e habilitada para exercer o ramo de sua atividade, assim como o pessoal alocado para o cumprimento do objeto deste CONTRATO, e está em situação regular, de acordo com a legislação aplicável, inclusive no que se refere às necessárias licenças e autorizações.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Possui, em seu nome, todas as autorizações, licenças, aprovações, certificados, permissões e autorizações estaduais, federais e municipais, materialmente exigidos por lei para prestar, viabilizar e/ou permitir a prestação dos Serviços objeto do presente CONTRATO.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Está absolutamente em dia com todas as suas obrigações, notadamente com aquelas de natureza fiscal, previdenciária e trabalhista.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Tem capacidade para celebrar o presente CONTRATO e o faz, neste ato, de forma consciente, sem qualquer coação e/ou vício de consentimento, bem como, tem capacidade técnica, operacional, logística, comercial e financeira já instaladas e todas ora suficientes para suportar a finalidade e os efeitos do presente CONTRATO, comprometendo-se a cumpri-lo em sua integralidade.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Inexiste qualquer restrição administrativa, contratual ou judicial, notadamente por razões de proteção ambiental e de zoneamento urbano, que se aplique diretamente ao exercício do CONTRATO pelo FORNECEDOR.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A celebração do presente CONTRATO e o cumprimento pelo FORNECEDOR das obrigações aqui previstas não são conflitantes com qualquer contrato com o qual o FORNECEDOR esteja vinculada, nem resultarão em qualquer violação de tais contratos, conflito ou violação que afetem, significativa e adversamente, a capacidade do FORNECEDOR de cumprir suas obrigações, nos termos do presente CONTRATO.
                                        <TermsPage.ListOL id="teste">
                                            <TermsPage.ListItem >
                                                O FORNECEDOR informará à CELEBRAR, no prazo máximo de 24 (vinte e quatro) horas, acerca de qualquer fato, ato, omissão ou evento que possa de forma material e adversa, direta ou indiretamente, afetar os negócios relacionados ao CONTRATO ou à implementação das operações previstas neste CONTRATO.
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>

                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                As Partes concordam que a CELEBRAR fica isenta de qualquer atuação, notificação, intimação, reclamação e/ou condenação decorrente da Intermediação objeto deste Contrato.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O CONTRATANTE fica expressamente proibido de fornecer qualquer produto ou serviço anunciados na Plataforma da Celebrar para Comprador ou Compradores que tenham tomado conhecimento do FORNECEDOR e/ou que tenham adquirido produtos e/ou serviços do FORNECEDOR pela Plataforma da Celebrar e/ou aplicativo da CELEBRAR.

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Caso a CELEBRAR tome conhecimento do descumprimento do item 3.5 por parte do FORNECEDOR, a CELEBRAR, a seu exclusivo critério poderá aplicar uma multa de duas vezes o valor do produto e/ou serviço vendido pelo FORNECEDOR for da Plataforma da Celebrar e/ou aplicativo da CELEBRAR, bem como excluir o Comprador e seus produtos e/ou serviços da Plataforma da Celebrar e do aplicativo.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item7">
                        <TermsPage.ListTitle> OBRIGAÇÕES DA CELEBRAR </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A CELEBRAR obriga-se a:

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Cumprir com o disposto neste Contrato e na legislação vigente;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Realizar a Intermediação, objeto deste Contrato, nos termos aqui definidos;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Divulgar a Plataforma da Celebrar nas principais redes sociais.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Especialistas em Eventos:

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        O Especialista em Eventos é um representante da CELEBRAR que fará a indicação de produtos e serviços ao Comprador com base no que este busca na Plataforma. A obrigação da CELEBRAR em relação ao Especialista em Eventos é apenas de garantir a que os Especialistas em Eventos sejam profissionais capacitados na estruturação e gestão de eventos. 
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A partir dos itens (produtos/serviços) sugeridos pelo Especialista em Eventos, o Comprador deve escolher quais itens irá comprar, não havendo qualquer obrigatoriedade do Comprador em observar ou acatar as sugestões recebidas na Plataforma.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A indicação do Especialista de Eventos não torna a CELEBRAR corresponsável por qualquer ocorrência havida entre Comprador e Fornecedor.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item8">
                        <TermsPage.ListTitle> SUBCONTRATAÇÃO </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A SUBCONTRATAÇÃO DOS SERVIÇOS OBJETO DESTE CONTRATO PELO FORNECEDOR É TERMINANTEMENTE PROIBIDA, exceto quando houver autorização prévia, expressa e por escrito da CELEBRAR.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Tal aprovação não desonerará, porém, em nenhuma hipótese, o FORNECEDOR de qualquer de suas obrigações ou responsabilidades decorrentes deste CONTRATO, ficando e permanecendo completa e solidariamente responsável por todos os Serviços executados e quaisquer dos subcontratados, como se os Serviços executados por estes últimos tivessem sido realizados diretamente pelo FORNECEDOR, nos termos do Art. 265 e 942, parágrafo único do Código Civil.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Para a subcontratação dos Serviços, objeto deste CONTRATO, pelo FORNECEDOR, esta se compromete a apresentar à CELEBRAR, os seguintes documentos da empresa a ser subcontratada:

                                <TermsPage.ListOL className="alphabetical">
                                    <TermsPage.ListItem >
                                        Proposta comercial;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Certidões de regularidade perante os órgãos fiscalizadores;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Documentação securitária, incluindo a apólice, cuja cobertura deverá ser igual ou superior ao valor do serviço prestado, contemplando cobertura para a responsabilidade de terceiros.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR declara que toda a relação com terceiros, objeto de qualquer subcontratação, será devidamente formalizada através da assinatura de contratos específicos, o qual será apresentado sempre que solicitado pela CELEBRAR.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Fica desde já certo e estabelecido que toda e qualquer subcontratação deverá ser antecedida de prévia submissão à avaliação da CELEBRAR, principalmente no que tange ao objeto e capital social para a garantia do cumprimento integral da avença ora estabelecida.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Na ocorrência de faturamento direto, ou seja, a CELEBRAR realizar o pagamento diretamente ao subcontratado, o FORNECEDOR deverá, detalhar previamente, em até 24 horas da contratação do Serviço por qualquer Comprador, e por escrito toda a sua composição do preço relativa ao objeto deste CONTRATO.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item9">
                        <TermsPage.ListTitle> INFORMAÇÕES </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A Celebrar.co envidará seus melhores esforços no sentido de manter as informações que disponibilizar em seu Plataforma precisas, atualizadas e completas, o quanto possível. No entanto, não se responsabiliza por imprecisão, erro, fraude, inexatidão, insuficiência ou divergência nas informações, dados, imagens, vídeos ou outros conteúdos relacionados às comunicações.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Usuário está ciente de que, no que se refere aos produtos e/ou serviços oferecidos por Fornecedores, as informações constantes no Plataforma são de exclusiva responsabilidade do Fornecedor, inclusive as informações relativas as respostas e perguntas trocadas entre o Usuário e os Fornecedores sobre os produtos e/ou serviços por eles divulgados no Plataforma da Celebrar.co.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item10">
                        <TermsPage.ListTitle> CARACTERÍSTICAS PARA MELHOR NAVEGAÇÃO </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O Usuário é responsável por instalar os aplicativos eventualmente necessários para a visualização dos conteúdos disponíveis no Plataforma da Celebrar.co e adotar o navegador de sua preferência conforme padrão HTML utilizado.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item11">
                        <TermsPage.ListTitle> PRIVACIDADE  </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A Celebrar.co possui política expressa sobre Privacidade dos dados obtidos por meio do Plataforma. As informações de cadastro e demais informações sobre os Usuários estão sujeitas ao tratamento definido na referida Política.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Para maiores informações, favor consultar o documento "Política de Privacidade". Tal documento constitui parte integrante e indissociável dos Termos e Condições de Uso do Plataforma da Celebrar.co.com e deverá ser lido atentamente por todos os Usuários.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Na hipótese das disposições contidas nos Termos e Condições de Uso conflitarem com as disposições previstas na Política de Privacidade, observar-se-á o disposto no documento que trate do tema com especificidade.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item12">
                        <TermsPage.ListTitle> DESVINCULAÇÃO TRABALHISTA </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Não se estabelece, por força do presente CONTRATO, nenhum vínculo empregatício entre a CELEBRAR, os Clientes e o pessoal utilizado, empregado/subcontratado ou terceiro relacionado ao FORNECEDOR, cabendo à última, todas as responsabilidades trabalhistas, securitárias, previdenciárias e fiscais, inclusive aquelas oriundas de modificações na legislação em vigor, concernente aos seus empregados e/ou subcontratados envolvidos na execução do presente CONTRATO, vinculados direta ou indiretamente ao FORNECEDOR, devendo o FORNECEDOR reembolsar a CELEBRAR e/ou os Clientes, no prazo de 10 (dez) dias úteis, de quaisquer despesas que esta tenha sido obrigada a desembolsar em decorrência de reclamações trabalhistas, ações judiciais diversas e processos administrativos, de qualquer natureza, inclusive os relativos a acidente do trabalho, promovidos pelas pessoas mencionadas nesta Cláusula, devendo todos os valores ser devidamente corrigidos com base no IGP-M, desde a data do desembolso pela CELEBRAR e/ou os Clientes até a data do pagamento pelo FORNECEDOR.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR deverá, quando for o caso, atender expressamente ao quanto contido na legislação previdenciária, obrigando-se a encaminhar suas Notas Fiscais/Faturas com o devido destaque dos valores de mão-de-obra e materiais, viabilizando à CELEBRAR e/ou os Clientes efetuar o recolhimento previdenciário previsto na legislação pertinente.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A imprecisão, erro e/ou inverdade averiguados nas informações prestadas pelo FORNECEDOR, relativas à legislação aplicável, que ocasionem autuações e/ou ações judiciais contra a CELEBRAR e/ou os Clientes, serão de integral responsabilidade do FORNECEDOR, que se obriga a ressarcir à CELEBRAR e/ou os Clientes toda e qualquer importância eventualmente por ela despendida em decorrência dessas ocorrências, no prazo de 10 (dez) dias da comunicação da CELEBRAR e/ou dos Clientes.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item13">
                        <TermsPage.ListTitle> QUESTÕES SECURITÁRIAS </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O FORNECEDOR se compromete a adquirir seguro que cubra, dentre outras avenças aplicáveis, as questões relativas ao transporte e responsabilidade civil por danos, a fim de que a CELEBRAR não seja prejudicada por quaisquer danos causados pelo FORNECEDOR à CELEBRAR e/ou à seus Clientes.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR se obriga a manter seguro com apólice que, no mínimo, cubra os valores totais da obrigação assumida, bem como os respectivos riscos. Fica desde já certo e estabelecido que manutenção de apólice de seguro pelo FORNECEDOR não a eximirá de qualquer responsabilidade, inclusive no que se refere a valores e/ou coberturas que eventualmente superarem aqueles abrangidos pela apólice de seguro por ela contratada.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR, sob pena de inadimplência no presente instrumento, compromete-se a apresentar cópia da apólice de seguro contratada, bem como suas eventuais e sucessivas renovações.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item14">
                        <TermsPage.ListTitle> DIREITO DE FISCALIZAÇÃO </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Todas as obrigações previstas neste CONTRATO serão inspecionadas pela CELEBRAR.

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Os Serviços poderão inspecionados pela CELEBRAR no decorrer de sua execução, devidamente acompanhado por um preposto nomeado pelo FORNECEDOR para este fim;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Não serão aceitos como Serviços executados, ou como fase concluída, aqueles que não estiverem completos na data prevista para sua finalização.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A inspeção e a fiscalização dos Serviços pela CELEBRAR não exime o FORNECEDOR de qualquer responsabilidade pela qualidade do trabalho executado.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A CELEBRAR poderá, a seu critério: (a) recusar ou sustar qualquer execução de Serviço em desacordo com as disposições previstas neste CONTRATO, ou que atente contra a segurança pessoal ou dos bens da CELEBRAR e/ou de terceiros; (b) sustar o pagamento total ou parcial de qualquer fatura no caso de inobservância das condições previstas neste instrumento ou de obrigações legais, bastando uma mera comunicação por escrito da CELEBRAR à FORNECEDOR, fundamentando a causa da suspensão.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item15">
                        <TermsPage.ListTitle> DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O uso comercial da expressão Celebrar.co como marca, nome empresarial ou nome de domínio, bem como os conteúdos das telas do Plataforma da Celebrar.co, assim como os programas, bancos de dados, redes, arquivos que permitem que o Usuário acesse e use através de sua conta são de propriedade da Celebrar.co e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais, sendo vedado o uso pelo Usuário, salvo mediante prévia autorização por escrito.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Ao acessar a página da Celebrar.co, o Usuário declara que irá respeitar todos os direitos de propriedade intelectual e os decorrentes da proteção de marcas registradas da mesma, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis no Plataforma da Celebrar.co. O simples acesso ao Plataforma da Celebrar.co não confere ao Usuário qualquer direito de uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, imagens, vídeos, símbolos, entre outras, que nele estejam, ou estiveram, disponíveis; muito menos dos Fornecedores relacionados no Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A reprodução dos conteúdos descritos anteriormente está proibida, salvo mediante prévia autorização por escrito da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                É permitido fazer somente o arquivo temporário do Plataforma da Celebrar.co, sendo vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste Termo. São igualmente proibidas a reprodução, distribuição, divulgação, adaptação, modificação, utilização, edição, disponibilização em qualquer meio de comunicação etc., total ou parcial, dos textos, figuras, gráficos que compõem o presente Plataforma, sem prévia e expressa autorização da Celebrar.co, sendo permitida somente a impressão de cópias para uso e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real entendimento de seu conteúdo e objetivo.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Qualquer outro tipo de utilização de material autorizado, inclusive para fins editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso consentimento da Celebrar.co e respectivos Fornecedores presentes no Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Usuário assume toda e qualquer responsabilidade pela utilização indevida das informações, textos, gráficos, marcas, obras, imagens, vídeos, enfim, de todo e qualquer direito de propriedade intelectual ou industrial do Plataforma da Celebrar.co ou de seus Fornecedores, tanto de caráter civil quanto criminal.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Qualquer reutilização do material autorizado deverá ser objeto de uma nova autorização da Celebrar.co. Seus Fornecedores possuem orientações próprias, portanto, o interessado deverá conhecer as políticas de uso de material protegido por propriedade intelectual deste.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A autorização para utilização do material solicitado não poderá ser transferida a terceiros, mesmo que vinculados ao Usuário por alguma razão, salvo se mediante prévia autorização por escrito da Celebrar.co ou de seus Fornecedores, conforme o caso. A utilização do material não autoriza o Usuário a expor terceiros ao ridículo, criar uma obra de caráter ilegal, difamatória, obscena ou imoral, que possa violar a ética, a moral e os bons costumes, sob pena de arcar com as penalidades aplicáveis pela legislação vigente.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Qualquer utilização não contemplada na presente autorização será considerada como uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei 9.610, de 19 de fevereiro de 1998, que protege os direitos autorais no Brasil.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A eventual retirada de qualquer anúncio, artigo, vídeo, notícia ou fotografia reproduzido pelo Plataforma da Celebrar.co em decorrência de alguma reclamação, deverá ser sempre compreendida como uma demonstração de nossa intenção de evitar pendências com relação a este assunto e, jamais, como reconhecimento de qualquer infração pela Celebrar.co a direito de terceiro ou de possuir culpa sobre o ocorrido.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                As fotos e imagens utilizadas no Plataforma da Celebrar.co podem não refletir seu tamanho original ou situação atual do cenário reproduzido, sendo meramente ilustrativas.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item16">
                        <TermsPage.ListTitle> PROPRIEDADE INTELECTUAL PARA O FORNECEDOR </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O FORNECEDOR, na execução do objeto do CONTRATO se obriga a não infringir quaisquer direitos de propriedade intelectual, dentre eles, sem a estes ser limitados a marcas, patentes, segredo industrial ou qualquer direito autoral.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR concorda:

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        em defender, isentar e indenizar a CELEBRAR de quaisquer reclamações por violação de direitos de propriedade intelectual, dentre eles, patentes, marcas, direitos autorais, desenhos industriais, ou pela má utilização ou apropriação indébita de segredo industrial e por danos e despesas incorridos (incluindo honorários advocatícios razoáveis e outros honorários profissionais), de qualquer forma relacionados aos Serviços objeto deste CONTRATO;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        que tendo este CONTRATO, por objeto, a criação de obra/trabalho sujeito às disposições que regem os direitos autorais ou a propriedade intelectual, o resultado de tal criação será considerado “obra por encomenda”, na forma definida na legislação vigente, sendo certo que o valor devido pela CELEBRAR à FORNECEDOR, no tocante à transferência definitiva destes direitos, já se encontram contemplados no preço total aqui definido. Havendo qualquer impedimento para que tal resultado seja classificado como obra por encomenda, o FORNECEDOR, desde já, cede à CELEBRAR todos os direitos, a titularidade e a participação nos direitos autorais, morais, de propriedade industrial, ou de qualquer outra natureza, que recaiam ou venham a recair sobre o referido resultado, sem quaisquer ônus adicionais aos valores pagos pela CELEBRAR, tendo em vista a proposta comercial.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Será de exclusiva responsabilidade do FORNECEDOR o controle dos softwares eventualmente utilizados na execução deste CONTRATO. A CELEBRAR não será responsabilizada por quaisquer violações de direito autoral, incluindo desrespeito à propriedade intelectual de softwares, que vierem a ser cometidas pelo FORNECEDOR, ou por qualquer de seus empregados ou subcontratados. O FORNECEDOR (a) compromete-se a isentar a CELEBRAR de qualquer responsabilidade em reclamação ou em ação judicial que venha a ser movida contra a CELEBRAR, em decorrência da violação dos direitos acima previstos; (b) obriga-se a arcar e/ou reembolsar à CELEBRAR quaisquer custos e/ou despesas judiciais e/ou extrajudiciais, inclusive honorários advocatícios, sem prejuízo de responder por eventuais perdas e danos decorrentes do evento.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item17">
                        <TermsPage.ListTitle> DO DIREITO DE IMAGEM DO FORNECEDOR </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O FORNECEDOR autoriza a utilização de sua imagem, som da voz e/ou seu nome pela CELEBRAR para fins publicitários e/ou comerciais, de maneira gratuita, não onerosa e por prazo indeterminado. 

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        A CELEBRAR poderá praticar os seguintes atos relacionados com a imagem, som da voz e/ou nome do FORNECEDOR, seus funcionários, sócios e/ou qualquer equipe que estiver presente em eventos dos Clientes da CELEBRAR:
                                        
                                        <TermsPage.ListOL >
                                            <TermsPage.ListItem >
                                                Captar por intermédio de qualquer mecanismo ou meio tecnológico disponível para tanto;
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                Editar, reeditar, tratar, modificar, alterar, recortar, compilar, agrupar ou de qualquer modo complementar o conteúdo captado;
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                Transferir, migrar, deslocar, alterar ou de qualquer forma mudar o formato ou extensão do suporte ao qual o conteúdo tenha sido capturado;
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                Transmitir o suporte que contenha conteúdo autorizado através de qualquer meio, seja eletrônico, digital, magnético, fibra ótica ou qualquer outro que venha ser inventado;
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                Armazenar, agrupar ou de qualquer forma organizar o suporte em que esteja inserido o conteúdo autorizado, seja em banco de dados, servidores internos, externos, de maneira integra ou fracionada;
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                Utilizar, reproduzir, veicular, publicar, compartilhar ou distribuir em mídia impressa ou digital, em formato físico, em mídias de comunicação de massa e/ou pela Internet, podendo o conteúdo autorizado ser disponibilizado em mídias sociais, sites de compartilhamento de imagens, vídeos ou de arquivo de som, seja através de aplicativos, arquivos executáveis, editáveis ou não, wallpapers (papel de parede de computador, tablete, smartphone e celular), seja por intermédio de computadores pessoais, celulares, smartphones, tablets, laptops ou qualquer outro dispositivo que possa reproduzir, armazenar, compartilhar, editar ou receber tal conteúdo. 
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A CELEBRAR declara que estão ressalvados os direitos do FORNECEDOR sobre a integridade da sua honra, reputação, boa fama ou a respeitabilidade, sendo que o uso de sua imagem, som da voz ou nome devem ser feitos apenas nos limites acordados neste Termo e da legislação nacional vigente.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR exime, por meio deste CONTRATO, a CELEBRAR de qualquer responsabilidade pelo uso indevido de sua imagem, som da voz ou nome por terceiros devido a possibilidade de compartilhamento de conteúdo em ambientes de terceiros, trazida pelas novas tecnologias e pela Internet. 
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Não serão devidos ao FORNECEDOR nenhum valor adicional a título de royalties, licenciamento ou qualquer outra remuneração além daquelas já acordadas no presente Termo em razão da transferência da Propriedade Intelectual.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item18">
                        <TermsPage.ListTitle> CONDIÇÕES DE SUSTENTABILIDADE E CIDADANIA </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                O FORNECEDOR se compromete a cumprir todas as leis, normas e demais cominações legais relativas à política nacional do meio ambiente, proferidas em esfera executiva, legislativa ou administrativa, em âmbito federal, estadual ou municipal, notadamente às práticas da utilização sustentável de recursos de origem legal e o gerenciamento e destinação correta de resíduos.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR se compromete a cumprir todas as imposições legais relativas à segurança e medicina do trabalho, assim como as convenções e acordos trabalhistas e sindicais aplicáveis a seus colaboradores.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR se obrigam a não contratar mão-de-obra que utilize a exploração de trabalho forçado; utilizar trabalhadores em condições análogas à de escravo; utilizar trabalho realizado por crianças menores de dezesseis anos de idade, salvo na condição de aprendiz, a partir dos catorze anos de idade; e não empregar trabalhadores com menos de dezoito anos em locais prejudiciais à sua formação e desenvolvimento, assim como locais de serviços perigosos e/ou insalubres, trabalho noturno (horário compreendido entre as 22h e 5h).
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR, em nenhuma hipótese, permitirá que menores de dezoito anos possuam jornada de trabalho que impossibilite ou prejudique a frequência escolar.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR se compromete a não adotar ou apoiar práticas de discriminação negativa e/ou injuriosa, relacionada à raça, cor, sexo, religião, opção sexual, origem ou qualquer outra característica pessoal de seus profissionais.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR se obriga a manter as suas instalações dentro dos padrões mínimos definidos pela legislação aplicável à espécie.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O FORNECEDOR responsabiliza-se por si e por terceiros que porventura vierem a utilizar no curso da relação comercial instituída, devendo transmitir-lhes as <span className="underline"> “Condições de Sustentabilidade e Cidadania” </span> previstas acima.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A CELEBRAR, verificando o descumprimento do FORNECEDOR e dos terceiros por esta utilizados, das obrigações aqui previstas, poderá extinguir esse instrumento imediatamente, sem a necessidade de notificação prévia ou demais formalidades, sem prejuízo da aplicação das penalidades previstas neste instrumento e apuração das perdas e danos que vier a incorrer.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item19">
                        <TermsPage.ListTitle> RESPONSABILIDADES DO USUÁRIO </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A Celebrar.co adota todas as medidas para informação e segurança de seus Usuários. Assim, o Usuário assume todos os ônus e responsabilidades decorrentes dos atos que praticar no Plataforma da Celebrar.co e daqueles que terceiros praticarem em seu nome, por meio do uso de sua conta de acesso.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Usuário é o único responsável pelas informações que prestar ou divulgar no Plataforma da Celebrar.co. O Usuário SE COMPROMETE A INDENIZAR a Celebrar.co e seus Fornecedores por quaisquer prejuízos e danos que causar em decorrência de suas ações ou omissões que violem as disposições contidas na legislação vigente ou nos Termos e Condições de Uso do Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O Usuário autoriza a Celebrar.co a proceder à restituição dos tributos que tiverem sido por este pagos, indevidamente ou a maior, para fins do disposto no artigo 166 do Código Tributário Nacional.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                <span className="underline"> Poder de escolha: </span> todo Usuário tem a capacidade de escolher livremente o que irá comprar ou fornecer na Plataforma.

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        A escolha do Comprador é consolidada com a realização da Compra na Plataforma, quando este seleciona quais serviços e fornecedores ele irá adquirir com base nas sugestões e/ou indicações feitas na plataforma com base nos critérios que o Comprador apresentou à Plataforma e/ou ao Especialista de Eventos da CELEBRAR.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A escolha do Fornecedor é consolidada quando este envia orçamento em resposta a solicitação da Plataforma ou quando aceita uma confirmação de venda recebida pela Plataforma.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Prestação de informações: Para o uso da Plataforma, seja para aquisição ou fornecimento de produtos ou serviços, o Usuário deverá sempre que solicitado fornecer todas as informações para personalização e fiel cumprimento dos serviços contratados.

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Sempre que solicitado, o Comprador deverá oferecer todas as informações necessárias para a produção e execução do produto ou serviço, incluindo mas não se limitando a, cronograma, endereços de entrega de produtos ou execução de serviços, quantidade de convidados em eventos ou experiências corporativas (físico ou online), qualquer informação necessária para a personalização do produto ou serviço contratado, especialmente quando esse produto/serviço tiver como principal finalidade a personalização do que foi adquirido na Plataforma.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item20">
                        <TermsPage.ListTitle> EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                A Celebrar.co utiliza em seu Plataforma as melhores e mais modernas tecnologias para a segurança de seus Usuários. No entanto, em decorrência de questões operacionais, de terceirização de serviços, o Plataforma da Celebrar.co pode estar sujeito a eventuais problemas de interrupção, falha técnica e indisponibilidade de funcionamento temporário. Quando for razoavelmente possível, a Celebrar.co advertirá previamente as interrupções do funcionamento de seu Plataforma da Celebrar.co aos seus Usuários.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A Celebrar.co se exime de qualquer responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer da falta de disponibilidade ou de continuidade do funcionamento do Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A Celebrar.co não se responsabiliza por qualquer dano ou prejuízo com envio de informações ou de conteúdo publicitário que não tenham sido disparados pelo própria Celebrar.co, sejam esses últimos legítimos ou indesejados (SPAM).
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A Celebrar.co não se responsabiliza por qualquer caso em que ocorra erro sistêmico que alterem dados ou informações referentes aos produtos, inclusive aos preços informados no site. Em caso de erro, o Comprador será comunicado via e-mail, o telefone ou através da plataforma online de Auto Atendimento da Celebrar.co sobre o cancelamento de seu pedido.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                No caso de cancelamento do pedido, a operação será anulada e os valores eventualmente pagos pelo Comprador serão devolvidos pela Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Em todas as promoções e parcerias os descontos contidos em nosso site não serão cumulativos, ou seja, diante de duas ou mais promoções ou descontos será necessário optar por aquela(e) que melhor lhe convém, isentando a Celebrar.co e seus Fornecedores de qualquer responsabilidade nesse sentido.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                A Celebrar.co não se responsabiliza por eventuais produtos pessoais devolvidos erroneamente juntamente com os produtos da Celebrar.co adquiridos através do Plataforma da Celebrar.co em caso de troca, substituição ou arrependimento de compra, tais como CDs, pen drives etc.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Quanto aos produtos adquiridos através dos Fornecedores da Celebrar.co, deverão ser observadas as condições e regras correspondentes dos Fornecedores.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Destacamos que a Celebrar.co não se responsabiliza pela eventual reprovação de crédito no momento de realização da compra, pois, trata-se de procedimentos da respectiva Administradora do Cartão de Crédito utilizado pelo Comprador no momento da compra. Informamos também que a Celebrar.co não tem conhecimento do motivo da reprovação de crédito e de outros motivos da eventual reprovação. Caso ocorra a reprovação de sua compra por essas razões, por favor, entre em contato com a administradora do cartão utilizado no momento da compra para solucionar esse incidente.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Do mesmo modo, a Celebrar.co não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento do Usuário causado por falhas no sistema, no servidor ou na conexão decorrentes de condutas de terceiros, incluindo ações de softwares maliciosos como vírus, cavalo de troia, etc. que possam, de algum modo, danificar o equipamento ou conexão do Usuário em decorrência do acesso, utilização ou navegação no Plataforma da Celebrar.co, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos nele contidos.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item21">
                        <TermsPage.ListTitle> CONDIÇÕES COMERCIAIS  </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Somente poderão fazer uso e adquirir produtos divulgados no Plataforma da Celebrar.co pessoas físicas ou jurídicas capazes e em pleno exercício de seus direitos e obrigações, de acordo com a Lei Brasileira e demais regulamentações internacionais que possam ser aplicáveis com o respectivo cadastro e criação de conta de acesso.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Todos os preços e condições do Plataforma da Celebrar.co são válidos apenas para compras realizadas no www.celebrar.co e não se aplicam para lojas físicas de Fornecedores, assim como os preços e condições das lojas físicas não são válidos para o Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Ao concluir a formação do pedido, o Usuário receberá uma mensagem do Plataforma da Celebrar.co contendo os dados da compra na conta de e-mail fornecida no cadastro.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Os Fornecedores determinam seus fluxos e regras para a comercialização de seus produtos ou serviços conforme sua conveniência e sem qualquer interferência da Celebrar.co. Por isso, os Usuários devem conhecer e seguir tais procedimentos constantes dos Termos de Uso e demais documentos e descrições publicadas pelo Fornecedores Celebrar.co, disponibilizados antes da finalização da aquisição do produto ou serviço do Fornecedores no site da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Preço e Pagamento

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        O preço de qualquer produto ou serviço contratado será informado ao Usuário pela Celebrar.co via Plataforma da Celebrar.co ou via qualquer meio de correspondência digital (e-mail, aplicativos de mensagens etc.) após o preenchimento do formulário de cadastro na Celebrar.co.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Todos os preços dos serviços incluirão o Imposto Sobre Serviços de Qualquer Natureza (ISS) e outros eventuais impostos que possam vir a incidir sobre os serviços contratado, que serão de total responsabilidade e devidos pelo Usuário.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Para todos os efeitos, considera-se contratado o serviço a partir do aceite do Usuário via Plataforma da Celebrar.co ou via qualquer meio de correspondência digital (e- mail, aplicativos de mensagens etc.).
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Todos os pagamentos decorrentes da contratação de Serviços disponíveis no Plataforma da Celebrar.co só poderão ser pagos para a Celebrar.co ou a quem a Celebra.co indicar, independentemente da forma de pagamento realizada.
                                        
                                        <TermsPage.ListOL >
                                            <TermsPage.ListItem >
                                                Quaisquer pagamentos realizados diretamente ao Fornecedor, ou a qualquer terceiro, sem a expressa anuência da Celebrar.co por escrito, não será considerado como pagamento dos serviços contratados pelo Plataforma da Celebrar.co, isentando a Celebrar.co de qualquer responsabilidade sobre o(s) serviço(s) contratado(s).
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Caso ocorra atraso no pagamento devido pelo Comprador à Celebrar.co, o pagamento estará sujeito à incidência de juros de mora de 1% (um por cento) ao mês, pro rata die, mais multa de 2% (dois por cento).
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A Celebrar.co poderá, na hipótese de atraso ou inadimplência de qualquer dos valores devidos:

                                        <TermsPage.ListOL >
                                            <TermsPage.ListItem >
                                                (i) Protestar o boleto bancário em aberto; e
                                            </TermsPage.ListItem>

                                            <TermsPage.ListItem >
                                                (ii) Inscrever o nome do Comprador em cadastro de devedores, em órgãos de proteção ao crédito ou cartórios de protestos.
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Meios de pagamento e reembolso de valores

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        Caso o Usuário opte pelo boleto bancário e o pagamento não seja efetuado até a data do vencimento do título o pedido será automaticamente cancelado.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Se preferir pagar utilizando cartão de crédito, o Usuário será redirecionado para a plataforma de pagamentos online da Celebrar.co, que poderá ser da própria Celebrar.co, ou de empresa terceirizada de pagamentos online.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        O Plataforma da Celebrar.co se reserva ao direito de não trabalhar com cartões de crédito emitidos no exterior.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        O Usuário deve ficar atento a todos os detalhes do pagamento no momento da compra, pois, mesmo após o fechamento do pedido será possível à alteração da forma de pagamento caso o uso de Cartão de Crédito não tenha sido bem-sucedido e o Usuário deseje pagar de outro modo.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Destacamos que o Plataforma da Celebrar.co não solicita senha e dados de cartão de crédito aos seus Compradores. Excepcionalmente, quando houver o cancelamento ou arrependimento da compra e o pagamento se der por boleto bancário ou débito online, a Celebrar.co solicitará informações de dados bancários como nome do banco, agência e conta de seus Compradores por e-mail, para haver o devido ressarcimento. Portanto, pedimos que o Usuário que eventualmente receba um e-mail com conteúdo inapropriado ou duvidoso, não responda, desconsidere-o e se possível encaminhe-o para contato@celebrar.co
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Pagamento do Fornecedor

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        O pagamento pelos produtos e serviços oferecidos pelo FORNECEDOR na Plataforma da Celebrar serão realizados diretamente ao FORNECEDOR.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A CELEBRAR somente efetuará pagamentos por meio de transferência bancária ao FORNECEDOR.

                                        <TermsPage.ListOL >
                                            <TermsPage.ListItem >
                                                O FORNECEDOR fica terminantemente proibida de emitir boletos contra a CELEBRAR, ficando essa absolutamente obrigada a cancelar qualquer boleto porventura emitido e arcar com todo e qualquer custo decorrente deste cancelamento.
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        As Partes estabelecem que, em razão da prestação dos serviços de intermediação ora estipulado, a CELEBRAR receberá, a título de remuneração do serviço prestado, o valor equivalente a 10% (dez por cento) do preço de compra dos Produtos e/ou Serviços vendidos pelo FORNECEDOR ao Comprador por intermédio da CELEBRAR via Plataforma da Celebrar, aplicativo ou qualquer outro método a ser empregado pela CELEBRAR.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A CELEBRAR poderá, como forma de remuneração pelo serviço e intermediação, a qualquer momento, estabelecer um aumento no preço pelos serviços de Intermediação objeto deste Contrato, o qual será informado previamente ao FORNECEDOR, mediante de envio de comunicação por escrito, e será formalizado pelas partes o reajuste desse preço mediante a assinatura de termo aditivo.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Caso o FORNECEDOR não concorde com o preço estabelecido pela CELEBRAR nos termos do item 6.3, a CELEBRAR poderá rescindir o presente Contrato, sem qualquer ônus, mediante notificação por escrito a ser enviada com 10 dias de antecedência o FORNECEDOR.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Da entrega dos produtos e serviços

                                <TermsPage.ListOL >
                                    <TermsPage.ListItem >
                                        A Celebrar.co utiliza diversos serviços para a entrega de seus produtos, cujo tempo de transporte pode variar de acordo com a região do destinatário. Por isso, esteja atento com o status de seu pedido e se certifique que haverá alguém apto para receber a encomenda na data prevista, entre as 8h e 23h, ou na data e período selecionados, caso tenha optado pela entrega agendada.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        No caso da contratação de prestação de serviços oferecidos pela Celebrar.co ou por Fornecedor, estes serão entregues e executados de acordo com as condições, data e horário do seu evento, conforme apresentado pelo Comprador e acordado com o prestador de serviço, seja este a Celebrar.co ou Fornecedor.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Em caso de venda de produtos, caso a entrega não seja efetivada, haverá 02 (duas) tentativas posteriores, contudo se mesmo assim todas as tentativas de entrega restarem infrutíferas, o produto voltará à Celebrar.co ou ao Fornecedor, a depender de quem estiver vendendo os produtos, sendo que estes entrarão em contato com o Comprador para seguir com o reagendamento ou cancelamento da entrega.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        A Celebrar.co apenas entrega as encomendas transportadas no endereço indicado pelo Comprador, conforme descrito no pedido. Os entregadores não estão autorizados a instalar, montar, desmontar, mover, ligar, testar ou realizar qualquer tarefa que implique em manuseio dos produtos entregues, sob qualquer hipótese, exceto quando este serviço tenha sido previamente contratado pelo Comprador.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Caso seja constatado que o(s) produto(s) e/ou serviço(s) está(ão) em desacordo com o contratado, o Comprador deverá recusar o recebimento do pedido, registrar o motivo e comunicar a Celebrar.co sobre o ocorrido.
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Caso o produto ou serviço adquirido seja comercializado pelo Fornecedor, as condições de entrega deverão seguir as regras e procedimentos próprios do respectivo estabelecimento digital.
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Cancelamento de evento. Havendo a solicitação de cancelamento da prestação/entrega de qualquer/quaisquer produto(s) e/ou serviço(s) por parte o Comprador, aplicam-se as seguintes multas:

                                <TermsPage.ListOL className="alphabetical" >
                                    <TermsPage.ListItem >
                                        Cancelamentos até 15 dias úteis antes do evento: multa de 30% (trinta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamentos entre 15 e 5 dias úteis antes do evento: multa de 50% (cinquenta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamentos entre 5 e 3 dias úteis antes do evento: multa de 80% (oitenta por cento) sobre o valor total dos Serviços contratados;
                                    </TermsPage.ListItem>

                                    <TermsPage.ListItem >
                                        Cancelamentos entre 3 e 0 dias úteis antes do evento: multa de 100% (cem por cento) sobre o valor total dos Serviços contratados;

                                        <TermsPage.ListOL id="teste" >
                                            <TermsPage.ListItem >
                                                No evento de aplicação de quaisquer multas sobre o cancelamento, não serão emitidas notas fiscais e para o cancelamento de notas fiscais porventura emitidas, todos os custos para cancelamento e/ou reemissão de notas fiscais serão suportados integralmente pelo Comprador.
                                            </TermsPage.ListItem>
                                        </TermsPage.ListOL>
                                    </TermsPage.ListItem>
                                </TermsPage.ListOL>
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item22">
                        <TermsPage.ListTitle> PRODUTOS DE FORNECEDORES DIVULGADOS NO PLATAFORMA DA CELEBRAR.CO  </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Ao comprar um produto de qualquer um dos Fornecedores, você está ciente que pagamentos e eventuais estornos/ressarcimentos serão realizados exclusivamente através do Plataforma da Celebrar.co.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                O(s) produto(s) dos Fornecedores adquiridos só poderão ser devolvidos conforme a política de devolução correspondente. A receptividade dos produtos pelo Fornecedores deve ser verificada conforme publicada nos seus documentos que determinam a troca, entrega e devolução.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                O Usuário deverá verificar as informações de cado FORNECEDOR referentes à entrega dos produtos, política de retorno/trocas/devolução, informações de serviço ao consumidor, disponibilizados antes da finalização da aquisição do produto/serviço, ou ainda, disponibilizadas pelos Fornecedores. Assim, é esperado que o Usuário esteja ciente e de acordo com as regras de comércio e navegação de ambiente digital estabelecidas pelos Fornecedores quando concluir a aquisição de produto e/ou serviço.
                            </TermsPage.ListItem>                            
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item23">
                        <TermsPage.ListTitle> MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES  </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Os Termos e Condições de Uso do Plataforma da Celebrar.co estão sujeitos a constante melhoria e aprimoramento. Assim, a Celebrar.co se reserva o direito de modificar a qualquer momento, de forma unilateral, o presente Termos e Condições de Uso. Os novos Termos e Condições entrarão em vigor após 10 (dez) dias contados de sua publicação no Plataforma. Caso os Usuários e Compradores não estejam de acordo com as novas regras, deverá entrar em contato com os canais de atendimento da Celebrar.co, hipótese em que se considerará rescindido seu vínculo contratual com a Celebrar.co, devendo, no entanto, ser cumpridas todas as obrigações assumidas até o momento da efetiva rescisão.
                            </TermsPage.ListItem>  

                            <TermsPage.ListItem >
                                Ao navegar por este Plataforma da Celebrar.co, você aceita guiar-se pelos Termos e Condições de Uso do Plataforma da Celebrar.co que se encontram vigentes na data e, portanto, deve verificar os mesmos previamente cada vez que visitar este no Plataforma da Celebrar.co.
                            </TermsPage.ListItem>  
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>

                    <TermsPage.ListLI id="item24">
                        <TermsPage.ListTitle> DISPOSIÇÕES GERAIS  </TermsPage.ListTitle>

                        <TermsPage.ListOL >
                            <TermsPage.ListItem >
                                Ao aceitar o presente Contrato por qualquer meio de confirmação digital disponibilizado pela CELEBRAR, o Usuário declara estar absolutamente de acordo com todas as disposições do presente Contrato, tornando-se obrigado a cumprir o presente Contrato em sua integralidade, sob as penas da lei vigente e do presente Contrato.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                O não exercício de quaisquer direitos previstos neste Contrato, por qualquer das Partes, não poderá ser considerado precedente ou autorizador de modificação do que se encontra aqui pactuado, representando mera tolerância do titular de tal direito, que poderá exercê-lo quando julgar conveniente.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                O Usuário autoriza expressamente a Celebrar.co a comunicar-se com este através de todos os canais disponíveis, incluindo correio eletrônico (e-mail), celular, SMS, entre outros, ficando ressaltado que a principal via de informação para o Usuário é o Plataforma da Celebrar.co.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                Este Plataforma da Celebrar.co tem como base o horário oficial de Brasília.
                            </TermsPage.ListItem> 

                            <TermsPage.ListItem >
                                A Celebrar.co é empresa que respeita seu consumidor, disponibilizando a seus Compradores canais de Atendimento para tratamento eficiente de dúvidas, reclamações e sugestões, sempre em respeito ao Código de Defesa do Consumidor (Lei 8078/1990) e à legislação em vigor. Além disso, a Celebrar.co reafirma seu compromisso de cooperação com as autoridades e demais órgãos públicos, assegurando a seriedade no uso da Internet no Brasil e o fomento ao crescimento do Comércio Eletrônico.
                            </TermsPage.ListItem>

                            <TermsPage.ListItem >
                                Os Termos e Condições de Uso aqui descritos são regidos pela legislação da República Federativa do Brasil. Seu texto deverá ser interpretado no idioma português e os Usuários do Plataforma da Celebrar.co se submetem ao Foro do domicílio de seus consumidores.
                            </TermsPage.ListItem>
                        </TermsPage.ListOL>
                    </TermsPage.ListLI>
                </TermsPage.List>

                <p> Nossa tecnologia gera mais produtividade para quem compra e mais oportunidades para quem vende serviços. </p>
            </div>

            <Footer />
        </TermsPage >
    )
}

export default Terms;