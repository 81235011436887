import { useCallback, useEffect, useState } from 'react';

export function useSubmitInvoice({ contract_id }) {
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  function handleSelectFiles(event) {
    setFileErrorMessage('');
    const fileObj = Array.from(event.target.files)[0];
    if (!fileObj) {
      return;
    }
    if (fileObj.type !== 'text/xml' && fileObj.type !== 'application/pdf') {
      setFileErrorMessage('São aceitos apenas arquivos em PDF ou XML.');
    } else if (fileObj.size > 2000000) {
      setFileErrorMessage('O tamanho máximo para cada arquivo é 2 MB.');
    } else {
      setInvoiceFiles([...invoiceFiles, fileObj]);
    }
  }

  const handleSubmitInvoice = useCallback(() => {
    const form = document.getElementById('invoice-submit-form');
    const formData = new FormData(form);
    formData.delete('new_invoice');
    invoiceFiles.forEach(file => {
      formData.append('invoice[]', file);
    });

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/api/supplier/contracts/${contract_id}/submit-invoice`, true);
    xhr.onload = () => {
      if (xhr.status === 200) {
        setInvoiceFiles([]);
        setIsSubmitError(false);
        setIsSubmitting(false);
        setIsSubmitSuccess(true);
      } else {
        console.error(xhr.statusText);
        setIsSubmitError(true);
        setIsSubmitting(false);
      }
    };
    xhr.onerror = () => {
      console.error(xhr.statusText);
      setIsSubmitError(true);
      setIsSubmitting(false);
    };
    setIsSubmitting(true);
    xhr.send(formData);
  }, [invoiceFiles, contract_id]);

  useEffect(() => {
    if (invoiceFiles.length > 0) {
      handleSubmitInvoice();
    }
  }, [invoiceFiles, handleSubmitInvoice]);

  return {
    handleSelectFiles,
    handleSubmitInvoice,
    isSubmitting,
    isSubmitSuccess,
    setIsSubmitSuccess,
    fileErrorMessage,
    isSubmitError,
  };
}
