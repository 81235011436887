import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { TermsPage } from './styles';
import { Link } from 'react-router-dom';

require('dotenv').config();

function Terms() {
  return (
    <TermsPage>
      <Header bgBlack={true} />

      <div className="container" style={{ marginTop: '6rem' }}>
        <ul>
          <li style={{ textAlign: 'left', margin: '0.5rem 0' }}>
            <Link to="/termos/plataforma">TERMOS DE USO DA PLATAFORMA</Link>
          </li>
          <li style={{ textAlign: 'left', margin: '0.5rem 0' }}>
            <Link to="/termos/politica-privacidade">POLÍTICA DE PRIVACIDADE</Link>
          </li>
          <li style={{ textAlign: 'left', margin: '0.5rem 0' }}>
            <Link to="/termos/fornecedor">
              TERMOS DE USO E CONDIÇÕES GERAIS DE CONTRATAÇÃO FORNECEDORES
            </Link>
          </li>
          <li style={{ textAlign: 'left', margin: '0.5rem 0' }}>
            <Link to="/termos/comprador">TERMOS DE USO E CONDIÇÕES GERAIS DE COMPRA</Link>
          </li>
        </ul>
      </div>

      <Footer />
    </TermsPage>
  );
}

export default Terms;
