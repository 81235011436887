import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import NewContractCard from './NewContractCard';
import ConfirmedContractCard from './ConfirmedContractCard';
import OpportunityCard from './OpportunityCard';
import SupplierMenu from '../Menu';
import Modal from '../../../Components/Modal';
import SuccessAlert from '../../../Components/SuccessAlert';
import FeedbackChannel from '../FeedbackChannel';
import Tooltip from '../../../Components/Tooltip';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import AlertFixed from '../../../Components/Alert/AlertFixed.js';

// Styles & Images
import { SalesPanelPage } from './styles';
import { Load } from '../../../Components/Loader/styles';

function SupplierSalesPanel() {
  const [salesData, setSalesData] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [currentColumn, setCurrentColumn] = useState('new_alerts');
  const isMobileScreen = window.screen.width > 800 ? false : true;
  const modalRef = useRef();
  const { run, data } = useAsync();
  const { user } = useAuthContext();
  const history = useHistory();

  // Show success message if redirected after submitting proposal
  const { state } = useLocation();
  const successAlertRef = useRef(null);
  const showMsg = state?.showSuccessMessage;

  useEffect(() => {
    if (showMsg && successAlertRef.current) {
      successAlertRef.current.open();
      state.showSuccessMessage = false;
    }
  });

  const isRegisterComplete =
    user?.activated_at && new Date(user.activated_at) > new Date('2022-06-01');

  useEffect(() => {
    run(
      axios({
        method: 'get',
        url: `/api/usuario/painel-de-vendas`,
        headers: {
          Accept: 'application/json',
        },
      })
        .then(res => res.data)
        .catch(err => console.log(err))
    );
  }, [run]);

  useEffect(() => setSalesData(data), [data]);

  function handleShowModal(content) {
    setModalContent(content);
    modalRef.current.open();
  }

  const [filterByState, setFilterByState] = useState(true);

  if (!salesData) {
    return (
      <SalesPanelPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SalesPanelPage>
    );
  }

  const { new_alerts, in_progress_alerts, new_contracts, ready_contracts } = salesData;
  const filteredNewAlerts =
  user.company_state && filterByState
    ? new_alerts.filter(alert => {
        if (alert.state) {
          return alert.state === user.company_state;
        } else {
          return user.company_state === 'SP';
        }
      })
    : new_alerts;

  return (
    <SalesPanelPage>
      <SupplierMenu />
      <FeedbackChannel />
      <Modal modalSize="large" title="Vendas" ref={modalRef} children={modalContent} />
      <SalesPanelPage.ColumnHeader>
        <SalesPanelPage.ColumnHeaderItem
          className={isMobileScreen ? (currentColumn === 'new_alerts' ? 'active' : '') : ''}
          onClick={() => isMobileScreen && setCurrentColumn('new_alerts')}
        >
          <h3>
            Oportunidades
            <Tooltip position="right">
              As oportunidades de venda para as suas categorias apracerão nessa coluna
            </Tooltip>
          </h3>

        <p className="txt--white"> {filteredNewAlerts.length} </p>
        </SalesPanelPage.ColumnHeaderItem>

        <SalesPanelPage.ColumnHeaderItem
          className={isMobileScreen ? (currentColumn === 'in_progress_alerts' ? 'active' : '') : ''}
          onClick={() => isMobileScreen && setCurrentColumn('in_progress_alerts')}
        >
          <h3>
            Andamento
            <Tooltip position="right">
              {' '}
              Após enviar um orçamento, a oportunidade de venda será movida para esta coluna{' '}
            </Tooltip>
          </h3>
          <p className="txt--white"> {in_progress_alerts.length} </p>
        </SalesPanelPage.ColumnHeaderItem>

        <SalesPanelPage.ColumnHeaderItem
          className={isMobileScreen ? (currentColumn === 'new_contracts' ? 'active' : '') : ''}
          onClick={() => isMobileScreen && setCurrentColumn('new_contracts')}
        >
          <h3>
            Vendas
            <Tooltip position="right">
              {' '}
              Quando uma confirmação de venda for recebida, ela aparecerá nesta coluna para o envio
              da nota fiscal{' '}
            </Tooltip>
          </h3>
          <p className="txt--white"> {new_contracts.length} </p>
        </SalesPanelPage.ColumnHeaderItem>

        <SalesPanelPage.ColumnHeaderItem
          className={isMobileScreen ? (currentColumn === 'ready_contracts' ? 'active' : '') : ''}
          onClick={() => isMobileScreen && setCurrentColumn('ready_contracts')}
        >
          <h3>
            Eventos
            <Tooltip position="right">
              {' '}
              Após confirmação da venda e aprovação da sua nota fiscal, os eventos aparecerão nesta
              coluna{' '}
            </Tooltip>
          </h3>
          <p className="txt--white"> {ready_contracts.length} </p>
        </SalesPanelPage.ColumnHeaderItem>
      </SalesPanelPage.ColumnHeader>
      <SalesPanelPage.Grid>
        <SalesPanelPage.Column
          className={isMobileScreen ? (currentColumn === 'new_alerts' ? '' : 'hidden') : ''}
        >
          <SalesPanelPage.ColumnTitle>
            <h3> Oportunidades </h3>
            <p className="txt--secondary">{filteredNewAlerts.length}</p>
          </SalesPanelPage.ColumnTitle>
          {user?.company_state ? (
            <SalesPanelPage.StateCheckbox onClick={() => setFilterByState(!filterByState)}>
              <input type="checkbox" className="checkbox" readOnly checked={filterByState} />
              <p>Ver apenas para o estado de {user.company_state}</p>
            </SalesPanelPage.StateCheckbox>
          ) : null}

          {filteredNewAlerts.length === 0 ? (
            <SalesPanelPage.ColumnText>
              <p>Não há nenhuma nova oportunidade de venda para as suas categorias no momento</p>
              <p>
                Caso queira alterar suas categorias,{' '}
                <Link to="/fornecedor/minhas-categorias">clique aqui</Link>
              </p>
            </SalesPanelPage.ColumnText>
          ) : null}

          {filteredNewAlerts.map(data => {
            return <OpportunityCard data={data} key={data.id} />;
          })}
        </SalesPanelPage.Column>

        <SalesPanelPage.Column
          className={isMobileScreen ? (currentColumn === 'in_progress_alerts' ? '' : 'hidden') : ''}
        >
          <SalesPanelPage.ColumnTitle>
            <h3> Andamento </h3>
            <p className="txt--secondary">{in_progress_alerts.length}</p>
          </SalesPanelPage.ColumnTitle>

          {in_progress_alerts.length === 0 ? (
            <SalesPanelPage.ColumnText>
              <p>Você não possui nenhum orçamento em andamento</p>
              <p>Após enviar um orçamento, a oportunidade de venda será movida para esta coluna</p>
            </SalesPanelPage.ColumnText>
          ) : null}

          {in_progress_alerts.map(data => {
            return <OpportunityCard data={data} key={data.id} />;
          })}
        </SalesPanelPage.Column>

        <SalesPanelPage.Column
          className={isMobileScreen ? (currentColumn === 'new_contracts' ? '' : 'hidden') : ''}
        >
          <SalesPanelPage.ColumnTitle>
            <h3> Vendas </h3>
            <p className="txt--secondary">{new_contracts.length}</p>
          </SalesPanelPage.ColumnTitle>

          {new_contracts.length === 0 ? (
            <SalesPanelPage.ColumnText>
              <p>Você não possui confirmações de venda no momento</p>
              <p>
                Quando uma confirmação de venda for recebida, ela aparecerá nesta coluna para o
                envio da nota fiscal
              </p>
            </SalesPanelPage.ColumnText>
          ) : null}

          {new_contracts.map(data => {
            return <NewContractCard data={data} key={data.id} />;
          })}
        </SalesPanelPage.Column>

        <SalesPanelPage.Column
          className={isMobileScreen ? (currentColumn === 'ready_contracts' ? '' : 'hidden') : ''}
        >
          <SalesPanelPage.ColumnTitle>
            <h3> Eventos </h3>
            <p className="txt--secondary">{ready_contracts.length}</p>
          </SalesPanelPage.ColumnTitle>

          {ready_contracts.length === 0 ? (
            <SalesPanelPage.ColumnText>
              <p>Não há eventos confirmados</p>
              <p>
                Após confirmação da venda e aprovação da sua nota fiscal, os eventos aparecerão
                nesta coluna
              </p>
            </SalesPanelPage.ColumnText>
          ) : null}
          {ready_contracts.map(data => {
            return (
              <ConfirmedContractCard data={data} handleShowModal={handleShowModal} key={data.id} />
            );
          })}
        </SalesPanelPage.Column>
      </SalesPanelPage.Grid>
      {!isRegisterComplete ? (
        <AlertFixed
          title="Complete o seu cadastro"
          content="Complete seu cadastro para ter acesso total e começar a vender"
          btnTxt="Completar cadastro"
          btnOnClick={() => history.push('/fornecedor/finalizar-cadastro')}
        />
      ) : null}
      <SuccessAlert ref={successAlertRef}>
        <p>{showMsg}</p>
      </SuccessAlert>
    </SalesPanelPage>
  );
}

export default SupplierSalesPanel;
