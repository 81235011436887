import React, { useState } from 'react';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Link } from 'react-router-dom';
// Styles & Images
import LogoWhite from '../../../assets/images/logos/celebrar/celebrar.svg';
import profileImageStub from '../../../assets/icons/user-profile-image.svg';
import { Menu } from './styles';
import menu from '../../../assets/icons/menu-icon.svg';
import closeMenu from '../../../assets/icons/menu-close-icon.svg';
import Logo from '../../../assets/images/logos/celebrar/celebrar.png';

function SupplierMenu() {
  const { user, signOut } = useAuthContext();
  const path = window.location.pathname;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleLogout(event) {
    event.preventDefault();
    signOut();
  }

  function setMenuActived(linkPath) {
    return path.includes(linkPath);
  }

  return (
    <Menu>
      <div className="">
        <Menu.Links>
          <Link to="/">
            <img src={Logo} alt="Celebrar" className='logo' />
          </Link>

          <Menu.MobileIcon
            src={!isMenuOpen ? menu : closeMenu}
            alt="menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={isMenuOpen ? 'open' : undefined}
          />

          <ul className={isMenuOpen ? 'open' : undefined}>
            {user?.is_activated ? (
              <>
                <li>
                  <Link
                    to="/fornecedor/painel-de-vendas"
                    className={setMenuActived('painel-de-vendas') ? 'active' : undefined}
                  >
                    Painel de vendas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fornecedor/historico-orcamentos"
                    className={setMenuActived('historico') ? 'active' : undefined}
                  >
                    Orçamentos enviados
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fornecedor/confirmacoes-de-venda"
                    className={setMenuActived('confirmacoes-de-venda') ? 'active' : undefined}
                  >
                    Vendas confirmadas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fornecedor/carteira"
                    className={setMenuActived('carteira') ? 'active' : undefined}
                  >
                    Carteira
                  </Link>
                </li>
              </>
            ) : null}
            <li className="dropdown dropdown--arrow">
              <Link to="#">
                <span className="truncate">
                  {user && (user.name || user.company_name || 'Complete seu cadastro')}
                </span>
                
                <figure>
                  <img src={user?.avatar_url || profileImageStub} alt="" />
                </figure>
              </Link>
              <div>
                <Link to="/fornecedor/minha-empresa">Minha empresa</Link>
                <Link to="#" onClick={event => handleLogout(event)}>
                  Sair
                </Link>
              </div>
            </li>
          </ul>
        </Menu.Links>
      </div>
    </Menu>
  );
}

export default SupplierMenu;
