import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, Link, useHistory, BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { Load } from '../../../Components/Loader/styles';
import { useAuthContext } from '../../../Contexts/AuthContext';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import { useAlertData } from './hooks/useAlertData';
import { OfferItem } from '../../../Components/Offer/OfferItem';
import Alert from '../../../Components/Alert';
import { ButtonBack } from '../../../Components/common/Buttons/ButtonBack';
import Modal from '../../../Components/Modal';
// Styles & Images
import { SupplierPage } from '../styles';
import { OfferPage } from './styles';
import { Close, Image, Plus } from '../../../Components/common/Icons/IconsFamily';
import { Item } from '../../../Components/Offer/styles';
import { Button } from '../../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../../Components/common/Buttons/ButtonIconOnly';
import Image404 from '../../../assets/images/404_image.svg';
import { OfferPreview } from './offerPreview';

export default function SupplierOffer() {
  const { handle: alertId } = useParams();
  const { user } = useAuthContext();

  const {
    alertName,
    isLoadingAlertData,
    isDataEmpty,
    submittedOffersCount,
    deliveryDate,
    alertQuantity,
    alertPriceMethod,
    alertCategories,
  } = useAlertData(alertId);

  // Refs
  const submitAlert = useRef();
  const submitErrorAlert = useRef();
  const infoItemError = useRef();
  const previewOfferModalRef = useRef();

  // Items
  const [itemsCount, setItemsCount] = useState(1);
  const [offerItems, setOfferItems] = useState([{ label: 'items0', position: 0 }]);
  const [isLoadingItemchanges, setisLoadingItemchanges] = useState(false);

  const addNewItem = useCallback(() => {
    setOfferItems(offerItems => [
      ...offerItems,
      { label: `items${itemsCount}`, position: offerItems.length },
    ]);
    setItemsCount(itemsCount => itemsCount + 1);
  }, [itemsCount]);

  function handleRemoveItem(position) {
    setOfferItems(offerItems =>
      offerItems
        .filter(item => item.position !== position)
        .map(item => {
          if (item.position > position) {
            return { ...item, position: item.position - 1 };
          }
          return { ...item };
        })
    );
  }

  useEffect(() => {
    if (offerItems.length === 0) {
      addNewItem();
    }

    setisLoadingItemchanges(true);
    window.scrollTo(0, 300);
  }, [offerItems, addNewItem]);

  function handleMoveUp(position) {
    if (position !== 0) {
      const itemIndex = offerItems.findIndex(i => i.position === position);
      const itemAboveIndex = offerItems.findIndex(i => i.position === position - 1);

      const updatedOfferItems = [...offerItems];
      updatedOfferItems[itemIndex].position = position - 1;
      updatedOfferItems[itemAboveIndex].position = position;
      setOfferItems(updatedOfferItems);
    }
  }

  function handleMoveDown(position) {
    if (position !== offerItems.length - 1) {
      const itemIndex = offerItems.findIndex(i => i.position === position);
      const itemBelowIndex = offerItems.findIndex(i => i.position === position + 1);

      const updatedOfferItems = [...offerItems];
      updatedOfferItems[itemIndex].position = position + 1;
      updatedOfferItems[itemBelowIndex].position = position;
      setOfferItems(updatedOfferItems);
    }
  }

  useEffect(() => {
    if (isLoadingItemchanges) setTimeout(() => setisLoadingItemchanges(false), 500);
  }, [isLoadingItemchanges]);

  // ----- Files ------
  const [files, setFiles] = useState([{ label: 'files0' }]);
  const fileErrorAlertRef = useRef();

  function addFile(event) {
    const fileObj = event.target.files[0];
    if (!fileObj) {
      return;
    }
    if (fileObj.size > 2000000) {
      fileErrorAlertRef.current.open();
    } else {
      const newFiles = [...files];
      newFiles[files.length - 1].file = fileObj;
      newFiles.push({ label: `files${files.length}` });
      setFiles(newFiles);
    }
  }

  function removeFile(index) {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles.map(({ file }, index) => ({ file, label: `files${index}` })));
    const inputElement = document.getElementById(`files${index}`);
    inputElement.value = '';
  }

  // ----- Submit offer ------
  const [missingFields, setMissingFields] = useState([]);

  function handleSubmitOffer(event) {
    event.preventDefault();
    setMissingFields([]);

    const formData = new FormData(event.target);

    const missingFields = [];

    offerItems.forEach(({ label }) => {
      const itemName = formData.get(`${label}-name`);
      const itemDescription = formData.get(`${label}-description`);
      const unitPrice = formData.get(`${label}-unit-price`);
      const categoryId = formData.get(`${label}-category-id`);

      if (!itemName) {
        missingFields.push(`${label}-name`);
      }
      if (!itemDescription) {
        missingFields.push(`${label}-description`);
      }
      if (!unitPrice) {
        missingFields.push(`${label}-unit-price`);
      }
      if (!categoryId && alertCategories.length > 1) {
        missingFields.push(`${label}-category-id`);
      }
    });

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      infoItemError.current.open();
      return;
    }

    formData.append('alert_id', alertId);
    formData.append('delivery_estimated_at', deliveryDate);

    if (expirationDate) {
      formData.append('expires_at', expirationDate);
    }

    axios({
      method: 'post',
      url: '/api/supplier/offer',
      data: formData,
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    })
      .then(() => {
        submitAlert.current.open();
        setTimeout(() => {
          window.history.back();
        }, 3000);
      })
      .catch(() => {
        submitErrorAlert.current.open();
      });
  }

  useEffect(() => {
    if (missingFields.length > 0) {
      document.querySelector('.error').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [missingFields]);

  // ----- Offer expiration date ------
  const [expirationDate, setExpirationDate] = useState();
  const expirationDateModalRef = useRef('');

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  function formatDate(date, isMonth) {
    let dateFormated = date.split('/').reverse().join('-');
    return getDate(`${dateFormated} 00:00:00`, isMonth);
  }

  function getDate(date, isMonth) {
    return isMonth ? monthNames[new Date(date).getMonth()] : new Date(date).getDate();
  }

  if (isLoadingAlertData) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  if (isDataEmpty || isDataEmpty === null) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <OfferPage.Expired>
          <img src={Image404} alt="404" />
          <h1>Ops... Essa oportunidade já expirou ou não existe</h1>
          <Link to="/fornecedor/painel-de-vendas">
            <Button>Ir para o painel de vendas</Button>
          </Link>
        </OfferPage.Expired>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <OfferPage.Header>
        <ButtonBack link="/fornecedor/painel-de-vendas" />

        <div className="container">
          <p>
            {submittedOffersCount === 0
              ? 'Você ainda não enviou nenhum orçamento para essa demanda'
              : submittedOffersCount === 1
              ? 'Você já enviou 1 orçamento para essa demanda'
              : `Você já enviou ${submittedOffersCount} orçamentos para essa demanda`}
          </p>

          <h1>{alertName}</h1>
          <Link to={`/fornecedor/oportunidade/${alertId}`} target="_blank">
            Ver detalhes do pedido
          </Link>
        </div>
      </OfferPage.Header>

      <OfferPage.Content>
        <div className="container">
          <OfferPage.Body>
            <OfferPage.OfferExpiration onClick={() => expirationDateModalRef.current.open()}>
              <OfferPage.OfferExpirationBox>
                <span> Vencimento: </span>
                {expirationDate ? (
                  <>
                    <h5> {formatDate(expirationDate)} </h5>
                    <p>de {formatDate(expirationDate, true)}</p>
                  </>
                ) : (
                  <h5>- - -</h5>
                )}
              </OfferPage.OfferExpirationBox>

              <OfferPage.OfferExpirationText>
                Data de vencimento <br /> do seu orçamento
              </OfferPage.OfferExpirationText>
            </OfferPage.OfferExpiration>

            <OfferPage.Supplier>
              <figure>
                {user?.avatar_url ? (
                  <img style={{ width: '100%' }} src={user?.avatar_url} alt={user?.company_name} />
                ) : (
                  <Image />
                )}
              </figure>
              <p>{user?.company_name}</p>
            </OfferPage.Supplier>

            <form onSubmit={handleSubmitOffer}>
              {isLoadingItemchanges && (
                <Load.FullPageContainer>
                  <Load.SmallIcon />
                </Load.FullPageContainer>
              )}

              <OfferPage.ItemsList className={isLoadingItemchanges ? 'hidden' : ''}>
                {offerItems
                  .sort((a, b) => a.position - b.position)
                  .map(item => (
                    <OfferItem
                      key={item.label}
                      item={item}
                      handleMoveUp={handleMoveUp}
                      handleMoveDown={handleMoveDown}
                      handleRemoveItem={handleRemoveItem}
                      isControlBarEnable={true}
                      missingFields={missingFields}
                      alertPriceMethod={alertPriceMethod}
                      alertQuantity={alertQuantity}
                      alertCategories={alertCategories}
                    />
                  ))}
              </OfferPage.ItemsList>

              <Item.AddItemButton type="button" onClick={addNewItem}>
                <Plus /> Adicionar novo item
              </Item.AddItemButton>

              <OfferPage.Files>
                <h3>Arquivos (Opcional)</h3>
                <p className="margin--bottom-2">
                  Você pode adicionar aquivos em PDF caso queira para complementar o seu orçamento.
                  Lembre-se que o orçamento só é considerado se houver itens nele, caso você inclua
                  somente o arquivo em PDF seu orçamento não será considerado pelos nosso
                  especialistas em eventos.
                </p>

                {files?.length > 1 &&
                  files
                    .filter(x => x.file)
                    .map(({ file }, index) => (
                      <li key={index}>
                        <a href={file.path} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <ButtonIconOnly type="button" onClick={() => removeFile(index)}>
                          <Close />
                        </ButtonIconOnly>
                      </li>
                    ))}

                <label
                  htmlFor={`files${files.length - 1}`}
                  className="btn btn--secondary btn--center"
                >
                  <span>Adicionar arquivo</span>
                </label>
              </OfferPage.Files>

              {files.map(({ label, file }) => {
                return (
                  <input
                    type="file"
                    accept=".doc,.docx,.pdf,.xls,.xlsx,.txt"
                    key={label}
                    name={file ? label : ''}
                    id={label}
                    style={{ display: 'none' }}
                    onChange={addFile}
                  />
                );
              })}

              <Item.SendOfferButton type="submit">Enviar orçamento</Item.SendOfferButton>
            </form>
          </OfferPage.Body>
        </div>
      </OfferPage.Content>

      {/* Preview offer modal */}
      <Modal ref={previewOfferModalRef} modalSize="extra_large" title="Pré-visualizar o orçamento">
        <OfferPreview
          user={user}
          offerItems={offerItems}
          files={files}
          expirationDate={expirationDate}
          formatDate={formatDate}
        />
      </Modal>

      {/* Expiration date modal */}
      <Modal title="Escolha a data de vencimento desse orçamento" ref={expirationDateModalRef}>
        <p className="margin--bottom-2">
          Você pode escolher uma data de vencimento para este orçamento, ao fazer isso o seu
          orçamento poderá ser vendido somente até a data que você escolheu, após essa data será
          necessário atualizá-lo para vende-lo novamente.
        </p>
        <div className="input__field">
          <label htmlFor="expiration_date"> Data de vencimento</label>
          <input
            type="date"
            name="expiration_date"
            id="expiration_date"
            className="input"
            onChange={event => setExpirationDate(event.target.value)}
            value={expirationDate}
          />

          <Button type="button" onClick={() => expirationDateModalRef.current.close()}>
            Concluído
          </Button>
        </div>
      </Modal>

      {/* Sucess send offer alert */}
      <Alert ref={submitAlert} status="sucess">
        <p>Orçamento enviado com sucesso!</p>
      </Alert>

      {/* Error send offer alert */}
      <Alert ref={submitErrorAlert} status="danger">
        <p>Ocorreu um erro no envio do orçamento. Tente novamente mais tarde.</p>
      </Alert>

      {/* Warning file size alert */}
      <Alert ref={fileErrorAlertRef} status="warning">
        <p>Ops, você escolheu um arquivo maior que 2Mbs.</p>
      </Alert>

      {/* Error item infos */}
      <Alert ref={infoItemError} status="danger">
        <p>
          Você deve preencher os campos: Nome do item, descrição e preço de todos os itens do
          orçamento
        </p>
      </Alert>
    </SupplierPage>
  );
}
