import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

// General
import Home from './Pages/Home';
import About from './Pages/About';
import Error from './Pages/Error';
// Terms
import TermsIndex from './Pages/Terms';
import SupplierTerms from './Pages/Terms/SupplierTerms';
import BuyerTerms from './Pages/Terms/BuyerTerms';
import SiteTerms from './Pages/Terms/SiteTerms';
import PrivacyTerms from './Pages/Terms/PrivacyTerms';
import Terms2021 from './Pages/Terms/Terms2021';
// Proposal
import Proposal from './Pages/Proposal';
import OldProposal from './Pages/Proposal/OldProposal';
import Box from './Pages/Box';
import BoxShoppingCart from './Pages/BoxShoppingCart';
import ShoppingCartProposal from './Pages/ShoppingCartProposal';
import ProposalApproved from './Pages/Proposal/ProposalApproved';
import OldProposalApproved from './Pages/Proposal/OldProposal/ProposalApproved';

// Buyer
import BuyerHome from './Pages/Buyer/BuyerHome';
import BuyerUserAccount from './Pages/Buyer/UserAccount';
import NewDemand from './Pages/Buyer/NewDemand/oldIndex';
import NewDemandV2 from './Pages/Buyer/NewDemand/index';
import DemandSucess from './Pages/Buyer/NewDemand/demandSucess';
// Supplier - Account
import SupplierUserAccount from './Pages/Supplier/UserAccount';
import { UserContacts } from './Pages/Supplier/UserAccount/UserContacts';
import { UserAddress } from './Pages/Supplier/UserAccount/UserAddress';
import { UserCategories } from './Pages/Supplier/UserAccount/UserCategories';
import { UserBankAccount } from './Pages/Supplier/UserAccount/UserBankAccount';
import SupplierConfirmTerms from './Pages/Supplier/Account/ConfirmTerms';
import SupplierNPS from './Pages/Supplier/SupplierNPS';
import Wallet from './Pages/Supplier/Wallet';
// Supplier - Alert
import SupplierSalesPanel from './Pages/Supplier/SalesPanel';
import SupplierAlertDetails from './Pages/Supplier/Alerts/AlertDetails';
import SupplierInvoices from './Pages/Supplier/SupplierInvoices';
import SupplierContractInvoices from './Pages/Supplier/SupplierInvoices/ContractInvoices';

// Supplier - Offer
import SupplierOffer from './Pages/Supplier/Offer';
import SupplierSubmittedOffers from './Pages/Supplier/SubmittedOffers';
import SupplierSubmittedOfferDetails from './Pages/Supplier/SubmittedOffers/SubmittedOfferDetails';
import SupplierSubmittedAlertProposalWithoutOffer from './Pages/Supplier/SubmittedOffers/SubmittedAlertProposalWithoutOffer';

// Auth
import ResetPassword from './Pages/Auth/Password/ResetPassword';
import ResetEmail from './Pages/Auth/Password/ResetEmail';
import Login from './Pages/Auth/Login';
import RegisterAccountType from './Pages/Auth/RegisterAccountType';
import RegisterUser from './Pages/Auth/RegisterUser';
import RegisterSupplier from './Pages/Auth/RegisterSupplier';
import RegisterBuyer from './Pages/Auth/RegisterBuyer';
// Escale
import Escale from './Pages/Escale';
import EscaleForm from './Pages/Escale/EscaleForm';
// Learning Village
import LearningVillageStore from './Pages/LearningVillage';
import LearningVillageCheckout from './Pages/LearningVillage/LearningVillageCheckout';
import { useAuthContext } from './Contexts/AuthContext';
// Avaliações
import Reviews from './Pages/Reviews';
import ExternalReview from './Pages/Reviews/ExternalReview';
import { SupplierCategoriesSelection } from './Pages/Supplier/CategoriesSelection';
import { SupplierCompleteRegistration } from './Pages/Supplier/CompleteRegistration';
import { RegistrationFinished } from './Pages/Supplier/CompleteRegistration/RegistrationFinished';
import {FaqBuyer} from './Pages/Faq/FaqBuyer';
import {FaqProviderPlatform} from './Pages/Faq/FaqProviderPlatform';
import {FaqProviderAcount} from './Pages/Faq/FaqProviderAcount';
import {FaqProviderBudgets} from './Pages/Faq/FaqProviderBudgets';
import {FaqProviderPayments} from './Pages/Faq/FaqProviderPayments';
import {FaqProviderNF} from './Pages/Faq/FaqProviderNF';




import FaqProvider from './Pages/Faq/FaqProvider';

function Routes() {
  useEffect(() => {
    ReactGA.initialize('UA-118284558-4');
  });

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/quem-somos" exact component={About} />

      {/* Terms */}
      <Route path="/termos" exact component={TermsIndex} />
      <Route path="/termos/plataforma" exact component={SiteTerms} />
      <Route path="/termos/politica-privacidade" exact component={PrivacyTerms} />
      <Route path="/termos/fornecedor" exact component={SupplierTerms} />
      <Route path="/termos/comprador" exact component={BuyerTerms} />
      <Route path="/termos-2021" exact component={Terms2021} />

      {/* Proposals */}
      <Route path="/proposta/v1/:handle" exact component={OldProposal} />
      <Route path="/proposta/:handle" exact component={Proposal} />
      <Route path="/box/:handle/" exact component={Box} />
      <Route path="/box/:handle/:id" exact component={Box} />
      <Route path="/eventos/:handle/" exact component={Box} />
      <Route path="/eventos/:handle/:id" exact component={Box} />
      <Route path="/carrinho-proposta" exact component={ShoppingCartProposal} />
      <Route path="/carrinho" exact component={BoxShoppingCart} />
      <Route path="/proposta-aprovada" exact component={ProposalApproved} />
      <Route path="/confirmacao-proposta" exact component={OldProposalApproved} />

      {/* Auth */}
      <Route path="/login" exact component={Login} />
      <Route path="/cadastro" exact component={RegisterUser} />
      <Route path="/cadastro-comprador" exact component={RegisterBuyer} />
      <Route path="/cadastro-fornecedor" exact component={RegisterSupplier} />
      <PrivateRoute path="/cadastro-tipo-de-conta" exact component={RegisterAccountType} />
      <Route path="/recuperar-senha" exact component={ResetEmail} />
      <Route path="/redefinir-senha/:handle" exact component={ResetPassword} />

      {/* Buyer */}
      <PrivateRoute path="/painel-comprador" exact component={BuyerHome} />
      <PrivateRoute path="/comprador/minha-conta" exact component={BuyerUserAccount} />
      {/* <Route path="/comprador/pedido" exact component={NewDemand} /> */}
      <Route path="/comprador/pedido" exact component={NewDemandV2} />
      <Route path="/briefing-enviado" exact component={DemandSucess} />

      {/* Supplier */}
      <PrivateRoute
        path="/fornecedor/cadastro/categorias"
        component={SupplierCategoriesSelection}
      />
      <PrivateRoute
        path="/fornecedor/finalizar-cadastro"
        component={SupplierCompleteRegistration}
      />
      <PrivateRoute path="/fornecedor/cadastro-finalizado" component={RegistrationFinished} />
      <PrivateRoute path="/fornecedor/minha-empresa" exact component={SupplierUserAccount} />
      <PrivateRoute path="/fornecedor/meus-contatos" exact component={UserContacts} />
      <PrivateRoute path="/fornecedor/meu-endereco" exact component={UserAddress} />
      <PrivateRoute path="/fornecedor/minhas-categorias" exact component={UserCategories} />
      <PrivateRoute path="/fornecedor/meus-dados-bancarios" exact component={UserBankAccount} />
      <PrivateRoute path="/fornecedor/atualizar-termos" exact component={SupplierConfirmTerms} />
      <PrivateRoute path="/fornecedor/carteira" exact component={Wallet} />

      <PrivateRoute
        path="/fornecedor/historico-orcamentos"
        exact
        component={SupplierSubmittedOffers}
      />
      <PrivateRoute
        path="/fornecedor/historico-orcamentos/alert-proposal/:alertProposalId"
        exact
        component={SupplierSubmittedAlertProposalWithoutOffer}
      />
      <PrivateRoute
        path="/fornecedor/historico-orcamentos/:offerId"
        exact
        component={SupplierSubmittedOfferDetails}
      />

      {/* Painel de vendas */}
      <PrivateRoute
        path="/fornecedor/alertas"
        exact
        component={() => <Redirect to="/fornecedor/painel-de-vendas" />}
      />
      <PrivateRoute path="/fornecedor/painel-de-vendas" exact component={SupplierSalesPanel} />

      <PrivateRoute
        path="/fornecedor/alerta-detalhes/:handle"
        exact
        component={SupplierAlertDetails}
      />
      <PrivateRoute
        path="/fornecedor/oportunidade/:handle"
        exact
        component={SupplierAlertDetails}
      />
      <Route path="/fornecedor/enviar-orcamento/:handle" exact component={SupplierOffer} />
      <PrivateRoute path="/fornecedor/confirmacoes-de-venda" exact component={SupplierInvoices} />
      <PrivateRoute
        path="/fornecedor/confirmacoes-de-venda/:id"
        exact
        component={SupplierContractInvoices}
      />

      {/* FAQ */}
      <Route path="/faq/comprador" exact component={FaqBuyer} />
      <Route path="/faq/fornecedor/home" exact component={FaqProvider} />
      <Route path="/faq/fornecedor/plataforma" exact component={FaqProviderPlatform} />
      <Route path="/faq/fornecedor/conta" exact component={FaqProviderAcount} />
      <Route path="/faq/fornecedor/orcamentos" exact component={FaqProviderBudgets} />
      <Route path="/faq/fornecedor/pagamentos" exact component={FaqProviderPayments} />
      <Route path="/faq/fornecedor/nota-fiscal" exact component={FaqProviderNF} />
      {/* NPS */}
      <Route path="/fornecedor/nps-feedback/:handle/" exact component={SupplierNPS} />
      <Route path="/fornecedor/nps-feedback/:handle/:id" exact component={SupplierNPS} />

      {/* Escale */}
      <Route path="/escale" exact component={Escale} />
      <Route path="/escale-checkout" exact component={EscaleForm} />

      {/* Learning Village */}
      <Route path="/lv" exact component={LearningVillageStore} />
      <Route path="/lv-checkout" exact component={LearningVillageCheckout} />

      {/* Avaliações */}
      <Route path="/avaliacao/:reqId" exact component={Reviews} />
      <Route path="/avaliacao-ext/:reqId" exact component={ExternalReview} />

      {/* General */}
      <Route path="/404" exact component={Error} />
      <Route path="*" component={Error} />
    </Switch>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  let { user } = useAuthContext();

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default Routes;
