import React, { useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Register } from './styles';
import { Button } from '../../../Components/common/Buttons/Button';
import { FormEditableInput } from '../../../Components/common/Forms/FormEditableInput';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Load } from '../../../Components/Loader/styles';
import Header from '../../../Components/Header';

function Login() {
  const history = useHistory();
  const { run, isLoading } = useAsync();
  const { signIn } = useAuthContext();

  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmitLogin(e) {
    e.preventDefault();

    const errors = {};
    if (!email.trim()) {
      errors.email = 'Digite seu e-mail';
    }
    if (!password.trim()) {
      errors.password = 'Digite a senha';
    }
    setErrors(errors);
    if (errors.email || errors.password) {
      return;
    }

    run(
      axios.post('/api/auth/signin', { email, password }).then(res => {
        signIn({ email, password });
      })
    ).catch(error => {
      if (error.response?.status === 500) {
        setErrors({ login: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.' });
      } else {
        setErrors({ login: error.response.data?.message });
      }
    });
  }

  return (
    <Register>
      <Header buyerPage hideButtons />

      <Register.Container>
        <div className="container">
          <Register.Layout>
            <Register.Heading>
              <h1>
                Acessar
                <br />
                minha conta
              </h1>
              <p>Caso ainda não possua conta, cadastre-se gratuitamente</p>
              <Link to="/cadastro">
                <Button variant="secondary">Criar nova conta</Button>
              </Link>
            </Register.Heading>
            <Register.Form onSubmit={handleSubmitLogin}>
              <label>
                E-mail
                <FormEditableInput
                  type="email"
                  name="email"
                  className={errors.email ? 'form-error' : undefined}
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <p className="field-error-message">{errors.email}</p>
              </label>
              <label>
                Senha
                <FormEditableInput
                  type="password"
                  name="password"
                  className={errors.password ? 'form-error' : undefined}
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <p className="field-error-message">{errors.password}</p>
              </label>

              {errors.login ? (
                <p style={{ margin: '1rem 0', color: 'var(--color-red)', fontWeight: 700 }}>
                  {errors.login}
                </p>
              ) : null}

              <Button>
                {isLoading ? (
                  <Load.SmallIcon
                    style={{
                      margin: '0 auto',
                      borderColor: 'var(--color-primary-light)',
                      borderTopColor: 'var(--color-white)',
                    }}
                  />
                ) : (
                  'Acessar minha conta'
                )}
              </Button>
              <button
                type="button"
                className="link-button"
                style={{ marginTop: '1rem' }}
                onClick={() => history.push('/recuperar-senha')}
              >
                Esqueci minha senha
              </button>
            </Register.Form>
          </Register.Layout>
        </div>
      </Register.Container>
    </Register>
  );
}

export default Login;
