import React, { useState, useRef, useEffect } from 'react';
import { Button } from '../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../Components/common/Buttons/ButtonIconOnly';
import { AngleLeft, AngleRight } from '../../Components/common/Icons/IconsFamily';
import Modal from '../../Components/Modal';
import { toBraCurrency } from '../../utils';
import ProposalItemModal from './ProposalItemModal';
import ProposalItemReviewModal from './ProposalItemReviewModal';
import { ProposalPage } from './styles';

function ProposalItem(props) {
  const {
    item,
    addItem,
    removeItem,
    isAdded,
    isMyEventOpen,
    isExpired,
    user,
    reviews
  } = props;

  // ----------- Refs
  const modalPhotos = useRef();
  const modalItem = useRef();
  const modalReview = useRef();

  // ----------- States
  const [quantity, setQuantity] = useState(item?.quantity);
  const [currentImage, setCurrentImage] = useState(0);

  // ---------- Prices
  const subTotalPrice = parseFloat(
    (item?.priceMethod === 'Por pacote' ? 
    item?.final_buyer_price : 
    item?.final_additional_fee ? item?.price * quantity + item.final_additional_fee :
    item?.price * quantity)
  ).toFixed(2);
  const taxPrice = parseFloat(subTotalPrice * 0.1).toFixed(2);

  useEffect(() => console.log(item), [])

  // ----------- Quantity
  function validateQuantity(value) {
    if (value === '' || value < item.minQuantity) setQuantity(item.minQuantity);
    else if (value > item.maxQuantity) setQuantity(item.maxQuantity);
    else setQuantity(value);
  }

  function handleUpQuantity() {
    if (!item.maxQuantity || item.maxQuantity > quantity) setQuantity(quantity + 1);
  }

  function handleDownQuantity() {
    if (!item.minQuantity || item.minQuantity < quantity) setQuantity(quantity - 1);
  }

  // ------------ Photos
  function showModalPhotos() {
    document.querySelector("aside").classList.add("closing");
    document.querySelector("header").classList.add("closing");
    modalPhotos.current.open();
  }

  function hideModalPhotos() {
    document.querySelector("aside").classList.remove("closing");
    document.querySelector("header").classList.remove("closing");
  }

  // ------------ Reviews
  // function calculateSupplierRating(reviews) {

  //   let ratings = reviews.filter(function (review) {
  //     return review.rating != null;
  //   }).map(function (review) {
  //       return review.rating;
  //   })

  //   let avg = ratings ? "Nota: " + (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(2) : "Sem avaliações";

  //   return avg;
  // }

  useEffect(() => {
    if(item) console.log(item);
  }, [item]);

  return (
    <ProposalPage.Item >
      {
        item?.photos?.length > 0 && (
          <ProposalPage.Item.Photo onClick={showModalPhotos}>
            <img src={item?.photos[0].url} alt="" />
          </ProposalPage.Item.Photo>
        )
      }

      <ProposalPage.Item.Text
        onClick={() => modalItem.current.open()}
        className={!isMyEventOpen ? 'large' : ''}
      >
        <h3>{item?.name}</h3>
        <h6>
            Fornecido por: {item?.supplier_name} 
            {/* ({calculateSupplierRating(reviews)}) */}
          </h6>
        <p>{item?.description}</p>
        <Button
          variant="invisible"
            buttonSize="small"
          style={{ margin: 0, width: 'fit-content' }}
          onClick={() => modalItem.current.open()}
        >
          Ver detalhes
        </Button>

{/*         <Button
          variant="invisible"
            buttonSize="small"
          style={{ margin: 0, width: 'fit-content' }}
          onClick={() => modalReview.current.open()}
        >
          Ver avaliações
        </Button> */}
      </ProposalPage.Item.Text>

      <ProposalPage.Item.Prices >
        <div>
          <div>
            <span>Quantidade:</span>
            {
              item?.editableQuantity === "yes" && !isAdded(item?.label) ? (
                <div style={{ flexDirection: 'row' }}>
                  <div onClick={handleDownQuantity}>
                    <AngleLeft
                      color="var(--color-primary-light)"
                      strokeWidth="4"
                    />
                  </div>
                  <input
                    type="number"
                    value={quantity}
                    onChange={event => validateQuantity(event.target.value)}
                  />
                  <div onClick={handleUpQuantity}>
                    <AngleRight
                      color="var(--color-primary-light)"
                      strokeWidth="4"
                    />
                  </div>
                </div>
              ) : (
                <input
                  type="number"
                  defaultValue={quantity}
                  disabled
                />
              )
            }
          </div>
          <h4>
            <span>Total: </span>
            {toBraCurrency(subTotalPrice)}
          </h4>
        </div>

        {
          isAdded(item?.label) ? (
            <Button
              buttonSize="small"
              variant="secondary--danger"
              style={{ width: '100%' }}
              onClick={() => removeItem(item.label, item.price)}
            >
              Remover do meu evento
            </Button>
          ) : (
            <Button
              buttonSize="small"
              variant="purple"
              style={{ width: '100%' }}
              disabled={isExpired}
              onClick={() => addItem(item, quantity)}
            >
              Adicionar ao meu evento +
            </Button>
          )
        }
      </ProposalPage.Item.Prices>

      <Modal modalSize="inherit" ref={modalPhotos} onClose={hideModalPhotos}>
        <ProposalPage.Item.ModalPhoto >
          {
            item?.photos?.length > 1 && currentImage > 0 && (
              <ButtonIconOnly
                type="button"
                onClick={() => {
                  setCurrentImage(currentImage - 1);
                }}
              >
                <AngleLeft />
              </ButtonIconOnly>
            )
          }

          <img src={item?.photos[currentImage]?.url} alt="" />

          {
            item?.photos?.length > 1 && currentImage < item?.photos?.length - 1 && (
              <ButtonIconOnly
                type="button"
                onClick={() => {
                  setCurrentImage(currentImage + 1);
                }}
              >
                <AngleRight />
              </ButtonIconOnly>
            )
          }
        </ProposalPage.Item.ModalPhoto>
      </Modal>

      <ProposalItemModal
        ref={modalItem}
        item={item}
        modalPhotos={modalPhotos}
        subTotal={subTotalPrice}
        quantity={quantity}
        addItem={addItem}
        removeItem={removeItem}
        isAdded={isAdded}
        handleUpQuantity={handleUpQuantity}
        handleDownQuantity={handleDownQuantity}
        validateQuantity={validateQuantity}
        isExpired={isExpired}
      />

      {/* <ProposalItemReviewModal
        ref={modalReview}
        item={item}
        modalPhotos={modalPhotos}
        supplier={item.supplier}
        reviews={item.supplier.reviews}
      /> */}

    </ProposalPage.Item>
  )
}

export default ProposalItem;