import React, { useEffect, useRef, useState } from 'react';
import Alert from '../../../Components/Alert';

import { LVStore } from '../styles';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { ShoppingCart } from '../../../Components/Icons';

function LearningVillageCart({ selectedItems, removeItemFromCart, isAuditorium }){

    // States
    const [ isOpened, setIsOpened ] = useState( false );
    const [ isCheckoutAble, setIsCheckoutAble ] = useState( false );
    const [ eventPlace, setEventPlace ] = useState();

    // Alert component reference
    const alertRef = useRef();


    useEffect( () => {
        if (isAuditorium) setEventPlace("arena");
    }, [isAuditorium] );

    useEffect( () => {

        document.querySelector("body").style.overflow = isOpened ? "hidden" : "inheirt";

    }, [ isOpened ]);

    useEffect( () => {
        
        let isAble  = selectedItems.length > 0;
        setIsCheckoutAble(isAble);

    }, [ selectedItems ]);

/*=====================================================
        Functions to calculate items price
======================================================*/

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

    function calculateSubtotalPrice(){
        let subtotalPrice = 0;

        selectedItems.forEach( item => {
            subtotalPrice += parseFloat(item.final_buyer_price);
        });

        return toBraCurrency(subtotalPrice);
    }

    function calculateTax(){
        let subtotalPrice = 0;

        selectedItems.forEach( item => {
            subtotalPrice += parseFloat(item.final_buyer_price);
        });

        let tax = 0;

        tax = parseFloat(subtotalPrice) * 0.14

        return toBraCurrency(tax);
    }

    function calculateTotalPrice(){
        let finalPrice = 0;

        selectedItems.forEach( item => {
            finalPrice += parseFloat(item.final_buyer_price);
        });

        finalPrice += parseFloat(finalPrice) * 0.14;

        return toBraCurrency(finalPrice);
    }

/*=====================================================
    Functions to open and close shopping cart
======================================================*/

    function openShoppingCart(){

        setIsOpened( !isOpened );
        document.querySelector("body").style.overflow = "hidden";

    }

    function closeShoppingCart(){

        setIsOpened( false );
        document.querySelector("body").style.overflow = "inherit";

    }


/*=====================================================
                    Finish order
======================================================*/

    function finishOrder(){

        if( isCheckoutAble && eventPlace ){

            sessionStorage.setItem("eventPlace", eventPlace);
            sessionStorage.setItem("subtotal", calculateSubtotalPrice());
            sessionStorage.setItem("taxes", calculateTax());
            sessionStorage.setItem("total", calculateTotalPrice());

        } else {
            alertRef.current.open();
        }
    }



    return(
        <LVStore.CartComponent className={ isOpened && "open" }>

            {
                !isOpened && (
                    <div className="container">
                        <LVStore.CartButton onClick={ () => setIsOpened(true) }>
                            <ShoppingCart />
                            <span> CARRINHO <div> { selectedItems.length } </div> </span>
                        </LVStore.CartButton>
                    </div>
                )
            }

            {
                isOpened && (
                    <Fragment >
                        <LVStore.Lightbox onClick={ closeShoppingCart } className={ isOpened && "open" } />

                        <LVStore.Cart className={ isOpened && "open" }>
                            <LVStore.CartTitle >
                                <h3> Seu carrinho </h3>
                                <h5> { selectedItems.length } { selectedItems.length > 1 ? "Itens" : "Item" } </h5>
                            </LVStore.CartTitle>

                            <LVStore.CartItems >
                                {
                                    selectedItems.length > 0 && (
                                        selectedItems.map( item => {
                                            return(
                                                <LVStore.CartItem key={ item.id }>
                                                    <LVStore.CartItemImg >
                                                        <img src={ item.photos[0].url } alt="" />
                                                    </LVStore.CartItemImg>

                                                    <LVStore.CartText >
                                                        <LVStore.CartItemName >
                                                            { item.name }
                                                        </LVStore.CartItemName>

                                                        <LVStore.CartItemPrice >
                                                            { toBraCurrency(item.final_buyer_price) }
                                                        </LVStore.CartItemPrice>

                                                        <LVStore.CartRemoveItem onClick={ () => removeItemFromCart( item.id ) }>
                                                            Remover item
                                                        </LVStore.CartRemoveItem>

                                                    </LVStore.CartText>
                                                </LVStore.CartItem>
                                            )
                                        })
                                    )
                                }
                            </LVStore.CartItems>

                            <div className="input__field">
                                <label htmlFor="event_place"> SELECIONE A SALA RESERVADA PARA O SEU EVENTO </label>
                                <select name="event_place" id="event_place" className="input" onChange={ event => setEventPlace(event.target.value) }>
                                    {
                                        isAuditorium ? (
                                            <option value="auditorium"> Auditório </option>
                                        ) : (
                                            <Fragment >
                                                <option disabled selected > SELECIONE UMA SALA </option>
                                                <option value="8B"> Sala de reunião 8B </option>
                                                <option value="9A"> Sala de reunião 9A </option>
                                                <option value="9B"> Sala de reunião 9B </option>
                                                <option value="9C"> Sala de reunião 9C </option>
                                                <option value="10E"> Sala de reunião 10E </option>
                                                <option value="11C"> Sala de reunião 11C </option>
                                                <option value="11D"> Sala de reunião 11D </option>
                                            </Fragment>
                                        )
                                    }
                                </select>
                            </div>

                            <LVStore.CartTotalPrice>
                                <div>
                                    <span> Subtotal </span> <span> { calculateSubtotalPrice() } </span>
                                </div>
                                <div>
                                    <span> Impostos </span> <span> { calculateTax() } </span>
                                </div>
                                <div>
                                    <h1> Total </h1> <h1> { calculateTotalPrice() } </h1>
                                </div>
                            </LVStore.CartTotalPrice>

                            <LVStore.Button className="bgDark blue" onClick={ () => finishOrder() } >
                                {
                                    isCheckoutAble && eventPlace ? (
                                        <Link to="/lv-checkout" > Finalizar pedido  </Link>
                                    ) : (
                                        <span> Finalizar pedido </span>
                                    )
                                }
                            </LVStore.Button>

                            <div className="space"></div>

                        <Alert ref={ alertRef }>
                            <p> Adicione ao menos 1 item no seu carrinho e selecione a sala do seu evento para prosseguir </p>
                        </Alert>

                        </LVStore.Cart>
                    </Fragment>
                )
            }
        </LVStore.CartComponent>

    )
}

export default LearningVillageCart;