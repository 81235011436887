import React, { Fragment, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';
import ModalAuth from '../ModalAuth';

import { Menu } from './styles';
// Images
import Logo from '../../assets/images/logos/celebrar/celebrar.png';
import menuIcon from '../../assets/icons/menu-icon.svg';
import menuCloseIcon from '../../assets/icons/menu-close-icon.svg';
import userImage from '../../assets/icons/user-image.svg';
import { Button } from '../common/Buttons/Button';

function Header({ buyerPage, hideButtons, component }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const modalRef = useRef();

  const { user } = useAuthContext();
  const { push } = useHistory();

  if (buyerPage) {
    return (
      <Fragment>
        <Menu>
          <div className="container">
            <Menu.BuyerNav>
              <Menu.Logo>
                <a href="https://www.celebrar.com.br">
                  <img src={Logo} alt="Celebrar" />
                </a>
              </Menu.Logo>
              <Menu.Links>
                {!hideButtons ? (
                  <>
                    <Button onClick={() => push('/comprador/pedido')} buttonSize="small">Comece agora</Button>
                    <a href='https://v1.celebrar.com.br/login'>
                    <Button variant="secondary"  buttonSize="small">
                      Acessar minha conta
                    </Button>

                    </a>
                  </>
                ) : null}
                {
                  component && component
                }
              </Menu.Links>
            </Menu.BuyerNav>
          </div>
        </Menu>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu>
        <div className="container">
          <Menu.NavMobile>
            <Menu.Logo>
              <a href="https://www.celebrar.com.br">
                <img src={Logo} alt="Celebrar" />
              </a>
            </Menu.Logo>

            <img src={menuIcon} alt="Menu" onClick={() => setIsMenuOpened(true)} />
          </Menu.NavMobile>

          <Menu.Nav>
            <Menu.Logo>
            <a href="https://www.celebrar.com.br">
                <img src={Logo} alt="Celebrar" />
              </a>
            </Menu.Logo>
            <Menu.Links>
              {user ? (
                <Fragment>
                  <Link to="/fornecedor/painel-de-vendas">
                    <Button variant="invisible" buttonSize="small">
                      <figure>
                        <img src={user.avatar_url || userImage} alt={user.name} />
                      </figure>
                      {user.name || user.company_name || 'Complete o cadastro'}
                    </Button>
                  </Link>
                </Fragment>
              ) : (
                <>
                  <Button onClick={() => push('/comprador/pedido')} buttonSize="small">Comece agora</Button>
                  <a href='https://v1.celebrar.com.br/login'>
                    <Button variant="secondary"  buttonSize="small">
                      Acessar minha conta
                    </Button>
                    </a>
                </>
              )}
            </Menu.Links>
          </Menu.Nav>
        </div>

        {/* ------- Mobile menu ------------- */}
        {isMenuOpened && (
          <Menu.LinksMobile>
            {/* <Link to="/quem-somos"> Quem somos </Link> */}

            {user ? (
              <button className="btn btn--slim">
                <Link to="/login">
                  <figure>
                    <img src={user.avatar_url || userImage} alt={user.name} />
                  </figure>
                  {user.name || user.company_name || 'Complete o cadastro'}
                </Link>
              </button>
            ) : (
              <Fragment>
                <button className="btn btn--slim">
                  <span to="/" onClick={() => modalRef.current.open('login')}>
                    {' '}
                    Login{' '}
                  </span>
                </button>

                <button className="btn" onClick={() => push('/cadastro')}>
                  <span> Criar conta </span>
                </button>
              </Fragment>
            )}

            <Menu.Close onClick={() => setIsMenuOpened(false)}>
              <img src={menuCloseIcon} alt="Fechar Menu" />
              <figcaption> Fechar menu </figcaption>
            </Menu.Close>
          </Menu.LinksMobile>
        )}
      </Menu>

      <ModalAuth ref={modalRef} />
    </Fragment>
  );
}

export default Header;
