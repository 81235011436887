import styled, { keyframes } from 'styled-components';

const Anime = keyframes`
    from{
        transform: translateY(-3rem);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
`;

export const AlertBox = styled.div`
  /* Diplay & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  /* Colors & Background */
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);

  /* Potision */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  /* Others */
  transition: all 0.3s ease-out;
  animation: ${Anime} 0.3s ease-in-out;
`;

AlertBox.Alert = styled.div`
  /* Diplay & Box Model */
  width: 20rem;
  padding: 1rem;
  text-align: left;

  /* Text */
  text-align: center;

  /* Others */
  box-shadow: var(--shadow);
  border-radius: 3px;
`;

export const AlertPrimary = styled(AlertBox.Alert)`
  /* Colos */
  background-color: var(--color-primary-light);
  color: var(--color-white);
`;

export const AlertSucess = styled(AlertBox.Alert)`
  /* Colos */
  background-color: var(--color-green);
  color: var(--color-white);
`;

export const AlertWarning = styled(AlertBox.Alert)`
  /* Colos */
  background-color: var(--color-yellow-dark);
  color: var(--color-white);
`;

export const AlertDanger = styled(AlertBox.Alert)`
  /* Colos */
  background-color: var(--color-red);
  color: var(--color-white);
`;
