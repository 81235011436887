import React, { useEffect, useState, Fragment, useRef } from 'react';
import axios from 'axios';
import { Link, useParams, useHistory } from 'react-router-dom';
import SupplierMenu from '../../Menu';
import FeedbackChannel from '../../FeedbackChannel';
import Alert from '../../../../Components/Alert';
import { useAuthContext } from '../../../../Contexts/AuthContext';
// Styles & Images
import BackLink from '../../../../Components/BackLink';
import { Load } from '../../../../Components/Loader/styles';
import { AlertsPage, SupplierPage } from '../../styles';

function SupplierAlertDetails() {
  const alertRef = useRef();
  const [alert, setAlert] = useState(null);
  const [submittedOffersCount, setSubmittedOffersCount] = useState([]);

  const { user } = useAuthContext();
  const { handle } = useParams();
  const { replace } = useHistory();

  if (user.accept_terms_at === null) {
    window.location.href = '/fornecedor/atualizar-termos';
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    axios({
      method: 'get',
      url: `/api/supplier/alert/${handle}`,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(res => {
        setAlert(res.data.alert);
        setSubmittedOffersCount(res.data.submittedOffersCount);
      })
      .catch(err => {
        if (err.response?.status === 404) {
          replace('/404');
        } else {
          console.log(err);
        }
      });
  }, [handle, user, replace]);

  /*===================================
                Dates function
    ====================================*/

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  function getDate(date, isMonth) {
    return isMonth ? monthNames[new Date(date).getMonth()] : new Date(date).getDate();
  }

  if (!alert) {
    return (
      <SupplierPage>
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <div className="container">
        <BackLink link={'/fornecedor/painel-de-vendas'}>Painel de vendas</BackLink>

        <SupplierPage.Box className="margin--top-2">
          <SupplierPage.BoxHeader>
            <h3 style={{ margin: 0 }}>Detalhes da oportunidade</h3>
          </SupplierPage.BoxHeader>

          <AlertsPage.Details>
            <Alert ref={alertRef}>
              <p> Antes de enviar um orçamento você deve preencher os dados da sua empresa. </p>
            </Alert>

            <AlertsPage.DetailsName>
              {alert && alert.name}

              <button className="btn float" disabled={!user.company_name}>
                <div
                  onMouseOver={() => {
                    !user.company_name && alertRef.current.open();
                  }}
                >
                  <Link
                    to={`/fornecedor/enviar-orcamento/${alert && alert.id}`}
                    style={{
                      pointerEvents: `${!user.company_name ? 'none' : 'inherit'}`,
                      display: 'flex',
                    }}
                  >
                    Enviar um orçamento
                  </Link>
                </div>
              </button>
            </AlertsPage.DetailsName>

            <div className="alerts__fieldset">
              <span className="fieldset__title"> Descrição </span>
              <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.description}</p>
            </div>

            {alert?.price_method ? (
              <div className="alerts__fieldset">
                <span className="fieldset__title"> Modo de cobrança </span>
                <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.price_method}</p>
              </div>
            ) : null}

            {alert?.state ? (
              <div className="alerts__fieldset">
                <span className="fieldset__title"> Local (Estado) </span>
                <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.state}</p>
              </div>
            ) : null}

            {alert?.quantity ? (
              <div className="alerts__fieldset">
                <span className="fieldset__title"> Quantidade </span>
                <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.quantity}</p>
              </div>
            ) : null}

            <AlertsPage.DetailsDates>
              <div className="alerts__fieldset">
                <span className="fieldset__title"> Datas </span>
              </div>

              <AlertsPage.DetailsDatesGrid>
                <AlertsPage.DetailsDate>
                  <AlertsPage.DetailsDateBox className="purple">
                    {alert?.delivery_estimated_at ? (
                      <Fragment>
                        <span> Entrega em: </span>
                        <h5> {getDate(alert.delivery_estimated_at)} </h5>
                        <p> de {getDate(alert.delivery_estimated_at, true)} </p>
                      </Fragment>
                    ) : (
                      <p className="date--string"> {alert && alert.delivery_date} </p>
                    )}
                  </AlertsPage.DetailsDateBox>

                  <AlertsPage.DetailsDateText className="purple">
                    Data de entrega <br /> do serviço
                  </AlertsPage.DetailsDateText>
                </AlertsPage.DetailsDate>

                <AlertsPage.DetailsDate>
                  <AlertsPage.DetailsDateBox className="red">
                    {alert && alert.expires_at ? (
                      <Fragment>
                        <span> Vencimento: </span>
                        <h5> {alert && getDate(alert.expires_at)} </h5>
                        <p> de {alert && getDate(alert.expires_at, true)} </p>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <span> Vencimento: </span>
                        <h5> -- </h5>
                        <p> </p>
                      </Fragment>
                    )}
                  </AlertsPage.DetailsDateBox>

                  <AlertsPage.DetailsDateText className="txt--red">
                    Data limite para <br /> mandar o orçamento
                  </AlertsPage.DetailsDateText>
                </AlertsPage.DetailsDate>
              </AlertsPage.DetailsDatesGrid>
            </AlertsPage.DetailsDates>

            <AlertsPage.DetailsImages>
              <div className="alerts__fieldset">
                <span className="fieldset__title"> Imagens de referência </span>
                {alert && alert.photos && alert.photos.length > 0 ? (
                  <AlertsPage.DetailsImagesGrid>
                    {alert.photos.map(photo => {
                      return (
                        <figure key={photo.id}>
                          <img src={photo.url} alt="Imagem de referência do alerta" />
                        </figure>
                      );
                    })}
                  </AlertsPage.DetailsImagesGrid>
                ) : (
                  <ul>
                    <li>Não foram incluídas imagens de referência.</li>
                  </ul>
                )}
              </div>
            </AlertsPage.DetailsImages>

            <div className="alerts__fieldset">
              <span className="fieldset__title"> Orçamentos enviados </span>
              {submittedOffersCount > 0 ? (
                <li>{`Você já enviou ${submittedOffersCount} ${
                  submittedOffersCount === 1 ? 'orçamento' : 'orçamentos'
                } para esta demanda.`}</li>
              ) : (
                <li>Você ainda não enviou nenhum orçamento para esta demanda.</li>
              )}
            </div>
          </AlertsPage.Details>

          {user.company_name ? (
            <button className="btn" style={{ margin: '4rem auto 2rem' }}>
              <Link to={`/fornecedor/enviar-orcamento/${alert?.id}`}>Enviar um orçamento</Link>
            </button>
          ) : (
            <div className="row txt--center" style={{ marginTop: '4rem' }}>
              <p className="txt--bold txt--red txt--center">
                {' '}
                Antes de enviar um orçamento você precisa cadastrar os dados da sua empresa.{' '}
              </p>
              <button className="btn btn--secondary" style={{ margin: '1rem auto' }}>
                <Link to="/fornecedor/minha-empresa" className="txt--purple">
                  {' '}
                  Cadastrar dados da empresa{' '}
                </Link>
              </button>
            </div>
          )}
        </SupplierPage.Box>
      </div>
    </SupplierPage>
  );
}

export default SupplierAlertDetails;
