import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowLeft } from '../../Components/Icons';

function BackLink({ children, link }){
    return(
        <div className="backlink">
            <Link to={ link }>
                <div className="backlink__icon">
                    <ArrowLeft />
                </div>    
                <span className="backlink__text"> { children } </span>
            </Link>
        </div>
    )
}

export default BackLink;