import styled from 'styled-components';
import bgRegister from '../../../../assets/images/backgrounds/bg-register-buyer.jpg';

export const Reset = styled.div `
    width: 100%;
    min-height: 100vh;
    background-image: url( ${ bgRegister } );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

Reset.Container = styled.div `
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

Reset.Box = styled.div `
    text-align: center;
    color: var(--color-text-in-black);
    text-shadow: 0 0 0.5rem rgba( 0, 0, 0, 0.5);

    h1{
        font-size: 2rem;
    }

    form{
        width: auto;
        padding: 2rem;
        background-color: var(--color-white);
        box-shadow: var(--shadow);
        margin: 2rem auto 0 auto;
    }

    @media (min-width: 800px){
        form{
            width: 25rem;
        }

        h1{
            font-size: 3rem;
        }
    }
`;