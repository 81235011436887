import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReviewCelebrar from './reviewCelebrar';
import ReviewCard from './reviewCard';
import SuccessAlert from '../../Components/SuccessAlert';
// Images & Styles
import { ReviewsPage } from './styles';
import reviewArtwork from '../../assets/images/email/reviews-artwork.png';
import ReviewedImage from '../../assets/images/review-sucess.svg';
import Celebrar from '../../assets/images/logos/celebrar/celebrar.svg';
import { Load } from '../../Components/Loader/styles';

function Reviews() {
    const { reqId } = useParams();
    const { replace } = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isAnswered, setIsAnswered] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const alertRef = useRef();

    const [reviews, setReviews] = useState(null);
    const [eventName, setEventName] = useState(null);
    const [eventDate, setEventDate] = useState(null);

    // User review
    const [npsRating, setNpsRating] = useState(null);
    const [npsDescription, setNpsDescription] = useState('');
    const [supplierReviews, setSupplierReviews] = useState([]);
    const [review, setReview] = useState(null);

    useEffect(() => {
        axios
            .get(`/api/buyer/review/${reqId}`)
            .then(({ data }) => {
                if (data.message === 'Review request already answered.') {
                    setIsAnswered(true);
                } else {
                    setReviews(data.reviews);
                }
                setEventName(data.event_name);
                setEventDate(new Date(data.event_date).toLocaleString('pt-br'));
                setIsLoading(false);
            })
            .catch(error => {
                if (error.response?.status === 404) {
                    replace('/404');
                } else {
                    console.log(error);
                }
            });
    }, [replace, reqId]);

    useEffect(() => {
        npsRating &&
            supplierReviews &&
            setReview({
                review_request_id: reqId,
                supplier_reviews: supplierReviews,
                nps: {
                    rating: npsRating,
                    description: npsDescription,
                },
            });
    }, [npsRating, supplierReviews, reqId, npsDescription]);

    function submitReview() {
        setIsSubmitting(true);
        axios({
            method: 'post',
            url: `/api/buyer/review/`,
            data: {
                review,
            },
        })
            .then(res => {
                if (res.status === 200) {
                    alertRef.current.open();
                    setIsSubmitting(false);
                    setIsAnswered(true);
                }
            })
            .catch(error => {
                console.log(error);
                setIsSubmitting(false);
                window.alert('Ops, ocorreu um erro, por favor tente novamente mais tarde');
            });
    }

    if (isLoading) {
        return (
            <Load.FullPageContainer>
                <Load.SmallIcon />
            </Load.FullPageContainer>
        );
    }

    return (
        <ReviewsPage>
            <ReviewsPage.Header>
                <div className="container txt--center">
                    <ReviewsPage.HeaderLogo src={Celebrar} id="logo" />
                    <ReviewsPage.HeaderInfos>
                        <p>
                            {' '}
                            Evento: <span className="txt--bold txt--secondary">
                                {eventName}
                            </span>{' '}
                        </p>
                        <p>
                            {' '}
                            Data do evento:{' '}
                            <span className="txt--bold txt--secondary">{eventDate}</span>
                        </p>
                    </ReviewsPage.HeaderInfos>

                    <img src={reviewArtwork} alt="Avaliação Celebrar" />
                    {reviews && (
                        <h1 className="txt--white txt--center margin--bottom-1">
                            {' '}
                            Como foi sua experiência com os serviços entregues?{' '}
                        </h1>
                    )}
                </div>
            </ReviewsPage.Header>

            <SuccessAlert ref={alertRef}>
                <p> Avaliação enviada com sucesso! </p>
            </SuccessAlert>

            <div className="container">
                <ReviewsPage.Reviews>
                    {!isAnswered ? (
                        reviews?.map(review => {
                            return (
                                <ReviewCard
                                    key={review.id}
                                    review={review}
                                    supplierReviews={supplierReviews}
                                    setSupplierReviews={setSupplierReviews}
                                />
                            );
                        })
                    ) : (
                        <ReviewsPage.Reviewed>
                            <img src={ReviewedImage} alt="" />
                            <h2 className="txt--primary">Avaliação enviada com sucesso!</h2>
                            <h3 className="txt--black">
                                Muito obrigado por avaliar sua experiência
                            </h3>
                            <button className="btn btn--center" type="submit">
                                <Link to="/painel-comprador"> Acessar meus pedidos </Link>
                            </button>
                        </ReviewsPage.Reviewed>
                    )}
                </ReviewsPage.Reviews>
            </div>

            {!isAnswered ? (
                <ReviewCelebrar
                    npsRating={npsRating}
                    setNpsRating={setNpsRating}
                    npsDescription={npsDescription}
                    setNpsDescription={setNpsDescription}
                    isAbleToSend={npsRating && supplierReviews.length === reviews.length}
                    submitReview={submitReview}
                    isSubmitting={isSubmitting}
                />
            ) : null}
        </ReviewsPage>
    );
}

export default Reviews;
