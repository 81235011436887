import React, { useRef, useState } from 'react';
import Modal from '../Modal';
import { ButtonIconOnly } from '../common/Buttons/ButtonIconOnly';
import { Button } from '../common/Buttons/Button';
import { OfferItemImages } from './OfferItemImages';
import { toBraCurrency } from '../../utils';
import { ButtonSecondary } from '../common/Buttons/ButtonSecondary';
import { FormEditableInput } from '../common/Forms/FormEditableInput';
import { FormEditableTextarea } from '../common/Forms/FormEditableTextarea';
// Styles & Images
import { Item } from './styles';
import { ArrowDown, ArrowUp, Camera, Close, Hiperlink, Money } from '../common/Icons/IconsFamily';

export function OfferItem(props) {
  const {
    item,
    addItem,
    removeItem,
    isAdded,
    handleMoveUp,
    handleMoveDown,
    handleRemoveItem,
    isControlBarEnable,
    missingFields,
    alertQuantity,
    alertPriceMethod,
    alertCategories,
    isExpired,
  } = props;

  const label = item.label;

  // Item details
  // const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [itemTitle, setItemTitle] = useState(item?.name || '');
  const [itemDescription, setItemDescription] = useState(item?.description || '');

  // Links
  const linksModalRef = useRef();
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState('');
  // Prices
  const pricesModalRef = useRef();
  const [priceMethod, setPriceMethod] = useState(
    item?.price_method ? item?.price_method : alertPriceMethod || ''
  );
  const [quantity, setQuantity] = useState(item?.quantity ? item?.quantity : alertQuantity || '');
  const [unitPrice, setUnitPrice] = useState(item?.price || '');
  const subTotalPrice = parseFloat(
    priceMethod === 'Por pacote' ? unitPrice || '0' : unitPrice * quantity
  ).toFixed(2);
  const taxPrice = parseFloat(subTotalPrice * 0.1).toFixed(2);
  const supplierFinalPrice = parseFloat(subTotalPrice - taxPrice).toFixed(2);
  const supplierUnitPrice = parseFloat(unitPrice * 0.9).toFixed(2);

  // Images
  const imagesModalRef = useRef();
  // Remove Item
  const removeItemModalRef = useRef();

  // Categories
  const [selectedCategory, setSelectedCategory] = useState(
    alertCategories.length === 1 ? alertCategories[0].id : null
  );

  function setPrices(event) {
    let price = event.target.value.includes(',')
      ? event.target.value.replace(',', '.')
      : event.target.value;

    if (price === '') {
      setUnitPrice('');
    } else {
      setUnitPrice(parseFloat(price));
    }
  }

  function validateQuantity(value) {
    if (value === '' || value < item.minQuantity) setQuantity(item.minQuantity);
    else if (value > item.maxQuantity) setQuantity(item.maxQuantity);
    else setQuantity(value);
  }

  function handleUpQuantity() {
    if (!item.maxQuantity || item.maxQuantity > quantity) setQuantity(quantity + 1);
  }

  function handleDownQuantity() {
    if (!item.minQuantity || item.minQuantity < quantity) setQuantity(quantity - 1);
  }

  return (
    <Item id={label}>
      <Item.Content>
        {(item && item?.photos?.length > 0) || isControlBarEnable ? (
          <OfferItemImages
            itemPhotos={item?.photos}
            imagesModalRef={imagesModalRef}
            label={label}
            isControlBarEnable={isControlBarEnable}
          />
        ) : null}

        <Item.Body className={item?.photos?.length < 1 || !isControlBarEnable ? 'fullwidth' : ''}>
          <Item.BodyGrid
            className={
              missingFields?.includes(`${label}-description`) && itemDescription.trim().length === 0
                ? 'missing-item-description-error error'
                : undefined
            }
          >
            <Item.ItemNameContainer
              className={
                missingFields?.includes(`${label}-name`) && itemTitle.trim().length === 0
                  ? 'missing-item-name-error error'
                  : undefined
              }
            >
              <FormEditableInput
                type="text"
                name={`${label}-name`}
                placeholder="Digite aqui o nome desse item"
                style={{ marginTop: 0, marginBottom: 0 }}
                disabled={!isControlBarEnable}
                value={itemTitle}
                onChange={e => setItemTitle(e.target.value)}
                color={item ? 'var(--color-white)' : ''}
              />
            </Item.ItemNameContainer>

            {item?.obs ? (
              <div
                style={{
                  height: '18rem',
                  margin: 0,
                }}
              >
                <FormEditableTextarea
                  name={`${label}-description`}
                  placeholder="Digite aqui com detalhes a descrição do item"
                  disabled={!isControlBarEnable}
                  value={itemDescription}
                  onChange={e => setItemDescription(e.target.value)}
                  height="13rem"
                  style={{ margin: 0 }}
                />
                <FormEditableTextarea
                  name={`${label}-description`}
                  placeholder="Digite aqui com detalhes a descrição do item"
                  disabled={!isControlBarEnable}
                  value={`Observações:\n${item.obs}`}
                  onChange={e => setItemDescription(e.target.value)}
                  height="6rem"
                  style={{ margin: 0 }}
                />
              </div>
            ) : alertCategories.length > 1 ? (
              <div
                style={{
                  minHeight: '19rem',
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormEditableTextarea
                  name={`${label}-description`}
                  placeholder="Digite aqui com detalhes a descrição do item"
                  disabled={!isControlBarEnable}
                  value={itemDescription}
                  onChange={e => setItemDescription(e.target.value)}
                  style={{ margin: 0, minHeight: '150px' }}
                />
                <Item.CategorySelectionBox
                    className={
                      missingFields?.includes(`${label}-category-id`) && !selectedCategory
                        ? 'missing-category-id-error error'
                        : ''
                    }
                >
                  <p>Em qual categoria este item melhor se encaixa?</p>
                  <div>
                    {alertCategories.map(category => (
                      <div
                        key={category.id}
                        className={selectedCategory === category.id ? 'selected' : ''}
                        onClick={() => setSelectedCategory(category.id)}
                      >
                        {category.name}
                      </div>
                    ))}
                  </div>
                </Item.CategorySelectionBox>
              </div>
            ) : (
              <FormEditableTextarea
                name={`${label}-description`}
                placeholder="Digite aqui com detalhes a descrição do item"
                disabled={!isControlBarEnable}
                value={itemDescription}
                onChange={e => setItemDescription(e.target.value)}
                height="19rem"
                style={{ margin: 0 }}
              />
            )}
          </Item.BodyGrid>

          <Item.Prices
            onClick={() => isControlBarEnable && pricesModalRef.current.open()}
            className={
              missingFields?.includes(`${label}-unit-price`) && Number(supplierFinalPrice) === 0
                ? 'missing-item-price-error error'
                : undefined
            }
          >
            {priceMethod && (
              <p>
                <span>Modo de cobrança</span>
                {priceMethod || '---'}
              </p>
            )}

            <p>
              <span>
                Quantidade{' '}
                <span>
                  {item?.editableQuantity === 'yes' && item.minQuantity && item.maxQuantity
                    ? `(min: ${item.minQuantity}, max: ${item.maxQuantity})`
                    : ''}
                  {item?.editableQuantity === 'yes' && item.minQuantity && !item.maxQuantity
                    ? `(min: ${item.minQuantity})`
                    : ''}
                  {item?.editableQuantity === 'yes' && !item.minQuantity && item.maxQuantity
                    ? `(max: ${item.maxQuantity})`
                    : ''}
                </span>
              </span>
              {item?.editableQuantity === 'yes' ? (
                <Item.Quantity>
                  <button type="button" children="-" onClick={handleDownQuantity} />
                  <input
                    type="number"
                    value={quantity}
                    onChange={event => validateQuantity(parseInt(event.target.value))}
                    min={item.minQuantity}
                    max={item.maxQuantity}
                  />
                  <button type="button" children="+" onClick={handleUpQuantity} />
                </Item.Quantity>
              ) : (
                <>{quantity || 0}</>
              )}
            </p>

            <p>
              <span>
                Valor {''}
                {priceMethod || ''}
              </span>
              {toBraCurrency(unitPrice)}
            </p>

            <p>
              <span>Valor total</span>
              {toBraCurrency(subTotalPrice)}
            </p>
          </Item.Prices>

          <input name={`${label}-position`} type="hidden" defaultValue={item.position} />
          <input name={`${label}-quantity`} type="hidden" defaultValue={quantity} />
          <input name={`${label}-price-method`} type="hidden" defaultValue={priceMethod} />
          <input name={`${label}-unit-price`} type="hidden" defaultValue={unitPrice} />
          <input name={`${label}-category-id`} type="hidden" defaultValue={selectedCategory} />
          <input
            name={`${label}-supplier-unit-price`}
            type="hidden"
            defaultValue={supplierUnitPrice}
          />
          <input name={`${label}-subtotal-price`} type="hidden" defaultValue={subTotalPrice} />
          <input name={`${label}-tax-price`} type="hidden" defaultValue={taxPrice} />
          <input
            name={`${label}-supplier-final-price`}
            type="hidden"
            defaultValue={supplierFinalPrice}
          />
        </Item.Body>

        {isControlBarEnable && (
          <Item.ControlBar>
            <ButtonIconOnly
              type="button"
              label="Mover para cima"
              onClick={() => handleMoveUp(item.position)}
            >
              <ArrowUp />
            </ButtonIconOnly>
            <ButtonIconOnly
              type="button"
              onClick={() => handleMoveDown(item.position)}
              label="Mover para baixo"
            >
              <ArrowDown />
            </ButtonIconOnly>
            <ButtonIconOnly
              type="button"
              onClick={() => imagesModalRef.current.open()}
              label="Fotos"
            >
              <Camera />
            </ButtonIconOnly>
            <ButtonIconOnly
              type="button"
              onClick={() => pricesModalRef.current.open()}
              label="Precificação"
            >
              <Money />
            </ButtonIconOnly>
            <ButtonIconOnly
              type="button"
              onClick={() => linksModalRef.current.open()}
              label="Links"
            >
              <Hiperlink />
            </ButtonIconOnly>
            <ButtonIconOnly
              type="button"
              onClick={() => removeItemModalRef.current.open()}
              label="Remover"
            >
              <Close />
            </ButtonIconOnly>
          </Item.ControlBar>
        )}

        {links.map((link, index) => (
          <input
            type="text"
            key={`${label}-links${index}`}
            name={`${label}-links${index}`}
            defaultValue={link}
            id={label}
            style={{ display: 'none' }}
          />
        ))}

        <Modal ref={linksModalRef} title="Gerenciar links do item" modalSize="large">
          {links.length > 0 ? (
            links.map((link, index) => (
              <Item.Link key={`${label}-links${index}`}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <Hiperlink />
                  {link}
                </a>

                <ButtonIconOnly
                  type="button"
                  onClick={() => {
                    const newLinks = [...links];
                    newLinks.splice(index, 1);
                    setLinks(newLinks);
                  }}
                >
                  <Close />
                </ButtonIconOnly>
              </Item.Link>
            ))
          ) : (
            <p className="margin--tb-2">Você ainda não adicionou nenhum link</p>
          )}

          <input
            type="text"
            value={newLink}
            onChange={e => setNewLink(e.target.value)}
            className="input"
            placeholder="Link de referência desse item"
          />
          <Button
            type="button"
            align="center"
            onClick={() => {
              if (newLink !== '') {
                setLinks(links => [...links, newLink]);
                setNewLink('');
              }
            }}
          >
            Adicionar Link
          </Button>
        </Modal>

        <Modal ref={pricesModalRef} title="Precificação do item" modalSize="large">
          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Modo de cobrança</h6>
              <p>O modo de cobrança define como você irá precificar o seu serviço oferecido</p>
            </Item.ModalPrices.Content>
            <select
              name=""
              id=""
              className="input"
              onChange={event => {
                setPriceMethod(event.target.value);
                setUnitPrice('');
              }}
            >
              <option value="Por pessoa" disabled selected={priceMethod === ''}>
                Selecione uma opção
              </option>
              <option value="Por pessoa" selected={priceMethod && priceMethod === 'Por pessoa'}>
                Por pessoa
              </option>
              <option value="Por unidade" selected={priceMethod && priceMethod === 'Por unidade'}>
                Por unidade
              </option>
              <option value="Por pacote" selected={priceMethod && priceMethod === 'Por pacote'}>
                Por pacote
              </option>
              <option value="Por hora" selected={priceMethod && priceMethod === 'Por hora'}>
                Por hora
              </option>
            </select>
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Quantidade</h6>
            </Item.ModalPrices.Content>
            <input
              type="number"
              className="input"
              placeholder="00"
              min="1"
              value={quantity}
              onChange={event => setQuantity(event.target.value)}
            />
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6 className="txt--primary">
                Valor de venda <span>{priceMethod || ''}</span>
              </h6>
              <p>
                Informe o valor de venda{' '}
                <span style={{ textTransform: 'lowercase' }}>{priceMethod || ''}</span>. Lembre-se
                que este valor já inclui a taxa de serviço. Digite somente números
              </p>
            </Item.ModalPrices.Content>
            <input
              type="number"
              className="input"
              placeholder="R$"
              value={unitPrice}
              onChange={event => setPrices(event)}
              pattern="[0-9]+(\\.[0-9][0-9]?)?"
              step="0.01"
            />
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Subtotal da venda</h6>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h5>{toBraCurrency(subTotalPrice)}</h5>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Taxa de serviço</h6>
              <p>
                Cobramos 10% de taxa de serviço para te trazer mais clientes. A Celebrar só ganha se
                você ganhar.
              </p>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h5>{toBraCurrency(taxPrice)}</h5>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Valor do repasse </h6>
              <p>
                O valor do repasse será o valor da NF se o orçamento for aprovado, ou seja, este é o
                valor que você receberá em sua conta bancária.
              </p>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h4>{toBraCurrency(supplierFinalPrice)}</h4>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Button align="center" type="button" onClick={() => pricesModalRef.current.close()}>
            Concluído
          </Button>
        </Modal>
      </Item.Content>

      <Modal ref={removeItemModalRef} title="Remover o item">
        <h3>Você tem certeza de que deseja remover esse item?</h3>
        <Button
          onClick={() => handleRemoveItem(item.position)}
          type="button"
          variant="secondary--danger"
          buttonSize="large"
          align="center"
        >
          Sim, remover
        </Button>
      </Modal>

      {addItem && (
        <Item.Details>
          {isAdded(item.label) ? (
            <Button
              align="center"
              buttonSize="large"
              variant="secondary--danger"
              onClick={() => removeItem(item.label, item.price)}
            >
              Remover do meu evento
            </Button>
          ) : (
            <Button
              buttonSize="large"
              align="center"
              onClick={() => addItem(item, quantity)}
              disabled={isExpired}
            >
              Adicionar ao meu evento
            </Button>
          )}
        </Item.Details>
      )}
    </Item>
  );
}
