import styled from 'styled-components';

export const Register = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-white);
`;

Register.Container = styled.div`
  /* Display & Box Model */
  padding: 3rem 0 6rem 0;
`;

Register.Heading = styled.h1`
  margin-top: 7rem;
  /* Colors */
  color: var(--color-primary-light);

  /* Text */
  font-size: 2.2rem;
  font-family: 'Estate', 'Manrope', sans-serif;
  font-weight: 700;
`;

Register.BlocksContainer = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-wrap: wrap;
  gap: 1.3rem;
  padding-top: 1.3rem;
`;

Register.UserTypeBlock = styled.div`
  /* Display & Box Model */
  min-height: 250px;
  flex-basis: 400px;
  padding: 2rem;

  /* Colors */
  color: var(--color-white);

  h2,
  h2 span {
    /* Text */
    font-size: 1.5rem;
    line-height: 1.2;
  }

  h2 span {
    /* Text */
    font-weight: 700;
  }

  p {
    /* Display & Box Model */
    margin: 1rem 0 1.6rem;
    max-width: 250px;

    /* Text */
    line-height: 1.5;
  }

  button {
    /* Display & Box Model */
    padding: 1rem 2rem;
    margin: 0;
    margin-bottom: 1rem;

    /* Color */
    background-color: var(--color-white);

    &:hover,
    &:focus {
      background-color: var(--color-white);
      animation: none;
    }
  }

  &.supplier-register-block {
    /* Color */
    background-color: var(--color-primary-dark);

    button {
      /* Color */
      color: var(--color-primary-dark);
    }
  }

  &.buyer-register-block {
    /* Color */
    background-color: var(--color-purple-dark);

    button {
      /* Color */
      color: var(--color-purple-dark);
    }
  }
`;
