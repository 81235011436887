import styled from 'styled-components';

export const ContactButton = styled.div `
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    position: fixed;
    z-index: 998;
    bottom: 3rem;
    right: 2rem;
    background-color: var(--color-black);
    box-shadow: var(--shadow);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 0.3rem var(--color-primary);

    span{
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        font-size: 3rem;
        font-weight: 300;
        color: var(--color-primary);
        text-align: center;
        text-shadow: 0 1px 0 var(--color-secondary);

        &::after{
            content: '';
            color: var(--color-secondary);

        }
    }
`;