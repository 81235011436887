import React from 'react';
import styled from 'styled-components';

export const FormEditableInput = ({ color, textColor, borderColor, width, ...props }) => {
  return (
    <StyledInput
      color={color}
      textColor={textColor}
      borderColor={borderColor}
      width={width}
      autoComplete="off"
      className='input'
      {...props}
    />
  );
};

const StyledInput = styled.input`
  /* Display & Box Model */
  width: ${({ width }) => (width ? width : '100%')};
  height: 3rem;
  padding: 0 1rem;
  margin: 1rem 0;

  /* Colors */
  background-color: ${({ color }) => (color ? color : 'transparent')};
  color: ${({ colorText }) => (colorText ? colorText : 'var(--color-black)')};

  /* Others */
  border: 1px solid var(--color-border);
  transition: all 0.3s ease-out;

  ::placeholder {
    color: ${({ colorText }) => (colorText ? colorText : 'var(--color-text)')};
  }

  :hover {
    /* Colors */
    background-color: var(--color-grat-light);
    color: var(--color-text);

    /* Others */
    border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : 'var(--color-green)')};
    transition: all 0.3s ease-out;

    ::placeholder {
      color: var(--color-text);
    }
  }

  :focus {
    /* Colors */
    background-color: var(--color-white);
    color: var(--color-black);

    /* Others */
    border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : 'var(--color-green)')};
    transition: all 0.3s ease-out;

    ::placeholder {
      color: var(--color-text);
    }
  }

  :disabled {
    /* Others */
    cursor: default;

    :hover {
      /* Colors */
      background-color: ${({ color }) => (color ? color : 'inherit')};
      color: ${({ colorText }) => (colorText ? colorText : 'inherit')};

      /* Others */
      border: none;
    }
  }

  &.readonly {
    border: none;
  }
`;
