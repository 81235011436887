import React, { useEffect } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../Contexts/AuthContext';
// Styles & Images
import { AuthPage } from '../styles';

function RegisterAccountType() {
    const { user, setLoginRedirect, updateUserData } = useAuthContext();

    useEffect(() => {
        if (user?.type) {
            setLoginRedirect(true);
        }
    }, [user, setLoginRedirect]);

    function setAccountType(type) {
        axios({
            method: 'post',
            url: '/api/auth/select-account-type',
            data: { type },
        })
            .then(response => {
                if (response.status === 200) {
                    updateUserData({ type });
                    setLoginRedirect(true);
                }
            })
            .catch(err => console.log(err.message));
    }

    return (
        <AuthPage className="bg--black">
            <div className="container">
                <AuthPage.AccountTypePage>
                    <div>
                        <AuthPage.AccountTypePageTitle>
                            Escolha o tipo da sua conta
                        </AuthPage.AccountTypePageTitle>

                        <AuthPage.AccountTypePageSubTitle>
                            Gerencie pedidos de eventos, orçamentos e dados pessoais na sua área
                            exclusiva
                        </AuthPage.AccountTypePageSubTitle>
                    </div>

                    <form action="" name="register_account_type" id="form">
                        <AuthPage.AccountTypeGrid>
                            <AuthPage.AccountTypeCard>
                                <h3> Conta Comprador </h3>
                                <p>
                                    {' '}
                                    Peça orçamentos para nossa comunidade de fornecedores, gerencie
                                    orçamentos recebidos e monte seu evento{' '}
                                </p>

                                <button
                                    className="btn btn--center"
                                    type="button"
                                    onClick={() => setAccountType('buyer')}
                                >
                                    <span> Selecionar </span>
                                </button>
                            </AuthPage.AccountTypeCard>

                            <AuthPage.AccountTypeCard>
                                <h3> Conta Fornecedor </h3>
                                <p>
                                    {' '}
                                    Receba alertas sobre demandas de serviços para eventos, envie
                                    orçamentos para clientes e gerencie suas vendas{' '}
                                </p>

                                <button
                                    className="btn btn--center"
                                    type="button"
                                    onClick={() => setAccountType('supplier')}
                                >
                                    <span> Selecionar </span>
                                </button>
                            </AuthPage.AccountTypeCard>
                        </AuthPage.AccountTypeGrid>
                    </form>
                </AuthPage.AccountTypePage>
            </div>
        </AuthPage>
    );
}

export default RegisterAccountType;
