import styled from 'styled-components';
import offerCover from '../../../assets/images/offer_page/offer-cover.png';

export const OfferPage = styled.div``;

/*==============================
        Offer Header
================================*/

OfferPage.Header = styled.header`
  /* Display & Box Model */
  width: 100%;
  height: 21rem;
  padding: 2rem 0;

  /* Colors & Background */
  background-color: var(--color-primary-dark);
  background-image: url(${offerCover});
  background-size: cover;
  color: var(--color-white);

  h1 {
    /* Display & Box Model */
    max-width: 100%;
    margin: 1rem 0;

    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
  }

  a {
    /* Color */
    color: var(--color-white);

    /* Text */
    font-weight: 600;
  }

  p {
    max-width: 15rem;
    margin: 0 0 0.5rem auto;
    font-size: 0.8rem;
    text-align: right;
  }

  @media (min-width: 800px) {
    h1 {
      /* Display & Box Model */
      max-width: 40vw;
      margin: 0;
      margin-bottom: 1rem;

      /* Text */
      font-size: 2rem;
    }

    p {
      max-width: 15rem;
      float: right;
    }
  }
`;

OfferPage.Content = styled.div`
  /* Display & Box Model */
  width: 100%;

  /* Position */
  position: relative;
  top: -6rem;

  @media (min-width: 800px) {
    top: -8rem;
  }
`;

OfferPage.Body = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  box-shadow: var(--shadow);
  border-radius: var(--radius);

  @media (min-width: 800px) {
    padding: 2rem;
  }
`;

OfferPage.OfferItemImagesGrid = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  overflow: hidden;
`;

OfferPage.OfferItemImage = styled.div`
  height: 220px;
  overflow: hidden;

  img {
    max-height: 90%;
  }
`;

/*==============================
      Supplier profile
================================*/

OfferPage.Supplier = styled.div`
  /* Display & Box Model */
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;

  /* Position */
  position: relative;
  top: -1rem;

  figure {
    /* Display & Box Model */
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Others */
    border: 1px solid var(--color-border);
    overflow: hidden;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 100%;
    /* flex-direction: row; */
  }
`;

/*==============================
        Expiration Date
================================*/

OfferPage.OfferExpiration = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Position */
  position: absolute;
  top: 2rem;
  right: 1rem;

  /* Others */
  cursor: pointer;
  z-index: 9;

  @media (min-width: 800px) {
    top: -2.2rem;
    right: 2rem;
  }
`;

OfferPage.OfferExpirationBox = styled.div`
  /* Display & Box Model */
  width: 6rem;
  height: 7.5rem;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-black);

  /* Others */
  border: solid 1px var(--color-yellow-dark);
  border-radius: 0.3rem;
  overflow: hidden;

  span {
    /* Display & Box Model */
    width: 101%;
    display: block;
    padding: 0.5rem;

    /* Colors */
    background-color: var(--color-yellow-dark);
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }

  h5 {
    /* Display & Box Model */
    margin: 0.5rem auto;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }
`;

OfferPage.OfferExpirationText = styled.span`
  /* Display & Box Model */
  margin: 0.5rem auto;

  /* Text */
  font-size: 0.8rem;
  text-align: center;
`;

OfferPage.OfferSentAtBox = styled.div`
  /* Display & Box Model */
  width: 6rem;
  height: 7.5rem;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-black);

  /* Others */
  border: solid 1px var(--color-blue-light);
  border-radius: 0.3rem;
  overflow: hidden;

  span {
    /* Display & Box Model */
    width: 101%;
    display: block;
    padding: 0.5rem;

    /* Colors */
    background-color: var(--color-blue-light);
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }

  h5 {
    /* Display & Box Model */
    margin: 0.5rem auto;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }
`;

/*==============================
          Offer files
================================*/

OfferPage.Files = styled.ul`
  /* Display & Box Model */
  width: 100%;
  padding: 2rem;
  margin-top: 3rem;

  /* Others */
  border: 1px solid var(--color-border);
  border-radius: 3px;

  h3 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-weight: 700;
  }

  p {
    font-size: 0.8rem;
  }

  li {
    /* Display & Box Model */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;
    padding: 0 1rem;

    /* Others */
    border: 1px solid var(--color-border);

    a {
      width: 100%;
      overflow-wrap: anywhere;
    }
  }
`;

OfferPage.ItemsList = styled.div`
  min-height: 360px;
  margin-bottom: 2rem;
  transition: all 0.3s ease-out;

  &.hidden {
    opacity: 0;
    transition: all 0.3s ease-out;
  }
`;

/*==============================
        Offer expired
================================*/

OfferPage.Expired = styled.div`
  /* Display & Box Model */
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  h1 {
    /* Colors */
    color: var(--color-red);

    /* Text */
    font-size: 1.2rem;
    font-weight: 700;
  }
`;
