import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Input } from './Forms/styles';

export const FormSelect = ({ label, icon, errorMessage, ...props }) => {
  return(
    <Input >
      <div className="formTxtInput">
        <label className="hidden">{label}</label>
        <div>
          {icon && <FeatherIcon icon={icon} className="icon" size={23} color="silver" />}
          <select {...props} >
            { props.children }
          </select>
        </div>
        <p className="error-msg">{errorMessage}</p>
      </div>
    </Input>
  )
}
