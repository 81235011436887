import axios from 'axios';
import { useAsync } from '../../../utils/useAsync';

export function useCategoryNotFound() {
  const { run, isLoading, isError, isSuccess } = useAsync();

  const submitCategoryNotFound = ({
    selectedCategoryArea,
    categoryNotFoundDescription,
    suggestedCategory,
  }) => {
    run(
      axios.post('/api/supplier/categories/not-found', {
        categoryArea: selectedCategoryArea,
        description: categoryNotFoundDescription,
        suggestedCategory,
      })
    );
  };

  return {
    submitCategoryNotFound,
    isSubmittingCategoryNotFound: isLoading,
    isErrorCategoryNotFound: isError,
    isSuccessCategoryNotFound: isSuccess,
  };
}
