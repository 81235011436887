import React, { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../Contexts/AuthContext";
import { formatDateAndTime, useAsync } from "../../../utils";
import { NewDemandPage } from "./styles";
import Header from "../../../Components/Header";
import Modal from "../../../Components/Modal";
import { Button } from "../../../Components/common/Buttons/Button";
import { CategoriesImages } from "../../../Components/Categories/CategoriesImages";
import { FormTxtarea } from "../../../Components/common/FormTextarea";

export const CheckoutPage = ({
  setDemandCheckout,
  handleRemoveService,
  extraQuestions,
  myEvent,
  handleAddCategory,
}) => {
  const { signInWithModal } = useAuthContext();
  const { run: runSubmitBriefing } = useAsync();
  const history = useHistory();
  const [selectedExtraQuestions, setSelectedExtraQuestions] = useState({});
  const [eventName, setEventName] = useState('');
  const [error, setError] = useState('');

  const modalRef = useRef();

  // useImperativeHandle(ref, () => ({
  //   open() {
  //     setIsOpen(true);
  //   },
  //   close() {
  //     setIsOpen(false);
  //   },
  // }));

  React.useEffect(() => window.scrollTo(0, 0), []);

  function selectExtraQuestion(question) {
    setSelectedExtraQuestions((selected) =>
      Object.assign({}, selected, {
        [question.name]: { ...question, value: "" },
      })
    );
  }

  function handleChangeEventName (e) {
      setEventName(e.target.value);
  }

  function handleChangeValue(e, name) {
    const newSelectedExtraQuestions = Object.assign(
      {},
      selectedExtraQuestions,
      {
        [name]: { ...selectedExtraQuestions[name], value: e.target.value },
      }
    );
    setSelectedExtraQuestions(newSelectedExtraQuestions);
  }

  function handleSubmitBriefing() {
    console.log(eventName, "(Nome do Evento)");
    runSubmitBriefing(
      axios
        .post("/api/buyer/new-briefing", {
          event: myEvent,
          event_name: eventName,
          extraQuestions: selectedExtraQuestions,
        })
        .then((res) => {
          if (res.status === 201) {
            history.push("/briefing-enviado");
            console.log(res.status);
          }
        })
    );
  }

  return (
    <NewDemandPage.Checkout>
      <Header buyerPage hideButtons />

      <div className="container">
        <NewDemandPage.CheckoutContent>
          <NewDemandPage.BackButton
            type="button"
            style={{ color: "var(--color-primary-light)" }}
            onClick={() => {
              setDemandCheckout(false);
            }}
          >
            &#x2190; voltar
          </NewDemandPage.BackButton>
          <h1 style={{ color: "var(--color-primary-light)" }}>
            Finalizar pedido
          </h1>

          <h3>Nome do evento (obrigatório)</h3>
          <input
            name="event_name"
            className="input"
            id="event_name"
            style={{ marginBottom: '2rem' }}
            required="required"
            onChange={e => setEventName(e.target.value)}
          />

          <h2>Resumo do seu pedido</h2>

          <NewDemandPage.Summary>
            {myEvent.length === 0 ? (
              <NewDemandPage.EmptyEventMessage>
                Você ainda não adicionou nenhum serviço ao seu pedido
              </NewDemandPage.EmptyEventMessage>
            ) : (
              <>
                {myEvent.map((service, index) => {
                  const { category1, ...inputs } = service;
                  return (
                    <div key={index}>
                      <NewDemandPage.SummaryCard>
                        <NewDemandPage.SummaryCardImage>
                          <img
                            src={
                              CategoriesImages.find(
                                (e) => e.categoryId === service.id
                              )?.url
                            }
                            alt={service.name}
                          />
                        </NewDemandPage.SummaryCardImage>

                        <NewDemandPage.SummaryCardBody>
                          <h3>{service.name}</h3>

                          <div className="buttons">
                            <Button
                              buttonSize="small"
                              variant="secondary"
                              onClick={() => handleAddCategory(service)}
                            >
                              Editar
                            </Button>
                            <Button
                              buttonSize="small"
                              variant="secondary--danger"
                              onClick={() => handleRemoveService(service.id)}
                            >
                              Remover do meu pedido
                            </Button>
                          </div>
                        </NewDemandPage.SummaryCardBody>
                      </NewDemandPage.SummaryCard>
                    </div>
                  );
                })}
              </>
            )}
          </NewDemandPage.Summary>

          <NewDemandPage.AdditionalQuestions>
            <h3>Enriqueça o seu pedido</h3>
            <p>
              Você pode adicionar mais informações ao seu pedido. Quanto mais
              informações tivermos, mais assertivas serão as propostas que você
              receberá.
            </p>

            <div className="row">
              {Object.entries(selectedExtraQuestions).length > 0 ? (
                <>
                  {Object.entries(selectedExtraQuestions).map(
                    ([name, question]) => (
                      <div className="col s12 m6" key={name}>
                        <FormTxtarea
                          name={name}
                          label={question.label}
                          placeholder={question.label}
                          icon="align-justify"
                          onChange={(e) => handleChangeValue(e, name)}
                          value={selectedExtraQuestions[name].value}
                        />
                      </div>
                    )
                  )}
                </>
              ) : null}
            </div>
            <div className="extra-questions-buttons">
              {extraQuestions.map((q, index) => (
                <div
                  key={index}
                  className={
                    selectedExtraQuestions[q.name] ? "selected" : undefined
                  }
                  onClick={() => selectExtraQuestion(q)}
                >
                  {q.label} +
                </div>
              ))}
            </div>
          </NewDemandPage.AdditionalQuestions>

          <Button onClick={() => signInWithModal(handleSubmitBriefing)} >
            Finalizar
          </Button>

          {/* <NewDemandPage.ModalErrorMessage style={ error.length > 0 ? { display: 'block' } : { display: 'none' } }>
            <h3>Atenção!</h3>
            <p>O nome do evento é obrigatório</p>
          </NewDemandPage.ModalErrorMessage>
 */}

        </NewDemandPage.CheckoutContent>
      </div>
    </NewDemandPage.Checkout>
  );
};
