import React from 'react';
import styled from 'styled-components';

export const FormEditableTextarea = ({
  color,
  textColor,
  borderColor,
  width,
  height,
  ...props
}) => {
  return(
    <StyledTextarea
      color={color}
      textColor={textColor}
      borderColor={borderColor}
      width={width}
      height={height}
      autoComplete="off"
      {...props}
    />
  )
}

const StyledTextarea = styled.textarea `
  /* Display & Box Model */
  width: ${({width}) => width ? width : '100%'};
  height: ${({height}) => height ? height : '12rem'};
  padding: 1rem;
  margin: 1rem 0;

  /* Colors */
  background-color: ${({color}) => color ? color : 'transparent'};
  color: ${({colorText}) => colorText ? colorText : 'var(--color-black)'};

  /* Others */
  border: 1px solid var(--color-border);
  transition: all 0.3s ease-out;

  ::placeholder{
    color: ${({colorText}) => colorText ? colorText : 'var(--color-text)'};
  }
  
  :hover{
    /* Colors */
    background-color: var(--color-grat-light);
    color: var(--color-text);
    
    /* Others */
    border: 1px solid ${({borderColor}) => borderColor ? borderColor : 'var(--color-green)'}; 
    transition: all 0.3s ease-out;

    ::placeholder{
      color: var(--color-text);
    }
  }
  
  :focus{
    /* Colors */
    background-color: var(--color-white);
    color: var(--color-black);
    
    /* Others */
    border: 1px solid ${({borderColor}) => borderColor ? borderColor : 'var(--color-green)'}; 
    transition: all 0.3s ease-out;

    ::placeholder{
      color: var(--color-text);
    }
  }

  :disabled{
    /* Others */
    cursor: default;

    :hover{
      /* Colors */
      background-color: ${({color}) => color ? color : 'inherit'};
      color: ${({colorText}) => colorText ? colorText : 'inherit'};

      /* Others */
      border: none;
    }
  }
`;