import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { ButtonIconOnly } from "../common/Buttons/ButtonIconOnly";
import { Close } from "../common/Icons/IconsFamily";
import { ModalStyles } from "./styles";

const Modal = forwardRef(({ title, modalSize, onClose, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(null);
  const [isClosing, setIsClosing] = useState(null);

  const modalSizes = [
    {
      name: "default",
      size: "500px",
    },
    {
      name: "inherit",
      size: "fit-content",
    },
    {
      name: "large",
      size: "800px",
    },
    {
      name: "small",
      size: "300px",
    },
    {
      name: "extra_large",
      size: "1140px",
    },
    {
      name: "full",
      size: "95%",
    },
  ];

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
      document.querySelector("body").style.overflow = "hidden";
    },
    close() {
      setIsClosing(true);

      setTimeout(() => {
        setIsOpen(false);
        document.querySelector("body").style.overflow = "inherit";
        if (typeof onClose === "function") {
          onClose();
        }
      }, 500);
    },
  }));

  useEffect(() => {
    return () => (document.querySelector("body").style.overflow = "inherit");
  }, []);

  useEffect(() => {
    isOpen === false && setIsClosing(null);
  }, [isOpen]);

  function close() {
    setIsClosing(true);

    setTimeout(() => {
      setIsOpen(false);
      document.querySelector("body").style.overflow = "inherit";
      if (typeof onClose === "function") {
        onClose();
      }
    }, 500);
  }

  function setModalSize() {
    if (modalSize && modalSizes.find((e) => e.name === modalSize)) {
      return modalSizes.find((e) => e.name === modalSize).size;
    } else {
      return modalSizes.find((e) => e.name === "default").size;
    }
  }

  return (
    <>
      {isOpen && (
        <ModalStyles>
          <ModalStyles.LightBox
            className={isClosing && "closing"}
            onClick={close}
          />

          <ModalStyles.Container
            className={isClosing && "closing"}
            size={setModalSize}
          >
            {title ? (
              <ModalStyles.Title>
                {title}

                <ButtonIconOnly onClick={close} type="button">
                  <Close color="var(--color-white)" />
                </ButtonIconOnly>
              </ModalStyles.Title>
            ) : (
              <ButtonIconOnly
                onClick={close}
                type="button"
                className="absolute"
              >
                <Close color="var(--color-red)" />
              </ButtonIconOnly>
            )}

            <ModalStyles.Content size={modalSize} className={props.className} style={props.style}>
              {props.children}
            </ModalStyles.Content>

            <ModalStyles.Cancel>
              <p onClick={close}>Cancelar</p>
            </ModalStyles.Cancel>
          </ModalStyles.Container>
        </ModalStyles>
      )}
    </>
  );
});

export default Modal;
