import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';

// import Header from '../../Components/Header';
import ProposalJsonSaoPaulo from '../../Json/proposal.json';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';
import Modal from '../../Components/Modal';

import { ProposalPage, Calculate, ProposalFooter, ProposalHeader, FooterSpace, SliderController } from './styles';
import Logo from '../../assets/images/logos/celebrar/Celebrar_Branco_fundo_alpha.png';
import LeftArrow from '../../assets/icons/arrowLeft.svg';
import RightArrow from '../../assets/icons/arrowRight.svg';
import CardArrow from '../../assets/icons/arrowCard.svg';
import OfferItems from './offerItems';
import FeaturedItem from './featuredItem';

function Box(props){

    // const [items, setItem] = useState('');
    const [proposal, setProposal] = useState('');
    const [proposalSections, setProposalSections] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [modalImages, setModalImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    // Featured item states
    const [featuredItem, setFeaturedItem] = useState();

    const modalRef = useRef();

    // const [items, setItems] = useState();

    const { handle, id } = props.match.params;

    /*
    useEffect( () => {

        console.log(handle, id);

        let proposal = JSON.parse(ProposalJsonSaoPaulo.proposal.json_object);
        setProposal(proposal);
        setProposalSections(proposal.sections);

        if( id ){

            let item;

            proposal.sections.map( section => {
                return(
                    item = section.items.find( item => item.id === parseInt( id ) )
                )
            });

            setFeaturedItem( item );

        }
        // setItems( proposal.sections[0].items );

    }, [] );

    */

    useEffect( ()=> {
        const { handle, id } = props.match.params;
        // const { handle } = props.match.params;

        console.log("Pergunta");

        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.post(`/api/proposal/${handle}`)
        .then(res => {

            // console.log(handle, id);

            console.log(JSON.parse(res.data.proposal.json_object));

            let proposal = JSON.parse(res.data.proposal.json_object);

            setProposal(proposal);
            setProposalSections(proposal.sections);

            if( id ){

                let item;

                proposal.sections.map( section => {
                    return(
                        item = section.items.find( item => item.id === parseInt( id ) )
                    )
                });

                setFeaturedItem( item );

            }
            // setItems( proposal.sections[0].items );

            // Old stuff
            // const items = res.data.items;
            // const proposal = res.data.proposal;
            // setItem(items);
            // console.log(res.data);

            // const oldFormat = res.data.proposal;
            // const proposal = JSON.parse( oldFormat.json_object );

            // console.log(proposal);

            // localStorage.setItem('proposalId', res.data.proposal_id);

            // console.log('proposal id no ls= ' + localStorage.getItem('proposalId'));

            // if( proposal ){

            //     setProposal(proposal);
            //     setProposalSections( proposal.sections );
            //     setItem(items);

            // }
            
            // hideCategoriesNames();
            
        })
        .catch(err => console.log(err));

    }, []);

    // function hideCategoriesNames(){
    //     let categories = Array.from(document.querySelectorAll('.card__category'));
    //     let idCategories = [];

    //     categories.forEach( category => {
    //         if( !idCategories.includes(category.dataset.category) ){
    //             idCategories.push(category.dataset.category);
    //         }
    //     });

    //     idCategories.forEach( id => {
    //         document.querySelectorAll(`[data-category='${id}']`)[0].classList.add('card__category--show');
    //     });
    // }

    function selectItem(event, item, index){
        let card = event.parentElement.parentElement,
            filtered = selectedItems.filter( selectedItem => selectedItem.id === item.id ),
            newItems = selectedItems;

        card.classList.toggle('selected');
        
        if(filtered.length > 0){
            let reset = selectedItems.filter( selectedItem => selectedItem.id !== item.id );
            setSelectedItems(reset);

            // checks if additional fee was previously calculated
            let fee = item.final_additional_fee ?? parseFloat(calculateAdditionalPrice(item.additional_fee));

            // if( item.additional_fee && item.additional_fee_name === "Frete"){

            //     setShipping(shipping - parseFloat(item.fee));
            //     setTotalPrice(totalPrice - (parseFloat(item.buyer_price) * parseFloat(item.quantity)));

            // } else 
            
            if( item.additional_fee ) {

                setTotalPrice(totalPrice - (parseFloat(item.final_buyer_price) + parseFloat(fee)));

            } else {

                console.log(totalPrice, (parseFloat(item.final_buyer_price)))
                setTotalPrice(totalPrice - (parseFloat(item.final_buyer_price)));

            }

        } else {
            item['index'] = index;
            newItems.push(item);
            setSelectedItems(newItems);

            // checks if additional fee was previously calculated
            let fee = item.final_additional_fee ?? parseFloat(calculateAdditionalPrice(item.additional_fee));

            // if( item.additional_fee && item.additional_fee_name === "Frete"){

            //     setShipping( shipping + parseFloat(item.fee));
            //     setTotalPrice(totalPrice + (parseFloat(item.buyer_price) * parseFloat(item.quantity)));

            // } else 
            
            if( item.additional_fee ) {

                setTotalPrice(totalPrice + (parseFloat(item.final_buyer_price) + parseFloat(fee) ));

            } else {

                setTotalPrice(totalPrice + (parseFloat(item.final_buyer_price)));

            }
        }
    }

    function saveItemsOnLocalStorage(){

        localStorage.setItem('totalPrice', totalPrice);
        localStorage.setItem('shipping', shipping);
        localStorage.setItem('items', JSON.stringify(selectedItems));
        localStorage.setItem('proposal', proposal.name);

    }
    

    function accordion(element){
        element.classList.toggle('open');
    }

    function imageOnModal(image){
        // modalRef.current.open();
        // let teste = [ImgTeste, ImgTeste2, ImgTeste3];
        // setModalImages(teste);
    }

    function slideModal(newIndex){
        if(newIndex >= 0  && newIndex <= modalImages.length - 1){
            setCurrentImageIndex(newIndex);
        }
    }

    /*===================================
        Item calc pricing functions
    ====================================*/

    function calculateSupplierSalePrice(price){

        return parseFloat(price) * 0.9;

    }

    function calculateAdditionalPrice(additional_fee) {

        let additional_with_rate = parseFloat(additional_fee / 0.90);

        // console.log('additional_with_rate = ' + additional_with_rate / 0.85);

        return (parseFloat(additional_with_rate)) / 0.85 ;

    }


    function calculateSubtotal(obj){

        return parseFloat(obj.price) * parseFloat(obj.quantity);

    }


    function calculateTotal(obj){

        let subtotal = calculateSubtotal(obj);
        
        if( obj.additional ){

            let total = subtotal + calculateAdditionalPrice(obj.additional);
            return parseFloat(total);

        } else {

            return subtotal;

        }

    }

    /*===================================
    Returns value in Brazilian currency
    ====================================*/

    function toBraCurrency(value){
        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
    }

    return(
        <div className="bg--gray-light">
            <ProposalHeader >
                <div className="container">
                    <div className="header__content">
                        <div className="header__logo">
                            <figure className="logo">
                                <a href="https://celebrar.co">
                                    <img src={Logo} alt=""/>
                                </a>
                            </figure>
                            <h3> Celebrar Box </h3>
                        </div>

                        <div className="header__text">
                            <p> Celebrar Tecnologia e Intermediações Ltda </p>
                            <p> CNPJ: 28.282.186/0001-38 </p>
                        </div>
                    </div>
                </div>

                <div className="container margin--top-2">
                    <h1 className="pageheader__title--sub txt--left"> 
                        { proposal.name }
                    </h1>
                </div>
            </ProposalHeader>

            <div className="container">

                {
                    id && featuredItem && (
                        <FeaturedItem 
                            index={ 15 }
                            item={ featuredItem }
                            selectItem={ selectItem }
                            toBraCurrency={ toBraCurrency }
                            calculateAdditionalPrice={ calculateAdditionalPrice }
                            calculateTotal={ calculateTotal }
                        />
                    )
                }

                <ProposalPage >
                    <div >
                        {
                            proposalSections.length > 0 && (
                                proposalSections.map( section => {
                                    return(
                                        <ProposalPage.Section key={ section.id } id={section.id} >
                                            <header >
                                                { section.name !==- '' ? section.name : '' }
                                            </header>
                                            {
                                                section.items.length > 0 ? (
                                                    section.items.map( (item, index) => {
                                                        return(
                                                            item.id !== parseInt(id) && (                                                                
                                                                <OfferItems 
                                                                    key={ index }
                                                                    index={ index }
                                                                    item={ item }
                                                                    selectItem={ selectItem }
                                                                    toBraCurrency={ toBraCurrency }
                                                                    calculateAdditionalPrice={ calculateAdditionalPrice }
                                                                    calculateTotal={ calculateTotal }
                                                                />
                                                            )
                                                        )
                                                    }, [])
                                                ) : ""
                                            }
                                        </ProposalPage.Section>
                                    )
                                })
                            )
                        }

                        <ProposalFooter >
                            <div className="container">
                                <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                                    <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Formas de pagamento </h3>
                                    <p className="accordion__text">
                                        Cartão de Crédito, Débito, Boleto ou TED. Faturamento até 30 dias sujeito análise. <br />
                                        Em caso de atraso no pagamento, o valor terá incidência de juros de mora de 0,5% ao dia, pro rata die, mais multa de 2% ao mês. <br />
                                    </p>
                                </div>

                                <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                                    <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Política de cancelamento </h3>
                                    <p className="accordion__text">
                                        <b> a) Flexível: </b> Fornecedor oferece reembolso integral para cancelamentos com mais de 7 dias antes do evento e nenhum reembolso para cancelamentos feitos com menos de 7 dias antes da entrega. <br />
                                        <b> b) Moderada: </b> Fornecedor oferece reembolso integral para cancelamentos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega. <br />
                                        <b> c) Rigorosa: </b> Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega. <br />
                                        <b> d) Conservadora: </b> Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 30 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 30 dias antes da entrega. <br />
                                    </p>
                                </div>

                                <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                                    <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Termos de uso </h3>
                                    <p className="accordion__text"> Ao aceitar esta proposta declaro que li e aceito os <a href="https://celebrar.co/termos" target="_blank"> termos de uso Celebrar </a></p>
                                </div>
                            </div>
                        </ProposalFooter>

                        </div>

                        <Fragment ></Fragment>
                        <Calculate>
                            <div className="calculate__content">
                                <div className="calculate__numbers">
                                    <h3> Quantidade de itens </h3>
                                    <h4 className=""> { selectedItems.length } </h4>
                                </div>

                                {/* <div className="calculate__numbers">
                                    <h3> Frete </h3>
                                    <h4 className=""> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( shipping )} </h4>
                                </div> */}

                                <div className="calculate__numbers calculate__numbers--subtotal txt--secondary">
                                    <h3 className="txt--bold"> Subtotal </h3>
                                    <h4 className=""> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( totalPrice )} </h4>
                                    {/* <h4 className=""> { toBraCurrency() } </h4> */}
                                </div>

                                <button className="btn" onClick={saveItemsOnLocalStorage} disabled={ selectedItems.length > 0 ? false : true }>
                                    { selectedItems.length > 0 ? (
                                        <Link to="/carrinho" > Ir para o carrinho </Link>
                                    ) : (
                                        <span > Ir para o carrinho </span>
                                    )}
                                </button>
                            </div>
                        </Calculate>

                    </ProposalPage >
                </div>


                <Modal ref={modalRef} id="modal">
                    { 
                        modalImages.map( (image, index) => {
                            return(
                                index === currentImageIndex ? (
                                    <div>
                                        <img src={image} alt=""/>

                                        <SliderController >
                                            <div onClick={ () => slideModal(currentImageIndex - 1) }>
                                                <img src={LeftArrow} alt=""/>
                                            </div>
                                            <div onClick={ () => slideModal(currentImageIndex + 1) }>
                                                <img src={RightArrow} alt=""/>
                                            </div>
                                        </SliderController>
                                    </div>
                                ) : ''
                            )
                        })
                    }
                </Modal>

            <Footer />
            <FooterSpace />

        </div>
    )
}

export default Box