import styled, { keyframes } from 'styled-components';

const loadAnimation = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`;

const fadeinLoadAnimation = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;

export const Load = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: rgba(0, 0, 0, 0.6);

  /* Text */
  text-align: center;

  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  /* Others */
  animation: ${fadeinLoadAnimation} 0.3s ease-out 0s forwards;
  opacity: 0;
`;

Load.Icon = styled.div`
  /* Display & Box Model */
  width: 3rem;
  height: 3rem;

  /* Others */
  animation: ${loadAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border: solid 0.3rem var(--color-secondary);
  border-top: solid 0.3rem var(--color-gray);
  border-radius: 50%;
  box-shadow: var(--shadow);
`;

Load.SmallIcon = styled.div`
  /* Display & Box Model */
  width: 1.5rem;
  height: 1.5rem;

  /* Others */
  animation: ${loadAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  border: solid 0.2rem var(--color-secondary);
  border-top: solid 0.2rem var(--color-gray);
  border-radius: 50%;
`;

Load.CenterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

Load.FullPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  > div {
    margin-top: -4rem;
  }
`;
