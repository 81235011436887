import styled, { keyframes } from 'styled-components';

const Anime = keyframes`
    from {
        transform: translateY(-3rem);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const SuccessAlertBox = styled.div`
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 999;
    transition: all 0.3s ease-out;

    div {
        animation: ${Anime} 0.3s ease-in-out;
        width: 100%;
        padding: 1rem;
        text-align: left;
        background-color: #10b981;
        box-shadow: var(--shadow);
        color: var(--color-text-in-black);
        border-right: solid 0.3rem #10b981;
    }

    @media (min-width: 800px) {
        max-width: 25rem;
    }
`;
