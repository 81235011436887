import React, { useState, useEffect, useRef } from 'react';
import { CategoriesHeader } from '../../../Components/Categories/CategoriesHeader.js';
import { CategoriesBody } from '../../../Components/Categories/CategoriesBody.js';
import Header from '../../../Components/Header';
import axios from 'axios';
import { useAsync } from '../../../utils/useAsync';

import { DemandStyled } from './Demand.styles.tw';
import Modal from '../../../Components/Modal/index.js';
import { DemandServiceForm } from './DemandServiceForm.js';
import { EventSummary } from './EventSummary.js';
import { CheckoutPage } from './CheckoutPage.js';
import { ModalStyles } from '../../../Components/Modal/styles.js';

function NewDemandV2() {
  const { run, data } = useAsync();
  const categories = data?.categories || [];
  const questions = data?.questions || [];
  const extraQuestions = data?.extraQuestions || [];
  const modalRef = useRef();
  const maintenanceModalRef = useRef();

  const [currentCategory, setCurrentCategory] = useState(28);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [myEvent, setMyEvent] = useState([]);
  const [demandCheckout, setDemandCheckout] = useState(false);
  const [formQuestions, setFormQuestions] = useState([]);

  const serviceModalBtnText = myEvent?.some(service => service.id === selectedCategory?.id);

  useEffect(() => {
    run(axios.get('/api/buyer/load-new-demand').then(res => res.data));
  }, [run]);

  useEffect(() => {
    if (selectedCategory) {
      const categoriesIdsToFilterQuestions = [
        selectedCategory.id,
        selectedCategory.parentId,
        currentCategory,
      ];
      const categoryQuestions = questions.filter(q =>
        q.categories.some(c => categoriesIdsToFilterQuestions.includes(c))
      );
      setFormQuestions(categoryQuestions);
      modalRef.current.open();
    }
  }, [selectedCategory]);

  function addToEvent(demandService) {
    const serviceWithoutEmptyValues = Object.entries(demandService).reduce(
      (service, [name, data]) => {
        if (
          (Array.isArray(data.value) && data.value.filter(v => v).length === 0) ||
          (typeof data.value !== 'boolean' && !data.value)
        ) {
          return service;
        }

        if (Array.isArray(data.value)) {
          service[name] = { ...data, value: data.value.filter(v => v) };
          return service;
        }

        service[name] = data;
        return service;
      },
      {}
    );

    if (Object.keys(serviceWithoutEmptyValues).length > 0) {
      const newDemand = {
        category1: {
          name: categories?.find(cat => cat.id === currentCategory).name,
          id: currentCategory,
        },
        id: selectedCategory?.id,
        name: selectedCategory?.name,
        parentId: selectedCategory?.parentId,
        ...serviceWithoutEmptyValues,
      };

      if (myEvent.some(e => e.id === selectedCategory?.id)) {
        let newMyEvent = Object.create(myEvent);
        newMyEvent = newMyEvent.filter(demand => demand.id !== selectedCategory?.id);
        setMyEvent([...newMyEvent, newDemand]);
      } else {
        setMyEvent([...myEvent, newDemand]);
      }

      modalRef.current.close();
    }
  }

  function handleAddCategory(category) {
    setSelectedCategory(category);
  }

  function handleRemoveDemand(categoryId) {
    const newMyEvent = myEvent.filter(demand => demand.id !== categoryId);
    setMyEvent(newMyEvent);
  }

  if (demandCheckout) {
    return (
      <>
        <CheckoutPage
          extraQuestions={extraQuestions}
          handleRemoveService={handleRemoveDemand}
          setDemandCheckout={setDemandCheckout}
          questions={formQuestions}
          addToEvent={addToEvent}
          myEvent={
            myEvent.filter(service => service.id === selectedCategory?.id).length > 0
              ? myEvent.filter(service => service.id === selectedCategory?.id)
              : myEvent
          }
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleAddCategory={handleAddCategory}
        />

        <Modal
          ref={modalRef}
          onClose={() => setSelectedCategory(null)}
          className="bg--ice"
          style={{ padding: 0 }}
        >
          <DemandStyled.ModalHeader>
            <h3>{selectedCategory?.name}</h3>
          </DemandStyled.ModalHeader>

          <div style={{ padding: '2rem' }}>
            <DemandServiceForm
              questions={formQuestions}
              addToEvent={addToEvent}
              buttonText="Salvar alterações"
              myEvent={
                myEvent.filter(service => service.id === selectedCategory?.id).length > 0
                  ? myEvent.filter(service => service.id === selectedCategory?.id)
                  : myEvent
              }
            />
          </div>
        </Modal>
      </>
    );
  }

  return (
    <div style={{ overflowY: `hidden`, height: '100vh' }}>
      <DemandStyled
        style={{
          backgroundColor: 'var(--color-ice)',
        }}
      >
        <div className="content">
          <Header />
          <div className="categoriesNav">
            <div className="container">
              <CategoriesHeader
                groupedCategories={categories}
                currentCategory={currentCategory}
                setCurrentCategory={setCurrentCategory}
              />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col s12 m6 l8">
                <CategoriesBody
                  categories={categories}
                  currentCategory={currentCategory}
                  handleAddCategory={handleAddCategory}
                  handleRemoveCategory={handleRemoveDemand}
                  addedCategories={myEvent}
                />
              </div>

              <div className="col s12 m6 l4">
                <EventSummary
                  newDemand={myEvent}
                  handleAddCategory={handleAddCategory}
                  handleRemoveCategory={handleRemoveDemand}
                  setDemandCheckout={setDemandCheckout}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          ref={modalRef}
          onClose={() => setSelectedCategory(null)}
          className="bg--ice"
          style={{ padding: 0 }}
        >
          <DemandStyled.ModalHeader>
            <h3>{selectedCategory?.name}</h3>
          </DemandStyled.ModalHeader>

          <div style={{ padding: '2rem' }}>
            <DemandServiceForm
              questions={formQuestions}
              addToEvent={addToEvent}
              buttonText={serviceModalBtnText ? 'Salvar alterações' : 'Adicionar ao meu Pedido'}
              myEvent={
                serviceModalBtnText
                  ? myEvent.filter(service => service.id === selectedCategory?.id)
                  : myEvent
              }
            />
          </div>
        </Modal>

        <div
          style={{
            zIndex: 999,
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: `rgba(0, 0, 0, 0.8)`,
            backdropFilter: `blur('5px')`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#FFF',
              width: '26rem',
              padding: '2.5rem 2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '3px',
              gap: '1.8rem',
            }}
          >
            <h1 style={{ fontSize: '1.4rem' }}>Atenção</h1>
            <p style={{ textAlign: 'center', fontSize: '1.1rem' }}>
              O sistema Celebrar para novos pedidos de compradores estará fora do ar até dia 20/03.{' '}
              <br />
              <br />
              Por favor envie um email para meajuda@celebrar.com.br ou whatsapp para +55 11
              94788-0690.
              <br />
              <br />
              Lamentamos pelo transtorno.
            </p>
          </div>
        </div>
      </DemandStyled>
    </div>
  );
}

export default NewDemandV2;
