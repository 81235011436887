import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqProviderPlatform() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />

      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question1'>
              <p>
                Quem somos?
              </p>
            </a>
            <a href='#question2'>
              <p>
                O que é a Plataforma Celebrar?
              </p>
            </a>
            <a href='#question3'>
              <p>
                Quais são as áreas de atuação presentes na plataforma?
              </p>
            </a>
            <a href='#question4'>
              <p>
                O que fazer quando a plataforma apresentar um erro?
              </p>
            </a>
            <a href='#question5'>
              <p>
                Como posso compartilhar algum comentário ou sugestões de recursos da plataforma?
              </p>
            </a>
            <a href='#question6'>
              <p>
                Como tenho acesso ao “Regulamento/Termos de Uso”?
              </p>
            </a>
            <a href='#question7'>
              <p>
                Quanto custa usar a plataforma?
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Quem somos?
              </h2>
              <p>
                Somos uma tecnologia que reúne uma  comunidade formada por fornecedores de todo o Brasil, reunimos talentos de todas as partes do país para a realização de eventos e experiências corporativas.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                O que é a Plataforma Celebrar?
              </h2>
              <p>
                A Plataforma da Celebrar é um ambiente online que conecta grandes marcas que precisam de serviços relacionados à organização, preparo e assistência a eventos com pessoas que prestam serviços relacionados à organização, preparo, execução e assistência a eventos, a nossa comunidade de fornecedores com cadastro ativo no CNPJ na modalidade MEI.
              </p>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question3'>
                Quais são as áreas de atuação presentes na plataforma?
              </h2>
              <p>
                Temos 10 áreas de atuação subdivididas em 128 micro-categorias
              </p>
              <ul>
                <li>
                  Alimentos e bebidas
                </li>
                <li>
                  Brindes personalizados
                </li>
                <li>
                  Staff
                </li>
                <li>
                  Fotografia e filmagem
                </li>
                <li>
                  Música e entretenimento
                </li>
                <li>
                  Logística e movimentos
                </li>
                <li>
                  Produção e conteúdo
                </li>
                <li>
                  Decoração e iluminação
                </li>
                <li>
                  Espaços para eventos
                </li>
                <li>
                  Audiovisual e infraestrutura
                </li>
              </ul>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question4'>
                O que fazer quando a plataforma apresentar um erro?
              </h2>
              <p>
                Caso a plataforma apresente algum erro, nos envie uma mensagem explicando o que aconteceu e se possível anexe uma imagem (captura de tela), por e-mail para tecnologia@celebrar.com.br. Temos um time de tecnologia atuando de segunda a sexta das 10h às 19h para te auxiliar, caso tenha algum problema técnico, não hesite em nos escrever.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question5'>
                Como posso compartilhar algum comentário ou sugestões de recursos da plataforma?
              </h2>
              <p>
                Comentários e feedbacks de melhoria são sempre bem-vindos e nos ajudam a construir uma comunidade melhor para todos, nos envie sua mensagem através do campo “Feedback da Plataforma”.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question6'>
                Como tenho acesso ao “Regulamento/Termos de Uso”?
              </h2>
              <p>
                Os<span> “Termos e Condições de Uso da Celebrar”</span> podem ser conferidos no site da Celebrar, em <span>Termos de uso da Plataforma, Política de Privacidade, Termos - Fornecedores</span> e <span>Termos- Compradores</span>.
              </p>
              <p>
                Nossa <span>“Política de privacidade”</span> mostra o compromisso da Celebrar com a segurança e a privacidade das informações coleta.das dos usuários ("Usuários") de seus serviços. Os <span>“Termos de uso da Plataforma”</span> falam sobre o uso e acesso ao site e serviços disponibilizados por nós, os <span>"Termos de Fornecedores”</span> regem a venda dentro da plataforma, os direitos e deveres do fornecedor.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question7'>
                Quanto custa usar a plataforma?
              </h2>
              <p>
                O cadastro em nossa plataforma é totalmente gratuito, não cobramos mensalidades e você não paga nada para enviar orçamentos para as oportunidades.
              </p>
              <p>
                Cobramos uma taxa de serviço de 10% nas vendas fechadas pela plataforma.
                Só ganhamos se você ganhar, sempre!
              </p>
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >



  );
}