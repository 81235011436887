import React, { useState } from 'react';
import { HomePage } from './styles';
import { AngleLeft, AngleRight } from '../../Components/common/Icons/IconsFamily';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

// Clients Logo
import nubank from '../../assets/images/logos/clients/nubank-logo.png';
import unilever from '../../assets/images/logos/clients/unilever-logo.png';
import quintoAndar from '../../assets/images/logos/clients/quinto-andar.png';
import anima from '../../assets/images/logos/clients/anima.png';
import loggi from '../../assets/images/logos/clients/loggi.png';
import deloitte from '../../assets/images/logos/clients/deloitte.png';
import rappi from '../../assets/images/logos/clients/rappi.png';
import braskem from '../../assets/images/logos/clients/braskem.png';
import cea from '../../assets/images/logos/clients/cea.png';
import alura from '../../assets/images/logos/clients/alura.png';
import raizen from '../../assets/images/logos/clients/raizen.png';
import lv from '../../assets/images/logos/clients/learning-village.png';
import distrito from '../../assets/images/logos/clients/distrito.png';
import google from '../../assets/images/logos/clients/google-for-startups.png';
import abstartups from '../../assets/images/logos/clients/8-celebrar-cliente-abstartup.png';
import wework from '../../assets/images/logos/clients/wework.png';
import ciet from '../../assets/images/logos/clients/ciet.png';
import bp from '../../assets/images/logos/clients/bp.png';
import bsci from '../../assets/images/logos/clients/boston-scientific.png';
import rock from '../../assets/images/logos/clients/rock-world.png';
import t4f from '../../assets/images/logos/clients/t4f.png';
import hsm from '../../assets/images/logos/clients/hsm.png';
import housi from '../../assets/images/logos/clients/housi.png';
import sami from '../../assets/images/logos/clients/sami.png';

function Buyers(){

  const [currentIndex, setCurrentIndex] = useState(0);
  // Grid columns design by screen size
  const logosPerSection = () => {
    let screenSize = parseInt(window.innerWidth);
    switch (true) {
      case screenSize <= 500:
        return 2;

      case screenSize >= 501 && screenSize <= 899:
        return 5;

      default:
        return 8;
    }
  };
  // const logosPerSection = 8;
  const logos = [
    {
      name: "Nubank",
      image: nubank
    },
    {
      name: "Uniliver",
      image: unilever
    },
    {
      name: "Quinto Andar",
      image: quintoAndar
    },
    {
      name: "Ecossistema Anima",
      image: anima
    },
    {
      name: "Loggi",
      image: loggi
    },
    {
      name: "Deloitter",
      image: deloitte
    },
    {
      name: "Rappi",
      image: rappi
    },
    {
      name: "Braskem",
      image: braskem
    },
    {
      name: "C&A",
      image: cea
    },
    {
      name: "Alura",
      image: alura
    },
    {
      name: "Raízen",
      image: raizen
    },
    {
      name: "Learning Village",
      image: lv
    },
    {
      name: "Distrito",
      image: distrito
    },
    {
      name: "Google for Startups",
      image: google
    },
    {
      name: "ABStartups",
      image: abstartups
    },
    {
      name: "Wework",
      image: wework
    },
    {
      name: "CI&T",
      image: ciet
    },
    {
      name: "BP",
      image: bp
    },
    {
      name: "BSCI",
      image: bsci
    },
    {
      name: "Rock World SA",
      image: rock
    },
    {
      name: "T4F",
      image: t4f
    },
    {
      name: "hsm",
      image: hsm
    },
    {
      name: "Housi",
      image: housi
    },
    {
      name: "Sami",
      image: sami
    },
  ];

  return(
    <HomePage.Buyers>
    <div className="container">
      <h3>NOSSOS CLIENTES COMPRADORES</h3>

      <HomePage.BuyersLogos>
        <HomePage.BuyersLogos.Btn
          type="button"
          onClick={() => {
            currentIndex > 0 && setCurrentIndex(currentIndex - 1)
          }}
        >
          <AngleLeft color={currentIndex === 0 ? "var(--color-gray)" : "var(--color-primary-light)"} />
        </HomePage.BuyersLogos.Btn>
        {
          logos.map((logo, index) => {
            return(
                index >= currentIndex && index <= currentIndex + (logosPerSection() - 1) && (
                  <figure key={index}>
                    <img src={logo.image} loading="lazy" alt={logo.name} />
                  </figure>
                )
            )
          })
        }
        <HomePage.BuyersLogos.Btn
          type="button"
          onClick={() => {
            currentIndex < logos.length - logosPerSection() && setCurrentIndex(currentIndex + 1)
          }}
        >
          <AngleRight
            color={ currentIndex < logos.length - logosPerSection() ? "var(--color-primary-light)" : "var(--color-gray)"}
          />
        </HomePage.BuyersLogos.Btn>

      </HomePage.BuyersLogos>

      <Link
        to="/comprador/pedido"
        style={{display: 'block', width: '100%', textAlign: 'center'}}
      >
            <Button buttonSize="large" align="center">Comece agora</Button>
      </Link>

    </div>

  </HomePage.Buyers>
  )
}

export default Buyers;