import styled from 'styled-components';

export const FooterStyled = styled.footer `
  /* Display & Box Model */
  width: 100%;
  padding-top: 2rem;
  
  /* Colors */
  background-color: var(--color-gray-light);

  /* Others */
  border-top: 1px solid var(--color-border);

  h4, h5{
    font-size: 1.4rem;
  }

  h4{
    /* Colors */
    color: var(--color-primary-light);
  }
  
  .container{
    div{
      /* Display & Box Model */
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      margin: 1rem auto;

      :last-child{
        flex-direction: column-reverse;
      }

      button{
        margin: 0;
      }

      ul{
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  }

  @media (min-width: 600px){
    .container{
      div{
        flex-direction: row;
        margin: 2rem 0;

        :last-child{
          flex-direction: row;
        }
      }
    }
  }
@media print {
  display: none;
}
`;

FooterStyled.CNPJ = styled.div `
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
  
  /* Colors */
  color: var(--color-text);

  /* Others */
  border-top: 1px solid var(--color-border);
  
  p{
    /* Text */
    font-size: 0.8rem;
  }
  
  @media (min-width: 600px){
    height: 3rem;
    flex-direction: row;
    gap: 2rem;
  }
`;

FooterStyled.Terms = styled.div `
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;
  
  /* Colors */
  color: var(--color-text);
  a{
    font-size: 0.8rem;
    color: var(--color-text);
  }
  
  @media (min-width: 600px){
    height: 3rem;
    flex-direction: row;
    gap: 2rem;
  }
`;