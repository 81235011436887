import React from 'react';
import styled from 'styled-components';
import { Button } from '../common/Buttons/Button';
import FeatherIcon from 'feather-icons-react';
import {Link} from "react-router-dom";

export default function AlertFixed({ title, content, btnTxt, btnOnClick }) {
  return (
    <AlertCard>
      <h1>{title}</h1>
      <p>{content}</p>
      {btnTxt ? (
        <Link to="/fornecedor/finalizar-cadastro/empresa">
          <Button size="small" onClick={btnOnClick}>
            {btnTxt} <FeatherIcon icon="arrow-right" className="icon" size={15} color="silver" />
          </Button>
        </Link>
      ) : (
        ''
      )}
    </AlertCard>
  );
}

const AlertCard = styled.div`
  h1 {
    font-weight: normal;
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: lighter;
    font-size: small;
  }
  Button {
  }
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px silver;
  padding: 2rem;
  padding-bottom: 0rem;
  position: fixed;
  bottom: 1rem;
  right: 20%;
  width: 28rem;
  z-index: 9999;
`;
