import styled from 'styled-components';

export const UserPage = styled.div `

`;

UserPage.Title = styled.div `
    /* Display  & Box Model */
    width: 100%;
    margin-bottom: 2rem;
    
    h3{
        color: var(--color-black);
        font-size: 1.6rem;
    }
`;

export const UserPageAside = styled.aside `
    /* Display  & Box Model */
    width: 100%;
    padding-top: 2rem;

    /* Text */
    text-align: center;
`;

UserPageAside.User = styled.div `
    /* Display  & Box Model */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
`;

UserPageAside.Photo = styled.figure `
    /* Display  & Box Model */
    width: 10rem;
    height: 10rem;
    background-color: var(--color-gray-light);
    margin: auto;

    position: relative;
    text-align:center;

    /* Others */
    border: 0.1rem solid var(--color-border);
    border-radius: 0.3rem;
    overflow: hidden;

    img{
        width: 100%;
    }
`;

UserPageAside.ChangePhotoBtn = styled.button `
    /* Display  & Box Model */
    width: 100%;
    padding: 0.5rem 0;

    /* Colors */
    background-color: var(--color-white);
    backdrop-filter: blur(5px);
    color: var(--color-black);

    /* Position */
    position: absolute;
    bottom: 0;
    left: 0;

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border: none;
    cursor: pointer;
`;

UserPageAside.Menu = styled.nav `
    /* Display  & Box Model */
    display: flex;
    flex-direction: column;

    ul{
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease-out;

        &.open{
            height: auto;
            opacity: 1;
            display: block;
            transition: all 0.3s ease-out;
        }
    }

    p{
        margin: 1rem auto 2rem auto;
        font-weight: 700;
        color: var(--color-primary);
        cursor: pointer;

        svg{
            margin-left: 0.5rem;
            transition: all 0.3s ease-out;
        }
        
        &.rotate{
            svg{
                transform: rotate(180deg);
                transition: all 0.3s ease-out;
            }
        }
    }
    
    a{
        /* Display  & Box Model */
        width: 100%;
        display: block;
        padding: 1rem 0;

        /* Colors */
        color: var(--color-black);

        /* Others */
        border-top: 0.1rem solid var(--color-border);
        border-bottom: 0.1rem solid var(--color-border);
        transition: all 0.3s ease-out;
        
        :hover{
            background-color: var(--color-gray-light);
            transition: all 0.3s ease-out;
        }

        &.active{
            border-color: var(--color-primary);
            color: var(--color-primary);
            font-weight: 700;
        }
    }

    @media (min-width: 1000px){
        ul{
            height: auto;
            opacity: 1;
            display: block;
        }

        p{
            display: none;
        }
    }
`;

UserPageAside.DeleteBtn = styled.div `
    /* Display  & Box Model */
    margin: 2rem auto 1rem auto;

    /* Color */
    color: var(--color-red);

    /* Text */
    font-size: 1rem;
    text-align: center;

    /* Others */
    cursor: pointer;

    @media (min-width: 600px){
        /* Position */
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
`;