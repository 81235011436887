import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAsync } from '../../utils/useAsync';
import Alert from '../Alert';
import Modal from '../Modal';
import { ButtonLink } from '../common//Buttons/ButtonLink';
import { useCategoryNotFound } from './hooks/useCategoryNotFound';
import { FormTextarea } from '../common/FormTextarea';
import { Button } from '../common/Buttons/Button';

// Styles & Images
import { CategoriesPage } from './styles';
import { FormTextInput } from '../common/FormTextInput';
import { CategoriesIcons } from '../common/Icons/CategoriesIcons';
import { ArrowRight } from '../common/Icons/IconsFamily';

export function Categories() {
  const { run: runLoadData, data } = useAsync({ status: 'pending' });
  const alertRef = useRef();
  const shouldSelectCategoryAlertRef = useRef();

  // Grid columns design by screen size
  const gridColumns = () => {
    let screenSize = parseInt(window.innerWidth);
    switch (true) {
      case screenSize <= 500:
        return 1;

      case screenSize >= 501 && screenSize <= 899:
        return 2;

      default:
        return 3;
    }
  };

  // States
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState(null);
  const [microCategorySelected, setMicroCategorySelected] = useState(null);
  const [supplierCategories, setSupplierCategories] = useState([]);
  const [categoryTargetElement, setCategoryTargetElement] = useState(null);

  // CategoryNotFound Modal
  const modalRef = React.useRef();
  const [selectedCategoryArea, setSelectedCategoryArea] = React.useState(null);
  const {
    submitCategoryNotFound,
    isSubmittingCategoryNotFound,
    isErrorCategoryNotFound,
    isSuccessCategoryNotFound,
  } = useCategoryNotFound();
  const [categoryNotFoundDescription, setCategoryNotFoundDescription] = React.useState('');
  const [hasTriedSubmit, setHasTriedSubmit] = React.useState(false);
  const [suggestedCategory, setSuggestedCategory] = React.useState('');

  useEffect(() => {
    setSubCategorySelected(null);
    setMicroCategorySelected(null);
    if (selected && categoryTargetElement)
      categoryTargetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [selected]);

  useEffect(() => {
    setMicroCategorySelected(null);
  }, [subCategories]);

  useEffect(() => {
    window.scrollTo(0, 0);
    runLoadData(
      axios({
        method: 'get',
        url: `/api/supplier/categories`,
      }).then(response => response.data)
    );
  }, [runLoadData]);

  useEffect(() => {
    if (data) {
      setSupplierCategories(data.user_categories);

      // Group categories by grid layout
      let groupCategories = [],
        i = 0,
        subCat = [];
      data.categories.forEach(e => {
        if (e.parentId > 0) {
          subCat = [...subCat, e];
        } else {
          if (i % gridColumns() === 0) {
            groupCategories.push([e]);
          } else {
            groupCategories[groupCategories.length - 1].push(e);
          }
          i++;
        }
      });

      setSubCategories(subCat);
      setCategories(groupCategories);
    }
  }, [data]);

  /* =============================
      Get id of the top parent category
    ================================*/

  function getParentGroupId(categoryId) {
    const category = [...subCategories, ...categories.flat()].find(c => c.id === categoryId);
    if (category) {
      if (category.parentId === 0) {
        return category.id;
      } else {
        return getParentGroupId(category.parentId);
      }
    }
  }
  const parentCategoriesSelected = supplierCategories.map(c => getParentGroupId(c));

  /* =============================
        Get ids of all parents of a category
    ================================*/

  function getCategoryAndAbove(categoryId) {
    const category = [...subCategories, ...categories.flat()].find(c => c.id === categoryId);
    if (category) {
      if (category.parentId === 0) {
        return [category.id];
      } else {
        return [...getCategoryAndAbove(category.parentId), category.id];
      }
    }
  }

  function getCategoryAndBelow(categoryId) {
    const category = [...subCategories, ...categories.flat()].find(c => c.id === categoryId);
    if (category) {
      const childrenCategories = subCategories.filter(c => c.parentId === categoryId);
      if (childrenCategories.length === 0) {
        return [category.id];
      } else {
        return [category.id, ...childrenCategories.map(c => getCategoryAndBelow(c.id))].flat();
      }
    }
  }

  /* =============================
            Handle categories
    ================================*/

  function handleAddCategory(category_id) {
    const oldCategories = [...supplierCategories];
    const newCategories = getCategoryAndAbove(category_id).filter(c => !oldCategories.includes(c));
    setSupplierCategories([...oldCategories, ...newCategories]);
    axios({
      method: 'post',
      url: '/api/supplier/categories/add',
      data: { categories: newCategories },
    })
      .then(res => {
        setSupplierCategories(res.data.user_categories);
      })
      .catch(err => {
        console.log(err.message);
        setSupplierCategories(oldCategories);
        alertRef.current.open();
      });
  }

  function handleRemoveCategory(category_id) {
    if (supplierCategories.length === 1) {
      shouldSelectCategoryAlertRef.current.open();
      return;
    }

    const oldCategories = [...supplierCategories];
    const categoriesToRemove = getCategoryAndBelow(category_id);
    const newCategories = oldCategories.filter(c => !categoriesToRemove.includes(c));
    setSupplierCategories(newCategories);
    axios({
      method: 'post',
      url: '/api/supplier/categories/remove',
      data: { categories: categoriesToRemove },
    })
      .then(res => {
        setSupplierCategories(res.data.user_categories);
      })
      .catch(err => {
        console.log(err.message);
        setSupplierCategories(oldCategories);
        alertRef.current.open();
      });
  }

  return (
    <>
      <Alert ref={shouldSelectCategoryAlertRef}>Deve ser selecionada ao menos uma categoria.</Alert>

      {categories?.map((group, i) => {
        return (
          <div key={i}>
            <CategoriesPage>
              {group.map(category => {
                const isAdded = parentCategoriesSelected.includes(category.id);
                const Icon = CategoriesIcons.find(e => e.categoryId === category.id)?.icon;
                const subCategoriesCount = parentCategoriesSelected.filter(
                  c => c === category.id
                ).length;
                return (
                  <CategoriesPage.Category
                    key={category.id}
                    className={`${selected?.id === category.id ? 'selected' : ''} ${
                      isAdded ? 'added' : ''
                    }`}
                    onClick={event => {
                      setCategoryTargetElement(event.currentTarget);
                      setSelected(category);
                    }}
                  >
                    <CategoriesPage.CategoryTitle>
                      <figure>{Icon}</figure>
                      <p>
                        {category.name}
                        {subCategoriesCount > 0 ? (
                          <CategoriesPage.SubCategoriesCount>
                            {parentCategoriesSelected.filter(c => c === category.id).length}
                          </CategoriesPage.SubCategoriesCount>
                        ) : null}
                      </p>
                    </CategoriesPage.CategoryTitle>
                  </CategoriesPage.Category>
                );
              })}
            </CategoriesPage>

            {selected && group.some(e => e.id === selected.id) && (
              <CategoriesPage.CategoryWraper>
                <CategoriesPage.WrapperHeader>
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={() => {
                        !supplierCategories.includes(selected.id)
                          ? handleAddCategory(selected.id)
                          : handleRemoveCategory(selected.id);
                      }}
                      checked={supplierCategories.includes(selected.id)}
                    />
                    <p>{selected.name}</p>
                  </div>
                  <p style={{ cursor: 'pointer' }} onClick={() => setSelected(null)}>
                    Fechar
                  </p>
                </CategoriesPage.WrapperHeader>

                <CategoriesPage.WrapperBody>
                  <CategoriesPage.WrapperSubCategories>
                    {subCategories.some(category => category.parentId === selected.id) &&
                      subCategories
                        .filter(category => category.parentId === selected.id)
                        .map(category => {
                          const subCatsSelectedCount = subCategories.filter(
                            c => c.parentId === category.id && supplierCategories.includes(c.id)
                          ).length;
                            console.log("Estou imprimindo", subCategories);
                          return (
                            <CategoriesPage.WrapperSubCategoriesItem
                              key={category.id}
                              onClick={() => {
                                subCategories.some(cat => cat.parentId === category.id) &&
                                  setSubCategorySelected(category);
                              }}
                              className={subCategorySelected?.id === category.id && 'selected'}
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  onChange={() => {
                                    !supplierCategories.includes(category.id)
                                      ? handleAddCategory(category.id)
                                      : handleRemoveCategory(category.id);
                                  }}
                                  checked={supplierCategories.includes(category.id)}
                                />

                                <p>
                                  {category.name}
                                  {subCatsSelectedCount ? (
                                    <CategoriesPage.SubCategoriesCount>
                                      {subCatsSelectedCount}
                                    </CategoriesPage.SubCategoriesCount>
                                  ) : null}
                                </p>

                                {subCategories.some(e => e.parentId === category.id) && (
                                  <ArrowRight
                                    color={
                                      subCategorySelected?.id === category.id
                                        ? 'var(--color-purple-dark)'
                                        : 'var(--color-black)'
                                    }
                                  />
                                )}
                              </div>

                              {subCategorySelected?.id === category.id && (
                                <CategoriesPage.WrapperMicroCategories>
                                  {subCategories.some(
                                    category => category.parentId === subCategorySelected.id
                                  ) &&
                                    subCategories
                                      .filter(
                                        category => category.parentId === subCategorySelected.id
                                      )
                                      .map(category => {
                                        return (
                                          <CategoriesPage.WrapperSubCategoriesItem
                                            key={category.id}
                                            onClick={() => {
                                              subCategories.some(
                                                cat => cat.parentId === category.id
                                              ) && setMicroCategorySelected(category);
                                            }}
                                          >
                                            <div>
                                              <input
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={() => {
                                                  !supplierCategories.includes(category.id)
                                                    ? handleAddCategory(category.id)
                                                    : handleRemoveCategory(category.id);
                                                }}
                                                checked={supplierCategories.includes(category.id)}
                                              />

                                              {category.name}
                                              {subCategories.some(
                                                e => e.parentId === category.id
                                              ) && (
                                                <ArrowRight
                                                  color={
                                                    microCategorySelected?.id === category.id
                                                      ? 'var(--color-purple-dark)'
                                                      : 'var(--color-black)'
                                                  }
                                                />
                                              )}
                                            </div>

                                            {microCategorySelected &&
                                              microCategorySelected.id === category.id && (
                                                <CategoriesPage.WrapperSubCategories>
                                                  {subCategories.some(
                                                    microCategory =>
                                                      microCategory.parentId ===
                                                      microCategorySelected.id
                                                  ) &&
                                                    subCategories
                                                      .filter(
                                                        microCategory =>
                                                          microCategory.parentId ===
                                                          microCategorySelected.id
                                                      )
                                                      .map(microCategory => {
                                                        return (
                                                          <CategoriesPage.WrapperSubCategoriesItem
                                                            key={microCategory.id}
                                                          >
                                                            <div>
                                                              <input
                                                                type="checkbox"
                                                                className="checkbox"
                                                                onChange={() => {
                                                                  !supplierCategories.includes(
                                                                    microCategory.id
                                                                  )
                                                                    ? handleAddCategory(
                                                                        microCategory.id
                                                                      )
                                                                    : handleRemoveCategory(
                                                                        microCategory.id
                                                                      );
                                                                }}
                                                                checked={supplierCategories.includes(
                                                                  microCategory.id
                                                                )}
                                                              />

                                                              {microCategory.name}
                                                            </div>
                                                          </CategoriesPage.WrapperSubCategoriesItem>
                                                        );
                                                      })}
                                                </CategoriesPage.WrapperSubCategories>
                                              )}
                                          </CategoriesPage.WrapperSubCategoriesItem>
                                        );
                                      })}
                                </CategoriesPage.WrapperMicroCategories>
                              )}
                            </CategoriesPage.WrapperSubCategoriesItem>
                          );
                        })}
                  </CategoriesPage.WrapperSubCategories>
                </CategoriesPage.WrapperBody>
              </CategoriesPage.CategoryWraper>
            )}
          </div>
        );
      })}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '2rem',
          marginTop: '2rem',
        }}
      >
        <ButtonLink onClick={() => modalRef.current.open()}>
          Não encontrou sua categoria?
        </ButtonLink>
      </div>

      <Modal title="Não encontrei minha categoria" ref={modalRef}>
        {!isSuccessCategoryNotFound ? (
          <>
            <h3 className="txt--black">Não encontrou uma categoria específica?</h3>

            <br />

            <p>
              Nossa lista de categorias está sempre evoluindo! Conte-nos mais sobre os serviços que
              sua empresa oferece e faremos o possível para classificá-los da melhor maneira
              possível.
            </p>

            <br />
            <br />

            <p className="txt--black">
              Dentre as áreas de atuação abaixo, qual mais se aproxima do seu serviço?
            </p>

            <CategoriesPage.CategoryAreaOptionsSection>
              {categories
                ?.flat()
                .filter(c => c.parentId === 0)
                .map(c => (
                  <CategoriesPage.CategoryAreaOption
                    key={c.id}
                    className={selectedCategoryArea === c.name ? 'selected' : null}
                    onClick={() => setSelectedCategoryArea(c.name)}
                  >
                    {c.name}
                  </CategoriesPage.CategoryAreaOption>
                ))}
            </CategoriesPage.CategoryAreaOptionsSection>

            {hasTriedSubmit && !selectedCategoryArea ? (
              <p style={{ color: 'var(--color-red)', marginTop: '1rem' }}>
                Selecione uma das opções acima
              </p>
            ) : null}

            <br />
            <br />

            <FormTextarea
              id="service_description"
              label="Descreva com mais detalhes quais são os serviços que sua empresa oferece"
              labelStyle={{ fontSize: '1rem', position: 'relative' }}
              style={{ margin: 0 }}
              onChange={e => setCategoryNotFoundDescription(e.target.value)}
              value={categoryNotFoundDescription}
              isError={hasTriedSubmit && categoryNotFoundDescription.length < 50}
              errorMessage={'Descrição muito curta (mín. 50 caracteres)'}
            />

            <br />

            <FormTextInput
              label="Como você categorizaria o seu serviço?"
              labelStyle={{ fontSize: '1rem', position: 'relative' }}
              style={{ margin: 0 }}
              id="suggested-category"
              errorMessage="Campo obrigatório"
              isError={hasTriedSubmit && !suggestedCategory.trim()}
              value={suggestedCategory}
              onChange={e => setSuggestedCategory(e.target.value)}
            />

            <br />

            {isErrorCategoryNotFound ? (
              <p style={{ color: 'var(--color-red)', marginTop: '1rem', textAlign: 'center' }}>
                Erro no envio do formulário
              </p>
            ) : null}

            <Button
              onClick={() => {
                setHasTriedSubmit(false);
                if (
                  selectedCategoryArea &&
                  categoryNotFoundDescription.length >= 50 &&
                  suggestedCategory
                ) {
                  submitCategoryNotFound({
                    selectedCategoryArea,
                    categoryNotFoundDescription,
                    suggestedCategory,
                  });
                } else {
                  setHasTriedSubmit(true);
                }
              }}
              disabled={isSubmittingCategoryNotFound}
            >
              {isSubmittingCategoryNotFound ? 'Enviando...' : 'Enviar'}
            </Button>
          </>
        ) : (
          <div style={{ maxWidth: '600px', margin: '1rem auto' }}>
            <h2 className="txt--black" style={{ textAlign: 'center' }}>
              Recebemos sua mensagem!
            </h2>

            <br />

            <p>
              Iremos analisar seu pedido de inclusão de categoria. Caso ele seja aprovado, você
              receberá uma notificação para incluir sua empresa na nova categoria.
            </p>

            <br />

            <p>
              Enquanto isso, finalize o seu cadastro utilizando as categorias que mais se aproximem
              do seu caso. Não se preocupe, você poderá alterá-las a qualquer momento!
            </p>

            <br />

            <Button onClick={() => modalRef.current.close()}>Entendi</Button>
          </div>
        )}
      </Modal>
    </>
  );
}
