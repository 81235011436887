import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import { useAuthContext } from '../../../Contexts/AuthContext';
// Styles & Images
import { useSubmitImage } from './hooks/useSubmitImage';
import userImageStub from '../../../assets/icons/user-profile-image.svg';
import { SupplierPage } from '../styles';
import { UserPageAside } from './styles';
import { ArrowDown } from '../../../Components/Icons';

export function UserAccountAside() {
  const { user } = useAuthContext();
  const userImageModalRef = React.useRef();
  const alertRef = React.useRef();
  const userImage = user?.avatar_url || userImageStub;
  const location = useLocation();
  const pathname = location.pathname;

  const [isOpen, setIsOpen] = React.useState(false);

  const { file, addImage, handleSubmitImage } = useSubmitImage();

  function isActived(path) {
    return pathname.includes(path) ? 'active' : '';
  }

  return (
    <>
      <div className="col l3 m12 s12">
        <SupplierPage.Box style={{ padding: 0 }}>
          <UserPageAside>
            <UserPageAside.User>
              <UserPageAside.Photo>
                <img src={user?.avatar_url} alt="" />
                <UserPageAside.ChangePhotoBtn onClick={() => userImageModalRef.current.open()}>
                  Mudar foto
                </UserPageAside.ChangePhotoBtn>
              </UserPageAside.Photo>

              <div>
                <h4 className="txt--black">{user?.name}</h4>
                <p>{user?.email}</p>
              </div>
            </UserPageAside.User>

            <UserPageAside.Menu>
              <p onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'rotate' : ''}>
                {isOpen ? 'Fechar' : 'Abrir'} menu
                <ArrowDown bgColor={'#9614c3'} />
              </p>

              <ul className={isOpen ? 'open' : ''}>
                <li>
                  <Link to="/fornecedor/minha-empresa" className={isActived('minha-empresa')}>
                    Minha empresa
                  </Link>
                </li>
                {user?.is_activated ? (
                  <>
                    <li>
                      <Link to="/fornecedor/meus-contatos" className={isActived('meus-contatos')}>
                        Contatos
                      </Link>
                    </li>
                    <li>
                      <Link to="/fornecedor/meu-endereco" className={isActived('meu-endereco')}>
                        Endereço
                      </Link>
                    </li>
                    <li>
                      <Link to="/fornecedor/cadastro/categorias">Áreas de atuação</Link>
                    </li>
                    <li>
                      <Link
                        to="/fornecedor/meus-dados-bancarios"
                        className={isActived('meus-dados-bancarios')}
                      >
                        Dados bancários
                      </Link>
                    </li>
                  </>
                ) : null}
              </ul>
            </UserPageAside.Menu>
          </UserPageAside>
        </SupplierPage.Box>
      </div>

      <Modal title="Imagem do perfil" ref={userImageModalRef}>
        <h3 className="modal__title"> Mudar imagem de perfil </h3>

        <SupplierPage.UserImage className="modal__image">
          <img src={!file ? userImage : file} alt={user.name} />

          <form onSubmit={event => handleSubmitImage(event, user?.id, userImageModalRef)}>
            <label htmlFor="user_image" className="txt--black">
              {' '}
              Pesquisar imagem{' '}
            </label>
            <input
              type="file"
              name="user_image"
              id="user_image"
              style={{ display: 'none' }}
              onChange={e => addImage(e, alertRef)}
            />
            <button class="btn btn--center bg--purple" type="submit">
              <span> Salvar </span>
            </button>
          </form>
        </SupplierPage.UserImage>
      </Modal>

      <Alert ref={alertRef}>
        <p> Por favor escolha uma imagem de até 2Mb </p>
      </Alert>
    </>
  );
}
