import React from 'react';
import { Link } from 'react-router-dom';
import { OfferPage } from '../Offer/styles';
import { AlertsPage } from '../styles';
import { SubmittedOffersPage } from './styles';

export function SubmittedOfferCard({ offer }) {
  const deliveryDate = offer.delivery_estimated_at
    ? offer.delivery_estimated_at
    : offer.alert.delivery_date;

  function getDate(date, isMonth) {
    return isMonth ? monthNames[new Date(date).getMonth()] : new Date(date).getDate();
  }

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return (
    <Link to={`/fornecedor/historico-orcamentos/${offer.id}`}>
      <SubmittedOffersPage.OfferCardContainer>
        <div>
          <h3>{offer.alert?.name}</h3>
          <p className="alert-details-text">{offer.alert?.description}</p>
          <p className="alert-details-text">
            Válido até:{' '}
            {offer.expires_at
              ? new Date(offer.expires_at).toLocaleDateString('pt-br', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })
              : '-'}
          </p>
        </div>
        <OfferPage.OfferSentAtBox>
          <span> Enviado em: </span>
          {offer.created_at ? (
            <>
              <h5> {getDate(offer.created_at)} </h5>
              <p>de {getDate(offer.created_at, true)}</p>
            </>
          ) : (
            <h5>- - -</h5>
          )}
        </OfferPage.OfferSentAtBox>

        <SubmittedOffersPage.OfferDeliveryBox>
          <span> Entrega em: </span>
          {deliveryDate && !Number.isNaN(getDate(deliveryDate)) && deliveryDate.includes(':') ? (
            <>
              <h5> {getDate(deliveryDate)} </h5>
              <p>de {getDate(deliveryDate, true)}</p>
            </>
          ) : (
            <h5 style={{ fontSize: '0.8rem', marginTop: '1.5rem' }}>{deliveryDate}</h5>
          )}
        </SubmittedOffersPage.OfferDeliveryBox>
      </SubmittedOffersPage.OfferCardContainer>
    </Link>
  );
}
