import styled from "styled-components";
import tw from "tailwind.macro";
import bgGraphism from '../../assets/images/backgrounds/home-bg.svg';


export const FaqStyled = styled.div.attrs({
  className: ""
})`
  & {
    .hidden{
      display:none;
    }

    .marker{
      display:flex;
      align-items:center;
  
      div{
        display:flex;
        justify-content:center;
        margin-top:1.5rem;
        position:relative;
        padding-left:3rem;
        img{
          top:0;
          left:0rem;
          position:absolute;
          padding-top:0;
          justify-content:center;
          width:2rem;
          margin-right:1rem;
        }
      
    }
    }
    .content{
      ${tw`
         pt-16 
         flex 
      `}
    }
    .content .container{
        ${tw`
          pb-8
        `}
      }
    .row .col{
        ${tw`
        align-middle
        `}
      }
    span{
        ${tw`
          font-bold
        `}
      color:var(--color-primary-dark)
    }
    .col.m4{
      ${tw`
        flex
        justify-center
      `}
    }
    .col.m4 img{
      width:15rem;
    }
    .aside{
      ${tw`
        px-12
        py-4
        mr-16
        `}
      box-shadow:0px 0px 8px 1px grey ;
      width:25%; 
      min-height:93vh;      
    }
    .items{
      ${tw`
        py-4
        mr-4
      `}
        top: 5rem; /* altura que vai parar antes do topo */
        position: sticky;
      img{
        width:20vw;
      };
      p{
        ${tw`
          my-3
          font-bold
          text-lg
        `}
        color: var(--color-primary-dark)
      }
    }
    p, ul{
      ${tw`
        leading-6
        py-1
      `}
    }
    .back{
      ${tw`
        my-8
        flex
        items-center
      `}
      color: var(--color-primary);
      .icon{
        ${tw`
          mr-2
        `}
      }
    }
    h2{
      ${tw`
        my-2      
        font-bold
        text-2xl	
      `}
      font-family: 'Estate','Manrope',sans-serif;
      color: var(--color-purple-light)
    }
    h3{
      text-align:center;
      font-size:1.5rem;
      font-family: 'Estate','Manrope',sans-serif;
      color: var(--color-purple-light)
    }

    @media(max-width: 1080px){
    
      .alignment{
        ${tw`
          my-8 
        `}
        align-items:center
      }
      .items{
        ${tw`
          flex
          flex-col
          justify-center
        `}
        img{
          width:20rem;
        };
      }
      .col.m4 img{
        width:20rem;
      }
      .content{
        ${tw`
          pt-16 
          block 
        `}
      }
      .aside{
        ${tw`
          px-42
          h-72
          w-full
          `}
         }
      .content .container{
        ${tw`
          px-4
          pb-8
        `}
      }
    }

    ol{
      ${tw`
        my-4
      `}
      display:flex;
      flex-direction:column;
      width: 100%;
      li{
        ${tw`
          ml-6
        `}
        display: inline;
        /* Text */
        line-height: 1.8; 
      }
    }
 
    ul{
      ${tw`
        mt-4
      `}
      display:flex;
      flex-direction:column;
      width: 100%;
      li{
        ${tw`
          ml-6
          list-disc
          mb-1
        `}
        display: inline;
        /* Text */
        font-size: 0.8rem;
        line-height: 1.8; 
        font-weight:800;
      }
    }
    ul li:before {
      content: '\2022';
      margin: 0 2px;
      color: #e74c3c /* cor da bolinha */
    }
  }
`;

export const FaqProviderStyled = styled.div.attrs({
  className: ""
})`
  & {
    .sizePage{
      min-height:65vh;
    }
    footer{
      position:absolute;
      bottom:0;
    }
    .content{
      width: 100%;
      display: grid;
      grid-template-columns: repeat( 1, 1fr);
      gap: 2rem;
      margin-bottom: 8rem;
      
      @media (min-width: 600px){
        grid-template-columns: repeat( 3, 1fr);
      }

      @media (min-width: 800px){
        grid-template-columns: repeat( 4, 1fr);
      }

      @media (min-width: 900px){
        grid-template-columns: repeat( 5, 1fr);
      }
    }

    .cards {
      /* Display & Box Model */
      width: 100%;
      display: flex;
      flex-direction:column;
      align-items:center;
      justify-content: center;
      /* Colors */
      background-color: var(--color-white);
      box-shadow: 0px 0px 10px 1px grey;
      border-radius: 0.5rem;
        img{
          width: 10rem;
          height: 10rem;
          object-fit: cover;
        }
      }
      h4{
        /* Text */
        font-size: 1.2rem;
        font-weight: 600;
      }
      p{
        /* Text */
        font-size: 1.25rem;
        line-height: 1.8;
        margin-bottom:0.5rem;
      }
    }

    .FaqHeader{
      width: 100%;
      padding-top:12rem;
    /* Colors */
      background-color: var(--color-primary-dark);
      color: var(--color-white);
      /* Position */
      position: relative;
      h3{
        /* Display & Box Model */
        text-align:center;
        padding-bottom: 2rem; 
        /* Text */
        font-size: 2.5rem;
        font-weight: 700;
      }
      p{
        /* Display & Box Model */
        width: 100%;
        margin-bottom: 2rem;
        line-height: 1.5;
        text-align:center;
      }
      ::before{
        /* Display & Box Model */
        content: '';
        width: 100%;
        height: 100%;
        /* Background */
        background-image: url(${bgGraphism});
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 60%;
        /* Position */
        position: absolute;
        top: 0rem;
        left: 0;
        /* Others */
        transform: scaleY(-1);
      }
      ::after{
        /* Display & Box Model */
        content: '';
        width: 100%;
        height: 7rem;
        z-index: -1;
        /* Colors */
        background-color: var(--color-primary-dark);
        /* Potision */
        position: absolute;
      }
      @media (min-width: 600px){
        ::before{
          background-position: right center;
          background-size: inherit;
        }
        p{
          width: 50%;
        }
      }

  }
`;


