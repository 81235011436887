import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { useAsync } from '../../../utils';
import { SupplierPage } from '../styles';
import SupplierMenu from '../Menu';
import { Load } from '../../../Components/Loader/styles';
import FeedbackChannel from '../FeedbackChannel';
import { OfferPage } from '../Offer/styles';
import { ButtonBack } from '../../../Components/common/Buttons/ButtonBack';
import { ButtonLink } from '../../../Components/common/Buttons/ButtonLink';
import { SubmittedOffersPage } from './styles';
import { Image } from '../../../Components/Icons';
import { SubmittedOfferItemDetails } from './SubmittedOfferItemDetails';
import Modal from '../../../Components/Modal';

export default function SupplierSubmittedAlertProposalWithoutOffer() {
  const { alertProposalId } = useParams();
  const { user } = useAuthContext();
  const alertDetailsModalRef = useRef();

  const { run, data, isLoading } = useAsync({ status: 'pending' });
  const alert = data?.alert;
  const files = data?.files;
  const expirationDate = data?.expires_at;

  const sale_price = data?.sale_price ?? 0;
  const quantity = data?.quantity ?? 1;
  const total_sale_price = quantity * sale_price;
  const rate_price = data?.rate_price || parseFloat(total_sale_price * 0.9).toFixed(2);
  const total_supplier_sale_price =
    data?.total_supplier_sale_price || total_sale_price - rate_price;

  const item = {
    price_method: '-',
    photos: data?.photos,
    quantity,
    description: data?.description,
    sale_price,
    total_sale_price,
    rate_price,
    total_supplier_sale_price,
    user_links: data?.user_links,
  };

  useEffect(() => {
    run(
      axios.get(`/api/supplier/alert-proposal/${alertProposalId}`).then(response => response.data)
    );
  }, [run, alertProposalId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  function getDate(date, isMonth) {
    return isMonth ? monthNames[new Date(date).getMonth()] : new Date(date).getDate();
  }

  if (isLoading) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <OfferPage.Header>
        <ButtonBack link="/fornecedor/historico-orcamentos" />

        <div className="container">
          <h1>{alert?.name}</h1>
          <ButtonLink
            style={{ color: 'white' }}
            onClick={() => alertDetailsModalRef.current.open()}
          >
            Ver detalhes do pedido
          </ButtonLink>
        </div>
      </OfferPage.Header>

      <OfferPage.Content>
        <div className="container">
          <OfferPage.Body>
            <SubmittedOffersPage.OfferExpiration>
              <OfferPage.OfferExpirationBox>
                <span> Vencimento: </span>
                {expirationDate ? (
                  <>
                    <h5> {getDate(expirationDate)} </h5>
                    <p>de {getDate(expirationDate, true)}</p>
                  </>
                ) : (
                  <h5>- - -</h5>
                )}
              </OfferPage.OfferExpirationBox>

              <OfferPage.OfferExpirationText>
                Data de vencimento <br /> do seu orçamento
              </OfferPage.OfferExpirationText>
            </SubmittedOffersPage.OfferExpiration>

            <OfferPage.Supplier>
              <figure>
                {user?.avatar_url ? (
                  <img style={{ width: '100%' }} src={user?.avatar_url} alt={user?.company_name} />
                ) : (
                  <Image />
                )}
              </figure>
              <p>Orçamento de: {user?.company_name}</p>
            </OfferPage.Supplier>

            <OfferPage.ItemsList>
              <SubmittedOfferItemDetails item={item} index={0} />
            </OfferPage.ItemsList>

            {files?.length > 0 ? (
              <OfferPage.Files>
                <h3>Arquivos</h3>

                {files.map(({ id, url }) => {
                  const splitUrl = url.split('/');
                  const [, ...name] = splitUrl[splitUrl.length - 1].split('-');
                  const fileName = name.join('-');

                  return (
                    <li key={id} style={{ padding: '1rem' }}>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {fileName}
                      </a>
                    </li>
                  );
                })}
              </OfferPage.Files>
            ) : null}
          </OfferPage.Body>
        </div>
      </OfferPage.Content>

      <Modal ref={alertDetailsModalRef}>
        <SubmittedOffersPage.StyledAlertDetailsModal>
          <h2 className="txt--secondary margin--bottom-1">{alert?.name}</h2>
          <p>Data estimada de entrega: {alert?.delivery_date}</p>

          <div className="alerts__fieldset">
            <span className="fieldset__title">Detalhes da oportunidade</span>
            <span className="fieldset__description" />
            <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.description}</p>
          </div>
        </SubmittedOffersPage.StyledAlertDetailsModal>
      </Modal>
    </SupplierPage>
  );
}
