import styled from 'styled-components';

export const SubmittedOffersPage = {};

SubmittedOffersPage.OfferCardContainer = styled.div`
  /* Display & Box Model */
  width: 700px;
  display: flex;
  grid-gap: 1.5rem;
  margin: 1rem auto;
  padding: 1.5rem 2rem;

  transition: transform 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
  }

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-radius: var(--radius);
  overflow: hidden;

  /* Others */
  box-shadow: var(--shadow);

  h3 {
    /* Display & Box Model */
    max-width: 100%;
    margin: 0.5rem 0 1rem;

    /* Text */
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0px;
  }

  .alert-details-text {
    width: 400px;
    font-size: 0.9rem;
    line-height: 1.8;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

SubmittedOffersPage.OfferExpiration = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Position */
  position: absolute;
  top: 2rem;
  right: 1rem;

  @media (min-width: 800px) {
    top: -2.2rem;
    right: 2rem;
  }
`;

SubmittedOffersPage.StyledAlertDetailsModal = styled.div`
  h2 {
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 1.4rem;
  }

  h2,
  p {
    text-align: left;
  }
`;

SubmittedOffersPage.OfferDeliveryBox = styled.div`
  /* Display & Box Model */
  width: 6rem;
  height: 7.5rem;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-black);

  /* Others */
  border: solid 1px var(--color-yellow-dark);
  border-radius: 0.3rem;
  overflow: hidden;

  span {
    /* Display & Box Model */
    width: 101%;
    display: block;
    padding: 0.5rem;

    /* Colors */
    background-color: var(--color-yellow-dark);
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }

  h5 {
    /* Display & Box Model */
    margin: 0.5rem auto;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }
`;
