import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import FaqContent from './FaqContent';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqProviderAcount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />

      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question4'>
              <p>
                Quem pode se inscrever na plataforma?
              </p>
            </a>
            <a href='#question5'>
              <p>
                Por que preciso ter CNPJ para efetuar meu cadastro?
              </p>
            </a>
            <a href='#question6'>
              <p>
                Como vender pela Celebrar?
              </p>
            </a>
            <a href='#question7'>
              <p>
                Como a Celebrar escolhe as oportunidades para meu perfil?
              </p>
            </a>
            <a href='#question8'>
              <p>
                Não encontrei todas as informações em uma oportunidade, o que posso fazer?
              </p>
            </a>
            <a href='#question1'>
              <p>
                Esqueci minha senha. O que devo fazer?
              </p>
            </a>
            <a href='#question2'>
              <p>
              Como encerro a minha conta?
              </p>
            </a>
            <a href='#question3'>
              <p>
                Caso eu exclua a minha conta, posso criar outra?
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question4'>
                Quem pode se inscrever na plataforma?
              </h2>
              <p>
                Para se inscrever na Celebrar como fornecedor é necessário atuar em serviços e/ou produtos para eventos corporativos. Possuir CNPJ (Cadastro Nacional de Pessoa Jurídica) e emitir nota fiscal pelos serviços prestados e/ou produtos entregues.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question5'>
                Por que preciso ter CNPJ para efetuar meu cadastro?
              </h2>
              <p>
                Somos uma plataforma <span>B2B</span>, conectamos empresas com empresas. Nossos clientes compradores são grandes empresas e multinacionais, os fornecedores são microempreendedores individuais, micro e pequenas empresas. Possuímos uma <span>relação comercial com nossos fornecedores</span>, onde ambas as partes se beneficiam.
              </p>
              <p>
                Realizamos nossos pagamentos por meio da <span>emissão de nota fiscal</span>, procedimento que só pode ser realizado por profissionais com <span>CNPJ</span> (Cadastro Nacional de Pessoas Jurídicas) ativo.
              </p>
              <p>
                Além disso, <span>não realizamos contratações RPA</span> (Recibo de Pagamento Autônomo) de pessoas físicas, pois a taxa dos impostos referentes a este tipo de contratação é de <span>16%</span>, já a nossa taxa de serviço é apenas <span>10%</span>, se tivermos que pagar 16% de INSS a conta não fecha para nós.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question6'>
                Como vender pela Celebrar?
              </h2>
              <p>
                O primeiro passo é realizar seu cadastro, na tela inicial do site acesse o link “Seja um Fornecedor” e depois clique em “Fazer meu cadastro”
              </p>
              <p>
                Depois, é só seguir esses passos:
              </p>
              <ol>
                <li>
                  1. Digite seu e-mai
                </li>
                <li>
                  2. Crie uma senha. Para ter uma senha forte use uma combinação de letras, números e caracteres especiais
                </li>
                <li>
                  3. Confirme a senha
                </li>
                <li>
                  4. Leia e aceite os nossos termos de uso
                </li>
                <li>
                  3. Toque no botão <span>“Criar novo cadastro”</span>
                </li>
              </ol>
              <p>
                O responsável pelo cadastro da empresa precisa preencher dados básicos na plataforma, como:
              </p>
              <ul>
                <li>
                  Nome do responsável e CPF
                </li>
                <li>
                  CNPJ da empresa
                </li>
                <li>
                  E-mail e telefone de contato
                </li>
                <li>
                  Endereço completo da empresa
                </li>
                <li>
                  Áreas de atuação (as áreas de atuação vão definir quais pedidos de orçamento você receberá)
                </li>
                <li>Dados bancários ou PIX (cadastre a sua conta bancária em que deseja receber seus saldos)
                </li>
              </ul>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question7'>
                Como a Celebrar escolhe as oportunidades para meu perfil?
              </h2>
              <p>
                Escolhemos as oportunidades para seu perfil de acordo com as áreas de atuação selecionadas por você no cadastro da sua empresa e você pode acessá-las diretamente em seu<span> “Painel de Vendas”</span>.
              </p>
              <p>
                As oportunidades também podem ser filtradas por estados, além da localidade cadastrada em seu perfil, caso faça sentido para a sua empresa, é possível selecionar outros estados para atender.
              </p>
              <span>
                Por exemplo:
              </span>
              <ul>
                <li>
                  Temos categorias de serviços que podem ser realizados de forma remota, como nossos fornecedores de tradução simultânea ou intérprete de libras.
                </li>
                <li>
                  As categorias de brindes podem ser enviadas por transportadoras e serviços de entrega, possuindo assim, uma abrangência maior de localidades atendidas
                </li>
              </ul>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question8'>
                Não encontrei todas as informações em uma oportunidade, o que posso fazer?
              </h2>
              <p>
                Todas as informações presentes nas oportunidades de eventos (<span>solicitações de orçamentos</span>) são enviadas pelos compradores, pode acontecer de no momento de preenchimento do pedido, eles não tenham todos os dados.

              </p>
              <p>
                Por isso, contamos com a <span>criatividade da nossa comunidade de fornecedores</span> para criar eventos memoráveis para os compradores. Fique à vontade para fazer sugestões de produtos para os clientes e agregar a experiência dos participantes nos eventos.
              </p>
              <p>
                Abra a oportunidade e <span>confira todas as informações</span>, se ainda tiver dúvidas ao invés de fazer perguntas dê ideias e sugestões baseadas nas premissas técnicas do seu produto e/ou serviço.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Esqueci minha senha. O que devo fazer?
              </h2>
              <p>
                Para recuperar a sua senha, é muito simples:
              </p>
              <ol>
                <li>
                  1. Acesse o nosso site
                </li>
                <li>
                  2. Toque em “Acessar minha conta”
                </li>
                <li>
                  3. Clique em “Esqueci minha senha”
                </li>
                <li>
                  4. Informe o e-mail cadastrado
                </li>
                <li>
                  5. Clique em “redefinir senha”
                </li>
              </ol>
              <p>
                As instruções para a troca de senha serão enviadas para o e-mail cadastrado. Aparecerá uma mensagem assim:<span> “Esqueci minha senha”</span>.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                Como encerro a minha conta?
              </h2>
              <p>
                Sentimos muito que a sua experiência não tenha sido a esperada com a Celebrar
              </p>
              <p>
                Antes de excluir a sua conta, você precisa:
              </p>
              <ol>
                <li>
                  1. Excluir seus dados bancários;
                </li>
                <li>
                  2. Realizar o upload de todas as notas fiscais pendentes
                </li>
                <li>
                  3. Sacar todo o saldo que estiver disponível na sua conta.
                </li>
              </ol>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question3'>
                Caso eu exclua a minha conta, posso criar outra?
              </h2>
              <p>
                Sim, caso você queira voltar a integrar nossa comunidade de fornecedores, é possível realizar um novo cadastro.
              </p>
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >
  );
}