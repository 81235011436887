import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Input } from './Forms/styles';

export function FormTextarea({ label, id, labelStyle, isError = false, errorMessage, ...props }) {
  return (
    <div className="input__field">
      <label htmlFor={id} style={labelStyle}>
        {label}
      </label>
      <textarea
        className={isError ? 'input textarea input--error' : 'input textarea'}
        id={id}
        {...props}
      />
      {isError ? <p className="txt--red">{errorMessage}</p> : null}
    </div>
  );
}

export function FormTxtarea({ ...props }) {
  return (
    <Input >
      <div className="formTxtInput">
        <label>{props.label}</label>
        <div>
          <textarea {...props} />
          {props.icon && <FeatherIcon icon={props.icon} className="icon" size={23} color="silver" />}
        </div>
      </div>
    </Input>
  );
}
