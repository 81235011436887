import React from 'react';

export const useValidateForm = () => {
  const [form, setForm] = React.useState('');
  const [isSuccess, setIsSucess] = React.useState(false);
  const [callbackFromProps, setCallbackFromProps] = React.useState();

  React.useEffect(() => {
    form && validate();
  }, [form]);

  React.useEffect(() => {
    isSuccess && callbackFromProps && callbackFromProps();
  }, [isSuccess, callbackFromProps]);

  const start = (formEl, callbackFromProps) => {
    callbackFromProps && setCallbackFromProps(callbackFromProps);
    if (!form) {
      setForm(formEl);
    } else {
      validate();
    }
  };

  const getRequiredFields = inputTypes => {
    const inputs = inputTypes.map(e => {
      if (form.querySelector(e)) return Array.from(form.querySelectorAll(`${e}:required`));
    });
    return inputs.filter(e => e);
  };

  const callbackForEach = (elementsGroups, callback) => {
    elementsGroups.forEach(elements => {
      elements.forEach(e => {
        callback(e);
      });
    });
  };

  const setErrorClassInFields = element => {
    element.parentElement.classList.add('input__field--error');
    element.classList.add('input--error');
  };

  const setTimeoutToRemoveClass = elementsGroups => {
    setTimeout(() => callbackForEach(elementsGroups, removeErrorClass), 5000);
  };

  const removeErrorClass = input => {
    input.parentElement.classList.remove('input__field--error');
    input.classList.remove('input--error');
  };

  const getInvalidFields = e => {
    if (e.type === 'checkbox' || e.type === 'radio') {
      if (!e.checked) return e;
    } else {
      if (e.value === '') return e;
    }
  };

  const validate = () => {
    const requiredFields = getRequiredFields(['input', 'textarea', 'select']);

    const invalidFields = requiredFields.map(e => {
      return e
        .map(f => {
          return getInvalidFields(f);
        })
        .filter(e => e);
    });

    if (invalidFields[0].length > 0) {
      setIsSucess(false);
      window.scrollTo(0, invalidFields[0][0].scrollHeight);
      callbackForEach(invalidFields, setErrorClassInFields);
      setTimeoutToRemoveClass(invalidFields);
    } else {
      setIsSucess(true);
    }
  };

  return {
    start,
  };
};
