import React, { Fragment, useState, useRef } from 'react';
import axios from 'axios';
import { useAsync } from '../../../utils/useAsync';
// Styles & Images
import { Feedback } from './styles';
import feedbackIcon from '../../../assets/icons/feedback-icon.svg';
import closeMenu from '../../../assets/icons/menu-close-icon.svg';
import Alert from '../../../Components/Alert';

function FeedbackChannel() {
    const alertRef = useRef();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const { run, isLoading } = useAsync();

    function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        run(
            axios({
                method: 'post',
                url: '/api/usuario/enviar-feedback',
                data: {
                    message: data.get('message'),
                    userAgent: navigator.userAgent,
                    screenWidth: window.innerWidth,
                    screenHeight: window.innerHeight,
                    hasTouchPoints: !!navigator.maxTouchPoints || !!navigator.msMaxTouchPoints,
                },
            }).then(res => {
                if (res.status === 200) {
                    alertRef.current.open();
                    setIsFormOpen(false);
                }
            })
        );
    }

    return (
        <Fragment>
            <Feedback onClick={() => setIsFormOpen(!isFormOpen)}>
                <Feedback.Icon src={feedbackIcon} alt="Entre em contato" />
                <p> Entre em contato </p>
            </Feedback>

            {isFormOpen && (
                <Fragment>
                    <Feedback.Form onSubmit={handleSubmit} name="feedback_form">
                        <Feedback.Close src={closeMenu} alt="Fechar" />

                        <h4>
                            {' '}
                            Ajude-nos a construir uma <br /> plataforma melhor para você!{' '}
                        </h4>
                        <p>Envie mensagens sobre melhorias que possamos fazer na plataforma</p>

                        <div className="input__field">
                            <label htmlFor="message"> Mensagem </label>
                            <textarea
                                name="message"
                                id="message"
                                cols="30"
                                rows="10"
                                className="input textarea"
                            />
                        </div>

                        <button className="btn btn--center" type="submit" disabled={isLoading}>
                            <span> {isLoading ? 'Enviando...' : 'Enviar mensagem'} </span>
                        </button>
                    </Feedback.Form>

                    <Feedback.Lightbox onClick={() => setIsFormOpen(false)} />
                </Fragment>
            )}

            <Alert ref={alertRef}>
                <p> Mensagem enviada com sucesso! </p>
            </Alert>
        </Fragment>
    );
}

export default FeedbackChannel;
