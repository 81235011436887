import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonLink } from '../../../Components/common/Buttons/ButtonLink';
import Header from '../../../Components/Header';
import FeatherIcon from 'feather-icons-react';

import { RegistrationAddressStep } from './AddressStep';
import { RegistrationCompanyStep } from './CompanyStep';
import { RegistrationRecommendationsStep } from './RecommendationsStep';
import { CompleteRegistrationPageStyles, ProgressBarStyled } from './styles';
import { RegistrationFinished } from './RegistrationFinished';

export function SupplierCompleteRegistration() {
  const history = useHistory();

  const steps = [
    {
      title: 'Dados da empresa',
      component: RegistrationCompanyStep,
    },
    {
      title: 'Localização',
      component: RegistrationAddressStep,
    },
    {
      title: 'Avaliações',
      component: RegistrationRecommendationsStep,
    },
  ];
  const [currentStep, setCurrentStep] = useState(1);

  const StepToRender = steps[currentStep - 1].component;

  const handleReturnStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep => currentStep - 1);
    }
  };

  const handleContinueRegistration = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep => currentStep + 1);
    } else {
      history.push('/fornecedor/cadastro-finalizado');
    }
  };

  return (
    <>
      <Header buyerPage hideButtons />
      <div className="container">
        <CompleteRegistrationPageStyles>
          <div style={{ flex: 1, overflow: 'hidden' }}>
            {currentStep > 1 ? (
              <ButtonLink onClick={handleReturnStep} style={{ marginTop: '1rem' }}>
                <FeatherIcon icon="arrow-left" size="12" /> {steps[currentStep - 2].title}
              </ButtonLink>
            ) : null}

            <StepToRender handleContinueRegistration={handleContinueRegistration} />
          </div>

          <ProgressBar currentStep={currentStep} steps={steps} />
        </CompleteRegistrationPageStyles>
      </div>
    </>
  );
}

const ProgressBar = ({ currentStep, steps }) => {
  return (
    <ProgressBarStyled>
      <div>
        {steps.map((step, index) => (
          <div className="step" key={index}>
            <p className={currentStep === index + 1 ? 'step-text' : undefined}>{step.title}</p>
            <div className={`progress ${currentStep === index + 1 && 'step-number'}`}>
              {index + 1}
            </div>
          </div>
        ))}
        <div className="step lasted">
          <p className>Comece a vender!</p>
          <div className="progress">{steps.length + 1}</div>
        </div>
      </div>
    </ProgressBarStyled>
  );
};
