import styled from "styled-components";
import tw from "tailwind.macro";

export const DemandStyled = styled.div.attrs({
  className: "w-full h-screen flex flex-col items-center justify-center"
})`
  & {
    /*Class and Tags */
    p {
      ${tw`
        text-sm
      `}
    }
 
    h1, .cardCategory p, .card p{
      ${tw`
      text-base 
      font-bold
      `}
    }
  
    .button{
      ${tw`
        pb-6
        flex
        justify-center
      `}
    }
    .l6 .card{
      ${tw`
        py-4
        `}
    }
    /* Header */
    .icon{
      ${tw`
        flex
        justify-center
        items-center
        `}
    }
    .icon svg{
      ${tw`
        w-16
        `}
    }
   
    .next{
      ${tw`
       hidden
        `}
    }
    .category {
      ${tw`
      flex
      flex-col
      justify-center
      py-2
      cursor-pointer
     `}
      width: 7rem;
    
    }
    .category p{
      ${tw`
        mt-2
        font-bold
        text-center
     `}
     color: var(--color-black);
      font-size: 0.74rem
    }
    /* Content */
    section{
      ${tw`
        mt-12
      `}
    }
    span{
      ${tw`
        cursor-pointer
        text-red-600
      `}
    }
    .title-sub{
      ${tw`
        mt-4
        mb-8
        text-xl
      `}
    border-bottom: 2px solid var(--color-gray)
    }
    .contentAside{
      ${tw`
        p-8
        h-56
        overflow-auto
      `}
    }
    .contentAside .contentCard{
      ${tw`
      flex
      items-center
      my-2
      `}
    }
    .contentAside p, .contentAside span{
      ${tw`
        ml-4
     `} 
    }
   .content-card span{
      ${tw`
        ml-4
        my-1
        cursor-pointer
        text-red-600
        text-sm
      `}
    }
    .img-card-aside{
      ${tw`
        w-16
        p-2
        rounded
      `}
      height: 4rem;
      background-color: #c0c0c0;
    }
   /* Card Category */
    .categoriesNav{
      ${tw`
        pt-16
      `}
      border-bottom:1px solid var(--color-border);
      background-color: var(--color-white);
    }
    .asideCategory .card{
      ${tw`
        w-4/5
        rounded
      `}
      box-shadow:0px 2px 5px 1px #4448;
    }
    .categoryHeader{
      ${tw`
        py-3
      `}
      background-color: var(--color-secondary) !important;
    }
    .asideCategory{
      ${tw`
        flex
        justify-center
      `}
      top: 2rem; /* altura que vai parar antes do topo */
      position: sticky;
    }
    .asideCategory .content{
      ${tw`
        text-center
        flex
        justify-center
        items-center
        h-32
        w-full
      `}
    }
    .asideCategory .content-card{
      ${tw`
        flex
        items-center
        self-end
        h-20
      `}
    }
   
    .asideCategory .inicialText{
      ${tw`
        ml-4
        w-36
        text-sm
        `}
    }
    .active{
      ${tw`
       p-2
       `}
      border-radius: 0.2rem 0.2rem 0 0;
      border-bottom: 0.2rem solid var(--color-primary-light);
      padding-bottom: 0.6rem;
      background-color: var(--color-white);
    }
    .active p{
      color: var(--color-primary-light) !important;
    }
    .categoryHeader div{
      ${tw`
        text-base
        `}
    }
    .asideCategoryMobile{
        ${tw`
          hidden
        `}
      } 
    /* ---> Mobile */
    @media (max-width: 1318px){
      .categories {
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 83vw;
      } 

      *::-webkit-scrollbar-track {
        background-color: transparent;
      }
    
      *::-webkit-scrollbar {
        background-color: transparent;
        transition: .3s;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #9614c36e;
        border: solid 1px var(--color-primary);
        margin-top:2rem;
        border-radius: 10px;
        height:2rem !important;
      }
    }
    
    @media (max-width: 768px){
      .next{
      ${tw`
       flex
       text-lg
      `}
    }
    span{
      ${tw`
        ml-4
      `}
    }
    .textAsideMobile{
      ${tw`
        flex
        justify-center
        items-start
        flex-col
      `}
    }
    .cardAsideMobile{
      ${tw`
        my-1
        flex
      `}
    }
    .category{
      ${tw`
        w-16
        px-4
        mb-3
      `}
    } 
    .asideCategory{
      ${tw`
        hidden
      `}
    } 
    .asideCategoryMobile{
      ${tw`
        block
      `}
    } 
    .category{
      ${tw`
        w-56
        h-44
      `};
    }
    .card{
      ${tw`
        w-full
        h-60
      `}
    }
    }
    .txt--gray h1{
      ${tw`
        mb-4
      `}
    } 
/*Slide off canvas category */
  .buttonAside {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 3rem;
    background-color: var(--color-primary);
    transition: transform 250ms ease;
    cursor: pointer;
    z-index: 200;
  }
  #menu {
    display: none;
    &:checked ~ .menu {
      background-color: transparent;
      transition: transform 250ms ease;
    }
    &:checked ~ .contentAside {
      right: 0px;
      transition: 0.5s;
    }
  }
  .contentAside {
    position: fixed;
    top: 0;
    right: -100vw;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #fff;
    transition: 0.5s;
    z-index: 0;
  }
  .contentAside nav{
    ${tw`
        font-bold	
        text-white	
        text-center
        py-3
      `}
    background-color: var(--color-secondary) !important;
  }
  .contentAside section{
    ${tw`
      text-center
      p-2
    `}
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:78vh;
  }

}`;

DemandStyled.ModalHeader = styled.header`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--color-green);
  color: var(--color-white);

  h3{
    font-family: 'Estate','Manrope',sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
  }
`;


