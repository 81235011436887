import React, { useRef, useState, useEffect } from 'react';
import { Fragment } from 'react';
import { ShoppingCart } from '../../../Components/Icons';
import LearningVillageModal from '../LearningVillageModal';
import { LVStore } from '../styles';

function LearningVillageItems({ item, addItemToCart, removeItemFromCart, selectedItems }){

    const modalRef = useRef();
    const [ isItemAdded, setIsItemAdded ] = useState();
    const [ quantity, setQuantity ] = useState( parseInt(item.quantity) );
    const [ currentImage, setCurrentImage ] = useState(0);
    const [ itemFinalPrice, setItemFinalPrice ] = useState(item.final_buyer_price);


    useEffect( () => {

        item.final_buyer_price = itemFinalPrice;

    }, [itemFinalPrice] );

    useEffect( () => {

        let isThisItemAdded = selectedItems.some( selectedItem => selectedItem.id == item.id );
        setIsItemAdded( isThisItemAdded );
        
    }, [ selectedItems ] );



    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

/*=============================================
                Item quantity
===============================================*/

    function changeQuantity(value){

        let newQuantity = quantity;

        if( value === "mais" && quantity < parseInt(item.max_quantity) ){

            newQuantity += 1;
            setQuantity(newQuantity);

        } else if( value === "menos" && quantity > parseInt(item.min_quantity) ){

            newQuantity -= 1;
            setQuantity(newQuantity);

        }
        
    }

    function inputChangeQuantity(value) {

        if( value > parseInt(item.max_quantity) ){

            setQuantity( parseInt(item.max_quantity) );

        } else if ( value < parseInt(item.min_quantity) ){

            setQuantity( parseInt(item.min_quantity) );

        } else {

            setQuantity( parseInt(value) );

        }

    }


/*=============================================
            Calculate item price
===============================================*/

    function calculateSubtotal(obj){

        return parseFloat(obj.price) * parseInt(obj.quantity);

    }

    function calculateTotal(obj){

        let subtotal = calculateSubtotal(obj);
        
        if( obj.additional ){

            let total = subtotal + calculateAdditionalPrice(obj.additional);
            return parseFloat(total);

        } else {

            return subtotal;

        }

    }

    function calculateAdditionalPrice(additional_fee) {

        let additional_with_rate = parseFloat(additional_fee / 0.90);

        // console.log('additional_with_rate = ' + additional_with_rate / 0.85);

        return (parseFloat(additional_with_rate)) / 0.85 ;

    }


/*=====================================================
    Functions to add & remove item from shopping cart
======================================================*/

    function addItem( ){        
        
        setIsItemAdded( true );
        
        let newFinalPrice = calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee });
        
        setItemFinalPrice(newFinalPrice);
        item.quantity = quantity;

        addItemToCart( item );

        modalRef.current.close();

    }

    function removeItem(){

        setIsItemAdded( false );

        setItemFinalPrice( parseFloat(item.buyer_price) );

        removeItemFromCart( item.id );

    }


/*=============================================
            Slide images function
===============================================*/

function changeImage( direction ){
    const photosLength = item.photos.length - 1;

    if( direction == "next" && currentImage < photosLength ){

        let newIndex = currentImage + 1;
        setCurrentImage( newIndex );

    } else if( direction == "prev" && currentImage > 0 ){

        let newIndex = currentImage - 1;
        setCurrentImage( newIndex );

    }

}

    return(
        <Fragment >
            <LearningVillageModal
                ref={ modalRef }
                item={ item }
                isItemAdded={ isItemAdded }
                changeQuantity={ changeQuantity }
                inputChangeQuantity={ inputChangeQuantity }
                quantity={ quantity }
                calculateTotal={ calculateTotal }
                addItem={ addItem }
                removeItem={ removeItem }
                changeImage={ changeImage }
                currentImage={ currentImage }
            />

            <LVStore.ItemCard onClick={ () => modalRef.current.open() }>
                {
                    isItemAdded && (
                        <LVStore.ItemCardAdded >
                            <ShoppingCart />
                            <span>no <br /> carrinho</span>
                        </LVStore.ItemCardAdded>
                    )
                }

                <LVStore.ItemCardImg >
                    <img src={ item.photos[0].url } alt="" />
                </LVStore.ItemCardImg>

                <LVStore.ItemCardName >
                    { item.name }
                </LVStore.ItemCardName>

                <LVStore.ItemCardPrice >
                    <span> a partir de </span>
                    { toBraCurrency( parseFloat( item.buyer_price ) ) }
                </LVStore.ItemCardPrice>

                <LVStore.Button className="bgLight blue">
                    <span> VER DETALHES </span>
                </LVStore.Button>
            </LVStore.ItemCard>
        </Fragment>
    )
}

export default LearningVillageItems;