import styled, { keyframes } from 'styled-components';

export const AboutPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
`;

/*===========================================
                Animations
===========================================*/

const exitSlideAnimation = keyframes `
    from{
        opacity: 1;
    }

    to{
        opacity: 0;
    }
`;

const enterSlideAnimation = keyframes `
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
`;

const portfolioGraphismAnimation = keyframes `
    from{
        transform: translateY(-10rem);
        opacity: 0;
    }

    to{
        transform: translateY(0);
        opacity: 1;
    }
`;

const portfolioTitleAnimation = keyframes `
    from{
        transform: translateX(-10rem);
        opacity: 0;
    }

    to{
        transform: translateX(0);
        opacity: 1;
    }
`;


/*===========================================
                Portfolio
===========================================*/

AboutPage.Portfolio = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    background-color: var(--color-black);

    /* Position */
    position: relative;
`;

AboutPage.PortfolioTitle = styled.h1 `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Color */
    color: var(--color-text-in-black);

    /* Position */
    position: relative;
    z-index: 9;

    /* Others */
    animation: ${ portfolioTitleAnimation } 0.5s ease-out 1.2s forwards;
    opacity: 0;
    
    @media (min-width: 800px){
        /* Display & Box Model */
        width: 60%;
    }
`;

AboutPage.PortfolioTitleGraphism = styled.img `
    /* Position */
    position: absolute;
    left: -1rem;
    top: 4rem;

    /* Others */
    animation: ${ portfolioGraphismAnimation } 0.5s ease-out 0.6s forwards;
    opacity: 0;
`;

AboutPage.PortfolioSlide = styled.figure `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    /* Position */
    position: absolute;

    /* Others */
    overflow: hidden;
    animation: ${ enterSlideAnimation } 0.5s ease-out forwards;

    img{
        /* Display & Box Model */
        width: auto;
        height: 100%;

        /* Position */
        position: absolute;
    }

    &.exit{
        animation: ${ exitSlideAnimation } 0.5s ease-out forwards;
    }

    @media (min-width: 800px){
        img{
            /* Display & Box Model */
            width: 100%;
        }
    }
`;

AboutPage.SlideButtons = styled.div `
    /* Display & Box Model */
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    /* Position */
    position: relative;
    bottom: 5rem;
    z-index: 9;
`;

AboutPage.SlideButton = styled.div `
    /* Display & Box Model */
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;

    /* Color */
    background-color: var(--color-white);

    /* Others */
    cursor: pointer;

    &.selected {
        /* Color */
        background-color: var(--color-secondary);
    }
`;

AboutPage.PortfolioSlideLegend = styled.figcaption `
    /* Display & Box Model */
    padding: 1rem;

    /* Color */
    background-color: rgba( 255, 255, 255, .3);
    color: var(--color-text-in-black);

    /* Position */
    position: relative;
    bottom: 4rem;
`;

AboutPage.PortfolioLeftShadow = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Position */
    position: absolute;
    left: 0;
    /* z-index: 9; */

    /* Others */
    background-image: linear-gradient(to right, #000000, transparent);
`;


/*===========================================
            "Somos Celebrar"
===========================================*/

AboutPage.Celebrar = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 2rem 0;

    /* Color */
    background-color: var(--color-black);
    color: var(--color-text-in-black);

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;
`;

AboutPage.CelebrarTitle = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    text-align: center;
`;

AboutPage.CelebrarGraphism = styled.img `
    /* Position */
    position: absolute;

    :nth-child(1){
        left: -1rem;
    }

    :nth-child(2){
        transform: rotateY(180deg);
        right: -1rem;
    }
`;

AboutPage.CelebrarText = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    line-height: 1.5;

    p{
        /* Display & Box Model */
        width: 80%;
        margin: auto;
    }
`;

/*===========================================
            Celebrar services
===========================================*/

AboutPage.Services = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 4rem 0;

    /* Color */
    background-color: var(--color-black);
    color: var(--color-text-in-black);

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;
`;

AboutPage.ServicesTitle = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 4rem;

    /* Text */
    text-align: center;

    img{
        width: 16rem;
    }

    @media (min-width: 800px){
        img{
            width: auto;
        }
    }
`;

AboutPage.ServicesGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: repeat( 2, 1fr);
    grid-gap: 1rem;
    margin: 2rem auto;

    @media (min-width: 800px){
        grid-template-columns: repeat( 7, 1fr);
    }
`;

AboutPage.ServicesCard = styled.img `
    /* Display & Box Model */
    width: 100%;
`;

AboutPage.ServicesGraphism = styled.img `
    /* Position */
    position: absolute;

    :nth-child(1){
        /* Positon */
        left: -1rem;
        bottom: 0;
    }

    :nth-child(2){
        /* Positon */
        right: -1rem;
        top: -1rem;
    }
`;

/*===========================================
        Third Section - Supplier photos
===========================================*/

AboutPage.Suppliers = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Color */
    background-color: black;
    color: var(--color-text-in-black);

    /* Text */
    text-align: center;

    /* Position */
    position: relative;
`;

AboutPage.SuppliersTitle = styled.h3 `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    font-size: 2rem;

    @media (min-width: 800px){
        width: 70%;
    }
`;

AboutPage.SuppliersText = styled.p `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    @media (min-width: 800px){
        width: 70%;
    }
`;

AboutPage.SuppliersBgImages = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    /* Position */
    position: absolute;

    /* Others */
    overflow: hidden;
    animation: ${ enterSlideAnimation } 0.5s ease-out forwards;

    img{
        width: auto;
        height: 100%;
    }

    &.exit{
        animation: ${ exitSlideAnimation } 0.5s ease-out forwards;
    }

    @media (min-width: 800px){
        width: 100%;
    }
`;

AboutPage.SuppliersButtons = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    @media (min-width: 800px){
        width: 60%;
        flex-direction: row;
        grid-gap: 2rem;
    }
`;

AboutPage.SuppliersShadow = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Position */
    position: absolute;
    left: 0;
    top: 0;

    /* Others */
    background-image: linear-gradient( to bottom, transparent, #000000);
`;