import React from 'react';
import styled from 'styled-components';
import { FormTxtInput } from '../FormTextInput';
import { Input } from './styles';

function FormDateInput({width, color, colorText, ...props}){
  let minDate = new Date();                    //Gravo a data atual na variavel
  minDate.setDate(minDate.getDate() + 1);        //Adiciono 7 dias 
  minDate = minDate.toISOString().split('T')[0];

  return(
    <InputContainer
      width={ width }
      color={ color }
      colorText={ colorText }
    >
      <FormTxtInput
        type="date"
        label={props.label}
        placeholder={props.label}
        icon={props.icon}
        onKeyDown={(e) => e.preventDefault()}
        min={minDate}
        {...props}
      />
    </InputContainer>
  )
}

export default FormDateInput;

const InputContainer = styled.div `
  input {
    width: ${({width}) => width ? width : '100%'};
    box-sizing: border-box;
    padding: 0.7rem 1rem;
    position: relative;
    outline: 0;
    border: none;
  }

  input::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;