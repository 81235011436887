import styled from "styled-components";

export const NewDemandPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-primary-light);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

NewDemandPage.Checkout = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-white);
`;

NewDemandPage.Content = styled.div`
  color: var(--color-white);
  max-width: 800px;
  position: relative;
  padding: 7rem 0 5rem;
  margin-left: 10%;

  h1 {
    font-size: 4rem;
    font-family: "Estate", "Manrope", sans-serif;
    font-weight: 700;
    margin: 1rem 0 1rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  button.outline-button {
    background-color: transparent;
    color: var(--color-white);
    border-color: var(--color-white);

    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary-light);
    }
  }

  @media (max-width: 1150px) {
    margin-right: 10%;
    padding-bottom: 2rem;
  }

  @media (max-width: 540px) {
    padding-bottom: 1rem;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }
`;

NewDemandPage.CheckoutContent = styled.div`
  color: var(--color-primary-light);
  position: relative;
  padding: 7rem 0;

  h1 {
    font-size: 4rem;
    font-family: "Estate", "Manrope", sans-serif;
    font-weight: 700;
    margin: 1rem 0 1rem;
  }

  h2 {
    font-size: 1.8rem;
    margin-bottom: 3.5rem;
  }

  button.outline-button {
    background-color: transparent;
    color: var(--color-white);
    border-color: var(--color-white);

    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary-light);
    }
  }

  @media (max-width: 1150px) {
    margin-right: 10%;
    padding-bottom: 2rem;
  }

  @media (max-width: 540px) {
    padding-bottom: 1rem;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }
`;

NewDemandPage.CloseCategoryButton = styled.button`
  position: absolute;
  line-height: 0;
  top: 8rem;
  right: 1rem;
  padding: 0.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-white);
  background-color: transparent;
  color: var(--color-white);

  &:hover {
    cursor: pointer;
  }
`;

NewDemandPage.BackButton = styled.button`
  margin: 1rem 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--color-white);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

NewDemandPage.CategoriesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
`;

NewDemandPage.CategoryCard = styled.div`
  background-color: var(--color-blue-light);
  border: 1px solid var(--color-border);
  border-radius: 3px;
  height: 140px;
  width: 140px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  p {
    position: absolute;
    top: 50%;
    left: 0.5rem;
  }

  @media (max-width: 540px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

NewDemandPage.ChildrenCategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 4.25rem;

  button {
    background-color: var(--color-primary-dark);
    border-radius: 3px;
    border: 1px solid var(--color-primary-dark);
    padding: 0.5rem 1rem;
    text-align: left;
    color: var(--color-white);

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      cursor: pointer;
      border-color: var(--color-white);
      background-color: var(--color-blue-light);
    }
  }
`;

NewDemandPage.ChildrenSubCategoriesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;

  li {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color: var(--color-primary-dark);
    color: var(--color-white);
    border: 1px solid var(--color-primary-dark);

    &.selected {
      background-color: var(--color-green);
      border-color: var(--color-white);
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 540px) {
    gap: 0.5rem;
    li {
      font-size: 0.9rem;
    }
  }
`;

NewDemandPage.Form = styled.form`
  margin-bottom: 4rem;
`;

NewDemandPage.FormErrorMessage = styled.p`
  /* Display & Box Model */
  width: fit-content;
  display: block;
  padding-bottom: 0.3rem;

  /* Colors */
  color: var(--color-black);

  /* Text */
  font-size: 0.8rem;
  font-weight: 700;

  /* Others */
  border-bottom: 2px solid var(--color-red);
`;

NewDemandPage.ModalErrorMessage = styled.p`
  /* Display & Box Model */
  width: fit-content;
  display: block;
  padding-bottom: 0.3rem;

  /* Colors */
  color: var(--color-black);

  /* Text */
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
`;

NewDemandPage.FormFileField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 2rem;

  p {
    font-weight: 700;
  }

  button {
    margin: 0;
    border-radius: 0;
    padding: 0;
    border: none;

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }

    :hover {
      background-color: var(--color-white);
      color: var(--color-primary-light);
    }
  }
`;

NewDemandPage.DatetimeFormField = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  row-gap: 1rem;

  input[type="date"] {
    width: 10rem;
  }

  input[type="time"] {
    width: 8rem;
  }
`;

NewDemandPage.CheckboxFormField = styled.div`
  margin-bottom: 2rem;

  label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  input {
    width: 20px;
    margin: 0;

    &:focus {
      background-color: var(--color-primary-light);
    }
  }
`;

NewDemandPage.EventSummary = styled.div`
  /* Display & Box Model */
  margin-top: 3rem;
  width: 100%;
  height: 15vh;
  background-color: var(--color-white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Position */
  position: fixed;
  bottom: 0;
  right: 0;

  h1 {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 700;
  }

  &.open {
    height: 80vh;
    padding-bottom: 6rem;
    /* Others */
    overflow-y: scroll;
  }

  h1 span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-light);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    margin-top: 4px;
    color: var(--color-white);
    font-weight: 700;
    font-size: 0.9rem;
  }

  h1 button {
    position: absolute;
    top: 0;
    right: 1rem;
  }

  @media (min-width: 800px) {
    width: 22rem;
    min-height: 100vh;
    /* padding: 6rem 10%; */
    position: fixed;
    top: 0rem;
    bottom: auto;
    padding: 6rem 2rem;
    /* margin-top: 5rem; */

    /* Others */
    overflow-y: scroll;

    h1 button {
      display: none;
    }
  }

  @media (min-width: 1400px) {
    width: 30rem;
  }
`;

NewDemandPage.EmptyEventMessage = styled.p`
  margin: auto;
  padding: 0 1rem;
  line-height: 1.8;
  text-align: center;
`;

NewDemandPage.Summary = styled.div`
  margin: 2rem 0;
  flex: 1;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  /* > div {
    border: 1px solid var(--color-border);
    height: 200px;
  } */

  .remove-btn {
    font-size: 0.9rem;
    border-radius: 3px;
    background-color: var(--color-border);
    color: var(--color-white);
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    width: fit-content;

    &:hover {
      cursor: pointer;
    }
  }
`;

NewDemandPage.SummaryCard = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
`;

NewDemandPage.SummaryCardImage = styled.figure`
  width: 182px;
  height: 128px;
  border-radius: 0.3rem;
  background-color: var(--color-gray);
  position: relative;
  overflow: hidden;

  ::before {
    content: "\\2714";
    width: 4rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    background-color: var(--color-green);
    color: var(--color-white);
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.2rem;
    padding-left: 1.5rem;
    box-sizing: border-box;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }

  img {
    width: 100%;
  }
`;

NewDemandPage.SummaryCardBody = styled.div `
  max-width: 20rem;
  hight: 128px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  h3{
    color: var(--color-black);
    font-weight: 700;
  }

  .buttons{
    display: flex;
    gap: 1rem;
  }
`;

NewDemandPage.SummaryCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 0.5rem;

  span {
    background-color: var(--color-primary-light);
    color: var(--color-white);
    font-weight: 700;
    font-size: 0.9rem;
    padding: 4px 8px;
    border-radius: 3px;
  }
`;

NewDemandPage.SummaryInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;

  div {
    border: 1px solid var(--color-border);
    border-radius: 3px;
    padding: 0.5rem 1rem;

    label {
      font-size: 0.8rem;
      color: var(--color-gray-medium);
    }

    p {
      padding-top: 2px;
      color: var(--color-black);
    }
  }
`;

NewDemandPage.CheckoutSummaryInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 2rem;

  div {
    border: 1px solid var(--color-border);
    border-radius: 3px;
    padding: 0.5rem 1rem;

    label {
      font-size: 0.8rem;
      color: var(--color-gray-medium);
    }

    p {
      color: var(--color-black);
      padding-top: 2px;
    }
  }
`;

NewDemandPage.AdditionalQuestions = styled.div`
  margin-top: 4rem;
  h3 {
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  p {
    color: var(--color-black);
    margin-bottom: 1rem;
  }

  label {
    color: var(--color-black);
  }

  .extra-questions-forms-section {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0;
  }

  .extra-questions-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;

    div {
      color: var(--color-primary-light);
      padding: 1rem 2rem;
      border-radius: 3px;
      border: 1px solid var(--color-primary-light);
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        color: var(--color-white);
        background-color: var(--color-primary-light);
      }
    }
  }
`;

/*====================================
        Demand Sucess
=====================================*/

export const SucessPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;

  /* Colors */
  background-color: var(--color-primary-light);
  background-image: url(${({ bg }) => bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

SucessPage.Content = styled.div`
  /* Display & Box Model */
  width: 100;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Colors */
  color: var(--color-white);

  img {
    max-width: 12rem;
  }

  h1 {
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    line-height: 1.5;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: start;

    img {
      max-width: inherit;
    }

    h1 {
      /* Text */
      font-size: 3rem;
      font-weight: 700;
    }
  }
`;
NewDemandPage.Alert = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: red;
  text-align: center;
  font-weight: bold;
  color: #fff;
  position: fixed;
  z-index: 10;
  margin-top: 4rem;
  font-size: 0.8rem;
  p {
    font-size: 0.8rem;
  }
  span {
    font-size: 0.8rem;
    font-weight: bold;
  }
`;
