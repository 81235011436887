import React from 'react';
import { ReviewsPage } from './styles';

const Star = ({ setRating, value, isActived }) => {
    return (
        <ReviewsPage.CelebrarStar
            className={isActived ? 'actived' : undefined}
            onClick={() => setRating(value)}
        >
            <svg
                width="68"
                height="66"
                viewBox="0 0 48 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
            </svg>
            <span>{value}</span>
        </ReviewsPage.CelebrarStar>
    );
};

function ReviewCelebrar({
    npsRating,
    setNpsRating,
    npsDescription,
    setNpsDescription,
    isAbleToSend,
    submitReview,
    isSubmitting,
}) {
    return (
        <ReviewsPage.Celebrar>
            <h2 className="txt--black margin--tb-2">
                {' '}
                De zero a dez, o quanto você recomenda a Celebrar para amigos ou familiares?{' '}
            </h2>
            <br />
            <div className="container">
                <ReviewsPage.CelebrarGrid>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => {
                        return (
                            <Star
                                key={value}
                                value={value}
                                rating={npsRating}
                                isActived={npsRating && value <= npsRating}
                                setRating={setNpsRating}
                            />
                        );
                    })}
                </ReviewsPage.CelebrarGrid>

                <div className="input__field">
                    <label htmlFor="description">Se quiser, conte-nos o porquê da nota acima</label>
                    <textarea
                        name="decription"
                        id="description"
                        className="input textarea"
                        cols="30"
                        rows="10"
                        value={npsDescription}
                        onInput={event => setNpsDescription(event.target.value)}
                    />
                </div>

                <br />
                <button
                    className="btn btn--center"
                    type="button"
                    disabled={!isAbleToSend || isSubmitting}
                    onClick={submitReview}
                >
                    <span> {isSubmitting ? 'Enviando...' : 'Enviar avaliação'} </span>
                </button>
            </div>
        </ReviewsPage.Celebrar>
    );
}

export default ReviewCelebrar;
