import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/dateFunctions';
import { SalesPanelPage } from './styles';

function ConfirmedContractCard({ data }) {
  const { id, name, offers, state, alert_proposals, delivery_date, expires_at, delivery_estimated_at } =
    data;
  const formattedExpiresDate = formatDate(expires_at);
  const formattedDeliveryDate = delivery_estimated_at
    ? formatDate(delivery_estimated_at)
    : delivery_date;
  const offersCount =
    offers.length + alert_proposals.filter(a => a.supplier_offer_id === null).length;

  return (
    <SalesPanelPage.Card>
      <SalesPanelPage.CardHeader>
        <h5 className="txt--black">{name}</h5>
        {offersCount > 0 && (
          <p className="txt--secondary">
            Você enviou {offersCount} {offersCount === 1 ? 'orçamento' : 'orçamentos'}
          </p>
        )}

        {formattedDeliveryDate && (
          <p>
            Data do evento: <span className="txt--bold">{formattedDeliveryDate}</span>{' '}
          </p>
        )}
        {expires_at && (
          <p>
            Vencimento da oportunidade: <span className="">{formattedExpiresDate}</span>{' '}
          </p>
        )}
        <p>
          Estado: <span>{state || 'SP'}</span>
        </p>
      </SalesPanelPage.CardHeader>

      <button>
        <Link to={`oportunidade/${id}`} className="txt--primary">
          {offersCount > 0 ? 'Enviar um novo orçamento' : 'Enviar orçamento'}
        </Link>
      </button>
    </SalesPanelPage.Card>
  );
}

export default ConfirmedContractCard;
