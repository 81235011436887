import React from "react";
import styled from "styled-components";
import { CategoriesAsideStyled } from "../../../Components/Categories/Categories.styles.tw";
import {
  ArrowRight,
  ShoppingCart,
} from "feather-icons-react/build/IconComponents";
import { CategoriesImages } from "../../../Components/Categories/CategoriesImages";

export const EventSummary = ({
  newDemand,
  handleAddCategory,
  handleRemoveCategory,
  setDemandCheckout,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Aside className={isOpen ? "open" : ""}>
          <Aside.Header>
            <h4>Meu pedido</h4>
            <p>{newDemand?.length} itens</p>
          </Aside.Header>
          <Aside.Body>
            {newDemand?.map((demand) => {
              return (
                <Demand>
                  <Demand.Photo>
                    <img
                      src={
                        CategoriesImages.find((e) => e.categoryId === demand.id)
                          .url
                      }
                      alt={demand.name}
                    />
                  </Demand.Photo>
                  <Demand.Header>
                    <h4>{demand.name}</h4>
                    <div>
                      <Demand.Button
                        type="button"
                        onClick={() => handleAddCategory(demand)}
                      >
                        Ver detalhes
                      </Demand.Button>
                      <Demand.Button
                        type="button"
                        onClick={() => handleRemoveCategory(demand.id)}
                      >
                        Remover
                      </Demand.Button>
                    </div>
                  </Demand.Header>
                </Demand>
              );
            })}

            {newDemand?.length < 1 && (
              <h5>Adicione ao menos um item ao seu pedido para prosseguir</h5>
            )}
          </Aside.Body>

          <Aside.Button
            type="button"
            onClick={() => setDemandCheckout(true)}
            disabled={newDemand?.length < 1}
          >
            <h5>Finalizar pedido</h5>
            <ArrowRight />
          </Aside.Button>
        </Aside>
      </div>
      <Aside.OpenModalBtn onClick={() => setIsOpen(true)}>
        <div>{newDemand?.length}</div>
        <ShoppingCart />
      </Aside.OpenModalBtn>
      <Aside.Lightbox
        className={isOpen ? "open" : ""}
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
};

export const Aside = styled.aside`
  /* Display & Box Model */
  width: 100%;
  max-width: 400px;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: fixed;

  /* Others */
  border-radius: 0.5rem;
  box-shadow: var(--shadow);

  @media (max-width: 600px) {
    /* Display & Box Model */
    width: 90%;
    height: 100vh;

    /* Position */
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;

    /* Others */
    border-radius: 0;
    transform: translateX(35rem);
    transition: all 0.3s ease-out;

    &.open {
      transform: translateX(0);
      transition: all 0.3s ease-out;
    }
  }
`;

Aside.Lightbox = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;
  display: none;

  /* Colors */
  background-color: rgba(0, 0, 0, 0.6);

  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;

  @media (max-width: 600px) {
    &.open {
      display: block;
    }
  }
`;

Aside.OpenModalBtn = styled.div`
  /* Display & Box Model */
  width: 4rem;
  height: 4rem;
  display: none;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-color: var(--color-primary-light);
  color: var(--color-white);

  /* Position */
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;

  /* Others */
  border-radius: 50%;
  box-shadow: var(--shadow);

  @media (max-width: 600px) {
    display: flex;

    div {
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: absolute;
      text-align: center;
      top: 0.5rem;
      left: -0.5rem;
      background-color: var(--color-white);
      color: var(--color-primary-light);
      border-radius: 50%;
    }
  }
`;

Aside.Header = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  /* Others */
  border-bottom: 0.1rem solid var(--color-border);

  h4 {
    /* Colors */
    color: var(--color-purple-light);

    /* Text */
    font-weight: 600;
  }

  p {
    /* Color */
    color: var(--color-blue-light);
  }
`;

Aside.Button = styled.button`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-primary-light);

  /* Others */
  border: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  cursor: pointer;

  &:disabled {
    /* Colors */
    color: var(--color-text);

    /* Others */
    cursor: default;
  }

  h5 {
    /* Text */
    font-weight: 700;
  }

  /* Others */
  border-top: 0.1rem solid var(--color-border);
`;

Aside.Body = styled.div`
  /* Display & Box Model */
  width: 100%;
  max-height: 16rem;
  min-height: 8rem;
  overflow-y: scroll;

  h5 {
    text-align: center;
    padding: 4rem 0;
  }

  @media (max-width: 600px) {
    height: 80vh;
    max-height: fit-content;
  }
`;

export const Demand = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem 1.5rem;

  /* Colors */
  backgroundcolor: var(--color-white);
`;

Demand.Header = styled.div`
  /* Display & Box Model */
  h4 {
    color: var(--color-primary-light);
    font-size: 0.8rem;
    font-weigth: 600;
  }

  div {
    display: flex;
    gap: 1rem;
  }
`;

Demand.Photo = styled.figure`
  /* Display & Box Model */
  width: 4rem;
  height: 3rem;

  background: #696969;

  /* Others */
  border-radius: 0.3rem;

  img {
    width: 100%;
    border-radius: 0.3rem;
  }
`;

Demand.Button = styled.div`
  /* Display & Box Model */
  margin-top: 0.5rem;

  /* Text */
  font-size: 0.8rem;

  /* Others */
  cursor: pointer;

  :last-child {
    color: var(--color-red);
  }
`;
