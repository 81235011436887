import styled from 'styled-components';

export const ReviewsPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 50vh;
    padding-bottom: 2rem;

    /* Colors */
    background-color: var(--color-gray-light);
`;

ReviewsPage.Header = styled.header `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 0;

    /* Colors */
    background-color: var(--color-black);

    h1{
        font-size: 1.2rem;
    }

    img:not(#logo){
        margin-top: 5rem;
    }

    @media (min-width: 800px){
        h1{
            font-size: 2rem;
        }

        img{
            margin-top: 0;
        }
    }
`;

ReviewsPage.HeaderLogo = styled.img `
    /* Display & Box Model */
    width: 3rem;

    /* Position */
    position: absolute;
    left: 0;
`;

ReviewsPage.HeaderInfos = styled.div `
    /* Display & Box Model */
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    /* Position */
    position: absolute;
    right: 0;

    /* Text */
    text-align: right;
`;

ReviewsPage.Reviews = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto 4rem auto;
`;

/*=========================================
            Review Card
=========================================*/

ReviewsPage.Review = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 16rem;
    margin: 2rem auto;

    /* Colors */
    background-color: var(--color-white);

    /* Others */
    border-radius: var(--radius);
    box-shadow: var(--shadow);

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 40rem;
    }
`;

ReviewsPage.ReviewGrid = styled.div `
    /* Display & Box Model */
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 800px){
        &.photos{
            grid-template-columns: 40% 60%;
        }
    }
`;

ReviewsPage.ReviewImages = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 18.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Colors */
    background-color: var(--color-gray-light);
    border-right: 0.1rem solid var(--color-border);

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;

    img{
        height: 18.5rem;
    }
`;

ReviewsPage.ReviewImagesBtn = styled.img `
    /* Display & Box Model */
    width: 2rem;
    height: 2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;

    /* Colors */
    background-color: var(--color-secondary);

    /* Potision */
    position: absolute;

    /* Others */
    border-radius: 50%;
    cursor: pointer;

    &.left{
        left: 0;
    }

    &.right{
        right: 0;
    }
`;

ReviewsPage.ReviewDetails = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 1rem 0 2rem;
`;

ReviewsPage.ReviewDescription = styled.p `
    /* Display & Box Model */
    width: 100%;
    height: 4rem;
    display: -webkit-box;

    /* Others */
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

ReviewsPage.ReviewStars = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: repeat( 5, 1fr);
    margin: 2rem auto;

    svg{
        /* Others */
        cursor: pointer;
        fill: var(--color-white);
        stroke: black;

        &.actived{
            fill: var(--color-primary);
            stroke: white;
        }
    }

    .actived{
        svg{
            fill: var(--color-primary);
            stroke: white;
        }
    }
`;

ReviewsPage.ReviewFeedback = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem;
    
    /* Others */
    border-top: 0.1rem solid var(--color-border);
`;

ReviewsPage.ReviewFeedbackTag = styled.div `
    /* Display & Box Model */
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    display: inline-block;

    /* Colors */
    color: var(--color-black);

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border-radius: 0.5rem;
    border: 0.1rem solid var(--color-black);
    cursor: pointer;

    &.selected{
        /* Colors */
        background-color: var(--color-black);
        color: var(--color-white);
    }
`;

/*=========================================
            Review Celebrar
=========================================*/

ReviewsPage.Celebrar = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    text-align: center;
`;

ReviewsPage.CelebrarStar = styled.div `
    /* Display & Box Model */
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        /* Others */
        cursor: pointer;
        fill: var(--color-white);
        stroke: black;

        &.actived{
            fill: var(--color-primary);
            stroke: white;
        }
    }

    span{
        /* Colors */
        color: var(--color-black);

        /* Position */
        position: absolute;

        /* Text */
        font-size: 1.2rem;
        font-weight: 700;

        /* Others */
        cursor: pointer;
    }

    &.actived{
        svg{
            fill: var(--color-primary);
            stroke: white;
        }

        span{
            /* Colors */
            color: var(--color-white);
        }
    }
`;

ReviewsPage.CelebrarGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: repeat( 3, 1fr);
    grid-gap: 1rem 0;
    margin: 2rem auto;

    @media (min-width: 800px){
        grid-template-columns: repeat( 11, 1fr);
    }
`;

ReviewsPage.Reviewed = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    /* Text */
    text-align: center;

    img{
        width: 15rem;
    }
`;