import styled, { keyframes } from "styled-components";

const colors = {
    white: "#fff",
    black: "#000",
    yellow: "#f6be00"
}

export const EscalePage = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;

    /* Color */
    background-color: var(--color-white);
`;

EscalePage.ItemsGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    @media (min-width: 600px){
        /* Display & Box Model */
        grid-template-columns: repeat( 2, 50%);
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        grid-template-columns: repeat( 3, 33.33%);
    }

    @media (min-width: 1100px){
        /* Display & Box Model */
        grid-template-columns: repeat( 4, 22%);
    }
`;

EscalePage.PageTitle = styled.h5 `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 2rem auto;

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    hr{
        /* Display & Box Model */
        width: 3rem;
        height: 0.1rem;
        margin-right: 1rem;

        /* Color */
        color: ${ colors.black };
    }
`;

/*===========================================
                Escale menu
===========================================*/

EscalePage.Menu = styled.header `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 0;

    /* Color */
    color: ${ colors.white };

    /* Text */
    text-align: left;

    /* Position */
    position: absolute;
    z-index: 99;

    img{
        width: 6rem;
    }
`;

/*===========================================
                Slider
===========================================*/

EscalePage.Slide = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;

    .container{
        /* Display & Box Model */
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    img{
        /* Display & Box Model */
        height: 100%;

        /* Position */
        position: absolute;
    }

    h1{
        /* Display & Box Model */
        width: 100%;
        margin-bottom: 2rem;

        /* Text */
        font-size: 1.6rem;
    }

    p{
        /* Display & Box Model */
        width: 100%;
    }

    h1, p{
        /* Color */
        color: ${ colors.white };

        /* Position */
        position: relative;
        z-index: 9;

        /* Others */
        text-shadow: 0 0 0.5rem rgba( 0, 0, 0, 0.6);
    }

    @media (min-width: 800px){
        img{
            /* Display & Box Model */
            width: 100%;
            height: auto;
        }

        h1{
            /* Display & Box Model */
            width: 45%;
            margin-bottom: 2rem;

            /* Text */
            font-size: 3rem;
        }

        p{
            /* Display & Box Model */
            width: 40%;

            /* Text */
            line-height: 1.5;
        }
    }
`;

/*===========================================
                Categories
===========================================*/

EscalePage.Categories = styled.section `
    /* Display & Box Model */
    width: 100%;

    /* Position */
    position: relative;
`;

EscalePage.CategoriesGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    margin-bottom: 1rem;

    @media (min-width: 650px){
        /* Display & Box Model */
        flex-direction: row;
    }
`;

EscalePage.Category = styled.div `
    /* Display & Box Model */
    width: 90%;
    height: 230px;
    background-color: red;

    /* Text */
    text-align: center;

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    div{
        /* Display & Box Model */
        width: 100%;
        height: 230px;
        padding: 1rem;
        display: flex;
        align-items: flex-end;

        /* Others */
        background-image: linear-gradient( to bottom, transparent 50%, #000);

        &.blocked{
            /* Color */
            background-color: rgba( 0, 0, 0, 0.8);

            /* Others */
            background-image: none;
            backdrop-filter: blur(10px);
            cursor: no-drop;
        }
    }

    p{
        /* Position */
        position: relative;
        z-index: 9;

        /* Color */
        color: ${ colors.white };
    }

    @media (min-width: 600px){
        /* Display & Box Model */
        width: 100%;
    }
`;

EscalePage.CategoryPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: left;
    align-items: center;

    img{
        /* Display & Box Model */
        width: 100%;

        /* Position */
        position: absolute;
        top: 0;
        left: 0;
    }

    h1{
        /* Color */
        color: ${ colors.white };

        /* Text */
        font-size: 1.6rem;
        font-weight: 700;

        /* Others */
        text-shadow: 0 0 0.5rem rgba( 0, 0, 0, 0.8);
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        height: 420px;

        h1{
            /* Text */
            font-size: 3rem;
        }
    }
`;

EscalePage.CategoriesBtn = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    /* Position */
    position: relative;

    p{
        /* Text */
        text-align: center;

        /* Others */
        cursor: pointer;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 50%;

        /* Position */
        position: absolute;
        right: 0;
    }

`;

/*===========================================
                Section
===========================================*/

EscalePage.Section = styled.section `
    /* Display & Box Model */
    width: 100%;
    margin: 3rem auto 5rem auto;
`;

EscalePage.SectionTitle = styled.p `
    /* Display & Box Model */
    width: max-content;
    margin: 2rem 0;
    padding: 0 2rem 0.5rem 0;
    border-bottom: solid 0.2rem ${ colors.yellow };

    /* Color */
    color: ${ colors.black };

    /* Text */
    font-size: 1.2rem;
    text-align: left;

    @media (min-width: 800px){
        /* Text */
        font-size: 1.6rem;
    }
`;

/*===========================================
                Cards
===========================================*/

EscalePage.CardItem = styled.div `
    /* Display & Box Model */
    width: 100%;
    max-width: 12rem;
    margin: 1rem auto;

    /* Position */
    position: relative;

    &:hover {
        button {
            /* Display & Box Model */
            width: auto;
            padding: 0.5rem 1rem;
    
            span{
                /* Display & Box Model */
                margin-left: 0.5rem;
                display: block;
            }
        }
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        max-width: 14rem;
    }
`;

EscalePage.CardImage = styled.figure `
    /* Display & Box Model */
    width: 100%;
    height: 15rem;
    /* border: solid 0.1rem var(--color-border); */
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Text */
    text-align: center;

    /* Others */
    cursor: pointer;
    overflow: hidden;

    img{
        height: 100%;
    }
`;

EscalePage.CardSelected = styled.div `
    /* Display & Box Model */
    padding: 0.5rem;

    /* COlor */
    background-color: ${ colors.yellow };
    color: ${ colors.black };

    /* Text */
    font-size: 0.8rem;
    text-align: center;

    /* Position */
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
`;

EscalePage.CardTitle = styled.p `
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Text */
    text-align: left;
    font-size: 1rem;
    font-weight: 300;

    /* Others */
    cursor: pointer;
`;

EscalePage.CardPrice = styled.p `
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Text */
    text-align: left;
    font-weight: 700;

    /* Others */
    cursor: pointer;
`;

EscalePage.CardButton = styled.button `
    /* Display & Box Model */
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    overflow: hidden;

    /* Color */
    background-color: ${ colors.yellow };
    color: ${ colors.black };

    /* Text */
    font-size: 0.8rem;

    /* Others */
    transition: all 0.3s ease-out;
    cursor: pointer;

    span{
        /* Display & Box Model */
        display: none;
    }

    :hover{
        /* Display & Box Model */
        width: auto;
        padding: 0.5rem 1rem;

        span{
            /* Display & Box Model */
            margin-left: 0.5rem;
            display: block;
        }
    }
`;

EscalePage.ImgButton = styled.button `
    /* Display & Box Model */
    width: 0;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    overflow: hidden;

    /* Color */
    background-color: ${ colors.yellow };

    /* Others */
    transition: all 0.3s ease-out;
    cursor: pointer;

    /* Position */
    position: absolute;

    :nth-child(2){
        /* Position */
        right: -0.5rem;
        left: auto;
    }


    :nth-child(3){
        /* Position */
        left: -0.5rem;

        /* Others */
        transform: rotate(180deg);
    }

    &.hidden {
        /* Display & Box Model */
        display: none;
    }
`;

/*===========================================
                Shopping Cart
===========================================*/

EscalePage.CartComponent = styled.div `
    /* Display & Box Model */
    width: 2rem;
    height: 100vh;
    display: flex;
    justify-content: right;
    align-items: center;

    /* Position */
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;

    /* Others */
    transition: all 0.3s ease-out;
    
    &.open{
        /* Position */
        width: 100%;
    }
`;

EscalePage.Cart = styled.div `
    /* Display & Box Model */
    width: 1rem;
    height: 100vh;

    /* Color */
    background-color: ${ colors.black };
    color: ${ colors.black };

    /* Position */
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    /* Others */
    transition: all 0.3s ease-out;

    &.open{
        /* Display & Box Model */
        width: 80%;

        /* Color */
        background-color: ${ colors.white };
    }

    @media (min-width: 800px){
        &.open{
            /* Display & Box Model */
            width: 60%;
        }
    }
`;

EscalePage.CartImg = styled.figure `
    /* Display & Box Model */
    width: 2rem;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Position */
    position: absolute;
    top: 0;

    img{
        /* Display & Box Model */
        height: 100vh;

        /* Position */
        position: relative;
        left: -2rem;

        /* Others */
        cursor: pointer;
    }

    svg{
        /* Position */
        position: absolute;
        z-index: 99;
        left: -2rem;
        margin-top: -2.5rem;

        /* Others */
        cursor: pointer;
    }
`;

EscalePage.CartItemsQuantity = styled.div `
    /* Display & Box Model */
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -5.5rem;

    /* Color */
    background-color: ${ colors.white };
    color: ${ colors.black };

    /* Text */
    font-size: 0.8rem;

    /* Position  */
    position: absolute;
    left: -0.5rem;
    z-index: 99;
`;

EscalePage.CartContent = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    padding: 0 0.5rem 0 1rem;
    
    /* Others */
    overflow-y: scroll;
    
    @media (min-width: 800px){
        /* Display & Box Model */
        padding: 0 2rem;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 1rem;

        /* Others */
        overflow-y: scroll;
    }
`;

const lightboxAnimation = keyframes `
    from{
        opacity: 0;
    }

    to{
        opacity: 0.8;
    }
`;

EscalePage.CartTitle = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    h3, h5{
        /* Text */
        font-size: 1.2rem;
        font-weight: 300;
    }
`;

EscalePage.CartItems = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 50vh;
    padding: 1rem 1rem 2rem 0;
    margin-bottom: 1rem;
    border-bottom: solid 0.1rem var(--color-border);

    /* Others */
    overflow-y: scroll;

    @media (min-width: 800px){
        /* Display & Box Model */
        height: 100vh;
        
        /* Others */
        overflow-y: scroll;
    }
`;

EscalePage.CartItem = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1rem;
    border-bottom: solid 0.1rem var(--color-gray);
    margin: 1rem auto;
    padding: 0.5rem 0;
`;

EscalePage.CartTotalPrice = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 0 1rem;

    /* Text */
    font-size: 1rem;
    font-weight: 700;

    label{
        /* Text */
        font-weight: 300;
    }

    h3{
        /* Display & Box Model */
        margin-bottom: 2rem;
    }

    h6{
        /* Text */
        font-size: 1.2rem;
    }

    div{
        /* Display & Box Model */
        display: flex;
        justify-content: space-between;
        margin: 1rem auto;
    }

    input{
        /* Display & Box Model */
        margin: 1rem auto;
    }
`;

EscalePage.Address = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: block !important;
    margin: 2rem auto !important;

    /* Text */
    font-weight: 300;
    text-align: center;

    p{
        /* Display & Box Model */
        margin: 0.5rem auto;
    }
`;

EscalePage.Complement = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid !important;
    grid-template-columns: 30% 70%;
    grid-gap: 0.5rem;

`;

EscalePage.CartItemImg = styled.figure `
    /* Display & Box Model */
    width: 100%;

    img{
        /* Display & Box Model */
        width: 100%;
    }
`;

EscalePage.CartText = styled.div `
    /* Display & Box Model */
    width: 100%;
`;

EscalePage.CartItemName = styled.h6 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 1rem;

    /* Color */
    color: ${ colors.black };

    /* Text */
    font-size: 1rem;
    font-weight: 300;
`

EscalePage.CartItemPrice = styled.p `
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Color */
    color: ${ colors.black };

    /* Text */
    font-weight: 700;
`;

EscalePage.CartRemoveItem = styled.p `

    /* Color */
    color: var(--color-red);

    /* Others */
    cursor: pointer;
`;

EscalePage.CartBtn = styled.button `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    border: none;
    margin: 2rem auto;

    /* Color */
    background-color: ${ colors.yellow };

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    /* Position */
    /* position: relative;
    bottom: 0;
    right: 0; */

    /* Others */
    cursor: pointer;
`;

EscalePage.Lightbox = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: none;

    /* Color */
    background-color: ${ colors.black };

    /* Others */
    opacity: 0;
    
    &.open{
        /* Display & Box Model */
        display: block;
        
        /* Others */
        animation: ${ lightboxAnimation } 0.3s ease-out 0.3s forwards;
    }
`;


/*===========================================
            Modal Shopping Cart
===========================================*/

EscalePage.CartModalGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    padding: 1rem;

    @media (min-width: 800px){
        /* Display & Box Model */
        grid-template-columns: 40% 60%;
    }
`;

EscalePage.CartModalImg = styled.figure `
    /* Display & Box Model */
    width: 100%;
    height: 18rem;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;

    img{
        width: auto !important;
        height: 100% !important;
    }
`;

EscalePage.CartModalText = styled.div `
    /* Display & Box Model */
    width: 100%;
`;

EscalePage.CartModalName = styled.h3 `
    /* Display & Box Model */
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: solid 0.3rem ${ colors.yellow };

    /* Text */
    font-size: 1.6rem;
    font-weight: 300;
`;

EscalePage.CartModalDescription = styled.textarea `
    /* Display & Box Model */
    width: 100%;
    border: none;
    padding: 1rem 0;

    /* Text */
    font-size: 0.8rem;
    line-height: 1.2;
`;

EscalePage.CartModalButton = styled.button `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 2rem !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Color */
    background-color: ${ colors.yellow };

    /* Text */
    font-size: 1rem;

    /* Others */
    cursor: pointer;

    &.remove{
        /* Display & Box Model */
        border: solid 0.1rem ${ colors.black };

        /* Color */
        background-color: transparent;
    }

    a{
        /* Color */
        color: ${ colors.black };
    }

    @media (min-width: 600px){
        /* Display & Box Model */
        width: auto;
    }
`;

EscalePage.CartModalPrices = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    .item__quantity{
        /* Display & Box Model */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem auto;
    }

    .item__quantity--print{
        /* Display & Box Model */
        display: none;
    }

    input{
        /* Display & Box Model */
        width: 5rem;
        text-align: center;
        margin: 0;
    }

    p, span{
        /* Text */
        text-align: center;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        flex-direction: row;

        .item__quantity{
            /* Display & Box Model */
            display: flex;
            justify-content: left;
            align-items: center;
        }

        p, span{
            /* Text */
            text-align: left;
        }
    }
`;

EscalePage.CartModalPrice = styled.div `
    /* Display & Box Model */
    width: 100%;

    span{
        /* Display & Box Model */
        display: block;
        margin-bottom: 0.5rem;

        /* Text */
        font-size: 1rem;
    }

    p{
        /* Text */
        font-size: 1.6rem;
    }
`;

/*===========================================
            Form to finish order
===========================================*/

EscalePage.FormPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;

    form{
        #cnpj{
            pointer-events:none;
        }
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        display: grid;
        grid-template-columns: 35% 65%;
    }
`;

EscalePage.FormItems = styled.div `
    /* Display & Box Model */
    width: 100%;
    
    @media (min-width: 800px){
        padding-right: 3rem;
    }
`;

EscalePage.FormContent = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;

    form{
        /* Position */
        position: relative;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        padding-left: 3rem;
        margin: 0;
        border-left: solid 0.1rem;
    }
`;

EscalePage.FormButton = styled.button `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    border: none;

    /* Color */
    color: ${ colors.black };
    background-color: ${ colors.yellow };

    /* Text */
    font-size: 1rem;

`;

EscalePage.BackButton = styled.div `
    /* Display & Box Model */
    width: 4rem;

    /* Color */
    color: var(--color-red);

    /* Text */
    font-size: 1.2rem;

    /* Position */
    position: absolute;
    right: 0;

    /* Others */
    cursor: pointer;
`;

EscalePage.SelectBuyer = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3{
        /* Display & Box Model */
        margin: 2rem auto;

        /* Text */
        font-size: 1.2rem;
        font-weight: 300;
    }
`;

/*===========================================
            Escale footer
===========================================*/

EscalePage.Footer = styled.footer `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 0;

    /* Color */
    background-color: ${ colors.black };
    color: ${ colors.white };

    /* Text */
    text-align: center;

    p{
        /* Text */
        font-size: 0.8rem;
    }
`;