import styled from 'styled-components';
import Bg from '../../assets/images/bg-offer.png';


export const ProposalHeader = styled.div `
    width: 100%;
    background-color: var(--color-black);
    padding: 0.5rem 0;

    .header__logo{
        display: flex;
        align-items: center;
    }

    .logo{
        width: 3rem;

        img{
            width: 100%;
        }
    }

    h3{
        color: var(--color-text-in-black);
        font-size: 1.5rem;
        font-weight: 200;
        border-left: solid 0.1rem var(--color-text);
        padding-left: 1rem;
        margin-left: 1rem;
    }

    .header__text{
        color: var(--color-text-in-black);
        text-align: center;
        margin: 1rem 0;
    }

    .pageheader__title--sub{
        margin-top: 4rem;
        color: var(--color-secondary);
    }

    @media (min-width: 800px){
        background-image: url(${Bg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        .header__content{
            display: flex;
            align-items:center;
            justify-content: space-between;
        }

        .header__text{
            text-align: left;
        }
    }

    @media only print {
        -webkit-print-color-adjust: exact !important;

        .pageheader__title--sub{
            text-align: center !important;
        }
    }
`;

export const HeaderText = styled.div `
    label{
        color: var(--color-text-in-primary);
    }

    @media (min-width: 800px){
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 1rem;
    }
`;

export const DeadLine = styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-in-primary);
    margin: 1rem auto 2rem auto;
    font-size: 1rem;

    .deadline__alert{
        width: 2rem;
        height: 2rem;
        background-color: var(--color-black);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        border: solid 0.1rem var(--color-white);
    }

    p{
        font-weight: 600;
        margin-left: 1rem;
        color: var(--color-white);
        text-shadow: 0 0.1rem 0.5rem var(--color-black);
    }

    @media (min-width: 800px){
        margin-top: -0.5rem;
        float: right;
    }
`;


/*=======================================
                Proposal
========================================*/

export const ProposalPage = styled.div `
    width: 100%;

    @media only screen and (min-width: 800px){
        display: grid;
        grid-template-columns: 75% 25%;
    }
`;


/*=======================================
        Proposal section
========================================*/

ProposalPage.Section = styled.div `
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1rem;

    header{
        width: 100%;
        font-size: 1.2rem;
        text-align: left;
        border-bottom: solid 0.1rem var(--color-border);
        padding: 1rem;
        position: absolute;
        left: 0;
        top: 0;
    }

    @media only screen and (min-width: 500px){
        padding: 4rem 1rem 2rem 1rem;
        grid-template-columns: 50% 50%;
    }

    @media only screen and (min-width: 800px){
        padding: 4rem 1rem 2rem 1rem;
        grid-template-columns: 33% 33% 33%;
    }
`;


/*=======================================
            Proposal item
========================================*/

ProposalPage.Item = styled.div `
    width: 100%;
    padding: 1rem;
    border: solid 0.1rem var(--color-border);
    background-color: var(--color-white);
    box-shadow: var(--shadow);
    border: solid 0.1rem var(--color-border);
    position: relative;
    cursor: pointer;

    .card__added{
        width: 100%;
        height: 0px;
        overflow: hidden;
        background-color: var(--color-primary);
        color: var(--color-white);
        text-align: center;
        transition: all 0.3s ease-out;
        position: absolute;
        top: 0;
        left: 0;
    }

    .card__remove{
        text-align: center;
        color: var(--color-secondary);
        display: none;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
        margin: 1rem auto;
        border: none;
        background-color: transparent;
        font-size: 1rem;
        font-weight: 300;
    }

    .item__img{
        width: 100%;
        height: 12rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        
        img{
            height: 100%;
        }
    }

    .item__content{
        grid-area: content;
    }
    
    .item__name{
        h3, h4{
            font-size: 1rem;
            text-align: center;
            margin: 0.5rem auto;
        }

        h4{
            font-size: 0.8rem;
            font-weight: 300;
        }
    }

    .item__description{
        font-size: 0.8rem;
        text-align: left;
        margin: 1rem auto;
        line-height: 25px;

        textarea{
            width: 100%;
            border: none;
            font-size: 0.8rem;
            margin-bottom: 2rem;
            background-color: transparent;
            font-family: inherit;
        }

        .item__price{
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: 700;
            color: var(--color-primary);
        }
    }

    .item__description--without-photo{
        max-height: 10rem;
        overflow: hidden;
    }

    .item__arrows{
        float: right;

        img{
            margin: 0 0.5rem;
        }
    }

    .item__numbers{
        width: 100%;
        margin: 1rem 0;
        padding: 1rem;
        border-top: solid 0.1rem var(--color-border);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        span{
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
            display: block;
        }

        .item__number{
            margin: 1rem auto;
        }

        .item__price{
            font-size: 1rem;
            font-weight: 700;
        }

        .item__quantity{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input{
            width: 5rem;
            text-align: center;
            margin: 0;
        }

        button{
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f8f8f8;
            border: solid 0.1rem var(--color-border);
            cursor: pointer;

            span{
                font-size: 1.6rem;
                margin-bottom: 0;
            }
        }
    }

    &.selected{
        border: solid 0.2rem var(--color-secondary);

        .card__added{
            padding: 0.8rem;
            height: auto;
            font-size: 0.8rem;
        }

        .btn{
            display: none;
        }

        .card__remove{
            display: block;
        }
    }

    @media (min-width: 800px){
        .item__numbers{
            flex-direction: row;
        }
    }

/* ------- Modal photos  */

    .item__modal--photos{
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 1rem;
    }

    .item__slider{
        width: 100%;
        height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        img{
            position: absolute;
            height: 100% !important;
            width: auto;
        }

        .item__button{
            width: 3rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            z-index: 9;
            right: 0;
            transition: all 0.3s ease-out;
            border: none;
            opacity: 0.6;

            img{
                width: 3rem;
                height: auto !important;
            }
        }

        .item__button:nth-child(1){
            left: 0;
            right: auto;
        }

        .item__button:hover{
            opacity: 1;
        }
    }
    @media (min-width: 800px){
        .item__modal--photos{
            grid-template-columns: 50% 50% !important;
        }
    }
    
`;

ProposalPage.FeaturedItem = styled.div `
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--shadow);
    padding: 1rem;
    margin: 2rem 0;
    text-align: center;
    position: relative;

    .card__added{
        width: 100%;
        height: 0px;
        overflow: hidden;
        background-color: var(--color-primary);
        color: var(--color-white);
        text-align: center;
        transition: all 0.3s ease-out;
        position: absolute;
        top: 0;
        left: 0;
    }

    .item__numbers{
        width: 100%;
        margin: 1rem 0;
        padding: 1rem;
        border-top: solid 0.1rem var(--color-border);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        span{
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
            display: block;
        }

        .item__number{
            margin: 1rem auto;
        }

        .item__price{
            font-size: 1rem;
            font-weight: 700;
        }

        .item__quantity{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        input{
            width: 5rem;
            text-align: center;
            margin: 0;
        }

        button{
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f8f8f8;
            border: solid 0.1rem var(--color-border);
            cursor: pointer;

            span{
                font-size: 1.6rem;
                margin-bottom: 0;
            }
        }
    }

    .card__remove{
        text-align: center;
        color: var(--color-secondary);
        display: none;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
        margin: 1rem auto;
        border: none;
        background-color: transparent;
        font-size: 1rem;
        font-weight: 300;
    }

    &.selected{
        border: solid 0.2rem var(--color-secondary);

        .card__added{
            padding: 0.8rem;
            height: auto;
            font-size: 0.8rem;
        }

        .btn{
            display: none;
        }

        .card__remove{
            display: block;
        }
    }

    .item--photos{
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 1rem;
    }

    .item__slider{
        width: 100%;
        height: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        img{
            position: absolute;
            height: 100% !important;
            width: auto;
        }

        .item__button{
            width: 3rem;
            height: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            z-index: 9;
            right: 0;
            transition: all 0.3s ease-out;
            border: none;
            opacity: 0.6;

            img{
                width: 3rem;
                height: auto !important;
            }
        }

        .item__button:nth-child(1){
            left: 0;
            right: auto;
        }

        .item__button:hover{
            opacity: 1;
        }
    }

    .item__description{
        font-size: 0.8rem;
        text-align: left;
        margin: 1rem auto;
        line-height: 25px;

        .item__price{
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: 700;
            color: var(--color-primary);
        }
    }

    .item__description--without-photo{
        max-height: 10rem;
        overflow: hidden;
    }

    @media (min-width: 800px){
        width: 75%;

        .item--photos{
            grid-template-columns: 50% 50% !important;
        }

        .item__numbers{
            flex-direction: row;
        }
    }
`;



export const CategoryName = styled.h3 `
    width: 100%;
    font-size: 1.4rem;
    padding-bottom: 1rem;
    border-bottom: solid 0.1rem var(--color-border);
    margin: 2rem auto;
    display: none;

    &.card__category--show{
        display: block;
    }
`;

export const Card = styled.div `
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0.3rem;
    box-shadow: var(--shadow);
    transition: all 0.3s ease-out;
    margin: 4rem auto;

    &.card--selected{

        .btn{
            display: none;
        }

        .card__remove{
            display: block;
        }

        .card__added{
            padding: 0.8rem;
            height: auto;
            font-size: 0.8rem;
        }
    }

    .card__added{
        width: 100%;
        height: 0px;
        overflow: hidden;
        background-color: var(--color-primary);
        color: var(--color-white);
        text-align: center;
        transition: all 0.3s ease-out;
    }

    .card__remove{
        width: 100%;
        text-align: center;
        color: var(--color-secondary);
        display: none;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
    }

    .card__header{
        background-color: var(--color-black);
        text-align: center;
        position: relative;

        .card__img{
            width: 14rem;
            overflow: hidden;
            background-color: var(--color-white);
            box-shadow: var(--shadow);
            border: solid 0.1rem var(--color-border);
            padding: 0.3rem;
            transition: all 0.3s ease-out;
            text-align: center;
            position: relative;
            top: -1.5rem;
            margin: auto;
            cursor: pointer;

            img{
                background-color: var(--color-gray);
                width: 100%;
            }
        }

        .card__name{
            font-weight: 600;
            color: var(--color-title-in-black);
        }

        .card__label{
            font-size: 0.9rem;
            font-weight: 200;
            margin: 1rem 0;
            color: var(--color-text-in-black);
        }

        .card__supplier{
            font-weight: 200;
            font-size: 1rem;
            margin-top: 0.3rem;
        }
    }

    .card__texts{
        padding: 1.5rem;
        font-size: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card__details{
            margin-bottom: 1rem;
        }
        
        .card__obs{
            padding-top: 1rem;

            span{
                margin-bottom: 0.5rem;
                display: block;
            }
        }
    }

    .card__calc{
        border-left: solid 0.1rem var(--color-border);

        .card__number{
            padding: 1rem;
            /* border-bottom: solid 0.1rem var(--color-border); */
            display: flex;
            justify-content: space-between;

            h3{
                font-size: 1rem;
                font-weight: 200;
            }
        }

        .card__subtotal{
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            background-color: var(--color-primary-complementary);
            color: var(--color-secondary);
            border-top: solid 0.1rem var(--color-border);
        }
    }

    @media (min-width: 800px){
        max-width: 100%;
        height: auto;
        
        .card__content{
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
        }

        &.card--additional{

            .card__content{
                grid-template-columns: 1fr 2fr 1fr;
            }
        }

        .card__name, .card__label, .card__number{
            padding: 0;
        }
    }
`;

export const Calculate = styled.div `
    width: 100%;
    padding: 1.5rem;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 99;
    background-color: var(--color-white);
    box-shadow: 0 -0.5rem 1rem rgba(0,0,0, .25);
    
    .calculate__numbers{
        display: none;
    }
    
    .calculate__numbers--subtotal{
        display: block;
    }
    
    @media (min-width: 800px){
        width: 25%;
        left: auto;
        top: 30%;
        bottom: auto;
        right: 0;

        .calculate__content{
            text-align: left;
        }

        .calculate__numbers{
            text-align: left;
            display: block;
            margin-bottom: 1.5rem;
            
            h3{
                font-size: 1rem;
                padding-bottom: 0.5rem;
                border-bottom: solid 0.1rem var(--color-border);
            }

            h4{
                font-weight: 200;
            }
        }
    }

    @media only print{
        display: none;
    }
`;

export const ProposalFooter = styled.div `
    width: 100%;
    padding: 1rem 0;
    padding-bottom: 4rem;
    margin-top: 5rem;

    .accordion{
        margin: 2rem auto;
        background-color: var(--color-white);
        box-shadow: var(--shadow);
        cursor: pointer;

        &.open{
            .accordion__text{
                height: auto;
                padding: 1rem;
                opacity: 1;
                margin: 0.3rem auto;
            }

            .accordion__title{
                span{
                    img{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .accordion__title{
        font-weight: 200;
        font-size: 1rem;
        display: flex;
        align-items: center;
        background-color: var(--color-black);
        color: var(--color-title-in-black);

        span{
            display: block;
            padding: 1rem 0;
            text-align: center;
            background-color: var(--color-secondary);
            width: 3rem;
            margin-right: 1rem;

            img{
                width: 1rem;
                transition: all 0.3s ease-out;
            }
        }
    }

    .accordion__text{
        height: 0rem;
        overflow: hidden;
        font-weight: 200;
        line-height: 2;
        transition: all .3s ease-out;
        opacity: 0;

        a{
            text-decoration: underline;
        }
    }

    @media only print {
        display: none;
    }
`;

export const SliderController = styled.ul `
    display: flex;
    justify-content: center;
    margin: 1rem auto;

    div{
        margin: auto 1rem;
        cursor: pointer;
    }

    img{
        width: 5rem;
    }
`;

export const FooterSpace = styled.div `
    width:100%;
    height: 5rem;
    background-color: var(--color-black);
`;