import styled from 'styled-components';
import authBg from '../../../assets/images/backgrounds/auth-bg.png';

export const Register = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-purple-dark);
  background-image: url(${authBg});
  background-position: bottom left;
  background-repeat: no-repeat;
`;

Register.Header = styled.header`
  /* Display & Box Model */
  background-color: var(--color-white);
  width: 100%;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  figure {
    /* Display & Box Model */
    width: 120px;

    img {
      /* Display & Box Model */
      width: 100%;
      height: 100%;
    }
  }

  .header-btns {
    /* Display & Box Model */
    display: flex;
    gap: 1rem;

    button {
      /* Display & Box Model */
      padding: 0.8rem 1rem;

      /* Text */
      font-size: 0.9rem;
    }
  }

  > div {
    width: 94%;
  }

  @media (min-width: 480px) {
    > div {
      width: 85%;
    }
  }

  @media (min-width: 800px) {
    figure {
      /* Display & Box Model */
      width: 150px;
    }

    .header-btns {
      button {
        /* Display & Box Model */
        padding: 1rem 2rem;

        /* Text */
        font-size: 1rem;
      }
    }
  }
`;

Register.Container = styled.div`
  /* Display & Box Model */
  padding: 3rem 0;

  > div {
    width: 94%;
  }

  @media (min-width: 480px) {
    > div {
      width: 85%;
    }
  }
`;

Register.Heading = styled.div`
  /* Colors */
  color: var(--color-white);
  h1 {
    /* Text */
    font-size: 3rem;
    font-family: 'Estate', 'Manrope', sans-serif;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 2rem;
    margin-top: 1rem;

    span {
      font-size: inherit;
      font-weight: 700;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 300;
    max-width: 500px;
  }

  @media (min-width: 960px) {
    h1 {
      margin-top: 6rem;
    }
  }

  @media (min-width: 1150px) {
    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;

Register.Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

Register.Form = styled.form`
  background-color: var(--color-white);
  padding: 1.5rem 1.2rem;
  border-radius: 8px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  button,
  p {
    width: 100%;
  }

  p {
    text-align: center;
  }

  input.form-error {
    border-color: var(--color-red);
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    span {
      line-height: 1.5;
    }

    a {
      color: var(--color-primary-light);
    }

    input {
      margin: 0;
    }
  }

  .field-error-message {
    font-size: 0.8rem;
    text-align: left;
    color: var(--color-red);
  }

  @media (min-width: 480px) {
    padding: 2.5rem;
  }

  @media (min-width: 620px) {
    margin-top: 4rem;
  }
`;
