import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../Components/common/Buttons/ButtonLink';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import { StyledTerms, TermsPage } from './styles';

export default function BuyerTerms() {
  const section1ref = React.useRef(null);
  const section2ref = React.useRef(null);
  const section3ref = React.useRef(null);
  const section4ref = React.useRef(null);
  const section5ref = React.useRef(null);
  const section6ref = React.useRef(null);
  const section7ref = React.useRef(null);
  const section8ref = React.useRef(null);
  const section9ref = React.useRef(null);

  const scrollEffect = targetRef => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <TermsPage>
      <Header bgBlack={true} />
      <TermsPage.Nav>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          1 - CONTROLADORA
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section2ref)}>
          2 - INFORMAÇÕES FORNECIDAS PELO USUÁRIO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section3ref)}>
          3 - DURANTE O USO DO SITE
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          4 - USO DAS INFORMAÇÕES COLETADAS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section5ref)}>
          5 - COMPARTILHAMENTO DE DADOS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section6ref)}>
          6 - SEGURANÇA DA INFORMAÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section7ref)}>
          7 - DIREITOS DOS USUÁRIOS E TRANSFERÊNCIA DE INFORMAÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section8ref)}>
          8 - PERÍODO DE RETENÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section9ref)}>
          9 - DISPOSIÇÕES GERAIS
        </ButtonLink>
      </TermsPage.Nav>
      <StyledTerms>
        <h1>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</h1>

        <section>
          <p>
            A Política de Privacidade de Dados Pessoais ("Política de Privacidade”) da{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> foi criada de modo que venha a
            demonstrar o compromisso da empresa com a segurança e a privacidade das informações
            coletadas dos usuários ("Usuários") de seus serviços. As informações coletadas nos
            auxiliam a sempre melhorar o site e enriquecer a experiência de navegação dos Usuários.
          </p>
          <p>
            Nossa Política de Privacidade tem como objetivo explicar aos usuários como seus dados e
            informações são tratados, armazenados e utilizados em nosso site. As informações dos
            Usuários não serão fornecidas, publicadas ou comercializadas em quaisquer
            circunstâncias, exceto conforme previsto nesta Política de Privacidade.
          </p>
          <p>
            Os <Link to="/termos/plataforma">Termos de Uso da Plataforma</Link>, os{' '}
            <Link to="/termos/fornecedor">
              Termos de Uso e Condições Gerais de Contratação de Fornecedores
            </Link>{' '}
            e os <Link to="/termos/comprador">Termos de Uso e Condições Gerais de Compra</Link>{' '}
            integram a presente política, sendo parte inseparável.
          </p>
          <p>
            Sabemos que alguns termos utilizados nesta política podem ser de difícil compreensão,
            sendo assim, elaboramos um glossário para facilitar sua leitura:
          </p>
          <ul>
            <li style={{ listStyle: 'disc' }}>
              <b>Dado pessoal:</b> Informação relacionada a pessoa natural identificada ou
              identificável.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Dado pessoal sensível:</b> Dado pessoal sobre origem racial ou étnica, convicção
              religiosa, opinião política, filiação a sindicato ou a organização de caráter
              religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado
              genético ou biométrico, quando vinculado a uma pessoa natural.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Controlador:</b> Pessoa natural física ou jurídica, de direito público ou privado,
              a quem competem as decisões referentes ao tratamento de dados pessoais.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Operador:</b> Pessoa natural ou jurídica, de direito público ou privado, que
              realiza o tratamento de dados pessoais em nome do controlador.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Tratamento de dados:</b> Toda operação realizada com dados pessoais, como as que se
              referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
              transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
              avaliação ou controle da informação, modificação, comunicação, transferência, difusão
              ou extração.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Uso compartilhado de dados:</b> Comunicação, difusão, transferência internacional,
              interconexão de dados pessoais ou tratamento compartilhado de bancos de dados pessoais
              por órgãos e entidades públicos no cumprimento de suas competências legais, ou entre
              esses e entes privados, reciprocamente, com autorização específica, para uma ou mais
              modalidades de tratamento permitidas por esses entes públicos, ou entre entes
              privados.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>
                <i>Cookies</i>:
              </b>{' '}
              Pequenos arquivos de texto que ficam gravados no computador do internauta e podem ser
              recuperados pelo site que o enviou durante a navegação. São utilizados principalmente
              para identificar e armazenar informações sobre os visitantes.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>Criptografia:</b> Conjunto de princípios e técnicas para cifrar a escrita, torná-la
              ininteligível para os que não tenham acesso às convenções combinadas.
            </li>
            <li style={{ listStyle: 'disc' }}>
              <b>
                IP (ou <i>Internet Protocol</i>):
              </b>{' '}
              Identificação única para cada computador conectado a uma rede.
            </li>
          </ul>
        </section>

        <section ref={section1ref}>
          <h2>1. CONTROLADORA</h2>

          <p>
            <b>1.1.</b> Essa política é utilizada pela{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b>, pessoa jurídica de direito privado
            devidamente inscrita no CNPJ sob nº 28.282.186/0001-38, com endereço na Avenida
            Interlagos, 800, bloco C2 apto 233, Jardim Marajoara, São Paulo/SP, CEP nº 04.660-000,
            adiante denominada como <b>CELEBRAR</b>.
          </p>
          <p>
            <b>1.2.</b> A Celebrar é uma comunidade de micro e pequenas empresas que oferecem os
            mais diferentes serviços para a realização de eventos e experiências corporativas.
          </p>
          <p>
            <b>1.3.</b> Os dados fornecidos pelo Usuário facilitará a comunicação com a CELEBRAR,
            bem como oferecer produtos e serviços dentro da plataforma.
          </p>
          <p>
            <b>1.4.</b> O uso da plataforma implica aceite tácito a esta política de privacidade,
            bem como ao tratamento dos dados necessários e dispostos neste documento, com base no
            art. 7º, V, da Lei 13.709/2018.
          </p>
        </section>

        <section ref={section2ref}>
          <h2>2. INFORMAÇÕES FORNECIDAS PELO USUÁRIO</h2>

          <p>
            <b>2.1. Informações solicitadas.</b> Para otimizar a sua experiência de Usuário do site
            da <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b>, podemos solicitar que você forneça
            determinadas informações pessoais. Ou seja, informações que possam ser usadas para
            identificá-lo pessoalmente ou contatá-lo.
          </p>
          <p>
            <b>
              2.2. Dados pessoais fornecidos expressamente pelo usuário contratante durante o uso do
              site.
            </b>{' '}
            Podemos coletar dados de clientes de diferentes maneiras.
          </p>
          <p>
            <b>2.2.1. Momento da coleta dos dados.</b> A coleta dos dados ocorre nos seguintes
            momentos:
          </p>
          <ul>
            <li>
              <b>I.</b> Formulário cadastral disponibilizado no Site;
            </li>
            <li>
              <b>II.</b> E-mail xx@xx.
            </li>
          </ul>
          <p>
            <b>2.3. Dados pessoais coletados:</b>
          </p>
          <ul>
            <li>
              <b>I.</b> Nome completo;
            </li>
            <li>
              <b>II.</b> RG;
            </li>
            <li>
              <b>III.</b> CPF;
            </li>
            <li>
              <b>IV.</b> Endereço;
            </li>
            <li>
              <b>V.</b> E-mail;
            </li>
            <li>
              <b>VI.</b> Telefone;
            </li>
          </ul>
          <p>
            <b>2.3.1.</b> Além dos dados pessoais coletados, a <b>CELEBRAR</b> coleta dados
            referente a pessoa jurídica, tais quais a razão social, o número de CNPJ e o tipo de
            empresa.
          </p>
        </section>

        <section ref={section3ref}>
          <h2>3. DURANTE O USO DO SITE</h2>

          <p>
            <b>3.1.</b> Para fins administrativos, eventualmente a CELEBRAR poderá utilizar
            "cookies", sendo que o usuário pode, a qualquer instante, ativar em seu navegador
            mecanismos para informá-lo quando estiverem acionados ou até mesmo desativá-los.
          </p>
          <p>
            <b>3.1.1.</b> Os chamados “cookies” são diferenciados da seguinte forma:
          </p>
          <ul>
            <li>
              <b>a) Cookies Necessários:</b> São aqueles importantes para o funcionamento do Site
              sem eles, as funcionalidades ficariam afetadas e impossibilitariam o uso do Site.
              Estes cookies podem coletar as seguintes informações: configurações de preferências do
              sistema, histórico de ações, e número de IP.
            </li>
            <li>
              <b>b) Cookies de Terceiros:</b> São os cookies utilizados para melhorar o desempenho
              do Site e suas estratégias de marketing, com ajuda de empresas parceiras, mas
              independentes, como: Google Analytics e Google Ads. Estes cookies podem coletar as
              seguintes informações: tempo de visita, páginas acessadas, downloads efetuados,
              formulários preenchidos, e número de IP.
            </li>
            <li>
              <b>c) Cookies de Preferências:</b> Esses cookies têm o objetivo de lembrar as
              preferências do Usuário no Site mesmo depois do Usuário fechar o seu navegador ou
              efetuar uma reinicialização, através da coleta de informações como: senha de acesso
              (caso o Usuário tenha optado por essa função), palavras pesquisadas, páginas visitadas
              e número de IP.
            </li>
          </ul>
          <p>
            <b>3.2. Rejeição ou exclusão de Cookies:</b> Você poderá fazer o gerenciamento dos
            cookies através de seu navegador, que muitas vezes pode já ter autorizações padrões.
          </p>
          <p>
            <b>3.3.</b> Este Site poderá conter links ou frames de outros sites, que podem ou não
            ser parceiros da Empresa. Esses links e frames são disponibilizados com o objetivo de
            proporcionar mais um benefício para os Usuários. A inclusão destes links e frames não
            significa, no entanto, que a nossa empresa tenha conhecimento, concorde, ou seja,
            responsável por eles ou por seus respectivos conteúdos. Ou seja, não nos
            responsabilizamos por eventuais perdas ou danos sofridos em razão da utilização dos
            referidos links ou frames, sendo que os responsáveis dos outros sites são os gestores de
            seu conteúdo. Sempre que outras empresas/parceiros forem contratadas para executar
            serviços de apoio ao nosso site, será exigida a adequação aos padrões de privacidade da{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b>.
          </p>
        </section>

        <section ref={section4ref}>
          <h2>4. USO DAS INFORMAÇÕES COLETADAS</h2>

          <p>
            <b>4.1. Destinação dos dados coletados.</b> Usamos as informações coletadas sobre os
            usuários para efetivar nossas comunicações, avisos e alterações de regras e políticas,
            bem como para prestar o serviço por meio da plataforma <b>CELEBRAR</b>.
          </p>
          <p>
            <b>4.2.</b> Para maior clareza quanto à utilização dos dados, procuramos delinear a
            finalidade da coleta destas informações da forma mais transparente possível:
          </p>
          <table>
            <thead>
              <tr>
                <th>
                  <b>DADO</b>
                </th>
                <th>
                  <b>BASE LEGAL</b>
                </th>
                <th>
                  <b>FINALIDADE</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <b>Nome</b>
                </td>
                <td>
                  Necessário para a execução de contrato ou de procedimentos preliminares
                  relacionados a contrato do qual seja parte o titular, a pedido do titular dos
                  dados (Art. 7º, V, Lei nº 13.709/2018).
                </td>
                <td>
                  <b>
                    Identificação do Usuário. Trata-se de dado pessoal essencial para que seja
                    possível que os respectivos controladores possam entrar em contato com os
                    usuários.
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Contato telefônico e de e-mail</b>
                </td>
                <td>
                  Necessário para a execução de contrato ou de procedimentos preliminares
                  relacionados a contrato do qual seja parte o titular, a pedido do titular dos
                  dados (Art. 7º, V, Lei nº 13.709/2018). Necessário para atender aos interesses
                  legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e
                  liberdades fundamentais do titular que exijam a proteção dos dados pessoais (Art.
                  7º, IX, Lei nº 13.709/2018).
                </td>
                <td>
                  <b>
                    Utilizado como meio de comunicação com os usuários pelos respectivos
                    controladores, para contatos e interações ao longo da jornada de uso da
                    plataforma.
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>RG, CPF e endereço</b>
                </td>
                <td>
                  Necessário para a execução de contrato ou de procedimentos preliminares
                  relacionados a contrato do qual seja parte o titular, a pedido do titular dos
                  dados (Art. 7º, V, Lei nº 13.709/2018).
                </td>
                <td>
                  <b>
                    Identificação do Usuário. Trata-se de um dado pessoal necessário para a
                    finalidade de contratação.
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <b>4.3.</b> Além disso, os dados pessoais informados poderão ser utilizados:
          </p>
          <ul>
            <li>
              <b>(i)</b> Atender às finalidades comerciais e aprimorar a oferta de Serviços com os
              seguintes objetivos: gerenciamento de eventos; gestão do relacionamento com usuários;
              envio de comunicados e informações, inclusive para fins de marketing; e gerenciamento
              de feedback dos usuários;
            </li>
            <li>
              <b>(ii)</b> Ajudar na melhoria do desenvolvimento da plataforma para os seguintes
              objetivos: medição de tráfego e monitoramento de fluxo de clique;
            </li>
            <li>
              <b>(iii)</b> Outras hipóteses guiadas a partir da livre vontade da{' '}
              <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b>, desde que demonstrada sua finalidade
              no momento de coleta.
            </li>
          </ul>
          <p>
            <b>4.4. Alteração da finalidade do uso dos dados coletados.</b> Na hipótese da{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> alterar as finalidades previstas na
            presente Política de Privacidade, em inobservância ao consentimento originalmente
            fornecido, será informado ao Fornecedor dos dados, com destaque de forma específica o
            teor das alterações, ficando, assim, facultada a revogação do consentimento.
          </p>
        </section>

        <section ref={section5ref}>
          <h2>5. COMPARTILHAMENTO DE DADOS</h2>

          <p>
            <b>5.1. Compartilhamento de dados.</b> Exceto conforme disposto nesta Política de
            Privacidade, não compartilharemos suas informações pessoais com terceiros sem o seu
            consentimento específico. Podemos compartilhar informações as quais não são pessoais,
            como estatísticas agregadas de usuários, informações demográficas e informações de uso,
            com terceiros.
          </p>
          <p>
            <b>5.2. Hipóteses de compartilhamento.</b> Além disso, podemos compartilhar suas
            informações nas seguintes circunstâncias:
          </p>
          <p>
            <b>5.2.1 Terceiros que prestam serviços em nosso nome.</b> Fornecedores de serviço: para
            que possamos disponibilizar nossos produtos e prestar os nossos serviços com qualidade,
            contamos com a colaboração de diversos prestadores de serviço, que tratam os dados
            pessoais coletados em nosso nome e de acordo com nossas instruções.{' '}
          </p>
          <p>
            <b>5.2.2. Transferências Empresariais.</b> Podemos compartilhar suas informações
            pessoais com outras entidades e afiliadas controladas pela{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> para fins comerciais e operacionais. À
            medida que continuamos a desenvolver nossos negócios, podemos vender ou comprar ativos.
            Se outra empresa comprar nossa empresa, negócio ou nossos ativos, ela também poderá
            adquirir as informações pessoais (e Informações de Uso) coletadas e armazenadas por nós,
            de forma que assumirá os direitos e obrigações referentes às suas informações pessoais,
            conforme descrito nesta Política de Privacidade.
          </p>
          <p>
            <b>5.2.3. Divulgação Legal.</b> Exceto nos casos em que a{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> receba determinação legal ou judicial,
            suas informações pessoais não serão transferidas a terceiros ou usadas para finalidades
            diferentes daquelas para as quais foram coletadas.
          </p>
        </section>

        <section ref={section6ref}>
          <h2>6. SEGURANÇA DA INFORMAÇÃO</h2>

          <p>
            <b>6.1. Confidencialidade.</b> Seguimos padrões rígidos de segurança e confidencialidade
            a fim de proteger as informações pessoais dos nossos usuários. Todas as informações
            pessoais fornecidas à <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> serão coletadas
            por meios éticos e legais, de modo que os usuários serão informados sobre a finalidade
            da coleta de informação e por isso, caberá a ele optar por fornecer ou não a informação.
            A Empresa manterá íntegras as Informações Pessoais fornecidas pelos Usuários, por meio
            de políticas internas de anonimização, restrição de acesso ao banco de dados e acordo de
            confidencialidade.
          </p>
          <p>
            <b>6.2. Previsão legal.</b> Você é titular de todos os direitos previstos no artigo 18,
            da Lei 13.709 de 2018. Ou seja, isso significa que, a qualquer momento, você pode nos
            solicitar e exercer qualquer direito previsto na Lei Geral de Proteção de Dados,
            conforme exposto adiante nesta política. Estes direitos poderão ser exercidos perante os
            organismos de defesa do consumidor e também perante a Agência Nacional de Proteção de
            Dados, nos termos legais.
          </p>
          <p>
            <b>6.3. Medida de segurança.</b> Medidas padrão da indústria (por exemplo, firewalls e
            ferramentas de criptografia) para proteger contra a perda, uso indevido e alteração não
            autorizada de informações pessoais em nossa posse e controle.
          </p>
          <p>
            <b>6.4. Cancelamento de cadastro.</b> Caso o usuário queira cancelar seu cadastro, basta
            acessar a Plataforma no campo “excluir conta” no menu “minha conta”. O Usuário também
            poderá entrar em contato conosco pelo botão flutuante “Entre em contato” no rodapé das
            páginas da área pessoal para fazer sugestões ou tirar dúvidas.
          </p>
          <p>
            <b>6.5. Responsabilidade.</b> Esteja ciente de que, embora nos empenhamos em fornecer
            segurança para informações em nossa posse e controle, nenhum sistema de segurança pode
            prevenir contra possíveis brechas de segurança, e não nos responsabilizamos por usos ou
            divulgações de informações pessoais ou não-pessoais (anonimizadas) que surjam em conexão
            com o seu roubo.
          </p>
          <p>
            <b>6.6. Acesso às informações.</b> O acesso às Informações pessoais coletadas está
            restrito apenas aos nossos colaboradores autorizados ou a contratados que nos ajudem a
            operar o Site ou processar os pedidos feitos por meio da plataforma. Os colaboradores e
            contratados que se utilizarem indevidamente dessas informações, ferindo essa Política de
            Privacidade, estarão sujeitos às penalidades administrativas, bem como às penalidades
            previstas na legislação cível e penal.
          </p>
        </section>

        <section ref={section7ref}>
          <h2>7. DIREITOS DOS USUÁRIOS E TRANSFERÊNCIA DE INFORMAÇÃO</h2>

          <p>
            <b>7.1 Consentimento dos usuários.</b> O Fornecedor das Informações declara-se ciente e
            concorda de forma expressa, livre informada e inequívoca que a{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b>, anteriormente qualificada, em
            decorrência do presente Termo, poderá realizar o tratamento dos seus dados pessoais,
            inclusive dos dados pessoais sensíveis, bem como poderá ter acesso, utilizar, manter,
            recepcionar, armazenar, classificar, avaliar, transmitir, reproduzir, armazenar,
            eliminar, distribuir e processar, eletrônica e/ou manualmente, informações e dados
            prestados por este, razão pela qual, desde já, a empresa se compromete a tratar os dados
            pessoais envolvidos na confecção e necessários à execução da presente Política de
            Privacidade, única e exclusivamente para cumprir com a finalidade a que se destinam, bem
            como, em respeito a toda a legislação aplicável sobre a segurança da informação,
            privacidade e proteção de dados, inclusive, mas não se limitando à Lei nº 13.709/2018
            (Lei Geral de Proteção de Dados), sob pena de responsabilização da parte infratora por
            perdas e danos.
          </p>
          <p>
            <b>7.2.</b> Além das informações disponibilizadas nesta Política de Privacidade, o
            usuário pode também exercer os direitos previstos na Lei Geral de Proteção de Dados,
            entre eles:
          </p>
          <ul>
            <li>
              <b>(i)</b> Confirmação da existência de tratamento de dados pessoais;
            </li>
            <li>
              <b>(ii)</b> Acesso aos dados pessoais;
            </li>
            <li>
              <b>(iii)</b> Revogação do consentimento;
            </li>
            <li>
              <b>(iv)</b> Correção de dados pessoais incompletos, inexatos ou desatualizados;
            </li>
            <li>
              <b>(v)</b> Eliminação dos dados pessoais tratados com o consentimento ou
              desnecessários, excessivos ou quando entender que algum ponto da LGPD não foi
              atendido;
            </li>
            <li>
              <b>(vi)</b> Informação sobre as hipóteses e com quais empresas, parceiros e outras
              instituições podemos compartilhar, ou receber dados pessoais referentes a você;
            </li>
            <li>
              <b>(vii)</b> Informação sobre a possibilidade de não fornecer consentimento e sobre as
              consequências da negativa, quando aplicável;
            </li>
            <li>
              <b>(viii)</b> Todas as solicitações serão tratadas de forma gratuita, e serão
              submetidas a uma prévia avaliação da sua identidade e da viabilidade do atendimento, a
              fim de cumprir com eventuais obrigações que impeçam o completo atendimento das
              requisições dos titulares de direito.
            </li>
          </ul>
          <p>
            <b>7.3. Transferência dos dados pessoais dos usuários.</b> Alguns de seus dados pessoais
            poderão ser transferidos para outros países, por exemplo, quando utilizarmos serviços
            computacionais em nuvem para processamento ou armazenamento de dados, localizados fora
            do Brasil. Cabe salientar que são observados todos os requisitos estabelecidos na
            legislação vigente, adotando as melhores práticas de mercado a fim de garantir a
            proteção e privacidade dos seus dados pessoais.
          </p>
        </section>

        <section ref={section8ref}>
          <h2>8. PERÍODO DE RETENÇÃO</h2>

          <p>
            <b>8.1. Prazo de retenção dos dados.</b> A{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIACOES LTDA</b> estabeleceu períodos de retenção dos
            seus Dados com base nos objetivos do processamento e considerando as obrigações legais.
            O prazo que retemos os seus dados pessoais é por até <b>5 (cinco) anos</b>.
          </p>
        </section>

        <section ref={section9ref}>
          <h2>9. DISPOSIÇÕES GERAIS</h2>

          <p>
            <b>9.1. Aplicabilidade.</b> Se, por qualquer motivo, um tribunal competente considerar
            que qualquer disposição desta Política de Privacidade (ou qualquer parte da mesma) é
            inexequível, essa disposição (ou qualquer parte da mesma) será aplicada na medida do
            máximo permitido, de forma a refletir a intenção dos Termos da empresa, e as disposições
            restantes dos Termos manter-se-ão em pleno vigor e efeito.
          </p>
          <p>
            <b>9.2. Tolerância.</b> Qualquer tolerância a um descumprimento do presente será
            considerada mera liberalidade, não sendo considerada novação tampouco passando a
            integrar os presentes Termos.
          </p>
          <p>
            <b>9.3. Foro.</b> Fica eleito o Foro de São Paulo, Estado de São Paulo, para dirimir
            eventuais conflitos advindos do presente instrumento, renunciando a qualquer outro, por
            mais privilegiado que seja.
          </p>
        </section>
        <section>
          <p>
            Expostos os termos e condições da política de privacidade, o usuário deverá concordar
            com as disposições para efetivo uso do serviço oferecido, sendo que o uso sem a
            concordância expressa e por escrito será considerado como concordância tácita.
          </p>

          <p>São Paulo/SP, 13 de julho de 2022</p>
        </section>

        <h1>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA.</h1>
      </StyledTerms>

      <Footer />
    </TermsPage>
  );
}
