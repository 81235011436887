import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { useAsync } from '../../../utils/useAsync';
import Modal from '../../../Components/Modal';
import Alert from '../../../Components/Alert';
import { formatDate, formatDateAndTime } from '../../../utils/dateFunctions';
import FeedbackChannel from '../../Supplier/FeedbackChannel';
import BuyerMenu from '../BuyerMenu';
// Styles & Images
import { Buyer } from '../styles';
import { FormCheckbox } from '../../../Components/common/FormCheckbox';

function BuyerHome() {
  const modalRef = useRef();
  const alertRef = useRef();

  const submittedDemandModalRef = useRef();
  const [selectedSubmittedDemand, setSelectedSubmittedDemand] = useState(null);
  const [submittedDemands, setSubmittedDemands] = useState([]);
  const { user } = useAuthContext();
  const [isLoadingDemands, setIsLoadingDemands] = useState(true);
  const { run, isLoading: isSubmitting, isError } = useAsync();

  useEffect(() => {
    axios({
      method: 'get',
      url: `/api/comprador/pedidos`,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(res => {
        setSubmittedDemands(res.data);
        setIsLoadingDemands(false);
      })
      .catch(err => console.log(err));
  }, []);

  /*==============================
            New proposal form
    ===============================*/

  function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);

    run(
      axios({
        method: 'post',
        url: '/api/comprador/pedidos/novo',
        data: {
          name: data.get('contact_name'),
          email: data.get('contact_email'),
          phone: data.get('contact_phone'),
          client_description: data.get('message'),
        },
      }).then(res => {
        if (res.status === 201) {
          setSubmittedDemands(submittedDemands => [res.data, ...submittedDemands]);
          modalRef.current.close();
          alertRef.current.open();
        }
      })
    );
  }

  return (
    <Buyer>
      <FeedbackChannel />
      <Buyer.Section>
        <BuyerMenu />

        <Modal title="Solicite seu orçamento" ref={modalRef}>
          <form onSubmit={handleSubmit}>
            <h3 className="modal__title"> Solicite seu orçamento </h3>
            <div className="input__field">
              <label htmlFor="contact_name"> Seu nome </label>
              <input
                className="input"
                id="contact_name"
                name="contact_name"
                type="text"
                min="1"
                defaultValue={user.name && user.name}
                required
              />
            </div>

            <div className="input__field">
              <label htmlFor="contact_email"> Seu e-mail </label>
              <input
                className="input"
                id="contact_email"
                name="contact_email"
                type="text"
                min="1"
                defaultValue={user.email}
                required
              />
            </div>

            <div className="input__field">
              <label htmlFor=""> Seu telefone </label>
              <input
                className="input"
                name="contact_phone"
                type="text"
                min="1"
                defaultValue={user.phone && user.phone}
              />
            </div>

            <div className="input__field">
              <label htmlFor="">
                {' '}
                Explique aqui o que você precisa cotar, quais os seus prazos e necessidades{' '}
              </label>
              <textarea
                className="input textarea"
                name="message"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div style={{ margin: '1.5rem 0 3rem' }}>
              <FormCheckbox name="terms" id="terms" required>
                Declaro que li e aceito os{' '}
                <a href="/termos/plataforma" target="_blank">
                  Termos de uso da plataforma
                </a>
                , a{' '}
                <a href="/termos/politica-privacidade" target="_blank">
                  Política de Privacidade
                </a>{' '}
                e os{' '}
                <a href="/termos/comprador" target="_blank">
                  Termos de Uso e Condições Gerais de Compra
                </a>{' '}
                da Celebrar
              </FormCheckbox>
            </div>

            <button className="btn btn--fullwidth" type="submit" disabled={isSubmitting}>
              <span>
                {isSubmitting
                  ? 'Enviando pedido...'
                  : 'Solicitar orçamentos para a comunidade de fornecedores Celebrar'}
              </span>
            </button>
            <p>
              {isError ? 'Não foi possível enviar o seu pedido. Tente novamente mais tarde.' : null}
            </p>
          </form>
        </Modal>

        <Alert ref={alertRef}>
          <p>
            {' '}
            Agradecemos a sua mensagem! Em breve, seu especialista em eventos Celebrar entrará em
            contato com você para apresentar os orçamentos da Comunidade.{' '}
          </p>
        </Alert>
      </Buyer.Section>

      <Modal title="Detalhes do pedido" ref={submittedDemandModalRef}>
        <Buyer.DemandDetails>
          <div className="alerts__fieldset" style={{ marginTop: 0 }}>
            <span className="fieldset__title">Detalhes do pedido</span>
            <span className="fieldset__description">
              Enviado em {formatDateAndTime(selectedSubmittedDemand?.createdAt)}
            </span>

            <ul>
              <li>
                <span className="txt--black">Nome:</span> {selectedSubmittedDemand?.name}
              </li>
              <li>
                <span className="txt--black">Email: </span>
                {selectedSubmittedDemand?.email}
              </li>
              <li>
                <span className="txt--black">Telefone:</span> {selectedSubmittedDemand?.phone}
              </li>
              <li>
                <span className="txt--black">Descrição do pedido:</span> <br />
                {selectedSubmittedDemand?.client_description ? (
                  <span className="demand-description">
                    "{selectedSubmittedDemand?.client_description}"
                  </span>
                ) : null}
              </li>
            </ul>
          </div>

          <header>
            Agradecemos a sua mensagem! <br />
            <br /> Em breve, seu especialista em eventos Celebrar entrará em contato para apresentar
            os orçamentos da comunidade.
          </header>
        </Buyer.DemandDetails>
      </Modal>

      <Buyer.Section className=" margin--top-2 ">
        <div className="container">
          <Buyer.Title>Meus pedidos</Buyer.Title>

          <button
            className="btn btn--secondary btn--small"
            id="small__button"
            onClick={() => modalRef.current.open()}
          >
            <span> Fazer um novo pedido </span>
          </button>

          {submittedDemands.length > 0 ? (
            <Buyer.DemandsTable>
              <thead>
                <tr>
                  <th>Responsável</th>
                  <th>Data do pedido</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                {submittedDemands && submittedDemands.length > 0
                  ? submittedDemands.map(demand => {
                      return (
                        <tr
                          key={demand.id}
                          onClick={() => {
                            setSelectedSubmittedDemand(demand);
                            submittedDemandModalRef.current.open();
                          }}
                        >
                          <td>{demand.name}</td>
                          <td>{formatDate(demand.createdAt)}</td>
                          <td> {demand.client_description} </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Buyer.DemandsTable>
          ) : (
            <p>
              {isLoadingDemands
                ? 'Carregando pedidos enviados...'
                : 'Você ainda não enviou nenhum pedido.'}
            </p>
          )}
        </div>

        <button className="btn btn--center" onClick={() => modalRef.current.open()}>
          <span> Fazer um novo pedido </span>
        </button>
      </Buyer.Section>

      <Buyer.Section style={{ display: 'none' }}>
        <div className="container">
          <Buyer.Title className="margin--tb-2">Lembre-se de avaliar seus pedidos</Buyer.Title>
          <Buyer.Reviews>
            <Buyer.ReviewsCard>
              <p> Nome da proposta </p>
              <span>Data do evento: 00/00/0000</span>

              <div>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
              </div>
            </Buyer.ReviewsCard>

            <Buyer.ReviewsCard>
              <p> Nome da proposta </p>
              <span>Data do evento: 00/00/0000</span>

              <div>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
              </div>
            </Buyer.ReviewsCard>

            <Buyer.ReviewsCard>
              <p> Nome da proposta </p>
              <span>Data do evento: 00/00/0000</span>

              <div>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
                <svg
                  width="48"
                  height="46"
                  viewBox="0 0 48 46"
                  fill="none"
                  stroke="black"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
                </svg>
              </div>
            </Buyer.ReviewsCard>
          </Buyer.Reviews>
        </div>
      </Buyer.Section>
    </Buyer>
  );
}

export default BuyerHome;
