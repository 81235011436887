import React, { useRef } from 'react';
import { Button } from '../../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../../Components/common/Buttons/ButtonIconOnly';
import { FormEditableInput } from '../../../Components/common/Forms/FormEditableInput';
import { FormEditableTextarea } from '../../../Components/common/Forms/FormEditableTextarea';
import { Camera, Hiperlink, Money } from '../../../Components/common/Icons/IconsFamily';
import Modal from '../../../Components/Modal';
import { Item } from '../../../Components/Offer/styles';
import { toBraCurrency } from '../../../utils';
import { SubmittedOfferItemDetailsImages } from './SubmittedOfferItemDetailsImages';

export const SubmittedOfferItemDetails = ({ item, index }) => {
  const imagesModalRef = useRef();
  const pricesModalRef = useRef();
  const linksModalRef = useRef();

  const priceMethod = item.price_method;
  const userLinks = item.user_links?.split(',') ?? [];

  return (
    <Item>
      <Item.Content>
        <SubmittedOfferItemDetailsImages photos={item.photos} imagesModalRef={imagesModalRef} />

        <Item.Body>
          <Item.BodyGrid>
            <Item.ItemNameContainer>
              <FormEditableInput
                type="text"
                className="readonly"
                color="var(--color-green)"
                colorText="var(--color-white)"
                style={{ marginTop: 0 }}
                defaultValue={item.name ?? `Item ${index + 1}`}
                readOnly
                disabled
              />
            </Item.ItemNameContainer>

            <FormEditableTextarea
              placeholder="Digite aqui com detalhes a descrição do item"
              disabled
              value={item.description}
              height="18rem"
              style={{ margin: 0 }}
              className="readonly"
            />
          </Item.BodyGrid>
          <Item.Prices onClick={() => pricesModalRef.current.open()}>
            <p>
              <span>Modo de cobrança</span>
              {item.price_method || '---'}
            </p>

            <p>
              <span>Quantidade</span>
              {item.quantity || 0}
            </p>

            <p>
              <span>Valor de venda</span>
              {toBraCurrency(item.sale_price)}
            </p>

            <p>
              <span>Valor total da venda</span>
              {toBraCurrency(item.total_sale_price)}
            </p>
          </Item.Prices>
        </Item.Body>

        <Item.ControlBar style={{ justifyContent: 'center', gap: '1rem' }}>
          <ButtonIconOnly type="button" label="Fotos" onClick={() => imagesModalRef.current.open()}>
            <Camera />
          </ButtonIconOnly>
          <ButtonIconOnly
            type="button"
            label="Precificação"
            onClick={() => pricesModalRef.current.open()}
          >
            <Money />
          </ButtonIconOnly>
          <ButtonIconOnly type="button" label="Links" onClick={() => linksModalRef.current.open()}>
            <Hiperlink />
          </ButtonIconOnly>
        </Item.ControlBar>

        <Modal ref={linksModalRef} title="Gerenciar links do item" modalSize="large">
          {userLinks.length > 0 ? (
            userLinks.map((link, index) => (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Item.Link key={index}>
                  <Hiperlink />
                  {link}
                </Item.Link>
              </a>
            ))
          ) : (
            <p className="margin--tb-2">Você não adicionou nenhum link</p>
          )}
        </Modal>

        <Modal ref={pricesModalRef} title="Precificação do item" modalSize="large">
          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Modo de cobrança</h6>
              <p>O modo de cobrança define como você irá precificar o seu serviço oferecido</p>
            </Item.ModalPrices.Content>
            <FormEditableInput
              type="text"
              readOnly
              className="readonly"
              defaultValue={priceMethod}
              style={{ userSelect: 'none', textAlign: 'right' }}
              disabled
            />
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Quantidade</h6>
            </Item.ModalPrices.Content>
            <FormEditableInput
              className="readonly"
              value={item.quantity}
              disabled
              style={{ textAlign: 'right' }}
            />
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6 className="txt--primary">
                Valor de venda <span>{priceMethod || ''}</span>
              </h6>
              <p>
                Informe o valor de venda{' '}
                <span style={{ textTransform: 'lowercase' }}>{priceMethod || ''}</span>. Lembre-se
                que este valor já inclui a taxa de serviço. Digite somente números
              </p>
            </Item.ModalPrices.Content>
            <FormEditableInput
              className="readonly"
              value={toBraCurrency(item.sale_price)}
              disabled
              readOnly
              style={{ textAlign: 'right' }}
            />
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Subtotal da venda</h6>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h5>{toBraCurrency(item.total_sale_price)}</h5>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Taxa de serviço</h6>
              <p>
                Cobramos 10% de taxa de serviço para te trazer mais clientes. A Celebrar só ganha se
                você ganhar.
              </p>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h5>{toBraCurrency(item.rate_price)}</h5>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Item.ModalPrices>
            <Item.ModalPrices.Content>
              <h6>Valor do repasse </h6>
              <p>
                O valor do repasse será o valor da NF se o orçamento for aprovado, ou seja, este é o
                valor que você receberá em sua conta bancária.
              </p>
            </Item.ModalPrices.Content>
            <Item.ModalPrices.Price>
              <h4>{toBraCurrency(item.total_supplier_sale_price)}</h4>
            </Item.ModalPrices.Price>
          </Item.ModalPrices>

          <Button type="button" onClick={() => pricesModalRef.current.close()}>
            Concluído
          </Button>
        </Modal>
      </Item.Content>
    </Item>
  );
};
