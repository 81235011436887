import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';

export const Input = styled.div `
.formTxtInput {
  ${tw`
      flex
      flex-col
      mt-4
    `}

  div {
    ${tw`
        w-full
        flex
        items-center
        relative
      `}
  }
  label {
    ${tw`
      text-left
    `}
    font-size: 0.8rem;
  }

  input,
  textarea,
  select {
    ${tw`
        p-3
        pl-12
        rounded
        border-none
        text-black
        w-full
      `};
    background-color: white;
    box-shadow: 0px 2px 5px 1px var(--color-gray);
    max-width: 21rem;
    margin: 1rem 0;
    font-size: 0.8rem;

    &::placeholder {
      ${tw`
          p-1
          text-slate-300
        `}
      font-size: 0.8rem;
    }

    &.input--error{
      ${
        tw`
          border-b
          border-red-800
        `
      }
    }
  }

  textarea{
      ${
        tw`
          min-w-full
          h-20
        `
      }
    }

  input:disabled {
    color: black;
  }

  .icon {
    ${tw`
        absolute
      `}
    left: 1rem;

    svg {
      ${tw`
        text-slate-300
      `}
    }
  }

  .error-msg {
    ${tw`text-xs mt-2`}
    color: var(--color-red);
  }
}
`;