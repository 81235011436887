import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Load } from '../Components/Loader/styles';
import { BuyerSignInModal } from '../Components/Modal/BuyerSignInModal';

const AuthContext = createContext({});

function useAuthContext() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loginRedirect, setLoginRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [redirectPath, setRedirectPath] = useState(null);
  const { push, replace } = useHistory();

  const signInModalRef = useRef();
  const [onSignInWithModal, setOnSignInWithModal] = useState(null);

  async function signIn({ email, password, redirectTo }) {
    setRedirectPath(redirectTo);
    try {
      const response = await axios({
        method: 'post',
        url: '/api/auth/signin',
        data: {
          email,
          password,
        },
      });
      setUser(response.data);
      setLoginRedirect(true);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 404) {
          throw new Error(err.response.data.message);
        } else {
          console.log(err);
        }
      } else {
        console.log(err.message);
      }
    }
  }

  function signInWithModal(onSignIn) {
    if (onSignIn && typeof onSignIn !== 'function') {
      console.log('onSignIn argument must be a function');
    }
    if (!user) {
      signInModalRef.current.open();
      if (typeof onSignIn === 'function') {
        setOnSignInWithModal(() => onSignIn);
      } else {
        setOnSignInWithModal(() => setLoginRedirect(true));
      }
    } else {
      if (typeof onSignIn === 'function') {
        onSignIn();
      } else {
        setLoginRedirect(true);
      }
    }
  }

  function finishSignInWithModal(userData) {
    signInModalRef.current.close();
    setUser(userData);
    setTimeout(onSignInWithModal, 200);
  }

  async function signOut(to = '/') {
    const response = await axios({
      method: 'post',
      url: '/api/auth/logout',
    });
    if (response.status === 200) {
      push(to);
      setUser(null);
      window.scroll(0, 0);
      window.localStorage.removeItem('user');
    }
  }

  const updateUserData = useCallback(updatedData => {
    setUser(user => ({ ...user, ...updatedData }));
  }, []);

  useEffect(() => {
    if (!user) {
      axios({
        method: 'get',
        url: '/api/auth/me',
      })
        .then(response => {
          setUser(response.data);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status !== 401) {
              console.log(err.message);
            }
          } else {
            console.log(err.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [push, user]);

  const redirect = useCallback(() => {
    if (redirectPath) {
      setRedirectPath(null);
      replace(redirectPath);
    } else {
      switch (user.type) {
        case 'supplier':
          replace('/fornecedor/painel-de-vendas');
          break;
        case 'buyer':
          replace('/comprador/pedido');
          break;
        default:
          replace('/cadastro-tipo-de-conta');
      }
    }
    setLoginRedirect(false);
  }, [user, redirectPath, replace]);

  useEffect(() => {
    if (user && loginRedirect) {
      redirect();
    }
  }, [user, loginRedirect, redirect]);

  if (isLoading) {
    return (
      <Load>
        <Load.Icon />
      </Load>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUserData,
        signIn,
        signOut,
        setLoginRedirect,
        signInWithModal,
        finishSignInWithModal,
      }}
    >
      {children}
      <BuyerSignInModal ref={signInModalRef} setUser={setUser} />
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuthContext };
