import React, { useCallback, useEffect, useRef, useState } from "react";
import Alert from "../../../Components/Alert";
import { Button } from "../../../Components/common/Buttons/Button";
import FormDateInput from "../../../Components/common/Forms/FormDateInput";
import { FormEditableInput } from "../../../Components/common/Forms/FormEditableInput";
import { FormEditableTextarea } from "../../../Components/common/Forms/FormEditableTextarea";
import { FormSelect } from "../../../Components/common/FormSelect";
import { FormTxtarea } from "../../../Components/common/FormTextarea";
import { FormTxtInput } from "../../../Components/common/FormTextInput";
import { NewDemandPage } from "./styles";

export function DemandServiceForm({ questions, addToEvent, myEvent, buttonText }) {
  const [demandService, setDemandService] = useState(
    questions.reduce((result, question) => {
      const { name, ...rest } = question;
      let defaultValue = question.multiple ? [""] : "";
      if (question.type === "file") {
        defaultValue = [];
      }
      for (let service of myEvent) {
        if (service[name]) {
          defaultValue = service[name].value;
          break;
        }
      }
      result[name] = {
        value: defaultValue,
        ...rest,
      };
      return result;
    }, {})
  );
  const [errors, setErrors] = useState({});

  function handleAddToEvent(demandService) {
    const errors = {};

    Object.entries(demandService).forEach(([name, data]) => {
      if (data.required) {
        if (
          !data.value ||
          (Array.isArray(data.value) && data.value.every((v) => !v))
        ) {
          console.log(errors, name);
          errors[name] = "Campo obrigatório";
        }
      }
    });

    if (Object.entries(errors).length === 0) {
      addToEvent(demandService);
    } else {
      setErrors(errors);
    }
  }

  return (
    <NewDemandPage.Form onSubmit={addToEvent}>
      <div className="row">
        {Object.entries(demandService).map(([name, question]) => {
          if (question.type === "file") {
            return (
              <FormFileField
                label={question.label}
                name={name}
                setDemandService={setDemandService}
                defaultValue={question.value}
                key={name}
              />
            );
          }
          if (question.multiple) {
            return (
              <MultipleFormField
                key={name}
                name={name}
                type={question.type}
                label={question.label}
                setDemandService={setDemandService}
                multipleButtonText={question.multipleButtonText}
                defaultValue={question.value}
                dateLabel={question.dateLabel}
                timeLabel={question.timeLabel}
                error={errors[name]}
              />
            );
          }
          if (question.type === "datetime-local") {
            return (
              <FormDatetimeField
                key={name}
                name={name}
                dateLabel={question.dateLabel}
                timeLabel={question.timeLabel}
                setDemandService={setDemandService}
                defaultValue={question.value}
                error={errors[name]}
              />
            );
          }
          if (question.type === "local") {
            return (
              <FormLocalField
                key={name}
                name={name}
                dateLabel={question.dateLabel}
                timeLabel={question.timeLabel}
                setDemandService={setDemandService}
                defaultValue={question.value}
                error={errors[name]}
              />
            );
          }
          if (question.type === "checkbox") {
            return (
              <FormCheckboxField
                key={name}
                name={name}
                type={question.type}
                label={question.label}
                setDemandService={setDemandService}
                defaultValue={question.value}
              />
            );
          }
          if (question.type === "longtext") {
            return (
              <FormTextAreaField
                key={name}
                name={name}
                label={question.label}
                setDemandService={setDemandService}
                defaultValue={question.value}
              />
            );
          }
          return (
            <FormField
              key={name}
              name={name}
              type={question.type}
              label={question.label}
              setDemandService={setDemandService}
              defaultValue={question.value}
              error={errors[name]}
            />
          );
        })}

        {Object.entries(errors).length > 0 ? (
          <NewDemandPage.FormErrorMessage>
            Preencha todos os campos obrigatórios
          </NewDemandPage.FormErrorMessage>
        ) : null}

        <div className="col s12">
          <Button
            type="button"
            align="center"
            onClick={() => handleAddToEvent(demandService)}
          >
            { buttonText }
          </Button>
        </div>
      </div>
    </NewDemandPage.Form>
  );
}

const FormField = ({
  type,
  name,
  label,
  setDemandService,
  defaultValue,
  error,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value },
      })
    );
  }, [value, setDemandService, name]);

  return (
    <div className="col s12 m6">
      <FormTxtInput
        label={label}
        placeholder={label}
        icon="align-justify"
        type={type}
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        errorMessage={error}
      />
    </div>
  );
};

const MultipleFormField = ({
  name,
  label,
  type,
  setDemandService,
  multipleButtonText,
  defaultValue,
  dateLabel,
  timeLabel,
  error,
}) => {
  const [fields, setFields] = useState(() =>
    defaultValue.map((value, index) => ({
      key: `${name}-${index}`,
      value,
    }))
  );
  const [fieldsCount, setFieldsCount] = useState(defaultValue.length - 1);

  function addNewMultipleField(e) {
    setFields((fields) => [
      ...fields,
      { key: `${name}-${fieldsCount + 1}`, value: "" },
    ]);
    setFieldsCount(fieldsCount + 1);
    e.target.blur();
  }

  function removeMultipleField(index) {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  }

  function handleChange(e, index) {
    const newFields = [...fields];
    newFields[index].value = e.target.value;
    setFields(newFields);
  }

  useEffect(() => {
    const value = fields.map(({ value }) => value);
    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value },
      })
    );
  }, [fields, name, setDemandService]);

  return (
    <>
      {type === "datetime-local" ? null : label}

      {fields.map(({ key, value }, index) => (
        <>
          {type === "datetime-local" ? (
            <FormMultipleDatetimeInput
              value={value}
              name={name}
              onChange={(value) => handleChange({ target: { value } }, index)}
              dateLabel={dateLabel}
              timeLabel={timeLabel}
              error={error}
            >
              <div className="col s12 m12">
                {index === fields.length - 1 ? (
                  <Button
                    className="add-date-button"
                    type="button"
                    onClick={addNewMultipleField}
                    buttonSize="small"
                    variant="secondary"
                  >
                    + {multipleButtonText}
                  </Button>
                ) : (
                  <Button
                    className="add-date-button"
                    type="button"
                    onClick={() => removeMultipleField(index)}
                    buttonSize="small"
                    variant="secondary--danger"
                  >
                    remover
                  </Button>
                )}
              </div>
            </FormMultipleDatetimeInput>
          ) : (
            <FormEditableInput
              type={type}
              name={name}
              value={value}
              onChange={(e) => handleChange(e, index)}
            />
          )}
        </>
      ))}
    </>
  );
};

const FormDatetimeField = ({
  name,
  setDemandService,
  defaultValue,
  dateLabel,
  timeLabel,
  error,
}) => {
  const [dateValue, setDateValue] = useState(
    defaultValue ? defaultValue.split("T")[0] : ""
  );
  const [timeValue, setTimeValue] = useState(
    defaultValue ? defaultValue.split("T")[1] : ""
  );

  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);

  useEffect(() => {
    let value = "";
    if (dateValue && !timeValue) {
      value = `${dateValue}T00:00`;
      setTimeValue("00:00");
    }
    if (!dateValue && timeValue) {
      value = `${todayDate}T${timeValue}`;
      setDateValue(todayDate);
    }
    if (dateValue && timeValue) {
      value = `${dateValue}T${timeValue}`;
    }

    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value },
      })
    );
  }, [dateValue, name, setDemandService, timeValue, todayDate]);

  return (
    <div className={`row ${error ? "required-error" : ""}`}>
      <div className="col s12 m6">
        <FormDateInput
          value={dateValue}
          label={dateLabel}
          placeholder={dateLabel}
          icon="calendar"
          errorMessage={error ? error : ""}
          onChange={(e) => {
            if (!e.target.value) {
              setTimeValue("");
              setDateValue("");
            } else {
              setDateValue(e.target.value);
            }
          }}
        />
      </div>

      <div className="col s12 m6">
        <FormTxtInput
          type="time"
          label={timeLabel}
          placeholder={timeLabel}
          icon="clock"
          errorMessage={error ? error : ""}
          value={timeValue}
          onChange={(e) => setTimeValue(e.target.value)}
        />
      </div>
    </div>
  );
};

const FormMultipleDatetimeInput = ({
  value,
  onChange,
  dateLabel,
  timeLabel,
  error,
  ...props
}) => {
  const splitValue = value.split("T");
  const dateValue = splitValue[0] || "";
  const timeValue = splitValue[1] || "";

  const today = new Date();
  const todayDate = today.toISOString().slice(0, 10);

  function handleChangeDate(e) {
    const value = e.target.value;
    if (!value) {
      onChange("");
    } else {
      onChange(`${e.target.value}T${timeValue || "00:00"}`);
    }
  }

  function handleChangeTime(e) {
    const value = e.target.value;
    if (!value && dateValue) {
      onChange(`${dateValue}T00:00`);
    } else {
      onChange(`${dateValue || todayDate}T${e.target.value}`);
    }
  }

  return (
    <div className="row">
      <div className="col s12 m6">
        <FormDateInput
          value={dateValue}
          label={dateLabel}
          placeholder={dateLabel}
          icon="calendar"
          errorMessage={error ? error : ""}
          onChange={handleChangeDate}
        />
      </div>

      <div className="col s12 m6">
        <FormTxtInput
          type="time"
          label={timeLabel}
          placeholder={timeLabel}
          icon="clock"
          errorMessage={error ? error : ""}
          value={timeValue}
          onChange={handleChangeTime}
        />
      </div>

      {props.children}
    </div>
  );
};

const FormLocalField = ({ setDemandService, name, error, defaultValue }) => {
  const splitLocalValue = defaultValue?.split(",");
  const neighborhoodDefaultValue = splitLocalValue[0];
  const cityDefualtValue = splitLocalValue[1]?.split("-")[0];
  const estateDefaultValue = splitLocalValue[1]
    ?.split("-")[1]
    ?.replaceAll(" ", "");
  const [state, setState] = useState(estateDefaultValue || "SP");
  const [city, setCity] = useState(cityDefualtValue || "");
  const [neighborhood, setNeighborhood] = useState(
    neighborhoodDefaultValue || ""
  );

  useEffect(() => {
    if (city && neighborhood) {
      const value = `${neighborhood}, ${city} - ${state}`;
      setDemandService((demandService) =>
        Object.assign({}, demandService, {
          [name]: { ...demandService[name], value, state },
        })
      );
    }
  }, [state, city, neighborhood]);

  useEffect(() => console.log(error), [error]);

  return (
    <>
      <div className="col s12 m6">
        <FormSelect
          label="Estado"
          placeholder="Estado"
          value={state}
          onChange={(e) => setState(e.target.value)}
          icon="map-pin"
          name={name}
          required
        >
          <option value="AC" selected={state === "AC"}>
            AC
          </option>
          <option value="AL" selected={state === "AL"}>
            AL
          </option>
          <option value="AP" selected={state === "AP"}>
            AP
          </option>
          <option value="AM" selected={state === "AM"}>
            AM
          </option>
          <option value="BA" selected={state === "BA"}>
            BA
          </option>
          <option value="CE" selected={state === "CE"}>
            CE
          </option>
          <option value="DF" selected={state === "DF"}>
            DF
          </option>
          <option value="ES" selected={state === "ES"}>
            ES
          </option>
          <option value="GO" selected={state === "GO"}>
            GO
          </option>
          <option value="MA" selected={state === "MA"}>
            MA
          </option>
          <option value="MT" selected={state === "MT"}>
            MT
          </option>
          <option value="MS" selected={state === "MS"}>
            MS
          </option>
          <option value="MG" selected={state === "MG"}>
            MG
          </option>
          <option value="PA" selected={state === "PA"}>
            PA
          </option>
          <option value="PB" selected={state === "PB"}>
            PB
          </option>
          <option value="PR" selected={state === "PR"}>
            PR
          </option>
          <option value="PE" selected={state === "PE"}>
            PE
          </option>
          <option value="PI" selected={state === "PI"}>
            PI
          </option>
          <option value="RJ" selected={state === "RJ"}>
            RJ
          </option>
          <option value="RN" selected={state === "RN"}>
            RN
          </option>
          <option value="RS" selected={state === "RS"}>
            RS
          </option>
          <option value="RO" selected={state === "RO"}>
            RO
          </option>
          <option value="RR" selected={state === "RR"}>
            RR
          </option>
          <option value="SC" selected={state === "SC"}>
            SC
          </option>
          <option value="SP" selected={state === "SP"}>
            SP
          </option>
          <option value="SE" selected={state === "SE"}>
            SE
          </option>
          <option value="TO" selected={state === "TO"}>
            TO
          </option>
        </FormSelect>
      </div>

      <div className="col s12 m6">
        <FormTxtInput
          className={error ? "input--error" : ""}
          label="Cidade"
          placeholder="Cidade"
          type="text"
          name={name}
          value={city}
          required
          errorMessage={error && !city ? error : ""}
          icon="map-pin"
          onChange={(e) => setCity(e.target.value)}
        />
      </div>

      <div className="col s12 m6">
        <FormTxtInput
          className={error ? "input--error" : ""}
          label="Bairro"
          placeholder="Bairro"
          type="text"
          name={name}
          value={neighborhood}
          required
          errorMessage={error && !neighborhood ? error : ""}
          icon="map-pin"
          onChange={(e) => setNeighborhood(e.target.value)}
        />
      </div>
    </>
  );
};

const FormCheckboxField = ({
  type,
  name,
  label,
  setDemandService,
  defaultValue,
}) => {
  const [checked, setChecked] = useState(!!defaultValue);

  function toggleCheck() {
    setChecked(!checked);
  }

  useEffect(() => {
    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value: checked },
      })
    );
  }, [checked, setDemandService, name]);

  return (
    <div className="col s12">
      <div className="input__field" style={{ textAlign: 'left' }}>
        <label className="checkbox__label" htmlFor="checkbox">{label}</label>
        <input
          className="checkbox"
          type={type}
          name={name}
          checked={checked}
          onChange={toggleCheck}
          id="checkbox"
        />
      </div>
    </div>
  );
};

const FormTextAreaField = ({
  name,
  label,
  setDemandService,
  defaultValue,
  error,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value },
      })
    );
  }, [value, setDemandService, name]);

  return (
    <div className="col s12 m12 l12">
      <FormTxtarea
        label={label}
        placeholder={label}
        icon="align-justify"
        style={{ color: "black" }}
        errorMessage={error ? error : ""}
        name={name}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
    </div>
  );
};

const FormFileField = ({ label, name, setDemandService }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const uploadErrorAlertRef = useRef(null);
  const uploadSuccessAlertRef = useRef(null);

  useEffect(() => {
    setDemandService((demandService) =>
      Object.assign({}, demandService, {
        [name]: { ...demandService[name], value: uploadedFiles },
      })
    );
  }, [name, setDemandService, uploadedFiles]);

  function handleSelectFiles(event) {
    setSubmitErrorMessage(null);
    const fileObj = Array.from(event.target.files)[0];
    if (!fileObj) {
      return;
    }
    if (fileObj.size > 2000000) {
      setSubmitErrorMessage("O tamanho máximo para cada arquivo é 2 MB.");
    } else {
      setSelectedFiles([...selectedFiles, fileObj]);
    }
  }

  const handleSubmitFile = useCallback(() => {
    const form = document.querySelector("form");
    const formData = new FormData(form);
    formData.delete("new_file");
    selectedFiles.forEach((file) => {
      formData.append("file[]", file);
    });

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `/api/buyer/new-briefing/upload-file`, true);
    xhr.onload = () => {
      if (xhr.status === 200) {
        const { uploadedFiles } = JSON.parse(xhr.responseText);
        setUploadedFiles((files) => [...files, ...uploadedFiles]);
        setSelectedFiles([]);
        setIsSubmitting(false);
        uploadSuccessAlertRef.current.open();
      } else {
        setSubmitErrorMessage("Erro no envio do arquivo.");
        setIsSubmitting(false);
      }
    };
    xhr.onerror = () => {
      setSubmitErrorMessage("Erro no envio do arquivo");
      setIsSubmitting(false);
    };
    setIsSubmitting(true);
    xhr.send(formData);
  }, [selectedFiles]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      handleSubmitFile();
    }
  }, [selectedFiles, handleSubmitFile]);

  useEffect(() => {
    if (submitErrorMessage) {
      uploadErrorAlertRef.current.open();
    }
  }, [submitErrorMessage]);

  return (
    <div className="col s12" style={{ margin: '2rem 0', textAlign: 'left' }}>
      <input
        type="file"
        name="new_file"
        id="new_file"
        style={{ display: "none" }}
        onInput={handleSelectFiles}
        disabled={isSubmitting}
      />
      <label style={{ fontSize: '0.8rem' }}>{label}</label>
      {uploadedFiles.map((file, index) => (
        <p key={index}>&#x2713; {file.name}</p>
      ))}

      <Button type="button" variant="secondary" buttonSize="small">
        <label htmlFor="new_file" style={{ fontSize: '0.8rem' }}>
          {isSubmitting ? "Enviando..." : "Enviar novo arquivo"}
        </label>
      </Button>

      <Alert ref={uploadErrorAlertRef} status="danger">
        <p>{submitErrorMessage}</p>
      </Alert>

      <Alert ref={uploadSuccessAlertRef} status="sucess">
        <p>Arquivo adicionado com sucesso!</p>
      </Alert>
    </div>
  );
};
