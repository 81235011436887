import React, { useEffect } from 'react';
import axios from 'axios';
import HeaderPage from '../../../Components/HeaderPage';
import { useAsync } from '../../../utils/useAsync';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import { SupplierPage } from '../styles';
import { Load } from '../../../Components/Loader/styles';
import { SubmittedOfferCard } from './SubmittedOfferCard';

export default function SupplierSubmittedOffers() {
  const { run, data } = useAsync({ status: 'pending' });
  const submittedOffers = data?.supplierOffers ?? [];
  const oldAlertProposalsWithoutOffer = data?.alertProposalsWithoutOffer ?? [];

  useEffect(() => {
    run(axios.get('/api/supplier/offer').then(response => response.data));
  }, [run]);

  if (!data) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <div className="container">
        <HeaderPage
          title="Histórico de orçamentos enviados"
          subtitle="Todos os seus orçamentos anteriores estão disponíveis aqui."
        />

        {submittedOffers.length === 0 ? (
          <p className="fieldset__title margin--bottom-1 txt--center">
            Você ainda não enviou nenhum orçamento.
          </p>
        ) : (
          <>
            {submittedOffers.map(offer => (
              <SubmittedOfferCard key={offer.id} offer={offer} />
            ))}
            {oldAlertProposalsWithoutOffer.map(alertProposal => (
              <SubmittedOfferCard
                key={alertProposal.id}
                offer={{
                  id: `alert-proposal/${alertProposal.id}`,
                  alert: alertProposal.alert,
                  created_at: alertProposal.createdAt,
                }}
              />
            ))}
          </>
        )}
      </div>
    </SupplierPage>
  );
}
