import React, { useRef, Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import Modal from '../Modal';
import axios from 'axios';
import Alert from '../Alert';

import { ContactButton } from './styles';

function ModalContact(){

    useEffect( () => {
        ReactGA.initialize('UA-118284558-4');
    });

    const modalRef = useRef();
    const alertRef = useRef();

    useEffect( () => {
        ReactGA.initialize('UA-118284558-4');
    });

    function handleSubmit(event) {
        event.preventDefault();
    
        const data = new FormData(event.target);
    
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: '/api/get_in_touch',
            data: {
                contact_name: data.get('contact_name'),
                contact_email: data.get('contact_email'),
                message: data.get('message'),
                hidden_phone: data.get('hidden_phone'),
            }
            })
        .then(res => {
            if (res.status === 200) {
                modalRef.current.close();
                alertRef.current.open();
            }
        })        
    }

    function teste(){
        ReactGA.event({
            category: 'Test',
            action: 'Sent message',
            label: 'Test message'
        });

        modalRef.current.open();
    }

    return(
        <Fragment >
            <ContactButton title="Precisa de ajuda?" onClick={ teste }>
                <span> ? </span>
            </ContactButton>

            <Modal ref={modalRef}>
                <form onSubmit={handleSubmit}>
                    <h3 className="modal__title"> Precisa de ajuda? entre em contato conosco </h3>
                    <div className="input__field">
                        <label htmlFor=""> Seu nome </label>
                        <input className="input" name="contact_name" type="text" min="1" required />
                    </div>

                    <div className="input__field">
                        <label htmlFor=""> Seu e-mail </label>
                        <input className="input" name="contact_email" type="text" min="1" required />
                    </div>

                    <div className="input__field">
                        <label htmlFor=""> Sua mensagem </label>
                        <textarea className="input textarea" name="message" id="" cols="30" rows="10"></textarea>
                    </div>

                    {/* <input type="text" id="hidden_phone" name="hidden_phone" style="display:none;" /> */}

                    <button className="btn" type="submit">
                        <span> Enviar mensagem </span>
                    </button>

                </form>
            </Modal>

                <Alert ref={alertRef}>
                    <p>Sua mensagem foi enviada! Entraremos em contato em até 7 dias úteis. </p>
                </Alert>
        </Fragment>
    )
}

export default ModalContact;