import styled from 'styled-components';

export const SalesPanelPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;

  /* Colors */
  background-color: var(--color-gray-light);

  position: relative;
`;

SalesPanelPage.Grid = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 90vh;
  margin: 1rem auto 3rem auto;
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(4, 1fr);
  }
`;

SalesPanelPage.Column = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-top: 2rem;
  padding: 0 0.5rem;

  :first-child {
    /* Display & Box Model */
    margin-top: 0;
  }

  &.hidden {
    display: none;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    margin-top: 0;

    /* Others */
    border-right: solid 0.1rem var(--color-border);

    :last-child {
      border-right: none;
    }
  }
`;

SalesPanelPage.ColumnTitle = styled.div`
  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0.5rem 1rem;
  margin-bottom: 1rem;

  /* Colors */
  color: var(--color-black);

  /* Position */
  position: relative;

  /* Text */
  font-size: 1rem;
  font-weight: 700;

  /* Others */
  border-bottom: 0.1rem solid var(--color-border);

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: none;
  }
`;

SalesPanelPage.ColumnHeader = styled.header`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  /* Colors */
  background-color: var(--color-green);


  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 996;

  /* Others */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.15);

  h3 {
    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 0.7rem;
    font-weight: 300;
  }

  p {
    /* Display & Box Model */
    display: none;
  }
  .tooltip{
    background-color: var(--color-white);
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Position */
    position: relative;

    /* Others */
    box-shadow: var(--shadow);

    h3 {
      /* Text */
      font-size: 1rem;
      font-weight: 700;
    }

    p {
      /* Display & Box Model */
      display: block;

      /* Text */
      font-weight: 700;
    }
  }
`;

SalesPanelPage.ColumnHeaderItem = styled.div`
  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  /* Position */
  position: relative;

  &.active {
    h3 {
      /* Colors */
      color: var(--color-dark);

      /* Text */
      font-weight: 700;
    }
  }

  ::after {
    /* Display & Box Model */
    content: '';
    width: 1rem;
    height: 3rem;

    /* Colors */
    background-color: var(--color-border);

    /* Position */
    position: absolute;
    right: -0.5rem;

    /* Others */
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 35% 100%, 85% 50%, 35% 0%);
  }

  :last-child {
    :after {
      /* Display & Box Model */
      display: none;
    }
  }

  .tooltip {
    /* Display & Box Model */
    display: none;

    /* Others */
    float: right;
    border: 0.1rem solid var(--color-border);
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    padding: 0 1rem 0 1.5rem;

    .tooltip {
      /* Display & Box Model */
      display: flex;
    }

    ::after {
      height: 3rem;
    }
  }
`;

SalesPanelPage.ColumnHeaderHide = styled.span`
  /* Display & Box Model */
  display: none;
  margin-top: 0.2rem;

  /* Text */
  font-size: 0.8rem;
  font-weight: 300;

  /* Others */
  cursor: pointer;

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: block;
  }
`;

SalesPanelPage.ColumnText = styled.div`
  /* Text */
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: center;

  p {
    padding: 1.5rem 1rem;
    margin: 0 1.5rem;
  }

  p:first-of-type {
    border-bottom: 2px dashed var(--color-gray);
  }

  a {
    color: var(--color-primary);
  }
`;

SalesPanelPage.Card = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 0 auto 1rem auto;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  overflow: hidden;
  line-height: 1.4;

  p {
    /* Display & Box Model */
    margin: 0.5rem 0;
  }

  p,
  a {
    /* Text */
    font-size: 0.8rem;
  }

  h5 {
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Text */
    font-size: 0.9rem;
  }

  > button {
    /* Display & Box Model */
    width: 100%;
    display: block;
    margin-top: 0;

    /* Colors */
    background-color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;

    /* Others */
    border: none;
    border-top: 0.1rem solid var(--color-border);
    cursor: pointer;

    a,
    span {
      /* Display & Box Model */
      display: block;
      padding: 1rem 0;
    }

    :first-of-type {
      /* Display & Box Model */
      margin-top: 1rem;
    }
  }
`;

SalesPanelPage.CardHeader = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

SalesPanelPage.ItemsList = styled.ul`
  /* Display & Box Model */
  padding: 0rem 1rem;
  margin: 0.7rem 0;

  font-size: 0.8rem;

  > li {
    list-style-type: square;
    list-style-position: inside;
    margin-left: 3px;
  }
`;

SalesPanelPage.CardMessage = styled.div`
  /* Display & Box Model */
  padding: 0 1rem;
  margin: 0.7rem 0;
`;

SalesPanelPage.ItemDescription = styled.div`
  margin: 1.5rem;

  h4 {
    color: var(--color-primary);

    span {
      color: var(--color-black);
    }
  }

  div {
    padding-left: 1rem;

    p,
    li {
      text-align: left;
    }
  }
`;

SalesPanelPage.DetailsSection = styled.div`
  padding-left: 1rem;

  p {
    white-space: pre-wrap;
  }
`;

SalesPanelPage.StateCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1rem;
  margin-bottom: 1rem;
  background-color: var(--color-gray-light-2);
  border-radius: var(--radius);
  p {
    user-select: none;
    font-size: 0.8rem;
  }
  &:hover {
    cursor: pointer;
    input {
      border-color: black;
    }
  }
`;