import styled, { keyframes } from 'styled-components';
// Images
import bgGraphism from '../../assets/images/backgrounds/home-bg.svg';
import categories from '../../assets/images/icons/categories/icons-sprite-sheet.svg';

export const HomePage = styled.div`
  /* Display & Box Model */
  width: 100%;
`;


/*=======================================
              Bunner
========================================*/

HomePage.Bunner = styled.section`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  
  /* Colors & Background */
  background-color: var(--color-white);
  color: var(--color-black);
  
  /* Position */
  position: relative;
  
  ::before{
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 100vh;

    /* Colors & Background */
    background-image: url(${bgGraphism});
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 80%;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;
  }

  .container{
    position: relative;
    z-index: 9;
  }
  
  h1{
    /* Display & Box Model */
    margin-bottom: 1rem;
    
    /* Colors */
    color: var(--color-primary-light);
    
    /* Text */
    font-family: 'Estate','Manrope',sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
  }
  
  p{
    /* Display & Box Model */
    max-width: 24rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 600px){
    ::before{
      background-size: inherit;
    }


    h1{
      /* Text */
      font-size: 4rem;
    }
  }

  @media (min-width: 1400px){
    h1{
      /* Text */
      font-size: 6rem;
    }

    p{
      font-size: 1.2rem;
      max-width: 40rem;
    }
  }
`;

/*=======================================
            Celebrar Numbers
========================================*/

HomePage.Numbers = styled.section`
  /* Display & Box Model */
  width: 100%;
  
  /* Colors */
  background-color: var(--color-white);
  
  /* Position */
  position: relative;
  
  @media (min-width: 600px){
    /* Display & Box Model */
    height: 60vh;
    
    /* Others */
    overflow: hidden;
  }
`;

HomePage.NumbersPhoto = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 30vh;
  
  /* Position */
  position: relative;
  /* left: -15%; */

  /* Others */
  overflow: hidden;
  
  img{
    height: 100%;
  }
  
  @media (min-width: 600px){
    /* Display & Box Model */
    width: 60%;
    height: 60vh;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (min-width: 1400px){
    width: 80%;
  }
`;

HomePage.NumbersContent = styled.div`
  /* Display & Box Model */
  width: 90%;
  padding: 2rem;
  margin: auto;
  
  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;
  top: -4rem;
  
  h4{
    /* Display & Box Model */
    margin-bottom: 2rem;
    
    /* Colors */
    color: var(--color-primary-light);
    
    /* Text */
    font-size: 1.2rem;
    font-weight: 600;
  }

  div{
    /* Display & Box Model */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    :last-child{
      margin-bottom: 0;
    }
  }
  
  span{
    /* Colors */
    color: var(--color-primary-light);

    /* Text */
    font-size: 1.2rem;
    font-weight: 600;
  }

  @media (min-width: 600px){
    /* Display & Box Model */
    width: 25rem;

    /* Position */
    position: absolute;
    top: 0;
    right: 5%;
  }

  @media (min-width: 1400px){
    right: 10%;
  }
`;

/*=======================================
            Celebrar Buyers
========================================*/

HomePage.Buyers = styled.section`
  /* Display & Box Model */
  width: 100%;
  height: 50vh;
  margin: 4rem auto;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-primary-light);

  /* Text */
  text-align: center;

  h3{
    /* Display & Box Model */
    margin-bottom: 4rem;

    /* Text */
    font-size: 1.4rem;
    font-weight: 600;
  }

  @media (min-width: 600px){
    height: 40vh;
  }

  @media (min-width: 1400px){
    height: 30vh;
  }
`;

HomePage.BuyersLogos = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  
  figure{
    /* Display & Box Model */
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 100%;
      object-fit: cover;
    }
  }
`;

HomePage.BuyersLogos.Btn = styled.button`
  /* Display & Box Model */

  /* Colors */
  background-color: transparent;

  /* Others */
  border: none;
  cursor: pointer;
`;

/*=======================================
              About Us
========================================*/

HomePage.About = styled.section`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  
  .container{
    /* Display & Box Model */
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    /* Position */
    position: static;
  }

  @media (min-width: 600px){
    /* Display & Box Model */
    height: 100vh;

    .container{
        /* Display & Box Model */
        flex-direction: row;

        /* Position */
        position: static;
      }
  }

  @media (min-width: 1400px){
    height: 80vh;
  }
`;

HomePage.AboutText = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  /* Colors */
  background-color: var(--color-purple-dark);
  color: var(--color-white);

  /* Position */
  position: relative;
  z-index: 9;
  
  h3{
    /* Display & Box Model */
    margin-bottom: 2rem;
    
    /* Text */
    font-size: 2rem;
    font-weight: 700;
  }

  p{
    padding-right: 2rem;
  }
  
  ::before{
    /* Display & Box Model */
    content: '';
    width: 60%;
    height: 100%;

    /* Colors */
    background-color: var(--color-purple-dark);

    /* Position */
    position: absolute;
    top: 0;
    left: -40%;
    z-index: -1;
  }

  @media (min-width: 600px){
    /* Display & Box Model */
    width: 60%;
    
    h3{
      /* Text */
      font-size: 2.5rem;
    }
    
    p{
      /* Display & Box Model */
      width: 75%;
      padding-right: 0;
    }
  }
`;

HomePage.AboutImage = styled.figure`
  /* Display & Box Model */
  width: 90%;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  
  img{
    width: 100%;
    object-fit: cover;
  }
  
  @media (min-width: 600px){
    /* Display & Box Model */
    width: 40%;
  }
`;
HomePage.Alert = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: red;
  text-align: center;
  font-weight: bold;
  color:#fff;
  position: fixed;
  z-index: 10;
  margin-top: 4rem;
  font-size: 0.8rem;
  p{
    font-size: 0.8rem;
  }
  span{
    font-size: 0.8rem;
    font-weight: bold;
  }

`;



HomePage.faqHeader = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 5rem 0 2rem 0;

  /* Colors */
  background-color: var(--color-black);
  color: var(--color-white);

  /* Position */
  position: relative;

  h3{
    /* Display & Box Model */    
    /* Text */
    font-size: 2.5rem;
    font-weight: 700;
  }
  
 

  .container{
    text-align: center;
  }
  ::before{
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 100%;

    /* Background */
    background-image: url(${bgGraphism});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;

    /* Others */
    transform: scaleY(-1);
  }
  
  ::after{
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 12rem;
    z-index: -1;

    /* Colors */
    background-color: var(--color-black);

    /* Potision */
    position: absolute;
  }

  @media (min-width: 600px){
    ::before{
      background-position: right center;
      background-size: inherit;
    }


  }
`;


HomePage.Faq = styled.div`
  display: flex;
  justify-content: center;
  .content{
    color: #fff;
    border-radius: 0.5rem;
    h1{
      font-size:1.5rem;
      text-align: center;
      z-index: 5;
      margin-bottom: 2rem;
    }
    
  }
    .container_card{
      display: flex !important;
      justify-content: center;
    }
    h3{
      color: #fff;
      text-align: center;
    }
    figure{
      width: 28rem;
      height: 18rem;
      background-color: #fff;
      border-radius: 0.5rem;
      margin-left:1rem;
      margin-right:1rem;
      margin-bottom :4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px 0.5px silver;
      h1{
        font-size: 2rem;
        font-weight: 600;
        color: var(--color-primary-dark);
        margin-bottom: 3rem;
      }
      
      #comprador{
        width: 11rem;
        height: 13rem;
      }
      #fornecedor{
        width: 14rem;
        height: 13rem;  
      }
      
    }
    
    @media (max-width: 768px){
      .content{
 margin-left: 2rem;
      }
      .container_card{
        display: block !important;
      }
      figure{
      width: 20rem;
      height: 18rem;
      margin-left:0rem;
      margin-right:0rem;
    }
    }
    
    
    `;

/*=======================================
        How does it work (Steps)
========================================*/

HomePage.Steps = styled.section`
  /* Display & Box Model */
  /* width: 100vw; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 3rem;

  h3{
    /* Display & Box Model */
    margin-bottom: 2rem;

    /* Colors */
    color: var(--color-primary-light);
    
    /* Text */
    font-size: 2rem;
    font-weight: 700;
  }
  
  a{
    /* Position */
    position: relative;
    top: -4rem;
  }
  
  @media (min-width: 600px){
    padding-top: 0;

    h3{
      font-size: 2.5rem;
    }
  }
`;

HomePage.StepsGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  align-items: start;
  justify-content: space-between;

  @media (min-width: 600px){
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  @media (min-width: 700px){
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  @media (min-width: 900px){
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
`;

HomePage.StepCard = styled.div`
  /* Display & Box Model */
  width: 100%;
  
  figure{
    /* Display & Box Model */
    width: 100%;
    height: 15rem;
    
    /* Colors */
    background-color: var(--color-gray);

    /* Others */
    overflow: hidden;

    img{
      width: 100%;
      object-fit: cover;
    }
  }
`;

HomePage.StepCard.Text = styled.div`
  /* Display & Box Model */
  width: 80%;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Text */
  line-height: 1.5;

  /* Position */
  position: relative;
  top: -6rem;

  p{
    font-size: 0.8rem;
  }

  @media (min-width: 600px){
    p{
      font-size: 0.9rem;
    }
  }
`;

HomePage.StepCard.Title = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  /* Colors */
  color: var(--color-primary-light);

  h4{
    font-size: 2rem;
  }

  h5{
    font-size: 1.4rem;
  }
`;

/*=======================================
              Categories
========================================*/

HomePage.Categories = styled.section`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  margin-bottom: 4rem;
`;
HomePage.Categories = styled.section`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  margin-bottom: 4rem;
`;

HomePage.CategoriesContent = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 4rem 0 2rem 0;

  /* Colors */
  background-color: var(--color-black);
  color: var(--color-white);

  /* Position */
  position: relative;

  h3{
    /* Display & Box Model */
    margin-bottom: 2rem;
    
    /* Text */
    font-size: 2.5rem;
    font-weight: 700;
  }
  
  p{
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 2rem;
    line-height: 1.5;
  }

  .container{
    text-align: center;
  }
  ::before{
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 100%;

    /* Background */
    background-image: url(${bgGraphism});
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 60%;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;

    /* Others */
    transform: scaleY(-1);
  }
  
  ::after{
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 12rem;
    z-index: -1;

    /* Colors */
    background-color: var(--color-black);

    /* Potision */
    position: absolute;
  }

  @media (min-width: 600px){
    ::before{
      background-position: right center;
      background-size: inherit;
    }


  }
`;

HomePage.CategoriesGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat( 1, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  
  @media (min-width: 600px){
    grid-template-columns: repeat( 3, 1fr);
  }

  @media (min-width: 800px){
    grid-template-columns: repeat( 4, 1fr);
  }

  @media (min-width: 900px){
    grid-template-columns: repeat( 5, 1fr);
  }
`;

HomePage.Categories.Card = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  /* Colors */
  background-color: var(--color-primary-light);
  color: var(--color-white);

  figure{
    /* Display & Box Model */
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    /* Colors */
    background-color: var(--color-white);
    background-image: url(${categories});
    background-size: 19rem;
    background-repeat: no-repeat;

    /* Others */
    border-radius: 50%;

    img{
      height: 3.5rem;
      object-fit: cover;
    }
  }

  h4{
    /* Text */
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  p{
    width: 100%;
    /* Text */
    font-size: 0.8rem;
    line-height: 1.8;
  }
  
  ul{
    width: 100%;
    
    li{
      display: inline;
      /* Text */
      font-size: 0.8rem;
      line-height: 1.8;
      
    }
  }

  button{
    /* Colors */
    background-color: transparent;
    color: var(--color-white);

    /* Text */
    font-size: 1rem;
    font-weight: 600;
    text-align: left;

    /* Others */
    border: none;
  }

  #alimentacao{
    background-position: 0.1rem 0.2rem;
  }

  #musica{
    background-position: -3.7rem -0.3rem;
  }

  #brindes{
    background-position: -7.5rem -0.1rem;
  }

  #audiovisual{
    background-position: -11.2rem -0.1rem;
  }
  
  #staff{
    background-position: -15.1rem -0.2rem;
  }

  #fotografia{
    background-position: 0.1rem -3.7rem;
  }

  #logistica{
    background-position: -3.6rem -3.7rem;
  }

  #decoracao{
    background-position: -7.5rem -3.7rem;
  }

  #conteudo{
    background-position: -11.2rem -3.7rem;
  }

  #espacos{
    background-position: -15.1rem -3.8rem;
  }
`;

/*=======================================
              Comments
========================================*/

HomePage.Comments = styled.section`
  /* Display & Box Model */
  width: 100%;
  /* height: fit; */
  margin: 4rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
  h3{
    /* Display & Box Model */
    margin-bottom: 4rem;
    
    /* Colors */
    color: var(--color-primary-light);
    
    /* Text */
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
  }
  
  @media (min-width: 600px){
    height: 40vh;

  }
`;

HomePage.CommentsContent = styled.div`
  /* Display & Box Model */
  width: 100%;
  max-width: 45rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

HomePage.Comment = styled.div`
  /* Display & Box Model */
  /* width: 40rem; */
  padding: 2rem;
  margin: auto;
  
  /* Colors */
  background-color: var(--color-gray-light);
  
  /* Others */
  border-radius: 0.3rem;

  h6{
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Colors */
    color: var(--color-primary-light);

    /* Text */
    font-weight: 600;
  }

  p{
    line-height: 1.5;
  }
`;