import React from 'react';

export function FormCheckbox({ children, id, ...props }) {
  return (
    <div className="input__field input__field--checkbox">
      <label className="checkbox__label" htmlFor={id}>
        {children}
      </label>
      <input className="checkbox" type="checkbox" id={id} {...props} />
    </div>
  );
}
