import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import FaqContent from './FaqContent';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqProviderNF() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />
      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question1'>
              <p>
                Como envio a nota fiscal do meu serviço e/ou produto?
              </p>
            </a>
            <a href='#question2'>
              <p>
                Tive uma nota recusada, o que devo fazer??
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Como envio a nota fiscal do meu serviço e/ou produto?
              </h2>
              <p>
                Siga o passo a passo para realizar o envio da nota fiscal (NF):
              </p>
              <ol>
                <li>
                  1. Acesse <span>“Painel de Vendas”</span>
                </li>
                <li>
                  2. Clique em <span>“Vendas confirmadas”</span>
                </li>
                <li>
                  3. Selecione a venda que deseja enviar a nota fiscal
                </li>
                <li>
                  4.Clique em <span>“Anexar nota fiscal”</span>
                </li>
                <li>
                  5. Selecione o arquivo e clique em <span>Enviar nota fiscal</span>
                </li>
                <li>
                6. Prontinho, sua nota foi enviada com sucesso!
                </li>
              </ol>
              <p>
                Você ainda pode realizar o procedimento pelo e-mail de “Confirmação de venda”.
              </p>
              <p>
                <span>Importante:</span> Não se esqueça de conferir o <span>“Valor do Repasse”</span> e de informar a <span>ID do evento "#"</span> no campo de descrição da nota fiscal.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                Tive uma nota recusada, o que devo fazer?
              </h2>
              <p>
                Sempre que uma nota fiscal (NF) é reprovada, enviamos um e-mail com orientações para o envio de uma nova nota fiscal, com as alterações que devem ser feitas.
              </p>
              <p>
                Confira os motivos frequentes para ter uma <span>Nota Recusada</span>:
              </p>
              <ul>
                <li>
                  Não mencionar a # (ID do evento)
                </li>
                <li>
                  Valor informado diferente do valor de repasse
                </li>
                <li>
                  Emissão para outro contratante
                </li>
                <li>
                  Emissor da Nota diferente do contratado
                </li>
              </ul>
              <p>
              O envio da NF deve ser realizado com o mesmo e-mail da "Confirmação de Venda".
              </p>
              <p>
                <span>Importante:</span> Só realizamos pagamentos em contas de pessoa física para quem possui CNPJ-MEI.
              </p>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >
  );
}