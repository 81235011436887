import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Button } from '../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../Components/common/Buttons/ButtonIconOnly';
import { AngleLeft, AngleRight } from '../../Components/common/Icons/IconsFamily';
import { ModalStyles } from '../../Components/Modal/styles';
import { toBraCurrency } from '../../utils';
// import { Close } from '../common/Icons/IconsFamily';
import { ProposalPage } from './styles';

const ProposalItemModal = forwardRef((
  { item, modalPhotos, supplier, reviews, ...props }, ref) => {

//   const [currentImage, setCurrentImage] = useState(0);

  const [isOpen, setIsOpen] = useState(null);
  const [isClosing, setIsClosing] = useState(null);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector("header").classList.add("closing");
      document.querySelector("aside").classList.add("closing");
    },
    close() {
      setIsClosing(true);

      setTimeout(() => {
        setIsOpen(false);
        document.querySelector('body').style.overflow = 'inherit';
        document.querySelector("header").classList.remove("closing");
        document.querySelector("aside").classList.remove("closing");
      }, 500);
    },
  }));

  useEffect(() => {
    return () => (document.querySelector('body').style.overflow = 'inherit');
  }, []);

  useEffect(() => {
    isOpen === false && setIsClosing(null);
  }, [isOpen]);

  function close() {
    setIsClosing(true);

    setTimeout(() => {
      setIsOpen(false);
      document.querySelector('body').style.overflow = 'inherit';
    }, 500);
  }

  function openMoralPhotos() {
    close();
    modalPhotos.current.open();
  }

  function closeModal() {
    document.querySelector("header").classList.remove("closing");
    document.querySelector("aside").classList.remove("closing");
    close();
  }

  return (
    <>
      {isOpen && (
        <ModalStyles >
          <ModalStyles.LightBox className={isClosing && 'closing'} onClick={closeModal} />

          <ProposalPage.Item.Modal className={isClosing && 'closing'}>
            <ProposalPage.Item.ModalClose
              children="Fechar"
              onClick={closeModal}
            />

            <ProposalPage.Item.ModalTitle >
              <h2>{supplier?.name}</h2>
            </ProposalPage.Item.ModalTitle>

            <ProposalPage.Item.ModalGrid >
              {/* {
                item?.photos?.length > 0 && (
                  <ProposalPage.Item.ModalPhoto id="modal">
                    {
                      item?.photos?.length > 1 && currentImage > 0 && (
                        <ButtonIconOnly
                          type="button"
                          onClick={() => {
                            setCurrentImage(currentImage - 1);
                          }}
                        >
                          <AngleLeft />
                        </ButtonIconOnly>
                      )
                    }

                    <img
                      style={{ width: 'auto', height: '100%', position: 'absolute' }}
                      src={item?.photos[currentImage]?.url}
                      alt=""
                      onClick={openMoralPhotos}
                    />

                    {
                      item?.photos?.length > 1 && currentImage < item?.photos?.length - 1 && (
                        <ButtonIconOnly
                          type="button"
                          onClick={() => {
                            setCurrentImage(currentImage + 1);
                          }}
                        >
                          <AngleRight />
                        </ButtonIconOnly>
                      )
                    }
                  </ProposalPage.Item.ModalPhoto>
                )
              } */}

              <ProposalPage.Item.ModalDescription >
                {/* {
                  reviews && (
                        reviews.map(function (review) {
                            <div>
                                <input
                                    id=''
                                    className={`input`}
                                    defaultValue={review?.rating ?? ""}
                                    disabled
                                />
                                <textarea
                                    id=''
                                    className={`input textarea`}
                                    defaultValue={review?.obs ?? "Sem comentários"}
                                    disabled
                                />
                            </div>
                        })
                  )}; */}
              </ProposalPage.Item.ModalDescription>
            </ProposalPage.Item.ModalGrid>

          </ProposalPage.Item.Modal>
        </ModalStyles>
      )}
    </>
  );
});

export default ProposalItemModal;
