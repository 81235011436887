import React from 'react';
import { EscalePage } from '../styles';

function EscaleFooter(){
    return(
        <EscalePage.Footer>
            <p> Powered by Celebrar </p>
        </EscalePage.Footer>
    )
}

export default EscaleFooter;