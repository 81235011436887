import { useEffect } from 'react';
import axios from 'axios';
import { useAsync } from '../../../../utils/useAsync';

export function useRegisteredData() {
  const { run, data, isLoading, isError } = useAsync({ status: 'pending' });

  useEffect(() => {
    run(axios.get('/api/supplier/register-company').then(response => response.data)).catch(err =>
      console.log(err.message)
    );
  }, [run]);

  return {
    manager_name: data?.manager_name || '',
    known_name: data?.known_name || '',
    company_name: data?.company_name || '',
    registered_cpf: data?.manager_cpf || '',
    registered_cnpj: data?.cnpj || '',
    email: data?.email || '',
    indicated_by: data?.indicated_by || '',
    isLoadingRegisteredData: isLoading,
    isErrorRegisteredData: isError,
  };
}
