import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCategories } from './hooks/useCategories';
import Alert from '../../../Components/Alert';
import { CategoriesHeader } from '../../../Components/Categories/CategoriesHeader';
import Header from '../../../Components/Header';
import { Load } from '../../../Components/Loader/styles';
import Modal from '../../../Components/Modal';
import { Button } from '../../../Components/common/Buttons/Button';
import { CategoriesBody } from '../../../Components/Categories/CategoriesBody';
import CategoriesSelectionPageStyles from './styles';
import Welcome from '../../../assets/images/review-sucess.svg';

import { AsideUserCategories } from './AsideUserCategories.js';

export function SupplierCategoriesSelection() {
  const history = useHistory();

  const welcomeMessageModalRef = useRef();
  const noCategoriesSelectedAlertRef = useRef();
  const serverErrorAlertRef = useRef();

  const [currentCategory, setCurrentCategory] = useState(28);

  const {
    groupedCategories,
    userCategories,
    level3categories,
    isLoadingCategories,
    addCategory,
    removeCategory,
  } = useCategories();

  useEffect(() => {
    if (!isLoadingCategories) {
      window.scrollTo(0, 0);
      welcomeMessageModalRef.current.open();
    }
  }, [isLoadingCategories]);

  const userLevel3Categories = level3categories.filter(cat => userCategories.includes(cat.id));

  function handleAddCategory(category) {
    addCategory(category.id).catch(() => serverErrorAlertRef.current.open());
  }

  function handleRemoveCategory(categoryId) {
    removeCategory(categoryId).catch(() => serverErrorAlertRef.current.open());
  }

  function handleContinue() {
    if (userLevel3Categories.length === 0) {
      noCategoriesSelectedAlertRef.current.open();
    } else {
      history.push('/fornecedor/painel-de-vendas');
    }
  }

  if (isLoadingCategories) {
    return (
      <>
        <Header />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </>
    );
  }

  return (
    <>
      <CategoriesSelectionPageStyles>
        <div className="content">
          <header className="bg--primary-dark">
            <div className="container">
              <h1>Selecione suas categorias de especialidades</h1>
              <h3>As áreas de atuação vão definir quais pedidos de orçamento você receberá</h3>
              <CategoriesHeader
                groupedCategories={groupedCategories}
                currentCategory={currentCategory}
                setCurrentCategory={setCurrentCategory}
              />
            </div>
          </header>

          <div className="row">
            <div className="container">
              <div className="col s12 m6 l8">
                <CategoriesBody
                  categories={groupedCategories}
                  currentCategory={currentCategory}
                  handleSubmit={handleContinue}
                  supplierCategories={userLevel3Categories}
                  handleAddCategory={handleAddCategory}
                  addedCategories={userCategories}
                handleRemoveCategory={handleRemoveCategory}
                />
              </div>

              <div className="col s12 m6 l4">
                <AsideUserCategories selectedCategories={userLevel3Categories} handleRemoveCategory={handleRemoveCategory} handleSubmit={handleContinue} />
              </div>
            </div>
          </div>

        </div>
      </CategoriesSelectionPageStyles>

      <Alert status="warning" ref={noCategoriesSelectedAlertRef}>
        Deve ser selecionada ao menos uma categoria.
      </Alert>
      <Alert status="danger" ref={serverErrorAlertRef}>
        Ocorreu um erro.
      </Alert>

      <Modal modalSize="large" title="Seja bem vindo(a)" ref={welcomeMessageModalRef}>
        <h2 className="txt--black txt--center" style={{ marginBottom: '0.5rem' }}>
          Bem vindo(a) à Celebrar
        </h2>
        <h3 className="txt--black txt--center margin--bottom-2">
          Antes de acessar o seu <span className="txt--secondary">Painel de Vendas</span>, complete
          as informações a seguir
        </h3>

        <figure className="txt--center" style={{ width: '100%' }}>
          <img src={Welcome} alt="Bem vindo(a)" style={{ width: '12rem', margin: 'auto' }} />
        </figure>

        <Button align="center" onClick={() => welcomeMessageModalRef.current.close()}>
          Vamos lá
        </Button>
      </Modal>
    </>
  );
}
