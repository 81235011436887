import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ProposalPage } from './styles';
// import { ShoppingCart } from '../../Components/CategoriesIcons';
import Modal from '../../../Components/Modal';

function OldProposalCart({ saveItems, selectedItems, totalPrice }){
    
    const modalRef = useRef();

    /*===================================
    Returns value in Brazilian currency
    ====================================*/

    function toBraCurrency(value){
        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
    }

    return(
        <Fragment >
            <ProposalPage.CartButton onClick={ () => modalRef.current.open() } id="cart" >
                {/* <ShoppingCart /> */}
                Carrinho
                <div> { selectedItems.length } </div>
            </ProposalPage.CartButton>

            <Modal ref={ modalRef } >
                <ProposalPage.Cart >
                    <h3> Resumo da compra </h3>
                    <h4> Itens no carrinho: { selectedItems.length } </h4>
                    <h4> Valor total: { toBraCurrency(totalPrice) } </h4>

                    <button className="btn" onClick={ saveItems } disabled={ selectedItems.length > 0 ? false : true }>
                        { selectedItems.length > 0 ? (
                            <Link to="/carrinho-proposta" > Ir para o carrinho </Link>
                        ) : (
                            <span > Ir para o carrinho </span>
                        )}
                    </button>
                </ProposalPage.Cart>
            </Modal>
        </Fragment>
    )
}

export default OldProposalCart;