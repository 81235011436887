import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { LVStore } from '../styles';
// Icons
import arrowPink from '../../../assets/icons/learning-village/arrow-pink.svg';
import arrowWhite from '../../../assets/icons/learning-village/arrow-white.svg';


const LearningVillageModal = forwardRef((
    {
        item,
        isItemAdded,
        changeQuantity,
        inputChangeQuantity,
        quantity,
        calculateTotal,
        addItem,
        removeItem,
        changeImage,
        currentImage
    }, ref) => {

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle( ref, () => ({
            open() {
                setIsOpen(true);
                document.querySelector("body").style.overflow = "hidden";
            },
            close() {
                setIsOpen(false);
                document.querySelector("body").style.overflow = "inherit";
            }
         }),
     )

    function close(){
        setIsOpen(false);
        document.querySelector("body").style.overflow = "inherit";
    }

    useEffect( () => {
        return () => document.querySelector("body").style.overflow = "inherit"
    }, [] );
    
    useEffect( () => {

        console.log( isItemAdded , "aqui");
    }, [])

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

    return(
        <Fragment >
            {
                isOpen && (
                    <LVStore.ModalItem>
                        <LVStore.Lightbox onClick={ close } className={ isOpen && "open" } />
                        
                        <LVStore.ModalContainer >
                            <div className="container">
                                <LVStore.ModalContent >
                                    <LVStore.ModalPhotos >
                                        <LVStore.ModalPhotosBg />
                                        <figure>
                                            <img src={ item.photos[currentImage].url } alt="" />
                                        </figure>
                                        <LVStore.ModalPhotosControl >
                                            <img src={ arrowWhite } alt="" onClick={ () => changeImage("prev") } />
                                            <img src={ arrowPink } alt="" onClick={ () => changeImage("next") } />
                                        </LVStore.ModalPhotosControl>
                                    </LVStore.ModalPhotos>

                                    <LVStore.ModalDetails >
                                        <LVStore.ModalClose>
                                            <img src={ arrowPink } alt="" onClick={ () => close() } />
                                        </LVStore.ModalClose>
                                        
                                        <LVStore.ModalName >
                                            { item.name }
                                        </LVStore.ModalName>

                                        <LVStore.ModalDescription defaultValue={ item.description } disabled={ true } />

                                        {
                                            !isItemAdded ? (
                                                <LVStore.Button className="bgLight blue" onClick={ () => addItem( item ) }>
                                                    <span> Adicionar ao carrinho </span>
                                                </LVStore.Button>
                                            ) : (
                                                <LVStore.Button className="bgLight blue" onClick={ () => removeItem( item.id ) }>
                                                    <span> Remover do carrinho </span>
                                                </LVStore.Button>
                                            )
                                        }

                                        <LVStore.ModalPrices >
                                            <LVStore.ModalPricesItem >
                                                <span> valor de venda </span>
                                                <h6> { toBraCurrency( parseFloat( item.buyer_price ) ) } </h6>
                                            </LVStore.ModalPricesItem>

                                            <LVStore.ModalPricesItem >
                                                <span> quantidade </span>
                                                {
                                                    item.editable && !isItemAdded  ? (
                                                        // <input type="number" min={ item.min_quantity } max={ item.max_quantity }> { item.quantity } </input>
                                                        <div className="item__quantity" >
                                                            <button type="button" onClick={ () => changeQuantity("menos") }>
                                                                <span> - </span>
                                                            </button>

                                                            <input
                                                                type="number"
                                                                value={ quantity }
                                                                onInput={ (event) => inputChangeQuantity(event.target.value) }
                                                                className="input"
                                                            />

                                                            <button type="button" onClick={ () => changeQuantity("mais") }>
                                                                <span> + </span>
                                                            </button>
                                                        </div>
                                                    ) : 
                                                    <h6> { quantity } </h6>
                                                }
                                            </LVStore.ModalPricesItem>

                                            <LVStore.ModalPricesItem >
                                                <span> subtotal </span>
                                                <h6> { toBraCurrency(calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee })) } </h6>
                                            </LVStore.ModalPricesItem>
                                        </LVStore.ModalPrices>
                                    </LVStore.ModalDetails>

                                </LVStore.ModalContent>
                            </div>  
                        </LVStore.ModalContainer>
                    </LVStore.ModalItem>
                )
            }
        </Fragment>
    )
})

export default LearningVillageModal;