import React, { useState } from 'react';
import Header from '../../../../Components/Header';
import { Link } from 'react-router-dom';
import { Reset } from './styles';
import axios from 'axios';

function ResetPassword(props){

    const [ message, setMessage ] = useState();

    function handleSubmit(event) {
        event.preventDefault();
    
        const data = new FormData(event.target);
        const { handle } = props.match.params;
    
        console.log('Entrou no reset');
    
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: `/api/auth/reset_password/${handle}`,
            data: {
                password: data.get('password'),
                confirm_password: data.get('confirm_password'),
            }
        })
        .then(res => {
            setMessage(res.data.message);
            // window.location.href = "/painel-comprador";
        })
        .catch(err => console.log(err));
    }

    return(
        <Reset >
            <Header />
            <div className="container">
                <Reset.Container >
                    <Reset.Box >
                        <h1> Esqueci minha senha </h1>
                        <h2> Atualize sua senha através do formulário abaixo </h2>

                        <form onSubmit={handleSubmit}>
                            <div className="input__field">
                                <label htmlFor="password"> Nova senha </label>
                                <input 
                                    type="password"
                                    className="input"
                                    name="password"
                                    placeholder="Senha"
                                    // value={ password }
                                    // onInput={ event => setPassword( event.target.value ) }
                                />
                            </div>

                            <div className="input__field">
                                <label htmlFor="confirm-password"> Confirmar nova senha </label>
                                <input 
                                    type="password"
                                    className="input"
                                    name="confirm_password"
                                    placeholder="Confirme sua senha"
                                    // value={ confirmPassword }
                                    // onInput={ event => setConfirmPassword( event.target.value ) }
                                />
                            </div>

                            <button className="btn btn--fullwidth">
                                <span> Salvar nova senha </span>
                            </button>

                            { 
                                message === "ok" ? 
                                (
                                    <h3> Senha cadastrada com sucesso! <br />
                                        <Link to="/login" > Acesse sua conta </Link>
                                    </h3>

                                ) 
                                : <h3> { message } </h3>
                            }
                        </form>
                    </Reset.Box>
                </Reset.Container>
            </div>
        </Reset>
    )
}

export default ResetPassword;