import React, { useEffect, useState, useRef } from 'react';
// import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Modal from '../../Components/Modal';
import { Card, ProposalFooter, ProposalHeader } from './styles';
import axios from 'axios';
import Alert from '../../Components/Alert';

import Logo from '../../assets/images/logos/celebrar/Celebrar_Branco_fundo_alpha.png';
import CardArrow from '../../assets/icons/arrowCard.svg';

function Proposal(){

    const [items, setItems] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [proposal, setProposal] = useState(localStorage.getItem('proposal'));
    const [proposalId, setProposalId] = useState(localStorage.getItem('proposalId'));

    const modalRef = useRef();
    // const modalRefChanges = useRef();
    const alertRef = useRef();
    
    useEffect( () => {
        if(localStorage.getItem('items')){
            setItems(JSON.parse(localStorage.getItem('items')));
            if(localStorage.getItem('shipping')){
                setTotalPrice( parseFloat(localStorage.getItem('totalPrice')) + parseFloat(localStorage.getItem('shipping')));
            } else {
                setTotalPrice( parseFloat(localStorage.getItem('totalPrice')));
            }
        }


        console.log('Proposta nº' + localStorage.getItem('proposalId'));
    }, []);

    function removeItem(item){
        let localItems = JSON.parse(localStorage.getItem('items')).filter(localItem => localItem.id !== item.id);
        setItems(localItems);

        let newTotalPrice;
        if(item.additional_fee){

            let fee = item.final_additional_fee ?? calculateAdditionalPrice(item.additional_fee);

            newTotalPrice = totalPrice - (parseFloat(item.buyer_price) * parseFloat(item.quantity)) - fee;
            if( item.additional_fee_name === 'Frete'){
                let newShipping = parseFloat(localStorage.getItem('shipping')) - fee;
                localStorage.setItem('shipping', newShipping);
            }
        } else {
            newTotalPrice = totalPrice - parseFloat(item.buyer_price) * parseFloat(item.quantity);
        }
        
        setTotalPrice(newTotalPrice);

        localStorage.setItem('items', JSON.stringify(localItems));
        localStorage.setItem('totalPrice', newTotalPrice);
    }

    function calculateAdditionalPrice(additional_fee) {

        let additional_with_rate = parseFloat(additional_fee / 0.90);

        // console.log('additional_with_rate = ' + additional_with_rate / 0.85);

        return (parseFloat(additional_with_rate)) / 0.85 ;

    }

    function handleSubmit(event) {
        event.preventDefault();

        // console.log(JSON.parse(localStorage.getItem('items')));
        console.log('Proposta nº' + localStorage.getItem('proposalId'));
    
        const data = new FormData(event.target);
    
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: '/api/proposal_send',
            data: {
                contact_name: data.get('contact_name'),
                contact_email: data.get('contact_email'),
                payment_method: data.get('payment_method'),
                cnpj: data.get('cnpj'),
                message: data.get('message'),
                items: localStorage.getItem('items'),
                proposalId: localStorage.getItem('proposalId'),
                totalPrice: parseFloat(localStorage.getItem('totalPrice')),
            }
            })
        .then(res => {
            if (res.status == 200) {
                modalRef.current.close();
                alertRef.current.open();
            }
        })
        .catch(err => console.log(err));
    }    

    function accordion(element){
        element.classList.toggle('open');
    }

    return(
        <div>
            <ProposalHeader >
                <div className="container">
                    <div className="header__content">
                        <div className="header__logo">
                            <figure className="logo">
                                <a href="https://celebrar.co">
                                    <img src={Logo} alt=""/>
                                </a>
                            </figure>
                            <h3> Celebrar Box </h3>
                        </div>

                        <div className="header__text">
                            <p> Celebrar Tecnologia e Intermediações Ltda </p>
                            <p> CNPJ: 28.282.186/0001-38 </p>
                        </div>
                    </div>
                </div>

                <div className="container margin--top-2">
                    <h1 className="pageheader__title--sub txt--left"> 
                        Carrinho de compras
                    </h1>
                </div>
            </ProposalHeader>

                <div className="container">

                    <div className="content--box row">
                        <div className="col m8 s12">
                            {
                                items.length > 0 ? items.map( item => {
                                    return(
                                        <Card >
                                            <div className="card__header">
                                                <p className="card__remove" onClick={ () => removeItem(item)}> Remover item </p>
                                                <h3 className="card__name"> { item.name } </h3>
                                            </div>
                                                    
                                            <div className="card__content">
                                                <div className="card__text">
                                                    <span className="card__label"> Descrição: </span>
                                                    <p>
                                                        {item.description}
                                                    </p>
                                                </div>

                                                <div className="card__calculate">
                                                    <div className="card__number">
                                                        <h3> Quantidade </h3>
                                                        <h4> { item.quantity } </h4>
                                                    </div>

                                                    <div className="card__number">
                                                        <h3> Valor unitário </h3>
                                                        <h4> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( item.buyer_price)} </h4>
                                                    </div>

                                                    { item.additional_fee ? (
                                                        <div className="card__number">
                                                            <h3> { item.additional_fee_name } </h3>
                                                            <h4> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( calculateAdditionalPrice(item.additional_fee) )} </h4>
                                                        </div>
                                                    ) : '' }

                                                    <div className="card__subtotal">
                                                        <h3> Subtotal </h3>
                                                        { item.additional_fee ? (
                                                            
                                                            <h4 className=""> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( parseFloat(item.buyer_price) * parseFloat(item.quantity) + parseFloat(calculateAdditionalPrice(item.additional_fee)) )} </h4>

                                                        ) : (

                                                            <h4 className=""> {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( parseFloat(item.buyer_price) * parseFloat(item.quantity) )} </h4>

                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    )
                                }
                                ) : <h1> Você não tem itens no seu carrinho </h1>
                            }

                            <div className="txt--center">
                                <button className="btn btn--secondary bg--white center" onClick={ () => window.print() }>
                                    <span> Imprimir proposta </span>
                                </button>
                            </div>

                        </div>

                        <div className="col m4 s12">
                            <div className="box bg--black">
                                <span className="txt--center txt--left txt--gray"> Total da compra </span>
                                <hr></hr>
                                <h3 className="title--section txt--secondary txt--left margin--top-1"> { Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format( totalPrice ) } </h3>
                            </div>

                            {/* <Btn className="btn bg--primary">
                                <span className="txt--white"> Pagamento em cartão de crédito </span>
                            </Btn> */}

                            <button className="btn bg--secondary btn--fullwidth" 
                                    onClick={ () => modalRef.current.open() }
                                    disabled={ items.length > 0 ? false : true }>
                                <span> Aprovar proposta </span>
                            </button>

                            <Modal title="Aprovar proposta" ref={modalRef}>
                                <h4 className="modal__title"> Aprovar proposta </h4>
                                <p> Preencha os campos abaixo e confirmaremos seu pedido em até 24h </p>

                                <form onSubmit={handleSubmit}>
                                    <div className="input__field">
                                        <label htmlFor=""> Nome *</label>
                                        <input type="text" className="input" name="contact_name" required />
                                    </div>

                                    <div className="input__field">
                                        <label htmlFor=""> E-mail corporativo * </label>
                                        <input type="text" className="input" name="contact_email" required />
                                    </div>

                                    <div className="input__field">
                                        <label htmlFor=""> CNPJ para emissão da Nota Fiscal * </label>
                                        <input type="text" className="input" name="cnpj" required />
                                    </div>

                                    <div>
                                        <label htmlFor=""> Forma de pagamento </label>
                                        <br />
                                            <input type="radio" id="pix" name="payment_method" value="pix" checked />
                                                <label for="pix"> Pix </label> <br />
                                            <input type="radio" id="boleto" name="payment_method" value="boleto" />
                                                <label for="boleto"> Boleto bancário </label> <br />
                                            <input type="radio" id="fatura" name="payment_method" value="fatura" />
                                                <label for="fatura"> Faturado (30 dias) * </label> 

                                            <br /> <br />

                                        <small>* mediante cadastro prévio / aprovação</small>
                                    </div>

                                    <button className="btn" type="submit">
                                        <span> Finalizar pedido </span>
                                    </button>
                                </form>
                            </Modal>

                            <Alert ref={ alertRef }>
                                <p> Agradecemos a sua confirmação! Em breve, seu especialista em eventos Celebrar entrará em contato com você para finalizar o processo. </p>
                            </Alert>
                        </div>
                    </div>
                </div>

                <ProposalFooter >
                    <div className="container">
                        <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                            <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Formas de pagamento </h3>
                            <p className="accordion__text">
                                Cartão de Crédito, Débito, Boleto ou TED. Faturamento até 30 dias sujeito análise. <br />
                                Em caso de atraso no pagamento, o valor terá incidência de juros de mora de 0,5% ao dia, pro rata die, mais multa de 2% ao mês. <br />
                            </p>
                        </div>

                        <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                            <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Política de cancelamento </h3>
                            <p className="accordion__text">
                                <b> a) Flexível: </b> Fornecedor oferece reembolso integral para cancelamentos com mais de 7 dias antes do evento e nenhum reembolso para cancelamentos feitos com menos de 7 dias antes da entrega. <br />
                                <b> b) Moderada: </b> Fornecedor oferece reembolso integral para cancelamentos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega. <br />
                                <b> c) Rigorosa: </b> Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 15 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 15 dias antes da entrega. <br />
                                <b> d) Conservadora: </b> Fornecedor oferece reembolso de 50% para cancelamentos feitos com mais de 30 dias antes da entrega e nenhum reembolso para cancelamentos feitos com menos de 30 dias antes da entrega. <br />
                            </p>
                        </div>

                        <div className="accordion" onClick={ (event) => accordion(event.currentTarget) }>
                            <h3 className="accordion__title"> <span> <img src={CardArrow} alt=""/> </span> Termos de uso </h3>
                            <p className="accordion__text"> Ao aceitar esta proposta declaro que li e aceito os <a href="https://celebrar.co/termos" target="_blank"> termos de uso Celebrar </a></p>
                        </div>
                    </div>
                </ProposalFooter>
            <Footer />
        </div>
    )
}

export default Proposal