import React, { useState } from 'react';

function Tooltip({ children, position }) {
    const [tooltipState, setTooltipState] = useState('close');

    return (
        <span
            className="tooltip"
            onMouseOver={() => setTooltipState('open')}
            onMouseLeave={() => setTooltipState('close')}
        >
            ?
            <span
                className={`tooltip__description tooltip__description--${tooltipState} tooltip__position--${position} `}
            >
                {children}
            </span>
        </span>
    );
}

export default Tooltip;
