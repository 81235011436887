import React, { useLayoutEffect } from 'react';
import Header from '../../Components/Header';
import OfferItemPrintVersion from '../../Components/Offer/OfferItemPrintVersion';
// Styled & Images
import { Button } from '../../Components/common/Buttons/Button';
import { ProposalPage } from './styles';
import { SuccessPage } from '../ShoppingCartProposal/styles';
import success from '../../assets/images/proposals/sucess.png';
import { Redirect, useLocation } from 'react-router-dom';
import Footer from '../../Components/Footer';

function ProposalApproved() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!location.state?.items) {
    return <Redirect to="/" />;
  }

  const sectionsObject = location.state.items.reduce((sections, item) => {
    if (!sections[item.section_id]) {
      const items = [item];
      sections[item.section_id] = {
        section_id: item.section_id,
        section_name: item.section_name,
        items,
      };
      return sections;
    }
    sections[item.section_id].items.push(item);
    sections[item.section_id].items.sort((a, b) => a.section_position - b.section_position);

    return sections;
  }, {});

  const sections = Object.values(sectionsObject);

  return (
    <ProposalPage>
      <Header buyerPage hideButtons />

      <SuccessPage.Header>
        <div className="container">
          <div className="cnpj">
            <p>Celebrar Tecnologia e Intermediações Ltda</p>
            <p>CNPJ: 28.282.186/0001-38</p>
          </div>
        </div>

        <div className="container">
          <figure>
            <img src={success} alt="" />
          </figure>
          <div>
            <h1>Seu pedido foi realizado com sucesso!</h1>
            <p>
              Agradecemos a sua confirmação! Em breve, seu especialista em eventos Celebrar entrará
              em contato com você para finalizar o processo.
            </p>
          </div>
        </div>
        <div className="container">
          <p style={{ marginTop: '3rem', fontWeight: 700, fontSize: '1.1rem' }}>
            Resumo do seu pedido
          </p>
        </div>
      </SuccessPage.Header>

      <ProposalPage.Content>
        <div className="container">
          {sections.map((section, index) => (
            <ProposalPage.Section key={index}>
              {section.section_name && (
                <ProposalPage.Section.Title className="center" style={{ padding: '1.5rem 0' }}>
                  {/* <ProposalPage.Section.Icon id={'category189'} /> */}
                  {section.section_name}
                </ProposalPage.Section.Title>
              )}

              <ProposalPage.Section.Content>
                {section.items.map(item => (
                  <OfferItemPrintVersion key={item.label} item={item} />
                ))}
              </ProposalPage.Section.Content>
            </ProposalPage.Section>
          ))}
        </div>
        <Button align='center' onClick={() => window.print()}>
          Salvar em PDF
        </Button>
      </ProposalPage.Content>

      <Footer />
    </ProposalPage>
  );
}

export default ProposalApproved;
