import React from 'react';

import '../../assets/styles/admin-pages.css';
import Tooltip from '../Tooltip';

function HeaderPage({ title, subtitle, description }){
    return (
        <div className="headerpage">
            <h1 className="headerpage__title">
                { title }
                {
                    description && (
                        <Tooltip position="right"> { description } </Tooltip>
                    )
                }
            </h1>
            <h2 className="headerpage__subtitle"> { subtitle } </h2>
        </div>
    )
}

export default HeaderPage;