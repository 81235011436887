import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../Contexts/AuthContext';
import CelebrarLogo from '../../../assets/images/logos/celebrar/celebrar.svg';
import profileImageStub from '../../../assets/icons/user-profile-image.svg';
import { Menu } from '../../Supplier/Menu/styles';

function BuyerMenu() {
    const { user, signOut } = useAuthContext();

    function handleLogout(event) {
        event.preventDefault();
        signOut();
    }

    return (
        <Menu>
            <div className="container">
                <Menu.Links>
                    <Link to="/">
                        <Menu.Logo src={CelebrarLogo} alt="Celebrar" />
                    </Link>

                    <ul>
                        <li>
                            <Link to="/painel-comprador" className="active">
                                Painel do comprador
                            </Link>
                        </li>

                        <li className="dropdown dropdown--arrow">
                            <Link to="#">
                                {user &&
                                    (user.name || user.company_name || 'Complete seu cadastro')}
                                <figure>
                                    <img src={user?.avatar_url || profileImageStub} alt="" />
                                </figure>
                            </Link>
                            <div>
                                <Link to="/comprador/minha-conta">Minha conta</Link>
                                <Link to="#" onClick={event => handleLogout(event)}>
                                    Sair
                                </Link>
                            </div>
                        </li>
                    </ul>
                </Menu.Links>
            </div>
        </Menu>
    );
}

export default BuyerMenu;
