import FeatherIcon from 'feather-icons-react';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';

export function FormTxtInputWithStatus({
  isLoading,
  isSuccess,
  errorMessage,
  label,
  inputId,
  icon,
  ...props
}) {
  return (
    <Container>
      <label>{label}</label>
      <InputWrapper>
        {icon && <FeatherIcon icon={icon} className="icon" size={23} color="silver" />}
        <input {...props} />
        <IconWrapper>
          {isLoading ? (
            <FeatherIcon icon="loader" className="spinner" color="var(--color-primary-light)" />
          ) : isSuccess ? (
            <FeatherIcon icon="check" color="var(--color-green)" />
          ) : errorMessage ? (
            <FeatherIcon icon="x" color="var(--color-red)" />
          ) : null}
        </IconWrapper>
      </InputWrapper>
      {errorMessage ? <p className="error-msg">{errorMessage}</p> : null}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${tw`my-4`}

  label {
    ${tw`
         text-xs	
       `}
  }

  .error-msg {
    color: var(--color-red);
    ${tw`
         text-xs	
       `}
  }
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    height: 2.6rem;
    border: none;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 5px 1px var(--color-gray);
    width: 100%;
    margin:8px 0px;

    &::placeholder {
      ${tw`text-xs`}
    }
  }

  .icon {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
  }
`;

const loadAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0.85rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;

  .spinner {
    animation: ${loadAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
