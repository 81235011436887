import styled, { keyframes } from 'styled-components';

/*====================================
            Animations
=====================================*/

const openLightBoxAnime = keyframes`
  from{
    opacity: 0;
  }

  to{
    opacity: 1;
  }
`;
const openModalContainerAnime = keyframes`
  from{
    opacity: 0;
    transform: translateY(-25rem);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

const closeLightBoxAnime = keyframes`
  from{
    opacity: 1;
  }

  to{
    opacity: 0;
  }
`;

const closeModalContainerAnime = keyframes`
  from{
    opacity: 1;
    transform: translateY(0);
  }
  to{
    opacity: 0;
    transform: translateY(10rem);
  }
`;

/*====================================
            Elements
=====================================*/

export const ModalStyles = styled.div`
  /* Display & Box Model */
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  .absolute{
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
`;

ModalStyles.LightBox = styled.div`
  /* Display & Box Model */
  width: 100vw;
  height: 100vh;

  /* Colors & Background */
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);

  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  /* Others */
  animation: ${openLightBoxAnime} 0.3s ease-out forwards;
  opacity: 0;

  &.closing {
    animation: ${closeLightBoxAnime} 0.3s ease-out forwards;
  }
`;

ModalStyles.Container = styled.div`
  /* Display & Box Model */
  width: 90%;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  animation: ${openModalContainerAnime} 0.3s ease-out 0.3s forwards;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  opacity: 0;
  overflow: hidden;

  &.closing {
    animation: ${closeModalContainerAnime} 0.3s ease-out forwards;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: ${({ size }) => size};
  }
`;

ModalStyles.Title = styled.h3`
  /* Display & Box Model */
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  /* Colors */
  background-color: var(--color-purple-dark);
  color: var(--color-white);
`;

ModalStyles.Content = styled.div`
  /* Display & Box Model */
  width: 100%;
  max-height: ${({ size }) => size === "full" ? "40rem" : "30rem"};
  min-height: 10rem;
  padding: 1rem;

  /* Text */
  text-align: center;

  /* Others */
  overflow-y: scroll;

  @media (min-width: 480px) {
    padding: 2rem;
  }
`;

ModalStyles.Cancel = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  color: var(--color-red);

  p {
    /* Text */
    font-size: 0.8rem;
    font-weight: 600;

    /* Others */
    cursor: pointer;
  }
`;

/*====================================
          Registration Modal
=====================================*/

export const RegistrationModal = styled.div`
  overflow-x: hidden;
  padding: 0 4px;

  h1 {
    margin-bottom: 2rem;
    /* Colors */
    color: var(--color-primary-light);

    /* Text */
    text-align: left;
    font-size: 2.2rem;
    font-family: 'Estate', 'Manrope', sans-serif;
    font-weight: 700;
  }

  h2 {
    margin-top: -1rem;
    margin-bottom: 2rem;
    color: var(--color-primary-light);
    font-size: 1.1rem;
  }

  div {
    margin: 1rem 0;
    text-align: left;
  }

  button {
    margin: 0.5rem 0;
  }

  .link-button {
    margin: 0.5rem auto;
    width: fit-content;
    background-color: transparent;
    padding: 0;
    color: var(--color-primary-light);
    border: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

/*====================================
          Login Modal
=====================================*/

export const LoginModalForm = styled.form`
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  text-align: left;

  button,
  p {
    width: 100%;
  }

  p {
    text-align: center;
  }

  input.form-error {
    border-color: var(--color-red);
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;

    span {
      line-height: 1.5;
    }

    a {
      color: var(--color-green);
    }

    input {
      margin: 0;
    }
  }

  .field-error-message {
    font-size: 0.8rem;
    text-align: left;
    color: var(--color-red);
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(25%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FadeInContainer = styled.div`
  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;
