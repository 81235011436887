import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { AlertBox, AlertPrimary, AlertSucess, AlertWarning, AlertDanger } from './styles';

const Alert = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
      setTimeout(() => close(), 5000);
    },
    close() {
      setIsOpen(false);
    },
  }));

  function close() {
    setIsOpen(false);
  }

  return (
    <>
      {isOpen ? (
        <AlertBox>
          {!props.status && <AlertPrimary>{props.children}</AlertPrimary>}

          {props.status === 'sucess' && <AlertSucess>{props.children}</AlertSucess>}

          {props.status === 'warning' && <AlertWarning>{props.children}</AlertWarning>}

          {props.status === 'danger' && <AlertDanger>{props.children}</AlertDanger>}
        </AlertBox>
      ) : (
        ''
      )}
    </>
  );
});

export default Alert;
