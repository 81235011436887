import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Load } from '../../../Components/Loader/styles';
import SupplierMenu from '../Menu';
import { SupplierPage } from '../styles';
import { useContract } from './hooks/useContract';
import Image404 from '../../../assets/images/404_image.svg';
import { Button } from '../../../Components/common/Buttons/Button';
import FeedbackChannel from '../FeedbackChannel';
import { formatDate, toBraCurrency } from '../../../utils';
import { ButtonLink } from '../../../Components/common/Buttons/ButtonLink';
import Modal from '../../../Components/Modal';
import Cancelled from '../../../assets/icons/Reject.svg';
import { InvoiceSubmitForm } from './styles';
import { useSubmitInvoice } from './hooks/useSubmitInvoice';
import Alert from '../../../Components/Alert';
import Warning from '../../../assets/icons/warning.svg';
import { ContractDetailsModalContent } from './ContractDetailsModalContent';

export default function SupplierContractInvoices() {
  const { id } = useParams();

  const modalRef = useRef();
  const submitErrorAlertRef = useRef();
  const submitSuccessAlertRef = useRef();

  const {
    isLoadingContract,
    contract,
    loadContract,
    isContractNotFound,
    isContractFromOtherSupplier,
    isContractServerError,
  } = useContract(id);

  const isContractCancelled = contract?.status_id === 8;

  const {
    handleSelectFiles,
    handleSubmitInvoice,
    isSubmitting,
    isSubmitSuccess,
    isSubmitError,
    setIsSubmitSuccess,
    fileErrorMessage,
  } = useSubmitInvoice({
    contract_id: id,
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSubmitSuccess) {
      setIsSubmitSuccess(false);
      submitSuccessAlertRef.current.open();
      setTimeout(loadContract, 2000);
    }
  }, [isSubmitSuccess, loadContract, setIsSubmitSuccess]);

  useEffect(() => {
    if (fileErrorMessage || isSubmitError) {
      submitErrorAlertRef.current.open();
    }
  }, [fileErrorMessage, isSubmitError]);

  if (isLoadingContract) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  if (isContractServerError) {
    return (
      <SupplierPage>
        <FeedbackChannel />
        <SupplierMenu />
        <SupplierPage.NotFound>
          <h1>Ops... Ocorreu um erro :(</h1>
          <h1>Tente novamente mais tarde.</h1>
        </SupplierPage.NotFound>
      </SupplierPage>
    );
  }

  if (isContractNotFound || isContractFromOtherSupplier) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <FeedbackChannel />
        <SupplierPage.NotFound>
          <img src={Image404} alt="404" />
          <h1>Ops... Página não encontrada.</h1>
          <h1>
            {isContractFromOtherSupplier
              ? 'Verifique se o link acessado está correto, ou, se você possui mais de uma conta na plataforma, tente acessar com outro usuário.'
              : 'Verifique se o link acessado está correto.'}
          </h1>
          <Link to="/fornecedor/painel-de-vendas">
            <Button>Ir para o painel de vendas</Button>
          </Link>
        </SupplierPage.NotFound>
      </SupplierPage>
    );
  }

  // only works if you do them isolately
  const today = new Date();
  var day = today.getDate();
  var month = today.getMonth();

  var isSubmittalDisabled = false;

  switch (true) {
    // // Disabled between 16/12/2022 and 06/01/2023
    // case day >= 19 && month == 11:
    //   isSubmittalDisabled = true;
    //   break;
    // case day <= 4 && month == 0:
    //   isSubmittalDisabled = true;
    //   break;
      
    // Disabled every month between 26-31
    case day >= 26:
      isSubmittalDisabled = true;
      break;
    default:
      isSubmittalDisabled = false;
  }
  
  return (
    <SupplierPage>
      <FeedbackChannel />
      <Modal title="Detalhes da venda" ref={modalRef}>
        <ContractDetailsModalContent contract={contract} />
      </Modal>
      <SupplierMenu />
      <SupplierPage.Container>
        {!isContractCancelled ? (
          <SupplierPage.InvoiceAlert>
            <figure>
              <img src={Warning} alt="Aviso" />
              <figcaption className="txt--red"> IMPORTANTE! </figcaption>
            </figure>
            <h3 className="txt--red txt--bold">
              Não aceitamos notas fiscais emitidas após o dia 26 de cada mês. Caso você seja
              contratado neste período, por favor emitir a nota fiscal somente após o dia 01 do mês
              seguinte.
            </h3>
          </SupplierPage.InvoiceAlert>
        ) : null}
        <SupplierPage.Invoice>
          <div style={{ padding: '1rem 2rem', width: '100%' }}>
            <SupplierPage.BoxHeader
              style={{ paddingBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <h3> Evento #{contract.sale_code} </h3>
                <p>
                  Entrega do serviço em: {contract && formatDate(contract.delivery_estimated_at)}
                </p>
              </div>
              <ButtonLink onClick={() => modalRef.current.open()}>Ver detalhes da venda</ButtonLink>
            </SupplierPage.BoxHeader>
          </div>
          {isContractCancelled ? (
            <SupplierPage.CancelledContractHeader>
              <img src={Cancelled} alt="aviso" />
              <p className="txt--primary txt--center txt--bold txt--secondary">Venda cancelada</p>
            </SupplierPage.CancelledContractHeader>
          ) : (
            <>
              <h4 className="txt--black txt--bold margin--bottom-1">
                Atente-se às seguintes instruções:
              </h4>
              <SupplierPage.InvoiceInstructions>
                <li>
                  <h5> # {contract?.sale_code} </h5>
                  <p>
                    Informe na descrição da nota fiscal o id do evento. <br /> Insira{' '}
                    <span className="txt--primary txt--bold"># {contract?.sale_code}</span>
                  </p>
                </li>

                <li>
                  <h5> {contract && toBraCurrency(contract.total_supplier_sale_price)} </h5>
                  <p>
                    Emita a nota fiscal no valor de{' '}
                    <span className="txt--primary txt--bold">
                      <br /> {contract && toBraCurrency(contract.total_supplier_sale_price)}
                    </span>
                  </p>
                </li>

                <li>
                  <h5> 01 ~ 25 </h5>
                  <p>
                    Envie a NF em{' '}
                    <span className="txt--primary txt--bold">PDF entre os dias 01 e 25 do mês</span>
                    , antes da entrega do serviço
                  </p>
                </li>

                <li>
                  <h5> CNPJ </h5>
                  <p>
                    Razão Social: Celebrar Tecnologia e Intermediações Ltda. |{' '}
                    <span className="txt--primary txt--bold">CNPJ: 28.282.186/0001-38</span>
                  </p>
                </li>
              </SupplierPage.InvoiceInstructions>

              {contract?.invoices.length === 0 ? (
                <>
                  <img src={Warning} alt="Aviso" />
                  <p className="txt--red txt--bold txt--center" style={{ margin: '1rem 0 2rem' }}>
                    Você ainda não adicionou nenhuma nota <br /> fiscal a essa confirmação de venda
                  </p>
                </>
              ) : null}
            </>
          )}
          {contract.invoices?.length > 0 ? (
            <>
              {isContractCancelled ? (
                <h4 className="txt--black txt--center" style={{ lineHeight: 1.8, padding: '2rem' }}>
                  <span className="txt--bold">Atenção: </span>
                  <br /> Se você já emitiu uma nota fiscal para esta venda, é necessário enviar o
                  arquivo da NF cancelada.
                </h4>
              ) : null}
              <SupplierPage.InvoiceFiles>
                <h3>Arquivos enviados</h3>
                {contract.invoices.map(
                  ({ id, url, description, createdAt, approved_at, rejected_at }) => {
                    const splitUrl = url.split('/');
                    const fileName = splitUrl[splitUrl.length - 1];

                    return (
                      <li key={id} style={{ padding: '1rem' }}>
                        <div>
                          {decodeURIComponent(fileName)}
                          <span style={{ color: 'var(--color-text)', fontSize: '0.9rem' }}>
                            {' '}
                            - Enviada em {formatDate(createdAt)}
                            <br />
                            {approved_at
                              ? 'Aprovada'
                              : rejected_at
                              ? `Recusada: ${description}`
                              : 'Em análise'}
                          </span>
                        </div>
                      </li>
                    );
                  }
                )}
              </SupplierPage.InvoiceFiles>
            </>
          ) : null}

          {isSubmittalDisabled ? (
            <>
              <img src={Cancelled} alt="aviso" />
              <p className="txt--primary txt--center txt--bold txt--secondary">
                O envio de Notas Fiscais não é permitido neste período.
                Se você já emitiu sua NF, por favor cancele e faça uma nova emissão no mês seguinte.
              </p>
            </>
          ) : null}

          {isContractCancelled && contract.invoices.length === 0 ? null : (
            <InvoiceSubmitForm action="" onSubmit={handleSubmitInvoice} id="invoice-submit-form">
              <input
                type="file"
                name="new_invoice"
                id="new_invoice"
                accept=".pdf, .xml"
                style={{ display: 'none' }}
                onInput={handleSelectFiles}
                disabled={isSubmitting}
              />
              {
                !isSubmittalDisabled ? (
                  <button className="btn" type="button">
                    <label htmlFor="new_invoice">
                      <span>{isSubmitting ? 'Enviando...' : 'Enviar novo arquivo de NF'}</span>
                    </label>
                  </button>
                ) : null
              }
            </InvoiceSubmitForm>
          )}
        </SupplierPage.Invoice>
      </SupplierPage.Container>

      {/* Error send invoice alert */}
      <Alert ref={submitErrorAlertRef} status="danger">
        <p>
          {fileErrorMessage || 'Não foi possível enviar o arquivo. Tente novamente mais tarde.'}
        </p>
      </Alert>

      {/* Success send invoice alert */}
      <Alert ref={submitSuccessAlertRef} status="sucess">
        <p>Nota fiscal enviada com sucesso!</p>
      </Alert>
    </SupplierPage>
  );
}
