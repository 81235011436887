import styled, { keyframes } from 'styled-components';
// Images
import categories from '../../assets/images/icons/categories/icons-sprite-sheet.svg';
import bg from '../../assets/images/backgrounds/home-bg.svg';
import searchPlus from '../../assets/icons/search-plus.svg';

export const ProposalPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;

  /* Colors */
  background-color: var(--color-gray-light-2);

  /* Others */
  overflow-x: hidden;
`;

ProposalPage.Header = styled.header`
  /* Display & Box Model */
  width: 100%;
  padding: 7rem 0 1rem 0;

  /* Colors */
  background-color: var(--color-primary-light);
  color: var(--color-white);

  /* Position */
  position: relative;

  /*&::before {
    /* Display & Box Model 
    content: '';
    width: 605px;
    height: 500px;

    /* Background 
    background-image: url(${bg});
    background-position: top right;
    background-repeat: no-repeat;

    /* Position 
    position: absolute;
    top: 0;
    right: -16%;
    z-index: 1;

    /* Others 
    transform: rotate(270deg);
    overflow: hidden;
  }*/

  &::after {
    /* Display & Box Model */
    content: '';
    width: 100%;
    height: 16rem;

    /* Colors */
    background-color: var(--color-primary-light);

    /* Position */
    position: absolute;
  }

  .right {
    position: absolute;
    right: 0;
    z-index: 10;
  }

  #content{
    /* Display & Box Model */
    width: 100%;

    /* Position */
    position: relative;
  }

  h1 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-size: 1.6rem;
    font-weight: 600;
  }

  h2{
    margin-bottom: 0.5rem;
  }

  h4 {
    font-weight: 600;
  }

  @media (min-width: 600px) {
    &::before {
      right: -8%;
    }

    #content{
      /* Display & Box Model */
      width: 78%;
    }
  }
  @media print{
    h1{
      text-align: center;
    }
    padding-top: 0rem;
  }
`;

ProposalPage.Content = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding-bottom: 4rem;

  /* Position */
  position: relative;
  z-index: 2;
  
  @media (min-width: 600px){
    &.proposal--buyer{
      width: 78%;
    }
  }
  @media print {
    button{
      display: none;
    }
    img {
      display: none;
    }
  }
`;

ProposalPage.Section = styled.section`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem 0 4rem 0;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-radius: 0.3rem;
  box-shadow: var(--shadow);
  overflow: hidden;
`;

ProposalPage.Section.Title = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  /* Position */
  position: relative;

  /* Text */
  font-size: 1.1rem;
  font-weight: 700;

  /* Others */
  border-bottom: 1px solid var(--color-border);

  &.center {
    justify-content: center;
  }

  p {
    span {
      /* Display & Box Model */
      margin-right: 0.5rem;
      padding: 0.1rem 1rem;

      /* Colors */
      background-color: var(--color-green);
      color: var(--color-white);

      /* Text */
      font-size: 0.8rem;
      font-weight: 600;

      /* Others */
      border-radius: 0.3rem;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;

    /* Text */
    font-size: 1.4rem;

    p {
      /* Position */
      position: absolute;
      right: 2rem;
    }
  }
`;

ProposalPage.Section.Icon = styled.div`
  /* Display & Box Model */
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Background */
  background-image: url(${categories});
  background-size: 17rem;

  /* Position */
  position: relative;

  /* Others */
  border: 1px solid var(--color-border);
  border-radius: 50%;
  overflow: hidden;

  img {
    height: 100%;
    position: absolute;
  }

  .item {
    background-image: none;
  }

  &#category61 {
    background-position: -13.6rem 3.3rem;
  }

  &#category74 {
    background-position: -13.6rem -0.2rem;
  }

  &#category102 {
    background-position: -10.1rem 3.3rem;
  }

  &#category116 {
    background-position: -3.4rem -0.3rem;
  }

  &#category139 {
    background-position: -6.8rem -0.1rem;
  }

  &#category159 {
    background-position: -3.4rem 3.3rem;
  }

  &#category167 {
    background-position: -6.7rem 3.3rem;
  }

  &#category189 {
    background-position: -10.1rem -0.1rem;
  }

  &#category202 {
    background-position: 0 3.3rem;
  }
`;

ProposalPage.Section.Content = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  #hourglass {
    width: 6rem;
    margin-bottom: 1rem;
  }
  @media print{
    background-color: #000;
align-items: start;
  }
`;

/*===========================
      Shopping Cart
===========================*/

const CartAnime = keyframes`
  from{
    transform: translateX(80rem);
  }

  to{
    transform: translateX(0);
  }
`;

const CartAnimeClose = keyframes`
  from{
    transform: translateX(0);
  }
  
  to{
    transform: translateX(80rem);
  }
`;

const CartAnimeMobile = keyframes`
  from{
    transform: translateY(80rem);
  }

  to{
    transform: translateY(0);
  }
`;

const CartAnimeCloseMobile = keyframes`
  from{
    transform: translateY(0);
  }
  
  to{
    transform: translateY(80rem);
  }
`;

ProposalPage.Cart = styled.aside`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;
  display: none;

  /* Position */
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 998;

  /* Others */
  animation: ${CartAnimeMobile} 0.3s ease-out forwards;

  &.open{
    display: block;
    padding-bottom: 5rem;
  }

  &.closing {
    /* Others */
    animation: ${CartAnimeCloseMobile} 0.3s ease-out forwards;
  }

  @media (min-width: 1100px) {
    /* Display & Box Model */
    width: 20rem;
    height: 100vh;

    /* Colors */
    background-color: var(--color-white);

    /* Others */
    animation: ${CartAnime} 0.3s ease-out forwards;
    box-shadow: -0.3rem 0 1rem rgba(0, 0, 0, 0.1);

    &.closing {
      /* Others */
      animation: ${CartAnimeClose} 0.3s ease-out forwards;
    }
  }

  @media (min-width: 1400px) {
    width: 25rem;
  }
  @media print{
    display: none !important;
  }
`;

ProposalPage.Cart.Close = styled.div`
  /* Colors */
  color: var(--color-red);

  /* Text */
  font-size: 0.8rem;
  font-weight: 600;
  text-decoration: underline;

  /* Others */
  cursor: pointer;

  @media (min-width: 1100px){
    display: none;
  }
`;

ProposalPage.Cart.Title = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
  }
  @media print{  
    display:none !important;
  }
`;

ProposalPage.Cart.Items = styled.ul`
  /* Display & Box Model */
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 1.5rem;

  /* Colors */
  background-color: var(--color-gray-light);

  /* Others */
  overflow-y: scroll;
`;

ProposalPage.Cart.Item = styled.li`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;
    /* Text */
    font-size: 0.8rem;
    font-weight: 600;
  }

  h4 {
    /* Color */
    color: var(--color-primary-light);
  }

  span {
    color: var(--color-red);
    font-size: 0.8rem;
    cursor: pointer;
  }
`;

ProposalPage.Cart.Prices = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 30vh;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: fixed;
  left: 0;
  bottom: 0;

  /* Others */
  border-top: 1px solid var(--color-border);
  box-shadow: 0 -0.1rem 1rem rgba(0, 0, 0, 0.1);

  ul {
    /* Display & Box Model */
    width: 100%;
    padding: 1rem 2rem;

    li {
      /* Display & Box Model */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: 600;
      }

      :nth-child(1) {
        color: var(--color-primary-light);

        span {
          font-size: 1.4rem;
        }
      }

      :nth-child(2) {
        span {
          margin-top: 0.5rem;
          color: var(--color-text);
          font-size: 0.8rem;
        }
      }
    }
  }
`;

ProposalPage.Cart.Infos = styled.div`
  div{
    display: inline-block;
    margin: 0 1.5rem 1.5rem 0;
  }

  span{
    display: block;
  }

  span, p{
    font-size: 0.8rem;
  }
`;

ProposalPage.NotFound = styled.div`
  /* Display & Box Model */
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  h1 {
    /* Display & Box Model */
    max-width: 500px;

    /* Text */
    font-size: 1.2rem;
    text-align: center;
  }
`;

/*===========================
      Proposal Item
===========================*/

ProposalPage.Item = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.5rem;

  /* Others */
  border-bottom: 1px solid var(--color-border);

  
  @media (min-width: 1100px){
    flex-direction: row;
  }
  @media print{
  }
`;

ProposalPage.Item.Photo = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  position: relative;
  
  /* Others */
  cursor: zoom-in;
  overflow: hidden;
  :hover{
    ::after{
      /* Display & Box Model */
      content:'';
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      /* Colors */
      background-color: rgba(255, 255, 255, 0.2);

      /* Text */
      font-size: 0.8rem;

      /* Position */
      position: absolute;
      z-index: 9;
    }
  }
  
  img{
    /* Display & Box Model */
    height: 100%;

    /* Position */
    position: absolute;
  }

  @media (min-width: 600px){
    /* Display & Box Model */
    width: 5rem;
    height: 5rem;
  }
  @media print{
    display: none;
  }
`;

ProposalPage.Item.ModalPhoto = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;

  /* Position */
  position: relative;

  /* Othes */


  &#modal{
    cursor: zoom-in;
    :hover{
      ::after{
        /* Display & Box Model */
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        /* Colors */
        background-color: rgba(255, 255, 255, 0.2);
  
        /* Text */
        font-size: 0.8rem;
  
        /* Position */
        position: absolute;
        z-index: 9;
  
        /* Others */
        pointer-events: none;
      }
    }
  }

  img{
    width: 100%;
    max-height: 500px;
  }

  button{
    /* Display & Box Model */
    display: flex;

    /* Colors & Backgorund */
    background-color: var(--color-primary-light);

    /* Position */
    position: absolute;
    right: 0;
    z-index: 9;

    /* Others */
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    svg {
      width: 0.5rem;
    }

    :first-child {
      right: auto;
      left: 0;
      border-radius: 0;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`;

ProposalPage.Item.Print = styled.div`
  display: none;
  @media Print{
    display:inline-block;
    img{
      width: 4rem;
      margin: 0em 0.5em;
    }
  }
`;

ProposalPage.Item.Text = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  /* Others */
  cursor: pointer;
  
  h3{
    /* Text */
    font-size: 1rem;
    font-weight: 700;

  }
  h6{
    /* Text */
    font-size: 0.8rem;
    color: var(--color-gray-medium)
  }
  
  p{
    /* Text */
    font-size: 0.8rem;
    color: var(--color-text);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200rem;
  }

  @media (min-width: 600px){
    width: 20rem;

    &.large{
      width: 40rem;
    }
  }

  @media (min-width: 1400px){
    width: 25rem;

    &.large{
      width: 45rem;
    }
  }

  @media (min-width: 1600px){
    width: 35rem;

    &.large{
      width: 50rem;
    }
  }
  @media print {
    button {
    display:none
    }
p{
  font-size: 0.8rem;
    color: var(--color-text);
    text-overflow: ellipsis;
    max-width: 200rem;
    overflow-wrap: break-word !important;  
    word-wrap: break-word !important; 
    word-break: break-word !important;
    white-space: break-spaces !important;
    height: 10%;
    overflow: auto;
}
  }
`;

ProposalPage.Item.Prices = styled.div`
  /* Display & Box Model */
  width: 100%;

  /* Color */
  color: var(--color-purple-light);

  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  
  div:nth-child(2){
    flex-direction: row;
  }

  h4{
    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
  }

  span{
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }

  input{
    /* Display & Box Model */
    width: 3rem;
    margin: 0 0.5rem;

    /* Text */
    text-align: center;

    /* Others */
    border: none;
    border-bottom: 1px solid var(--color-border);
    -moz-appearance:textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }

  svg{
    width: 0.6rem;
    cursor: pointer;
  }

  @media (min-width: 600px){
    /* Display & Box Model */
    width: 25rem;

    div:first-of-type{
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
      gap: 0;
    }
  }
  @media print{
    div{
      display: flex;
        flex-direction:row !important;
        justify-content: center;
        align-items: center;
        gap: 0rem;
      }
  }
`;

/*===========================
      Modal Item
===========================*/

const modalAnimeOpen = keyframes`
  from{
    opacity: 0;
    transform: translateY(20rem);
  }

  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

const modalAnimeClose = keyframes`
  from{
    opacity: 1;
    transform: translateY(0);
  }
  
  to{
    opacity: 0;
    transform: translateY(20rem);
  }
`;

ProposalPage.Item.Modal = styled.div`
  /* Display & Box Model */
  width: 90%;
  max-width: 1200px;
  height: 85vh;
  max-height: 600px;
  
  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;
  
  /* Others */
  animation: ${modalAnimeOpen} 0.3s ease-out forwards;
  box-shadow: var(--color-shadow);
  border-radius: 0.3rem;
  overflow-y: scroll;
  
  &.closing{
    animation: ${modalAnimeClose} 0.3s ease-out forwards;
  }
  
  figure{
    /* Display & Box Model */
    width: 20rem;
    height: 20rem;
    display: flex;
    justify-content: center;

    /* Position */
    position: relative;

    /* Others */
    overflow: hidden;

    img{
      width: auto;
      height: 100%;
      position: absolute;
    }
  }

  @media (min-width: 600px){
    width: 80%;
  }

  @media (min-width: 1400px){
    width: 75%;
  }
`;

ProposalPage.Item.ModalClose = styled.div`
  /* Colors */
  color: var(--color-red);

  /* Position */
  position: absolute;
  top: 1rem;
  right: 1rem;

  /* Others */
  cursor: pointer;
`;

ProposalPage.Item.ModalGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 2rem;
  margin-bottom: 2rem;

  @media (min-width: 600px){
    flex-direction: row;
  }
`;

ProposalPage.Item.ModalTitle = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem;
  padding-bottom: 0;
  
  h2{
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
  }
`;

ProposalPage.Item.ModalDescription = styled.div`
  /* Display & Box Model */
  height: 20rem;
  width: 100%;
  
  textarea{
    /* Display & Box Model */
    height:13em !important;
    margin-bottom: 1rem;
    
    /* Others */
    cursor: default !important;
    
    &.fullheight {
      height: 19rem !important;
    };
    &#observables{
      height:1em !important;
      font-size: 0.8rem !important;
    }
  }

  p{
    font-size: 0.8rem;
  }
`;

ProposalPage.Item.ModalPrices = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 2rem;

  /* Others */
  border-top: 1px solid var(--color-border);

  div{
    width: 100%;
  }

  label {
    font-size: 0.75rem;
    color: var(--color-text);
  }

  p {
    font-weight: 700;
    margin-top: 4px;
    color: var(--color-black);
  }

  button{
    margin: 0;
  }

  input{
    /* Display & Box Model */
    width: 3rem;
    margin: 0 0.5rem;

    /* Text */
    text-align: center;

    /* Others */
    border: none;
    border-bottom: 1px solid var(--color-border);
    -moz-appearance:textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
  }  
    
  svg{
    width: 0.6rem;
    cursor: pointer;
  }

  @media (min-width: 600px){
    flex-direction: row;
  }
`;

/*===========================
    Proposal expires at
===========================*/

ProposalPage.ExpiresAt = styled.div`
  /* Display & Box Model */
  padding: 1rem;

  /* Colors */
  background-color: var(--color-yellow-dark);
  color: var(--color-white);
  
  /* Position */
  position: relative;

  /* Text */
  text-align: center;

  /* Others */
  border-radius: 0.3rem;
  box-shadow: var(--shadow);
  
  p,span{
    display: block;
    font-size: 0.8rem;
  }

  @media (min-width: 600px){
    /* Position */
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media print{
    box-shadow: none !important;
  }
`;

/*===========================
      Expired alert
===========================*/

ProposalPage.Expired = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-red);

  /* Position */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;

  /* Others */
  border: 1px solid var(--color-red);

  h4 {
    /* Text */
    font-size: 1.4rem;
    font-weight: 700;
  }

  img {
    width: 5rem;
  }
  @media print {
   
      display: none;
  
    p span{
    text-align:center;
    }
  }
`;

/*===========================
    Proposal Print Version
===========================*/

ProposalPage.PrintSuccess = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;

  img {
    width: 10rem;
  }

  h1 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-family: 'Estate', sans-serif;
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    /* Display & Box Model */
    max-width: 40rem;

    /* Text */
    font-size: 1rem;
  }
`;
