import React, { Fragment, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Modal from '../../../Components/Modal';

import { EscalePage } from '../styles';
import { Direction } from '../EscaleIcons';

function EscaleItems ({ item, selectedItems, addItemToCart, removeItemFromCart }) {

    const [ quantity, setQuantity ] = useState( parseInt(item.quantity) );
    const [ currentImage, setCurrentImage ] = useState(0);
    const [ itemFinalPrice, setItemFinalPrice ] = useState(item.final_buyer_price);
    const [ isItemAdded, setIsItemAdded ] = useState(false);
    const modalRef = useRef();

    useEffect( () => {

        item.final_buyer_price = itemFinalPrice;

    }, [itemFinalPrice] );

    useEffect( () => {

        let isThisItemAdded = selectedItems.some( selectedItem => selectedItem.id == item.id );
        setIsItemAdded( isThisItemAdded );
        
    }, [ selectedItems ] );

/*=============================================
            Slide images function
===============================================*/

    function changeImage( direction ){
        const photosLength = item.photos.length - 1;

        if( direction == "next" && currentImage < photosLength ){

            let newIndex = currentImage + 1;
            setCurrentImage( newIndex );

        } else if( direction == "prev" && currentImage > 0 ){

            let newIndex = currentImage - 1;
            setCurrentImage( newIndex );

        }

    }

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

/*=============================================
            Calculate item price
===============================================*/

    function calculateSubtotal(obj){

        return parseFloat(obj.price) * parseInt(obj.quantity);

    }

    function calculateTotal(obj){

        let subtotal = calculateSubtotal(obj);
        
        if( obj.additional ){

            let total = subtotal + calculateAdditionalPrice(obj.additional);
            return parseFloat(total);

        } else {

            return subtotal;

        }

    }

    function calculateAdditionalPrice(additional_fee) {

        let additional_with_rate = parseFloat(additional_fee / 0.90);

        // console.log('additional_with_rate = ' + additional_with_rate / 0.85);

        return (parseFloat(additional_with_rate)) / 0.85 ;

    }

/*=============================================
                Item quantity
===============================================*/

    function changeQuantity(value){

        let newQuantity = quantity;

        if( value === "mais" && quantity < parseInt(item.max_quantity) ){

            newQuantity += 1;
            setQuantity(newQuantity);

        } else if( value === "menos" && quantity > parseInt(item.min_quantity) ){

            newQuantity -= 1;
            setQuantity(newQuantity);

        }
        
    }

    function inputChangeQuantity(value) {

        if( value > parseInt(item.max_quantity) ){

            setQuantity( parseInt(item.max_quantity) );

        } else if ( value < parseInt(item.min_quantity) ){

            setQuantity( parseInt(item.min_quantity) );

        } else {

            setQuantity( parseInt(value) );

        }

    }

/*=====================================================
    Functions to add & remove item from shopping cart
======================================================*/

    function addItem( ){        
        
        setIsItemAdded( !isItemAdded );
        
        let newFinalPrice = calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee });
        
        setItemFinalPrice(newFinalPrice);
        item.quantity = quantity;

        addItemToCart( item );

    }

    function removeItem(){

        setIsItemAdded( !isItemAdded );

        setItemFinalPrice( parseFloat(item.buyer_price) );

        removeItemFromCart( item.id );

    }

    
    return(
        <EscalePage.CardItem >
            <EscalePage.CardImage>
                <img src={ item.photos[currentImage].url } alt="" onClick={ () => modalRef.current.open() }/>
                {
                    item.photos.length > 1 && (
                        <Fragment >
                            <EscalePage.ImgButton
                                onClick={ () => changeImage("next") }
                                className={ currentImage == item.photos.length - 1 && "hidden" }
                            >
                                <Direction />
                            </EscalePage.ImgButton>


                            <EscalePage.ImgButton
                                onClick={ () => changeImage("prev") }
                                className={ currentImage == 0 && "hidden" }
                                >
                                <Direction />
                            </EscalePage.ImgButton>
                        </Fragment>
                    )
                }

                { isItemAdded && <EscalePage.CardSelected > Item adicionado </EscalePage.CardSelected> }
            </EscalePage.CardImage>

            <EscalePage.CardTitle onClick={ () => modalRef.current.open() } > { item.name } </EscalePage.CardTitle>
            <EscalePage.CardPrice onClick={ () => modalRef.current.open() } > A partir de { toBraCurrency( parseFloat( item.buyer_price ) ) } </EscalePage.CardPrice>
            <EscalePage.CardButton onClick={ () => modalRef.current.open() } >
                <Direction />
                <span> Ver detalhes </span>
            </EscalePage.CardButton>

            <Modal ref={ modalRef }>
                <EscalePage.CartModalGrid >
                    <EscalePage.CartModalImg >
                        <img src={ item.photos[currentImage].url } alt="" />
                        {
                            item.photos.length > 1 && (
                                <Fragment >
                                    <EscalePage.ImgButton
                                        onClick={ () => changeImage("next") }
                                        className={ currentImage == item.photos.length - 1 && "hidden" }
                                    >
                                        <Direction />
                                    </EscalePage.ImgButton>


                                    <EscalePage.ImgButton
                                        onClick={ () => changeImage("prev") }
                                        className={ currentImage == 0 && "hidden" }
                                        >
                                        <Direction />
                                    </EscalePage.ImgButton>
                                </Fragment>
                            )
                        }
                    </EscalePage.CartModalImg>

                    <EscalePage.CartModalText >
                        <EscalePage.CartModalName  > { item.name } </EscalePage.CartModalName>
                        <EscalePage.CartModalDescription
                            defaultValue={ item.description }
                            cols="30" rows="10"
                            disabled
                        >
                        </EscalePage.CartModalDescription>

                        {
                            !isItemAdded && (
                                <EscalePage.CartModalButton onClick={ () => addItem() }>
                                    <span> Adicionar ao carrinho </span>
                                </EscalePage.CartModalButton>
                            )
                        }

                        {
                            isItemAdded && (
                                <EscalePage.CartModalButton className="remove" onClick={ () => removeItem() }>
                                    <span> Remover do carrinho </span>
                                </EscalePage.CartModalButton>
                            )
                        }
                    </EscalePage.CartModalText>
                </EscalePage.CartModalGrid>

                <EscalePage.CartModalPrices >
                    <EscalePage.CartModalPrice >
                        <span> Valor de venda </span>
                        <p> { toBraCurrency(parseFloat(item.buyer_price)) } </p>
                    </EscalePage.CartModalPrice>

                    <EscalePage.CartModalPrice >
                        <span> Quantidade </span>
                        {
                            
                            item.editable && !isItemAdded  ? (
                                // <input type="number" min={ item.min_quantity } max={ item.max_quantity }> { item.quantity } </input>
                                <div className="item__quantity" >
                                    <button type="button" onClick={ () => changeQuantity("menos") }>
                                        <span> - </span>
                                    </button>

                                    <input
                                        type="number"
                                        value={ quantity }
                                        onInput={ (event) => inputChangeQuantity(event.target.value) }
                                        className="input"
                                    />

                                    <button type="button" onClick={ () => changeQuantity("mais") }>
                                        <span> + </span>
                                    </button>
                                </div>
                            ) : 
                                <p className="item__price"> { quantity } </p>
                        }
                    </EscalePage.CartModalPrice>

                    <EscalePage.CartModalPrice >
                        <span> Subtotal </span>
                        <p> { toBraCurrency(calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee })) } </p>
                    </EscalePage.CartModalPrice>
                </EscalePage.CartModalPrices>
            </Modal>
        </EscalePage.CardItem>
    )
}

export default EscaleItems;