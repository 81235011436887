import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAsync } from '../../../utils';
import FeedbackChannel from '../FeedbackChannel';
import { ButtonBack } from '../../../Components/common/Buttons/ButtonBack';
import SupplierMenu from '../Menu';
import { Load } from '../../../Components/Loader/styles';
import Modal from '../../../Components/Modal';
import { ButtonLink } from '../../../Components/common/Buttons/ButtonLink';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Image } from '../../../Components/common/Icons/IconsFamily';
import { SubmittedOfferItemDetails } from './SubmittedOfferItemDetails';
import { OfferPage } from '../Offer/styles';
import { SupplierPage } from '../styles';
import { SubmittedOffersPage } from './styles';

export default function SubmittedOfferDetails() {
  const { offerId } = useParams();
  const { user } = useAuthContext();
  const alertDetailsModalRef = useRef();

  const { run, data, isLoading } = useAsync({ status: 'pending' });
  const alert = data?.alert;
  const files = data?.files;
  const expirationDate = data?.expires_at;
  const offerItems = data?.alert_proposals;

  useEffect(() => {
    run(axios.get(`/api/supplier/offer/${offerId}`).then(response => response.data));
  }, [run, offerId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  function getDate(date, isMonth) {
    return isMonth ? monthNames[new Date(date).getMonth()] : new Date(date).getDate();
  }

  if (isLoading) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <OfferPage.Header>
        <ButtonBack link="/fornecedor/historico-orcamentos" />

        <div className="container">
          <h1>{alert?.name}</h1>
          <ButtonLink
            style={{ color: 'white' }}
            onClick={() => alertDetailsModalRef.current.open()}
          >
            Ver detalhes do pedido
          </ButtonLink>
        </div>
      </OfferPage.Header>

      <OfferPage.Content>
        <div className="container">
          <OfferPage.Body>
            <SubmittedOffersPage.OfferExpiration>
              <OfferPage.OfferExpirationBox>
                <span> Vencimento: </span>
                {expirationDate ? (
                  <>
                    <h5> {getDate(expirationDate)} </h5>
                    <p>de {getDate(expirationDate, true)}</p>
                  </>
                ) : (
                  <h5>- - -</h5>
                )}
              </OfferPage.OfferExpirationBox>

              <OfferPage.OfferExpirationText>
                Data de vencimento <br /> do seu orçamento
              </OfferPage.OfferExpirationText>
            </SubmittedOffersPage.OfferExpiration>

            <OfferPage.Supplier>
              <figure>
                {user?.avatar_url ? (
                  <img style={{ width: '100%' }} src={user?.avatar_url} alt={user?.company_name} />
                ) : (
                  <Image />
                )}
              </figure>
              <p>Orçamento de: {user?.company_name}</p>
            </OfferPage.Supplier>

            <OfferPage.ItemsList>
              {offerItems.map((item, index) => (
                <SubmittedOfferItemDetails key={item.id} item={item} index={index} />
              ))}
            </OfferPage.ItemsList>

            {files?.length > 0 ? (
              <OfferPage.Files>
                <h3>Arquivos</h3>

                {files.map(({ id, url }) => {
                  const splitUrl = url.split('/');
                  const [, ...name] = splitUrl[splitUrl.length - 1].split('-');
                  const fileName = name.join('-');

                  return (
                    <li key={id} style={{ padding: '1rem' }}>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {fileName}
                      </a>
                    </li>
                  );
                })}
              </OfferPage.Files>
            ) : null}
          </OfferPage.Body>
        </div>
      </OfferPage.Content>

      <Modal ref={alertDetailsModalRef}>
        <SubmittedOffersPage.StyledAlertDetailsModal>
          <h2 className="txt--secondary margin--bottom-1">{alert?.name}</h2>
          <p>Data estimada de entrega: {alert?.delivery_date}</p>

          <div className="alerts__fieldset">
            <span className="fieldset__title">Detalhes da oportunidade</span>
            <span className="fieldset__description" />
            <p style={{ whiteSpace: 'pre-wrap' }}>{alert?.description}</p>
          </div>
        </SubmittedOffersPage.StyledAlertDetailsModal>
      </Modal>
    </SupplierPage>
  );
}
