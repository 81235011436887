import styled, { keyframes } from 'styled-components';

const spin = keyframes({
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
});

export const Spinner = styled.div`
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #000;
    animation: ${spin} 1s linear infinite;
`;

export const InvoiceSubmitForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin: 0.5rem 0;
    }
`;
