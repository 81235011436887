import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';

import { AuthContainer } from './styles';
import closeIcon from '../../assets/icons/menu-close-icon.svg';
import eyeIcon from '../../assets/icons/eye.png';
import loginGraphisms from '../../assets/images/auth_pages/login-modal-graphisms.svg';

const ModalAuth = forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [, setMessage] = useState();
    const [modalType, setModalType] = useState();
    const [passwordBuyer, setPasswordBuyer] = useState();
    const [isVisibleBuyer, setIsVisibleBuyer] = useState(false);

    const { signIn } = useAuthContext();

    useEffect(() => {
        return () => (document.querySelector('body').style.overflow = 'inherit');
    }, []);

    useImperativeHandle(ref, () => ({
        open(modalType) {
            setModalType(modalType);
            setIsOpen(true);
            document.querySelector('body').style.overflow = 'hidden';
        },

        close() {
            setIsOpen(close);
            document.querySelector('body').style.overflow = 'inherit';
        },
    }));

    function close() {
        setIsOpen(false);
        document.querySelector('body').style.overflow = 'inherit';
    }

    function handleLogin(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        signIn({ email: data.get('email'), password: data.get('password') }).catch(err =>
            setMessage(err.message)
        );
    }

    return (
        <Fragment>
            {isOpen && (
                <AuthContainer>
                    {/* Register modal */}
                    {modalType && modalType === 'register' ? (
                        <AuthContainer.Grid>
                            <AuthContainer.Center>
                                <h3> Crie sua conta </h3>
                                <p>
                                    {' '}
                                    Gerencie pedidos de eventos, orçamentos e dados pessoais na sua
                                    área exclusiva{' '}
                                </p>

                                <AuthContainer.Hr />

                                <p className="txt--white"> Já possui uma conta? </p>
                                <button className="btn btn--slim" type="button">
                                    <Link to="/login"> Acesse sua conta </Link>
                                </button>

                                <AuthContainer.CloseRegisterModal onClick={close}>
                                    <img src={closeIcon} alt="Fechar Modal" />
                                    <span> Fechar </span>
                                </AuthContainer.CloseRegisterModal>
                            </AuthContainer.Center>

                            <AuthContainer.Box>
                                <AuthContainer.LoginGraphisms src={loginGraphisms} />
                                <div>
                                    <h3> Comprador </h3>

                                    <p>
                                        {' '}
                                        Peça orçamentos para nossa comunidade de fornecedores,
                                        gerencie orçamentos recebidos e monte seu evento{' '}
                                    </p>

                                    <button className="btn">
                                        <Link to="/cadastro"> Criar conta comprador </Link>
                                    </button>
                                </div>
                            </AuthContainer.Box>

                            <AuthContainer.Box>
                                <AuthContainer.LoginGraphisms src={loginGraphisms} />
                                <div>
                                    <h3> Fornecedor </h3>

                                    <p>
                                        {' '}
                                        Receba alertas sobre demandas de serviços para eventos,
                                        envie orçamentos para clientes e gerencie suas vendas{' '}
                                    </p>

                                    <button className="btn">
                                        <Link to="/fornecedor/cadastro">
                                            {' '}
                                            Criar conta fornecedor{' '}
                                        </Link>
                                    </button>
                                </div>
                            </AuthContainer.Box>
                        </AuthContainer.Grid>
                    ) : (
                        // Login Modal
                        <AuthContainer.Login>
                            <AuthContainer.CloseLoginModal onClick={close}>
                                <img src={closeIcon} alt="Fechar Modal" />
                                <span> Fechar </span>
                            </AuthContainer.CloseLoginModal>

                            <div className="container">
                                <AuthContainer.Title>Acesse sua conta</AuthContainer.Title>
                                <AuthContainer.Text>
                                    Gerencie pedidos de eventos, orçamentos e dados pessoais na sua
                                    área exclusiva
                                </AuthContainer.Text>
                            </div>

                            <AuthContainer.LoginBox>
                                <AuthContainer.LoginGraphisms src={loginGraphisms} />

                                <form onSubmit={handleLogin}>
                                    <h3> Login </h3>

                                    <div className="input__field">
                                        <input
                                            type="email"
                                            className="input"
                                            name="email"
                                            placeholder="E-mail"
                                        />
                                        <input
                                            type={isVisibleBuyer ? 'text' : 'password'}
                                            className="input"
                                            name="password"
                                            placeholder="Senha"
                                            value={passwordBuyer}
                                            onInput={event => setPasswordBuyer(event.target.value)}
                                        />
                                        <div
                                            className={`password__visible ${
                                                isVisibleBuyer ? 'visible' : 'unvisible'
                                            }`}
                                            onClick={() => setIsVisibleBuyer(!isVisibleBuyer)}
                                        >
                                            <img src={eyeIcon} alt="" />
                                        </div>
                                    </div>

                                    <Link to="/recuperar-senha"> Esqueci minha senha </Link>

                                    <button className="btn btn--fullwidth" type="submit">
                                        <span> Entrar </span>
                                    </button>

                                    <AuthContainer.Hr />

                                    <p className="txt--white"> Ainda não possui uma conta? </p>

                                    <button className="btn btn--slim btn--fullwidth" type="button">
                                        <Link to="/cadastro"> Crie sua conta grátis </Link>
                                    </button>
                                </form>
                            </AuthContainer.LoginBox>
                        </AuthContainer.Login>
                    )}
                </AuthContainer>
            )}
        </Fragment>
    );
});

export default ModalAuth;
