import React from 'react';
import styled from 'styled-components';

export const ButtonIconOnly = ({label, ...props}) => {
  return(
    <StyledButton
      label={label}
      {...props}
    >
      {props.children}
      {
        label && (
          <span>{label}</span>
        )
      }
    </StyledButton>
  )
}

const StyledButton = styled.button `
  /* Display & Box Model */
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  
  /* Colors */
  background-color: transparent;

  /* Position */
  position: relative;

  /* Text */
  font-size: 1rem;
  
  /* Others */
  border: none;
  cursor: pointer;

  span{
    display: none;
  }
  
  :hover{
    span{
      /* Display & Box Model */
      content: "${props => props.label }";
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${props => props.label ? "0.5rem 1rem" : ""};
      
      /* Colors */
      background-color: var(--color-gray);

      /* Position */
      position: absolute;
      left: 3rem;
      z-index: 9;

      /* Text */
      font-size: 0.8rem;

      /* Others */
      border: 1px solid var(--color-border);
      border-radius: 3px;

      ::before{
        content: '';
        width: 1rem;
        height: 1rem;
        position: absolute;
        background: var(--color-gray);
        left: -1rem;
        clip-path: polygon(30% 50%, 100% 0, 100% 100%, 0 30%);
      }
    }
  }
`;