import React, { useEffect, useState, Fragment, useRef } from 'react';
import Modal from '../../../Components/Modal';
import { ProposalPage } from './styles';


function OldProposalItem({ item, index, selectItem, isProposalExpired }){

    const [ itemFinalPrice, setItemFinalPrice ] = useState();
    const [ quantity, setQuantity ] = useState( parseInt(item.quantity) );
    const [ currentPhoto, setCurrentPhoto ] = useState( 0 );
    const [ thisItemIsAdded, setThisItemIsAdded ] = useState( false );

    const modalRef = useRef();
    const itemImage = item.photos && item.photos.length > 0;

    const itemCalc = {
        price: parseFloat(item.buyer_price),
        quantity: quantity,
        additional: item.additional_fee_name && item.final_additional_fee ? parseFloat( item.final_additional_fee ) : false
    }

    /*============================================
    Change item final price after quantity changes
    =============================================*/

    useEffect( () => {

        setItemFinalPrice(calculateSubtotal(itemCalc));
        console.log(item)

    },[quantity] );

    /*===================================
    Add and remove item from shopping cart
    ====================================*/

    function addAndRemoveItem( item, index ){

        let cart = document.querySelector("#cart");

        cart.classList.add("cart--animation");

        setTimeout( () => {
            cart.classList.remove("cart--animation");
        }, 1000 );

        setThisItemIsAdded( !thisItemIsAdded );

        if( item.final_additional_fee ){
            item.final_buyer_price = itemFinalPrice - parseFloat(item.final_additional_fee);
        } else {
            item.final_buyer_price = itemFinalPrice;
        }
        item.quantity = quantity;

        selectItem(item, index);

    }

    /*===================================
        Calculate item subtotal
    ====================================*/

    function itemHasAdditionalFee(){

        return item.additional_fee_name && item.final_additional_fee;

    }

    /*===================================
        Calculate item subtotal
    ====================================*/

    function calculateSubtotal(obj){

        let subtotal = parseFloat(obj.price) * parseInt(obj.quantity);
        
        if( obj.additional ){

            let total = subtotal + obj.additional;
            return parseFloat(total);

        } else {

            return subtotal;

        }

    }

    /*===================================
    Returns value in Brazilian currency
    ====================================*/

    function toBraCurrency(value){
        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
    }

    /*===================================
        Change item quantity
    ====================================*/

    function changeQuantity(value){

        let newQuantity = quantity;

        if( value === "mais" && quantity < parseInt(item.max_quantity) ){

            newQuantity += 1;
            setQuantity(newQuantity);

        } else if( value === "menos" && quantity > parseInt(item.min_quantity) ){

            newQuantity -= 1;
            setQuantity(newQuantity);

        }
        
    }

    function inputChangeQuantity(value) {

        if( value > parseInt(item.max_quantity) ){

            setQuantity( parseInt(item.max_quantity) );

        } else if ( value < parseInt(item.min_quantity) ){

            setQuantity( parseInt(item.min_quantity) );

        } else {

            setQuantity( parseInt(value) );

        }

    }

    /*===================================
        Change current image
    ====================================*/

    function photosSlider(value){

        let newCurrentPhoto = currentPhoto;

        if( value === "next" && currentPhoto < parseInt(item.photos.length) - 1 ){

            newCurrentPhoto += 1;
            setCurrentPhoto(newCurrentPhoto);

        } else if( value === "prev" && currentPhoto > 0 ){

            newCurrentPhoto -= 1;
            setCurrentPhoto(newCurrentPhoto);

        }

    }

    /*===================================
            Add item to shopping cart
    ====================================*/

    return(
        <ProposalPage.Item className={[`${ itemImage && "item--photo" }`, `${ thisItemIsAdded ? "item--added" : "" }` ]}>
            {
                item.photos && item.photos.length > 0 ? (
                    <ProposalPage.ItemPhotos>

                        <button onClick={ () => photosSlider("prev") }>
                            <span> Anterior </span>
                        </button>

                        <img src={ item.photos[currentPhoto].url } alt={ item.name } onClick={ () => modalRef.current.open() } />

                        <button onClick={ () => photosSlider("next") }>
                            <span> Próximo </span>
                        </button>

                    </ProposalPage.ItemPhotos>
                ) : ""
            }

            <ProposalPage.ItemTexts >
                <h3> 
                    { item.name }
                    <br />
                    { item.proposal_supplier_name && <span> Fornecido por: { item.proposal_supplier_name ?? item.user_name } </span> }
                </h3>

                <ProposalPage.ItemDescription cols="30" rows="5" disabled defaultValue={ item.description } />
                {
                    item.observation && (
                        <Fragment>
                            <span> Observações </span>
                            <ProposalPage.ItemDescription cols="30" rows="3" disabled defaultValue={ item.observation } />
                        </Fragment >
                    )
                }

                <ProposalPage.Buttons >
                    {
                        !thisItemIsAdded ? (
                            <button
                                className="add"
                                onClick={ () => addAndRemoveItem( item, index ) }
                                disabled={ isProposalExpired ? true : false }
                            >
                                <span> Adicionar ao carrinho </span>
                            </button>
                        ) : (

                            <button className="remove" onClick={ () => addAndRemoveItem( item, index ) }>
                                <span> remover do carrinho </span>
                            </button>
                        )
                    }
                </ProposalPage.Buttons>

                <ProposalPage.Prices >
                    <ProposalPage.ItemNumber> 
                        <h6> valor por item: </h6>
                        <span>
                            { toBraCurrency( parseFloat( item.buyer_price ) ) }
                        </span>
                    </ProposalPage.ItemNumber>

                    <ProposalPage.ItemNumber >
                        <h6> Quantidade: </h6>

                        {
                            item.editable && !thisItemIsAdded ? (
                                <div className="item__quantity" >
                                    <button type="button" onClick={ () => changeQuantity("menos") }>
                                        <span> - </span>
                                    </button>

                                    <input
                                        type="number"
                                        value={ quantity }
                                        onInput={ (event) => inputChangeQuantity(event.target.value) }
                                        className="input"
                                        />

                                    <span className="item__quantity--print"> { quantity } </span>

                                    <button type="button" onClick={ () => changeQuantity("mais") }>
                                        <span> + </span>
                                    </button>
                                </div>
                            ) : (
                                <span> { quantity } </span>
                            )
                        }
                    </ProposalPage.ItemNumber>

                    {
                        itemHasAdditionalFee() && (
                            <ProposalPage.ItemNumber>
                                <h6> { item.additional_fee_name }: </h6> 
                                <span> { toBraCurrency( parseFloat( item.final_additional_fee ) ) } </span>
                            </ProposalPage.ItemNumber>
                        )
                    }

                    <ProposalPage.ItemNumber className=" subtotal ">
                        <h6> subtotal: </h6>
                        <span> { toBraCurrency( calculateSubtotal( itemCalc ) ) } </span>
                    </ProposalPage.ItemNumber>

                    <div>
                    </div>
                </ProposalPage.Prices>
            </ProposalPage.ItemTexts >

            {
                item.photos && item.photos.length > 0 && (
                    <Modal title='Fotos' ref={ modalRef } >
                        <ProposalPage.ModalImage >
                            <button onClick={ () => photosSlider("prev") }>
                                <span> Anterior </span>
                            </button>

                            <img src={ item.photos[currentPhoto].url } alt={ item.name } />

                            <button onClick={ () => photosSlider("next") }>
                                <span> Próximo </span>
                            </button>
                        </ProposalPage.ModalImage>
                    </Modal>
                )
            }
        </ProposalPage.Item>
    )
}

export default OldProposalItem;