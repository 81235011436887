import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SupplierMenu from '../Menu';
import FeedbackChannel from '../FeedbackChannel';
import { Load } from '../../../Components/Loader/styles';
import HeaderPage from '../../../Components/HeaderPage';
// Styles & Image
import { SupplierPage } from '../styles';
import Warning from '../../../assets/icons/warning.svg';
import Cancelled from '../../../assets/icons/Reject.svg';
import Invoice from '../../../assets/icons/invoice.svg';
import { ButtonLink } from '../../../Components/common/Buttons/ButtonLink';
import Modal from '../../../Components/Modal';
import { ContractDetailsModalContent } from './ContractDetailsModalContent';

function SupplierInvoices() {
  const [sales, setSales] = useState(null);
  const [selectedSale, setSelectedSale] = useState(null);

  const modalRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);

    axios({
      method: 'get',
      url: `/api/supplier/contracts/`,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(res => {
        setSales(res.data);
      })
      .catch(err => console.log(err));
  }, []);

  /*================================
                Format date
    =================================*/

  function formatDate(date) {
    return new Date(date).toLocaleDateString('pt-br');
  }

  /*===================================
    Returns value in Brazilian currency
    ====================================*/

  function toBraCurrency(value) {
    return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
  }

  if (!sales) {
    return (
      <SupplierPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </SupplierPage>
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <div className="container">
        <HeaderPage title="Suas vendas e notas fiscais" />
        <p className="txt--center">
          Você pode acompanhar seus pagamentos e cadastrar suas contas para recebimento na sua
          Carteira.{' '}
          <Link style={{ color: '#1C5AC3' }} to="/fornecedor/carteira">
            Acessar carteira
          </Link>
        </p>

        {sales.length > 0 ? (
          sales.map(sale => {
            const request = sale.invoice_requests[0];
            const isContractCancelled = sale.status_id === 8;
            const isPending = !isContractCancelled && sale.invoices.length === 0;
            const reviewNeeded =
              !isContractCancelled &&
              sale.invoices.some(i => i.rejected_at) &&
              sale.invoices.every(i => !i.approved_at);
            const isOk = !isContractCancelled && !isPending && !reviewNeeded;
            const hasSubmittedFiles = sale.invoices.length > 0;

            return (
              <SupplierPage.Order key={sale.id}>
                <div style={{ display: 'flex' }}>
                  <SupplierPage.OrderTitle>
                    Evento #{sale.sale_code}
                    <ButtonLink
                      onClick={() => {
                        setSelectedSale(sale);
                        modalRef.current.open();
                      }}
                    >
                      Ver detalhes da venda
                    </ButtonLink>
                  </SupplierPage.OrderTitle>
                </div>

                <SupplierPage.OrderInfos>
                  <SupplierPage.OrderInfo>
                    <h4 className="txt--secondary">{formatDate(sale.delivery_estimated_at)}</h4>
                    <p> Data de entrega do serviço </p>
                  </SupplierPage.OrderInfo>

                  <SupplierPage.OrderInfo>
                    <h4 className="txt--secondary"> #{sale.sale_code} </h4>
                    <p> ID do evento </p>
                  </SupplierPage.OrderInfo>

                  <SupplierPage.OrderInfo>
                    <h4 className="txt--primary">
                      {toBraCurrency(sale.total_supplier_sale_price)}
                    </h4>
                    <p> Valor a ser recebido </p>
                  </SupplierPage.OrderInfo>
                </SupplierPage.OrderInfos>

                {request ? (
                  <SupplierPage.OrderWarning>
                    {isContractCancelled ? (
                      <>
                        <img src={Cancelled} alt="aviso" />
                        <p className="txt--primary txt--center txt--bold txt--secondary">
                          Venda cancelada
                        </p>
                        {hasSubmittedFiles ? (
                          <button className="btn" style={{ display: 'block' }}>
                            <Link
                              to={{
                                pathname: `/fornecedor/confirmacoes-de-venda/${sale.id}`,
                              }}
                            >
                              Anexar NF cancelada
                            </Link>
                          </button>
                        ) : null}
                      </>
                    ) : null}
                    {reviewNeeded ? (
                      <>
                        <img src={Warning} alt="aviso" />
                        <p className="txt--red txt--bold">Sua nota fiscal precisa de alterações!</p>
                        <br />
                        <button className="btn">
                          <Link
                            to={{
                              pathname: `/fornecedor/confirmacoes-de-venda/${sale.id}`,
                            }}
                          >
                            Enviar nova nota fiscal
                          </Link>
                        </button>
                      </>
                    ) : null}
                    {isPending ? (
                      <>
                        <img src={Warning} alt="aviso" />
                        <p className="txt--red txt--bold">
                          Você ainda não adicionou a nota fiscal a esse serviço
                        </p>
                        <button className="btn" style={{ display: 'block' }}>
                          <Link
                            to={{
                              pathname: `/fornecedor/confirmacoes-de-venda/${sale.id}`,
                            }}
                          >
                            Anexar nota fiscal
                          </Link>
                        </button>
                      </>
                    ) : null}
                    {isOk ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                          marginTop: '1rem',
                        }}
                      >
                        <img src={Invoice} alt="ok" />
                        <p className="txt--primary txt--bold">Nota fiscal enviada!</p>
                        <button className="btn" style={{ display: 'block' }}>
                          <Link
                            to={{
                              pathname: `/fornecedor/confirmacoes-de-venda/${sale.id}`,
                            }}
                          >
                            Ver arquivos enviados
                          </Link>
                        </button>
                      </div>
                    ) : null}
                  </SupplierPage.OrderWarning>
                ) : (
                  <p className="txt--center margin--top-1 margin--bottom-1">
                    Status da nota fiscal indisponível para esta venda. <br /> Por favor, siga as
                    instruções recebidas no email de confirmação.
                  </p>
                )}
              </SupplierPage.Order>
            );
          })
        ) : (
          <p style={{ textAlign: 'center' }}>Você ainda não tem nenhuma confirmação de venda.</p>
        )}
      </div>

      <Modal modalSize="large" title="Detalhes da venda" ref={modalRef}>
        <ContractDetailsModalContent contract={selectedSale} />
      </Modal>
    </SupplierPage>
  );
}

export default SupplierInvoices;
