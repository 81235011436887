import React, { useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import HeaderPage from '../../../../Components/HeaderPage';
import { AccountPage } from '../styles';

function SupplierAccountDetails(props){

    const [ canSubmit, setCanSubmit ] = useState( false );
    const { user, updateUserData } = useAuthContext();
    const history = useHistory();

    function validateForm(){
        let form = document.querySelector("#terms__form"),
            inputs = Array.from(form.querySelectorAll("input")),
            emptyInputs = inputs.filter( input => input.type !== "checkbox" && input.value == "" );

        if( emptyInputs.length < 1 && inputs.find( input => input.type === "checkbox" && input.checked )){
            setCanSubmit( true );
        } else {
            setCanSubmit( false );
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
    
        const data = new FormData(event.target);

        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: '/api/usuario/termos-de-uso',
            data: {
                id: user.id,
                accept: data.get('accept')
            }
        })
        .then(res => {
            if (res.status == 200) {
                updateUserData({ accept_terms_at: res.data.new_terms });
                history.push('/fornecedor/alertas');
            }
        })
        .catch(err => console.log(err.message));
    }

    return(
        <AccountPage className="supplier">
            <AccountPage.Sidebar />

            <AccountPage.Container >
                <div className="container">
                    <HeaderPage 
                        title="Pedidos de orçamento"
                        subtitle="Pedidos de orçamentos de serviços para as categorias em que você atua aparecerão aqui. Quanto mais orçamentos você mandar, maior suas chances de fechar negócio!"
                        // description="Texto tooltip"
                    />

                    <AccountPage.Content>
                        <form onSubmit={handleSubmit} name="footer_contact_form" id="terms__form">
                            <h3 className="modal__title"> Termos de Uso </h3>
                            <p> Atenção: Fizemos alterações recentes em nossos Termos de Uso. Por favor revise os termos para visualizar pedidos de orçamento disponíveis para você.</p>

                            <div className="input__field input__field--checkbox">
                                <label htmlFor="" className="checkbox__label">
                                    Li e aceito os <Link to="/termos" > Termos de uso </Link> da Celebrar
                                </label>
                                <input type="checkbox" name="accept" className="checkbox" id="" onChange={ () => validateForm() }/>
                            </div>

                            <button className="btn" disabled={ !canSubmit ? true : false }>
                                <span> Salvar alterações </span>
                            </button>
                        </form>
                     </AccountPage.Content>
                </div>
            </AccountPage.Container>
        </AccountPage>
    )
}

export default SupplierAccountDetails;