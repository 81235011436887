import styled from "styled-components";

export const AuthPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
`;

/*================================
    Register Account Type Page
=================================*/

AuthPage.AccountTypePage = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    
    /* Text */
    text-align: center;
    
    @media ( min-width: 800px ){
        /* Colors */
        background-image: radial-gradient( ellipse at top, #700E21, var(--color-black) 40% );
    }
`;

AuthPage.AccountTypePageTitle = styled.h1 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 1rem;

    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 2rem;
    font-weight: 700;

    @media (min-width: 800px){
        /* Display & Box Model */
        margin-bottom: 2rem;

        /* Text */
        font-size: 3rem;
    }
`;

AuthPage.AccountTypePageSubTitle = styled.h2 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 1rem;

    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 1.2rem;
    font-weight: 300;

    @media (min-width: 800px){
        /* Display & Box Model */
        margin-bottom: 2rem;

        /* Text */
        font-size: 1.4rem;
    }
`;

AuthPage.AccountTypeGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;

    @media( min-width: 800px ){
        /* Display & Box Model */
        grid-gap: 4rem;
        flex-direction: row;
    }
`;

AuthPage.AccountTypeCard = styled.div `
    /* Display & Box Model */
    width: 18rem;
    height: 18rem;
    padding: 1rem;

    /* Colors */
    color: var(--color-white);

    /* Text */
    text-align: center;

    /* Others */
    border: solid 0.1rem var(--color-secondary);
    border-radius: var(--radius);

    h3 {
        /* Display & Box Model */
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;

        /* Text */
        font-size: 2rem;
        font-weight: 700;

        /* Others */
        border-bottom: solid 0.1rem var(--color-secondary);
    }

    p{
        /* Display & Box Model */
        margin-bottom: 1.5rem;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 20rem;
        padding: 2rem;

        /* Others */
        transform: skewX(-8deg);

        h3, p, span {
            /* Others */
            transform: skewX(8deg);
        }
    }
`;