import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LVStore } from './styles';
// Images
import learningVillageLogo from '../../assets/images/learning_village/learning-village-logo.png';
import LearningVillageItems from './LearningVillageItems';
import LearningVillageCart from './LarningVillageCart';
import { Fragment } from 'react';


function LearningVillageStore(){

    const [ proposal, setProposal ] = useState();
    const [ proposalUrl, setProposalUrl ] = useState();
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ isAuditorium, setIsAuditorium ] = useState();

    useEffect( () => {

        if( proposalUrl ){
            Axios.defaults.headers.post["Acess-Control-Allow-Origin"] = "*";
            Axios.post(`/api/proposal/${ proposalUrl }`)
            .then( res => {
                
                setProposal( JSON.parse( res.data.proposal.json_object ) );
    
            });

            setIsAuditorium( proposalUrl.includes("arena") );
    
            let itemsStoraged = JSON.parse( sessionStorage.getItem("items") );
            if( itemsStoraged && itemsStoraged.length > 0 ) {
                setSelectedItems( itemsStoraged );
            }
        }

    }, [proposalUrl] );

    useEffect( () => {

        sessionStorage.setItem("items", JSON.stringify(selectedItems));

    }, [selectedItems] );

/*=====================================================
    Functions to add & remove item from shopping cart
======================================================*/

    function addItemToCart( item ){

        let newItemsList = [...selectedItems, item];
        setSelectedItems( newItemsList );

    }

    function removeItemFromCart( itemId ) {

        let newItemsList = selectedItems.filter( selectedItem => selectedItem.id !== itemId );
        setSelectedItems( newItemsList );

    }

/*=====================================================
                Scroll smooth
======================================================*/

    function scrollSectionAnimation(sectionId){

        let offsetY = 0,
            offsetSection = document.querySelector(`#${sectionId}`).offsetTop;

        const scrollAnimationInterval = setInterval( () => {

            window.scrollTo( 0, offsetY);
            
            offsetY < offsetSection ? offsetY += 30 : clearInterval(scrollAnimationInterval);

        }, 10 );

    }

    return(
        <LVStore >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap" rel="stylesheet" />

            {
                !proposalUrl && (
                    <LVStore.EventPlace >
                        <LVStore.EventPlaceHeader >
                            <LVStore.EventPlaceLogo >
                                <img src={ learningVillageLogo } alt="Learning Village" />

                                <figcaption>
                                    EVENTS WEB STORE
                                </figcaption>
                            </LVStore.EventPlaceLogo>

                            <h1> LOJA VIRTUAL DE EVENTOS DO LEARNING VILLAGE </h1>
                            <h2> SELECIONE ABAIXO O ESPAÇO QUE VOCÊ RESERVOU </h2>
                        </LVStore.EventPlaceHeader>

                        <LVStore.EventPlaceGrid >
                            <LVStore.EventPlaceSection >
                                <LVStore.EventPlaceContent >
                                    <h2> SALA DE REUNIÃO </h2>
                                    <p> 8º, 9º e 10º andar </p>

                                    <LVStore.Button className="bgDark" onClick={ () => setProposalUrl("webstore-lv-salas-de-reuniao") }>
                                        <span> Selecionar </span>
                                    </LVStore.Button>
                                </LVStore.EventPlaceContent>
                            </LVStore.EventPlaceSection>

                            <LVStore.EventPlaceSection >
                                <LVStore.EventPlaceContent >
                                    <h2> AUDITÓRIO </h2>
                                    <p> 11º andar </p>

                                    <LVStore.Button className="bgDark" onClick={ () => setProposalUrl("webstore-lv-arena") }>
                                        <span> Selecionar </span>
                                    </LVStore.Button>
                                </LVStore.EventPlaceContent>
                            </LVStore.EventPlaceSection>
                        </LVStore.EventPlaceGrid>
                    </LVStore.EventPlace>
                )
            }

            {
                proposalUrl && (
                    <Fragment >
                        <LVStore.Menu >
                            <LVStore.MenuNavBg />
                            <div className="container">
                                <LVStore.MenuContainer >
                                    <LVStore.MenuLogo >
                                        <img src={ learningVillageLogo } alt="Learning Village" />
            
                                        <LVStore.MenuTitle >
                                            EVENTS <br />
                                            WEB <br />
                                            STORE <br />
                                        </LVStore.MenuTitle>
                                    </LVStore.MenuLogo>
            
                                    <LVStore.MenuNav >
                                        {
                                            proposal && (
                                                proposal.sections.map( section => {
                                                    return(
                                                        <span onClick={ () => scrollSectionAnimation(section.id) }> { section.name } </span>
                                                    )
                                                })
                                            )
                                        }
                                    </LVStore.MenuNav>
            
                                </LVStore.MenuContainer>
                            </div>
            
                            <LearningVillageCart
                                selectedItems={ selectedItems }
                                removeItemFromCart={ removeItemFromCart }
                                isAuditorium={ isAuditorium }
                            />
                        </LVStore.Menu>
            
                        <LVStore.Banner className={ isAuditorium ? "auditorium" : "room" }>
                            <div className="container">
                                <LVStore.BannerContainer >
                                    <LVStore.BannerTitle >
                                        VOCÊ ESTÁ NA LOJA VIRTUAL DE EVENTOS DO HUB LEARNING VILLAGE
                                    </LVStore.BannerTitle>
            
                                    <LVStore.BannerText >
                                    Após a confirmação de reserva do seu evento no LV, selecione aqui os serviços adicionais que você precisa, finalize o pedido e a gente faz acontecer. Tudo em um só lugar.
                                    </LVStore.BannerText>
            
                                    {/* <LVStore.Button className="bgDark blue">
                                        <span> Chamada botão </span>
                                    </LVStore.Button> */}
                                </LVStore.BannerContainer>
                            </div>
                        </LVStore.Banner>
            
                        {
                            proposal && (
                                proposal.sections.map( section => {
                                    return(
                                        <LVStore.Section key={ section.id } id={ `${section.id}` }>
                                            <div className="container">
                                                <LVStore.SectionTitle > { section.name } </LVStore.SectionTitle>
            
                                                <LVStore.SectionContainer>
                                                    {
                                                        section.items.map( item => {
                                                            return(
                                                                <LearningVillageItems 
                                                                    key={ item.id }
                                                                    item={ item }
                                                                    addItemToCart={ addItemToCart }
                                                                    removeItemFromCart={ removeItemFromCart }
                                                                    selectedItems={ selectedItems }
                                                                />
                                                            )
                                                        })
                                                    }
                                                </LVStore.SectionContainer>
                                            </div>
                                        </LVStore.Section>
                                    )
                                })
                            )
                        }
                    </Fragment >
                )
            }
        </LVStore>
    )
}

export default LearningVillageStore;