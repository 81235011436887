import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import { Button } from '../../../Components/common/Buttons/Button';
import { FormTxtarea } from '../../../Components/common/FormTextarea';
import { FormTxtInput } from '../../../Components/common/FormTextInput';
import Modal from '../../../Components/Modal';
import { RecommendationsStepStyled } from './styles';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import Alert from '../../../Components/Alert';

export function RegistrationRecommendationsStep({ handleContinueRegistration }) {
  const requestSubmittedModalRef = useRef();
  const serverErrorAlertRef = useRef();
  const { updateUserData } = useAuthContext();

  const {
    run: runSendRecommendation,
    isLoading: isSendingRecommendation,
    isError: isErrorSendRecommendation,
  } = useAsync();

  function handleSendRecommendation(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    runSendRecommendation(
      axios
        .post('/api/supplier/external-review-requests', {
          contact_name: formData.get('name'),
          contact_email: formData.get('email'),
          service_name: formData.get('service'),
          service_description: formData.get('description'),
          date: formData.get('date'),
        })
        .then(() => requestSubmittedModalRef.current.open())
    ).catch(err => console.log(err.message));
  }

  const {
    run: runFinishRegistration,
    isLoading: isFinishingRegister,
    isError: isErrorFinishRegister,
  } = useAsync();

  function handleFinishRegistration() {
    runFinishRegistration(
      axios.get('/api/supplier/finish-register').then(({ data }) => {
        updateUserData(data.updatedUser);
        handleContinueRegistration();
      })
    ).catch(err => console.log(err.message));
  }

  useEffect(() => {
    if (isErrorFinishRegister || isErrorSendRecommendation) {
      serverErrorAlertRef.current.open();
    }
  }, [isErrorFinishRegister, isErrorSendRecommendation]);

  return (
    <RecommendationsStepStyled>
      <div>
        <form onSubmit={handleSendRecommendation} id="recommendation-form">
          <h3>Pedido de avaliação</h3>
          <p>
            Convide pessoas que já conhecem o seu trabalho para te avaliarem na plataforma da
            Celebrar.
          </p>
          <div className="inputValues">
            <FormTxtInput
              name="service"
              label="Qual serviço você prestou neste evento?"
              type="text"
              placeholder="Descreva  o serviço prestado"
              icon="credit-card"
              required
            />
            <FormTxtInput
              name="date"
              label="Data que o evento ocorreu"
              type="date"
              placeholder="Data do evento"
              icon="home"
              required
            />
            <FormTxtInput
              name="name"
              label="Nome do contato que você vai pedir a avaliação"
              type="text"
              placeholder="Nome do contato"
              icon="home"
              required
            />
            <FormTxtInput
              name="email"
              label="E-mail do contato"
              type="email"
              placeholder="E-mail do contato"
              icon="credit-card"
              required
            />
            <FormTxtarea
              name="description"
              label="Descreva com mais detalhes como foi o serviço que você prestou"
              placeholder="Descreva a entrega do serviço*"
              icon="credit-card"
              required
            />
          </div>
          <div className="buttons">
            <Button type="submit" disabled={isSendingRecommendation || isFinishingRegister}>
              Enviar pedido e finalizar cadastro
              {isSendingRecommendation ? (
                <FeatherIcon icon="loader" className="spinner" size={16} color="white" />
              ) : (
                <FeatherIcon icon="arrow-right" className="icon" size={16} color="white" />
              )}
            </Button>
            <Button
              variant="secondary"
              type="button"
              onClick={handleFinishRegistration}
              disabled={isSendingRecommendation || isFinishingRegister}
            >
              Pular etapa e finalizar cadastro
              {isFinishingRegister ? (
                <FeatherIcon
                  icon="loader"
                  className="spinner"
                  size={16}
                  color="var(--color-primary-dark)"
                />
              ) : (
                <FeatherIcon
                  icon="arrow-right"
                  className="icon"
                  size={16}
                  color="var(--color-primary-dark)"
                />
              )}
            </Button>
          </div>
        </form>
      </div>

      <Modal title="Pedido de avaliação" ref={requestSubmittedModalRef}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h2 className="txt--black" style={{ marginBottom: '0.5rem' }}>
            Pedido de avaliação enviado com sucesso!
          </h2>

          <p style={{ marginBottom: '1rem' }}>
            Seu contato receberá um e-mail pedindo a opinião sobre os seus serviços.
          </p>

          <button
            className="btn btn--secondary"
            onClick={() => {
              document.getElementById('recommendation-form').reset();
              requestSubmittedModalRef.current.close();
              window.scrollTo(0, 0);
            }}
          >
            <span>Enviar mais um pedido</span>
          </button>

          <p className="txt--center margin--tb-1"> OU </p>

          <Button type="button" disabled={isFinishingRegister} onClick={handleFinishRegistration}>
            {isFinishingRegister ? (
              <FeatherIcon
                icon="loader"
                className="spinner"
                size={16}
                color="var(--color-primary-dark)"
              />
            ) : (
              'Finalizar cadastro'
            )}
          </Button>
        </div>
      </Modal>

      <Alert status="danger" ref={serverErrorAlertRef}>
        Ocorreu um erro.
      </Alert>
    </RecommendationsStepStyled>
  );
}
