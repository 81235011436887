import styled, { keyframes } from 'styled-components';

/*==============================
          Animations
================================*/

const PositionBtnsAnime = keyframes`
  from{
    opacity: 0;
    transform: translateY(2rem);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Item = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 4rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border: 1px solid var(--color-border);
`;

Item.Content = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

Item.Body = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 70%;
    flex-direction: row;

    &.fullwidth {
      width: 100%;
    }
  }
`;

Item.BodyGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;

  /* Position */
  position: relative;

  .readonly {
    border: none;
  }

  &.missing-item-description-error {
    textarea {
      border: 1px solid var(--color-red);
    }

    &::before {
      /* Display & Box Model */
      content: 'Preencha a descrição do item';

      /* Colors */
      background: var(--color-red);
      color: var(--color-white);

      /* Position */
      position: absolute;
      top: 100px;
      z-index: 9;
      padding: 6px 10px;
      left: 1rem;

      /* Text */
      font-size: 0.8rem;
      text-align: center;

      /* Others */
      border-radius: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 3rem;
      margin-left: -5px;
      top: 86px;
      border: 8px solid #000;
      border-color: transparent transparent var(--color-red) transparent;
    }

    @media (max-width: 800px) {
      &::before {
        top: 70px;
        left: 1rem;
      }

      &::after {
        top: 56px;
      }
    }
  }
`;

/*==============================
          Buttons
================================*/

Item.AddItemButton = styled.button`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 4rem auto 2rem;
  padding: 0.5rem 0;

  /* Colors */
  color: var(--color-blue-dark);
  background-color: transparent;

  /* Text */
  font-weight: 600;
  text-align: center;

  /* Others */
  border: 2px solid var(--color-blue-dark);
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
    transition: all 0.3s ease-out;
  }
`;

Item.SendOfferButton = styled.button`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto;
  padding: 1rem 0;

  /* Colors */
  color: var(--color-white);
  background-color: var(--color-primary-light);

  /* Text */
  font-weight: 600;
  text-align: center;

  /* Others */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    background-color: var(--color-primary-dark);
    transition: all 0.3s ease-out;
  }
`;

/*==============================
          Item prices
================================*/

Item.Prices = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 1rem 0;
  gap: 1rem;

  /* Position */
  position: relative;

  /* Others */
  border-left: 1px solid var(--color-border);
  cursor: pointer;

  p {
    padding: 0 1rem;

    :last-child {
      /* Display & Box Model */
      padding-top: 0.5rem;

      /* Colors */
      color: var(--color-purple-light);

      /* Text */
      font-weight: 600;

      /* Others */
      border-top: 1px solid var(--color-border);

      span {
        /* Text */
        font-weight: 600;
      }
    }
  }

  span {
    /* Display & Box Model */
    display: block;
    margin-bottom: 0.3rem;

    /* Text */
    font-size: 0.7rem;
    font-weight: 100;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 14rem;
  }

  &.missing-item-price-error {
    &::before {
      /* Display & Box Model */
      content: 'Preencha o preço do item';

      bottom: -75px;
      left: 0;
      width: 100px;
      position: absolute;

      padding: 6px 10px;
      border-radius: 10px;
      background: var(--color-red);
      color: var(--color-white);

      /* Text */
      font-size: 0.8rem;
      text-align: center;

      /* Others */
      border-radius: 10px;
    }

    &::after {
      /* Display & Box Model */
      content: '';
      margin-left: -5px;

      /* Position */
      position: absolute;
      left: 2rem;
      margin-left: -5px;
      bottom: -22px;
      transform: translateY(50%);
      border: 8px solid #000;
      border-color: transparent transparent var(--color-red) transparent;
      transform: translateY(50%);
    }

    @media (max-width: 800px) {
      padding-bottom: 3rem;

      &::before {
        bottom: 0px;
        margin-left: 1rem;
        width: fit-content;
      }

      &::after {
        bottom: 34px;
      }
    }
  }
`;

Item.Quantity = styled.div`
  /* Display & Box Model */
  display: flex;
  align-items: center;

  input {
    /* Display & Box Model */
    width: 4.5rem;
    padding: 0.5rem 1rem;

    /* Text */
    text-align: center;

    /* Others */
    border: 1px solid var(--color-border);
    -moz-appearance: textfield;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    /* Display & Box Model */
    width: 2rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Colors */
    background-color: transparent;

    /* Text */
    font-size: 1rem;

    /* Others */
    border: 1px solid var(--color-border);
    cursor: pointer;
  }
`;

Item.ModalPrices = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem auto;
  align-items: center;

  /* Others */
  border-bottom: 1px solid var(--color-border);

  @media (min-width: 800px) {
    height: 4.6rem;
    display: grid;
    grid-template-columns: 60% 40%;
    margin: 0;
  }
`;

Item.ModalPrices.Content = styled.div`
  /* Display & Box Model */
  width: 100%;

  /* Text */
  text-align: left;

  h6 {
    font-size: 0.9rem;
    font-weight: 700;

    span {
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: lowercase;
    }
  }

  p {
    font-size: 0.8rem;

    span {
      font-size: 0.8rem;
      text-transform: lowercase;
    }
  }
`;

Item.ModalPrices.Input = styled.div`
  /* Display & Box Model */
  width: 100%;
`;

Item.ModalPrices.Price = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 0 0 0 auto;

  /* Text */
  font-weight: 700;
  text-align: right;

  span {
    font-size: 0.8rem;
    font-weight: 400;
  }

  h4 {
    font-size: 2rem;
    color: var(--color-purple-light);
    margin-right: 1rem;
  }

  h5 {
    font-size: 1.2rem;
    margin-right: 1rem;
  }
`;

/*==============================
          Item images
================================*/

Item.Photos = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  /* Colors */
  background-color: var(--color-gray-light);

  /* Position */
  position: relative;

  /* Others */
  overflow: hidden;

  > button {
    /* Display & Box Model */
    display: flex;

    /* Colors & Backgorund */
    background-color: var(--color-green);

    /* Position */
    position: absolute;
    right: 0;
    z-index: 9;

    /* Others */
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    svg {
      width: 0.5rem;
    }

    :first-child {
      right: auto;
      left: 0;
      border-radius: 0;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  img {
    height: 100%;
    position: relative;
    cursor: pointer;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 30%;
    min-width: 15rem;
  }
`;

Item.PhotosBtn = styled.button`
  /* Display & Box Model */
  width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-color: var(--color-purple-light) !important;
  color: var(--color-white);

  /* Position */
  position: absolute;
  bottom: 0;

  /* Text */
  font-size: 0.8rem;

  /* Others */
  border: none;
  border-radius: 0 !important;
  cursor: pointer;
`;

Item.ModalPhotos = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(4, 1fr);
  }
`;

Item.ModalPhotos.Image = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 20rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* Position */
  position: relative;

  /* Others */
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.3s ease-out;

  ::after {
    /* Display & Box Model */
    content: '';
    width: 100%;

    /* Position */
    position: absolute;
    top: 0;

    /* Text */
    font-size: 0.8rem;
    transition: all 0.3s ease-out;
  }

  :first-child {
    ::after {
      /* Display & Box Model */
      content: 'Imagem de capa';
      width: 100%;
      padding: 0.5rem 0;

      /* Colors & Background*/
      background-color: rgba(5, 191, 163, 0.6);
      backdrop-filter: blur(5px);
      color: var(--color-white);

      /* Position */
      position: absolute;
      top: 0;

      /* Text */
      font-size: 0.8rem;
    }
  }

  &.selected {
    /* Others */
    border: 1px dashed var(--color-purple-light);
    transition: all 0.3s ease-out;

    ::after {
      /* Display & Box Model */
      content: '';
      width: 100%;
      height: 100%;

      /* Colors & Backgorund */
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(5px);

      /* Position */
      position: absolute;
      z-index: 9;

      /* Others */
      cursor: default;
      transition: all 0.3s ease-out;
    }
  }

  img {
    /* Display & Box Model */
    height: 100%;

    /* Position */
    position: absolute;

    /* Others */
    cursor: pointer;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 10rem;
    height: 12.9rem;
  }
`;

Item.ModalPhotos.PositionBtns = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: absolute;
  bottom: 0;

  /* Others */
  border: 1px solid var(--color-border);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: all 0.3s ease-out;

  button {
    /* Display & Box Model */
    width: 50%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5rem;

    svg {
      height: 1rem;
    }

    :first-child {
      border-right: 1px solid var(--color-border);
    }

    :hover {
      background-color: var(--color-gray);
      transition: all 0.3s ease-out;
    }
  }
`;

Item.ModalPhotos.ManagerBtns = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  /* Position */
  position: absolute;
  z-index: 10;

  button {
    /* Display & Box Model */
    margin: 0 auto;
    padding: 0.5rem;

    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    font-weight: 400;

    /* Others */
    animation: ${PositionBtnsAnime} 0.3s ease-out forwards;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0.1rem 0.6rem rgb(0 0 0 / 40%);
    cursor: pointer;
    opacity: 0;

    :first-child {
      animation-delay: 0.3s;
      background-color: var(--color-primary);
    }

    :last-child {
      animation-delay: 0.5s;
      background-color: var(--color-red);
    }
  }
`;

Item.Link = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  /* Text */
  text-align: left;
  text-decoration: underline;
  overflow-wrap: break-word;

  a {
    max-width: 80%;
    display: grid;
    align-items: center;
    grid-template-columns: 10% 90%;
    gap: 1rem;
    margin: 0.5rem 0;
    overflow-wrap: anywhere;
  }
`;

Item.ControlBar = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: row;
  grid: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-top: 1px solid var(--color-border);

  svg {
    height: 1.6rem;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 10%;
    max-width: 4rem;
    flex-direction: column;
    padding: 1rem;

    /* Others */
    border: none;
    border-left: 1px solid var(--color-border);
  }
`;

/*==============================
          Item details
================================*/

Item.Details = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-top: 1px solid var(--color-border);
`;

Item.PricesTable = styled.div`
  /* Display & Box Model */
  width: 30rem;
  margin: auto;

  /* Others */
  border: 1px solid var(--color-border);
  border-bottom: none;
`;

Item.PricesTable.Row = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  /* Others */
  border-bottom: 1px solid var(--color-border);

  &.right {
    flex-direction: column;
    align-items: end;

    p {
      color: var(--color-purple-light);
    }
  }

  h4 {
    /* Colors */
    color: var(--color-purple-light);

    /* Text */
    font-size: 2rem;
    font-weight: 700;
  }
`;

Item.ItemNameContainer = styled.div`
  position: relative;

  &.missing-item-name-error {
    &::before {
      content: 'Preencha o nome do item';
      position: absolute;

      top: -40px;

      padding: 5px 10px;
      border-radius: 10px;
      background: var(--color-red);
      color: var(--color-white);
      font-size: 0.8rem;
      text-align: center;
    }

    &::after {
      content: '';
      position: absolute;
      left: 2rem;
      margin-left: -5px;
      top: -5px;
      transform: translateY(-50%);
      border: 8px solid #000;
      border-color: var(--color-red) transparent transparent transparent;
    }

    @media (max-width: 800px) {
      padding-top: 40px;

      &::before {
        top: 8px;
        margin-left: 1rem;
      }

      &::after {
        top: 40px;
      }
    }
  }
`;

Item.ErrorMessage = styled.p`
  padding-top: 1rem;
  width: 100%;
  text-align: center;
  color: var(--color-red);
  font-weight: 700;
`;

/*==============================
    Offer Item Print Version
================================*/

Item.Print = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 4rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border: 1px solid var(--color-border);
  border-radius: 0.3rem;
`;

Item.Print.Title = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 2rem;
  padding-bottom: 0;

  h3 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
  }

  p {
    /* Text */
    font-size: 0.8rem;
  }
`;

Item.Print.Description = styled.p`
  /* Display & Box Model */
  width: 100%;
  padding: 2rem;
  font-size: 0.9rem;

  /* Text */
  white-space: pre-line;
`;

Item.Print.Prices = styled.div`
  border-top: 1px solid var(--color-border);
  padding: 2rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 3rem;

  label {
    font-size: 0.75rem;
    color: var(--color-text);
  }

  p {
    margin-top: 4px;
    color: var(--color-black);
  }

  .total-value {
    label,
    p {
      color: var(--color-purple-light);
      font-weight: 700;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
  @media print{
    display: flex;
    flex-direction: row;
  }
`;

Item.Print.Photos = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  padding: 2rem;

  img {
    width: 100%;
  }
`;

Item.CategorySelectionBox = styled.div`
  padding: 0.5rem 1rem;
  height: fit-content;
  position: relative;

  p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: var(--color-text);
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    div {
      border: 1px solid var(--color-blue-light);
      padding: 4px 8px;
      border-radius: 3rem;
      color: var(--color-blue-light);
      font-size: 0.8rem;
      cursor: pointer;

      &.selected {
        color: var(--color-white);
        background-color: var(--color-blue-light);
      }
    }
  }

  &.missing-category-id-error {
    border: 1px solid var(--color-red);

    &::before {
      /* Display & Box Model */
      content: 'Selecione uma categoria';

      /* Colors */
      background: var(--color-red);
      color: var(--color-white);

      /* Position */
      position: absolute;
      bottom: -50px;
      z-index: 9;
      padding: 6px 10px;
      left: 1rem;

      /* Text */
      font-size: 0.8rem;
      text-align: center;

      /* Others */
      border-radius: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 3rem;
      margin-left: -5px;
      bottom: -22px;
      border: 8px solid #000;
      border-color: transparent transparent var(--color-red) transparent;
    }

    @media (max-width: 600px) {
      &::after,
      &::before {
        display: none;
      }
    }
  }
`;
