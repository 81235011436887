import styled from 'styled-components';

export const Menu = styled.header`
  /* Display & Box Model */
  width: 100%;
  height: 4rem;
  padding-right: 8rem;
  padding-left: 8rem;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;
  @media (max-width: 1380px) {
    padding-right: 1rem ;
   padding-left: 1rem ;

  }

`;

Menu.Links = styled.nav`
  /* Display & Box Model */
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;

  /* Colors */
  color: var(--color-white);
  .logo{
    width: 10rem;
  }

  ul {
    /* Display & Box Model */
    display: none;

    &.open {
      /* Display & Box Model */
      width: 100%;
      min-height: 100vh;
      display: block;

      /* Colos */
      background-color: var(--color-white);

      /* Position */
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;

      li {
        /* Display & Box Model */
        width: 100%;

        /* Position */
        position: relative;

        /* Others */
        border-bottom: 0.1rem solid var(--color-border);

        :last-child {
          border-bottom: none;
        }

        div {
          display: none;
        }

        .dropdown {
          /* Display & Box Model */
          display: block;

          /* Positon */
          position: relative;

          div a {
            /* Positon */
            position: relative;
            left: 1rem;
          }
        }

        :hover {
          div {
            /* Display & Box Model */
            display: block;
          }
        }
      }
    }
  }

  li {
    word-break: break-all;
    /* Display & Box Model */
    display: inline-block;

    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 0.9rem;

    &.dropdown--arrow::after {
      /* Display & Box Model */
      content: '';
      width: 1rem;
      height: 1rem;

      /* Colors */
      background-color: var(--color-white);

      /* Position */
      position: absolute;
      top: 1.6rem;
      right: 0.5rem;

      /* Others */
      clip-path: polygon(0% 0%, 50% 50%, 100% 0%, 100% 15%, 50% 65%, 0% 15%);
    }

    a {
      /* Display & Box Model */
      height: 4rem;
      display: flex;
      align-items: center;
      padding: 0 2rem;

      /* Colors */
      color: var(--color-black);

      &.active {
        /* Colors */
        color: var(--color-primary);

        /* Text */
        font-weight: 700;
      }
    }

    figure {
      /* Display & Box Model */
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 1rem;

      /* Others */
      border-radius: 0.3rem;
      border-radius: 100%;
      z-index: 5;
      display: flex;
      align-items: start;

      img {
        /* Display & Box Model */
        height: 100%;
        min-width: 100%;
        z-index: 1;
        left:0;
      }
    }
  }

  @media (min-width: 800px) {
    ul {
      /* Display & Box Model */
      display: block;
    }

    li {
      /* Text */
      font-size: 0.8rem;

      a {
        /* Display & Box Model */
        padding: 0 0.5rem;
      }
    }

    li {
      /* Position */
      position: relative;

      &.dropdown {
        div {
          /* Display & Box Model */
          display: none;

          /* Colors */
          background-color: var(--color-white);
          color: var(--color-white);

          /* Text */
          text-align: center;

          /* Position */
          position: absolute;
          top: 4rem;
          z-index: 999;
          border: 1px solid var(--color-green);
          border-radius: 0.25rem;


          a {
            /* Display & Box Model */
            width: 10rem;
            padding: 1.5rem 1rem;

            /* Others */
            transition: all 0.3s ease-out;

            :hover {
              /* Colors */
              background-color: var(--color-green);
              color: var(--color-white);
              


              /* Others */
              transition: all 0.3s ease-out;
            }
          }
        }

        :hover {
          div {
            display: block;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    li {
      /* Text */
      font-size: 0.9rem;

      a {
        /* Display & Box Model */
        padding: 0 2rem;
      }
    }
  }
  @media only screen
  and (min-device-width: 800px)
  and (max-device-width: 916px) {
    .logo{
      display: none;
    }
  }

  .truncate {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

Menu.Logo = styled.img`
  /* Display & Box Model */
  width: 3rem;  
`;

Menu.MobileIcon = styled.img`
  /* Display & Box Model */
  width: 2rem;
  padding: 0.25rem;
  background-color: var(--color-green);
  border-radius: 0.25rem;
  /* Position */
  position: relative;
  z-index: 1000;

  &.open {
    /* Position */
    position: fixed;
    right: 1rem;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: none;
  }
`;
