import styled from 'styled-components';

export const TermsPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* Color */
  color: var(--color-black);

  /* Position */
  position: relative;

  /* Text */
  line-height: 1.6;

  ol,
  li {
    /* Text */
    text-align: justify;

    /* Others */
    text-decoration: inherit;
    list-style-type: none;
  }

  ol {
    /* Others */
    counter-reset: item;

    li::before {
      /* Display & Box Model */
      /* display: block; */
      margin-right: 1rem;

      /* Colors */
      color: var(--color-gray-medium);

      /* Text */
      font-size: 1rem;
      font-weight: 200;

      /* Others */
      content: counters(item, '.') ' ';
      counter-increment: item;
    }

    &.alphabetical {
      li {
        /* Others */
        counter-increment: listStyle;
      }

      li::before {
        /* Others */
        content: counter(listStyle, upper-alpha);
      }
    }
  }

  p {
    /* Display & Box Model */
    margin: 1rem auto;
  }

  .underline {
    /* Text */
    text-decoration: underline;
  }

  .container--form {
    /* Display & Box Model */
    padding-top: 10rem;
  }
`;

TermsPage.Resume = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border: solid 0.1rem var(--color-border);
`;

TermsPage.Nav = styled.nav`
  /* Display & Box Model */
  width: 20rem;
  padding: 1rem;

  /* Position */
  position: absolute;
  left: 2rem;
  top: 8rem;

  /* Text */
  font-size: 0.8rem;

  /* Others */
  border: solid 0.1rem var(--color-border);

  a,
  button {
    /* Display & Box Model */
    display: block;
    margin: 1rem 0;
    text-align: left;

    /* Others */
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    /* Display & Box Model */
    display: none;
  }
`;

TermsPage.Title = styled.h1`
  /* Display & Box Model */
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;

  /* Color */
  color: var(--color-black);

  /* Text */
  font-size: 2rem;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);
`;

TermsPage.Subtitle = styled.h2`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto 1rem auto;

  /* Color */
  color: var(--color-black);

  /* Text */
  font-size: 1.2rem;
`;

TermsPage.List = styled.ol`
  /* Display & Box Model */

  li {
    /* Display & Box Model */
    margin: 1rem auto;
  }
`;

TermsPage.ListLI = styled.li`
  /* Display & Box Model */
  width: 100%;
  display: block;
  margin: 2rem auto 4rem auto !important;

  /* Position */
  position: relative;

  ::before {
    /* Position */
    position: absolute;
    top: -0.2rem;

    /* Text */
    font-size: 1.6rem !important;
  }
`;

TermsPage.ListTitle = styled.h3`
  /* Display & Box Model */
  margin-bottom: 1rem;
  padding: 0 0 0.5rem 0.5rem;
  padding-left: 2rem;

  /* Color */
  color: var(--color-black);

  /* Text */
  font-size: 1.2rem;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);
`;

TermsPage.ListOL = styled.ol`
  /* Display & Box Model */
  width: 100%;
  margin: 1rem auto;
  padding-left: 2rem;

  /* Position */
  position: relative;

  @media (max-width: 600px) {
    /* Display & Box Model */
    padding-left: 0.5rem;
  }
`;

TermsPage.ListItem = styled.li`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;
  padding-left: 2rem;

  /* Position */
  position: relative;

  &.teste {
    ::before {
      content: counter(chapter, lower-alpha);
    }
  }

  @media (max-width: 600px) {
    /* Display & Box Model */
    padding-left: 0.5rem;
  }
`;

export const StyledTerms = styled.div`
  /* Color */
  color: var(--color-black);

  margin: 8rem 2rem 4rem 24rem;
  padding: 1rem 2rem;

  /* Others */
  border: solid 0.1rem var(--color-border);

  @media (max-width: 1024px) {
    /* Display & Box Model */
    margin-left: 2rem;
    margin-top: 6rem;
  }

  h1,
  h2,
  b {
    font-weight: bold;
  }

  u,
  i {
    font-weight: inherit;
  }

  @media (max-width: 600px) {
    /* Display & Box Model */
    padding: 0 1rem;
    margin: 5rem 0.5rem;
  }

  h1 {
    /* Display & Box Model */
    margin: 1.5rem 0;

    /* Text */
    text-align: center;
    font-size: 1rem;
  }

  section:first-of-type {
    /* Text */
    line-height: 1.5;
  }

  section {
    /* Display & Box Model */
    margin: 0 auto;
    padding-top: 5rem;
    max-width: 860px;

    /* Text */
    text-align: justify;
    line-height: 1.8;

    h2 {
      /* Text */
      font-size: 1rem;
    }

    p {
      /* Display & Box Model */
      margin: 0.8rem 0;

      a {
        text-decoration: underline;
      }
    }

    aside p {
      /* Display & Box Model */
      margin: 0.5rem 0;

      /* Text */
      line-height: 1.5;
      font-size: 0.8rem;
    }

    ul {
      /* Display & Box Model */
      margin-left: 2rem;
      margin-bottom: 1.5rem;

      li {
        /* Display & Box Model */
        margin: 0.8rem 0;

        a {
          text-decoration: underline;
        }
      }
    }

    table {
      /* Display & Box Model */
      border-collapse: collapse;

      td,
      th {
        /* Display & Box Model */
        border: 1px solid black;
        padding: 0.5rem;

        /* Text */
        text-align: left;
        vertical-align: super;
        font-size: 0.9rem;
        line-height: 1.4;

        b {
          font-size: inherit;
        }

        ul,
        li {
          /* Display & Box Model */
          margin-bottom: 0;
          margin-top: 0;

          /* Text */
          text-align: left;
          line-height: 1.5;

          /* Others */
          list-style-type: disc;
        }
      }
    }
  }
`;
