import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Load } from '../../../Components/Loader/styles';
import { CompanyStepStyled } from './styles';
import { FormTxtInput } from '../../../Components/common/FormTextInput';
import { Button } from '../../../Components/common/Buttons/Button';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useRegisteredData } from '../CompleteRegistration/hooks/useRegisteredData';
import { useCNPJ } from './hooks/useCNPJ';
import { useCPF } from './hooks/useCPF';
import { useAsync } from '../../../utils';
import { FormTxtInputWithStatus } from '../../../Components/InputWithStatus/FormTxtInputWithStatus';
import Alert from '../../../Components/Alert';

export function RegistrationCompanyStep({ handleContinueRegistration }) {
  const {
    manager_name,
    known_name,
    company_name,
    registered_cnpj,
    registered_cpf,
    isLoadingRegisteredData,
    indicated_by,
    isErrorRegisteredData,
  } = useRegisteredData();

  const {
    maskedCNPJ,
    handleChangeCNPJ,
    isValidCNPJ,
    isCheckingCNPJ,
    isCheckedCNPJ,
    valueCNPJ,
    setValueCNPJ,
  } = useCNPJ();

  const { maskedCPF, handleChangeCPF, isValidCPF, isCheckedCPF, valueCPF, setValueCPF } = useCPF();

  const [errors, setErrors] = useState({});

  const serverErrorAlertRef = useRef();
  const cpfAlreadyRegisteredAlertRef = useRef();

  useEffect(() => {
    setValueCPF(registered_cpf);
    setValueCNPJ(registered_cnpj);
  }, [registered_cpf, setValueCPF, registered_cnpj, setValueCNPJ]);

  useEffect(() => {
    if (isCheckedCNPJ) {
      if (isValidCNPJ) {
        setErrors(errors => ({ ...errors, cnpj: '' }));
      } else {
        setErrors(errors => ({ ...errors, cnpj: 'O CNPJ inserido é inválido' }));
      }
    }
    if (isCheckedCPF) {
      if (isValidCPF) {
        setErrors(errors => ({ ...errors, cpf: '' }));
      } else {
        setErrors(errors => ({ ...errors, cpf: 'O CPF inserido é inválido' }));
      }
    }
  }, [isCheckedCNPJ, isCheckedCPF, isValidCNPJ, isValidCPF]);

  const { run: runSubmit, isLoading: isSubmitting, isError: isErrorSubmit, error } = useAsync();

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const manager_name = formData.get('manager_name');
    const known_name = formData.get('known_name');
    const company_name = formData.get('company_name');
    const indicated_by = formData.get('indicated_by');

    const errors = {};

    if (!manager_name) {
      errors.manager_name = 'Campo obrigatório';
    }
    if (!known_name) {
      errors.known_name = 'Campo obrigatório';
    }
    if (!company_name) {
      errors.company_name = 'Campo obrigatório';
    }
    if (!maskedCPF) {
      errors.cpf = 'Campo obrigatório';
    } else if (!isValidCPF) {
      errors.cpf = 'O CPF inserido é inválido';
    }
    if (!maskedCNPJ) {
      errors.cnpj = 'Campo obrigatório';
    } else if (!isValidCNPJ) {
      errors.cnpj = 'O CNPJ inserido é inválido';
    }

    if (Object.entries(errors).length > 0) {
      setErrors(errors);
      return;
    }

    runSubmit(
      axios
        .post('/api/supplier/register-company', {
          manager_name,
          manager_cpf: valueCPF,
          known_name,
          cnpj: valueCNPJ,
          company_name,
          indicated_by,
        })
        .then(() => handleContinueRegistration())
    ).catch(err => console.log(err.message));
  }

  useEffect(() => {
    console.log();
    if (error?.response?.data?.message === 'CPF already registered') {
      cpfAlreadyRegisteredAlertRef.current.open();
    } else if (isErrorRegisteredData || isErrorSubmit) {
      serverErrorAlertRef.current.open();
    }
  }, [isErrorRegisteredData, isErrorSubmit, error]);

  if (isLoadingRegisteredData) {
    return (
      <Load.FullPageContainer style={{ height: '60vh' }}>
        <Load.SmallIcon />
      </Load.FullPageContainer>
    );
  }

  return (
    <CompanyStepStyled>
      <div>
        <form onSubmit={handleSubmit}>
          <h3>Informações sobre sua empresa</h3>
          <p>Queremos saber um pouco mais sobre você e o seu negócio</p>
          <div className="inputValues">
            <FormTxtInput
              label="Responsável pela empresa *"
              type="text"
              placeholder="Nome do responsável"
              icon="user"
              defaultValue={manager_name}
              name="manager_name"
              errorMessage={errors.manager_name}
            />
            <FormTxtInputWithStatus
              label="CPF do responsável pela empresa *"
              placeholder="CPF do responsável"
              icon="credit-card"
              value={maskedCPF}
              onChange={handleChangeCPF}
              isSuccess={isValidCPF}
              errorMessage={errors.cpf}
            />
            <FormTxtInput
              label="Nome fantasia (Nome da sua empresa) *"
              type="text"
              placeholder="Nome da empresa"
              icon="home"
              defaultValue={known_name}
              name="known_name"
              errorMessage={errors.known_name}
            />
            <FormTxtInput
              label="Razão social (Nome que aparece na sua NF) *"
              type="text"
              placeholder="Razão social"
              icon="home"
              defaultValue={company_name}
              name="company_name"
              errorMessage={errors.company_name}
            />
            <FormTxtInputWithStatus
              label="CNPJ da sua empresa *"
              placeholder="CNPJ"
              icon="credit-card"
              value={maskedCNPJ}
              onChange={handleChangeCNPJ}
              isLoading={isCheckingCNPJ}
              isSuccess={isValidCNPJ}
              errorMessage={errors.cnpj}
            />
          </div>
          <div className="recommendation">
            <h3>Alguém te indicou a Celebrar?</h3>
            <FormTxtInput
              label="Nome da pessoa ou empresa que te indicou"
              type="text"
              placeholder="Nome"
              defaultValue={indicated_by}
              icon="user"
              name="indicated_by"
              id="indicated_by"
            />
          </div>
          <Button type="submit" disabled={isSubmitting}>
            Continuar
            {isSubmitting ? (
              <FeatherIcon icon="loader" className="spinner" size={16} color="var(--color-text)" />
            ) : (
              <FeatherIcon icon="arrow-right" className="icon" size={16} color="white" />
            )}
          </Button>
        </form>
      </div>
      <Alert status="danger" ref={serverErrorAlertRef}>
        Ocorreu um erro.
      </Alert>
      <Alert status="warning" ref={cpfAlreadyRegisteredAlertRef}>
        Já existe um outro cadastro com este CPF.
      </Alert>
    </CompanyStepStyled>
  );
}
