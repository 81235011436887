import React, { useRef } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import axios from 'axios';
import Modal from '../../Components/Modal';
import Alert from '../../Components/Alert';
import { HomePage } from './styles';
import ModalAuth from '../../Components/ModalAuth';
import { Link } from 'react-router-dom';
import Buyers from './buyersLogos';
import { Button } from '../../Components/common/Buttons/Button';
import Comments from './buyersComments';
import FeatherIcon from 'feather-icons-react';

// Images
import team from '../../assets/images/home_page/celebrar-team.jpg';
import AboutUsImage from '../../assets/images/home_page/quebra-rotina.svg';
import Fornecedores from '../../assets/icons/faqIcons/Fornecedores.png';
import Compradores from '../../assets/icons/faqIcons/Compradores.png';

// How does it work (Steps)
import step1 from '../../assets/images/home_page/step-1.svg';
import step2 from '../../assets/images/home_page/step-2.svg';
import step3 from '../../assets/images/home_page/step-3.svg';
import step4 from '../../assets/images/home_page/step-4.svg';


function Home() {
  const modalMarketplaceRef = useRef();
  const alertRef = useRef();

  function handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: '/api/briefing_send',
      data: {
        contact_name: data.get('contact_name'),
        contact_email: data.get('contact_email'),
        contact_phone: data.get('contact_phone'),
        message: data.get('message'),
        hidden_phone: data.get('hidden_phone'),
      },
    })
      .then(res => {
        if (res.status === 200) {
          modalMarketplaceRef.current.close();
          alertRef.current.open();
        }
      })
      .catch(err => console.log(err));
  }

  return (
    <HomePage>
      <Header buyerPage />
      <HomePage.Bunner>
        <div className="container">
          <h1>
            Vamos quebrar <br /> rotinas juntos?
          </h1>
          <p>
            Na Celebrar, unimos fornecedores e marcas de todo país para tirar as pessoas do modo
            automático e criar eventos inesquecíveis.
          </p>

          <Link to="/comprador/pedido">
            <Button buttonSize="large" align="left">Comece agora</Button>
          </Link>
        </div>
      </HomePage.Bunner>

      <HomePage.Numbers>
        <HomePage.NumbersPhoto>
          <img src={team} alt="Equipe Celebrar" loading="lazy" />
        </HomePage.NumbersPhoto>
        <HomePage.NumbersContent>
          <h4>CELEBRAR EM NÚMEROS</h4>
          <div>
            <p>Serviços vendidos online </p>
            <span>18.623</span>
          </div>
          <div>
            <p>Renda distribuída</p>
            <span>R$ 7.253.129</span>
          </div>
          <div>
            <p>Micro fornecedores cadastrados</p>
            <span>3.272</span>
          </div>
          <div>
            <p>Grandes empresas compradoras</p>
            <span>128</span>
          </div>
          <div>
            <p>Agências tradicionais compradoras</p>
            <span>47</span>
          </div>
        </HomePage.NumbersContent>
      </HomePage.Numbers>

      <HomePage.About>
        <div className="container">
          <HomePage.AboutText>
            <h3>
              Quem <br /> Somos
            </h3>
            <p>
              Somos uma comunidade formada por fornecedores de todo o Brasil, <br />
              reunimos talentos de todas as partes do país para a realização de eventos e
              experiências corporativas.
              Estamos há 6 anos desenvolvendo soluções incríveis para os desafios dos eventos da sua
              marca.
            </p>
          </HomePage.AboutText>

          <HomePage.AboutImage>
            <img src={AboutUsImage} alt="Quebra Rotina" loading="lazy" />
          </HomePage.AboutImage>
        </div>
      </HomePage.About>

      <HomePage.Steps>
        <div className="container">
          <h3>
            Como <br /> Funciona
          </h3>

          <HomePage.StepsGrid>
            <HomePage.StepCard>
              <figure>
                <img src={step1} alt="Passo 1" loading="lazy" />
              </figure>
              <HomePage.StepCard.Text>
                <HomePage.StepCard.Title>
                  {/* <h4>1</h4> */}
                  <h5>Briefing do Evento</h5>
                </HomePage.StepCard.Title>
                <p>
                  O organizador do evento abre um chamado através da criação de um pedido de
                  orçamento na plataforma, incluindo todas as informações importantes sobre seu
                  evento: o que chamamos de "Briefing do Evento".
                </p>
              </HomePage.StepCard.Text>
            </HomePage.StepCard>
            <HomePage.StepCard>
              <figure>
                <img src={step2} alt="Passo 2" loading="lazy" />
              </figure>
              <HomePage.StepCard.Text>
                <HomePage.StepCard.Title>
                  {/* <h4>2</h4> */}
                  <h5>Disparo das Oportunidades</h5>
                </HomePage.StepCard.Title>
                <p>
                  A comunidade de fornecedores recebe as oportunidades segmentadas de acordo com as
                  categorias de serviços que oferecem e aplicam orçamentos personalizados para cada
                  demanda diretamente no "Painel de Vendas".
                </p>
              </HomePage.StepCard.Text>
            </HomePage.StepCard>
            <HomePage.StepCard>
              <figure>
                <img src={step3} alt="Pass 3" loading="lazy" />
              </figure>
              <HomePage.StepCard.Text>
                <HomePage.StepCard.Title>
                  {/* <h4>3</h4> */}
                  <h5>Proposta Comercial</h5>
                </HomePage.StepCard.Title>
                <p>
                  O comprador recebe um link mágico: onde é possível acompanhar os melhores
                  orçamentos aplicados por diferentes fornecedores, com fotos de referência e todas
                  as informações necessárias para contratar tudo online.
                </p>
              </HomePage.StepCard.Text>
            </HomePage.StepCard>
            <HomePage.StepCard>
              <figure>
                <img src={step4} alt="Passo 4" loading="lazy" />
              </figure>
              <HomePage.StepCard.Text>
                <HomePage.StepCard.Title>
                  {/* <h4>4</h4> */}
                  <h5>Entrega do Evento</h5>
                </HomePage.StepCard.Title>
                <p>
                  Os fornecedores aprovados pelo link mágico realizam as entregas dos serviços
                  confirmados de acordo com as especificações. A Celebrar unifica a NF para o
                  comprador e cuida do workflow de pagamentos aos fornecedores.
                </p>
              </HomePage.StepCard.Text>
            </HomePage.StepCard>
          </HomePage.StepsGrid>

          <Link
            to="/comprador/pedido"
            style={{ display: 'block', width: '100%', textAlign: 'center' }}
          >
            <Button buttonSize="large" align="center">Comece agora</Button>
          </Link>
        </div>
      </HomePage.Steps>

      <HomePage.Categories>
        <HomePage.CategoriesContent>
          <div className="container">
            <h3>Categorias</h3>
            <p>
              Do que você precisa para transformar seu evento em uma experiência única? <br />
              Na Celebrar, oferecemos um marketplace colaborativo completo, rápido e seguro para as
              suas experiências corporativas.
            </p>
          </div>
        </HomePage.CategoriesContent>

        <div className="container">
          <HomePage.CategoriesGrid>
            <HomePage.Categories.Card>
              <div>
                <figure id="alimentacao">
                  {/* <img src={alimentacao} alt="Alimentação e bebidas" loading="lazy" /> */}
                </figure>
                <h4>Alimentação <br /> e Bebidas</h4>
              </div>
              <ul>
                <li>Coffee Break, </li> <li>Almoço, </li> <li>Coquetel, </li> <li>Happy Hour, </li>
                <li>Jantar, </li> <li>Rodízio De Pizza, </li> <li>Hambúrguer Artesanal, </li>
                <li>Kits, </li> <li>Cestas, </li> <li>Drinks Clássico, </li> <li>Mixologia Especializada, </li>
                <li>Chopp, </li> <li>Cerveja, </li> <li>Espumantes, </li> <li>Destilados e Soft Drinks, </li>
                <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="musica">
                  {/* <img src={entretenimento} alt="Entretenimento" loading="lazy" /> */}
                </figure>
                <h4>Música e <br /> Entretenimento</h4>
              </div>
              <ul>
                <li>DJs, </li> <li>Bandas, </li> <li>Música Ao Vivo, </li> <li>Atrações Gastronômicas, </li>
                <li>Teatro, </li> <li>Dança, </li> <li>Arte Circenses, </li> <li>Totem Do Instagram, </li>
                <li>Cabine De Fotos, </li> <li>Locação De Videogames, </li> <li>Fliperamas, </li>
                <li>Jogos de Tabuleiro, </li> <li>Infláveis, </li> <li>Recreação, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="brindes">
                  {/* <img src={brindes} alt="Brindes personalizados" loading="lazy" /> */}
                </figure>
                <h4>Brindes <br /> Personalizados</h4>
              </div>
              <ul>
                <li>Camisetas, </li> <li>Cordões e Lanyards, </li> <li>Blusas e Moletons, </li>
                <li>Buttons e Pins, </li> <li>Chaveiros, </li> <li>Canecas, </li>
                <li>Copos e Garrafas, </li> <li>Canetas, </li> <li>Eletrônicos, </li>
                <li>Itens de escritório, </li> <li>Saúde e bem-estar, </li> <li>Brindes Sustentáveis, </li>
                <li>Costuráveis, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="audiovisual">
                  {/* <img src={audiovisual} alt="Audiovisual e estruturas" loading="lazy" /> */}
                </figure>
                <h4>Audiovisual e <br /> Infraestrutura</h4>
              </div>
              <ul>
                <li>Equipamentos de Som, </li> <li>Iluminação e Vídeo, </li> <li>Streaming e Transmissão ao vivo, </li>
                <li>Palcos e Praticáveis, </li> <li>Tendas, </li> <li>Box Truss, </li> <li>Locação de eletrônicos, </li>
                <li>Internet e Wi-Fi, </li> <li>Estacionamentos, </li> <li>Credenciamentos, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="staff">
                  {/* <img src={staff} alt="Staff Técnica e operacional" loading="lazy" /> */}
                </figure>
                <h4>Staff Técnica <br /> e Operacional</h4>
              </div>
              <ul>
                <li>Operadores e Técnicos, </li> <li>Intérpretes, </li> <li>Tradução Simultânea, </li>
                <li>Recepcionistas, </li> <li>Orientação De Público, </li> <li>Segurança, </li> <li>Limpeza, </li>
                <li>Carregadores, </li> <li>Montagem de Kits, </li> <li>Equipe Enfermagem, </li> <li>Ambulância, </li>
                <li>Bombeiro, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="fotografia">
                  {/* <img src={fotografia} alt="Fotografia e filmagem" loading="lazy" /> */}
                </figure>
                <h4>Fotografia <br /> e Filmagem</h4>
              </div>
              <ul>
                <li>Fotógrafos, </li> <li>Videomakers, </li> <li>Equipes de Foto e Filmagem, </li>
                <li>Edições de Foto e Vídeo, </li> <li>Teaser, </li> <li>Spinner 360, </li> <li>Drones, </li>
                <li>Vinhetas, </li> <li>Social Makers, </li> <li>Light Painting, </li>
                <li>Pós-produção Fotográfica, </li> <li>Panning, </li> <li>Longa Exposição, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="logistica">
                  {/* <img src={logistica} alt="Logística e movimentos" loading="lazy" /> */}
                </figure>
                <h4>Logística <br /> e Movimentos</h4>
              </div>
              <ul>
                <li>Logística de Passageiros e Shuttle Circulares para o público do seu evento ou atrações considerando Vans, </li>
                <li>Ônibus e Carros, </li>
                <li>além de Logística de Cargas e Fretes para entregas nacionais e internacionais.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="decoracao">
                  {/* <img src={decoracao} alt="Decoração e comunicação" loading="lazy" /> */}
                </figure>
                <h4>Decoração <br /> e Comunicação</h4>
              </div>
              <ul>
                <li>Credenciais, </li> <li>Cordões De Crachá, </li> <li>Pulseiras de acesso, </li>
                <li>Totens de Informações, </li> <li>Backdrop e Banners, </li> <li>Cenografia, </li>
                <li>Mobiliário, </li> <li>Lycra, </li> <li>Paisagismo, </li> <li>Papelaria, </li>
                <li>Balões, </li> <li>Letras Caixa, </li> <li>Painéis e Stands, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="conteudo">
                  {/* <img src={conteudo} alt="Produção e conteúdo" loading="lazy" /> */}
                </figure>
                <h4>Produção <br /> e Conteúdo</h4>
              </div>
              <ul>
                <li>Produtores de Eventos, </li> <li>Coordenadores de Eventos, </li>
                <li>Comunicação e criativo, </li> <li>Direção Artística, </li> <li>Direção Técnica, </li>
                <li>Roteirização, </li> <li>Palestrantes, </li> <li>Team Builders, </li>
                <li>Curadores De Conteúdo, </li> <li>Mestres De Cerimônias, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
            <HomePage.Categories.Card>
              <div>
                <figure id="espacos">
                  {/* <img src={espacos} alt="Espaços para eventos" loading="lazy" /> */}
                </figure>
                <h4>Espaços <br /> para Eventos</h4>
              </div>
              <ul>
                <li>Auditórios, </li> <li>Salas De Reunião, </li> <li>Espaços para confraternizações, </li>
                <li>Estúdios de Audiovisual, </li> <li>Estúdios de Podcast, </li> <li>Espaços De Festas, </li>
                <li>Cinemas e Teatros, </li> <li>Espaços Alternativos, </li> <li>Pavilhões, </li>
                <li>Estádios, </li> <li>etc.</li>
              </ul>
            </HomePage.Categories.Card>
          </HomePage.CategoriesGrid>
        </div>
      </HomePage.Categories>

      <Buyers />
      <Comments />

      <HomePage.faqHeader>
        <div className="container">
          <h3>Perguntas?</h3>
        </div>
      </HomePage.faqHeader>
      <HomePage.Faq>
        <div className='content'>
          <h1>Selecione seu segmento de atuação</h1>
          <div className='container_card'>
            <div className='row'>
              <div className='col m6 s12'>
                <Link to='/faq/comprador'>
                  <div className='cards'>
                    <figure className='img-faq'>
                      <img src={Compradores} id='comprador' />
                      <h1>Compradores</h1>
                    </figure>
                  </div>
                </Link>
              </div>
              <div className='col m6 s12'>
                <Link to='/faq/Fornecedor/home'>
                  <div className='cards'>
                    <figure className='img-faq'>
                      <img src={Fornecedores} id='fornecedor' />
                      <h1>Fornecedores</h1>
                    </figure>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </HomePage.Faq>

      <ModalAuth />
      <Modal title="Faça seu orçamento" ref={modalMarketplaceRef}>
        <form onSubmit={handleSubmit} name="marketplace_section_form">
          <h3 className="modal__title"> Faça seu orçamento </h3>
          <div className="input__field">
            <label htmlFor="contact_name"> Seu nome </label>
            <input
              className="input"
              id="contact_name"
              name="contact_name"
              type="text"
              min="1"
              required
            />
          </div>

          <div className="input__field">
            <label htmlFor="contact_email"> Seu e-mail </label>
            <input
              className="input"
              id="contact_email"
              name="contact_email"
              type="email"
              min="1"
              required
            />
          </div>

          <div className="input__field">
            <label htmlFor=""> Seu telefone </label>
            <input className="input" name="contact_phone" type="text" min="1" />
          </div>

          <div className="input__field">
            <label htmlFor="">
              {' '}
              Explique aqui o que você precisa cotar, quais os seus prazos e necessidades{' '}
            </label>
            <textarea
              className="input textarea"
              name="message"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>

          <button className="btn btn--fullwidth" type="submit">
            <span> Solicitar orçamentos para a comunidade de fornecedores Celebrar </span>
          </button>
        </form>
      </Modal>

      <Alert ref={alertRef}>
        <p>
          {' '}
          Agradecemos a sua mensagem! Em breve, seu especialista em eventos Celebrar entrará em
          contato com você para apresentar os orçamentos da Comunidade.{' '}
        </p>
      </Alert>

      <Footer />
    </HomePage >
  );
}

export default Home;
