import React from 'react';

export const Direction = () => {
    return(
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L10 10L1 19" stroke="black" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export const Cart = () => {
    return(
        <svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 1.49751C1 1.11615 1.30915 0.807007 1.6905 0.807007H7.131L10.9635 19.4816C11.1141 19.9685 11.4611 20.7095 12.003 21.3269C12.5506 21.9507 13.2464 22.3955 14.1039 22.4164C16.3128 22.4703 26.6085 22.4389 31.4931 22.4162C31.8745 22.4145 32.1851 22.7222 32.1868 23.1035C32.1886 23.4849 31.8809 23.7955 31.4995 23.7972C26.6295 23.8198 16.305 23.8515 14.0702 23.797C12.7058 23.7638 11.6742 23.0457 10.9651 22.2379C10.261 21.4357 9.82504 20.4933 9.63217 19.8504L9.62333 19.8209L6.00463 2.18801H1.6905C1.30915 2.18801 1 1.87886 1 1.49751Z" fill="#F6BF07" stroke="#F6BF07" strokeWidth="0.5" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.97255 7.20085C6.74735 6.06142 7.6212 5.00684 8.77808 5.00684H33.9472C35.1473 5.00684 36.0266 6.13632 35.7324 7.2997L33.372 16.6314C33.1882 17.358 32.5825 17.9006 31.8402 18.0037L11.1637 20.8755C10.8873 20.9139 10.5811 20.8825 10.3041 20.7282C10.017 20.5682 9.81506 20.3051 9.72141 19.9864C9.17833 18.1383 7.82718 11.525 6.97255 7.20085ZM8.77808 6.38784C8.4868 6.38784 8.27176 6.65185 8.32734 6.93309C9.17463 11.2201 10.48 17.6015 11.0188 19.5013L31.6502 16.6359C31.8358 16.6101 31.9872 16.4744 32.0331 16.2928L34.3935 6.96105C34.4671 6.67021 34.2472 6.38784 33.9472 6.38784H8.77808ZM10.944 19.5091C10.944 19.5091 10.9444 19.5092 10.9451 19.5093C10.9444 19.5092 10.944 19.5092 10.944 19.5091Z" fill="#F6BF07" stroke="#F6BF07" strokeWidth="0.5" strokeLinecap="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.884 32.619C15.0667 32.619 16.0255 31.6602 16.0255 30.4775C16.0255 29.2947 15.0667 28.336 13.884 28.336C12.7012 28.336 11.7425 29.2947 11.7425 30.4775C11.7425 31.6602 12.7012 32.619 13.884 32.619ZM13.884 34C15.8294 34 17.4065 32.4229 17.4065 30.4775C17.4065 28.532 15.8294 26.955 13.884 26.955C11.9385 26.955 10.3615 28.532 10.3615 30.4775C10.3615 32.4229 11.9385 34 13.884 34Z" fill="#F6BF07" stroke="#F6BF07" strokeWidth="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.3803 32.619C29.563 32.619 30.5218 31.6602 30.5218 30.4775C30.5218 29.2947 29.563 28.336 28.3803 28.336C27.1976 28.336 26.2388 29.2947 26.2388 30.4775C26.2388 31.6602 27.1976 32.619 28.3803 32.619ZM28.3803 34C30.3257 34 31.9028 32.4229 31.9028 30.4775C31.9028 28.532 30.3257 26.955 28.3803 26.955C26.4349 26.955 24.8578 28.532 24.8578 30.4775C24.8578 32.4229 26.4349 34 28.3803 34Z" fill="#F6BF07" stroke="#F6BF07" strokeWidth="0.5"/>
        </svg>
    )
}