import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Register } from './styles';
import { Button } from '../../../../Components/common/Buttons/Button';
import { FormEditableInput } from '../../../../Components/common/Forms/FormEditableInput';
import { useAsync } from '../../../../utils';
import { Load } from '../../../../Components/Loader/styles';
import Header from '../../../../Components/Header';

function ForgotPassword() {
  const history = useHistory();
  const { run, isLoading, isSuccess } = useAsync();
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    setErrors({});
    const errors = {};

    // Email validation regex from html specification https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!email.trim()) {
      errors.email = 'Você precisa informar seu e-mail cadastrado';
    } else if (!emailRegEx.test(email)) {
      errors.email = 'Endereço de e-mail inválido';
    }

    if (Object.values(errors).length > 0) {
      setErrors(errors);
      return;
    }

    run(axios.post('/api/auth/forgot_password', { email }).then(res => res.data)).catch(err => {
      if (err.response?.status === 500) {
        setErrors({
          server: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.',
        });
      } else {
        setErrors({ server: err.response.data?.message });
      }
    });
  }

  return (
    <Register>
      <Header buyerPage />

      <Register.Container>
        <div className="container">
          <Register.Layout>
            <Register.Heading>
              <h1>
                Esqueci
                <br />
                minha senha
              </h1>
              <p>
                Digite seu e-mail cadastrado na plataforma, e você receberá um e-mail para redefinir
                sua senha
              </p>
            </Register.Heading>
            <Register.Form onSubmit={handleSubmit}>
              <label>
                E-mail
                <FormEditableInput
                  type="email"
                  className={errors.email ? 'form-error' : undefined}
                  placeholder="Digite seu e-mail cadastrado"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <p className="field-error-message">{errors.email}</p>
              </label>

              {errors.server ? (
                <p style={{ margin: '1rem 0', color: 'var(--color-red)', fontWeight: 700 }}>
                  {errors.server}
                </p>
              ) : null}

              {isSuccess ? (
                <p style={{ margin: '1rem 0 2rem', color: 'var(--color-green)', fontWeight: 700 }}>
                  Feito! Você receberá nos próximos minutos um e-mail com instruções para criar uma
                  nova senha.
                </p>
              ) : null}

              {isSuccess ? (
                <Button onClick={() => history.push('/login')}>Acessar minha conta</Button>
              ) : (
                <>
                  <Button>
                    {isLoading ? (
                      <Load.SmallIcon
                        style={{
                          margin: '0 auto',
                          borderColor: 'var(--color-primary-light)',
                          borderTopColor: 'var(--color-white)',
                        }}
                      />
                    ) : (
                      'Redefinir senha'
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => history.push('/cadastro')}
                    style={{ marginTop: 0 }}
                  >
                    Criar nova conta
                  </Button>
                </>
              )}
            </Register.Form>
          </Register.Layout>
        </div>
      </Register.Container>
    </Register>
  );
}

export default ForgotPassword;
