import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import axios from 'axios';
import { useAsync } from '../../utils/useAsync';
import { Button } from '../common/Buttons/Button';
import { ButtonIconOnly } from '../common/Buttons/ButtonIconOnly';
import { FormEditableInput } from '../common/Forms/FormEditableInput';
import { Close } from '../common/Icons/IconsFamily';
import { FadeInContainer, LoginModalForm, ModalStyles, RegistrationModal } from './styles';
import { Load } from '../Loader/styles';
import { useAuthContext } from '../../Contexts/AuthContext';

export const BuyerSignInModal = forwardRef(({ onClose }, ref) => {
  const [isOpen, setIsOpen] = useState(null);
  const [isClosing, setIsClosing] = useState(null);
  const [page, setPage] = useState('register');

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
      document.querySelector('body').style.overflow = 'hidden';
    },
    close() {
      setIsClosing(true);

      setTimeout(() => {
        setIsOpen(false);
        document.querySelector('body').style.overflow = 'inherit';
        setPage('login');
        if (typeof onClose === 'function') {
          onClose();
        }
      }, 500);
    },
  }));

  useEffect(() => {
    return () => (document.querySelector('body').style.overflow = 'inherit');
  }, []);

  useEffect(() => {
    isOpen === false && setIsClosing(null);
  }, [isOpen]);

  function close() {
    setIsClosing(true);

    setTimeout(() => {
      setIsOpen(false);
      document.querySelector('body').style.overflow = 'inherit';
      setPage('login');
      if (typeof onClose === 'function') {
        onClose();
      }
    }, 500);
  }

  return (
    <>
      {isOpen && (
        <ModalStyles>
          <ModalStyles.LightBox className={isClosing && 'closing'} onClick={close} />

          <ModalStyles.Container className={isClosing && 'closing'} size="500px">
            <ButtonIconOnly
              onClick={close}
              type="button"
              style={{ marginRight: 0, marginLeft: 'auto' }}
            >
              <Close color="var(--color-black)" />
            </ButtonIconOnly>

            <ModalStyles.Content style={{ maxHeight: '90vh', paddingTop: '0.5rem' }}>
              {page === 'login' ? <SignInModalContent setPage={setPage} /> : null}
              {page === 'register' ? <RegisterModalContent setPage={setPage} /> : null}
              {page === 'forgotPassword' ? <ForgotPasswordModalContent setPage={setPage} /> : null}
            </ModalStyles.Content>
          </ModalStyles.Container>
        </ModalStyles>
      )}
    </>
  );
});

const SignInModalContent = ({ setPage }) => {
  const { run, isLoading } = useAsync();
  const { finishSignInWithModal } = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  function handleSubmitLogin(e) {
    e.preventDefault();

    const errors = {};

    if (!email.trim()) {
      errors.email = 'Digite seu e-mail';
    }
    if (!password.trim()) {
      errors.password = 'Digite a senha';
    }
    setErrors(errors);

    if (errors.email || errors.password) {
      return;
    }

    run(
      axios.post('/api/auth/signin', { email, password, type: 'buyer' }).then(res => {
        finishSignInWithModal(res.data);
      })
    ).catch(error => {
      if (error.response?.status === 500) {
        setErrors({ login: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.' });
      } else {
        setErrors({ login: error.response.data?.message });
      }
    });
  }

  return (
    <RegistrationModal>
      <FadeInContainer>
        <h1>Acessar minha conta</h1>

        <LoginModalForm onSubmit={handleSubmitLogin}>
          <label>
            E-mail
            <FormEditableInput
              type="email"
              name="email"
              placeholder="Digite seu e-mail"
              className={errors.email ? 'form-error' : undefined}
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <p className="field-error-message">{errors.email}</p>
          </label>
          <label>
            Senha
            <FormEditableInput
              type="password"
              name="password"
              placeholder="Digite sua senha"
              className={errors.password ? 'form-error' : undefined}
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <p className="field-error-message">{errors.password}</p>
          </label>

          <p style={{ marginBottom: '1rem', color: 'var(--color-red)', fontWeight: 700 }}>
            {errors.login}
          </p>

          <Button>
            {isLoading ? (
              <Load.SmallIcon
                style={{
                  margin: '0 auto',
                  borderColor: 'var(--color-primary-light)',
                  borderTopColor: 'var(--color-white)',
                }}
              />
            ) : (
              'Acessar minha conta'
            )}
          </Button>
          <Button type="button" variant="secondary" onClick={() => setPage('register')}>
            Criar nova conta
          </Button>
          <button
            type="button"
            className="link-button"
            style={{ marginTop: '1rem' }}
            onClick={() => setPage('forgotPassword')}
          >
            Esqueci minha senha
          </button>
        </LoginModalForm>
      </FadeInContainer>
    </RegistrationModal>
  );
};

const RegisterModalContent = ({ setPage }) => {
  const { run, isLoading } = useAsync();
  const { finishSignInWithModal } = useAuthContext();

  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [isPersonalEmail, setIsPersonalEmail] = useState(false);

  const emails = ["gmail.com", "hotmail.com", "outlook.com", "yahoo.com", "bol.com.br"];

  // useEffect(() => {
  //   const split = email?.split("@")[1];

  //   if(split?.length > 0){
  //     emails.forEach( e => {
  //       if(split.includes(e)) setIsPersonalEmail(true);
  //     })
  //   } else {
  //     setIsPersonalEmail(false);
  //   }
  // }, [email]);

  function handleSubmitRegister(e) {
    e.preventDefault();

    setErrors({});
    const errors = {};

    // Email validation regex from html specification https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!name.trim()) {
      errors.name = 'Você precisa informar seu nome';
    }
    if (!email.trim()) {
      errors.email = 'Você precisa informar um e-mail';
    } else if (!emailRegEx.test(email)) {
      errors.email = 'Endereço de e-mail inválido';
    }
    if (!password.trim()) {
      errors.password = 'Você precisa definir uma senha';
    }
    if (!passwordConfirmation.trim()) {
      errors.passwordConfirmation = 'Confirme sua nova senha';
    } else if (passwordConfirmation !== password) {
      errors.passwordConfirmation = 'As senhas não são iguais';
    }
    if (!termsChecked) {
      errors.terms = 'Você deve aceitar os termos de uso antes de continuar';
    }

    if (Object.values(errors).length > 0) {
      setErrors(errors);
      return;
    }

    run(
      axios
        .post('/api/auth/signup', {
          name,
          email,
          password,
          type: 'buyer',
        })
        .then(() => {
          axios.post('/api/auth/signin', { email, password }).then(res => {
            finishSignInWithModal(res.data);
          });
        })
    ).catch(error => {
      if (error.response?.status === 500) {
        setErrors({
          register: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.',
        });
      } else {
        setErrors({ register: error.response.data?.message });
      }
    });
  }

  return (
    <RegistrationModal>
      <FadeInContainer>
        <h1>Criar nova conta</h1>

        <LoginModalForm onSubmit={handleSubmitRegister}>
          <label>
            Nome
            <FormEditableInput
              type="name"
              name="name"
              className={errors.name ? 'form-error' : undefined}
              placeholder="Digite seu nome"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <p className="field-error-message">{errors.name}</p>
          </label>
          <label>
            E-mail corporativo
            <FormEditableInput
              type="email"
              name="email"
              className={errors.email ? 'form-error' : undefined}
              placeholder="Digite seu e-mail corporativo"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <p className="field-error-message">{errors.email}</p>
            {
              isPersonalEmail && (
                <p className="field-error-message">
                  Atenção! Use um e-mail corporativo para finalizar o pedido
                </p>
              )
            }
          </label>
          <label>
            Senha
            <FormEditableInput
              type="password"
              name="password"
              className={errors.password ? 'form-error' : undefined}
              placeholder="Digite sua senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <p className="field-error-message">{errors.password}</p>
          </label>
          <label>
            Confirmar senha
            <FormEditableInput
              type="password"
              name="confirm_password"
              className={errors.passwordConfirmation ? 'form-error' : undefined}
              placeholder="Confirme sua senha"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
            <p className="field-error-message">{errors.passwordConfirmation}</p>
          </label>
          <label
            className="checkbox__label"
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '0 4px',
              paddingTop: '0.5rem',
              gap: '0.75rem',
            }}
          >
            <input
              className={`checkbox ${errors.terms ? 'form-error' : undefined}`}
              type="checkbox"
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
            />
            <span>
              Li e aceito os{' '}
              <a href="/termos/plataforma" target="_blank">
                Termos de uso da plataforma
              </a>
              , a{' '}
              <a href="/termos/politica-privacidade" target="_blank">
                Política de privacidade
              </a>{' '}
              e os{' '}
              <a href="/termos/comprador" target="_blank">
                Termos e condições gerais de compra
              </a>
            </span>
          </label>
          <p className="field-error-message">{errors.terms}</p>

          <p style={{ margin: '1rem 0', color: 'var(--color-red)', fontWeight: 700 }}>
            {errors.register}
          </p>

          <Button disabled={isPersonalEmail}>
            {isLoading ? (
              <Load.SmallIcon
                style={{
                  margin: '0 auto',
                  borderColor: 'var(--color-primary-light)',
                  borderTopColor: 'var(--color-white)',
                }}
              />
            ) : (
              'Criar conta'
            )}
          </Button>
          <Button type="button" variant="secondary" onClick={() => setPage('login')}>
            Já tenho uma conta
          </Button>
        </LoginModalForm>
      </FadeInContainer>
    </RegistrationModal>
  );
};

const ForgotPasswordModalContent = ({ setPage }) => {
  const { run, isLoading, isSuccess } = useAsync();
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');

  function handleSubmit() {
    setErrors({});
    const errors = {};

    // Email validation regex from html specification https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!email.trim()) {
      errors.email = 'Você precisa informar seu e-mail cadastrado';
    } else if (!emailRegEx.test(email)) {
      errors.email = 'Endereço de e-mail inválido';
    }

    if (Object.values(errors).length > 0) {
      setErrors(errors);
      return;
    }

    run(axios.post('/api/auth/forgot_password', { email }).then(res => res.data)).catch(err => {
      if (err.response?.status === 500) {
        setErrors({
          server: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.',
        });
      } else {
        setErrors({ server: err.response.data?.message });
      }
    });
  }

  return (
    <RegistrationModal>
      <FadeInContainer>
        <h1>Esqueci minha senha</h1>
        <h2>Informe o e-mail cadastrado para redefinir sua senha</h2>
        <LoginModalForm>
          <label>
            E-mail
            <FormEditableInput
              type="email"
              className={errors.email ? 'form-error' : undefined}
              placeholder="Digite seu e-mail cadastrado"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <p className="field-error-message">{errors.email}</p>
          </label>

          {errors.server ? (
            <p style={{ margin: '1rem 0 2rem', color: 'var(--color-red)', fontWeight: 700 }}>
              {errors.server}
            </p>
          ) : null}

          {isSuccess ? (
            <p style={{ margin: '1rem 0 2rem', color: 'var(--color-green)', fontWeight: 700 }}>
              Feito! Você receberá nos próximos minutos um e-mail com instruções.
            </p>
          ) : null}

          {isSuccess ? (
            <Button onClick={() => setPage('login')}>Acessar minha conta</Button>
          ) : (
            <>
              <Button type="button" onClick={handleSubmit}>
                {isLoading ? (
                  <Load.SmallIcon
                    style={{
                      margin: '0 auto',
                      borderColor: 'var(--color-primary-light)',
                      borderTopColor: 'var(--color-white)',
                    }}
                  />
                ) : (
                  'Redefinir senha'
                )}
              </Button>
              <Button variant="secondary" onClick={() => setPage('register')}>
                Criar novo cadastro
              </Button>
            </>
          )}
        </LoginModalForm>
      </FadeInContainer>
    </RegistrationModal>
  );
};
