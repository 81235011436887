import React, { useEffect } from 'react';
import axios from 'axios';
import { useAsync } from '../../../../utils/useAsync';

export function useViaCep() {
  const {
    run,
    data: validCepData,
    isLoading: isCheckingCEP,
    reset,
    isSuccess: isCheckedCEP,
  } = useAsync({ data: false });

  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (value.length === 8) {
      run(validateCep(value));
    } else {
      reset();
    }
  }, [value, run, reset]);

  const handleChangeCEP = event => {
    const { value } = event.target;
    if (value.length <= 9) {
      setValue(unMaskCEP(value));
    }
  };

  const maskedCEP = maskCEP(value);

  return {
    maskedCEP,
    handleChangeCEP,
    validCepData,
    isCheckingCEP,
    isCheckedCEP,
    valueCEP: value,
    setValueCEP: setValue,
  };
}

function maskCEP(value) {
  if (!value) {
    return '';
  }
  return value.replace(/\D+/g, '').replace(/(\d{5})(\d)/, '$1-$2');
}

function unMaskCEP(maskedCNPJ) {
  return maskedCNPJ.replace(/[^0-9]/g, '');
}

async function validateCep(cep) {
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  if (data.erro) {
    return false;
  }
  return data;
}
