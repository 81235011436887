import React, { useState } from 'react';
import { Button } from '../../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../../Components/common/Buttons/ButtonIconOnly';
import { AngleLeft, AngleRight, Image } from '../../../Components/common/Icons/IconsFamily';
import Modal from '../../../Components/Modal';
import { Item } from '../../../Components/Offer/styles';

export const SubmittedOfferItemDetailsImages = ({ photos, imagesModalRef }) => {
  const [currentImage, setCurrentImage] = useState(0);
  return (
    <Item.Photos>
      {photos.length > 0 ? (
        <>
          {photos.length > 1 && currentImage > 0 && (
            <ButtonIconOnly
              type="button"
              onClick={() => {
                setCurrentImage(currentImage - 1);
              }}
            >
              <AngleLeft />
            </ButtonIconOnly>
          )}

          <img src={photos[currentImage].url} alt="" />
          {photos.length > 1 && currentImage < photos.length - 1 && (
            <ButtonIconOnly
              type="button"
              onClick={() => {
                setCurrentImage(currentImage + 1);
              }}
            >
              <AngleRight />
            </ButtonIconOnly>
          )}
        </>
      ) : (
        <>
          <Image />
          <p>Sem imagem</p>
        </>
      )}
      <Item.PhotosBtn type="button" onClick={() => imagesModalRef.current.open()}>
        Ver fotos
      </Item.PhotosBtn>

      <Modal ref={imagesModalRef} title="Gerenciar imagens do item" modalSize="large">
        <div>
          {photos.length > 0 ? (
            <Item.ModalPhotos>
              {photos.length > 0
                ? photos.map(({ url }, index) => (
                    <Item.ModalPhotos.Image key={index}>
                      <img src={url} alt="" />
                    </Item.ModalPhotos.Image>
                  ))
                : null}
            </Item.ModalPhotos>
          ) : (
            <p className="margin--tb-2">Você não adicionou nenhuma imagem para este item</p>
          )}

          <Button type="button" onClick={() => imagesModalRef.current.close()}>
            Concluído
          </Button>
        </div>
      </Modal>
    </Item.Photos>
  );
};
