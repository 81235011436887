export function formatDate(date) {
  return new Date(date).toLocaleDateString('pt-BR');
}

export function formatDateAndTime(date) {
  const dateObj = new Date(date);
  return (
    dateObj.toLocaleDateString('pt-br') +
    ' às ' +
    dateObj.toLocaleTimeString('pt-br', { hour: 'numeric', minute: 'numeric' })
  );
}
