import React from 'react';
import { formatDate } from '../../../utils';
import { OfferItem } from '../../../Components/Offer/OfferItem';
// Styles & Images
import { OfferPage } from './styles';
import { Image } from '../../../Components/common/Icons/IconsFamily';

export function OfferPreview({
  user,
  offerItems,
  files,
  expirationDate,
  formatDate,
}){
  return(
    <OfferPage.Content style={{top: 0, marginTop: '2rem'}}>
      <div className="container">
        <OfferPage.Body>
          <OfferPage.OfferExpiration >
            <OfferPage.OfferExpirationBox>
              <span> Vencimento: </span>
              {expirationDate ? (
                <>
                  <h5> {formatDate(expirationDate)} </h5>
                  <p>de {formatDate(expirationDate, true)}</p>
                </>
              ) : (
                <h5>- - -</h5>
              )}
            </OfferPage.OfferExpirationBox>

            <OfferPage.OfferExpirationText>
              Data de vencimento <br /> do seu orçamento
            </OfferPage.OfferExpirationText>
          </OfferPage.OfferExpiration>

          <OfferPage.Supplier>
            <figure>
              {
                user?.avatar_url ? (
                  <img style={{ width: '100%' }} src={user?.avatar_url} alt={user?.company_name} />
                ) : (
                  <Image />
                )
              }
            </figure>
            <p>Orçamento de: {user?.company_name}</p>
          </OfferPage.Supplier>

          <form >
            <OfferPage.ItemsList >
              {offerItems
                .sort((a, b) => a.position - b.position)
                .map(item => (
                  <OfferItem
                    key={item.label}
                    item={item}
                    isControlBarEnable={false}
                  />
                ))}
            </OfferPage.ItemsList>

            {
              files?.length > 1 && files && (
                <OfferPage.Files>
                  <h3>Aquivos </h3>
                  {
                    files.filter(x => x.file)
                    .map(({ file }, index) => (
                      <li key={index}>
                        <a href={file.path} target="_blank" rel="noreferrer">
                          {file.name}
                        </a>
                      </li>
                    ))
                  }
                </OfferPage.Files>
              )
            }
          </form>
        </OfferPage.Body>
      </div>
    </OfferPage.Content> 
  )
}