import styled, { keyframes } from 'styled-components';

import loginBg from '../../assets/images/auth_pages/login-modal-bg.jpg';

const AuthContainerAnimation = keyframes `
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
`;

const modalAnimaton = keyframes `
    from{
        opacity: 0;
        transform: translateX(30rem) skewX( -8deg );
    }

    to{
        opacity: 1;
        transform: translateX(0) skewX( -8deg );
    }
`;

const modalAnimaton2 = keyframes `
    from{
        opacity: 0;
        transform: translateX(-30rem) skewX( -8deg );
    }

    to{
        opacity: 1;
        transform: translateX(0) skewX( -8deg );
    }
`;

export const AuthContainer = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
`;

/*===============================
        Register modal
===============================*/

AuthContainer.Grid = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Others */
    background-image: url( ${ loginBg } );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: ${ AuthContainerAnimation } 0.3s ease-out forwards;

    @media (min-width: 800px){
        /* Display & Box Model */
        flex-direction: row;
    }
`;

AuthContainer.Center = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;

    /* Colors */
    color: var(--color-text-in-black);

    /* Text */
    text-align: center;

    /* Others */
    animation: ${ AuthContainerAnimation } 0.3s ease-out forwards;

    .btn{
        /* Display & Box Model */
        margin: 1rem auto;
    }

    h3{
        /* Text */
        font-size: 2rem;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 25rem;
    }
`;

AuthContainer.CloseRegisterModal = styled.div `
    /* Display & Box Model */
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    /* Colors */
    color: var(--color-white);

    /* Position */
    position: absolute;
    bottom: 3rem;

    /* Others */
    cursor: pointer;
`;

AuthContainer.Box = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    div{
        /* Display & Box Model */
        width: 60%;
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;

        /* Colors */
        color: var(--color-text-in-black);

        /* Text */
        text-align: center;
    }

    p, h3, img{
        /* Display & Box Model */
        display: none;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 25rem;
        height: 100vh;
        margin: auto;

        /* Colors */
        background-image: var(--color-purple-gradient);

        /* Position */
        position: absolute;

        /* Others */
        border-left: solid 0.1rem var(--color-primary);
        border-right: solid 0.1rem var(--color-primary);
        overflow: hidden;
        opacity: 0;
        transform: skewX( -8deg );

        &:nth-child(2){
            left: -3rem;

            /* Colors */
            background-image: var(--color-purple-gradient-auth-pages);

            /* Others */
            animation: ${ modalAnimaton2 } 0.3s ease-in 0.3s forwards;
        }

        &:nth-child(3){
            right: -3rem;

            /* Others */
            animation: ${ modalAnimaton } 0.3s ease-in 0.3s forwards;
        }

        a{
            /* Colors */
            color: var(--color-text-in-black);
        }

        div{
            /* Others */
            transform: skewX( 8deg );
        }

        h3{
            /* Display & Box Model */
            display: block;

            /* Colors */
            color: var(--color-text-in-black);

            /* Text */
            font-size: 2rem;
        }

        p, img{
            /* Display & Box Model */
            display: block;
        }
    }
`;


/*===============================
        Login modal
===============================*/

AuthContainer.Login = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Others */
    background-image: url( ${ loginBg } );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    animation: ${ AuthContainerAnimation } 0.3s ease-out forwards;
`;

AuthContainer.CloseLoginModal = styled.div `
    /* Display & Box Model */
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;

    /* Colors */
    color: var(--color-white);

    /* Position */
    position: absolute;
    bottom: 1rem;
    z-index: 99;

    /* Others */
    cursor: pointer;

    @media (min-width: 800px){
        /* Position */
        top: 2rem;
        left: 3rem;
        bottom: auto;
    }
`;

AuthContainer.LoginBox = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    /* Colors */
    background-image: var(--color-purple-gradient);

    /* Position */
    position: absolute;

    form{
        /* Display & Box Model */
        width: 18rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-gap: 1rem;
        margin: auto;

        /* Text */
        text-align: center;

        .password__visible{
            position: absolute;
            top: 4.6rem;
            right: 0;
            cursor: pointer;
            padding: 0.5rem 0.8rem;
            background-color: var(--color-white);
            transition: all .3s ease-in;

            &.unvisible{
                opacity: 0.5;
            }

            &.visible{
                opacity: 1;
                background-color: var(--color-secondary);
            }
        }
    }

    a{
        /* Colors */
        color: var(--color-text-in-black);
    }

    h3{
        /* Colors */
        color: var(--color-text-in-black);

        /* Text */
        font-size: 2rem;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 30rem;

        /* Position */
        right: -3rem;

        /* Others */
        border-left: solid 0.1rem var(--color-primary);
        border-right: solid 0.1rem var(--color-primary);
        overflow: hidden;
        opacity: 0;
        animation: ${ modalAnimaton } 0.3s ease-in 0.3s forwards;
        transform: skewX( -8deg );

        form{
            /* Others */
            transform: skewX( 8deg );
        }
    }
`;

AuthContainer.LoginGraphisms = styled.img `
    /* Display & Box Model */

    /* Position */
    position: absolute;
    top: -2rem;
    right: -1rem;

    /* Others */
    transform: skewX( 8deg );
`;

AuthContainer.Title = styled.h5 `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;
    display: none;

    /* Colors */
    color: var(--color-text-in-black);

    /* Text */
    font-size: 3rem;
    text-align: left;

    @media (min-width: 800px){
        /* Display & Box Model */
        display: block;
    }
`;

AuthContainer.Text = styled.p `
    /* Display & Box Model */
    width: 40%;
    display: none;

    /* Colors */
    color: var(--color-text-in-black);

    /* Text */
    font-size: 1.2rem;
    font-weight: 300;
    text-align: left;

    @media (min-width: 800px){
        /* Display & Box Model */
        display: block;
    }
`;

AuthContainer.Hr = styled.hr `
    /* Display & Box Model */
    width: 90%;

    /* Colors */
    color: var(--color-white);

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 100%;
    }
`;