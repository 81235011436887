import styled, { keyframes } from 'styled-components';

/*================================
            Categories
=================================*/

export const CategoriesPage = styled.ul`
  /* Display & Box Model */
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;

  @media (min-width: 501px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

CategoriesPage.Category = styled.li`
  /* Display & Box Model */
  width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: auto;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;

  /* Others */
  border: 0.1rem solid var(--color-border);
  border-radius: 0.3rem;
  cursor: pointer;

  &.added::after {
    /* Display & Box Model */
    content: '\\2714';
    width: 4rem;
    height: 4rem;
    padding-right: 0.8rem;

    /* Colors */
    background-color: var(--color-green);
    color: var(--color-white);

    /* Text */
    font-size: 1.5rem;
    text-align: right;

    /* Position */
    position: absolute;
    top: 0;
    right: 0;

    /* Others */
    box-sizing: border-box;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
  }

  &.selected {
    /* Others */
    border: 0.2rem solid var(--color-purple-dark);

    ::before {
      /* Display & Box Model */
      content: '';
      width: 2rem;
      height: 1.5rem;

      /* Colors */
      background-color: var(--color-purple-dark);

      /* Position */
      position: absolute;
      bottom: -1.5rem;

      /* Others */
      clip-path: polygon(0 0, 100% 0, 50% 70%, 0 0);
    }
  }
`;

CategoriesPage.CategoryTitle = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 1rem;

  /* Text */
  text-align: center;

  figure {
    /* Display & Box Model */
    width: 80px;
    height: 80px;
    margin: 0.5rem auto;

    svg {
      margin: auto;
      width: 4rem !important;
    }
  }
  svg {
    width: 4rem !important;
  }
  
  p {
    /* Display & Box Model */
    width: 80%;
    height: 2rem;
    margin: auto;

    /* Colors */
    color: var(--color-black);

    /* Text */
    text-align: center;
  }
`;

const CategoryWraperAnime = keyframes`
  from{
    opacity: 0;
    tranform: translateY(-2rem);
  }

  to{
    opacity: 1;
    tranform: translateY(0);
  }
`;

CategoriesPage.CategoryWraper = styled.div`
  /* Display & Box Model */
  width: 100%;

  /* Others */
  animation: ${CategoryWraperAnime} 0.3s ease-out forwards;
  opacity: 0;
  overflow: hidden;
`;

CategoriesPage.WrapperHeader = styled.div`
  /*Display  & Box Model */
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  /* Colors */
  background-color: var(--color-purple-dark);
  color: var(--color-white);

  /* Text */
  font-weight: 700;

  div {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      color: black;
    }

    p {
      transform: translateY(1px);
    }
  }
`;

CategoriesPage.WrapperBody = styled.div`
  /*Display  & Box Model */
  width: 100%;
  /* display: grid;
  grid-template-columns: 1fr; */

  /* Colors */
  background-color: var(--color-gray-light);

  /* Position */
  position: relative;

  /* Others */
  border: 0.1rem solid var(--color-border);

  /* @media (min-width: 501px){
    grid-template-columns: 40% 60%;
  }
  
  @media (min-width: 900px){
    grid-template-columns: 40% 60%;
  } */
`;

CategoriesPage.WrapperSubCategories = styled.ul`
  /*Display  & Box Model */
  width: 100%;

  /* Colors */
  color: var(--color-black);
`;

CategoriesPage.WrapperSubCategoriesItem = styled.li`
  /*Display  & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-bottom: 0.1rem solid var(--color-border);
  border-right: 0.1rem solid var(--color-border);
  cursor: pointer;

  > ul div {
    padding-left: 3rem;
  }

  div {
    /*Display  & Box Model */
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
    padding: 0.5rem 0 0.5rem 1rem;

    p {
      transform: translateY(1px);
    }
  }

  &.selected {
    > div {
      /* Colors */
      background-color: var(--color-gray-light);
      color: var(--color-purple-dark);

      /* Position */
      position: relative;

      /* Others */
      border-bottom: 0.1rem solid var(--color-border);
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: 900px) {
    > ul div {
      padding-left: 6rem;
    }

    div {
      padding: 0.5rem 0 0.5rem 3rem;
    }
  }
`;

CategoriesPage.WrapperMicroCategories = styled.ul`
  /*Display  & Box Model */
  width: 100%;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-black);

  li {
    /*Display  & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    /* Colors */
    /* background: var(--color-gray-light); */

    /* Others */
    border-bottom: 0.1rem solid var(--color-border);
    border-right: 0.1rem solid var(--color-border);
    cursor: pointer;

    &.selected {
      > div {
        /* Colors */
        background-color: var(--color-gray-light);
        color: var(--color-purple-dark);

        /* Position */
        position: relative;

        /* Others */
        border-bottom: 0.1rem solid var(--color-border);
        box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
      }
    }

    ul li {
      flex-direction: row;
      padding: 0 0 0 3rem;

      div {
        padding-left: 3rem;
      }
    }
  }

  @media (min-width: 900px) {
    ul li {
      div {
        padding-left: 6rem !important;
      }
    }
  }
`;

CategoriesPage.CategoryAreaOptionsSection = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.8rem;
`;

CategoriesPage.CategoryAreaOption = styled.div`
  /* Display & Box Model */
  width: fit-content;
  padding: 0.5rem;
  border: 1px solid var(--color-gray);
  border-radius: 0.2rem;

  /* Text */
  font-size: 0.9rem;

  cursor: pointer;
  user-select: none;

  &.selected {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-color: var(--color-primary);
  }
`;

CategoriesPage.SubCategoriesCount = styled.span`
  background-color: var(--color-green);
  color: var(--color-white);
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 700;
  margin-left: 0.5rem;
  border-radius: 50%;
  padding: 1px 5px;
  transform: translateY(-1px);
`;
