import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Button } from '../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../Components/common/Buttons/ButtonIconOnly';
import { AngleLeft, AngleRight } from '../../Components/common/Icons/IconsFamily';
import { ModalStyles } from '../../Components/Modal/styles';
import { toBraCurrency } from '../../utils';
// import { Close } from '../common/Icons/IconsFamily';
import { ProposalPage } from './styles';

const ProposalItemModal = forwardRef((
  { item, addItem, removeItem, isAdded, modalPhotos, subTotal, isExpired, ...props }, ref) => {

  const [currentImage, setCurrentImage] = useState(0);

  const [isOpen, setIsOpen] = useState(null);
  const [isClosing, setIsClosing] = useState(null);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector("header").classList.add("closing");
      document.querySelector("aside").classList.add("closing");
    },
    close() {
      setIsClosing(true);

      setTimeout(() => {
        setIsOpen(false);
        document.querySelector('body').style.overflow = 'inherit';
        document.querySelector("header").classList.remove("closing");
        document.querySelector("aside").classList.remove("closing");
      }, 500);
    },
  }));

  useEffect(() => {
    return () => (document.querySelector('body').style.overflow = 'inherit');
  }, []);

  useEffect(() => {
    isOpen === false && setIsClosing(null);
  }, [isOpen]);

  function close() {
    setIsClosing(true);

    setTimeout(() => {
      setIsOpen(false);
      document.querySelector('body').style.overflow = 'inherit';
    }, 500);
  }

  function openMoralPhotos() {
    close();
    modalPhotos.current.open();
  }

  function closeModal() {
    document.querySelector("header").classList.remove("closing");
    document.querySelector("aside").classList.remove("closing");
    close();
  }

  return (
    <>
      {isOpen && (
        <ModalStyles >
          <ModalStyles.LightBox className={isClosing && 'closing'} onClick={closeModal} />

          <ProposalPage.Item.Modal className={isClosing && 'closing'}>
            <ProposalPage.Item.ModalClose
              children="Fechar"
              onClick={closeModal}
            />

            <ProposalPage.Item.ModalTitle >
              <h2>{item?.name}</h2>
            </ProposalPage.Item.ModalTitle>

            <ProposalPage.Item.ModalGrid >
              {
                item?.photos?.length > 0 && (
                  <ProposalPage.Item.ModalPhoto id="modal">
                    {
                      item?.photos?.length > 1 && currentImage > 0 && (
                        <ButtonIconOnly
                          type="button"
                          onClick={() => {
                            setCurrentImage(currentImage - 1);
                          }}
                        >
                          <AngleLeft />
                        </ButtonIconOnly>
                      )
                    }

                    <img
                      style={{ width: 'auto', height: '100%', position: 'absolute' }}
                      src={item?.photos[currentImage]?.url}
                      alt=""
                      onClick={openMoralPhotos}
                    />

                    {
                      item?.photos?.length > 1 && currentImage < item?.photos?.length - 1 && (
                        <ButtonIconOnly
                          type="button"
                          onClick={() => {
                            setCurrentImage(currentImage + 1);
                          }}
                        >
                          <AngleRight />
                        </ButtonIconOnly>
                      )
                    }
                  </ProposalPage.Item.ModalPhoto>
                )
              }

              <ProposalPage.Item.ModalDescription >
                <textarea
                  className={`input textarea ${!item?.obs && "fullheight"}`}
                  defaultValue={item?.description}
                  disabled
                />

                {
                  item?.obs && (
                    <>
                      <span>Observações: </span>
                      <textarea
                        id='observables'
                        className={`input textarea ${!item?.obs && "fullheight"}`}
                        defaultValue={item?.obs}
                        disabled
                      />
                    </>

                  )
                }
              </ProposalPage.Item.ModalDescription>
            </ProposalPage.Item.ModalGrid>

            <ProposalPage.Item.ModalPrices >
              {item.price_method ? (
                <div>
                  <label>Modo de cobrança</label>
                  <p>{item.price_method}</p>
                </div>
              ) : null}
              <div>
                <label >Quantidade</label>
                {item.quantity ? (
                  item?.editableQuantity === "yes" && !isAdded(item?.label) ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div onClick={props.handleDownQuantity}>
                        <AngleLeft
                          color="var(--color-primary-light)"
                          strokeWidth="4"
                        />
                      </div>
                      <input
                        type="number"
                        value={props.quantity}
                        onChange={event => props.validateQuantity(event.target.value)}
                      />
                      <div onClick={props.handleUpQuantity}>
                        <AngleRight
                          color="var(--color-primary-light)"
                          strokeWidth="4"
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <input
                        type="number"
                        defaultValue={props.quantity}
                        disabled
                      />
                    </div>
                  )
                ) : null}
              </div>
              {item.price_method ? (
                <div>
                  <label>Valor {item.price_method.toLowerCase()}</label>
                  <p>{toBraCurrency(item.buyer_price)}</p>
                </div>
              ) : (
                <div>
                  <label>Valor unitário</label>
                  <p>{toBraCurrency(item.buyer_price)}</p>
                </div>
              )}

              {
                item.final_additional_fee && item.additional_fee_name && (
                  <div>
                    <label>{item.additional_fee_name}</label>
                    <p>{toBraCurrency(item.final_additional_fee)}</p>
                  </div>
                )
              }
              <div>
                <label htmlFor="">Subtotal</label>
                <p>{toBraCurrency(subTotal)}</p>
              </div>

              {
                isAdded(item.label) ? (
                  <Button
                    variant="secondary--danger"
                    buttonSize="small"
                    style={{ width: '100%', minWidth: '15rem' }}
                    onClick={() => removeItem(item.label, item.price)}
                  >
                    Remover do meu evento
                  </Button>
                ) : (
                  <Button
                    variant="purple"
                    buttonSize="small"
                    style={{ width: '100%', minWidth: '15rem' }}
                    disabled={isExpired}
                    onClick={() => addItem(item, props.quantity)}
                  >
                    Adicionar ao meu evento
                  </Button>
                )
              }
            </ProposalPage.Item.ModalPrices>
          </ProposalPage.Item.Modal>
        </ModalStyles>
      )}
    </>
  );
});

export default ProposalItemModal;
