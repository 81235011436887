import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import { AboutPage } from './styles';

// Images
import somosCelebrar from '../../assets/images/about_page/somos-celebrar.png';
import ballsGraphism from '../../assets/images/about_page/balls-graphism.svg';
import services from '../../assets/images/about_page/services.png';
import servicesGraphism from '../../assets/images/about_page/services-graphism.svg';
import servicesEvents from '../../assets/images/about_page/services-events.png';
import servicesOnline from '../../assets/images/about_page/services-online.png';
import servicesBox from '../../assets/images/about_page/services-box.png';
import servicesOnboarding from '../../assets/images/about_page/services-onboarding.png';
import servicesCoffee from '../../assets/images/about_page/services-coffee-break.png';
import servicesBirthday from '../../assets/images/about_page/services-birthdays.png';
import servicesSpecialDates from '../../assets/images/about_page/services-special-dates.png';
import portfolioBNDS from '../../assets/images/about_page/demoday-BNDES-garagem-2019.jpg';
import portfolioGoogle from '../../assets/images/about_page/google-pride.jpg';
import portfolioGraphism from '../../assets/images/about_page/portfolio-graphism.svg';
// Suppliers
import supplier1 from '../../assets/images/about_page/suppliers/supplier_1.jpg';
import supplier2 from '../../assets/images/about_page/suppliers/supplier_2.jpg';
import supplier3 from '../../assets/images/about_page/suppliers/supplier_3.jpg';
import supplier4 from '../../assets/images/about_page/suppliers/supplier_4.jpg';
import supplier5 from '../../assets/images/about_page/suppliers/supplier_5.jpg';
import supplier6 from '../../assets/images/about_page/suppliers/supplier_6.jpg';
import supplier7 from '../../assets/images/about_page/suppliers/supplier_7.jpg';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';

function About(){

    const portfolio = [
        {
            legend: "BNDS Garagem - 2019",
            image: portfolioBNDS
        },
        {
            legend: "Google Pride - 2018",
            image: portfolioGoogle
        }
    ];

    const suppliersImgs = [
        supplier1,
        supplier2,
        supplier3,
        supplier4,
        supplier5,
        supplier6,
        supplier7
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSupplierSlide, setCurrentSupplierSlide] = useState(0);

    const autoChangeSlideTimer = () => {
        setTimeout( (  ) => {
            changeSlide( currentSlide + 1 );
        }, 5000 );
    }



    useEffect( () => {
        window.scrollTo( 0, 0);
    }, [] );

    useEffect( () => {
        autoChangeSlideTimer();
    }, [ currentSlide ]);

    useEffect( () => {

        let supplier = document.querySelector("#suppliers"),
            index = currentSupplierSlide;

            supplier.classList.remove( "exit" );
            
            setTimeout( (  ) => {
                supplier.classList.add( "exit" );
                index <= suppliersImgs.length - 1 ? setCurrentSupplierSlide( index + 1) : setCurrentSupplierSlide( 0 );
            
        }, 5000 );

    }, [ currentSupplierSlide ]);



    function changeSlide( index ){
        let slide = document.querySelector("#slide");

        slide.classList.add( "exit" );

        clearTimeout(autoChangeSlideTimer);

        if( index <= portfolio.length - 1 ){
            timer(index, slide);
        } else {
            timer( 0, slide);
        }
    }

    function timer( index, slide ){
        setTimeout( () => {
            setCurrentSlide( index );
            slide.classList.remove( "exit" );
        }, 300 );
    }

    return(
        <AboutPage >
            <Header />

            <AboutPage.Portfolio >
                <AboutPage.PortfolioSlide id="slide" >
                    <img src={ portfolio[currentSlide].image } alt="" />

                    <AboutPage.PortfolioLeftShadow />
                    <AboutPage.PortfolioSlideLegend >
                        { portfolio[currentSlide].legend }
                    </AboutPage.PortfolioSlideLegend>
                </AboutPage.PortfolioSlide>

                <div className="container">
                    <AboutPage.PortfolioTitleGraphism src={ portfolioGraphism } />
                    <AboutPage.PortfolioTitle >
                        Uma infinidade de talentos que, juntos e combinados, entregam experiências inesquecíveis para multidões. 
                    </AboutPage.PortfolioTitle>

                    <AboutPage.SlideButtons >
                        {
                            portfolio && portfolio.map( (slide, index) => {
                                return(
                                    <AboutPage.SlideButton
                                        className={ currentSlide == index ? "selected" : "" }
                                        onClick={ () => changeSlide( index ) }
                                    />
                                )
                            })
                        }
                    </AboutPage.SlideButtons>
                </div>
            </AboutPage.Portfolio>

            <AboutPage.Celebrar >
                <AboutPage.CelebrarGraphism src={ ballsGraphism } />
                <AboutPage.CelebrarGraphism src={ ballsGraphism } />

                <div className="container">
                    <AboutPage.CelebrarTitle>
                        <img src={ somosCelebrar } alt="Somos Celebrar" />
                    </AboutPage.CelebrarTitle>

                    <AboutPage.CelebrarText >
                        <p> 
                            A Celebrar é uma comunidade de micro e pequenas empresas que oferecem os mais diferentes serviços para a realização de eventos e experiências corporativas.
                        </p>
                        <br />
                        <p>
                            Nossa tecnologia permite que pequenos fornecedores finais possam vender seus serviços para grandes corporações, proporcionando entregas inesquecíveis.
                        </p>
                        <br />
                        <p>
                            A plataforma, moderada por um time de especialistas em eventos, reúne uma comunidade de empreendedores confiáveis, capazes de atender em todo o mundo, demandas de diferentes volumes, de maneira simplificada, ágil e sobretudo com qualidade e inovação. 
                        </p>
                    </AboutPage.CelebrarText>
                </div>
            </AboutPage.Celebrar>

            <AboutPage.Services >
                <AboutPage.ServicesGraphism src={ servicesGraphism } />
                <AboutPage.ServicesGraphism src={ servicesGraphism } />

                <AboutPage.ServicesTitle >
                    <img src={ services } alt="" />
                </AboutPage.ServicesTitle>

                <div className="container">
                    <AboutPage.ServicesGrid >
                        <AboutPage.ServicesCard src={ servicesEvents } />
                        <AboutPage.ServicesCard src={ servicesOnline } />
                        <AboutPage.ServicesCard src={ servicesBox } />
                        <AboutPage.ServicesCard src={ servicesOnboarding } />
                        <AboutPage.ServicesCard src={ servicesCoffee } />
                        <AboutPage.ServicesCard src={ servicesBirthday } />
                        <AboutPage.ServicesCard src={ servicesSpecialDates } />
                    </AboutPage.ServicesGrid>
                </div>
            </AboutPage.Services>

            <AboutPage.Suppliers >
                <AboutPage.SuppliersBgImages id="suppliers">
                    <img src={ suppliersImgs[currentSupplierSlide] } alt="" />
                </AboutPage.SuppliersBgImages>

                <AboutPage.SuppliersShadow />

                <div className="container">
                    <AboutPage.SuppliersTitle >
                        Reunimos aqui o poder do coletivo e temos orgulho da força dessa diversidade. 
                    </AboutPage.SuppliersTitle>
                    
                    <AboutPage.SuppliersText>
                        Já distribuímos 5 milhões de reais em renda para uma comunidade com mais de 2.500 fornecedores através da venda online de 15 mil serviços. E nós só estamos começando.
                    </AboutPage.SuppliersText>

                    <AboutPage.SuppliersButtons >
                        <button className="btn btn--slim bg--primary">
                            <Link to="/cadastro-fornecedor"> Torne-se um fornecedor </Link>
                        </button>

                        <button className="btn btn--slim bg--secondary">
                            <Link to="/cadastro"> Torne-se um comprador </Link>
                        </button>
                    </AboutPage.SuppliersButtons>
                </div>
            </AboutPage.Suppliers>

            <Footer />
        </AboutPage>

    )
}

export default About;