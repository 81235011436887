import React, { useEffect, useRef } from 'react';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { useAsync } from '../../../utils';
import Alert from '../../../Components/Alert';
import axios from 'axios';
import SupplierMenu from '../Menu';
import Modal from '../../../Components/Modal';
import FeedbackChannel from '../FeedbackChannel';
import { useCPF } from '../CompleteRegistration/hooks/useCPF';
import { useCNPJ } from '../CompleteRegistration/hooks/useCNPJ';
import { useRegisteredData } from '../CompleteRegistration/hooks/useRegisteredData';
import { useValidateForm } from '../../../Components/Utils/useValidateForm';
import { InputWithStatus } from '../../../Components/InputWithStatus';
import { UserAccountAside } from './UserAccountAside';
// Styles & Images
import { SupplierPage } from '../styles';
import { UserPage, UserPageAside } from './styles';
import { Load } from '../../../Components/Loader/styles';
import SuccessAlert from '../../../Components/SuccessAlert';
import { Link } from 'react-router-dom';

function SupplierUserAccount() {
  const { user } = useAuthContext();
  const {
    maskedCNPJ,
    handleChangeCNPJ,
    isValidCNPJ,
    isCheckingCNPJ,
    isCheckedCNPJ,
    valueCNPJ,
    setValueCNPJ,
  } = useCNPJ();
  const { maskedCPF, handleChangeCPF, isValidCPF, isCheckedCPF, valueCPF, setValueCPF } = useCPF();
  const [hasTriedToSubmit, setHasTriedToSubmit] = React.useState(false);

  const {
    manager_name,
    known_name,
    company_name,
    registered_cnpj,
    registered_cpf,
    email,
    isLoadingRegisteredData,
    isErrorRegisteredData,
  } = useRegisteredData();

  const { start } = useValidateForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setValueCPF(registered_cpf);
    setValueCNPJ(registered_cnpj);
  }, [registered_cpf, setValueCPF, registered_cnpj, setValueCNPJ]);

  const { run, isLoading, isError } = useAsync();

  const submitCompanyData = event => {
    event.preventDefault();
    const form = document.querySelector('form');

    start(form, () => () => {
      if (!isValidCPF || !isValidCNPJ) {
        setHasTriedToSubmit(true);
        return;
      }

      const formData = new FormData(form);

      run(
        axios
          .post('/api/supplier/register-company', {
            manager_name: formData.get('manager_name'),
            manager_cpf: valueCPF,
            known_name: formData.get('known_name'),
            cnpj: valueCNPJ,
            company_name: formData.get('company_name'),
            email: formData.get('manager_email'),
          })
          .then(() => {
            updateUserData({
              name: formData.get('manager_name'),
              email: formData.get('manager_email'),
            });
            successAlertRef.current.open();
          })
      );
    });
  };

  // User
  const { signOut, updateUserData } = useAuthContext();
  // Modal & Alert refs
  const deleteAccountModalRef = useRef();
  const deleteAccountConfirmationRef = useRef();
  const errorAlertRef = useRef();
  const successAlertRef = useRef();
  // States
  const { run: runDeleteAccount, isLoading: isLoadingDeleteAccount } = useAsync();
  function handleDeleteAccount(event) {
    event.preventDefault();
    runDeleteAccount(
      axios
        .post('/api/user/delete-account')
        .then(() => {
          deleteAccountModalRef.current.close();
          deleteAccountConfirmationRef.current.open();
          setTimeout(() => signOut(), 10000); //wait 10 seconds before sign out
        })
        .catch(err => {
          console.log(err.response?.message);
          errorAlertRef.current.open();
        })
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <SupplierPage.Container>
        <div className="row">
          <UserAccountAside />

          <div className="col l9 m12 s12">
            {isLoadingRegisteredData ? (
              <div style={{ height: '70vh' }}>
                <Load.CenterIcon>
                  <Load.SmallIcon />
                </Load.CenterIcon>
              </div>
            ) : isErrorRegisteredData ? (
              <div
                style={{
                  height: '30vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'var(--color-red)',
                  fontWeight: 'bold',
                }}
              >
                <p>Erro ao carregar os dados da empresa</p>
              </div>
            ) : (
              <SupplierPage.Box style={{ padding: '2rem' }}>
                <UserPage.Title>
                  <h3>Minha empresa</h3>
                  <p>Mantenha seus dados e da sua empresa atualizados.</p>
                </UserPage.Title>

                {user?.is_activated ? (
                  <form>
                    <div className="row">
                      <div className="col m6 s12">
                        <div className="input__field">
                          <label htmlFor="manager_name">Nome do responsável *</label>
                          <input
                            type="text"
                            name="manager_name"
                            defaultValue={manager_name}
                            id="manager_name"
                            placeholder="Nome do titular ou sócio administrador"
                            className="input"
                            required
                          />
                        </div>
                      </div>

                      <div className="col m6 s12">
                        <div className="input__field">
                          <label htmlFor="manager_name">E-mail da conta *</label>
                          <input
                            type="mail"
                            name="manager_email"
                            defaultValue={email}
                            id="manager_email"
                            placeholder="E-mail de acesso à conta"
                            className="input"
                            required
                          />
                        </div>
                      </div>

                      <div className="col m6 s12">
                        <InputWithStatus
                          label="CPF *"
                          placeholder="CPF do responsável pela empresa"
                          name="cpf"
                          inputId="cpf"
                          value={maskedCPF}
                          onChange={handleChangeCPF}
                          isError={(isCheckedCPF || hasTriedToSubmit) && !isValidCPF}
                          isSuccess={isValidCPF}
                          errorMessage="O CPF inserido é inválido"
                          required
                        />
                      </div>

                      <div className="col m6 s12">
                        <div className="input__field">
                          <label htmlFor="known_name"> Nome fantasia * </label>
                          <input
                            type="text"
                            name="known_name"
                            id="known_name"
                            defaultValue={known_name}
                            placeholder="Nome fantasia da empresa"
                            className="input"
                            required
                          />
                        </div>
                      </div>

                      <div className="col m6 s12">
                        <InputWithStatus
                          label="CNPJ *"
                          placeholder="CNPJ da empresa"
                          name="cnpj"
                          inputId="cnpj"
                          value={maskedCNPJ}
                          onChange={handleChangeCNPJ}
                          isLoading={isCheckingCNPJ}
                          isSuccess={isValidCNPJ}
                          isError={(isCheckedCNPJ || hasTriedToSubmit) && !isValidCNPJ}
                          errorMessage="O CNPJ inserido é inválido"
                          required
                        />
                      </div>

                      <div className="col m6 s12">
                        <div className="input__field">
                          <label htmlFor="company_name">
                            {' '}
                            Razão social (conforme cartão CNPJ da empresa) *
                          </label>
                          <input
                            type="text"
                            name="company_name"
                            id="company_name"
                            defaultValue={company_name}
                            placeholder="Razão social da empresa"
                            className="input"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {isError ? (
                      <p style={{ color: 'var(--color-red)', fontWeight: 700 }}>
                        Ocorreu um erro no envio dos dados. Tente novamente mais tarde.
                      </p>
                    ) : null}

                    <button
                      className="btn"
                      type="button"
                      onClick={submitCompanyData}
                      disabled={isLoading}
                    >
                      <span> {isLoading ? 'Enviando...' : 'Salvar'} </span>
                    </button>
                  </form>
                ) : (
                  <p style={{ marginBottom: '4rem' }}>
                    Para poder gerenciar os dados da sua empresa e acessar o painel de vendas,{' '}
                    <Link
                      style={{
                        color: 'var(--color-primary)',
                        textDecoration: 'underline',
                      }}
                      to="/fornecedor/finalizar-cadastro"
                    >
                      complete seu cadastro
                    </Link>
                    .
                  </p>
                )}

                <UserPageAside.DeleteBtn onClick={() => deleteAccountModalRef.current.open()}>
                  Excluir minha conta
                </UserPageAside.DeleteBtn>

                <SuccessAlert ref={successAlertRef}>Dados atualizados com sucesso!</SuccessAlert>

                <Modal title="Excluir conta" ref={deleteAccountModalRef}>
                  <h3 className="modal__title" style={{ marginTop: '2rem' }}>
                    Tem certeza de que deseja excluir sua conta?
                  </h3>

                  <form onSubmit={handleDeleteAccount}>
                    <div className="row">
                      <div className="col m12 s12 txt--left">
                        <button
                          className="btn btn--center btn--secondary"
                          type="submit"
                          disabled={isLoadingDeleteAccount}
                        >
                          <span>
                            {isLoadingDeleteAccount ? 'Excluindo conta...' : 'Sim, excluir conta'}{' '}
                          </span>
                        </button>
                      </div>
                      <div className="col m12 s12 txt--right">
                        <button
                          className="btn btn--center btn--slim txt--gray"
                          type="button"
                          onClick={() => deleteAccountModalRef.current.close()}
                        >
                          <span> Cancelar </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </Modal>

                <Modal title="Excluir conta" ref={deleteAccountConfirmationRef}>
                  <p
                    style={{
                      textAlign: 'center',
                      margin: '1rem auto 2rem',
                      lineHeight: 1.7,
                    }}
                  >
                    Já recebemos a solicitação para excluir a sua conta. <br /> Você será
                    desconectado da plataforma, e em até 72 horas seus dados serão excluídos do
                    sistema.
                  </p>
                  <p></p>
                  <button className="btn btn--center" type="button" onClick={() => signOut()}>
                    <span> Ok </span>
                  </button>
                </Modal>

                <Alert ref={errorAlertRef}>
                  <p>Não foi possível completar a sua solicitação. Tente novamente mais tarde.</p>
                </Alert>
              </SupplierPage.Box>
            )}
          </div>
        </div>
      </SupplierPage.Container>
    </SupplierPage>
  );
}

export default SupplierUserAccount;
