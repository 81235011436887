import React,  { useRef, useEffect } from 'react';
import SupplierSidebarMenu from '../SidebarMenu';
import HeaderPage from '../../../Components/HeaderPage';
import axios from 'axios';

import Alert from '../../../Components/Alert';
import { SupplierPage } from '../styles';

function SupplierNPS(props){

    //  Alert and modal ref functions
    const alertRef = useRef();

    const { handle, id } = props.match.params;
    
    useEffect( () => {
           
        // const { handle, id } = props.match.params;

        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

        axios.post(`/api/fornecedor/nps/${ handle }/${ id }`)
        .then( res => {

            console.log(res.data, "Resposta");

        }).catch( error => {

            console.log(error);

        })

    }, [] );

    /*==============================
        Submit feedback details
    ===============================*/

    function handleSubmit(event) {
        event.preventDefault();
    
        const data = new FormData(event.target);
    
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: `/api/fornecedor/nps/${ handle }`,
            data: {
                description: data.get('description'),
            }
        })
        .then(res => {
            if (res.status === 200) {
                alertRef.current.open();
            }
        })
        .catch(err => console.log(err));
    }

    return(
        <SupplierPage >
            {/* <SupplierSidebarMenu /> */}

            <SupplierPage.Container >
                <SupplierPage.NPS >
                    <HeaderPage 
                        title="Agradecemos a sua participação!"
                        subtitle="Se quiser, use o formulário abaixo para nos dar mais informações:"
                    />

                    <form onSubmit={handleSubmit}>
                        <div className="input__field">
                            <label htmlFor=""> Observações da avaliação </label>
                            <textarea className="input textarea" name="description" id="description" cols="30" rows="10"></textarea>
                        </div>

                        <button className="btn" type="submit">
                            <span> Enviar comentários </span>
                        </button>
                    </form>

                    <Alert ref={ alertRef }>
                        <p> Agradecemos a sua mensagem! </p>
                    </Alert>
                </SupplierPage.NPS>
            </SupplierPage.Container>
        </SupplierPage>
    )
}

export default SupplierNPS;