import React from 'react';
import Header from '../../../Components/Header';
// Styles & Images
import { SucessPage } from './styles';
import Bg from '../../../assets/images/backgrounds/bg-proposal-sucess.png';
import Sucess from '../../../assets/images/proposals/sucess.png';

function DemandSucess() {
  return (
    <SucessPage bg={Bg}>
      <Header buyerPage />

      <div className="container">
        <SucessPage.Content>
          <figure>
            <img src={Sucess} alt="" />
          </figure>
          <div>
            <h1>
              Seu pedido foi realizado <br />
              com sucesso!
            </h1>
            <p>
              Em breve, você receberá em seu e-mail o link mágico para <br />
              acompanhar os orçamentos dos fornecedores em tempo real.
            </p>
          </div>
        </SucessPage.Content>
      </div>
    </SucessPage>
  );
}

export default DemandSucess;
