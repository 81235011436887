import React from 'react';
import { EscalePage } from '../styles';

function EscaleCategoriesBtn({ currentSection, setFilter }){

    const sections = [
        "Início da Escalada",
        "Escale Além do Topo",
        "Meu pet Escale",
        "Orgulho da minha escalada"
    ];

    return(
        <EscalePage.CategoriesBtn >
            {
                sections.map( section => {
                    return(
                        section !== currentSection && (
                            <p onClick={ () => setFilter( section ) } > { section } </p>
                        )
                    )
                })
            }
        </EscalePage.CategoriesBtn>
    )
}

export default EscaleCategoriesBtn;