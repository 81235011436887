import React, { useRef, useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import HeaderPage from '../../../Components/HeaderPage';
import SupplierMenu from '../Menu';
import Modal from '../../../Components/Modal';
import ModalConfirmation from '../../../Components/ModalConfirmation';
import { useAuthContext } from '../../../Contexts/AuthContext';
import FeedbackChannel from '../FeedbackChannel';
import { UserPage } from './styles';
import { UserAccountAside } from './UserAccountAside';

// Styles & Images
import { SupplierPage } from '../styles';
import { BankPage } from '../styles';
import deleteIcon from '../../../assets/icons/trash-icon.svg';

export function UserBankAccount() {
  const modalRef = useRef();
  const deletAccountModalRef = useRef();

  const [bankAccounts, setBankAccounts] = useState([]);
  const [pixKeys, setPixKeys] = useState([]);
  const [accountType, setAccountType] = useState();
  const [isCpf, setIsCpf] = useState();
  const [accountOrPixId, setAccountOrPixId] = useState();

  const { user } = useAuthContext();

  useEffect(() => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    axios({
      method: 'post',
      url: `/api/fornecedor/dados-bancarios`,
      headers: {
        Accept: 'application/json'
      },
      data: {
        user_id: user.id
      }
    })
      .then(res => {
        if (res.data.accounts) {
          setBankAccounts(res.data.accounts);
        }
      })
      .catch(err => console.log(err));

    axios({
      method: 'post',
      url: `/api/fornecedor/chaves-pix`,
      headers: {
        Accept: 'application/json'
      },
      data: {
        user_id: user.id
      }
    })
      .then(res => {
        if (res.data.pix_keys) {
          setPixKeys(res.data.pix_keys);
        }
      })
      .catch(err => console.log(err));

  }, [user.id]);

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    // Regex to return only numbers in CNPJ/CPF fields
    // This must be treated here since using replace() in a null value returns an error
    var cpf = data.get('cpf') ? data.get('cpf').replace(/[^0-9]/g, '') : null;
    var cnpj = data.get('cnpj') ? data.get('cnpj').replace(/[^0-9]/g, '') : null;

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: '/api/fornecedor/dados-bancarios/novo',
      data: {
        user_id: user.id,
        bank_name: data.get('bank_name'),
        bank_agency: data.get('bank_agency'),
        account_type: data.get('account_type'),
        account_number: data.get('account_number'),
        account_name: data.get('account_name'),
        cpf: cpf,
        cnpj: cnpj
      }
    })
      .then(res => {
        if (res.status === 200) {
          // var new_accounts = bankAccounts;
          // new_accounts.push(res.data.result);

          // setBankAccounts(new_accounts);
          // modalRef.current.close();

          window.location.reload();
        }
      })
  }

  function handleSubmitPix(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: '/api/fornecedor/chaves-pix/novo',
      data: {
        user_id: user.id,
        key: data.get('pix_key')
      }
    })
      .then(res => {
        if (res.status === 201) {
          window.location.reload();
        }
      });
  }

  function handleDelete(event) {
    event.preventDefault();

    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: `/api/fornecedor/dados-bancarios/${accountOrPixId}/remover`,
      data: {
        user_id: user.id,
        account_id: accountOrPixId
      }
    })
      .then(res => {
        if (res.status === 200) {
          // var new_accounts = bankAccounts;
          // new_accounts.push(res.data.result);

          // setBankAccounts(new_accounts);
          // modalRef.current.close();

          window.location.reload();
        }
      })
  }

  function handleDeletePix(event) {
    event.preventDefault();
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: `/api/fornecedor/chaves-pix/${accountOrPixId}/remover`,
      data: {
        user_id: user.id,
        pix_key_id: accountOrPixId
      }
    })
      .then(res => {
        if (res.status === 200) {
          window.location.reload();
        }
      })
  }

  return (
    <SupplierPage >
      <SupplierMenu />
      <FeedbackChannel />

      <SupplierPage.Container >
        <div className="row">
          <UserAccountAside />

          <div className="col m9 s12">
            <SupplierPage.Box style={{ padding: "2rem" }}>
              <UserPage.Title >
                <h3>Contas bancárias</h3>
                <p>
                  Cadastre a sua conta bancária em que deseja receber seus pagamentos.
                </p>
                <br />
                <p className="txt--red">
                  <b>Atenção:</b> Confirma todos os campos antes de salvar as informações! <br />
                  Pagamentos estornados por erros de preenchimento só poderão ser solicitados novamente após 90 dias da primeira tentativa.
                </p>
              </UserPage.Title>

              <BankPage >
                <BankPage.CardsGrid >
                  {
                    bankAccounts && bankAccounts.map(bank => {
                      return (
                        <BankPage.Card key={bank.id}>
                          <BankPage.CardGraphism />
                          <BankPage.CardGraphism />

                          <BankPage.CardName >
                            {bank.account_name} - {bank.bank_name}
                          </BankPage.CardName>
                          <p> Agência: {bank.bank_agency} </p>
                          <p> Conta {bank.account_type} </p>
                          <p> Número {bank.account_number} </p>
                          {
                            bank.cpf ? (
                              <p> CPF: {bank.cpf} </p>
                            ) : (
                              <p> CNPJ: {bank.cnpj} </p>
                            )
                          }
                          <BankPage.DeletAccount onClick={() => {
                            setAccountOrPixId(bank.id);
                            setAccountType(bank.accountType);
                            deletAccountModalRef.current.open();
                          }}>
                            <img src={deleteIcon} alt="Deletar conta" id={bank.id} />
                          </BankPage.DeletAccount>
                        </BankPage.Card>
                      )
                    })
                  }
                  {
                    pixKeys && pixKeys.map(pixKey => (
                      <BankPage.Card key={pixKey.id}>
                        <BankPage.CardGraphism />
                        <BankPage.CardGraphism />

                        <BankPage.CardName >
                          Chave Pix
                        </BankPage.CardName>
                        <p> Chave: {pixKey.key} </p>

                        <BankPage.DeletAccount onClick={() => {
                          setAccountType('pix');
                          setAccountOrPixId(pixKey.id);
                          deletAccountModalRef.current.open();
                        }}>
                          <img src={deleteIcon} alt="Deletar conta" id={pixKey.id} />
                        </BankPage.DeletAccount>

                      </BankPage.Card>
                    ))
                  }
                </BankPage.CardsGrid>

                <button
                  className="btn"
                  onClick={() => {
                    setAccountType();
                    setIsCpf();
                    modalRef.current.open();
                  }
                  }
                >
                  <span> Adicionar nova conta </span>
                </button>

                <Modal title="Cadastrar conta bancária" modalSize="large" ref={modalRef} >
                  <h3 className="modal__title"> Cadastro de nova conta bancária </h3>
                  {
                    accountType === 'pix' ? (
                      <form onSubmit={handleSubmitPix} name="register_pix_key">
                        <BankPage.AccountTypeName >
                          Digite a chave pix abaixo
                        </BankPage.AccountTypeName>

                        <div className="input__field">
                          <label htmlFor=""> Chave PIX </label>
                          <input className="input" name="pix_key" type="text" min="1" required />
                        </div>

                        <button className="btn" type="submit">
                          <span> Salvar nova conta </span>
                        </button>
                      </form>
                    ) : (
                      <form onSubmit={handleSubmit} name="register_bank_account">
                        {
                          accountType === undefined && (
                            <Fragment>
                              <BankPage.AccountTypeName >
                                Qual é o tipo da conta?
                              </BankPage.AccountTypeName>

                              <BankPage.GridAccountBtns >
                                <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType("corrente")}>
                                  Conta Corrente
                                </BankPage.AccountTypeBtn>
                                <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType("poupança")}>
                                  Conta Poupança
                                </BankPage.AccountTypeBtn>
                                <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType("pix")}>
                                  Chave PIX
                                </BankPage.AccountTypeBtn>
                              </BankPage.GridAccountBtns>
                            </Fragment>
                          )
                        }

                        {
                          isCpf === undefined && accountType !== undefined && accountType !== "pix" && (
                            <Fragment>
                              <BankPage.AccountTypeName >
                                A conta pertence à pessoa física ou jurídica?
                              </BankPage.AccountTypeName>

                              <BankPage.GridAccountBtns >
                                <BankPage.AccountTypeBtn type="button" onClick={() => setIsCpf(false)}>
                                  Conta Pessoa Jurídica - CNPJ
                                </BankPage.AccountTypeBtn>
                                <BankPage.AccountTypeBtn type="button" onClick={() => setIsCpf(true)}>
                                  Conta Pessoa Física - CPF - SOMENTE PARA MEI
                                </BankPage.AccountTypeBtn>
                              </BankPage.GridAccountBtns>
                            </Fragment>
                          )
                        }

                        {
                          accountType !== undefined && isCpf !== undefined && (
                            <Fragment >
                              <div className="row">
                                <div className="col m6 s12">
                                  <div className="input__field">
                                    <label htmlFor=""> Nome do banco </label>
                                    <input className="input" name="bank_name" type="text" min="1" required />
                                  </div>
                                </div>

                                {/* Account type input */}
                                <input
                                  type="text"
                                  name="account_type"
                                  id=""
                                  value={accountType}
                                  style={{ display: "none" }}
                                />

                                <div className="col m6 s12">
                                  <div className="input__field">
                                    <label htmlFor=""> Número da agência </label>
                                    <input className="input" name="bank_agency" type="text" min="1" required />
                                  </div>
                                </div>
                                <div className="col m6 s12">
                                  <div className="input__field">
                                    <label htmlFor=""> Número da conta </label>
                                    <input className="input" name="account_number" type="text" min="1" required />
                                  </div>
                                </div>

                                <div className="col m6 s12">
                                  <div className="input__field">
                                    <label htmlFor=""> {isCpf ? "CPF" : "CNPJ"} do titular </label>
                                    <input className="input" name={isCpf ? "cpf" : "cnpj"} type="text" min="1" required />
                                  </div>
                                </div>
                                <div className="col m6 s12">
                                  <div className="input__field">
                                    <label htmlFor=""> Nome do titular da conta </label>
                                    <input className="input" name="account_name" type="text" min="1" required />
                                  </div>
                                </div>
                              </div>

                              <button className="btn" type="submit">
                                <span> Salvar nova conta </span>
                              </button>
                            </Fragment>
                          )
                        }

                      </form>
                    )
                  }

                </Modal>

                <ModalConfirmation ref={deletAccountModalRef}>
                  {
                    accountType === 'pix' ? (
                      <Fragment>
                        <h3 className="modal__title"> Apagar chave PIX </h3>
                        <p> Deseja mesmo apagar essa chave PIX? </p>

                        <button className="btn btn--warning" onClick={handleDeletePix}>
                          <span> Sim, apagar chave PIX </span>
                        </button>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <h3 className="modal__title"> Apagar conta bancária </h3>
                        <p> Deseja mesmo apagar essa conta bancária? </p>

                        <button className="btn btn--warning" onClick={handleDelete}>
                          <span> Sim, apagar conta bancária </span>
                        </button>
                      </Fragment>
                    )
                  }
                  <button className="btn btn--slim txt--gray" onClick={() => deletAccountModalRef.current.close()}>
                    <span> Não, mudei de ideia </span>
                  </button>
                </ModalConfirmation>
              </BankPage>
            </SupplierPage.Box>

          </div>
        </div>
      </SupplierPage.Container>
    </SupplierPage>
  )
}