import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import EscaleCart from './EscaleCart';
import EscaleForm from './EscaleForm';
import EscaleItems from './EscaleItems';
import { EscalePage } from './styles';
import EscaleFooter from './EscaleFooter';
import EscaleMenu from './EscaleMenu';
import EscaleSlide from './EscaleSlide';
import EscaleCategories from './EscaleCategories';
import EscaleCategoryPage from './EscaleCategoryPage';
import EscaleCategoriesBtn from './EscaleCategoriesBtn';
import escaleGraphism from '../../assets/images/escale/grafismo_escale.svg';

function Escale(){

    const [ proposal, setProposal ] = useState();
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ filterSections, setFilterSections ] = useState();

/*=====================================================
                    Shipping
======================================================*/

    const shippingTablePrices = [
        {
            type: "full",
            weightUpTop: 500,
            price: 29.9,
            regions: "south and shoutheast"
        },
        {
            type: "others",
            weightUpTop: 500,
            price: 32.9,
            regions: "south and shoutheast"
        },
        {
            type: "full",
            weightUpTop: 500,
            price: 29.9,
            regions: "others"
        },
        {
            type: "others",
            weightUpTop: 500,
            price: 52.9,
            regions: "others"
        }
    ];

    useEffect( () => {

        Axios.defaults.headers.post["Acess-Control-Allow-Origin"] = "*";
        Axios.post("/api/proposal/escale")
        .then( res => {

            setProposal( JSON.parse( res.data.proposal.json_object ) );

        });

        let itemsStoraged = JSON.parse( sessionStorage.getItem("items") );
        if( itemsStoraged && itemsStoraged.length > 0 ) {
            setSelectedItems( itemsStoraged );
        }

    }, [] );

    useEffect( () => {

        sessionStorage.setItem("items", JSON.stringify(selectedItems));

    }, [selectedItems] );

/*=====================================================
    Functions to add & remove item from shopping cart
======================================================*/

    function addItemToCart( item ){

        let newItemsList = [...selectedItems, item];
        setSelectedItems( newItemsList );

    }

    function removeItemFromCart( itemId ) {

        let newItemsList = selectedItems.filter( selectedItem => selectedItem.id !== itemId );
        setSelectedItems( newItemsList );

    }

    return(
        <EscalePage >
            {
                filterSections == undefined && (
                    <Fragment >
                        <EscaleMenu />
                        <EscaleSlide />
                        <EscaleCategories setFilter={ setFilterSections } />
                    </Fragment>
                )
            }

            {
                filterSections && (
                    <Fragment >
                        <EscaleMenu />
                        <EscaleCategoryPage
                            section={ filterSections }
                            setFilter={ setFilterSections }
                        />
                    </Fragment>
                )
            }


            <div className="container">
                <EscaleCart
                    selectedItems={ selectedItems }
                    removeItemFromCart={ removeItemFromCart }
                    shipping={ shippingTablePrices }
                />
                
                <EscalePage.PageTitle >
                    <hr /> Itens
                </EscalePage.PageTitle>

                {
                    proposal && filterSections == undefined && proposal.sections.map( section => {
                        return(
                            <EscalePage.Section key={ section.id }>
                                <EscalePage.SectionTitle > { section.name } </EscalePage.SectionTitle>
                                <img
                                    src={ escaleGraphism }
                                    style={
                                        { 
                                            position: "absolute",
                                            right: "-2rem",
                                            top: "8rem",
                                            zIndex: 9
                                        }
                                    }
                                    alt=""
                                />
                                <EscalePage.ItemsGrid >
                                    {
                                        section.items.map( item => {
                                            return(
                                                <EscaleItems
                                                    item={ item }
                                                    key={ item.id }
                                                    selectedItems={ selectedItems }
                                                    setSelectedItems={ setSelectedItems }
                                                    addItemToCart={ addItemToCart }
                                                    removeItemFromCart={ removeItemFromCart }
                                                />
                                            )
                                        })
                                    }
                                </EscalePage.ItemsGrid>
                            </EscalePage.Section>
                        )
                    })
                }

                {
                    filterSections && proposal.sections.map( section => {
                        return(
                            section.name == filterSections && (
                                <EscalePage.Section key={ section.id }>
                                    <EscalePage.BackButton 
                                        onClick={ () => setFilterSections()}
                                        style={ { top: "-1rem" } }
                                    >
                                        Voltar
                                    </EscalePage.BackButton>

                                    <EscaleCategoriesBtn
                                        currentSection={ section.name }
                                        setFilter={ setFilterSections }
                                    />


                                    <EscalePage.SectionTitle > { section.name } </EscalePage.SectionTitle>
                                    <EscalePage.ItemsGrid >
                                        {
                                            section.items.map( item => {
                                                return(
                                                    <EscaleItems
                                                        item={ item }
                                                        key={ item.id }
                                                        selectedItems={ selectedItems }
                                                        setSelectedItems={ setSelectedItems }
                                                        addItemToCart={ addItemToCart }
                                                        removeItemFromCart={ removeItemFromCart }
                                                    />
                                                )
                                            })
                                        }
                                    </EscalePage.ItemsGrid>
                                </EscalePage.Section>
                            )
                        )
                    })
                }
            </div>
            <EscaleFooter />
        </EscalePage>
    )
}

export default Escale;