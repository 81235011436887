import React from 'react';
import styled, { keyframes } from 'styled-components';

export function Button({ variant, buttonSize, align, ...props }) {
  return (
    <>
      {/* ---------------- Primary Buttons */}
      {!variant && (
        <PrimaryButton size={buttonSize} align={align} {...props}>
          {props.children}
        </PrimaryButton>
      )}

      {variant === 'purple' && (
        <PurpleButton size={buttonSize} {...props}>
          {props.children}
        </PurpleButton>
      )}

      {variant === 'white' && (
        <WhiteButton size={buttonSize} {...props}>
          {props.children}
        </WhiteButton>
      )}

      {/* ---------------- Secondary Buttons */}
      {variant === 'secondary' && (
        <SecondaryButton size={buttonSize} {...props}>
          {props.children}
        </SecondaryButton>
      )}

      {variant === 'secondary--danger' && (
        <SecondaryDangerButton size={buttonSize} {...props}>
          {props.children}
        </SecondaryDangerButton>
      )}

      {variant === 'secondary--sucess' && (
        <SecondarySucessButton size={buttonSize} {...props}>
          {props.children}
        </SecondarySucessButton>
      )}

      {/* ---------------- State Buttons */}
      {variant === 'warning' && (
        <WarningButton size={buttonSize} {...props}>
          {props.children}
        </WarningButton>
      )}

      {variant === 'sucess' && (
        <SucessButton size={buttonSize} {...props}>
          {props.children}
        </SucessButton>
      )}

      {variant === 'invisible' && (
        <InvisibleButton size={buttonSize} {...props}>
          {props.children}
        </InvisibleButton>
      )}

      {variant === 'icon' && (
        <WhiteButton size={buttonSize} {...props}>
          {props.children}
        </WhiteButton>
      )}
    </>
  );
}

const sizes = {
  small: {
    padding: '0.5rem 1rem',
    font: '0.8rem',
  },
  default: {
    padding: '1rem 2rem',
    font: '1rem',
  },
  large: {
    padding: '1rem 4rem',
    font: '1rem',
  },
};

const alignment = {
  left: '1rem 0',
  center: '1rem auto',
  right: '1rem 0 1rem auto',
  default: '1rem 0',
};

const PrimaryButtonFocusAnime = keyframes`
  from{
    background-color: var(--color-primary-light);
  }
  to{
    background-color: var(--color-primary-dark);
  }
`;

const StyledButton = styled.button`
  /* Display & Box Model */
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: ${({ align }) => (!align ? alignment['default'] : alignment[align])};
  padding: ${({ size }) => (!size ? sizes.default.padding : sizes[size].padding)};

  /* Text */
  font-size: ${({ size }) => (!size ? sizes.default.font : sizes[size].font)};
  font-weight: 600;
  text-align: center;

  /* Others */
  border-radius: 2rem;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    /* Others */
    transition: all 0.3s ease-out;
  }

  &:disabled {
    /* Colors */
    background-color: var(--color-gray) !important;
    color: var(--color-text) !important;

    /* Others */
    cursor: not-allowed;
    border: 1px solid var(--color-border);
  }
`;

const PrimaryButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-primary-light);
  color: var(--color-white);

  &:hover {
    /* Colors */
    background-color: var(--color-primary-dark);
  }

  &:focus {
    /* Others */
    animation: ${PrimaryButtonFocusAnime} 0.5s ease-out forwards;
    transition: all 0.3s ease-out;
  }
`;

const SecondaryButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-white);
  color: var(--color-primary-light);

  /* Others */
  border: 1px solid var(--color-primary-light);

  &:hover {
    /* Colors */
    background-color: rgba(122, 41, 242, 0.1);
    color: var(--color-primary-dark);

    /* Others */
    border: 1px solid var(--color-primary-dark);
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  }

  &:focus {
    /* Colors */
    background-color: var(--color-primary-light);
    color: var(--color-white);
  }
`;

const SecondaryDangerButton = styled(SecondaryButton)`
  /* Colors */
  background-color: var(--color-white);
  color: var(--color-red);

  /* Others */
  border: 1px solid var(--color-red);

  &:hover {
    /* Colors */
    background-color: rgba(233, 41, 41, 0.2);
    color: var(--color-red);

    /* Others */
    border: 1px solid var(--color-red);
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
  }

  &:focus {
    /* Colors */
    background-color: var(--color-red);
    color: var(--color-white);
  }
`;

const SecondarySucessButton = styled(SecondaryButton)`
  /* Colors */
  background-color: var(--color-white);
  color: var(--color-green);

  /* Others */
  border: 1px solid var(--color-green);
`;

const WarningButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-yellow-dark);
  color: var(--color-white);
`;

const SucessButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-green);
  color: var(--color-white);
`;

const InvisibleButton = styled(StyledButton)`
  background-color: transparent;
  color: var(--color-blue-dark);

  :hover {
    /* Colors */
    background-color: var(--color-blue-dark);
    color: var(--color-white);
  }
`;

const PurpleButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-purple-light);
  color: var(--color-white);

  :hover {
    /* Colors */
    background-color: var(--color-purple-dark);
    color: var(--color-white);
  }
`;

const WhiteButton = styled(StyledButton)`
  /* Colors */
  background-color: var(--color-white);
  color: var(--color-primary-light);
  border: 1px solid var(--color-white);

  :hover {
    /* Colors */
    background-color: var(--color-primary-light);
    color: var(--color-white);
  }
`;
