import React, { useRef, useState, useEffect, Fragment } from 'react';
import { ButtonBack } from '../../../Components/common/Buttons/ButtonBack';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import axios from 'axios';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Button } from '../../../Components/common/Buttons/Button';
import Modal from '../../../Components/Modal';
import Tooltip from '../../../Components/Tooltip';
import { BankPage } from '../styles';
// Styles & Images
import { WalletPage } from './styles';
import WalletBg from '../../../assets/images/supplier/wallet_bg.jpg';
import { formatDate, toBraCurrency, useAsync } from '../../../utils';
import { Load } from '../../../Components/Loader/styles';
import { Link } from 'react-router-dom';

function Wallet() {
  const { user } = useAuthContext();
  const { run, isLoading, data } = useAsync({ status: 'pending' });
  const {
    run: runSubmitWithdrawal,
    isLoading: isSubmittingWithdrawal,
    isError: isSubmitWithdrawalError,
  } = useAsync();
  const payments = data?.payments || [];
  const availablePayments = data?.availablePayments || [];
  const availableBalance = toBraCurrency(
    availablePayments.reduce(
      (balance, payment) => balance + parseFloat(payment.final_supplier_payment),
      0
    )
  );
  const bankAccounts = data?.bankAccounts || [];
  const pixKeys = data?.pixKeys || [];

  useEffect(() => {
    run(axios.get('/api/supplier/wallet').then(response => response.data));
  }, [run]);

  // Modals Refs
  const withdrawBalanceModalRef = useRef();
  const confirmWithdrawBalanceModalRef = useRef();
  const bankAccountModalRef = useRef();
  const registerBankAccountModalRef = useRef();
  const deletAccountModalRef = useRef();
  const successWithdrawalModalRef = useRef();
  const selectPaymentsModalRef = useRef();
  const [selectedWithdrawalPayments, setSelectedWithdrawalPayments] = useState([]);
  const selectedWithdrawalPaymentsTotal = toBraCurrency(
    selectedWithdrawalPayments.reduce(
      (balance, payment) => balance + parseFloat(payment.final_supplier_payment),
      0
    )
  );

  // Bank account and pix keys
  const [bankAccountSelected, setBankAccountSelected] = useState({});

  // Add new bank account
  const [accountType, setAccountType] = useState();
  const [isCpf, setIsCpf] = useState();
  const [accountOrPixId, setAccountOrPixId] = useState();

  function toggleSelectWithdrawalPayment(paymentId) {
    if (selectedWithdrawalPayments.some(p => p.id === paymentId)) {
      setSelectedWithdrawalPayments(payments => payments.filter(p => p.id !== paymentId));
    } else {
      const payment = availablePayments.find(p => p.id === paymentId);
      setSelectedWithdrawalPayments(payments => [...payments, payment]);
    }
  }

  /*=======================================
  Add and remove bank accounts and pix keys
  ========================================*/

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    // Regex to return only numbers in CNPJ/CPF fields
    // This must be treated here since using replace() in a null value returns an error
    var cpf = data.get('cpf') ? data.get('cpf').replace(/[^0-9]/g, '') : null;
    var cnpj = data.get('cnpj') ? data.get('cnpj').replace(/[^0-9]/g, '') : null;

    axios({
      method: 'post',
      url: '/api/fornecedor/dados-bancarios/novo',
      data: {
        user_id: user.id,
        bank_name: data.get('bank_name'),
        bank_agency: data.get('bank_agency'),
        account_type: data.get('account_type'),
        account_number: data.get('account_number'),
        account_name: data.get('account_name'),
        cpf: cpf,
        cnpj: cnpj,
      },
    }).then(res => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  }

  function handleSubmitPix(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    axios({
      method: 'post',
      url: '/api/fornecedor/chaves-pix/novo',
      data: {
        user_id: user.id,
        key: data.get('pix_key'),
      },
    }).then(res => {
      if (res.status === 201) {
        window.location.reload();
      }
    });
  }

  function handleDelete(event) {
    event.preventDefault();

    axios({
      method: 'post',
      url: `/api/fornecedor/dados-bancarios/${accountOrPixId}/remover`,
      data: {
        user_id: user.id,
        account_id: accountOrPixId,
      },
    }).then(res => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  }

  function handleDeletePix(event) {
    event.preventDefault();
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios({
      method: 'post',
      url: `/api/fornecedor/chaves-pix/${accountOrPixId}/remover`,
      data: {
        user_id: user.id,
        pix_key_id: accountOrPixId,
      },
    }).then(res => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  }

  function handleSubmitWithdrawal() {
    runSubmitWithdrawal(
      axios
        .post('/api/supplier/wallet/withdrawal', {
          payments: selectedWithdrawalPayments.map(p => p.id),
          bankAccountSelected,
        })
        .finally(res => {
          confirmWithdrawBalanceModalRef.current.close();
          successWithdrawalModalRef.current.open();
        })
    );
  }

  if (isLoading) {
    return (
      <WalletPage>
        <SupplierMenu />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </WalletPage>
    );
  }

  if (!isLoading && !data) {
    return (
      <WalletPage>
        <SupplierMenu />
        <h1 style={{ paddingTop: '6rem', textAlign: 'center' }}>
          Ops... Ocorreu um erro ao carregar os dados da página. Tente novamente mais tarde.
        </h1>
      </WalletPage>
    );
  }

  return (
    <WalletPage>
      <SupplierMenu />
      <FeedbackChannel />

      <WalletPage.Header bg={WalletBg}>
        <WalletPage.HeaderBlur />
        <ButtonBack link="/fornecedor/painel-de-vendas" />

        <div className="container">
          <h1>Minha carteira</h1>

          <p>Saldo disponível para saque</p>
          <h2>{availableBalance}</h2>

          <Button
            type="button"
            onClick={() => withdrawBalanceModalRef.current.open()}
            variant="purple"
          >
            Sacar saldo disponível
          </Button>
        </div>
      </WalletPage.Header>

      <div className="container">
        <div className="row">
          <div className="col s12 m5">
            <WalletPage.Events>
              <WalletPage.Subtitle>
                Valores a receber
                <p>Pagamentos disponíveis a partir de 05/10/2022 serão listados aqui</p>
              </WalletPage.Subtitle>

              {payments.map((payment, index) => {
                let status, statusClass;
                if (availablePayments.some(p => p.id === payment.id)) {
                  status = 'Disponível';
                  statusClass = 'available';
                } else if (payment.contract.delivery_confirmed_at && payment.invoice?.approved_at) {
                  status = 'Confirmado';
                  statusClass = 'confirmed';
                } else if (payment.invoice?.approved_at) {
                  status = 'NF aprovada';
                  statusClass = 'invoice-approved';
                } else {
                  status = 'Enviar NF';
                }
                return (
                  <WalletPage.Event key={index}>
                    <p>
                      <span>ID do evento:</span>#{payment.contract?.sale_code}
                    </p>
                    <p>
                      <span>Disponível para saque em:</span>
                      {formatDate(payment.payment_expected_at)}
                    </p>
                    <p>
                      <span>Valor a receber:</span>
                      {toBraCurrency(payment.final_supplier_payment)}
                    </p>
                    <div>
                      {status === 'Enviar NF' ? (
                        <Link to={`/fornecedor/confirmacoes-de-venda/${payment.contract.id}`}>
                          <p className={statusClass}>{status}</p>
                        </Link>
                      ) : (
                        <p className={statusClass}>{status}</p>
                      )}
                    </div>
                  </WalletPage.Event>
                );
              })}
            </WalletPage.Events>
          </div>

          <div className="col s12 m7">
            <WalletPage.Banks>
              <WalletPage.Subtitle>Contas bancárias e chaves pix</WalletPage.Subtitle>

              {bankAccounts?.length > 0 &&
                bankAccounts.map(bank => {
                  return (
                    <WalletPage.BankAccount key={bank.id}>
                      <WalletPage.BankAccount.Bank>
                        <p>
                          <span>Banco:</span>
                          {bank.bank_name}
                        </p>
                      </WalletPage.BankAccount.Bank>

                      <WalletPage.BankAccount.Content>
                        <p>
                          <span>Agência:</span>
                          {bank.bank_agency}
                        </p>
                        <p>
                          <span>Conta:</span>
                          {bank.account_number}
                        </p>
                        <p>
                          <span>Tipo da conta:</span>
                          {bank.account_type}
                        </p>
                        <p>
                          <span>{isCpf ? 'CPF' : 'CNPJ'}</span>
                          {bank.cpf ?? bank.cnpj }
                        </p>
                        <p>
                          <span>Titular:</span>
                          {bank.account_name}
                        </p>
                      </WalletPage.BankAccount.Content>
                      <WalletPage.BankAccount.Delete
                        onClick={() => {
                          setAccountOrPixId(bank.id);
                          setAccountType(bank.account_type);
                          deletAccountModalRef.current.open();
                        }}
                      >
                        Apagar conta
                      </WalletPage.BankAccount.Delete>
                    </WalletPage.BankAccount>
                  );
                })}

              {pixKeys?.length > 0 &&
                pixKeys.map(pix => {
                  return (
                    <WalletPage.BankAccount key={pix.id}>
                      <WalletPage.BankAccount.Bank>
                        <p>
                          <span>Chave:</span>
                          PIX
                        </p>
                      </WalletPage.BankAccount.Bank>

                      <WalletPage.BankAccount.Content>
                        <p>
                          <span>Chave:</span>
                          {pix.key}
                        </p>
                      </WalletPage.BankAccount.Content>

                      <WalletPage.BankAccount.Delete
                        onClick={() => {
                          setAccountType('pix');
                          setAccountOrPixId(pix.id);
                          deletAccountModalRef.current.open();
                        }}
                      >
                        Apagar conta
                      </WalletPage.BankAccount.Delete>
                    </WalletPage.BankAccount>
                  );
                })}

              {bankAccounts.length < 1 && pixKeys.length < 1 && (
                <p className="txt--red">
                  Você ainda não cadastrou nenhuma conta bancária ou chave pix
                </p>
              )}

              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  registerBankAccountModalRef.current.open();
                  setAccountType();
                  setIsCpf();
                }}
              >
                Cadastrar nova conta/pix
              </Button>
            </WalletPage.Banks>
          </div>
        </div>
      </div>

      <Modal title="Sacar dinheiro" ref={withdrawBalanceModalRef}>
        {availablePayments.length > 0 ? (
          <>
            <p>Saldo disponível para saque:</p>
            <WalletPage.Balance>{availableBalance}</WalletPage.Balance>
            <WalletPage.ModalBtns>
              <Button
                type="button"
                variant="invisible"
                onClick={() => {
                  withdrawBalanceModalRef.current.close();
                  selectPaymentsModalRef.current.open();
                }}
              >
                Selecionar pagamentos a receber
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setSelectedWithdrawalPayments([...availablePayments]);
                  withdrawBalanceModalRef.current.close();
                  bankAccountModalRef.current.open();
                }}
              >
                Sacar valor total
              </Button>
            </WalletPage.ModalBtns>
          </>
        ) : (
          <>
            <p style={{ paddingBottom: '2rem' }}>
              Você não possui nenhum valor disponível para saque
            </p>
            <WalletPage.ModalBtns>
              <Button
                type="button"
                variant="invisible"
                onClick={() => {
                  withdrawBalanceModalRef.current.close();
                }}
              >
                Voltar
              </Button>
            </WalletPage.ModalBtns>
          </>
        )}
      </Modal>

      <Modal title="Selecionar pagamentos a receber" ref={selectPaymentsModalRef} modalSize="large">
        <WalletPage.ModalBankAccount>
          {availablePayments.map(payment => (
            <li onClick={() => toggleSelectWithdrawalPayment(payment.id)}>
              <WalletPage.ModalBankAccount.Radio
                className={
                  selectedWithdrawalPayments.some(p => p.id === payment.id) ? 'active' : ''
                }
              />
              <p>Id do evento: #{payment.contract.sale_code}</p>
              <p>Valor: {toBraCurrency(payment.final_supplier_payment)}</p>
            </li>
          ))}
        </WalletPage.ModalBankAccount>
        <Button
          type="button"
          disabled={selectedWithdrawalPayments.length === 0}
          onClick={() => {
            selectPaymentsModalRef.current.close();
            bankAccountModalRef.current.open();
          }}
        >
          {selectedWithdrawalPayments.length === 0
            ? 'Selecione ao menos um pagamento'
            : `Sacar ${selectedWithdrawalPaymentsTotal}`}
        </Button>
      </Modal>

      <Modal title="Contas nova conta bancária/pix" ref={bankAccountModalRef} modalSize="large">
        <WalletPage.Warning>
            Atenção: A primeira retirada é gratuita. A partir da segunda, serão repassadas ao banco as tarifas de transferência a seguir:
            <br />
            <br />
            Transferência para outros bancos: R$ 3,99 por TED enviada<br />
            Transferência via Pix Dados Bancários: R$ 0,50 por transferência<br />
            <br />
            No momento não estamos efetuando pagamentos por Chave PIX, apenas PIX via Dados Bancários.
            <br />
        </WalletPage.Warning>

        <WalletPage.ModalBankAccount>
          <p>Escolha a conta bancária</p>

          {bankAccounts?.length > 0 &&
            bankAccounts.map(bank => {
              return (
                <li
                  onClick={() => setBankAccountSelected({ type: 'bankAccount', id: bank.id })}
                  key={bank.id}
                >
                  <WalletPage.ModalBankAccount.Radio
                    className={
                      bankAccountSelected.id === bank.id &&
                      bankAccountSelected.type === 'bankAccount'
                        ? 'active'
                        : ''
                    }
                  />
                  <p>Banco: {bank.bank_name}</p>
                  <p>Agência: {bank.bank_agency}</p>
                  <p>Conta: {bank.account_number}</p>
                  <p>Tipo {bank.account_type}</p>
                </li>
              );
            })}

          {/* {pixKeys?.length > 0 &&
            pixKeys.map(pix => {
              return (
                <li
                  onClick={() => setBankAccountSelected({ type: 'pix', id: pix.id })}
                  style={{ justifyContent: 'start' }}
                  key={pix.id}
                >
                  <WalletPage.ModalBankAccount.Radio
                    className={
                      bankAccountSelected.id === pix.id && bankAccountSelected.type === 'pix'
                        ? 'active'
                        : ''
                    }
                  />
                  <p className="margin--lr-2">Chave pix: {pix.key}</p>
                </li>
              );
            })} */}

          {bankAccounts.length < 1 && pixKeys.length < 1 ? (
            <>
              <p className="txt--red">
                Você ainda não cadastrou nenhuma conta bancária ou chave pix
              </p>
              <Button
                type="button"
                onClick={() => {
                  bankAccountModalRef.current.close();
                  registerBankAccountModalRef.current.open();
                  setAccountType();
                  setIsCpf();
                }}
              >
                Cadastrar nova conta/pix
              </Button>
            </>
          ) : (
            <Button
              type="button"
              disabled={!bankAccountSelected?.id}
              onClick={() => {
                bankAccountModalRef.current.close();
                confirmWithdrawBalanceModalRef.current.open();
              }}
            >
              {bankAccountSelected.id ? 'Sacar' : 'Selecione uma conta'}
            </Button>
          )}
        </WalletPage.ModalBankAccount>
      </Modal>

      <Modal title="Cadastrar nova conta/pix" ref={registerBankAccountModalRef} modalSize="large">
        {accountType === 'pix' ? (
          <form onSubmit={handleSubmitPix} name="register_pix_key">
            <BankPage.AccountTypeName>Digite a chave pix abaixo</BankPage.AccountTypeName>

            <div className="input__field">
              <label htmlFor=""> Chave PIX </label>
              <input className="input" name="pix_key" type="text" min="1" required />
            </div>

            <button className="btn" type="submit">
              <span> Salvar nova conta </span>
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmit} name="register_bank_account">
            {accountType === undefined && (
              <Fragment>

                <p className="txt--red">
                  <b>Atenção:</b> Confira todos os campos antes de salvar as informações! <br />
                  Pagamentos estornados por erros de preenchimento só poderão ser solicitados novamente em até 10 dias da primeira tentativa.
                </p>

                <BankPage.AccountTypeName>Qual é o tipo da conta?</BankPage.AccountTypeName>

                <BankPage.GridAccountBtns>
                  <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType('corrente')}>
                    Conta Corrente
                  </BankPage.AccountTypeBtn>
                  <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType('poupança')}>
                    Conta Poupança
                  </BankPage.AccountTypeBtn>
                  <BankPage.AccountTypeBtn type="button" onClick={() => setAccountType('pix')}>
                    Chave PIX
                  </BankPage.AccountTypeBtn>
                </BankPage.GridAccountBtns>
              </Fragment>
            )}

            {isCpf === undefined && accountType !== undefined && accountType !== 'pix' && (
              <Fragment>
                <BankPage.AccountTypeName>
                  A conta pertence à pessoa física ou jurídica?
                </BankPage.AccountTypeName>

                <BankPage.GridAccountBtns>
                  <BankPage.AccountTypeBtn type="button" onClick={() => setIsCpf(false)}>
                    Conta Pessoa Jurídica - CNPJ
                  </BankPage.AccountTypeBtn>
                  <BankPage.AccountTypeBtn type="button" onClick={() => setIsCpf(true)}>
                    Conta Pessoa Física - CPF - SOMENTE PARA MEI
                  </BankPage.AccountTypeBtn>
                </BankPage.GridAccountBtns>
              </Fragment>
            )}

            {accountType !== undefined && isCpf !== undefined && (
              <Fragment>
                <div className="row">
                  <div className="col m6 s12">
                    <div className="input__field">
                      <label htmlFor=""> Nome do banco </label>
                      <input className="input" name="bank_name" type="text" min="1" required />
                    </div>
                  </div>

                  {/* Account type input */}
                  <input
                    type="text"
                    name="account_type"
                    id=""
                    value={accountType}
                    style={{ display: 'none' }}
                  />

                  <div className="col m6 s12">
                    <div className="input__field">
                      <label htmlFor=""> Número da agência </label>
                      <input className="input" name="bank_agency" type="text" min="1" required />
                    </div>
                  </div>
                  <div className="col m6 s12">
                    <div className="input__field">
                      <label htmlFor=""> Número da conta </label>
                      <input className="input" name="account_number" type="text" min="1" required />
                    </div>
                  </div>

                  <div className="col m6 s12">
                    <div className="input__field">
                      <label htmlFor=""> {isCpf ? 'CPF' : 'CNPJ'} do titular </label>
                      <input
                        className="input"
                        name={isCpf ? 'cpf' : 'cnpj'}
                        type="text"
                        min="1"
                        required
                      />
                    </div>
                  </div>
                  <div className="col m6 s12">
                    <div className="input__field">
                      <label htmlFor=""> Nome do titular da conta </label>
                      <input className="input" name="account_name" type="text" min="1" required />
                    </div>
                  </div>
                </div>

                <button className="btn" type="submit">
                  <span> Salvar nova conta </span>
                </button>
              </Fragment>
            )}
          </form>
        )}
      </Modal>

      <Modal title="Apagar conta" ref={deletAccountModalRef}>
        {accountType === 'pix' ? (
          <Fragment>
            <h3 className="modal__title"> Apagar chave PIX </h3>
            <p> Deseja mesmo apagar essa chave PIX? </p>

            <Button variant="secondary--danger" onClick={handleDeletePix}>
              <span> Sim, apagar chave PIX </span>
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className="modal__title"> Apagar conta bancária </h3>
            <p> Deseja mesmo apagar essa conta bancária? </p>

            <Button variant="secondary--danger" onClick={handleDelete}>
              <span> Sim, apagar conta bancária </span>
            </Button>
          </Fragment>
        )}
        <Button variant="invisible" onClick={() => deletAccountModalRef.current.close()}>
          <span> Não, mudei de ideia </span>
        </Button>
      </Modal>

      <Modal title="Confirmar saque" ref={confirmWithdrawBalanceModalRef}>
        {isSubmittingWithdrawal ? (
          <Load.CenterIcon style={{ paddingTop: '2rem' }}>
            <Load.SmallIcon />
          </Load.CenterIcon>
        ) : (
          <>
            <p style={{ paddingBottom: '2rem' }}>
              Tem certeza que deseja sacar {selectedWithdrawalPaymentsTotal}?{' '}
            </p>
            <WalletPage.ModalBtns>
              <Button onClick={handleSubmitWithdrawal}>Sim, quero sacar</Button>
              <Button
                variant="invisible"
                onClick={() => confirmWithdrawBalanceModalRef.current.close()}
              >
                Não, mudei de ideia
              </Button>
            </WalletPage.ModalBtns>
          </>
        )}
      </Modal>

      <Modal
        onClose={() => window.location.reload()}
        title="Confirmar saque"
        ref={successWithdrawalModalRef}
      >
        {isSubmitWithdrawalError ? (
          <p>
            Ocorreu um erro ao confirmar o saque.
            <br />
            Tente novamente mais tarde.
          </p>
        ) : (
          <p>
            Seu saque foi confirmado! Você receberá o valor em até 3 dias úteis na conta bancária
            selecionada.
          </p>
        )}
        <Button onClick={() => successWithdrawalModalRef.current.close()}>Ok</Button>
      </Modal>
    </WalletPage>
  );
}

export default Wallet;
