import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../../Components/Alert';
import { EscalePage } from '../styles';
import axios from 'axios';
import escaleGraphism from '../../../assets/images/escale/grafismo_escale.svg';

function EscaleForm() {

    // Get address informations from sessionStorage
    const address = JSON.parse(sessionStorage.getItem("address"));
    const items = JSON.parse(sessionStorage.getItem("items"));
    // Alert component reference
    const alertRef = useRef();
    // Form states
    const [ name, setName ] = useState();
    const [ email, setEmail ] = useState();
    const [ phone, setPhone ] = useState();
    const [ details, setDetails ] = useState();
    const [ cpf, setCpf ] = useState();
    const [ buyer, setBuyer ] = useState();
    const [ order, setOrder ] = useState();
    // Escale CNPJ
    const cnpjEscale = "18.126.983/0001-72";

    useEffect( () => {

        document.querySelector("body").style.overflow = "auto";
        window.scrollTo( 0, 0);

    }, [] );

    // useEffect( () => {

    //     if ( order != undefined ){
    //         document.querySelector("#escale_order").submit();
    //     }

    // }, [ order ]);


/*=====================================================
        Functions to calculate items price
======================================================*/

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

    
/*=====================================================
                Validate order form
======================================================*/

    function validateForm(event) {
        event.preventDefault();

        let newOrder = {};

        let form = document.querySelector("#escale_order"),
            inputs = Array.from(form.querySelectorAll("input")),
            emptyInput = inputs.filter( input => input.value === "" && input.type !== "hidden");

            console.log(emptyInput)

        if ( emptyInput.length > 0 ) {

            emptyInput.forEach( input => {
                input.style.border = "solid 0.2rem var(--color-red)"
            });

        } else {
 
            inputs.forEach( input => {
                newOrder[input.name] = input.value;
            });

            // newOrder["address"] = address;
            // newOrder["items"] = items;
            // newOrder["details"] = details;

            setOrder( newOrder );            

            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            axios({
                method: 'post',
                url: '/api/escale_store',
                data: {
                    buyer: newOrder,
                    address: address,
                    items: items,
                    details: details,
                }
                })
            .then(res => {
                console.log(res.data, "Resposta");
            })
            .catch(err => console.log(err));

            console.log('Terminou de processar');
        }

    }

    return(
        <EscalePage >
            <div className="container">

                <EscalePage.BackButton >
                    <Link to="/escale"> Voltar </Link>
                </EscalePage.BackButton>

                <EscalePage.PageTitle >
                    <hr /> Finalizar pedido
                </EscalePage.PageTitle>

                <EscalePage.FormPage >
                    <EscalePage.FormItems >
                        <EscalePage.SectionTitle > Seu carrinho </EscalePage.SectionTitle>
                            {
                                items && items.length > 0 && (
                                    items.map( item => {
                                        return(
                                            <EscalePage.CartItem key={ item.id }>
                                                <EscalePage.CartItemImg >
                                                    <img src={ item.photos[0].url } alt="" />
                                                </EscalePage.CartItemImg>

                                                <EscalePage.CartText >
                                                    <EscalePage.CartItemName >
                                                        { item.name }
                                                    </EscalePage.CartItemName>

                                                    <EscalePage.CartItemPrice >
                                                        { toBraCurrency(item.final_buyer_price) }
                                                    </EscalePage.CartItemPrice>

                                                </EscalePage.CartText>
                                            </EscalePage.CartItem>
                                        )
                                    })
                                )
                            }

                        <EscalePage.CartTotalPrice>
                            <div className="input__field" style={ { margin: "2rem auto" } }>
                                <label htmlFor=""> Enviar para: </label>
                                <p> {`${address.logradouro} ${ address.numero ? address.numero : ''}, ${address.bairro} - ${address.localidade} - ${address.uf}`} </p>
                            </div>

                            {
                                address.complemento ? (
                                    <div className="input__field" style={ { margin: "2rem auto" } }>
                                        <label htmlFor=""> Complemento: </label>
                                        <p> { address.complemento } </p>
                                    </div>
                                ) : ''
                            }

                            <div>
                                <span> Subtotal </span> <span> { sessionStorage.getItem("subtotal") } </span>
                            </div>
                            <div>
                                <span> Frete </span> <span> { toBraCurrency(sessionStorage.getItem("shipping")) } </span>
                            </div>
                            <div>
                                <span> Impostos </span> <span> { sessionStorage.getItem("taxes") } </span>
                            </div>
                            <div>
                                <h6> Total </h6> <h6> { sessionStorage.getItem("total") } </h6>
                            </div>
                        </EscalePage.CartTotalPrice>
                        <img
                            src={ escaleGraphism }
                            style={
                                { 
                                    position: "absolute",
                                    right: "-4rem",
                                    top: "8rem",
                                    zIndex: 9
                                }
                            }
                            alt=""
                        />
                    </EscalePage.FormItems>

                    <EscalePage.FormContent >
                        <EscalePage.SectionTitle > Finalizar pedido </EscalePage.SectionTitle>

                        {
                            buyer === undefined ? (
                                <EscalePage.SelectBuyer >
                                    <h3> Quem vai pagar: sua pessoa física ou a Escale? </h3>

                                    <button className="btn btn--secondary" onClick={ () => setBuyer("cpf") }>
                                        <span> Eu vou pagar </span>
                                    </button>

                                    <button className="btn btn--secondary" onClick={ () => setBuyer("cnpj") }>
                                        <span> A Escale vai pagar </span>
                                    </button>
                                </EscalePage.SelectBuyer>
                            ) : (
                                <form onSubmit={validateForm} name="escale_order" id="escale_order" method="POST">
                                    <div className="input__field">
                                        <label htmlFor="name"> Nome e sobrenome *</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="input"
                                            onInput={ event => setName( event.target.value ) }
                                            onFocus={ event => event.target.removeAttribute("style") }
                                            value={ name }
                                        />
                                    </div>

                                    <div className="input__field">
                                        <label htmlFor="email"> E-mail *</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="input"
                                            onInput={ event => setEmail( event.target.value ) }
                                            onFocus={ event => event.target.removeAttribute("style") }
                                            value={ email }
                                        />
                                    </div>

                                    <div className="input__field">
                                        <label htmlFor="phone"> Telefone *</label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="input"
                                            onInput={ event => setPhone( event.target.value ) }
                                            onFocus={ event => event.target.removeAttribute("style") }
                                            value={ phone }
                                        />
                                    </div>

                                    {
                                        buyer == "cpf" && (
                                            <div className="input__field">
                                                <label htmlFor="cpf"> CPF *</label>
                                                <input
                                                    type="text"
                                                    name="cpf"
                                                    id="cpf"
                                                    className="input"
                                                    onInput={ event => setCpf( event.target.value ) }
                                                    onFocus={ event => event.target.removeAttribute("style") }
                                                    value={ cpf }
                                                />
                                            </div>
                                        )
                                    }

                                    {
                                        buyer === "cnpj" && (
                                            <div className="input__field">
                                                <label htmlFor="cnpj"> CNPJ Escale *</label>
                                                <input
                                                    type="text"
                                                    name="cnpj"
                                                    id="cnpj"
                                                    className="input"
                                                    desabled
                                                    value={ cnpjEscale }
                                                />
                                            </div>
                                        )
                                    }
                                    
                                    <div className="input__field">
                                        <label htmlFor="details"> Detalhes e observações </label>
                                        <textarea
                                            name="details"
                                            id="details"
                                            cols="30"
                                            rows="10"
                                            className="input textarea"
                                            onInput={ event => setDetails( event.target.value ) }
                                            value={ details }
                                        >

                                        </textarea>
                                    </div>

                                    <input type="hidden" name="order" value={ order } />

                                    <EscalePage.FormButton type="submit" >
                                        Finalizar pedido
                                    </EscalePage.FormButton>
                                </form>
                            )
                        }

                    </EscalePage.FormContent>
                </EscalePage.FormPage>
            </div>

            <Alert ref={ alertRef }>
                <p> Obrigado! Você receberá a confirmação do pedido no seu e-mail junto com o link para pagamento. </p>
            </Alert>
        </EscalePage>
    )
}

export default EscaleForm;