
import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqProviderStyled } from './Faq.styles.tw';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import Conta from '../../assets/icons/faqIcons/Conta.png'
import NotasFiscais from '../../assets/icons/faqIcons/NotasFiscais.png'
import Plataforma from '../../assets/icons/faqIcons/Plataforma.png'
import Orcamento from '../../assets/icons/faqIcons/Orcamentos.png'
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';




function FaqProvider() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <> <Header />
      <FaqProviderStyled>
        <div className='sizePage'>
          <div className='FaqHeader'>
            <div className="container">
              <h3>Perguntas Frequentes</h3>
            </div>
          </div>

          <div className="container">
            <div className='content'>
              <Link to="/faq/fornecedor/plataforma">
                <div className='cards'>
                  <figure>
                    <img src={Plataforma} />
                  </figure>
                  <p>Plataforma Celebrar</p>
                </div>
              </Link>
              <Link to="/faq/fornecedor/conta">
                <div className='cards'>
                  <figure>
                    <img src={Conta} />
                  </figure>
                  <p>Conta Celebrar</p>
                </div>
              </Link>
              <Link to="/faq/fornecedor/orcamentos">
                <div className='cards'>
                  <figure>
                    <img src={Orcamento} />
                  </figure>
                  <p>Orçamentos</p>
                </div>
              </Link>
              <Link to="/faq/fornecedor/nota-fiscal">
                <div className='cards'>
                  <figure>
                    <img src={NotasFiscais} />
                  </figure>
                  <p>Notas Fiscais</p>
                </div>
              </Link>
              <Link to="/faq/fornecedor/pagamentos">
                <div className='cards'>
                  <figure>
                    <img src={Pagamentos} />
                  </figure>
                  <p>Pagamentos</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </FaqProviderStyled>
      <Footer /></>
  );
}

export default FaqProvider;
