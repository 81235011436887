import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import Marker from '../../assets/icons/faqIcons/marker.svg'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqProviderPayments() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />

      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question1'>
              <p>
                Qual o prazo de pagamento?
              </p>
            </a>
            <a href='#question2'>
              <p>
                Quais são as formas de pagamento?
              </p>
            </a>
            <a href='#question3'>
              <p>
                Como solicitar a liberação do saque?
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Qual o prazo de pagamento?
              </h2>
              <p>
                O prazo de pagamento é baseado na entrega completa do serviço, lembrando que a entrega só será considerada completa após o envio da nota fiscal pela plataforma.
              </p>
              <p>
                Os valores são liberados por lotes e estarão disponíveis para saque nos dias <span>05</span>, <span>15</span> e <span>25</span> (<span>sempre 30 dias após a data de entrega do evento</span>).
              </p>
              <p>
                Não se esqueça que a Nota Fiscal deve sempre ser emitida antes da entrega do evento!
              </p>
              <p>
                <span>Lembrando:</span> Você receberá um e-mail caso sua nota fiscal seja aprovada ou recusada. Fique atenta/o as informações e próximos passos.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col xl9 s12'>
              <div className='row '>
              <h3 id='question1'>
                Fique atento aos prazos
                </h3>
                  <div className='col xl6 s12 marker'>
                    <div>
                      <img src={Marker} />
                      <p>
                      Serviços entregues entre os dias 01 e 05 
                     <br />
                      Pagamento dia 05 do mês seguinte
                      </p>
                    </div>
                  </div>
                  <div className='col xl6 s12 marker'>
                    <div>
                      <img src={Marker} />
                      <p>
                      Serviços entregues entre os dias 06 e 15
                     <br />
                      Pagamento dia 15 do mês seguinte
                      </p>
                    </div>
                  </div>
                  <div className='col xl6 s12 marker'>
                    <div>
                      <img src={Marker} />
                      <p>
                      Serviços entregues entre os dias 16 e 25
                     <br />
                      Pagamento dia 25 do mês seguinte
                      </p>
                    </div>
                  </div>
                  <div className='col xl6 s12 marker'>
                    <div>
                      <img src={Marker} />
                      <p>
                      Serviços entregues entre os dias 26 a 31
                     <br />
                      O saldo será liberado no dia 05 do mês seguinte. Atenção: A nota fiscal deve ser emitida no mês seguinte.
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                Quais são as formas de pagamento??
              </h2>
              <p>
                A forma de pagamento é definida por você durante o cadastro da empresa e é possível alterar e cadastrar seus dados bancários através da área <span>“Minha Carteira”</span>.
              </p>
              <p>
                Vamos lá, você pode receber através de:
              </p>
              <ul>
                <li>
                  Conta poupança
                </li>
                <li>
                  Conta-corrente
                </li>
                <li>
                  Chave PIX
                </li>
              </ul>
              <p>
                Cobramos as seguintes tarifas:
              </p>
              <ul>
                <li>
                  <strong className='txt--bold'>Transferência para outros bancos:</strong> R$ 3,99 por TED enviada
                </li>
                <li>
                  <strong className='txt--bold'>Transferências via Chave Pix:</strong> R$ 0,50 por transferência
                </li>
              </ul>
              <p>
                <span>Importante:</span> Só realizamos pagamentos em contas de pessoa física para quem possui CNPJ-MEI
              </p>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m12'>
              <h2 id='question3'>
                Como solicitar a liberação do saque?
              </h2>
              <p>
                Para solicitar o saque do valor disponível, é muito simples:
              </p>
              <ol>
                <li>
                  1. Acesse <span>“Carteira”</span>
                </li>
                <li>
                  2. Clique no botão <span>“Sacar saldo disponível”</span>

                </li>
                <li>
                  3. Selecione os valores a receber e a conta bancária de destino
                </li>
                <li>
                  4. Assim que você solicitar o pagamento, a compensação do valor será feita em até <span>3 dias úteis</span>.
                </li>
              </ol>
              <p>
                <span>Atenção:</span> Ao solicitar um saque, confira atentamente os dados bancários selecionados.
              </p>
              <p>
                Pedidos de pagamento que não forem processados devido a divergências nos dados bancários informados por você, só poderão ser realizados novamente em até <span>10 dias</span> após a primeira tentativa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >



  );
}