import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Input } from './Forms/styles';

export function FormTextInput({ label, id, isError = false, labelStyle, errorMessage, ...props }) {
  return (
    <div className="input__field">
      <label htmlFor={id} style={labelStyle}>
        {label}
      </label>
      <input type="text" className={isError ? 'input input--error' : 'input'} id={id} {...props} />
      {isError ? <p className="txt--red">{errorMessage}</p> : null}
    </div>
  );
}

export function FormTxtInput({ label, icon, errorMessage, ...props }) {
  return (
    <Input >
      <div className="formTxtInput">
        <label className="hidden">{label}</label>
        <div>
          <input {...props} />
          {icon && <FeatherIcon icon={icon} className="icon" size={23} color="silver" />}
        </div>
        <p className="error-msg txt--left">{errorMessage}</p>
      </div>
    </Input>
  );
}