import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { SuccessAlertBox } from './styles';

const SuccessAlert = forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
            setTimeout(() => close(), 5000);
        },
        close() {
            setIsOpen(false);
        },
    }));

    function close() {
        setIsOpen(false);
    }

    return <SuccessAlertBox>{isOpen ? <div>{props.children}</div> : null}</SuccessAlertBox>;
});

export default SuccessAlert;
