import React from 'react';
import styled from 'styled-components';

export function ButtonLink({ children, ...props }) {
  return (
    <StyledButton type="button" {...props}>
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  font-size: 1rem;
  font-family: inherit;

  color: var(--color-primary-dark);
  background: transparent;

  border: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
