import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import ProposalShoppingCart from './ProposalShoppingCart';
import { Button } from '../../Components/common/Buttons/Button';
import { formatDate, useAsync } from '../../utils';
import { Redirect, useParams } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { useAuthContext } from '../../Contexts/AuthContext';
import Alert from '../../Components/Alert';
import ProposalItem from './ProposalItem';
// Styles & Images
import hourglass from '../../assets/images/proposals/hourglass.svg';
import Image404 from '../../assets/images/404_image.svg';
import { ProposalPage } from './styles';
import { Load } from '../../Components/Loader/styles';
import expiredImage from '../../assets/images/proposals/alert-danger.svg';

function Proposal() {
  const { handle } = useParams();
  const { user, signOut } = useAuthContext();
  const submitOrderErrorAlertRef = useRef();
  const accountModalRef = useRef();
  const obsModalRef = useRef();
  const location = document.location.href;

  const [isMyEventOpen, setIsMyEventOpen] = useState();
  const [proposal, setProposal] = useState(null);
  const [itemsAddedInCart, setItemsAddedInCart] = useState([]);
  const [observations, setObservations] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const totalValue = itemsAddedInCart.reduce(
    (prev, curr) => parseFloat(prev) + parseFloat(curr.final_buyer_price),
    0
  );

  const { run, isLoading, isError, error } = useAsync({ status: 'pending' });
  const isProposalNotFound = isError && error.response.status === 404;
  const isServerError = isError && error.response.status === 500;

  // My Event Button
  const myEventBtn =
    <Button
      children={`Meu evento (${itemsAddedInCart?.length})`}
      onClick={() => setIsMyEventOpen(true)}
      buttonSize="small"
      style={{ display: 'block' }}
    />

  function isItemAddedInCart(id) {
    return itemsAddedInCart.some(e => e.label === id);
  }

  useEffect(() => {
    setIsMyEventOpen(!(parseInt(window.innerWidth) < 1001));
    // if(user) accountModalRef.current.open();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (user) accountModalRef.current.open();
    }, 1000);
  }, [user]);

  useEffect(() => {
    run(
      axios.get(`/api/proposal/v2/${handle}`).then(response => {
        setProposal(response.data.proposal);
        setIsExpired(new Date() > new Date(response.data.proposal.expires_at))
      })
    );
  }, [run, handle]);

  function addItemInCart(item, quantity) {
    setItemsAddedInCart([
      ...itemsAddedInCart,
      {
        ...item,
        quantity,
        final_buyer_price: quantity * item.buyer_price,
        final_supplier_sale_price: quantity * item.supplier_sale_price,
        final_sale_price: quantity * item.sale_price,
      },
    ]);
  }

  function removeItemFromCart(itemId) {
    const items = itemsAddedInCart.filter(e => e.label !== itemId);
    setItemsAddedInCart(items);
  }

  const {
    run: runSubmitOrder,
    isLoading: isSubmittingOrder,
    isSuccess: isOrderApproved,
  } = useAsync();

  // ------------------- Submit order

  function openObsModal() {
    obsModalRef.current.open();
  }

  function handleSubmitOrder() {
    runSubmitOrder(
      axios.post('/api/create-order-v2', {
        client_proposal_id: proposal.id,
        client_proposal_name: proposal.name,
        total_buyer_price: totalValue,
        obs: observations,
        page_url: proposal.page_url,
        items: itemsAddedInCart.map(item => ({
          ...item,
          name: item.item?.name,
        })),
      })
    ).catch(() => {
      submitOrderErrorAlertRef.current.open();
    });
  }

  // ------------------ SignOut

  function userSignOut() {
    signOut().then(() => document.location.assign(location));
  }

  if (isOrderApproved) {
    return (
      <Redirect push to={{ pathname: '/proposta-aprovada', state: { items: itemsAddedInCart } }} />
    );
  }

  if (isLoading) {
    return (
      <ProposalPage>
        <Header />
        <Load.FullPageContainer>
          <Load.SmallIcon />
        </Load.FullPageContainer>
      </ProposalPage>
    );
  }

  if (isProposalNotFound) {
    return (
      <ProposalPage>
        <Header />
        <ProposalPage.NotFound>
          <img src={Image404} alt="404" />
          <h1>Ops... Página não encontrada.</h1>
          <h1>Verifique se o link acessado está correto.</h1>
        </ProposalPage.NotFound>
      </ProposalPage>
    );
  }

  if (isServerError) {
    return (
      <ProposalPage>
        <Header />
        <ProposalPage.NotFound>
          <h1>Ops... Ocorreu um erro ao carregar a proposta.</h1>
          <h1>Por favor, tente novamente mais tarde.</h1>
        </ProposalPage.NotFound>
      </ProposalPage>
    );
  }

  return (
    <ProposalPage>
      <Header
        buyerPage
        hideButtons
        component={myEventBtn}
      />

      <ProposalShoppingCart
        items={itemsAddedInCart}
        removeItem={removeItemFromCart}
        total={totalValue}
        openObsModal={openObsModal}
        isSubmittingOrder={isSubmittingOrder}
        className={isMyEventOpen ? "open" : ""}
        setIsMyEventOpen={setIsMyEventOpen}
        obsModal={obsModalRef}
        isExpired={isExpired}
      />

      <ProposalPage.Header>
        <div className="container">
          <div id="content">
            <h1>Proposta comercial: {proposal?.name}</h1>

            <ProposalPage.ExpiresAt >
              <p>
                <span>Proposta válida até:</span>
                {formatDate(proposal?.expires_at)}
              </p>
            </ProposalPage.ExpiresAt>
          </div>
        </div>

      </ProposalPage.Header>

      <div className="container" style={{ minHeight: '60vh' }}>
        <ProposalPage.Content className={isMyEventOpen ? "proposal--buyer" : ""}>
          {proposal.sections.map((section, index) => (
            <ProposalPage.Section key={index}>
              {section.section_name && (
                <ProposalPage.Section.Title>
                  {section.section_name}
                </ProposalPage.Section.Title>
              )}

              {section.client_proposal_items.length < 1 ? (
                <ProposalPage.Section.Content>
                  <img src={hourglass} id="hourglass" alt="" />
                  <p>Aguardando o recebimento de orçamentos para essa seção</p>
                </ProposalPage.Section.Content>
              ) : (
                <ProposalPage.Section.Content>
                  {section.client_proposal_items.map(clientProposalItem => {
                    const item = {
                      ...clientProposalItem,
                      client_proposal_item_id: clientProposalItem?.id,
                      label: clientProposalItem?.id,
                      name: clientProposalItem?.item?.name,
                      description: clientProposalItem?.item?.description,
                      photos: clientProposalItem?.item?.photos,
                      editableQuantity: clientProposalItem?.editable_quantity,
                      minQuantity: clientProposalItem?.min_quantity,
                      maxQuantity: clientProposalItem?.max_quantity,
                      price: clientProposalItem?.buyer_price,
                    };

                    return (
                      <ProposalItem
                        key={item.label}
                        item={item}
                        addItem={addItemInCart}
                        removeItem={removeItemFromCart}
                        isAdded={isItemAddedInCart}
                        isExpired={isExpired}
                        isMyEventOpen={isMyEventOpen}
                        user={user?.name ?? ""}
                        // reviews={item.supplier.reviews ?? ""}
                      />
                    );
                  })}
                </ProposalPage.Section.Content>
              )}
            </ProposalPage.Section>
          ))}
          <Button align='center' onClick={() => window.print()}>
            Salvar em PDF
          </Button>

          {
            handle === 'impacto-mr-1666790295' && (
              <a href="https://drive.google.com/file/d/1cAFDSa5DoMlDhmMURo__TxbPSWzx7-J0/view?usp=share_link" target='_blank'>
                <Button align="center" buttonSize="large">
                  Visualizar Termos de Uso
                </Button>
              </a>
            )
          }

        </ProposalPage.Content>
      </div>

      {isExpired && (
        <ProposalPage.Expired>
          <img src={expiredImage} alt="" />
          <h4>Proposta vencida em {formatDate(proposal?.expires_at)}</h4>
        </ProposalPage.Expired>
      )}

      <Footer />

      <Alert ref={submitOrderErrorAlertRef} status="danger">
        Ocorreu um erro ao finalizar o seu pedido.
      </Alert>

      {user && (
        <Modal ref={accountModalRef}>
          <ProposalPage.Section.Title>
            Bem vindo(a) {user?.name ?? ''}
          </ProposalPage.Section.Title>
          <p className='margin--tb-2'>
            Você está logado com o e-mail: {" "}
            <span className='txt--bold txt--primary'>{user?.email}</span>
          </p>

          <Button
            type="button"
            onClick={() => accountModalRef.current.close()}
            buttonSize="large"
            align="center"
          >
            Desejo continuar {user?.name ? `como ${user?.name}` : 'nessa conta'}
          </Button>

          <Button
            type="button"
            style={{ margin: 'auto' }}
            variant="invisible"
            onClick={userSignOut}
            buttonSize="large"
          >
            Sair dessa conta
          </Button>
        </Modal>
      )}


      <Modal ref={obsModalRef}>
        <ProposalPage.Section.Title>
          Finalizar pedido
        </ProposalPage.Section.Title>

        <p className='margin--tb-2'>
          Você está aprovando essa proposta com o e-mail: {" "}
          <span className='txt--bold txt--primary'>{user?.email}</span>
        </p>

        <div className="input__field">
          <label htmlFor="observations">Observações</label>
          <textarea
            name="observations"
            className="input textarea"
            id="observations"
            cols="30"
            rows="8"
            style={{ maxHeight: '10rem' }}
            value={observations}
            onChange={event => setObservations(event.target.value)}
          />
        </div>

        <Button
          buttonSize='large'
          align='center'
          type='button'
          onClick={handleSubmitOrder}
        >
          Finalizar pedido
        </Button>
      </Modal>
    </ProposalPage>

  );
}

export default Proposal;
