import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import ProposalItem from './proposalItem';
import ProposalCart from './proposalCart';

import { ProposalPage, ProposalFooter, ProposalHeader, DeadLine, HeaderText } from './styles';
import CardArrow from '../../../assets/icons/arrowCard.svg';
import Logo from '../../../assets/images/logos/celebrar/Celebrar_Branco_fundo_alpha.png';
import Footer from '../../../Components/Footer';
import { Load } from '../../../Components/Loader/styles';
import { useAsync } from '../../../utils/useAsync';

function OldProposal(props) {
  const [proposal, setProposal] = useState();
  const [isProposalExpired, setIsProposalExpired] = useState();
  const [expiredDate, setExpiredDate] = useState();
  // const [proposalId, setProposalId] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shipping] = useState(0);

  const { handle } = props.match.params;

  const { run, isLoading } = useAsync();

  useEffect(() => {
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    run(
      axios
        .post(`/api/proposal/${handle}`)
        .then(res => {
          // console.log(res.data.proposal, "Resposta");

          // for some reason this won't work after setProposal()
          localStorage.setItem('proposalId', res.data.proposal.id);
          localStorage.setItem('proposalName', res.data.proposal.name);
          localStorage.setItem('proposalSlug', handle);

          setProposal(JSON.parse(res.data.proposal.json_object));
          proposalValidity(res.data.proposal);
        })
        .catch(error => {
          console.log(error);
        })
    );
  }, [handle, run]);

  /*===================================
            Check proposal validity
    ====================================*/

  function proposalValidity(proposal) {
    let expiresAt = new Date(proposal.expires_at);
    setExpiredDate(expiresAt.toLocaleDateString('pt-br'));

    if (expiresAt < new Date()) {
      setIsProposalExpired(true);
    }
  }

  /*===================================
        Save and calculate items values
    ====================================*/

  function selectItem(item, index) {
    let filteredItemId = item.proposal_id ? `selectedItem.proposal_id` : `selectedItem.id`;
    let itemId = item.proposal_id ? `item.proposal_id` : `item.id`;

    let filtered = selectedItems.filter(selectedItem => eval(filteredItemId) === eval(itemId)),
      newItems = Array.from(selectedItems);

    if (filtered.length > 0) {
      let reset = selectedItems.filter(selectedItem => eval(filteredItemId) !== eval(itemId));
      setSelectedItems(reset);

      // checks if additional fee was previously calculated
      let fee =
        item.final_additional_fee ?? parseFloat(calculateAdditionalPrice(item.additional_fee));

      if (item.additional_fee) {
        setTotalPrice(totalPrice - (parseFloat(item.final_buyer_price) + parseFloat(fee)));
      } else {
        console.log(totalPrice, parseFloat(item.final_buyer_price));
        setTotalPrice(totalPrice - parseFloat(item.final_buyer_price));
      }
    } else {
      item['index'] = index;
      newItems.push(item);
      setSelectedItems(newItems);

      // checks if additional fee was previously calculated
      let fee =
        item.final_additional_fee ?? parseFloat(calculateAdditionalPrice(item.additional_fee));

      if (item.additional_fee) {
        setTotalPrice(totalPrice + (parseFloat(item.final_buyer_price) + parseFloat(fee)));
      } else {
        setTotalPrice(totalPrice + parseFloat(item.final_buyer_price));
      }
    }
  }

  function calculateAdditionalPrice(additional_fee) {
    let additional_with_rate = parseFloat(additional_fee / 0.9);

    // console.log('additional_with_rate = ' + additional_with_rate / 0.85);

    return parseFloat(additional_with_rate) / 0.85;
  }

  /*===================================
    Save selected items in localstorage
    ====================================*/

  function saveItemsOnLocalStorage() {
    localStorage.setItem('totalPrice', totalPrice);
    localStorage.setItem('shipping', shipping);
    localStorage.setItem('items', JSON.stringify(selectedItems));
    localStorage.setItem('proposal', proposal.name);
  }

  /*===================================
    Accordion elements function
    ====================================*/

  function accordion(element) {
    element.classList.toggle('open');
  }

  return (
    <ProposalPage>
      {isProposalExpired && (
        <ProposalPage.ExpiredAlert>
          <h3> Essa proposta venceu em {expiredDate} </h3>
        </ProposalPage.ExpiredAlert>
      )}

      <ProposalHeader>
        <div className="container">
          <div className="header__content">
            <div className="header__logo">
              <a href="https://celebrar.co/">
                <figure className="logo">
                  <img src={Logo} alt="" />
                </figure>
              </a>
              <h3> Proposta comercial </h3>
            </div>

            <div className="header__text">
              <p> Celebrar Tecnologia e Intermediações Ltda </p>
              <p> CNPJ: 28.282.186/0001-38 </p>
            </div>
          </div>
        </div>

        <div className="container margin--top-2">
          <h1 className="pageheader__title--sub">
            {proposal && proposal.name}

            {expiredDate && (
              <DeadLine>
                <div className="deadline__alert"> ! </div>
                <p>
                  {' '}
                  <span> Proposta válida até: </span> {expiredDate}{' '}
                </p>
              </DeadLine>
            )}
          </h1>
          <HeaderText>
            {proposal && proposal.address && (
              <div className="input__field txt--white">
                <label htmlFor=""> Local do evento </label>
                <h6> {proposal.address} </h6>
              </div>
            )}

            {proposal && proposal.date && (
              <div className="input__field txt--white">
                <label htmlFor=""> Data do evento </label>
                <h6> {proposal.date.slice(0, 10).split('-').reverse().join('/')} </h6>
              </div>
            )}

            {proposal && proposal.pax && (
              <div className="input__field txt--white">
                <label htmlFor="" className="txt--white">
                  {' '}
                  Convidados{' '}
                </label>
                <h6> {proposal.pax} </h6>
              </div>
            )}
          </HeaderText>
        </div>
      </ProposalHeader>

      {isLoading ? (
        <div style={{ height: '70vh' }}>
          <Load.CenterIcon>
            <Load.SmallIcon />
          </Load.CenterIcon>
        </div>
      ) : (
        <div className="container">
          {proposal ? (
            <Fragment>
              {proposal.sections &&
                proposal.sections.map(section => {
                  return (
                    <ProposalPage.Section key={section.id}>
                      {section.name && <ProposalPage.Title> {section.name} </ProposalPage.Title>}
                      {section.items.map((item, index) => {
                        return (
                          <ProposalItem
                            key={item.id}
                            index={index}
                            item={item}
                            selectItem={selectItem}
                            isProposalExpired={isProposalExpired}
                          />
                        );
                      })}
                    </ProposalPage.Section>
                  );
                })}
            </Fragment>
          ) : (
            <span>
              Ocorreu um erro
              {console.error(proposal, 'State proposal')}
            </span>
          )}

          <div className="txt--center">
            <button className="btn" onClick={() => window.print()}>
              <span> Salvar em PDF </span>
            </button>
          </div>

          <ProposalCart
            saveItems={saveItemsOnLocalStorage}
            selectedItems={selectedItems}
            totalPrice={totalPrice}
          />

          <ProposalFooter>
            <div className="accordion" onClick={event => accordion(event.currentTarget)}>
              <h3 className="accordion__title">
                {' '}
                <span>
                  {' '}
                  <img src={CardArrow} alt="" />{' '}
                </span>{' '}
                Formas de pagamento{' '}
              </h3>
              <p className="accordion__text">
                PIX, Boleto ou TED. Faturamento até 30 dias sujeito análise. <br />
                Em caso de atraso no pagamento, o valor terá incidência de juros de mora de 0,5% ao
                dia, pro rata die, mais multa de 2% ao mês. <br />
              </p>
            </div>

            <div className="accordion" onClick={event => accordion(event.currentTarget)}>
              <h3 className="accordion__title">
                {' '}
                <span>
                  {' '}
                  <img src={CardArrow} alt="" />{' '}
                </span>{' '}
                Política de cancelamento{' '}
              </h3>
              <p className="accordion__text">
                A política de cancelamento está disponível nos{' '}
                <a href="https://celebrar.com.br/termos/comprador" target="_blank" rel="noreferrer">
                  {' '}
                  termos de uso Celebrar{' '}
                </a>
              </p>
            </div>

            <div className="accordion" onClick={event => accordion(event.currentTarget)}>
              <h3 className="accordion__title">
                {' '}
                <span>
                  {' '}
                  <img src={CardArrow} alt="" />{' '}
                </span>{' '}
                Termos de uso{' '}
              </h3>
              <p className="accordion__text">
                {' '}
                Ao aceitar esta proposta declaro que li e aceito os{' '}
                <a href="https://celebrar.com.br/termos/comprador" target="_blank" rel="noreferrer">
                  {' '}
                  termos de uso Celebrar{' '}
                </a>
              </p>
            </div>
          </ProposalFooter>
        </div>
      )}

      <Footer />
    </ProposalPage>
  );
}

export default OldProposal;
