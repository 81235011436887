import { ArrowRight, List, Plus } from "feather-icons-react/build/IconComponents";
import React from "react";
import styled from "styled-components";
import { CategoriesAsideStyled } from "../../../Components/Categories/Categories.styles.tw";
import { CategoriesImages } from "../../../Components/Categories/CategoriesImages";
import { Button } from "../../../Components/common/Buttons/Button";
import { Aside, Demand } from "../../Buyer/NewDemand/EventSummary";

export function AsideUserCategories({
  selectedCategories,
  handleRemoveCategory,
  handleSubmit,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Aside className={isOpen ? "open" : ""}>
          <Aside.Header>
            <h4>Minhas categorias de especialidade</h4>
            <p>{selectedCategories?.length} </p>
          </Aside.Header>
          <Aside.Body>
            {selectedCategories?.map((category) => {
              return (
                <Demand>
                  <Demand.Photo>
                    <img
                      src={
                        CategoriesImages.find(
                          (e) => e.categoryId === category.id
                        ).url
                      }
                      alt={category.name}
                    />
                  </Demand.Photo>
                  <Demand.Header>
                    <h4>{category.name}</h4>
                    <div>
                      <Demand.Button
                        onClick={() => handleRemoveCategory(category.id)}
                      >
                        Remover
                      </Demand.Button>
                    </div>
                  </Demand.Header>
                </Demand>
              );
            })}

            {selectedCategories?.length < 1 && (
              <h5>Adicione ao menos uma categoria para prosseguir</h5>
            )}
          </Aside.Body>

          <Aside.Button
            onClick={handleSubmit}
            disabled={selectedCategories?.length < 1}
          >
            <h5>Continuar</h5>
            <ArrowRight />
          </Aside.Button>
        </Aside>
      </div>
      <Aside.OpenModalBtn onClick={() => setIsOpen(true)}>
        <div>{selectedCategories?.length}</div>
        <List />
      </Aside.OpenModalBtn>
      <Aside.Lightbox
        className={isOpen ? "open" : ""}
        onClick={() => setIsOpen(false)}
      />
    </div>
  );
}
