import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AngleLeft } from '../Icons/IconsFamily';

export const ButtonBack = ({color, link, ...props}) => {
  return(
    <Container >
      <div className="container">
        <StyledButton
          type='button'
          {...props}
        >
          <Link to={link}>
            <AngleLeft color={color} />
          </Link>
        </StyledButton>
      </div>
    </Container >
  )
}

const Container = styled.div `
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
`;  

const StyledButton = styled.button ` 
  /* Colors */
  background-color: transparent;

  /* Position */
  position: relative;
  left: 0;
  
  /* Text */
  font-size: 1rem;
  
  /* Others */
  border: none;
  cursor: pointer;
  
  @media (min-width: 800px){
    left: -2rem;
  }
`;