import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../../Components/Alert';
import { LVStore } from '../styles';
import axios from 'axios';

function LearningVillageCheckout() {

    const items = JSON.parse(sessionStorage.getItem("items"));
    const eventPlace = sessionStorage.getItem("eventPlace");
    // Alert component reference
    const alertRef = useRef();
    // Form states
    const [ name, setName ] = useState();
    const [ company, setCompany ] = useState();
    const [ cnpj, setCnpj ] = useState();
    const [ email, setEmail ] = useState();
    const [ phone, setPhone ] = useState();
    const [ eventDate, setEventDate ] = useState();
    const [ startTime, setStartTime ] = useState();
    const [ endTime, setEndTime ] = useState();
    const [ paymentMethod, setPaymentMethod ] = useState();
    const [ order, setOrder ] = useState();

    useEffect( () => {

        document.querySelector("body").style.overflow = "auto";
        window.scrollTo( 0, 0);

    }, [] );

    // useEffect( () => {

    //     if ( order != undefined ){
    //         document.querySelector("#escale_order").submit();
    //     }

    // }, [ order ]);


/*=====================================================
        Functions to calculate items price
======================================================*/

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

    
/*=====================================================
                Validate order form
======================================================*/

    function validateForm(event) {
        event.preventDefault();

        let newOrder = {};

        let form = document.querySelector("#lv_order"),
            inputs = Array.from(form.querySelectorAll(".input")),
            emptyInput = inputs.filter( input => input.value === "" && input.name !== "phone");

            console.log(emptyInput)

        if ( emptyInput.length > 0 ) {

            emptyInput.forEach( input => {
                input.style.border = "solid 0.2rem var(--color-red)"
            });

        } else {
 
            inputs.forEach( input => {
                newOrder[input.name] = input.value;
            });

            setOrder( newOrder );

            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            axios({
                method: 'post',
                url: '/api/lv_store',
                data: {
                    buyer: newOrder,
                    event_place: eventPlace,
                    items: items,
                    subtotal: sessionStorage.getItem("subtotal"),
                    taxes: sessionStorage.getItem("taxes"),
                    total: sessionStorage.getItem("total"),
                }
                })
            .then(res => {
                console.log(res.data, "Resposta");
                alertRef.current.open();
            })
            .catch(err => console.log(err));

            console.log('Terminou de processar');
        }

    }

    return(
        <LVStore >
            <div className="container">

                <LVStore.BackButton >
                    <Link to="/lv"> Voltar </Link>
                </LVStore.BackButton>

                <LVStore.FormPage >
                    <LVStore.FormItems >
                        <LVStore.CartTitle > Seu carrinho </LVStore.CartTitle>
                            {
                                items && items.length > 0 && (
                                    items.map( item => {
                                        return(
                                            <LVStore.CartItem key={ item.id }>
                                                <LVStore.CartItemImg >
                                                    <img src={ item.photos[0].url } alt="" />
                                                </LVStore.CartItemImg>

                                                <LVStore.CartText className="checkout" >
                                                    <LVStore.CartItemName >
                                                        { item.name }
                                                    </LVStore.CartItemName>

                                                    <LVStore.CartItemPrice >
                                                        { toBraCurrency(item.final_buyer_price) }
                                                    </LVStore.CartItemPrice>

                                                </LVStore.CartText>
                                            </LVStore.CartItem>
                                        )
                                    })
                                )
                            }

                        <div className="input__field" style={ { margin: "2rem auto" } }>
                            <p> Local do evento: </p> <span> { eventPlace && eventPlace == "arena" ? "Auditório" : `Sala ${ eventPlace }`  } </span>
                        </div>
                        <LVStore.CartTotalPrice className="checkout">

                            <div>
                                <span> Subtotal </span> <span> { sessionStorage.getItem("subtotal") } </span>
                            </div>

                            <div>
                                <span> Impostos </span> <span> { sessionStorage.getItem("taxes") } </span>
                            </div>

                            <div>
                                <h1> Total </h1> <h1> { sessionStorage.getItem("total") } </h1>
                            </div>
                        </LVStore.CartTotalPrice>
                    </LVStore.FormItems>

                    <LVStore.FormContent >
                        <LVStore.FormContentTitle > Finalizar pedido </LVStore.FormContentTitle>
                            <form onSubmit={validateForm} name="lv_order" id="lv_order" method="POST">
                                <div className="row">
                                    <div className="col s12">
                                        <div className="input__field">
                                            <label htmlFor="name"> Nome completo *</label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="input"
                                                onInput={ event => setName( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ name }
                                            />
                                        </div>
                                    </div>

                                    <div className="col m6 s12">
                                        <div className="input__field">
                                            <label htmlFor="company"> Empresa *</label>
                                            <input
                                                type="text"
                                                name="company"
                                                id="company"
                                                className="input"
                                                onInput={ event => setCompany( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ company }
                                            />
                                        </div>
                                    </div>

                                    <div className="col m6 s12">
                                        <div className="input__field">
                                            <label htmlFor="cnpj"> CNPJ *</label>
                                            <input
                                                type="text"
                                                name="cnpj"
                                                id="cnpj"
                                                className="input"
                                                onInput={ event => setCnpj( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ cnpj }
                                            />
                                        </div>
                                    </div>

                                    <div className="col m6 s12">
                                        <div className="input__field">
                                            <label htmlFor="email"> E-mail *</label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="input"
                                                onInput={ event => setEmail( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ email }
                                            />
                                        </div>
                                    </div>

                                    <div className="col m6 s12">
                                        <div className="input__field">
                                            <label htmlFor="phone"> Telefone </label>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                className="input"
                                                onInput={ event => setPhone( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ phone }
                                                />
                                        </div>
                                    </div>

                                    <div className="col m4 s12">
                                        <div className="input__field">
                                            <label htmlFor="eventDate"> Data do evento *</label>
                                            <input
                                                type="date"
                                                name="eventDate"
                                                id="eventDate"
                                                className="input"
                                                onInput={ event => setEventDate( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ eventDate }
                                                />
                                        </div>
                                    </div>

                                    <div className="col m4 s12">
                                        <div className="input__field">
                                            <label htmlFor="startTime"> Horário de inicio do evento *</label>
                                            <input
                                                type="time"
                                                name="startTime"
                                                id="startTime"
                                                className="input"
                                                onInput={ event => setStartTime( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ startTime }
                                                />
                                        </div>
                                    </div>

                                    <div className="col m4 s12">
                                        <div className="input__field">
                                            <label htmlFor="endTime"> Horário de término do evento *</label>
                                            <input
                                                type="time"
                                                name="endTime"
                                                id="endTime"
                                                className="input"
                                                onInput={ event => setEndTime( event.target.value ) }
                                                onFocus={ event => event.target.removeAttribute("style") }
                                                value={ endTime }
                                                />
                                        </div>
                                    </div>

                                    <div className="col s12">
                                        <div className="input__field">
                                            <label htmlFor="paymentMethod"> Forma de pagamento *</label>
                                            <select
                                                name="paymentMethod"
                                                id="paymentMethod"
                                                className="input"
                                                onChange={ event => setPaymentMethod( event.target.value ) }
                                            >
                                                <option disabled > SELECIONE A FORMA DE PAGAMENTO </option>
                                                <option value="TED"> TED </option>
                                                <option value="BOLETO"> BOLETO </option>
                                                <option value="PIX"> PIX </option>
                                            </select>
                                        </div>
                                    </div>

                                    <input type="hidden" name="order" value={ order } />

                                    <div className="row txt--center">
                                        <LVStore.Button type="submit" className="bgLight blue" >
                                            <span> Finalizar pedido </span>
                                        </LVStore.Button>
                                    </div>
                                </div>
                            </form>

                    </LVStore.FormContent>
                </LVStore.FormPage>
            </div>

            <Alert ref={ alertRef }>
                <p> Em até 24h um especialista em eventos entrará em contato com você os próximos passos. </p>
            </Alert>
        </LVStore>
    )
}

export default LearningVillageCheckout;