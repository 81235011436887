import React, { useEffect } from 'react';
import { useAsync } from '../../../../utils/useAsync';

export function useCNPJ() {
  const {
    run,
    data: isValidCNPJ,
    isLoading: isCheckingCNPJ,
    reset,
    isSuccess: isCheckedCNPJ,
  } = useAsync({ data: false });

  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (value.length === 14) {
      run(validateCNPJ(value));
    } else {
      reset();
    }
  }, [value, run, reset]);

  const handleChangeCNPJ = event => {
    const { value } = event.target;
    if (value.length <= 18) {
      setValue(unMaskCNPJ(value));
    }
  };

  const maskedCNPJ = maskCNPJ(value);

  return {
    maskedCNPJ,
    handleChangeCNPJ,
    isValidCNPJ,
    isCheckingCNPJ,
    isCheckedCNPJ,
    valueCNPJ: value,
    setValueCNPJ: setValue,
  };
}

function maskCNPJ(value) {
  if (!value) {
    return '';
  }
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

function unMaskCNPJ(maskedCNPJ) {
  return maskedCNPJ.replace(/[^0-9]/g, '');
}

// https://www.geradorcnpj.com/javascript-validar-cnpj.htm
async function validateCNPJ(cnpj) {
  await new Promise(resolve => setTimeout(resolve, 500));

  if (cnpj === '') {
    return false;
  }

  if (cnpj.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  // Valida DVs (dígitos verificadores)
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== Number(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== Number(digitos.charAt(1))) {
    return false;
  }

  return true;
}
