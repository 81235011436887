import React, { Component } from 'react';
import Header from '../../Components/Header';
import { FaqStyled } from './Faq.styles.tw';
import Pagamentos from '../../assets/icons/faqIcons/Pagamentos.png'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/common/Buttons/Button';

export function FaqBuyer() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <FaqStyled>
      <Header />
      <div className='content'>
        <div className='aside'>
          <div className='items'>
            <img src={Pagamentos} />
            <a href='#question1'>
              <p>
                Quem somos?
              </p>
            </a>
            <a href='#question2'>
              <p>
                O que fazemos de diferente?
              </p>
            </a>
            <a href='#question3'>
              <p>
                Como funciona?
              </p>
            </a>
            <a href='#question4'>
              <p>
                Como faço um pedido?
              </p>
            </a>
            <a href='#question5'>
              <p>
                Como os prestadores de serviço são selecionados?
              </p>
            </a>
            <a href='#question6'>
              <p>
                Os fornecedores são funcionários da Celebrar?
              </p>
            </a>
            <a href='#question7'>
              <p>
                Tenho área de compras com necessidade de homologação, como devo seguir?
              </p>
            </a>
            <Link to="/faq/fornecedor/home">
              <Button size='small'>Voltar ao FAQ completo</Button>
            </Link>
          </div>
        </div>
        <div className='container'>
          <Link to="/faq/fornecedor/home">
            <div className='back'>
              <FeatherIcon icon="arrow-left" className="icon" size={16} color="purple" />
              Voltar ao FAQ completo
            </div>
          </Link>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question1'>
                Quem somos?
              </h2>
              <p>
                Somos uma Eventech (eventech = startup que oferece soluções para o mercado de eventos através de tecnologia) que reúne fornecedores diversos de todo o Brasil, para que as corporações possam materializar experiências incríveis com qualidade e preço justo para todos. Estamos há 6 anos conectando microempreendedores a grandes marcas.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question2'>
                O que fazemos de diferente?
              </h2>
              <p>
                Na Celebrar acreditamos que cada experiência deve ser única, fazemos isso por meio de tecnologia, conhecimento do mercado, ousadia e uma base de fornecedores qualificados, engajados e diversos, oferecemos:
              </p>
              <ul>
                <li>
                  Cotações personalizadas 4x mais rápidas
                </li>
                <li>
                  Curadoria de qualidade dos serviços
                </li>
                <li>
                  Compliance e track record dos fornecedores
                </li>
                <li>
                  Sem intermediários, até 25% savings para compradores.
                </li>
                <li>
                  Impacto social (ODS 8) e relatórios de ESG
                </li>
              </ul>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question3'>
                Como funciona?
              </h2>
              <p>
                Acesse nosso site e envie  as informações referentes ao seu evento, selecione os produtos e serviços desejados. Nossa comunidade de fornecedores recebe as oportunidades segmentadas segundo as categorias de serviços que oferecem e aplicam orçamentos personalizados para cada demanda.
              </p>
              <p>
                Você recebe um link mágico, onde é possível acompanhar os melhores orçamentos aplicados por diferentes fornecedores, com fotos de referência e todas as informações necessárias para contratar de forma segura e rápida.
              </p>
              <p>
                Os fornecedores aprovados pelo link mágico, realizam as entregas dos serviços confirmados de acordo com as especificações. A Celebrar unifica a NF para o comprador e cuida do workflow de pagamentos aos fornecedores.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question4'>
                Como faço um pedido?
              </h2>
              <p>
                Para realizar pedidos em nossa plataforma, é necessário acessar nosso site, criar um cadastro e preencher o briefing com as informações sobre o seu evento, escolher os produtos e/ou serviços para encaminharmos as propostas para nossa comunidade de fornecedores.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question5'>
                Como os prestadores de serviço são selecionados?
              </h2>
              <p>
                Nosso time de especialistas em eventos realiza a curadoria criteriosa dos fornecedores que irão atender ao seu evento conforme as categorias selecionadas e te enviam as melhores opções disponíveis, mas a escolha de quais fornecedores irão atender ao seu evento é sua - basta incluir no seu carrinho de compras “Seu evento” e finalizar a compra online.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question6'>
                Os fornecedores são funcionários da Celebrar?
              </h2>
              <p>
                Não. Os fornecedores da comunidade Celebrar são prestadores de serviços com cadastro ativo no Cadastro Nacional de Pessoas Jurídicas (CNPJ), ou seja, integram nossa plataforma como microempreendedores e cuidamos da curadoria, qualificação, compliance, além dos fluxos de pagamentos dos serviços entregues.
              </p>
            </div>
          </div>
          <div className='row alignment'>
            <div className='col m8'>
              <h2 id='question7'>
              Tenho área de compras com necessidade de homologação, como devo seguir?
              </h2>
              <p>
              Não se preocupe, nós cuidamos de todo o cadastro do CNPJ da Celebrar junto ao seu time de compras e através de um único cadastro nós te damos acesso a toda comunidade de fornecedores da Celebrar. Emitiremos uma NF unificada e personalizada segundo as necessidades da sua empresa, como pedido de compra ou centro de custos.
              </p>
            </div>
            <div className='col m4'>
              <img src={Pagamentos} />
            </div>
          </div>
        </div>
      </div>
    </FaqStyled >
  );
}