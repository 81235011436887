import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Confimation } from './styles';

const ModalConfirmation = forwardRef((props, ref) => {

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle( ref, () => ({
            open() {
                setIsOpen(true);
                document.querySelector("body").style.overflow = "hidden";
            },
            close() {
                setIsOpen(false);
                document.querySelector("body").style.overflow = "inherit";
            }
         }),
     )

    function close(){
        setIsOpen(false);
        document.querySelector("body").style.overflow = "inherit";
    }

    useEffect( () => {
        return () => document.querySelector("body").style.overflow = "inherit"
    }, [] );

    return(
        <Fragment >
            {
                isOpen && (
                    <Confimation >
                        <Confimation.Lightbox onClick={ () => close() } />

                        <Confimation.Modal >
                            { props.children }
                        </Confimation.Modal>
                    </Confimation>
                )
            }
        </Fragment>
    )
});

export default ModalConfirmation;