import React from 'react';
import { Register } from './styles';
import { Button } from '../../../Components/common/Buttons/Button';
import { Link, useHistory } from 'react-router-dom';
import Header from '../../../Components/Header';
import { useAuthContext } from '../../../Contexts/AuthContext';

function RegisterUser() {
  const { setLoginRedirect, user } = useAuthContext();
  const { push } = useHistory();

  return (
    <Register>
      <Header buyerPage />

      <Register.Container>
        <div className="container">
          <Register.Heading>
            Primeiro,
            <br />
            quem é você?
          </Register.Heading>

          <Register.BlocksContainer>
            <Register.UserTypeBlock className="buyer-register-block">
              <h2>
                Sou um
                <br />
                <span>comprador</span>
              </h2>
              <p>Quero transformar meu evento em uma experiência única</p>

              <Link to="/comprador/pedido">
                <Button>Fazer meu pedido</Button>
              </Link>
            </Register.UserTypeBlock>

            <Register.UserTypeBlock className="supplier-register-block">
              <h2>
                Sou um
                <br />
                <span>fornecedor</span>
              </h2>
              <p>Quero fazer parte da comunidade de fornecedores da Celebrar</p>
              <Link to="/cadastro-fornecedor">
                <Button>Fazer meu cadastro</Button>
              </Link>
              <Button
                onClick={() => {
                  if (user) {
                    setLoginRedirect(true);
                  } else {
                    push('/login');
                  }
                }}
                type="button"
              >
                Acessar minha conta
              </Button>
            </Register.UserTypeBlock>
          </Register.BlocksContainer>
        </div>
      </Register.Container>
    </Register>
  );
}

export default RegisterUser;
