import styled from 'styled-components';


export const Confimation = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
`;

Confimation.Modal = styled.div `
    /* Display & Box Model */
    width: 30rem;
    min-height: 15rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Colors */
    background-color: var(--color-white);
    color: var(--color-text);

    /* Text */
    text-align: center;

    /* Position */
    position: absolute;
    z-index: 999;

    /* Others */
    border-radius: var(--radius);

    .modal__title{
        /* Display & Box Model */
        width: 100%;
        padding: 1rem 0;
        margin-bottom: 2rem;

        /* Others */
        border-bottom: solid 0.1rem var(--color-gray);
    }

    .btn{
        /* Display & Box Model */
        margin: 0.5rem auto;
    }

    p{
        /* Display & Box Model */
        margin-bottom: 1rem;
    }
`;

Confimation.Lightbox = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Colors */
    background-color: rgba(0, 0, 0, 0.8);

    /* Positon */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998; 
`;