import React from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../../Contexts/AuthContext';

export const useSubmitImage = () => {
    const { updateUserData } = useAuthContext();
    const [file, setFile] = React.useState();

    function addImage(e, alertRef) {
        if (e.target.files[0].size <= 2000000) {
            setFile(URL.createObjectURL(e.target.files[0]));
        } else {
            alertRef.current.open();
        }
    }

    function handleSubmitImage(event, userId, userImageModalRef) {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('user_id', userId);
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios({
            method: 'post',
            url: '/api/usuario/novo-avatar',
            data: formData,
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        })
        .then(res => {
            if (res.status === 200) {
                updateUserData({ avatar_url: res.data.new_avatar_url });
                userImageModalRef.current.close();
            }
        })
        .catch(err => console.log(err));
    }

    return{
        file,
        addImage,
        handleSubmitImage
    }
}