import React, { useEffect, useRef, useState } from 'react';
import Alert from '../../../Components/Alert';

import { EscalePage } from '../styles';
import cartImg from '../../../assets/images/escale/shopping-cart.svg';
import { Cart } from '../EscaleIcons';
import { Link } from 'react-router-dom';

function EscaleCart({ selectedItems, removeItemFromCart, shipping }){

    // States
    const [ isOpened, setIsOpened ] = useState( false );
    const [ cep, setCep ] = useState();
    const [ address, setAddress ] = useState();
    const [ addressNumber, setAddressNumber ] = useState();
    const [ addressComplement, setAddressComplement ] = useState();
    const [ shippingPrice, setShippingPrices ] = useState( 0 );
    const [ taxes, setTaxes ] = useState( 0 );
    const [ subtotal, setSubtotal ] = useState( 0 );

    // Alert component reference
    const alertRef = useRef();
    const shippingSouthAndSoutheast = [
        "SP",
        "RJ",
        "ES",
        "MG",
        "PR",
        "SC",
        "RS"
    ];

    useEffect( () => {
        shippingPriceFilter();
    }, [address] );

/*=====================================================
        Functions to calculate items price
======================================================*/

    function toBraCurrency(value){

        return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value);
        
    }

    function shippingPriceFilter(){
        // let isSouthOrSoutheastAddress,
        //     shippingPrices = shipping.filter( e => e.type == "full" );

        if( address ){
            // isSouthOrSoutheastAddress = shippingSouthAndSoutheast.includes( address.uf );

            // if( isSouthOrSoutheastAddress ){
            //     setShippingPrices(parseFloat(shippingPrices[0].price));
            // } else {
            //     setShippingPrices(parseFloat(shippingPrices[1].price));
            // }

            switch(address.uf) {
                case "SP":
                    setShippingPrices(parseFloat(38.5));
                    break;
                case "PR":
                case "RS":
                    setShippingPrices(parseFloat(49.83));
                    break;
                case "RJ":
                    setShippingPrices(parseFloat(54.36));
                    break;
                case "MG":
                    setShippingPrices(parseFloat(56.63));
                    break;
                case "GO":
                case "DF":
                case "ES":
                case "SC":
                    setShippingPrices(parseFloat(61.16));
                    break;
                case "BA":
                    setShippingPrices(parseFloat(67.96));
                    break;
                // case "AC":
                // case "AP":
                // case "RO":
                // case "RR":
                // case "SE":
                // case "TO":
                //     setShippingPrices(parseFloat(44.32));
                //     break;
                default:
                    setShippingPrices(parseFloat(79.27));
                    break;
            }

        } //endif

    }

    function calculateSubtotalPrice(){
        let subtotalPrice = 0;

        selectedItems.forEach( item => {
            subtotalPrice += parseFloat(item.final_buyer_price);
        });

        return toBraCurrency(subtotalPrice);
    }

    function calculateTax(){
        let subtotalPrice = 0;

        selectedItems.forEach( item => {
            subtotalPrice += parseFloat(item.final_buyer_price);
        });

        let tax = 0;

        tax = parseFloat(subtotalPrice) * 0.14

        return toBraCurrency(tax);
    }

    function calculateTotalPrice(){
        let finalPrice = 0;

        selectedItems.forEach( item => {
            finalPrice += parseFloat(item.final_buyer_price);
        });

        finalPrice += parseFloat(finalPrice) * 0.14;
        finalPrice += shippingPrice;

        return toBraCurrency(finalPrice);
    }

/*=====================================================
    Functions to open and close shopping cart
======================================================*/

    function openShoppingCart(){

        setIsOpened( !isOpened );
        document.querySelector("body").style.overflow = "hidden";

    }

    function closeShoppingCart(){

        setIsOpened( false );
        document.querySelector("body").style.overflow = "inherit";

    }

/*=====================================================
        Functions to shearch address via CEP
======================================================*/

    function filterCepInput( value ){

        setCep( value );

        let cepFiltered = value.replaceAll("-", "");
            cepFiltered = value.replaceAll(" -", "");
            cepFiltered = value.replaceAll(" - ", "");

        cepFiltered.length == 8 && searchCep( cepFiltered );
    }

    function searchCep( cepFiltered ){
        fetch(`https://viacep.com.br/ws/${ cepFiltered }/json/`)
        .then( res => res.json() )
        .then( address => {

            if( address.uf !== undefined ){
                setAddress( address );
                console.log(address);
            } else {
                setAddress( false );    
            }


        })
        .catch( error => {
            setAddress( false );
            console.log( error );
        } )
    }


/*=====================================================
                    Finish order
======================================================*/

    function finishOrder(){

        if( selectedItems.length > 0 && address != undefined ){

            let addressComplet = address;

            addressComplet.numero = addressNumber;
            addressComplet["complemento"] = addressComplement;

            sessionStorage.setItem("address", JSON.stringify( address ));
            sessionStorage.setItem("subtotal", calculateSubtotalPrice());
            sessionStorage.setItem("shipping", shippingPrice);
            sessionStorage.setItem("taxes", calculateTax());
            sessionStorage.setItem("total", calculateTotalPrice());

        } else {
            alertRef.current.open();
        }
    }



    return(
        <EscalePage.CartComponent className={ isOpened && "open" }>
            <EscalePage.Lightbox onClick={ closeShoppingCart } className={ isOpened && "open" } />

            <EscalePage.Cart className={ isOpened && "open" }>
                <EscalePage.CartImg onClick={ openShoppingCart }>
                    <EscalePage.CartItemsQuantity > { selectedItems.length } </EscalePage.CartItemsQuantity>
                    <Cart />
                    <img src={ cartImg } alt="Carrinho de compras" />
                </EscalePage.CartImg>

                {
                    isOpened && (
                        <EscalePage.CartContent >
                            <EscalePage.CartItems >
                                <EscalePage.CartTitle >
                                    <h3> Seu carrinho </h3>
                                    <h5> { selectedItems.length } itens </h5>
                                </EscalePage.CartTitle>
                                {
                                    selectedItems.length > 0 && (
                                        selectedItems.map( item => {
                                            return(
                                                <EscalePage.CartItem key={ item.id }>
                                                    <EscalePage.CartItemImg >
                                                        <img src={ item.photos[0].url } alt="" />
                                                    </EscalePage.CartItemImg>

                                                    <EscalePage.CartText >
                                                        <EscalePage.CartItemName >
                                                            { item.name }
                                                        </EscalePage.CartItemName>

                                                        <EscalePage.CartItemPrice >
                                                            { toBraCurrency(item.final_buyer_price) }
                                                        </EscalePage.CartItemPrice>

                                                        <EscalePage.CartRemoveItem onClick={ () => removeItemFromCart( item.id ) }>
                                                            Remover item
                                                        </EscalePage.CartRemoveItem>

                                                    </EscalePage.CartText>
                                                </EscalePage.CartItem>
                                            )
                                        })
                                    )
                                }
                            </EscalePage.CartItems>

                            <EscalePage.CartTotalPrice >
                                <EscalePage.CartTitle >
                                    <h3> Endereço de entrega e valores </h3>
                                </EscalePage.CartTitle>

                                <div className="input__field">
                                    <label htmlFor=""> CEP </label>
                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Insira o CEP de entrega"
                                        onInput={ event => filterCepInput( event.target.value ) }
                                        value={ cep }
                                    />
                                </div>

                                <EscalePage.Address >
                                    {
                                        address ? (
                                            <div className="input__field">
                                                <label htmlFor=""> Enviar para: </label>
                                                <p> {`${address.logradouro}, ${address.bairro} - ${address.localidade} - ${address.uf}`} </p>
                                            </div>
                                        ) : (
                                            <p> Informe um CEP válido </p>
                                        )
                                    }
                                </EscalePage.Address>

                                <EscalePage.Complement >
                                    <div className="input__field">
                                        <label htmlFor=""> Número </label>
                                        <input
                                            type="number"
                                            className="input"
                                            onInput={ event =>  setAddressNumber( event.target.value ) }
                                            value={ addressNumber }
                                        />
                                    </div>

                                    <div className="input__field">
                                        <label htmlFor=""> Complemento </label>
                                        <input
                                            type="text"
                                            className="input"
                                            onInput={ event =>  setAddressComplement( event.target.value ) }
                                            value={ addressComplement }
                                            />
                                    </div>
                                </EscalePage.Complement>

                                <div>
                                    <span> Subtotal </span> <span> { calculateSubtotalPrice() } </span>
                                </div>
                                <div>
                                    <span> Frete </span> <span> { address ? toBraCurrency(shippingPrice) : "Informe um CEP" } </span>
                                </div>
                                <div>
                                    <span> Impostos </span> <span> { calculateTax() } </span>
                                </div>
                                <div>
                                    <h6> Total </h6> <h6> { calculateTotalPrice() } </h6>
                                </div>

                                <EscalePage.CartBtn onClick={ () => finishOrder() } >
                                    {
                                        address ? (
                                            <Link to="/escale-checkout" > Finalizar pedido  </Link>
                                        ) : (
                                            <span> Finalizar pedido </span>
                                        )
                                    }
                                </EscalePage.CartBtn>
                            </EscalePage.CartTotalPrice>
                        </EscalePage.CartContent>
                    )
                }

                <Alert ref={ alertRef }>
                    <p> Adicione ao menos 1 item no seu carrinho e insira o CEP de entrega para prosseguir </p>
                </Alert>

            </EscalePage.Cart>
        </EscalePage.CartComponent>
    )
}

export default EscaleCart;