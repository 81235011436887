import styled from 'styled-components';

export const BuyerPage = styled.div`
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;

    /* Colors */
    background-color: var(--color-gray-light);

    /* Others */
    overflow: hidden;
`;

BuyerPage.Container = styled.div`
    /* Display & Box Model */
    width: 90%;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 0 4rem 0;

    /* Color */
    background-color: var(--color-gray-light);

    /* Position */
    position: relative;

    /* Others */
    transition: all 0.3s ease-out;
`;

export const Buyer = styled.div`
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flow-root; 

    /* Colors */
    background-color: var(--color-gray-light);
`;

Buyer.Section = styled.section`
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 3rem;

    /* Text */
    text-align: center;

    #small__button {
        /* Display & Box Model */
        display: none;
    }

    p {
        margin: 1rem auto;
    }

    @media (min-width: 800px) {
        #small__button {
            /* Display & Box Model */
            display: block;
            /* Position */
            position: absolute;
            top: -1rem;
            right: 0;
        }
    }
`;

/*==============================
            Menu
===============================*/

Buyer.Menu = styled.nav`
    /* Display & Box Model */
    width: 100%;
    height: 4rem;

    /* Colors */
    background-color: var(--color-black);
    color: var(--color-white);

    .container {
        /* Display & Box Model */
        height: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        /* Display & Box Model */
        width: 3rem;
    }
`;

Buyer.MenuLinks = styled.div`
    /* Display & Box Model */
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
`;

Buyer.Title = styled.h1`
    /* Display & Box Model */
    width: 100%;
    padding-bottom: 0.5rem;
    margin: 1rem auto;

    /* Colors */
    color: var(--color-black);

    /* Text */
    font-size: 2rem;

    /* Others */
    border-bottom: solid 0.1rem var(--color-border);
`;

Buyer.Thead = styled.div`
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 25%);
    margin-bottom: 0.5rem;
`;

Buyer.Historic = styled.div`
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

Buyer.DemandsTable = styled.table`
    border-spacing: 0 1rem;
    width: 100%;
    padding-bottom: 1rem;

    thead tr {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: var(--color-black);
    }

    tbody tr {
        background-color: var(--color-white);
        border: solid 0.1rem var(--color-border);
        box-shadow: var(--shadow);
        margin: 1rem 0;
        width: 100%;
        border-radius: 0.3rem;
        cursor: pointer;

        td {
            padding: 1.5rem 0;
        }

        td:nth-of-type(1) {
            width: 200px;
            max-width: 200px;
            padding-left: 1.5rem;
            padding-right: 0.5rem;
        }

        td:nth-of-type(2) {
            width: 180px;
            padding: 0 1rem;
        }

        td:nth-of-type(3),
        th:nth-of-type(3) {
            max-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 1.5rem;
            padding-left: 0.5rem;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }
`;

Buyer.DemandDetails = styled.div`
    padding: 2rem;

    header {
        color: var(--color-primary);
    }

    ul {
        padding-top: 1rem;
        list-style: none;

        li {
            list-style-type: square;
            list-style-position: inside;
            margin-bottom: 0.5rem;
            line-height: 1.5;

            span.demand-description {
                display: inline-block;
                vertical-align: top;
                white-space: pre-wrap;
                line-height: 1.5;
                margin-left: 1.5rem;
                margin-top: 0.5rem;
            }
        }
    }

    @media (max-width: 800px) {
        padding: 0;
    }
`;

BuyerPage.UserAccount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

BuyerPage.UserImage = styled.figure`
    /* Display & Box Model */
    margin-right: 2rem;

    img {
        /* Display & Box Model */
        width: 8rem;
        border-radius: 8px;
    }

    label {
        /* Display & Box Model */
        display: block;
        padding: 0.5rem 1rem;

        /* Text */
        text-align: center;

        /* Others */
        cursor: pointer;
        border: solid 0.1rem var(--color-gray);
    }

    &.modal__image {
        /* Display & Box Model */
        margin: auto;

        /* Text */
        text-align: center;

        img {
            /* Display & Box Model */
            width: 8rem;
        }

        .user__image {
            /* Display & Box Model */
            display: 5rem;
        }
    }
`;

BuyerPage.UserInfo = styled.div`
    margin: 0.5rem 0;

    span {
        font-size: 0.8rem;
    }

    p {
        color: var(--color-black);
    }
`;

BuyerPage.AccountButtons = styled.div`
    /* Display & Box Model */
    margin-top: 2rem;

    /* Colors */
    color: var(--color-secondary);

    /* Text */
    text-align: center;

    div {
        /* Display & Box Model */
        width: max-content;
        margin: auto;
        margin-bottom: 1rem;
        padding-bottom: 1px;
        cursor: pointer;

        /* Others */
        border-bottom: solid 1px var(--color-secondary);
    }
`;

/*==============================
            Reviews
===============================*/

Buyer.Reviews = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;

    @media (min-width: 800px){
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
    }
`;

Buyer.ReviewsCard = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 1rem;

    /* Colors */
    background-color: var(--color-white);

    /* Text */
    text-align: left;

    /* Others */
    box-shadow: var(--shadow);
    border-radius: 0.3rem;

    p{
        /* Display & Box Model */
        margin: 0;

        /* Colors */
        color: var(--color-black);

        /* Text */
        font-weight: 700;
    }

    span{
        /* Display & Box Model */
        display: block;
        margin-top: 0.5rem;

        /* Text */
        font-size: 0.9rem;
    }

    div{
        /* Display & Box Model */
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        margin-top: 2rem;

        img{
            /* Display & Box Model */
            width: 2rem;
        }
    }
`;
