import styled from 'styled-components';
import tw from 'tailwind.macro';

const CategoriesSelectionPageStyles = styled.div.attrs({
  className: 'w-full h-screen flex flex-col items-center justify-center',
})`
  & {
    h1{
      font-family: 'Estate','Manrope',sans-serif;
      font-size: 1.6rem;
      font-weight: 700;
      padding-top: 3rem;
      margin: 0 0 0.5rem 0;
      color: var(--color-white);
    }
    h3 {
      color: var(--color-white);
      margin-bottom: 2.5rem;
    }
  }
`;

export default CategoriesSelectionPageStyles;
