import React, { forwardRef, useState, useEffect, useRef } from 'react';
import { Button } from '../../Components/common/Buttons/Button';
import { ButtonIconOnly } from '../../Components/common/Buttons/ButtonIconOnly';
import { Close } from '../../Components/common/Icons/IconsFamily';
import { Load } from '../../Components/Loader/styles';
import Modal from '../../Components/Modal';
import { ModalStyles } from '../../Components/Modal/styles';
import { useAuthContext } from '../../Contexts/AuthContext';
import { toBraCurrency } from '../../utils';
import { ProposalPage } from './styles';

const ProposalShoppingCart = forwardRef(
  ({ items, removeItem, total, openObsModal, isSubmittingOrder, ...props }, ref) => {
    const { signInWithModal } = useAuthContext();

    const taxValue = total * 0.05;

    return (
      <ProposalPage.Cart className={props.className}>
        <ProposalPage.Cart.Items>
          <ProposalPage.Cart.Close
            children="Fechar"
            onClick={() => props.setIsMyEventOpen(false)}
          />
          <ProposalPage.Cart.Title>
            <h3>Meu Evento</h3>
            <span>{items?.length > 1 ? items.length + ' itens' : items.length + ' item'}</span>
          </ProposalPage.Cart.Title>

          {/* <ProposalPage.Cart.Infos >
            <div>
              <span>Data do evento</span>
              <p>00/00/0000</p>
            </div>

            <div>
              <span>Convidados</span>
              <p>00</p>
            </div>

            <div>
              <span>Local do evento</span>
              <p>Local do evento</p>
            </div>
          </ProposalPage.Cart.Infos> */}

          {items?.map(item => {
            return (
              <ProposalPage.Cart.Item key={item.label}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  {item.photos[0] && (
                    <ProposalPage.Section.Icon className="item">
                      <img src={item?.photos[0]?.url} alt="" />
                    </ProposalPage.Section.Icon>
                  )}
                  <div>
                    <h5>{item.name}</h5>
                    <h4>{toBraCurrency(item.final_buyer_price)}</h4>
                    <span onClick={() => removeItem(item.label, item.price)}>Remover</span>
                  </div>
                </div>
              </ProposalPage.Cart.Item>
            );
          })}
        </ProposalPage.Cart.Items>

        <ProposalPage.Cart.Prices>
          <ul>
            <li>
              <span>Total</span>
              <span>{toBraCurrency(total)}</span>
            </li>
            <li>
              <span></span>
              <span>Taxa e impostos inclusos</span>
            </li>
          </ul>

          <Button
            buttonSize="large"
            align="center"
            disabled={!(items?.length > 0)}
            onClick={() => {
              signInWithModal(openObsModal);
            }}
          >
            {isSubmittingOrder ? (
              <Load.SmallIcon
                style={{
                  borderColor: 'var(--color-white)',
                  borderTopColor: 'var(--color-primary-light)',
                }}
              />
            ) : (
              'Finalizar pedido'
            )}
          </Button>
        </ProposalPage.Cart.Prices>
      </ProposalPage.Cart>
    );
  }
);

export default ProposalShoppingCart;
