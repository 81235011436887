import React from 'react';

// Styles & Images
import { CategoriesHeaderStyled } from './Categories.styles.tw';

import { CategoriesIcons } from '../common/Icons/CategoriesIcons';

export function CategoriesHeader({ groupedCategories, currentCategory, setCurrentCategory }) {
  return (
    <CategoriesHeaderStyled>
      <div className="categories">
        {groupedCategories?.map(category => {
          const Icon = CategoriesIcons.find(e => e.categoryId === category.id)?.icon;
          return (
            <div key={category.name}>
              <div
                className={`category ${category.id === currentCategory ? 'active' : ''} `}
                onClick={() => setCurrentCategory(category.id)}
              >
                <div className="icon">{Icon}</div>
                <p>{category.name}</p>
              </div>
            </div>
          );
        })}
      </div>
    </CategoriesHeaderStyled>
  );
}
