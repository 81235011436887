import styled, { keyframes } from 'styled-components';

const HeaderAnime = keyframes `
  from{
    transform: translateY(-5rem);
  }

  to{
    transform: translateY(0);
  }
`;

const HeaderAnimeClose = keyframes `
  from{
    transform: translateY(0);
  }
  
  to{
    transform: translateY(-5rem);
  }
`;

export const Menu = styled.header`
  /* Display & Box Model */
  width: 100%;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: fixed;
  z-index: 900;

  /* Others */
  animation: ${HeaderAnime} 0.3s ease-out forwards;
  border-bottom: 1px solid var(--color-border);
  
  &.closing{
    animation: ${HeaderAnimeClose} 0.3s ease-out forwards;
  }
  @media print {
    button {
      display: none;
    }
    position: static;
  }
`;

/*------------------------- Mobile menu */

Menu.NavMobile = styled.nav`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #logo {
    /* Colors */
    background-color: var(--color-primary-light);
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: none;

    #logo {
      /* Colors */
      background-color: transparent;
    }
  }
`;

Menu.LinksMobile = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  padding: 2rem;

  /* Colors */
  color: var(--color-white);
  background-color: var(--color-black);

  /* Positon */
  position: fixed;
  left: 0;
  top: 0;

  span {
    cursor: pointer;
  }

  a {
    /* Color */
    color: var(--color-white);
  }

  button {
    figure {
      /* Display & Box Model */
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      /* Others */
      border-radius: 0.3rem;
      overflow: hidden;
    }

    img {
      /* Display & Box Model */
      width: 2rem;
    }
  }
`;

Menu.Close = styled.figure`
  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-gap: 1rem;
`;

/*------------------------- Desktop menu */

Menu.Nav = styled.nav`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
  display: none;

  @media (min-width: 800px) {
    /* Display & Box Model */
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    button {
      margin: 0;
    }
  }
`;

Menu.Logo = styled.div`
  /* Display & Box Model */
  width: 10rem;

  img {
    width: 100%;
  }

  @media (max-width: 540px) {
    width: 8rem;
  }
`;

Menu.Links = styled.div`
  /* Display & Box Model */
  display: flex;
  align-items: center;
  grid-gap: 2rem;

  /* Colors */
  color: var(--color-white);

  span {
    cursor: pointer;
  }

  a {
    /* Color */
    color: var(--color-white);
  }

  figure {
    /* Display & Box Model */
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Others */
    border-radius: 0.3rem;
    border: 1px solid var(--color-border);
    overflow: hidden;
  }

  img {
    /* Display & Box Model */
    height: 2rem;
  }

  @media print {
    button {
      display: none !important;
    }
  }
`;

Menu.BuyerNav = styled.nav`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0;
  display: none;

  /* Display & Box Model */
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  button {
    margin: 0;
  }

  @media (max-width: 760px) {
    p {
      display: none;
    }

    button {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }

    button:first-of-type {
      display: none;
    }
  }
`;
