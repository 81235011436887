import styled, { keyframes } from 'styled-components';
// Images
import eventPlaceBgFirst from '../../assets/images/learning_village/learning-village-auditorio.jpg';
import eventPlaceBgSecond from '../../assets/images/learning_village/learning-village-sala.jpg';

const colors = {
    blue: "#2F0DC6",
    lightBlue: "#E0E0E0",
    pink: "#F20089",
    rgbPink: " 242, 0, 137 ",
    darkGray: "#1F1F1F",
    mediumGray: "#272727",
    lightGray: "#D3D3D3",
    white: "#FFFFFF",
    black: "#000000",
    border: "#D3D3D3"
}


export const LVStore = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    
    /* Position */
    position: relative;

    p, h1, h2, h3, h4, h5, h6, a, ul, li, ol, button, input, textarea, div, span, figcaption {
        /* Text */
        font-family: 'Montserrat', sans-serif;;
    }
`;

/*=======================================
                Elements
=======================================*/

LVStore.Button = styled.button `
    /* Display & Box Model */
    padding: 1rem;
    margin-left: 1rem;

    /* Colors */
    background-color: transparent;

    /* Text */
    font-size: 1rem;
    font-family: inherit;

    /* Position */
    position: relative;

    /* Others */
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;

    span, a{
        /* Position */
        position: relative;
    }

    &.bgDark {
        span, a{
            /* Colors */
            color: ${ colors.white };
        }
    }

    &.bgLight {
        span, a{
            /* Colors */
            color: ${ colors.blue };
        }
    }
  
    /* BEFORE */

    ::before{
        /* Display & Box Model */
        content: "";
        width: 60%;
        height: 3.5rem;
        display: block;

        /* Position */
        position: absolute;
        top: -0.1rem;
        left: -1rem;
    }

    :hover::before{
        /* Display & Box Model */
        width: 120%;

        /* Others */
        transition: all 0.3s;
    }

    &.bgDark::before{
        /* Colors */
        background-color: ${ colors.mediumGray };
    }

    &.bgLight::before{
        /* Colors */
        background-color: ${ colors.lightGray };
    }

    &.blue:hover::before{
        /* Colors */
        background-color: ${ colors.blue };
    }

    /* AFTER */

    &.bgDark::after{
        /* Colors */
        background-color: ${ colors.white };
    }

    &.bgLight::after{
        /* Colors */
        background-color: ${ colors.blue };
    }

    ::after{
        /* Display & Box Model */
        content: "";
        width: 3rem;
        height: 0.1rem;

        /* Position */
        position: absolute;
        left: -3rem;
        top: 1.5rem;
    }

    &.bgLight:hover{
        span, a{
            /* Colors */
            color: ${ colors.white };
        }
    }

    &.bgLight:hover::after{
        /* Colors */
        background-color: ${ colors.lightGray }
    }

`;


/*=======================================
            First section
=======================================*/

LVStore.EventPlace = styled.div `
    /* Display & Box Model */
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

LVStore.EventPlaceHeader = styled.header `
    /* Display & Box Model */
    width: 100%;
    height: 12rem;
    margin: auto;
    padding: 2rem;

    /* Colors */
    background-color: ${ colors.black };

    /* Others */
    backdrop-filter: blur(15px);

    h1{
        /* Display & Box Model */
        margin-bottom: 0.5rem;
        
        /* Colors */
        color: ${ colors.white };

        /* Text */
        font-size: 1.5rem;
        font-weight: 200;
        text-align: center;
    }

    h2{
        /* Colors */
        color: ${ colors.white };

        /* Text */
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 60%;
        height: 12rem;

        /* Colors */
        background-color: rgba( 0, 0, 0, 0.8);
        
        /* Position */
        position: absolute;
        top: 0;
        z-index: 9;
    }
`;

LVStore.EventPlaceLogo = styled.figure `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        /* Display & Box Model */
        width: 5rem;
    }

    figcaption{
        /* Display & Box Model */
        height: 3rem;
        margin-left: 2rem;
        padding-left: 1rem;
        display: flex;
        align-items: center;

        /* Colors */
        color: ${ colors.pink };

        /* Text */
        font-size: 1.2rem;

        /* Others */
        border-left: solid 0.1rem ${ colors.pink };
    }
`;

LVStore.EventPlaceGrid = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 800px){
        /* Display & Box Model */
        flex-direction: row;
    }
`;

LVStore.EventPlaceSection = styled.section `
    /* Display & Box Model */
    width: 100%;
    height: 80vh;

    /* Position */
    position: relative;
    
    /* Others */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 0.3s ease-out;
    
    :nth-child(1){
        /* Others */
        background-image: url(${ eventPlaceBgSecond });
    }
    
    :nth-child(2){
        /* Others */
        background-image: url(${ eventPlaceBgFirst });
        
        div{
            /* Colors */
            background-color: ${ colors.pink };
            
            /* Position */
            left: 0;
            
            /* Text */
            text-align: left;
        }
    }
    
    @media (min-width: 800px){
        /* Display & Box Model */
        width: 50%;
        height: 100vh;

        /* Others */
        filter: grayscale(1);
        
        :hover{
            /* Display & Box Model */
            width: 75%;
            
            /* Others */
            transition: all 0.3s ease-out;
            filter: grayscale(0);
        }
    }
`;

LVStore.EventPlaceContent = styled.div `
    /* Display & Box Model */
    width: 20rem;
    padding: 2rem;

    /* Colors */
    color: ${ colors.white };

    /* Position */
    position: absolute;
    bottom: 1rem;

    :nth-child(1){
        /* Colors */
        background-color: ${ colors.blue };

        /* Position */
        right: 0;

        /* Text */
        text-align: right;
    }

    h2{
        /* Display & Box Model */
        margin-bottom: 0.5rem;

        /* Text */
        font-weight: 300;
    }

    p{
        /* Display & Box Model */
        margin-bottom: 1.5rem;

        /* Text */
        font-size: 0.8rem;
        font-weight: 200;
    }

`;


/*=======================================
                Menu
=======================================*/

LVStore.Menu = styled.header `
    /* Display & Box Model */
    width: 100%;
    padding-top: 1rem;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
`;

LVStore.MenuContainer = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

LVStore.MenuLogo = styled.figure `
    /* Display & Box Model */
    height: 8rem;
    display: flex;
    align-items: center;

    /* Position */
    position: relative;
    z-index: 98;

    img{
        /* Display & Box Model */
        width: 8rem;
        margin-right: 2rem;
    }

    @media (min-width: 800px){
        ::before{
            /* Display & Box Model */
            content: "";
            width: 180%;
            height: 10rem;
            display: block;

            /* Colors */
            background-color: rgba( 0, 0, 0, 0.8);

            /* Position */
            position: absolute;
            left: -50%;
            z-index: -1;

            /* Others */
            backdrop-filter: blur(15px);
        }
    }
`;

LVStore.MenuTitle = styled.h1 `
    /* Display & Box Model */
    padding-left: 1rem;

    /* Colors */
    color: ${ colors.pink };

    /* Text */
    font-size: 1.2rem;
    font-weight: 300;

    /* Others */
    border-left: solid 0.1rem ${ colors.pink };
`;

LVStore.MenuNav = styled.nav `
    /* Display & Box Model */
    width: 65%;
    height: 8rem;
    display: none;
    justify-content: center;
    justify-content: flex-start;
    grid-gap: 2rem;
    align-items: flex-end;
    padding-bottom: 1.8rem;

    span{
        /* Colors */
        color: ${ colors.white };

        /* Text */
        font-size: 0.8rem;
        text-transform: uppercase;

        /* Others */
        cursor: pointer;
    }

    @media (min-width:800px){
        /* Display & Box Model */    
        display: flex;
    }
`;

LVStore.MenuNavBg = styled.div `
    /* Display & Box Model */
    width: 70%;
    height: 10rem;

    /* Colors */
    background-color: ${ colors.darkGray };

    /* Position */
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;

    @media (min-width: 1400px){
        /* Display & Box Model */
        width: 65%;
    }
`;


/*=======================================
            Banner home
=======================================*/

LVStore.Banner = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Colos */
    color: ${ colors.white };
    background-color: #000;

    /* Others */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.auditorium{
        background-image: url(${ eventPlaceBgFirst });
    }

    &.room{
        background-image: url(${ eventPlaceBgSecond });
    }

    .container{
        /* Display & Box Model */
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
`;

LVStore.BannerContainer = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 72.3%;
    }
`;

LVStore.BannerTitle = styled.h1 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 2rem;

    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;

    /* Others */
    text-shadow: 0 0.1rem 0.5rem #000000;

    @media (min-width: 800px){
        /* Text */
        font-size: 2rem;
    }
`;

LVStore.BannerText = styled.h3 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 2rem;

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    /* Others */
    text-shadow: 0 0.1rem 0.5rem #000000;

    @media (min-width: 800px){
        /* Text */
        font-size: 1.2rem;
    }
`;


/*=======================================
            Items sections
=======================================*/

LVStore.Section = styled.section `
    /* Display & Box Model */
    width: 100%;
    padding: 4rem 0;

    /* Colors */
    background-color: ${ colors.white };
`;

LVStore.SectionTitle = styled.h4 `
    /* Display & Box Model */
    width: 60%;
    height: 6rem;
    display: flex;
    align-items: center;

    /* Colors */
    background-color: ${ colors.pink };
    color: ${ colors.white };

    /* Text */
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;

    /* Position */
    position: relative;
    top: -2rem;
    z-index: 9;

    ::before{
        /* Display & Box Model */
        content: "";
        width: 50%;
        height: 6rem;
        display: block;

        /* Colors */
        background-color: ${ colors.pink };

        /* Position */
        position: absolute;
        left: -50%;
    }

    ::after{
        /* Display & Box Model */
        content: "";
        width: 50%;
        height: 6rem;
        display: block;

        /* Colors */
        background-color: ${ colors.pink };

        /* Position */
        position: absolute;
        right: -50%;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 20%;
        
        /* Position */
        top: 1rem;
    }

    @media (min-width: 1400px){
        width: 15%;

        ::before{
            /* Display & Box Model */
            width: 180%;

            /* Position */
            left: -180%;
        }

        ::after{
            /* Display & Box Model */
            width: 80%;

            /* Position */
            right: -80%;
        }
    }

`;

LVStore.SectionContainer = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
    margin: auto;
    
    /* Position */
    position: relative;
    top: -3rem;
    z-index: 10;
    
    @media (min-width: 800px){
        /* Display & Box Model */
        width: 80%;
        grid-template-columns: repeat( 3, 1fr);
        margin: 0 0 0 auto;
    }
`;

/*=======================================
            Items
=======================================*/

LVStore.ItemCard = styled.div `
    /* Display & Box Model */
    height: 28rem;

    /* Colors */
    background-color: ${ colors.white };

    /* Position */
    position: relative;

    /* Others */
    border: solid 0.1rem ${ colors.border };
    cursor: pointer;

    ::before{
        /* Display & Box Model */
        content: "";
        width: 100%;
        height: 28rem;
        display: block;

        /* Colors */
        background-color: ${ colors.pink };

        /* Position */
        position: absolute;
        top: 0rem;
        left: 0rem;
        z-index: -1;
        
        /* Others */
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    
    :hover{
        ::before{
            /* Position */
            top: -0.5rem;
            left: -0.5rem;

            /* Others */
            opacity: 1;
            transition: all 0.3s ease-out;
        }

        button::before{
            /* Display & Box Model */
            width: 120%;

            /* Colors */
            background-color: ${ colors.blue };

            /* Others */
            transition: all 0.3s;

        }

        button{
            span, a{
                /* Colors */
                color: ${ colors.white };
            }
        }

        button::after{
            /* Colors */
            background-color: ${ colors.white };
        }
    }

    button{
        span, a{
            /* Text */
            font-size: 0.8rem;
        }

        ::before{
            /* Display & Box Model */
            height: 2.5rem;
    
            /* Position */
            top: 0.5rem;
        }

        ::after{
            /* Display & Box Model */
            width: 2rem;

            /* Position */
            left: -2rem;
        }
    }
`;

LVStore.ItemCardAdded = styled.div `
    /* Display & Box Model */
    width: 3rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* Colors */
    background-color: ${ colors.pink };

    /* Position */
    position: absolute;
    top: 0;
    right: 1rem;

    svg{
        /* Display & Box Model */
        width: 1.5rem;
        margin-bottom: 0.5rem;

        /* Colors */
        fill: ${ colors.white };
    }

    span{
        /* Colors */
        color: ${ colors.white };

        /* Text */
        font-size: 0.6rem;
        text-align: center;
    }
`;

LVStore.ItemCardImg = styled.figure `
    /* Display & Box Model */
    width: 100%;
    height: 15rem;
    margin-bottom: 1rem;

    /* Colors */
    background-color: ${ colors.border };

    /* Others */
    overflow: hidden;
    border: none;

    img{
        /* Display & Box Model */
        width: 100%;
    }
`;

LVStore.ItemCardName = styled.p `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    margin-bottom: 1rem;

    /* Colors */
    color: ${ colors.black };
`;

LVStore.ItemCardPrice = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    /* Colors */
    color: ${ colors.black };

    span{
        /* Text */
        font-size: 0.8rem;
    }
`;

/*=======================================
            Modal Item
=======================================*/

LVStore.ModalItem = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    button{
        /* Display & Box Model */
        width: fit-content;
    }
`;

// LVStore.Lightbox = styled.div `
//     /* Display & Box Model */
//     width: 100%;
//     height: 100vh;

//     /* Position */
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 998; 
//     background-color: rgba(0, 0, 0, 0.8);
// `;

LVStore.ModalContainer = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 75vh;

    /* Colors */
    background-color: ${ colors.white };

    /* Position */
    position: relative;
    z-index: 999;
`;

LVStore.ModalContent = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 75vh;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 4rem;
    align-items: flex-end;
`;

LVStore.ModalPhotos = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 70vh;

    
    /* Position */
    position: relative;
    
    /* Others */
    overflow: hidden;

    figure{
        /* Display & Box Model */
        width: 90%;
        height: 55vh;
        margin: 2rem 0 0 auto;

        /* Colors */
        background-color: ${ colors.darkGray };

        /* Position */
        position: relative;

        /* Others */
        overflow: hidden;
    }
    
    img{
        /* Display & Box Model */
        width: 100%;
    }

    `;

LVStore.ModalPhotosBg = styled.div `
    /* Display & Box Model */
    width: 80%;
    height: 70vh;
    
    /* Colors */
    background-color: ${ colors.pink };

    /* Position */
    position: absolute;
`;

LVStore.ModalPhotosControl = styled.div `
    /* Display & Box Model */
    width: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Position */
    position: absolute;
    right: 0.5rem;
    bottom: 1rem;

    img{
        /* Display & Box Model */    
        width: 1.2rem;

        /* Others */
        cursor: pointer;
    }
`;

LVStore.ModalClose = styled.figure `
    /* Display & Box Model */
    width: 1.2rem;

    /* Position */
    position: absolute;
    top: 0;
    right: 0;

    /* Others */
    cursor: pointer;

    img{
        /* Display & Box Model */
        width: 100%;

        /* Others */
        transform: rotate(90deg);
    }
`;

LVStore.ModalDetails = styled.div `
    /* Display & Box Model */
    width: 88%;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;

    /* Colors */
    color: ${ colors.black };

    /* Position */
    position: relative;
`;

LVStore.ModalName = styled.h5 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 2rem;

    /* Text */
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
`;

LVStore.ModalDescription = styled.textarea `
    /* Display & Box Model */
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;

    /* Colors */
    background-color: transparent;

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border: none;
    overflow-y: scroll;
`;

LVStore.ModalPrices = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

LVStore.ModalPricesItem = styled.div `
    /* Display & Box Model */
    margin-top: 2rem;

    /* Colors */
    color: ${ colors.black };

    .item__quantity{
        /* Display & Box Model */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item__quantity--print{
        /* Display & Box Model */
        display: none;
    }

    input{
        /* Display & Box Model */
        width: 5rem;
        text-align: center;
        margin: 0;
    }

    button{
        /* Display & Box Model */
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        /* Colors */
        background-color: transparent;

        /* Text */
        font-size: 1.2rem;

        /* Others */
        border: solid 0.1rem;
        cursor: pointer;

        span{
            /* Display & Box Model */
            margin-bottom: 0;
        }
    }

    span{
        /* Display & Box Model */
        margin-bottom: 1rem;
        display: block;
    }

    h6{
        /* Text */
        font-size: 1rem;
    }
`;


/*===========================================
                Shopping Cart
===========================================*/

LVStore.CartComponent = styled.div `
    /* Display & Box Model */
    width: auto;
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;

    /* Position */
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;

    /* Others */
    transition: all 0.3s ease-out;
    
    &.open{
        /* Position */
        width: 100%;
        min-height: 100vh;
    }

    .container{
        /* Display & Box Model */
        display: flex;
        justify-content: flex-end;
    }
`;

LVStore.CartButton = styled.button `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* Colors */
    background-color: ${ colors.blue };
    color: ${ colors.white };

    /* Position */
    position: fixed;
    right: 0;
    bottom: 0;

    /* Text */
    font-size: 0.8rem;
    text-transform: uppercase;

    /* Others */
    border: none;
    cursor: pointer;

    ::after{
        /* Display & Box Model */
        content: "";
        width: 20%;
        height: 3rem;
        display: block;

        /* Colors */
        background-color: ${ colors.blue };

        /* Position */
        position: absolute;
        right: -20%;
        top: 0.01rem;
    }

    svg{
        /* Display & Box Model */
        width: 1.5rem;
        margin-right: 1rem;

        /* Colors */
        fill: ${ colors.white };
    }
    
    span{
        /* Display & Box Model */
        display: flex;
        align-items: center;
    }

    div{
        /* Display & Box Model */
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1rem;

        /* Colors */
        background-color: ${ colors.white };
        color: ${ colors.blue };

        /* Others */
        border-radius: 50%;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        width: 10rem;
        justify-content: flex-end;

        /* Position */
        top: 0;
    }

    @media (min-width:1400px){
        ::after{
            /* Display & Box Model */
            width: 30%;

            /* Position */
            right: -30%;
        }
    }
`;

LVStore.Cart = styled.div `
    /* Display & Box Model */
    width: auto;
    height: 100%;
    min-height: 100vh;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    /* Color */
    background-color: ${ colors.darkGray };
    color: ${ colors.white };

    /* Position */
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    /* Text */
    text-align: center;

    /* Others */
    transition: all 0.3s ease-out;
    overflow-y: scroll;

    &.open{
        /* Display & Box Model */
        width: 80%;
    }

    .space{
        /* Display & Box Model */
        width: 100%;
        height: 3rem;
    }

    .input__field{
        label{
            /* Colors */
            color: ${ colors.white };

            /* Position */
            top: -1.5rem;
        }
    }

    .input{
        /* Colos */
        color: ${ colors.black };

        /* Others */
        border-radius: 0;

        :focus{
            /* Others */
            border: none;
        }
    }

    button{
        /* Display & Box Model */
        width: 12rem;
        margin: auto;
    }

    @media (min-width: 800px){
        &.open{
            /* Display & Box Model */
            width: 40%;
        }
    }
`;

const lightboxAnimation = keyframes `
    from{
        opacity: 0;
    }

    to{
        opacity: 0.8;
    }
`;

LVStore.CartTitle = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-right: 1rem;

    /* Colors */
    background-color: ${ colors.pink };
    color: ${ colors.white };

    /* Position */
    position: relative;

    ::before{
        /* Display & Box Model */
        content: "";
        width: 20%;
        height: 3rem;

        /* Colors */
        background-color: ${ colors.pink };

        /* Position */
        position: absolute;
        top: 0;
        left: -20%;
    }

    h3{
        /* Text */
        font-size: 1.2rem;
        font-weight: 300;
    }

    h5{
        /* Text */
        font-size: 1rem;
        font-weight: 300;
    }
`;

LVStore.CartItems = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 1rem;
`;

LVStore.CartItem = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1rem;
    margin: 1rem auto;
    padding: 0.5rem 0;

    /* Position */
    position: relative;
    
    /* Others */
    border-bottom: solid 0.1rem var(--color-gray);
`;

LVStore.CartTotalPrice = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    /* Position */
    position: relative;

    label{
        /* Text */
        font-weight: 300;
    }

    h1{
        /* Colors */
        color: ${ colors.pink };

        /* Text */
        font-weight: 700;
    }

    div{
        /* Display & Box Model */
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    span{
        /* Text */
        font-weight: 300;
    }

    .input{
        /* Others */
        border-radius: 0;
    }

    &.checkout{
        p, label, span{
            /* Colors */
            color: ${ colors.white };
        }

        label{
            /* Position */
            top: -1.5rem;
        }
        
        h1{
            /* Colors */
            color: ${ colors.pink } !important;
        }
    }
`;

LVStore.CartItemImg = styled.figure `
    /* Display & Box Model */
    width: 100%;

    img{
        /* Display & Box Model */
        width: 100%;
    }
`;

LVStore.CartText = styled.div `
    /* Display & Box Model */
    width: 100%;
    
`;

LVStore.CartItemName = styled.h6 `
    /* Display & Box Model */
    width: 100%;
    margin-bottom: 1rem;

    /* Color */
    color: ${ colors.white };

    /* Text */
    font-size: 1rem;
    font-weight: 300;
`

LVStore.CartItemPrice = styled.p `
    /* Display & Box Model */
    margin-bottom: 1rem;

    /* Color */
    color: ${ colors.pink };

    /* Text */
    font-weight: 700;
`;

LVStore.CartRemoveItem = styled.p `
    /* Others */
    cursor: pointer;
`;

LVStore.CartBtn = styled.button `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    border: none;
    margin: 2rem auto;

    /* Color */
    background-color: ${ colors.yellow };

    /* Text */
    font-size: 1rem;
    font-weight: 300;

    /* Position */
    /* position: relative;
    bottom: 0;
    right: 0; */

    /* Others */
    cursor: pointer;
`;

LVStore.Lightbox = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: none;

    /* Color */
    background-color: ${ colors.black };

    /* Others */
    opacity: 0;
    
    &.open{
        /* Display & Box Model */
        display: block;
        
        /* Others */
        animation: ${ lightboxAnimation } 0.3s ease-out 0.3s forwards;
    }
`;


/*===========================================
        Learning Village Checkout
===========================================*/

LVStore.FormPage = styled.div `
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 800px){
        /* Display & Box Model */
        display: grid;
        grid-template-columns: 35% 65%;
    }
`;

LVStore.FormItems = styled.div `
    /* Display & Box Model */
    width: 100%;
    padding-top: 2rem;

    /* Position */
    position: relative;

    ::before{
        /* Display & Box Model */
        content: "";
        width: 120%;
        height: 100%;
        min-height: 100vh;
        display: block;

        /* Colors */
        background-color: ${ colors.black };

        /* Position */
        position: absolute;
        top: 0;
        left: -20%;
    }

    h4{
        /* Display & Box Model */
        height: 3rem;
        margin-bottom: 2rem;

        /* Colors */
        background-color: transparent !important;

        ::before{
            /* Display & Box Model */
            width: 50%;
            height: 3rem;
            display: none;


            /* Position */
            left: -50%;
        }
    }

    .input__field{
        /* Display & Box Model */
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            /* Colors */
            color: ${ colors.white };
        }

        span{
            /* Colors */
            color: ${ colors.pink };
        }
    }

    @media (min-width: 800px){
        padding-right: 3rem;
    }

    @media (min-width: 1400px){
        ::before{
            /* Display & Box Model */
            width: 200%;

            /* Position */
            left: -100%;
        }
    }
`;

LVStore.FormContent = styled.div `
    /* Display & Box Model */
    width: 100%;
    margin: 2rem auto;
    padding-top: 2rem;

    form{
        /* Position */
        position: relative;
    }

    @media (min-width: 800px){
        /* Display & Box Model */
        padding-left: 3rem;
        margin: 0;
        border-left: solid 0.1rem;
    }

    h4{
        /* Display & Box Model */
        height: 3rem;
        margin-bottom: 2rem;

        /* Colors */
        background-color: transparent !important;
        color: ${ colors.black };

        ::before{
            /* Display & Box Model */
            width: 19%;
            height: 3rem;
            display: none;

            /* Position */
            left: -19%;
        }
    }
`;

LVStore.FormContentTitle = styled.h2 `
    /* Display & Box Model */
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;

    /* Colors */
    color: ${ colors.black };

    /* Text */
    font-size: 1.2rem;
    font-weight: 300;
`;

LVStore.BackButton = styled.div `
    /* Display & Box Model */
    width: 6rem;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    /* Colors */
    color: ${ colors.white };
    background-color: ${ colors.pink };
    
    /* Text */
    font-size: 1rem;
    
    /* Position */
    position: absolute;
    top: 2rem;
    right: 0;
    
    /* Others */
    cursor: pointer;

    ::before{
        /* Display & Box Model */
        content: "";
        width: 50%;
        height: 2rem;

        /* Colors */
        background-color: ${ colors.pink };

        /* Position */
        position: absolute;
        right: -50%;
    }
    
    a{
        /* Color */
        color: ${ colors.white };
    }
`;

LVStore.SelectBuyer = styled.div `
    /* Display & Box Model */
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3{
        /* Display & Box Model */
        margin: 2rem auto;

        /* Text */
        font-size: 1.2rem;
        font-weight: 300;
    }
`;

