import React, { useEffect, useState, useRef } from 'react';
import Modal from '../../Components/Modal';
// Styles & Images
import { ReviewsPage } from './styles';
import LeftArrow from '../../assets/icons/review-left-arrow.svg';
import RightArrow from '../../assets/icons/review-right-arrow.svg';

const Star = ({ setRating, value, isActived }) => {
    return (
        <svg
            onClick={() => setRating(value)}
            className={isActived ? 'actived' : undefined}
            width="48"
            height="46"
            viewBox="0 0 48 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M22.5734 2.00861C23.0225 0.626644 24.9776 0.626644 25.4266 2.00861L29.6605 15.0391C29.9952 16.0692 30.9551 16.7666 32.0381 16.7666H45.7392C47.1923 16.7666 47.7965 18.626 46.6209 19.4801L35.5365 27.5334C34.6603 28.17 34.2936 29.2985 34.6283 30.3285L38.8622 43.359C39.3112 44.741 37.7295 45.8902 36.5539 45.0361L25.4695 36.9828C24.5933 36.3462 23.4068 36.3462 22.5306 36.9828L11.4461 45.0361C10.2706 45.8902 8.68883 44.741 9.13786 43.3591L13.3717 30.3285C13.7064 29.2985 13.3398 28.17 12.4636 27.5334L1.37913 19.4801C0.203557 18.626 0.807719 16.7666 2.26081 16.7666H15.9619C17.045 16.7666 18.0049 16.0692 18.3396 15.0391L22.5734 2.00861Z" />
        </svg>
    );
};

function ReviewCard({ review, supplierReviews, setSupplierReviews }) {
    const { id, name, description, supplier, photos } = review;
    const modalRef = useRef();

    const tags = [
        'Apresentação ruim',
        'Atrasou a entrega',
        'Qualidade abaixo do esperado',
        'Entrega diferente do contratado',
        'Falta de profissionalismo',
        'Outros motivos',
    ];

    const [currentPhoto, setCurrentPhoto] = useState(0);
    const [supplierReview, setSupplierReview] = useState(null);
    const [rating, setRating] = useState();
    const [selectedTags, setSelectedTags] = useState([]);
    const [comment, setComment] = useState();

    useEffect(() => {
        rating === 5 && setSelectedTags([]);
    }, [rating]);

    useEffect(() => {
        rating &&
            setSupplierReview({
                id: id,
                rating: rating,
                comments: selectedTags.join('. '),
            });
    }, [id, rating, selectedTags]);

    function toggleTags(tag) {
        setSelectedTags(
            selectedTags.some(e => e.includes(tag))
                ? selectedTags.filter(e => !e.includes(tag))
                : [...selectedTags, tag]
        );
    }

    useEffect(() => {
        setSelectedTags(tags => {
            if (
                comment &&
                tags[tags.findIndex(e => e.includes('Outros motivos'))] !==
                    `Outros motivos: ${comment}`
            ) {
                const newTags = [...tags];
                newTags[
                    newTags.findIndex(e => e.includes('Outros motivos'))
                ] = `Outros motivos: ${comment}`;
                return newTags;
            } else {
                return tags;
            }
        });
    }, [comment, selectedTags]);

    useEffect(() => {
        let obj = supplierReviews;

        if (supplierReview) {
            if (supplierReviews.some(e => e.id === id)) {
                obj[obj.findIndex(e => e.id === id)] = supplierReview;
                setSupplierReviews(obj);
            } else {
                setSupplierReviews([...supplierReviews, supplierReview]);
            }
        }
    }, [id, setSupplierReviews, supplierReview, supplierReviews]);

    return (
        <ReviewsPage.Review>
            <ReviewsPage.ReviewGrid 
                // className={photos.length > 0 && 'photos'}
            >
                {/* {photos.length > 0 && (
                    <ReviewsPage.ReviewImages>
                        {currentPhoto > 0 && (
                            <ReviewsPage.ReviewImagesBtn
                                className="left"
                                onClick={() => setCurrentPhoto(currentPhoto - 1)}
                                src={LeftArrow}
                            />
                        )}
                        <img src={photos[currentPhoto].url} alt="" />
                        {currentPhoto < photos.length - 1 && (
                            <ReviewsPage.ReviewImagesBtn
                                className="right"
                                onClick={() => setCurrentPhoto(currentPhoto + 1)}
                                src={RightArrow}
                            />
                        )}
                    </ReviewsPage.ReviewImages>
                )} */}

                <ReviewsPage.ReviewDetails>
                    <p className="txt--bold txt--black">{name}</p>
                    {/* <p className="margin--bottom-2">Oferecido por: {supplier.name} </p> */}

                    <ReviewsPage.ReviewDescription>{description}</ReviewsPage.ReviewDescription>

                    <p
                        className="txt--center txt--primary txt--bold margin--top-1"
                        style={{ cursor: 'pointer', fontSize: '0.8rem' }}
                        onClick={() => modalRef.current.open()}
                    >
                        Ver mais
                    </p>

                    <ReviewsPage.ReviewStars>
                        {[1, 2, 3, 4, 5].map((value, index) => {
                            return (
                                <Star
                                    key={index}
                                    value={value}
                                    rating={rating}
                                    isActived={value <= rating}
                                    setRating={setRating}
                                />
                            );
                        })}
                    </ReviewsPage.ReviewStars>
                </ReviewsPage.ReviewDetails>
            </ReviewsPage.ReviewGrid>

            {rating && rating < 5 && (
                <ReviewsPage.ReviewFeedback>
                    <p className="margin--tb-1">Houve problemas com esse serviço?</p>

                    {tags.map((tag, index) => {
                        return (
                            <ReviewsPage.ReviewFeedbackTag
                                key={index}
                                className={selectedTags.some(e => e.includes(tag)) && 'selected'}
                                onClick={() => toggleTags(tag)}
                            >
                                {tag}
                            </ReviewsPage.ReviewFeedbackTag>
                        );
                    })}
                    <br />
                    {selectedTags.some(tag => tag.includes('Outros motivos')) && (
                        <div className="input__field">
                            <label htmlFor="description"> Descreva o problema </label>
                            <textarea
                                name="description"
                                id="description"
                                className="input textarea"
                                cols="30"
                                rows="5"
                                value={comment}
                                onChange={event => setComment(event.target.value)}
                            />
                        </div>
                    )}
                </ReviewsPage.ReviewFeedback>
            )}

            <Modal ref={modalRef}>
                <h3 className="modal__title">{name}</h3>
                <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
            </Modal>
        </ReviewsPage.Review>
    );
}

export default ReviewCard;
