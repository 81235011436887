import React, { useEffect, useState, useRef } from 'react';
import { Fragment } from 'react';
import Modal from '../../Components/Modal';
import { ProposalPage } from './styles';

import LeftArrow from '../../assets/icons/arrowLeft.svg';
import RightArrow from '../../assets/icons/arrowRight.svg';

function OfferItems(
    {
        item,
        index,
        selectItem,
        toBraCurrency,
        calculateAdditionalPrice,
        calculateTotal

    }
){

    const [quantity, setQuantity] = useState( parseInt(item.quantity) );
    const [proposalFinalPrice, setproposalFinalPrice] = useState();
    const [isItemAdded, setIsItemAdded] = useState(false);
    const [currentPhoto, setcurrentPhoto] = useState(0);

    const modalRef = useRef();

    function changeQuantity(value){

        let newQuantity = quantity;

        if( value === "mais" && quantity < parseInt(item.max_quantity) ){

            newQuantity += 1;
            setQuantity(newQuantity);

        } else if( value === "menos" && quantity > parseInt(item.min_quantity) ){

            newQuantity -= 1;
            setQuantity(newQuantity);

        }
        
    }

    function inputChangeQuantity(value) {

        if( value > parseInt(item.max_quantity) ){

            setQuantity( parseInt(item.max_quantity) );

        } else if ( value < parseInt(item.min_quantity) ){

            setQuantity( parseInt(item.min_quantity) );

        } else {

            setQuantity( parseInt(value) );

        }

    }

    function calculateSubtotal(obj){

        return parseFloat(obj.price) * parseInt(obj.quantity);

    }

    function addItem( event, item, index ){

        setIsItemAdded( !isItemAdded );

        item.final_buyer_price = proposalFinalPrice;
        item.quantity = quantity;

        selectItem(event,item, index);

    }

    function removeItem( event, item, index ){

        setIsItemAdded( !isItemAdded );
        selectItem( event, item, index );

    }

    function photosSlider(value){

        let newCurrentPhoto = currentPhoto;

        if( value === "next" && currentPhoto < parseInt(item.photos.length) - 1 ){

            newCurrentPhoto += 1;
            setcurrentPhoto(newCurrentPhoto);

        } else if( value === "prev" && currentPhoto > 0 ){

            newCurrentPhoto -= 1;
            setcurrentPhoto(newCurrentPhoto);

        }

    }

    useEffect( () => {

        setproposalFinalPrice(calculateSubtotal({"price" : parseFloat(item.buyer_price), "quantity": quantity }));

    },[quantity] );


    return(
        <ProposalPage.Item
            key={ item.id }
            className={ item.photos && item.photos.length > 0 ? "image" : '' }
        >

        <div className="card__added">
            <p> Adicionado ao carrinho </p>
        </div>

        {
            item.photos && item.photos.length > 0 && (
                <figure className="item__img" onClick={ () => modalRef.current.open() }>
                    <img src={ item.photos[0].url } alt={ item.name } />
                </figure>
            )
        }


        <div className="item__content" >
            <div className="item__name" onClick={ () => modalRef.current.open() }>
                <h3> { item.name } </h3>
                {/* <h4> Fornecido por: { item.user_name ? item.user_name : ''} </h4> */}
            </div>

            <h5 className="item__price txt--secondary">
                {
                    toBraCurrency(calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee }))
                }
            </h5>

            {
                item.photos.length < 1 && (
                    <div className="item__description item__description--without-photo" onClick={ () => modalRef.current.open() }>
                        <p> { item.description } </p>
                    </div>
                )
            }

            <button className="btn btn--secondary bg--white" id={ `item-${ index }` } onClick={ (event) => addItem(event.currentTarget,item, index)}>
                <span> Add ao carrinho </span>
            </button>

            <button className="card__remove margin--tb-2" onClick={ (event) => removeItem( event.currentTarget, item, index)}>
                <span> Remover do carrinho </span>
            </button>
        </div>

        {/* Modal with item details */}

        <Modal ref={modalRef}>
            <div className={ item.photos.length > 0 && "item__modal--photos" }>

                {
                    item.photos && item.photos.length > 0 && (
                        <div className="item__slider">
                            <button className="item__button" onClick={ () => photosSlider("prev") }>
                                <img src={ LeftArrow } alt=""/>
                            </button>

                            <img src={ item.photos[currentPhoto].url } alt={ item.name } />

                            <button className="item__button" onClick={ () => photosSlider("next") }>
                                <img src={ RightArrow } alt=""/>
                            </button>
                        </div>
                    )
                }

                <div >
                    <div className="item__name" onClick={ () => modalRef.current.open() }>
                        <h3> { item.name } </h3>
                        <h4> Fornecido por: { item.user_name ? item.user_name : ''} </h4>
                    </div>

                    <div className="item__description">
                        <textarea cols="30" rows="10" disabled>
                            { item.description }
                        </textarea>
                    </div>

                    {
                        item.observation && (
                        <div className="item__description">
                            <span> Observações </span>
                            <p> { item.observation } </p>
                        </div>
                        )
                    }
                </div>
            </div>

            {
                item.buyer_price && item.quantity ? (
                    <div className="item__numbers">

                        <div className="item__number">
                            <span> Valor do item </span>
                            <h5 className="item__price"> 
                                {
                                    toBraCurrency(parseFloat(item.buyer_price))
                                }
                            </h5>
                        </div>

                        <div className="item__number">
                            <span> Quantidade </span>
                            {
                                
                                item.editable && !isItemAdded ? (
                                    // <input type="number" min={ item.min_quantity } max={ item.max_quantity }> { item.quantity } </input>
                                    <div className="item__quantity" >
                                        <button type="button" onClick={ () => changeQuantity("menos") }>
                                            <span> - </span>
                                        </button>

                                        <input
                                            type="number"
                                            value={ quantity }
                                            onInput={ (event) => inputChangeQuantity(event.target.value) }
                                            className="input"
                                        />

                                        <button type="button" onClick={ () => changeQuantity("mais") }>
                                            <span> + </span>
                                        </button>
                                    </div>
                                ) : 
                                    <h5 className="item__price"> { quantity } </h5>
                            }
                        </div>

                        {
                            item.additional && item.additional_fee && (
                                <div className="item__number">
                                    <span> { item.additional } </span>
                                    <h5 className="item__price"> { toBraCurrency( calculateAdditionalPrice(item.additional_fee) ) } </h5>
                                </div>
                            )
                        }

                        <div className="item__number">
                            <span className="txt--secondary"> Total </span>
                            <h5 className="item__price txt--secondary">
                                {
                                    item.editable ? (
                                        toBraCurrency(calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee }))
                                    ) : (
                                        toBraCurrency(calculateTotal({"price" : item.buyer_price, "quantity": quantity, "additional": item.additional_fee }))
                                    )
                                }
                            </h5>
                        </div>
                    </div>
                ) : (
                    <div className="item__numbers">
                        <div className="item__description">
                            <h3> Este item pode ter sofrido alterações, por favor entre em contato com seu Especialista em Eventos </h3>
                        </div>
                    </div>
                    
                )
            }

            {
                isItemAdded ? (

                    <button
                        className="card__remove margin--tb-2"
                        onClick={ 
                            () => {
                                removeItem( 
                                    document.querySelector(`#item-${ index }`),
                                    item,
                                    index
                                )

                                modalRef.current.close();
                            }
                        }
                    >
                        <span> Remover do carrinho </span>
                    </button>
                ) : (
                    <button
                        className="btn btn--secondary bg--white"
                        id={ index }
                        onClick={
                            () => {
                                addItem(
                                    document.querySelector(`#item-${ index }`),
                                    item,
                                    index
                                )

                                modalRef.current.close();
                            }
                        }
                    >
                        <span> Add ao carrinho </span>
                    </button>
                )
            }
        </Modal>

    </ProposalPage.Item>
    )
}

export default OfferItems;