import styled from 'styled-components';

export const Feedback = styled.div`
    /* Display & Box Model */
    width: 4rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Colors */
    background-color: var(--color-black);
    color: var(--color-white);

    /* Position */
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    z-index: 998;

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border-radius: 0.1rem;
    cursor: pointer;
    transition: all 0.3s ease-out;

    ::after {
        /* Display & Box Model */
        content: '';
        width: 0.5rem;
        height: 1rem;

        /* Colors */
        background-color: black;

        /* Others */
        clip-path: polygon(0% 0%, 100% 50%, 0% 100%, 0% 50%);
        position: absolute;
        right: -0.5rem;
    }

    p {
        /* Display & Box Model */
        width: 0;

        /* Others */
        opacity: 0;
        transition: all 0.3s ease-out;
        white-space: nowrap;
        overflow: hidden;
    }

    :hover {
        /* Display & Box Model */
        width: 10rem;

        /* Others */
        transition: all 0.3s ease-out;

        p {
            /* Display & Box Model */
            width: auto;

            /* Others */
            opacity: 1;
            transition: all 0.3s ease-out;
        }
    }

    @media (min-width: 800px) {
        /* Position */
        bottom: 1rem;
    }
`;

Feedback.Icon = styled.img`
    /* Display & Box Model */
    width: 1.5rem;
    margin-right: 0.5rem;
`;

Feedback.Form = styled.form`
    /* Display & Box Model */
    width: 16rem;
    height: 26rem;
    padding: 1rem;

    /* Colors */
    background-color: var(--color-white);

    /* Position */
    position: fixed;
    bottom: 7rem;
    right: 1rem;
    z-index: 1002;

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border-radius: 0.3rem;
    box-shadow: var(--shadow);

    h4 {
        /* Display & Box Model */
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        /* Colors */
        color: var(--color-black);

        /* Others */
        border-bottom: 0.1rem solid var(--color-border);
    }

    p {
        /* Display & Box Model */
        margin-bottom: 2rem;
    }

    button {
        /* Text */
        font-size: 0.8rem;
    }

    @media (min-width: 800px) {
        /* Display & Box Model */
        width: 20rem;

        /* Position */
        bottom: 4rem;
    }
`;

Feedback.Close = styled.img`
    /* Display & Box Model */
    width: 2rem;

    /* Position */
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

Feedback.Lightbox = styled.div`
    /* Display & Box Model */
    width: 100%;
    height: 100vh;

    /* Colors */
    background-color: rgba(0, 0, 0, 0.7);

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
`;
