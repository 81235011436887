import React, { useState } from 'react';
import { EscalePage } from '../styles';
// Slide images
import slideBg from '../../../assets/images/escale/escale-slide-bg.jpg';

function EscaleSlide() {

    const slides = [
        {
            title: "Seus companheiros de escalada, sempre com você",
            text: "Que tal escalar com mais estilo? É fácil, basta adicionar os itens de sua escolha ao carrinho, confirmar seus dados e prontinho! Você receberá em seu e-mail o link para pagamento, junto com a confirmação das informações para finalizar o pedido. Show, né? Garanta sua comprinhas",
            image: slideBg
        }
    ];

    const [ currentSlideIndex, setCurrentSlideIndex ] = useState( 0 );

    return(
        <EscalePage.Slide >
            <img src={ slides[ currentSlideIndex ].image } alt="" />
            <div className="container">
                <h1> { slides[ currentSlideIndex ].title } </h1>
                <p> { slides[ currentSlideIndex ].text } </p>
            </div>
        </EscalePage.Slide>
    )
}

export default EscaleSlide;