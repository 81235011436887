import React, { useRef, useState } from 'react';
import axios from 'axios';
import HeaderPage from '../../../Components/HeaderPage';
import { useAuthContext } from '../../../Contexts/AuthContext';
import FeedbackChannel from '../../Supplier/FeedbackChannel';
import BuyerMenu from '../BuyerMenu';
import { BuyerPage } from '../styles';
import userImageStub from '../../../assets/icons/user-profile-image.svg';
import Modal from '../../../Components/Modal';
import Alert from '../../../Components/Alert';
import { useAsync } from '../../../utils';

function BuyerUserAccount() {
    const { user, updateUserData, signOut } = useAuthContext();
    const userImage = user?.avatar_url || userImageStub;

    const userImageModalRef = useRef();
    const userFormModalRef = useRef();
    const deleteAccountModalRef = useRef();
    const deleteAccountConfirmationRef = useRef();
    const alertRef = useRef();
    const errorAlertRef = useRef();
    const [file, setFile] = useState();

    const { run: runSubmitAvatar, isLoading: isLoadingSubmitAvatar } = useAsync();
    const { run: runUpdateUser, isLoading: isLoadingUpdateUser, isError, error } = useAsync();
    const { run: runDeleteAccount, isLoading: isLoadingDeleteAccount } = useAsync();

    function addImage(e) {
        if (e.target.files[0].size <= 2000000) {
            setFile(URL.createObjectURL(e.target.files[0]));
        } else {
            alertRef.current.open();
        }
    }

    function handleSubmitImage(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('user_id', user.id);
        runSubmitAvatar(
            axios({
                method: 'post',
                url: '/api/usuario/novo-avatar',
                data: formData,
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        updateUserData({ avatar_url: res.data.new_avatar_url });
                        userImageModalRef.current.close();
                    }
                })
                .catch(err => console.log(err))
        );
    }
    function handleSubmitNewUserData(event) {
        event.preventDefault();

        const data = new FormData(event.target);
        const name = data.get('name');
        const email = data.get('email');

        runUpdateUser(
            axios({
                method: 'post',
                url: '/api/usuario/editar',
                data: {
                    user_id: user.id,
                    name,
                    email,
                },
            }).then(res => {
                if (res.status === 200) {
                    updateUserData({ name, email });
                    userFormModalRef.current.close();
                }
            })
        );
    }

    function handleDeleteAccount(event) {
        event.preventDefault();
        runDeleteAccount(
            axios
                .post('/api/user/delete-account')
                .then(() => {
                    deleteAccountModalRef.current.close();
                    deleteAccountConfirmationRef.current.open();
                    setTimeout(() => signOut(), 10000);
                })
                .catch(err => {
                    console.log(err.response?.message);
                    errorAlertRef.current.open();
                })
        );
    }

    return (
        <BuyerPage>
            <BuyerMenu />
            <FeedbackChannel />

            <BuyerPage.Container>
                <div className="container">
                    <HeaderPage
                        title="Minha conta"
                        subtitle="Gerencie os dados do seu cadastro aqui"
                    />

                    <BuyerPage.UserAccount>
                        <div style={{ display: 'flex' }}>
                            <BuyerPage.UserImage>
                                <img src={userImage} alt={user.name} />
                            </BuyerPage.UserImage>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <BuyerPage.UserInfo>
                                    <span> Nome </span>
                                    <p> {user.name || 'Nome não cadastrado'} </p>
                                </BuyerPage.UserInfo>
                                <BuyerPage.UserInfo>
                                    <span> E-mail </span>
                                    <p> {user.email} </p>
                                </BuyerPage.UserInfo>
                            </div>
                        </div>
                    </BuyerPage.UserAccount>

                    <BuyerPage.AccountButtons>
                        <div onClick={() => userImageModalRef.current.open()}>
                            Mudar imagem de perfil
                        </div>

                        <div onClick={() => userFormModalRef.current.open()}>
                            Alterar informações do perfil
                        </div>

                        <div onClick={() => deleteAccountModalRef.current.open()}>
                            Excluir conta
                        </div>
                    </BuyerPage.AccountButtons>
                </div>
            </BuyerPage.Container>

            <Modal title="Mudar imagem de perfil" ref={userImageModalRef}>
                <h3 className="modal__title"> Mudar imagem de perfil </h3>

                <BuyerPage.UserImage className="modal__image">
                    <img src={!file ? userImage : file} alt={user.name} />

                    <form onSubmit={handleSubmitImage}>
                        <label htmlFor="user_image" className="txt--black">
                            Pesquisar imagem
                        </label>
                        <input
                            type="file"
                            name="user_image"
                            id="user_image"
                            style={{ display: 'none' }}
                            onChange={addImage}
                        />
                        <button className="btn bg--purple" type="submit">
                            <span>{isLoadingSubmitAvatar ? 'Salvando...' : 'Salvar'} </span>
                        </button>
                    </form>
                </BuyerPage.UserImage>
            </Modal>

            <Modal ref={userFormModalRef}>
                <h4 className="modal__title"> Editar dados do usuário </h4>
                <form
                    name="user_form"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '500px',
                        margin: 'auto',
                    }}
                    onSubmit={handleSubmitNewUserData}
                >
                    <div>
                        <div className="input__field">
                            <label htmlFor="name"> Nome do usuário </label>
                            <input
                                type="text"
                                name="name"
                                className="input validate"
                                required
                                minLength="5"
                                defaultValue={user.name}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="input__field">
                            <label htmlFor="email"> E-mail </label>
                            <input
                                type="email"
                                name="email"
                                className="input validate"
                                required
                                minLength="5"
                                defaultValue={user.email}
                            />
                        </div>
                    </div>

                    {isError ? (
                        <p
                            style={{
                                color: 'var(--color-red)',
                                textAlign: 'center',
                                marginBottom: '1rem',
                            }}
                        >
                            {error.response?.data?.message || null}
                        </p>
                    ) : null}

                    <div>
                        <button
                            className="btn bg--purple"
                            type="submit"
                            disabled={isLoadingUpdateUser}
                        >
                            <span> {isLoadingUpdateUser ? '...' : 'Salvar'} </span>
                        </button>
                    </div>
                </form>
            </Modal>

            <Modal ref={deleteAccountModalRef}>
                <h3 className="modal__title" style={{ marginTop: '2rem' }}>
                    Tem certeza de que deseja excluir sua conta?
                </h3>

                <form onSubmit={handleDeleteAccount}>
                    <div className="row">
                        <div className="col m12 s12 txt--left">
                            <button
                                className="btn btn--secondary"
                                type="submit"
                                disabled={isLoadingDeleteAccount}
                            >
                                <span>
                                    {isLoadingDeleteAccount
                                        ? 'Excluindo conta...'
                                        : 'Sim, excluir conta'}{' '}
                                </span>
                            </button>
                        </div>
                        <div className="col m12 s12 txt--right">
                            <button className="btn btn--slim txt--gray" type="button">
                                <span> Cancelar </span>
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal ref={deleteAccountConfirmationRef}>
                <p style={{ textAlign: 'center', margin: '1rem auto 2rem', lineHeight: 1.7 }}>
                    Já recebemos a solicitação para excluir a sua conta. <br /> Você será
                    desconectado da plataforma, e em até 72 horas seus dados serão excluídos do
                    sistema.
                </p>
                <p></p>
                <button className="btn" type="button" onClick={() => signOut()}>
                    <span> Ok </span>
                </button>
            </Modal>

            <Alert ref={alertRef}>
                <p>A imagem selecionada não pode ser maior que 2Mb</p>
            </Alert>

            <Alert ref={errorAlertRef}>
                <p>Não foi possível completar a sua solicitação. Tente novamente mais tarde.</p>
            </Alert>
        </BuyerPage>
    );
}

export default BuyerUserAccount;
