import React from 'react';
import axios from 'axios';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import { useValidateForm } from '../../../Components/Utils/useValidateForm';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import SuccessAlert from '../../../Components/SuccessAlert';
import { Load } from '../../../Components/Loader/styles';
import { FormSubHeader } from '../../../Components/common/FormSubHeader';
import { Categories } from '../../../Components/Categories';
import { Button } from '../../../Components/common/Buttons/Button';

// Styles
import { SupplierPage } from '../styles';
import { UserPage } from './styles';
import { UserAccountAside } from './UserAccountAside';

export function UserCategories() {
  const { user, updateUserData } = useAuthContext();
  const isRegistering = user.user_categories.length === 0;
  const successAlertRef = React.useRef();
  const { run, isLoading: isSubmitting } = useAsync();

  const [supplierCategories, setSupplierCategories] = React.useState([]);

  const { start } = useValidateForm();

  const { run: runLoadData, data, isLoading } = useAsync({ status: 'pending' });
  React.useEffect(() => {
    window.scrollTo(0, 0);
    runLoadData(
      axios({
        method: 'get',
        url: `/api/supplier/categories`,
      }).then(response => response.data)
    );
  }, [runLoadData]);

  React.useEffect(() => {
    if (data) {
      setSupplierCategories(data.user_categories);
    }
  }, [data]);

  React.useEffect(() => {
    if (isRegistering && supplierCategories.length > 0) {
      updateUserData({ user_categories: supplierCategories });
    }
  }, [supplierCategories, updateUserData, user, isRegistering]);

  function handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    run(
      axios
        .post('/api/supplier/register-categories', {
          description: formData.get('description'),
        })
        .then(() => {
          successAlertRef.current.open();
          window.scrollTo(0, 0);
        })
    );
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <SupplierPage.Container>
        <div className="row">
          <UserAccountAside />

          <div className="col l9 m12 s12">
            {isLoading ? (
              <div style={{ height: '70vh' }}>
                <Load.CenterIcon>
                  <Load.SmallIcon />
                </Load.CenterIcon>
              </div>
            ) : (
              <SupplierPage.Box style={{ padding: '2rem' }}>
                <UserPage.Title>
                  <h3>Áreas de atuação</h3>
                  <p>
                    Selecione as categorias que você trabalha para receber pedidos de orçamento de
                    acordo com o seu negócio.
                  </p>
                </UserPage.Title>

                <form name="supplier_company" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col m12 s12">
                      <div className="input__field">
                        <label htmlFor="description"> Descrição * </label>
                        <textarea
                          name="description"
                          id="description"
                          placeholder="Descreva seu negócio"
                          className="input textarea"
                          defaultValue={data?.description}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <FormSubHeader
                    title="Escolha as suas áreas de atuação"
                    subtitle="As áreas de atuação vão definir quais pedidos de orçamento você receberá"
                  />

                  <Categories />

                  <Button
                    disabled={isSubmitting}
                    onClick={() => start(document.querySelector('form'))}
                  >
                    {isSubmitting ? 'Enviando...' : 'Salvar'}
                  </Button>
                </form>
              </SupplierPage.Box>
            )}
          </div>
        </div>

        <SuccessAlert ref={successAlertRef}>Dados atualizados com sucesso.</SuccessAlert>
      </SupplierPage.Container>
    </SupplierPage>
  );
}
