import React from 'react';
import axios from 'axios';
import SuccessAlert from '../../Components/SuccessAlert';
import ReviewCard from './reviewCard';
import { useAsync } from '../../utils/useAsync';

// Images & Styles
import { ReviewsPage } from './styles';
import reviewArtwork from '../../assets/images/email/reviews-artwork.png';
import ReviewedImage from '../../assets/images/review-sucess.svg';
import Celebrar from '../../assets/images/logos/celebrar/celebrar.svg';
import { Redirect, useParams } from 'react-router-dom';
import { Load } from '../../Components/Loader/styles';

export default function ExternalReview() {
    const { reqId } = useParams();
    const alertRef = React.useRef(null);
    const [supplierReviews, setSupplierReviews] = React.useState([]);
    const isAbleToSend = supplierReviews[0]?.rating;

    const { run, data, isLoading, isError } = useAsync({ status: 'pending' });
    const externalReviewRequest = data?.externalReviewRequest;
    const {
        run: runSubmitReview,
        isLoading: isSubmittingReview,
        isSuccess: isSubmitSuccess,
        isError: isSubmitError,
    } = useAsync();

    const isAnswered = isSubmitSuccess || externalReviewRequest?.answered_at;

    React.useEffect(() => {
        run(
            axios
                .get(`/api/supplier/external-review-requests/${reqId}`)
                .then(response => response.data)
        );
    }, [run, reqId]);

    function submitReview() {
        runSubmitReview(
            axios
                .post(`/api/supplier/external-review-requests/${reqId}`, {
                    rating: supplierReviews[0].rating,
                    comments: supplierReviews[0].comments,
                })
                .then(() => {
                    alertRef.current.open();
                })
        );
    }

    if (isLoading) {
        return (
            <Load.FullPageContainer>
                <Load.SmallIcon />
            </Load.FullPageContainer>
        );
    }

    if (isError) {
        return <Redirect to="/404" />;
    }

    return (
        <ReviewsPage>
            <ReviewsPage.Header>
                <div className="container txt--center">
                    <ReviewsPage.HeaderLogo src={Celebrar} id="logo" />

                    <img src={reviewArtwork} alt="Avaliação Celebrar" />
                    {externalReviewRequest && (
                        <h1 className="txt--white txt--center margin--bottom-1">
                            Como foi sua experiência com os serviços entregues?
                        </h1>
                    )}
                </div>
            </ReviewsPage.Header>

            <SuccessAlert ref={alertRef}>
                <p> Avaliação enviada com sucesso! </p>
            </SuccessAlert>

            <div className="container">
                <ReviewsPage.Reviews>
                    {!isAnswered ? (
                        <>
                            <ReviewCard
                                review={{
                                    id: externalReviewRequest.id,
                                    name: externalReviewRequest.service_name,
                                    description: externalReviewRequest.service_description,
                                }}
                                supplierReviews={supplierReviews}
                                setSupplierReviews={setSupplierReviews}
                            />

                            {isSubmitError ? (
                                <p
                                    style={{
                                        color: 'var(--color-red)',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                    }}
                                >
                                    Ocorreu um erro no envio dos dados. Tente novamente mais tarde.
                                </p>
                            ) : null}

                            <button
                                className="btn btn--center"
                                type="button"
                                disabled={!isAbleToSend || isSubmittingReview}
                                onClick={submitReview}
                            >
                                <span> {false ? 'Enviando...' : 'Enviar avaliação'} </span>
                            </button>
                        </>
                    ) : (
                        <ReviewsPage.Reviewed>
                            <img src={ReviewedImage} alt="" />
                            <h2 className="txt--primary">Avaliação enviada com sucesso!</h2>
                            <h3 className="txt--black">
                                Muito obrigado por avaliar sua experiência
                            </h3>
                        </ReviewsPage.Reviewed>
                    )}
                </ReviewsPage.Reviews>
            </div>
        </ReviewsPage>
    );
}
