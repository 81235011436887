import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Register } from './styles';
import NewLogo from '../../../assets/images/Logo_Celebrar1.png';
import { Button } from '../../../Components/common/Buttons/Button';
import { FormEditableInput } from '../../../Components/common/Forms/FormEditableInput';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Load } from '../../../Components/Loader/styles';

function RegisterBuyer() {
  const { run, isLoading } = useAsync();
  const { signIn } = useAuthContext();

  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  function handleSubmitRegister(e) {
    e.preventDefault();

    setErrors({});
    const errors = {};

    // Email validation regex from html specification https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    const emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!name.trim()) {
      errors.name = 'Você precisa informar seu nome';
    }
    if (!email.trim()) {
      errors.email = 'Você precisa informar um e-mail';
    } else if (!emailRegEx.test(email)) {
      errors.email = 'Endereço de e-mail inválido';
    }
    if (!password.trim()) {
      errors.password = 'Você precisa definir uma senha';
    }
    if (!passwordConfirmation.trim()) {
      errors.passwordConfirmation = 'Confirme sua nova senha';
    } else if (passwordConfirmation !== password) {
      errors.passwordConfirmation = 'As senhas não são iguais';
    }
    if (!termsChecked) {
      errors.terms = 'Você deve aceitar os termos de uso antes de continuar';
    }

    if (Object.values(errors).length > 0) {
      setErrors(errors);
      return;
    }

    run(
      axios
        .post('/api/auth/signup', {
          name,
          email,
          password,
          type: 'buyer',
        })
        .then(() => {
          signIn({ email, password });
        })
    ).catch(error => {
      if (error.response?.status === 500) {
        setErrors({
          register: 'Ocorreu um erro no envio do formulário. Tente novamente mais tarde.',
        });
      } else {
        setErrors({ register: error.response.data?.message });
      }
    });
  }

  return (
    <Register>
      <Register.Header>
        <div className="container">
          <div>
            <Link to="/">
              <figure>
                <img src={NewLogo} alt="Celebrar" />
              </figure>
            </Link>
          </div>
          <div className="header-btns">
            <Button variant="secondary">Acessar minha conta</Button>
          </div>
        </div>
      </Register.Header>

      <Register.Container>
        <div className="container">
          <Register.Layout>
            <Register.Heading>
              <h1>
                Sou um
                <br />
                <span>comprador</span>
              </h1>
              <p>Quero transformar meu evento em uma experiência única</p>
            </Register.Heading>
            <Register.Form onSubmit={handleSubmitRegister}>
              <label>
                Nome
                <FormEditableInput
                  type="name"
                  name="name"
                  className={errors.name ? 'form-error' : undefined}
                  placeholder="Digite seu nome"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <p className="field-error-message">{errors.name}</p>
              </label>
              <label>
                E-mail
                <FormEditableInput
                  type="email"
                  name="email"
                  className={errors.email ? 'form-error' : undefined}
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <p className="field-error-message">{errors.email}</p>
              </label>
              <label>
                Senha
                <FormEditableInput
                  type="password"
                  name="password"
                  className={errors.password ? 'form-error' : undefined}
                  placeholder="Digite sua senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <p className="field-error-message">{errors.password}</p>
              </label>
              <label>
                Confirmar senha
                <FormEditableInput
                  type="password"
                  name="confirm_password"
                  className={errors.passwordConfirmation ? 'form-error' : undefined}
                  placeholder="Confirme sua senha"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                />
                <p className="field-error-message">{errors.passwordConfirmation}</p>
              </label>
              <label
                className="checkbox__label"
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  padding: '0 4px',
                  paddingTop: '0.5rem',
                  gap: '0.75rem',
                }}
              >
                <input
                  className={`checkbox ${errors.terms ? 'form-error' : undefined}`}
                  type="checkbox"
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                />
                <span>
                  Li e aceito os{' '}
                  <a href="/termos/plataforma" target="_blank">
                    Termos de uso da plataforma
                  </a>
                  , a{' '}
                  <a href="/termos/politica-privacidade" target="_blank">
                    Política de privacidade
                  </a>{' '}
                  e os{' '}
                  <a href="/termos/comprador" target="_blank">
                    Termos e condições gerais de compra
                  </a>
                </span>
              </label>
              <p className="field-error-message">{errors.terms}</p>

              {errors.register ? (
                <p style={{ margin: '1rem 0', color: 'var(--color-red)', fontWeight: 700 }}>
                  {errors.register}
                </p>
              ) : null}

              <Button>
                {isLoading ? (
                  <Load.SmallIcon
                    style={{
                      margin: '0 auto',
                      borderColor: 'var(--color-primary-light)',
                      borderTopColor: 'var(--color-white)',
                    }}
                  />
                ) : (
                  'Criar novo cadastro'
                )}
              </Button>
            </Register.Form>
          </Register.Layout>
        </div>
      </Register.Container>
    </Register>
  );
}

export default RegisterBuyer;
