import styled from 'styled-components';
import Bg from '../../assets/images/bg-offer.png';

export const Container = styled.div `
    width: 100%;
    display: flex;
`;

export const ProposalHeader = styled.div `
    width: 100%;
    background-color: var(--color-black);
    padding: 0.5rem 0;
    margin-bottom: 2rem;

    .header__logo{
        display: flex;
        align-items: center;
    }

    .logo{
        width: 3rem;

        img{
            width: 100%;
        }
    }

    h3{
        color: var(--color-text-in-black);
        font-size: 1.5rem;
        font-weight: 200;
        border-left: solid 0.1rem var(--color-text);
        padding-left: 1rem;
        margin-left: 1rem;
    }

    .header__text{
        color: var(--color-text-in-black);
        text-align: center;
        margin: 1rem 0;
    }

    .pageheader__title--sub{
        margin-top: 4rem;
        color: var(--color-secondary);
    }

    @media (min-width: 800px){
        height: 12rem;
        background-image: url(${Bg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        .header__content{
            display: flex;
            align-items:center;
            justify-content: space-between;
        }

        .header__text{
            text-align: left;
        }
    }
`;

export const Card = styled.div `
    width: 100%;
    /* height: 15rem; */
    background-color: var(--color-white);
    box-shadow: var(--shadow);
    overflow: hidden;
    transition: all 0.3s ease-out;
    margin: 0 auto 2rem auto;
    border-radius: 0.1rem;

    .card__header{
        width: 100%;
        /* display: flex; */
        position: relative;
        background-color: var(--color-black);
        padding: 1rem;

        .card__name{
            width: 100%;
            font-weight: 600;
            color: var(--color-text-in-black);
        }

        .card__remove{
            color: var(--color-secondary);
            float: right;
            cursor: pointer;
            position: relative;
            z-index: 99;
        }
    }

    .card__content{
        display: grid;
        grid-template-columns: 2fr 1fr;


        .card__text{
            font-size: 1rem;
            font-weight: 200;
            padding: 0.5rem;
        }

        .card__calculate{
            border-left: solid 0.1rem var(--color-border);

            .card__number{
                padding: 0.5rem;
                /* border-bottom: solid 0.1rem var(--color-border); */
                display: flex;
                justify-content: space-between;

                h3{
                    font-size: 1rem;
                    font-weight: 200;
                }
            }

            .card__subtotal{
                padding: 1rem;
                display: flex;
                justify-content: space-between;
                background-color: var(--color-primary-complementary);
                color: var(--color-secondary);
                border-top: solid 0.1rem var(--color-border);
            }
        }
    }

    .card__label{
        font-size: 0.8rem;
        font-weight: 200;
        margin: 1rem 0 0.5rem 0;
    }

    .card__price{
        font-weight: 200;
        color: var(--color-primary);
    }

    .card__finalprice{
        font-size: 1rem;
        color: var(--color-primary);
        margin-bottom: 1rem;
    }

    @media (min-width: 800px){
        max-width: 100%;
        display: block;
        height: auto;

        :hover{
            .card__img{
                height: 5rem;
            }
        }

        .card__container{
            max-width: 100%;
            display: flex;
        }

        .card__img{
            width: 5rem;
            height: 5rem;

            svg{
                width: 180px;
                height: 180px;
            }
        }
    }
`;

export const ProposalFooter = styled.div `
    width: 100%;
    padding: 1rem 0;
    padding-bottom: 4rem;
    margin-top: 5rem;
    
    .accordion{
        border-radius: 0.1rem;
        margin: 2rem auto;
        background-color: var(--color-white);
        box-shadow: var(--shadow);
        cursor: pointer;

        &.open{
            .accordion__text{
                height: auto;
                padding: 1rem;
                opacity: 1;
                margin: 0.3rem auto;
            }
        }
    }

    .accordion__title{
        font-weight: 200;
        font-size: 1rem;
        display: flex;
        align-items: center;
        background-color: var(--color-black);
        color: var(--color-title-in-black);
        border-radius: 0.1rem;

        span{
            display: block;
            padding: 1rem 0;
            text-align: center;
            background-color: var(--color-secondary);
            width: 3rem;
            margin-right: 1rem;
        }
    }

    .accordion__text{
        height: 0rem;
        overflow: hidden;
        font-weight: 200;
        line-height: 2;
        transition: all .3s ease-out;
        opacity: 0;

        a{
            text-decoration: underline;
        }
    }
`;