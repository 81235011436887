import React from 'react';
import Header from '../../../Components/Header';
// Styles & Images
import Bg from '../../../assets/images/backgrounds/bg-proposal-sucess.png';
import Sucess from '../../../assets/images/proposals/sucess.png';
import { SuccessPage } from '../../ShoppingCartProposal/styles';

function ProposalSucess() {
  return (
    <SuccessPage bg={Bg}>
      <Header />

      <div className="container">
        <SuccessPage.Content>
          <figure>
            <img src={Sucess} alt="" />
          </figure>
          <div>
            <h1>
              Seu pedido foi realizado <br />
              com sucesso!
            </h1>
            <p>
              Agradecemos a sua confirmação! Em breve, seu especialista em eventos <br />
              Celebrar entrará em contato com você para finalizar o processo.
            </p>
          </div>
        </SuccessPage.Content>
      </div>
    </SuccessPage>
  );
}

export default ProposalSucess;
