import React, { useEffect, useState, useRef } from 'react';
import { resizeImage } from '../../utils';
import Alert from '../Alert';
import Modal from '../Modal';
// Styles & Images
import { Item } from './styles';
import { AngleLeft, AngleRight, Image } from '../common/Icons/IconsFamily';
import { Load } from '../Loader/styles';
import { ButtonIconOnly } from '../common/Buttons/ButtonIconOnly';
import { Button } from '../common/Buttons/Button';

export function OfferItemImages({ itemPhotos, imagesModalRef, label, isControlBarEnable }) {
  const [images, setImages] = useState(itemPhotos || [{ label: `${label}-imgs0` }]);
  const [isAddingImage, setIsAddingImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [imageSelected, setImageSelected] = useState(null);
  const addFileErrorAlertRef = useRef();
  const imageSizeErrorAlertRef = useRef();
  const zoomImageModalRef = useRef();

  useEffect(() => {
    setCurrentImage(0);
    setImageSelected(null);
  }, [images]);

  useEffect(() => {
    setIsAddingImage(true);
  }, [currentImage]);

  useEffect(() => {
    if (isAddingImage)
      setTimeout(() => {
        setIsAddingImage(false);
      }, 300);
  }, [isAddingImage]);

  /*==========================
      Add and remove images
  ===========================*/

  async function addImage(event) {
    const input = event.target;
    const fileObj = input.files[0];

    if (!fileObj) {
      return;
    }

    setIsAddingImage(true);
    resizeImage(fileObj)
      .then(resizedFile => {
        if (resizedFile.size > 2000000) {
          imageSizeErrorAlertRef.current.open();
        } else {
          const newFileList = new DataTransfer();
          newFileList.items.add(resizedFile);
          input.files = newFileList.files;
          const newImages = [...images];
          newImages[images.length - 1].file = resizedFile;
          newImages[images.length - 1].blob = URL.createObjectURL(resizedFile);
          newImages.push({ label: `${label}-imgs${images.length}` });
          setImages(newImages);
        }
        setIsAddingImage(false);
      })
      .catch(err => {
        setIsAddingImage(false);
        console.log(err);
        addFileErrorAlertRef.current.open();
      });
  }

  function removeImage(index) {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages.map((img, index) => ({ ...img, label: `${label}-imgs${index}` })));
  }

  /*==========================
        Reorder images
  ===========================*/

  function reorderImages(index, position) {
    if (position < images.length - 1 && position >= 0) {
      let item = images.splice(index, 1),
        newImages = [...images];
      newImages.splice(position, 0, item[0]);
      setImages(newImages);
    }
  }

  function showModalPhotos(){
    document.querySelector("aside").classList.add("closing");
    zoomImageModalRef.current.open();
  }
  
  function hideModalPhotos(){
    document.querySelector("aside").classList.remove("closing");
  }

  return (
    <>
      <Item.Photos>
        {images[0]?.file || itemPhotos ? (
          <>
            {
              images.length > (itemPhotos ? 1 : 2) && currentImage > 0 && (
                <ButtonIconOnly
                  type="button"
                  onClick={() => {
                    setCurrentImage(currentImage - 1);
                  }}
                >
                  <AngleLeft />
                </ButtonIconOnly>
              )
            }

            {
              !isAddingImage ? (
                <img
                  src={itemPhotos ? images[currentImage]?.url : images[currentImage].blob}
                  alt=""
                  onClick={showModalPhotos}
                />
              ) : <Load.SmallIcon />
            }

            {
              images.length > (itemPhotos ? 1 : 2) && currentImage < images.length - (itemPhotos ? 1 : 2) && (
                <ButtonIconOnly
                  type="button"
                  onClick={() => {
                    setCurrentImage(currentImage + 1);
                  }}
                >
                  <AngleRight />
                </ButtonIconOnly>
              )
            }
          </>
        ) : (
          <>
            <Image />
            <p>Sem imagem</p>
          </>
        )}

        {isControlBarEnable && (
          <Item.PhotosBtn type="button" onClick={() => imagesModalRef.current.open()}>
            Adicionar fotos
          </Item.PhotosBtn>
        )}
      </Item.Photos>

      {images.map(({ label, file }) => {
        return (
          <input
            type="file"
            accept="image/*"
            key={label}
            name={file ? label : ''}
            id={label}
            style={{ display: 'none' }}
            onChange={addImage}
          />
        );
      })}

      <Modal ref={imagesModalRef} title="Gerenciar imagens do item" modalSize="large">
        {images.length > 1 ? (
          <Item.ModalPhotos
            id="modal"
            onClick={event => {
              if (event.target.id === 'modal') setImageSelected(null);
            }}
          >
            {images.length > 1
              ? images
                .filter(x => x.file)
                .map(({ blob }, index) => (
                  <Item.ModalPhotos.Image
                    key={index}
                    onClick={() => setImageSelected(index)}
                    className={imageSelected === index ? 'selected' : ''}
                  >
                    <img src={blob} alt="" />

                    <Item.ModalPhotos.PositionBtns>
                      <ButtonIconOnly
                        type="button"
                        onClick={() => reorderImages(index, index - 1)}
                      >
                        <AngleLeft color="var(--color-green)" />
                      </ButtonIconOnly>
                      <ButtonIconOnly
                        type="button"
                        onClick={() => reorderImages(index, index + 1)}
                      >
                        <AngleRight color="var(--color-green)" />
                      </ButtonIconOnly>
                    </Item.ModalPhotos.PositionBtns>

                    {imageSelected === index && (
                      <Item.ModalPhotos.ManagerBtns>
                        {index !== 0 && (
                          <button type="button" onClick={() => reorderImages(index, 0)}>
                            Transformar em capa
                          </button>
                        )}

                        <button type="button" onClick={() => removeImage(index)}>
                          Apagar foto
                        </button>
                      </Item.ModalPhotos.ManagerBtns>
                    )}
                    {/* <div id={index} >
                        Remover
                      </div> */}
                  </Item.ModalPhotos.Image>
                ))
              : null}
          </Item.ModalPhotos>
        ) : (
          <p className="margin--tb-2">Você ainda não adicionou nenhuma imagem para este item</p>
        )}

        <label
          className="btn btn--secondary btn--center"
          htmlFor={`${label}-imgs${images.length - 1}`}
        >
          <span>{isAddingImage ? <Load.SmallIcon /> : 'Adicionar Imagem'}</span>
        </label>

        <Button type="button" onClick={() => imagesModalRef.current.close()}>
          Concluído
        </Button>
      </Modal>

      <Modal ref={zoomImageModalRef} modalSize="inherit" onClose={hideModalPhotos}>
        <Item.Photos style={{height: 'fit-content', width: '100%', backgroundColor: 'transparent'}}>
          {
            images.length > (itemPhotos ? 1 : 2) && currentImage > 0 && (
              <ButtonIconOnly
                type="button"
                onClick={() => {
                  setCurrentImage(currentImage - 1);
                }}
              >
                <AngleLeft />
              </ButtonIconOnly>
            )
          }

          <img
            src={itemPhotos ? images[currentImage]?.url : images[currentImage].blob}
            alt=""
            style={{ marginTop: '2rem', maxHeight: '25rem' }}
          />

          {
            images.length > (itemPhotos ? 1 : 2) && currentImage < images.length - (itemPhotos ? 1 : 2) && (
              <ButtonIconOnly
                type="button"
                onClick={() => {
                  setCurrentImage(currentImage + 1);
                }}
              >
                <AngleRight />
              </ButtonIconOnly>
            )
          }
        </Item.Photos>
      </Modal>

      <Alert ref={imageSizeErrorAlertRef}>
        <p>O arquivo selecionado é muito grande. Não foi possível fazer o upload.</p>
      </Alert>
    </>
  );
}
