import React from 'react';
import { formatDate, formatDateAndTime } from '../../../utils/dateFunctions';
import { toBraCurrency } from '../../../utils/currencyFunctions';
import { SalesPanelPage } from './styles';

function ConfirmedContractCard({ data, handleShowModal }) {
    const { sale_code, delivery_estimated_at, items, total_supplier_sale_price } = data;
    const deliveryDate = formatDate(delivery_estimated_at);

    const supplierSalePrice = toBraCurrency(total_supplier_sale_price);

    return (
        <SalesPanelPage.Card>
            <SalesPanelPage.CardHeader>
                <h5 className="txt--black">{`#${sale_code}`}</h5>
                <p>Data do evento: {deliveryDate}</p>
            </SalesPanelPage.CardHeader>

            {items.length > 0 ? (
                <SalesPanelPage.ItemsList>
                    Itens vendidos:
                    {items?.map(({ name, id }) => (
                        <li key={id}>{name}</li>
                    ))}
                </SalesPanelPage.ItemsList>
            ) : null}

            <SalesPanelPage.CardMessage>
                <p>
                    Valor do repasse:{' '}
                    <span className="txt--secondary txt--bold">{supplierSalePrice}</span>
                </p>
            </SalesPanelPage.CardMessage>

            <button
                className="txt--primary"
                onClick={() => handleShowModal(<ContractDetails contract={data} />)}
            >
                <span>Ver detalhes da entrega</span>
            </button>
        </SalesPanelPage.Card>
    );
}

function ContractDetails({ contract }) {
    const {
        sale_code,
        delivery_estimated_at,
        total_supplier_sale_price,
        total_sale_price,
        items,
        infos,
    } = contract;
    return (
        <React.Fragment>
            <h2 className="txt--secondary margin--bottom-1">Evento #{sale_code}</h2>
            <p>Data do evento: {formatDate(delivery_estimated_at)}</p>

            <div className="alerts__fieldset">
                <span className="fieldset__title">Detalhes da venda</span>
                <span className="fieldset__description" />

                <div className="alerts__prices">
                    <div className="alerts__price txt--black">
                        <p> Valor de venda </p>
                        <h6> {toBraCurrency(total_sale_price)} </h6>
                    </div>

                    <div className="alerts__price" style={{ color: 'var(--color-primary)' }}>
                        <p> Valor do repasse </p>
                        <h6>{toBraCurrency(total_supplier_sale_price)}</h6>
                    </div>
                </div>
            </div>
            <div className="alerts__fieldset">
                <span className="fieldset__title">Serviços vendidos</span>
                <span className="fieldset__description" />
                {items.map(item => (
                    <SalesPanelPage.ItemDescription>
                        <h4>
                            {' '}
                            {item.name} (quantidade: {item.quantity})
                            <span>
                                {' '}
                                - Data e hora de entrega:{' '}
                                {formatDateAndTime(item.delivery_estimated_at)}
                            </span>
                        </h4>
                        <div>
                            <p>{item.description}</p>

                            <p>Observações: {item.obs}</p>

                            <ul class="item__prices">
                                <li>
                                    Valor da venda por unidade:{' '}
                                    <span>{toBraCurrency(item.sale_price)}</span>
                                </li>
                                <li>
                                    Valor total do serviço:{' '}
                                    <span>{toBraCurrency(item.final_sale_price)}</span>
                                </li>
                                <li>
                                    Valor do repasse:{' '}
                                    <span>{toBraCurrency(item.final_supplier_sale_price)}</span>
                                </li>
                            </ul>
                        </div>
                    </SalesPanelPage.ItemDescription>
                ))}
            </div>

            <div className="alerts__fieldset">
                <span className="fieldset__title">Mais detalhes</span>
                <span className="fieldset__description" />
                <SalesPanelPage.DetailsSection>
                    {infos.map(({ description }) => (
                        <p>{description}</p>
                    ))}
                </SalesPanelPage.DetailsSection>
            </div>
        </React.Fragment>
    );
}

export default ConfirmedContractCard;
