import React, { useEffect } from 'react';
import axios from 'axios';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import { useValidateForm } from '../../../Components/Utils/useValidateForm';
import { useAsync } from '../../../utils';
import { useViaCep } from '../CompleteRegistration/hooks/useViaCep';
// Styles
import { SupplierPage } from '../styles';
import { UserPage } from './styles';
import { UserAccountAside } from './UserAccountAside';
import { Load } from '../../../Components/Loader/styles';
import SuccessAlert from '../../../Components/SuccessAlert';
import { useAuthContext } from '../../../Contexts/AuthContext';

export function UserAddress() {
  const { run, isLoading, isError: isSubmitError } = useAsync();
  const { handleChangeCEP, maskedCEP, isError, validCepData, setValueCEP, valueCEP } = useViaCep();
  const { updateUserData } = useAuthContext();
  const { start } = useValidateForm();
  const successAlertRef = React.useRef();

  // Load registered address data
  const {
    run: runLoadData,
    data: savedData,
    isLoading: isLoadingAddress,
  } = useAsync({ status: 'pending' });
  useEffect(() => {
    runLoadData(axios.get('/api/supplier/register-address').then(response => response.data));
  }, [runLoadData]);
  useEffect(() => {
    if (savedData) {
      setValueCEP(savedData.zip_code);
    }
  }, [savedData, setValueCEP]);

  const submitAddressData = event => {
    event.preventDefault();

    const formData = new FormData(event.target);

    run(
      axios
        .post('/api/supplier/register-address', {
          zip_code: valueCEP,
          address: validCepData.logradouro,
          state: validCepData.uf,
          city: validCepData.localidade,
          neighborhood: validCepData.bairro,
          address_complement: formData.get('complement'),
          address_number: formData.get('number'),
        })
        .then(() => {
          updateUserData({ company_state: validCepData.uf })  
          successAlertRef.current.open()
        })
    );
  };

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <SupplierPage.Container>
        <div className="row">
          <UserAccountAside />

          <div className="col m9 s12">
            {isLoadingAddress ? (
              <div style={{ height: '70vh' }}>
                <Load.CenterIcon>
                  <Load.SmallIcon />
                </Load.CenterIcon>
              </div>
            ) : (
              <SupplierPage.Box style={{ padding: '2rem' }}>
                <UserPage.Title>
                  <h3>Endereço da sua empresa</h3>
                  <p>Mantenha seus dados e da sua empesa atualizados.</p>
                </UserPage.Title>

                <form id="address" name="supplier_company" onSubmit={submitAddressData}>
                  <div className="row">
                    <div className="col m4 s12">
                      <div className="input__field">
                        <label htmlFor="cep"> CEP * </label>
                        <input
                          type="text"
                          name="cep"
                          id="cep"
                          placeholder="CEP da sua empresa"
                          className={`input ${isError && 'input--error'} `}
                          value={maskedCEP}
                          maxLength="9"
                          onChange={event => handleChangeCEP(event)}
                          required
                        />
                        {isError && (
                          <p className="txt--red">CEP inválido, por favor digite novamente</p>
                        )}
                      </div>
                    </div>

                    <div className="col m10 s12">
                      <div className="input__field">
                        <label htmlFor="address">Rua</label>
                        <input
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Rua"
                          className="input"
                          defaultValue={validCepData?.logradouro}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col m2 s12">
                      <div className="input__field">
                        <label htmlFor="number">Número*</label>
                        <input
                          type="text"
                          name="number"
                          id="number"
                          defaultValue={savedData?.number || ''}
                          placeholder="Número"
                          className="input"
                          required
                        />
                      </div>
                    </div>

                    <div className="col m4 s12">
                      <div className="input__field">
                        <label htmlFor="state">Estado</label>
                        <input
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Estado"
                          className="input"
                          defaultValue={validCepData?.uf}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col m4 s12">
                      <div className="input__field">
                        <label htmlFor="city">Cidade</label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Cidade"
                          className="input"
                          defaultValue={validCepData?.localidade}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col m4 s12">
                      <div className="input__field">
                        <label htmlFor="district">Bairro</label>
                        <input
                          type="text"
                          name="district"
                          id="district"
                          placeholder="Bairro"
                          className="input"
                          defaultValue={validCepData?.bairro}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col m12 s12">
                      <div className="input__field">
                        <label htmlFor="complement">Complemento</label>
                        <input
                          type="text"
                          name="complement"
                          id="complement"
                          defaultValue={savedData?.complement || ''}
                          placeholder="Complemento do seu endereço"
                          className="input"
                        />
                      </div>
                    </div>
                  </div>

                  {isSubmitError ? (
                    <p
                      style={{
                        color: 'var(--color-red)',
                        fontWeight: 700,
                        marginTop: '2rem',
                      }}
                    >
                      Ocorreu um erro no envio dos dados. Tente novamente mais tarde.
                    </p>
                  ) : null}

                  <button
                    className="btn"
                    type="submit"
                    disabled={isLoading}
                    onClick={() => start(document.querySelector('#address'))}
                  >
                    <span> {isLoading ? 'Enviando...' : 'Continuar'} </span>
                  </button>
                </form>
              </SupplierPage.Box>
            )}
          </div>
        </div>

        <SuccessAlert ref={successAlertRef}>Dados atualizados com sucesso!</SuccessAlert>
      </SupplierPage.Container>
    </SupplierPage>
  );
}
