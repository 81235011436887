import styled from 'styled-components';

export const SupplierPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  min-height: 100vh;

  /* Colors */
  background-color: var(--color-gray-light);

  /* Others */
  overflow: hidden;
`;

SupplierPage.Box = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 2rem;
  margin: 1rem auto;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;

  /* Others */
  box-shadow: var(--shadow);
  border-radius: var(--radius);
`;

SupplierPage.BoxHeader = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);

  h3 {
    /* Colors */
    color: var(--color-black);

    /* Text */
    font-size: 1rem;
    font-weight: 700;
  }

  p {
    margin: 0.2rem 0;

    /* Colors */
    color: var(--color-text);

    /* Text */
    font-size: 0.8rem;
  }
`;

SupplierPage.Container = styled.div`
  /* Display & Box Model */
  width: 90%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 2rem 0 4rem 0;

  /* Color */
  background-color: var(--color-gray-light);

  /* Position */
  position: relative;

  /* Others */
  transition: all 0.3s ease-out;
`;

/*================================
            Sidebar menu
=================================*/

export const SupplierSidebar = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 3.5rem;

  /* Position */
  position: relative;

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: block;
  }
`;

SupplierSidebar.MenuMobile = styled.div`
  /* Display & Box Model */
  width: 2rem;

  /* Others */
  cursor: pointer;

  img {
    /* Display & Box Model */
    width: 100%;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: none;
  }
`;

SupplierSidebar.ContentMobile = styled.div`
  /* Display & Box Model */
  width: 100%;

  .container {
    /* Display & Box Model */
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mobile__logo {
      /* Display & Box Model */
      width: 3rem;
    }
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: none;

    .mobile__logo {
      /* Display & Box Model */
      display: none;
    }
  }
`;

SupplierSidebar.Content = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* Colors */
  background-image: var(--color-purple-gradient);
  color: var(--color-text-in-black);

  /* Others */
  border-right: solid 0.2rem var(--color-secondary);
  overflow: hidden;

  &.open {
    /* Display & Box Model */
    height: 100vh;

    /* Position */
    position: fixed;
    z-index: 99;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 22%;
    height: 100vh;
    justify-content: space-around;

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
  }
`;

SupplierSidebar.Graphism = styled.img`
  /* Display & Box Model */
  width: 100%;

  /* Position */
  position: absolute;
  top: -2rem;
  z-index: -1;
`;

SupplierSidebar.Profile = styled.div`
  /* Display & Box Model */
  width: 80%;
  padding: 1rem 0;
  display: flex;
  grid-gap: 0.5rem;
  margin: 0 auto;

  /* Others */
  border-bottom: solid 0.1rem var(--color-white);
`;

SupplierSidebar.ProfileImage = styled.figure`
  /* Display & Box Model */
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 2px;

  img {
    width: 100%;
    transform: scale(1.05);
  }
`;

SupplierSidebar.ProfileName = styled.div`
  p {
    /* Text */
    font-size: 0.8rem;
  }
`;

SupplierSidebar.Nav = styled.nav`
  /* Display & Box Model */
  width: 100%;
  padding-top: 2rem;

  ul {
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    display: block;
    padding-top: 0;
  }
`;

SupplierSidebar.Link = styled.li`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem 0 1rem 3rem;

  /* Text */
  text-align: left;

  &.active {
    /* Display & Box Model */
    width: 95%;
    padding: 1rem 0 1rem 2rem;
    margin-left: auto;
    margin-right: -0.2rem;

    /* Colors */
    background-color: rgba(255, 255, 255, 0.4);

    /* Others */
    transform: skewX(-8deg);
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;

    a {
      /* Display & Box Model */
      display: block;

      /* Others */
      transform: skewX(8deg);
    }
  }

  a {
    /* Colors */
    color: var(--color-text-in-black);
  }
`;

SupplierSidebar.Celebrar = styled.figure`
  /* Display & Box Model */
  width: 100%;

  /* Text */
  text-align: center;
  font-size: 0.8rem;

  img {
    /* Display & Box Model */
    width: 4rem;
  }

  a {
    /* Color */
    color: var(--color-text-in-black);
  }
`;

/*================================
    Supplier orders & invoices
=================================*/

SupplierPage.Order = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  margin: 2rem auto;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  box-shadow: var(--shadow);
  border-radius: var(--radius);

  p {
    line-height: 1.5;
  }
`;

SupplierPage.OrderTitle = styled.h3`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;

  /* Color */
  color: var(--color-black);

  /* Text */
  font-size: 1rem;
  font-weight: 700;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);
`;

SupplierPage.OrderInfos = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  grid-gap: 1rem;
  margin: 2rem auto;

  /* Text */
  text-align: center;

  @media (min-width: 800px) {
    /* Display & Box Model */
    flex-direction: row;
    grid-gap: 0;
  }
`;

SupplierPage.OrderInfo = styled.div`
  /* Display & Box Model */
  display: block;

  h4 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-size: 1.6rem;
    font-weight: 700;
  }

  p {
    /* Color */
    color: var(--color-black);
  }
`;

SupplierPage.OrderWarning = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  padding: 0 2rem;

  @media (min-width: 800px) {
    /* Display & Box Model */
    flex-direction: row;
  }
`;

SupplierPage.Invoice = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  box-shadow: var(--shadow);

  p.error-message {
    padding-top: 10px;
  }
`;

SupplierPage.InvoiceAlert = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  margin: 1rem auto;

  h3 {
    /* Text */
    font-size: 1rem;
  }

  figure {
    /* Display & Box Model */
    margin: 1rem auto;
    /* Text */
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    flex-direction: row;
    grid-gap: 2rem;

    h3 {
      /* Text */
      font-size: 1.2rem;
    }
  }
`;

SupplierPage.InvoiceInstructions = styled.ul`
  /* Display & Box Model */
  width: 100%;
  padding: 0 2rem;
  margin: 1rem auto 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;

  li {
    /* Display & Box Model */
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.3rem;

    /* Text */
    text-align: center;

    /* Others */
    border: solid 0.1rem var(--color-border);
    border-radius: var(--radius);

    h5 {
      /* Display & Box Model */
      height: 3rem;
      /* Colors */
      color: var(--color-secondary);

      /* Text */
      font-size: 1.6rem;
      font-weight: 700;
    }

    p {
      /* Display & Box Model */
      height: 3rem;

      /* Color */
      color: var(--color-black);

      /* Text */
      font-size: 0.8rem;
    }
  }

  @media (min-width: 960px) {
    /* Display & Box Model */
    grid-template-columns: repeat(4, 1fr);
  }
`;

SupplierPage.InvoiceWarning = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;

  /* Text */
  text-align: center;

  img {
    /* Display & Box Model */
    width: 4rem;
  }
`;

SupplierPage.InvoiceWarningMessage = styled.ul`
  /* Display & Box Model */
  display: block;
  padding: 1rem 0 0.5rem;

  /* Colors */
  color: var(--color-black);

  /* Text */
  text-align: center;
  line-height: 1.5;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);

  li {
    margin-bottom: 1.2rem;
  }
`;

SupplierPage.CancelledContractHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
`;

SupplierPage.InvoiceFiles = styled.ul`
  /* Display & Box Model */
  width: 100%;
  max-width: 800px;
  padding: 2rem;

  h3 {
    /* Display & Box Model */
    margin-bottom: 0.5rem;

    /* Text */
    font-weight: 700;
  }

  p {
    font-size: 0.8rem;
  }

  li {
    /* Display & Box Model */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;
    padding: 0 1rem;

    /* Others */
    border: 1px solid var(--color-border);

    div {
      font-weight: 600;
      width: 100%;
      overflow-wrap: anywhere;
    }
  }
`;

SupplierPage.NotFound = styled.div`
  /* Display & Box Model */
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  h1 {
    /* Display & Box Model */
    max-width: 500px;

    /* Text */
    font-size: 1.2rem;
    text-align: center;
  }
`;

/*================================
            User account
=================================*/

SupplierPage.User = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(3, 1fr);
  }
`;

SupplierPage.UserInfo = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 2rem;

  &.image {
    display: flex;
    justify-content: flex-start;
  }

  span {
    /* Display & Box Model */
    display: block;

    /* Text */
    font-size: 0.8rem;
  }

  p {
    /* Display & Box Model */
    display: block;
    margin-top: 1rem;

    /* Colors */
    color: var(--color-black);
  }
`;

SupplierPage.UserImage = styled.figure`
  /* Display & Box Model */
  margin-right: 1rem;

  img {
    /* Display & Box Model */
    width: 4rem;
  }

  label {
    /* Display & Box Model */
    display: block;
    padding: 0.5rem 1rem;

    /* Text */
    text-align: center;

    /* Others */
    cursor: pointer;
    border: solid 0.1rem var(--color-gray);
  }

  &.modal__image {
    /* Display & Box Model */
    margin: auto;

    /* Text */
    text-align: center;

    img {
      /* Display & Box Model */
      width: 8rem;
    }

    .user__image {
      /* Display & Box Model */
      display: 5rem;
    }
  }
`;

SupplierPage.UserButtons = styled.div`
  /* Display & Box Model */
  width: 100%;

  /* Colors */
  color: var(--color-secondary);

  /* Text */
  text-align: center;

  /* Others */
  cursor: pointer;

  div {
    /* Display & Box Model */
    width: max-content;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;

    /* Others */
    border-bottom: solid 0.1rem var(--color-secondary);
  }
`;

/*================================
            Bank accounts
=================================*/

export const BankPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

BankPage.CardsGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 2rem auto;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(2, 1fr);
  }
`;

BankPage.Card = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 12rem;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-secondary);
  color: var(--color-text-in-black);

  /* Position */
  position: relative;

  /* Others */
  border-radius: var(--radius);

  p {
    /* Display & Box Model */
    margin: 0.5rem auto;
  }
`;

BankPage.CardName = styled.h3`
  /* Display & Box Model */
  width: 100%;
  margin-bottom: 1rem;

  /* Colors */
  color: var(--color-text-in-black);

  /* Text */
  font-size: 1.2rem;
  font-weight: 300;
`;

BankPage.CardGraphism = styled.div`
  /* Display & Box Model */
  width: 8rem;
  height: 8rem;

  /* Colors */
  background-color: var(--color-gray-light);

  /* Position */
  position: absolute;
  right: -3rem;
  bottom: -3rem;

  /* Others */
  filter: opacity(0.2);
  border-radius: 50%;

  &:nth-child(1) {
    /* Display & Box Model */
    width: 12rem;
    height: 12rem;

    /* Position */
    right: -4rem;
    bottom: -4rem;
  }
`;

BankPage.AccountTypeName = styled.h4`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;

  /* Text */
  font-size: 1.2rem;
  text-align: center;
`;

BankPage.GridAccountBtns = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  margin: 1rem auto;
`;

BankPage.AccountTypeBtn = styled.button`
  /* Display & Box Model */
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: transparent;

  /* Text */
  text-align: center;
  font-size: 1rem;

  /* Others */
  border: solid 0.1rem;
  border-radius: var(--radius);
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    /* Colors */
    background-color: var(--color-gray);
  }
`;

BankPage.DeletAccount = styled.div`
  /* Display & Box Model */
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: absolute;
  top: 1rem;
  right: -0.5rem;

  /* Others */
  box-shadow: var(--shadow);
  border-radius: 0.3rem;
  cursor: pointer;

  img {
    /* Display & Box Model */
    width: 100%;
  }
`;

/*================================
            Categories
=================================*/

SupplierPage.UserCategories = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;
`;

SupplierPage.UserCategoriesGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(2, 1fr);
  }
`;

SupplierPage.UserCategory = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;

  /* Colors */
  background-color: var(--color-secondary);

  /* Others */
  border-radius: var(--radius);

  p {
    /* Display & Box Model */
    width: 100%;
    display: block;
    padding: 0.5rem 1rem;

    /* Colors */
    background-color: var(--color-secondary);
    color: var(--color-white);

    /* Text */
    text-align: center;

    /* Others */
    border-radius: 0.2rem;
  }

  button {
    /* Display & Box Model */
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;

    /* Colros */
    background-color: transparent;
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;

    /* Others */
    border: none;
    cursor: pointer;
  }
`;

SupplierPage.Categories = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  margin: 2rem auto;

  @media (min-width: 600px) {
    /* Display & Box Model */
    grid-template-columns: repeat(2, 48%);
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(4, 1fr);
  }
`;

SupplierPage.Category = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;

  /* Others */
  border: solid 1px var(--color-border);
  border-radius: var(--radius);
  overflow: hidden;
  transition: all 0.3s ease-out;

  :hover {
    border-color: var(--color-secondary);
    button {
      /* Colors */
      background-color: var(--color-secondary);
      color: var(--color-white);
    }
  }

  &.added {
    /* Colors */
    background-color: var(--color-primary);

    h4 {
      color: var(--color-white);
    }

    button {
      /* Colors */
      background-color: var(--color-primary);
      color: var(--color-white);

      /* Others */
      cursor: pointer;
    }

    :hover {
      border-color: var(--color-border);
    }
  }

  button {
    /* Display & Box Model */
    width: 100%;
    display: block;
    padding: 1rem 0;

    /* Colors */
    background-color: var(--color-gray-light);

    /* Text */
    font-size: 1rem;

    /* Others */
    border: none;
    border-top: solid 1px var(--color-border);
    cursor: pointer;
    transition: all 0.3s ease-out;
  }
`;

SupplierPage.CategoryTitle = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  margin: 0 auto 1rem auto;

  /* Text */
  text-align: center;
  line-height: 1.5;

  figure {
    /* Display & Box Model */
    width: 6rem;
    margin: auto;

    img {
      /* Display & Box Model */
      width: 100%;
    }
  }

  h4 {
    /* Colors */
    color: var(--color-black);

    /* Text */
    font-size: 0.9rem;
  }
`;

/*================================
        Supplier alerts models
=================================*/

SupplierPage.AlertModelsContainer = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-content: space-between;
  }
`;

SupplierPage.AlertModelCard = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  margin: 1rem auto;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border: solid 0.1rem var(--color-border);
  border-radius: var(--radius);
  overflow: hidden;
`;

SupplierPage.AlertModelCardImage = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: #d3d3d3;

  /* Text */
  text-align: center;

  /* Others */
  overflow: hidden;

  img {
    /* Display & Box Model */
    width: 100%;
  }
`;

SupplierPage.AlertModelCardTexts = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 0 1rem;
`;

SupplierPage.AlertModelButtons = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;

  a,
  span {
    /* Display & Box Model */
    padding: 1rem 0;

    /* Text */
    font-weight: 700;

    /* Others */
    border-top: solid 0.1rem var(--color-border);
    cursor: pointer;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    margin: 0;
  }
`;

/*================================
        Supplier alerts proposals
=================================*/

SupplierPage.AlertProposalsContainer = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    justify-content: space-between;
  }
`;

SupplierPage.AlertProposalCard = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  margin: 1rem auto;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border: solid 0.1rem var(--color-border);
  border-radius: var(--radius);
  overflow: hidden;
`;

SupplierPage.AlertProposalCardImage = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: #d3d3d3;

  /* Text */
  text-align: center;

  /* Others */
  overflow: hidden;

  img {
    /* Display & Box Model */
    width: 100%;
  }
`;

SupplierPage.AlertProposalCardTexts = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 0 1rem;
`;

SupplierPage.AlertProposalButtons = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;

  a,
  span {
    /* Display & Box Model */
    padding: 1rem 0;

    /* Text */
    font-weight: 700;

    /* Others */
    border-top: solid 0.1rem var(--color-border);
    cursor: pointer;
  }

  @media (min-width: 800px) {
    /* Display & Box Model */
    margin: 0;
  }
`;

SupplierPage.ImagesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

SupplierPage.Image = styled.div`
  height: 160px;
  margin: 5px 10px;
  img {
    object-fit: cover;
    max-height: 100%;
  }
`;

SupplierPage.ItemList = styled.ul`
  margin-top: 12px;

  li {
    list-style-type: square;
    margin-left: 2rem;
    line-height: 1.6;
  }

  a:visited {
    color: blue;
  }

  button {
    color: var(--color-red);
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 8px;
  }
`;

/*================================
            Alerts
=================================*/

export const AlertsPage = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(3, 1fr);
  }
`;

AlertsPage.CategoriesGrid = styled.ul`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin: 2rem auto;

  @media (min-width: 800px) {
    /* Display & Box Model */
    grid-template-columns: repeat(3, 1fr);
  }
`;

AlertsPage.Category = styled.li`
  /* Display & Box Model */
  /* width: 100%; */

  /* Colors */
  color: var(--color-black);

  /* Positon */
  position: relative;

  /* Text */
  font-size: 0.8rem;
  text-align: center;

  ::before {
    /* Display & Box Model */
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;

    /* Colors */
    background-color: var(--color-secondary);

    /* Position */
    position: relative;
    left: -0.5rem;
    top: 0;

    /* Others */
    border-radius: 50%;
  }
`;

// ----------------- Alerts cards

AlertsPage.Card = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;
  box-shadow: var(--shadow);
  border-radius: 0.1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Text */
  text-align: center;

  /* Position */
  position: relative;

  /* Others */
  border-left: solid 0.3rem var(--color-primary);
`;

AlertsPage.CardHeader = styled.div`
  /* Display & Box Model */
  padding-bottom: 1rem;

  /* Others */
  border-bottom: solid 0.1rem var(--color-border);
`;

AlertsPage.CardTitle = styled.p`
  /* Display & Box Model */
  margin: 0.5rem auto;

  /* Color */
  color: var(--color-important-text);

  /* Text */
  font-size: 1rem;
  font-weight: 700;
`;

AlertsPage.CardDate = styled.p`
  /* Display & Box Model */
  margin: 0.5rem auto;

  /* Color */

  color: var(--color-primary);

  /* Text */
  font-size: 0.8rem;

  &.alerts__date--expires {
    /* Color */
    color: var(--color-red);
  }
`;

AlertsPage.CardTag = styled.div`
  /* Display & Box Model */
  padding: 0.5rem;
  border-radius: 0.1rem;

  /* Position */
  position: absolute;
  top: -1rem;
  right: -0.5rem;

  /* Text */
  font-size: 0.8rem;
`;

AlertsPage.CardLink = styled.div`
  /* Display & Box Model */
  margin: 1rem auto;

  a {
    color: var(--color-primary);
  }
`;

// ----------------- Alert content

AlertsPage.Details = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 0 1rem;

  .description {
    /* Text */
    font-size: 1rem;
  }

  @media (min-width: 800px) {
    .float {
      /* Display & Box Model */
      margin-top: -0.5rem;

      /* Others */
      float: right;
    }
  }
`;

AlertsPage.DetailsName = styled.h3`
  /* Display & Box Model */
  width: 100%;
  margin: 1rem auto;

  /* Colors */
  color: var(--color-secondary);

  /* Text */
  font-size: 1.6rem;
  font-weight: 700;
`;

// ---------------------- Alert Dates

AlertsPage.DetailsDates = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 0 auto 2rem;

  /* Others */
  float: left;

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 40%;
    margin-right: 2rem;
  }
`;

AlertsPage.DetailsDatesGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

AlertsPage.DetailsDate = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

AlertsPage.DetailsDateBox = styled.div`
  /* Display & Box Model */
  width: 6rem;
  height: 7.5rem;

  /* Colors */
  background-color: var(--color-white);
  color: var(--color-black);

  /* Others */
  border-radius: 0.3rem;
  overflow: hidden;

  span {
    /* Display & Box Model */
    width: 101%;
    display: block;
    padding: 0.5rem;

    /* Colors */
    color: var(--color-white);

    /* Text */
    font-size: 0.8rem;
    text-align: center;
  }

  h5 {
    /* Display & Box Model */
    margin: 0.5rem auto;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    /* Text */
    font-size: 0.8rem;
    text-align: center;

    &.date--string {
      /* Display & Box Model */
      display: flex;
      height: 7rem;
      justify-content: center;
      align-items: center;

      /* Text */
      font-weight: 700;
    }
  }

  &.purple {
    /* Others */
    border: solid 1px var(--color-primary);

    span {
      /* Colors */
      background-color: var(--color-primary);
    }
  }

  &.red {
    /* Others */
    border: solid 1px var(--color-red);

    span {
      /* Colors */
      background-color: var(--color-red);
    }
  }
`;

AlertsPage.DetailsDateText = styled.span`
  /* Display & Box Model */
  margin: 0.5rem auto;

  /* Text */
  font-size: 0.8rem;
  text-align: center;
`;

// ---------------------- Alert Infos

AlertsPage.DetailsInfos = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;

  /* Others */
  float: left;

  @media (min-width: 800px) {
    /* Display & Box Model */
    width: 45%;
  }
`;

AlertsPage.DetailsImages = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;

  /* Others */
  clear: both;

  figure {
    width: 100%;

    img {
      width: 100%;
    }
  }
`;

AlertsPage.DetailsImagesGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

AlertsPage.Content = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;

  /* Color */
  background-color: var(--color-white);

  /* Positon */
  position: relative;

  /* Others */
  overflow-y: scroll;
`;

AlertsPage.ContentInfos = styled.div`
  /* Display & Box Model */
  width: 100%;
  padding: 1rem;

  /* Color */
  background-color: var(--color-black);

  /* Others */
  background-image: url(../images/backgrounds/bg-alert-infos.png);

  .infos__fieldset {
    /* Display & Box Model */
    margin: 2rem auto;

    /* Color */
    color: var(--color-text-in-black);
  }

  .alerts__infos .infos__title {
    /* Color */
    color: var(--color-text-in-black);

    /* Text */
    font-size: 1.6rem;
    font-weight: 300;

    span {
      /* Display & Box Model */
      margin: 0.5rem auto;
      display: block;

      /* Color */
      color: var(--color-text);
    }
  }

  @media (min-width: 800px) {
    /* Others */
    overflow-y: scroll;
  }
`;

// ---------------------- Alert budget form

AlertsPage.BtnForm = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

AlertsPage.BudgetDetails = styled.div`
  /* Display & Box Model */
  width: 100%;

  .budgets__grid {
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    grid-gap: 1rem;
    padding: 1rem 0;

    /* Color */
    color: var(--color-black);
  }

  h2 {
    /* Text */
    font-size: 2rem;
    font-weight: 300;
  }

  .budgets__price {
    h6 {
      /* Text */
      font-size: 1.6rem;
      font-weight: 300;
    }
  }

  .budgets__price:nth-child(3) {
    /* Color */
    color: var(--color-secondary);
  }

  .budgets__price:nth-child(4) {
    /* Color */
    color: var(--color-primary);
  }

  .budgets__description {
    /* Display & Box Model */
    margin: 2rem auto;

    /* Text */
    line-height: 1.5;
  }

  .budgets__images {
    /* Display & Box Model */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;

    figure {
      width: 100%;
      height: 8rem;
      background-color: var(--color-gray);
    }
  }
`;

AlertsPage.ImagesGrid = styled.div`
  /* Display & Box Model */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin: 2rem auto;
`;

AlertsPage.Image = styled.figure`
  /* Display & Box Model */
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Position */
  position: relative;

  /* Others */
  overflow: hidden;

  div {
    /* Display & Box Model */
    padding: 0.3rem;

    /* Colors */
    background-color: var(--color-white);
    color: var(--color-red);

    /* Text */
    font-size: 0.8rem;

    /* Position */
    position: absolute;
    bottom: 0rem;

    /* Others */
    cursor: pointer;
  }

  img {
    /* Display & Box Model */
    height: 100%;
  }
`;

AlertsPage.LinksInput = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  max-width: 600px;

  button {
    height: 54px;
    line-height: 1;
    min-width: 11rem;

    span {
      min-height: 1rem;
    }
  }
`;

AlertsPage.LinksList = styled.ul`
  margin-top: 12px;

  li {
    list-style-type: square;
    margin-left: 2rem;
    line-height: 1.6;
  }

  a:visited {
    color: blue;
  }

  button {
    color: var(--color-red);
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 8px;
  }
`;

/*================================
                NPS
=================================*/

SupplierPage.NPS = styled.div`
  /* Display & Box Model */
  width: 100%;

  form {
    /* Display & Box Model */
    margin: 2rem auto;
  }

  .btn {
    /* Display & Box Model */
    margin: 1rem auto;
  }
`;
