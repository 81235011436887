import { useCallback, useEffect } from 'react';
import axios from 'axios';
import { useAsync } from '../../../../utils';

export function useContract(id) {
  const { run, isLoading, data, error, isError } = useAsync({ status: 'pending' });
  const isContractNotFound = isError && error.response.status === 404;
  const isContractFromOtherSupplier = isError && error.response.status === 403;
  const isContractServerError = isError && error.response.status === 500;

  const loadContract = useCallback(() => {
    run(axios.get(`/api/supplier/contracts/${id}`).then(res => res.data));
  }, [run, id]);

  useEffect(() => {
    loadContract();
  }, [loadContract]);

  return {
    isLoadingContract: isLoading,
    contract: data,
    loadContract,
    isContractNotFound,
    isContractFromOtherSupplier,
    isContractServerError,
  };
}
