import React from 'react';
import { EscalePage } from '../styles';
import escaleLogo from '../../../assets/images/escale/escale_logo.png';

function EscaleMenu(){
    return(
        <EscalePage.Menu >
            <div className="container">
                <img src={ escaleLogo } alt="" />
            </div>
        </EscalePage.Menu>
    )
}

export default EscaleMenu;