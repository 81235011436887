import React from 'react';
import styled from 'styled-components';

export function FormSubHeader({ title, subtitle, ...props }) {
  return (
    <StyledSubHeader {...props}>
      <h4 className="txt--black">{title}</h4>
      {subtitle ? <p>{subtitle}</p> : null}
    </StyledSubHeader>
  );
}

const StyledSubHeader = styled.div`
  /* Display & Box Model */
  margin-bottom: 2rem;

  p {
    margin-top: 0.5rem;
  }
`;
