import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../Components/common/Buttons/ButtonLink';
import Footer from '../../Components/Footer';
import Header from '../../Components/Header';
import { StyledTerms, TermsPage } from './styles';

export default function BuyerTerms() {
  const section1ref = React.useRef(null);
  const section2ref = React.useRef(null);
  const section3ref = React.useRef(null);
  const section4ref = React.useRef(null);
  const section5ref = React.useRef(null);
  const section6ref = React.useRef(null);
  const section7ref = React.useRef(null);
  const section8ref = React.useRef(null);
  const section9ref = React.useRef(null);
  const section10ref = React.useRef(null);
  const section11ref = React.useRef(null);
  const section12ref = React.useRef(null);

  const scrollEffect = targetRef => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <TermsPage>
      <Header bgBlack={true} />
      <TermsPage.Nav>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          1 - CADASTRO DO COMPRADOR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section2ref)}>
          2 - USO DA PLATAFORMA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section3ref)}>
          3 - POLÍTICA DE CANCELAMENTO PELO COMPRADOR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          4 - OBRIGAÇÕES DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section5ref)}>
          5 - CONDIÇÕES COMERCIAIS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section6ref)}>
          6 - PRODUTOS DE FORNECEDORES DIVULGADOS NA PLATAFORMA DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section7ref)}>
          7 - DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section8ref)}>
          8 - LIMITE DE GARANTIA E DE RESPONSABILIDADE DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section9ref)}>
          9 - COMUNICAÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section10ref)}>
          10 - INFORMAÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section11ref)}>
          11 - MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section12ref)}>
          12 - DISPOSIÇÕES GERAIS
        </ButtonLink>
      </TermsPage.Nav>
      <StyledTerms>
        <h1>CELEBRAR</h1>
        <h1>TERMOS DE USO E CONDIÇÕES GERAIS DE COMPRA</h1>

        <section>
          <p>
            Estes são os Termos de Uso e Condições Gerais de Contratação (“<u>Contrato</u>” ou “
            <u>Termos de Uso</u>”) celebrado entre você, o <b>COMPRADOR</b>, e a{' '}
            <b>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA.</b> ("<u>CELEBRAR</u>"), que define os
            termos e condições gerais de cadastro e uso da plataforma de intermediação e gestão de
            serviços da CELEBRAR. Mediante aceite eletrônico na página e/ou ambiente digital
            disponibilizado pela CELEBRAR, você aceita e concorda com todos os termos e condições
            aqui previstos, os quais constituem acordo integral entre você e a CELEBRAR com relação
            aos serviços de cadastro de seus dados em nosso sistema e na abertura, manutenção e/ou
            encerramento da sua conta como adquirente de produtos e/ou serviços em nossa plataforma.
            Leia todo o conteúdo deste Contrato e de seus anexos atentamente antes de realizar o seu
            aceite eletrônico.
          </p>
          <p>
            Os <Link to="/termos/plataforma">Termos de Uso da Plataforma</Link>, a{' '}
            <Link to="/termos/politica-privacidade">Política de Privacidade</Link> e os{' '}
            <Link to="/termos/fornecedores">
              Termos de Uso e Condições Gerais de Contratação de Fornecedores
            </Link>{' '}
            integram o presente Termo, sendo parte inseparável do presente Termo.
          </p>
          <p>
            <b>
              CLICANDO NA OPÇÃO “<u>ACEITO</u>”, VOCÊ TOMA CONHECIMENTO DO INTEIRO TEOR DESTE
              INSTRUMENTO E CONCORDA COM ELE SEM RESSALVAS, SE COMPROMETENDO A CUMPRIR AS OBRIGAÇÕES
              AQUI DISPOSTAS.
            </b>
          </p>
          <p>
            O aceite deste Contrato é feito no final da leitura deste Instrumento. Você não é
            obrigado a fazê-lo, mas, sem ele, você não terá acesso aos nossos Serviços. Leia com
            atenção e aceite os termos apenas se estiver de acordo com as nossas condições. Em caso
            de dúvidas, entre em contato pelos seguintes canais:
          </p>
          <p>Por e-mail: eventos@celebrar.com.br</p>
          <p>Por celular ou WhatsApp: +55 11 98822-0134</p>
        </section>

        <section ref={section1ref}>
          <h2>1. CADASTRO DO COMPRADOR</h2>

          <p>
            1.1. São Partes deste Contrato a Celebrar e o <b>COMPRADOR</b>.
          </p>

          <p>
            1.1.1. Quem é o <b>COMPRADOR</b>? Pessoa Física ou Jurídica que adquire produto ou
            serviço oferecido pela CELEBRAR ou pelo FORNECEDOR, seja o produto ou serviço pago ou
            não, podendo ser denominado neste Contrato como “<b>COMPRADOR</b>” e/ou “
            <b>COMPRADORES</b>”.
          </p>

          <p>
            1.1.1.1. Sendo o <b>COMPRADOR</b> pessoa jurídica, o preposto neste instante se declara
            apto, capaz e com poderes legais para realizar a aquisição de produto e/ou a contratação
            de evento.
          </p>

          <p>
            1.1.2. Quem é o <b>FORNECEDOR</b>? Pessoa Jurídica que anuncia e comercializa seus
            produtos ou serviços por meio do Plataforma da Celebrar, únicos responsáveis pela
            composição e cumprimento da oferta, entrega, suporte, atendimento ao <b>COMPRADOR</b> e
            procedimentos que estejam diretamente ligados com a entrega dos produtos e/ou serviços
            respectivos; é pessoa jurídica, que concorda com os Termos de Uso da e com a Política de
            Privacidade da Celebrar, que preenche todos os formulários solicitados pela Celebrar e
            que é aceito no processo de avaliação interna da Celebrar, podendo ser denominado neste
            Contrato como “<b>FORNECEDOR</b>” e/ou “<b>FORNECEDOR</b>”.
          </p>

          <p>
            1.1.3. Quem é a <b>CELEBRAR</b>? É a <b>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA</b>,
            inscrita no CNPJ/ME sob o n.º 28.282.186/0001-38, endereço da sede: Av. Interlagos, 800,
            C2 233, Jd. Marajoara, cidade de São Paulo, Estado de São Paulo, CEP 04660-000.
          </p>

          <p>
            1.1.4. O que é a Plataforma da Celebrar? A Plataforma da Celebrar é um ambiente online
            que conecta pessoas que precisam de serviços relacionados a organização, preparo e
            assistência a eventos (“<b>COMPRADORES</b>”) com pessoas que prestam serviços
            relacionados à organização, preparo, execução e assistência a eventos (as “
            <b>
              <u>FORNECEDORES</u>
            </b>
            ”).
          </p>

          <p>
            1.2. A conta que o <b>COMPRADOR</b> terá na plataforma Celebrar é de titularidade única
            e exclusiva desse e será movimentada exclusivamente por ele ou seu representante em
            ambiente digital.
          </p>

          <p>
            1.3. Dados cedidos pelo <b>COMPRADOR</b> para uso da Conta Celebrar:
          </p>

          <p>
            1.3.1. No momento de seu cadastro, a Celebrar poderá solicitar dados essenciais para
            identificar e cadastrar a pessoa física ou jurídica titular da Conta Celebrar e dos
            representantes pessoas físicas do <b>COMPRADOR</b>. Todas as regras sobre a coleta e
            tratamento dos dados do COMPRADOR e de seus representantes estão detalhados na{' '}
            <Link to="/termos/politica-privacidade">Política de Privacidade Celebrar</Link>.
          </p>

          <p>
            1.3.2. Nós da Celebrar destacamos que toda coleta de dados que fazemos observam as
            disposições previstas na legislação e regulamentação aplicáveis, incluindo àquelas
            relativas à coleta, tratamento e armazenamento de dados pessoais dos <b>COMPRADORES</b>.
          </p>

          <p>1.4. Para obter acesso à Conta Celebrar, o COMPRADOR precisa:</p>
          <ul>
            <li>a) ler e concordar com esses termos de Uso;</li>
            <li>b) ler e concordar com a nossa Política de Privacidade;</li>
            <li>
              c) ter capacidade legal para contratar, ou seja, ser maior de 18 (dezoito) anos ou
              emancipado. Sendo menor de idade, seu responsável legal terá de arcar com as
              consequências dos atos praticados em nome do Comprador.
            </li>
            <li>
              d) Preencher o formulário de cadastro disponível no site da Celebrar ao selecionar um
              produto ou serviço que tenha interesse.
            </li>
            <li>
              e) Para que o <b>COMPRADOR</b> possa concluir sua compra, faz-se necessário o
              cadastramento na Plataforma da Celebrar.
            </li>
          </ul>

          <p>
            1.5. O <b>COMPRADOR</b>, ao preencher o cadastro na Celebrar, assume integral
            responsabilidade pelos dados fornecidos e se compromete a mantê-los permanentemente
            atualizados e a enviar à Celebrar informações e esclarecimentos sempre que solicitado.
          </p>

          <p>
            1.6. A Celebrar verificará periodicamente a veracidade das informações cadastrais
            requeridas do <b>COMPRADOR</b> e poderá exigir, independente de motivo, que o{' '}
            <b>COMPRADOR</b> comprove novamente qualquer informação já apresentada.
          </p>

          <p>
            1.7. Fica desde já advertido que ao preencher o cadastro todas as informações
            preenchidas são de inteira responsabilidade do <b>COMPRADOR</b> de forma civil e
            criminal. A constatação de qualquer informação falsa ou desconexos com o perfil do{' '}
            <b>COMPRADOR</b> implicam no cancelamento da inscrição e na impossibilidade de
            prosseguir com o cadastro.
          </p>

          <p>
            1.8. A Celebrar reserva o direito de excluir qualquer inscrição suspeita de fraude,
            somente procedendo outra inscrição mediante ao envio documental ao nosso canal de
            comunicação e análise do perfil devidamente comprovados pelo <b>COMPRADOR</b> e checados
            pelo nosso controle de segurança e qualidade, autenticando assim a inscrição.
            Reservando-se o direito de recusar e excluir qualquer inscrição previamente aceita, mas
            que seja descoberto algum fato superveniente impeditivo sem prévio aviso à parte.
          </p>
        </section>

        <section ref={section2ref}>
          <h2>2. USO DA PLATAFORMA CELEBRAR</h2>

          <p>
            2.1. O <b>COMPRADOR</b> deverá utilizar o serviço prestado pela Celebrar observando as
            seguintes responsabilidades, sem prejuízo de outras recomendações da Celebrar:
          </p>
          <ul>
            <li>
              (i) Observar as cláusulas e condições deste Contrato e das demais regras de prestação
              de serviços de qualquer natureza que eventualmente sejam celebradas entre a Celebrar e
              o FORNECEDOR; e
            </li>
            <li>
              (ii) Abster-se de realizar negócios jurídicos ilícitos, seja em virtude de Lei,
              Regulamentação ou outros.
            </li>
          </ul>

          <p>
            2.2. O <b>COMPRADOR</b>, ao acessar a Plataforma da Celebrar, tem a sua disposição uma
            série de produtos e serviços podendo estes ser oferecidos: (i) diretamente pela
            Celebrar, através dos Fornecedores; ou (ii) diretamente por Fornecedores de seus
            próprios produtos e serviços na Plataforma da Celebrar.
          </p>

          <p>
            2.2.1. <b>Produtos oferecidos diretamente pela Celebrar:</b> as condições de venda,
            preço e entrega destes produtos são definidas diretamente pela Celebrar, que se
            responsabiliza por elas.
          </p>

          <p>
            2.2.2.{' '}
            <b>
              Produtos oferecidos diretamente por Fornecedores de produtos na Plataforma da
              Celebrar:
            </b>{' '}
            as condições de venda, preço e entrega destes produtos são definidos diretamente pelo
            Fornecedor, identificado no anúncio.
          </p>

          <p>
            2.2.3. <b>Serviços oferecidos diretamente pela Celebrar:</b> as condições de venda,
            preço e execução destes serviços são definidas diretamente pela Celebrar, que se
            responsabiliza por elas.
          </p>

          <p>
            2.2.4.{' '}
            <b>
              Serviços oferecidos diretamente por Fornecedores de produtos no Plataforma da
              Celebrar:
            </b>{' '}
            as condições de venda, preço e execução destes serviços são definidos diretamente pelo
            Fornecedor, identificado no anúncio.
          </p>

          <p>
            2.5. Ao navegar na Plataforma o <b>COMPRADOR</b> declara estar ciente que deve verificar
            se um produto ou serviço é vendido diretamente pela Celebrar ou diretamente por
            Fornecedores.
          </p>

          <p>
            2.6. O <b>COMPRADOR</b> está ciente e de acordo que a Celebrar não se responsabiliza
            pela existência, entrega, qualidade, quantidade, vícios ou defeitos dos produtos e
            serviços vendidos na Plataforma diretamente por Fornecedores, devendo o <b>COMPRADOR</b>
            , nestes casos, contatar diretamente o Fornecedor. A Celebrar fará a intermediação entre
            o <b>COMPRADOR</b> e o Fornecedor para que eles diretamente resolvam eventual problema.
          </p>

          <p>
            2.7. O <b>COMPRADOR</b> é responsável por instalar os aplicativos eventualmente
            necessários para a visualização dos conteúdos disponíveis no Plataforma da Celebrar e
            adotar o navegador de sua preferência conforme padrão HTML utilizado.
          </p>
        </section>

        <section ref={section3ref}>
          <h2>3. POLÍTICA DE CANCELAMENTO PELO COMPRADOR</h2>

          <p>
            3.1. O cancelamento de um pedido de Produto ou Serviço por parte do <b>COMPRADOR</b>{' '}
            está sujeito à Política de Cancelamento.
          </p>

          <p>
            3.2. A CELEBRAR é um marketplace colaborativo para experiências corporativas mais
            completo, rápido e seguro. Nossos fornecedores são incríveis, basta você pedir: eles
            fazem seu sonho acontecer do jeito que você quer e onde você precisa.
          </p>

          <p>
            3.3. Os nossos produtos e serviços personalizados são o nosso diferencial. Estes
            produtos e serviços já empregam um serviço particular realizado especificamente a você{' '}
            <b>COMPRADOR</b>.
          </p>

          <p>
            3.4 A prestação deste tipo de serviço requer que o cliente cumpra com suas obrigações de
            envio das informações necessárias, dentro do prazo determinado.
          </p>

          <p>
            3.5. Dada a natureza do produto e do serviço particular de produção, em especial na
            contratação de eventos, não aceitamos o cancelamento do produto e/ou do serviço
            contratado arrependimento de compra, isso porque geraria um prejuízo ao fornecedor.,
            tendo em vista que o pedido foi confeccionado mediante a aprovação prévia.
          </p>

          <p>
            3.6. Em qualquer evento de cancelamento, independentemente do prazo, será devido pelo{' '}
            <b>COMPRADOR</b> o valor integral da taxa de serviço Celebrar.
          </p>

          <p>
            3.7. Sabemos que imprevistos acontecem e, por tal motivo, disponibilizamos um meio de
            contato específico para que encontramos a melhor solução para o produto e/ou serviço
            contratado. Basta você <b>COMPRADOR</b> enviar um e-mail para meajuda@celebrar.com.br e
            no prazo de 24 (vinte e quatro) horas responderemos a sua solicitação buscando
            solucionar o problema.
          </p>
        </section>

        <section ref={section4ref}>
          <h2>4. OBRIGAÇÕES DA CELEBRAR</h2>

          <p>4.1. A CELEBRAR obriga-se a:</p>

          <p>4.1.1. Cumprir com o disposto neste Contrato e na legislação vigente;</p>

          <p>4.1.2. Realizar a Intermediação, objeto deste Contrato, nos termos aqui definidos;</p>

          <p>4.1.3. Divulgar a Plataforma da Celebrar nas principais redes sociais.</p>
        </section>

        <section ref={section5ref}>
          <h2>5. CONDIÇÕES COMERCIAIS</h2>

          <p>
            5.1. Somente poderão fazer uso e adquirir produtos divulgados no Plataforma da Celebrar
            pessoas físicas ou jurídicas capazes e em pleno exercício de seus direitos e obrigações,
            de acordo com a Lei Brasileira e demais regulamentações internacionais que possam ser
            aplicáveis com o respectivo cadastro e criação de conta de acesso.
          </p>

          <p>
            5.2. Todos os preços e condições do Plataforma da Celebrar são válidos apenas para
            compras realizadas no www.celebrar.com.br e não se aplicam para lojas físicas de
            Fornecedores, assim como os preços e condições das lojas físicas não são válidos para o
            Plataforma da Celebrar.
          </p>

          <p>
            5.3. Ao concluir a formação do pedido, o <b>COMPRADOR</b> receberá uma mensagem da
            Plataforma da Celebrar contendo os dados da compra na conta de e-mail fornecida no
            cadastro.
          </p>

          <p>
            5.4. Os fornecedores determinam seus fluxos e regras para a comercialização de seus
            produtos ou serviços conforme sua conveniência e sem qualquer interferência da Celebrar.
            Por isso, os <b>COMPRADORES</b> devem conhecer e seguir tais procedimentos constantes
            dos Termos de Uso e demais documentos e descrições publicadas pelo Fornecedores da
            Celebrar, disponibilizados antes da finalização da aquisição do produto ou serviço do
            Fornecedores no site da Celebrar.
          </p>

          <p>
            5.5. <b>Preço e Pagamento</b>: o preço de qualquer produto ou serviço contratado será
            informado ao <b>COMPRADOR</b> pela Celebrar via Plataforma ou via qualquer meio de
            correspondência digital (e-mail, aplicativos de mensagens etc.), após o preenchimento do
            formulário de cadastro.
          </p>

          <p>
            5.6. Para todos os efeitos, considera-se contratado o serviço a partir do aceite do
            Usuário via Plataforma da Celebrar ou via qualquer meio de correspondência digital (e-
            mail, aplicativos de mensagens etc.).
          </p>

          <p>
            5.7. Todos os pagamentos decorrentes da contratação de serviços disponíveis na
            Plataforma da Celebrar só poderão ser pagos para a Celebrar ou a quem a Celebrar
            indicar, independentemente da forma de pagamento realizada.
          </p>

          <p>
            5.8. Quaisquer pagamentos realizados diretamente ao Fornecedor, ou a qualquer terceiro,
            sem a expressa anuência da Celebrar por escrito, não será considerado como pagamento dos
            serviços contratados pelo Plataforma da Celebrar, isentando a Celebrar de qualquer
            responsabilidade sobre o(s) serviço(s) contratado(s).
          </p>

          <p>
            5.9. Caso ocorra atraso no pagamento devido pelo <b>COMPRADOR</b> à Celebrar, o
            pagamento estará sujeito à incidência de juros de mora de 1% (um por cento) ao mês,{' '}
            <i>pro rata die</i>, acrescido de multa de 2% (dois por cento).
          </p>

          <p>
            5.10. A Celebrar poderá, na hipótese de atraso ou inadimplência de qualquer dos valores
            devidos: (i) Protestar o boleto bancário em aberto; e (ii) Inscrever o nome do Comprador
            em cadastro de devedores, em órgãos de proteção ao crédito ou cartórios de protestos.
          </p>

          <p>
            5.11. <b>Meios de pagamento e reembolso de valores:</b>
          </p>

          <p>
            5.11.1. Caso o <b>COMPRADOR</b> opte pelo boleto bancário e o pagamento não seja
            efetuado até a data do vencimento do título o pedido será automaticamente cancelado.
          </p>

          <p>
            5.11.2. Se preferir pagar utilizando cartão de crédito, o <b>COMPRADOR</b> será
            redirecionado para a plataforma de pagamentos on-line da Celebrar, que poderá ser da
            própria Celebrar, ou de empresa terceirizada de pagamentos online.
          </p>

          <p>
            5.11.2.1. A Plataforma da Celebrar se reserva ao direito de não trabalhar com cartões de
            crédito emitidos no exterior.
          </p>

          <p>
            5.11.3. O <b>COMPRADOR</b> deve ficar atento a todos os detalhes do pagamento no momento
            da compra, pois, mesmo após o fechamento do pedido será possível a alteração da forma de
            pagamento caso o uso de Cartão de Crédito não tenha sido bem-sucedido e o{' '}
            <b>COMPRADOR</b> deseje pagar de outro modo.
          </p>

          <p>
            5.11.4. Destacamos que a Plataforma da Celebrar não solicita senha e dados de cartão de
            crédito aos seus <b>COMPRADORES</b>. Excepcionalmente, quando houver o cancelamento ou
            arrependimento da compra e o pagamento se der por boleto bancário ou débito online, a
            Celebrar solicitará informações de dados bancários como nome do banco, agência e conta
            de seus <b>COMPRADORES</b> por e-mail, para haver o devido ressarcimento. Portanto,
            pedimos que o Usuário que eventualmente receba um e-mail com conteúdo inapropriado ou
            duvidoso, não responda, desconsidere-o e se possível encaminhe-o para{' '}
            <b>contato@celebrar.com.br</b>.
          </p>

          <p>
            5.12. <b>Da entrega dos produtos e serviços:</b>
          </p>

          <p>
            5.12.1. A Celebrar utiliza diversos serviços para a entrega de seus produtos, cujo tempo
            de transporte pode variar de acordo com a região do destinatário. Por isso, esteja
            atento com o status de seu pedido e se certifique que haverá alguém apto para receber a
            encomenda na data prevista, entre as 8h e 23h, ou na data e período selecionados, caso
            tenha optado pela entrega agendada.
          </p>

          <p>
            5.12.2. No caso da contratação de prestação de serviços oferecidos pela Celebrar ou por
            Fornecedor, estes serão entregues e executados de acordo com as condições, data e
            horário do seu evento, conforme apresentado pelo <b>COMPRADOR</b> e acordado com o
            prestador de serviço, seja este a Celebrar ou Fornecedor.
          </p>

          <p>
            5.12.3. Em caso de venda de produtos, caso a entrega não seja efetivada, haverá 02
            (duas) tentativas posteriores, contudo se mesmo assim todas as tentativas de entrega
            restarem infrutíferas, o produto voltará à Celebrar ou ao Fornecedor, a depender de quem
            estiver vendendo os produtos, sendo que estes entrarão em contato com o <b>COMPRADOR</b>{' '}
            para seguir com o reagendamento ou cancelamento da entrega.
          </p>

          <p>
            5.12.4. A Celebrar apenas entrega as encomendas transportadas no endereço indicado pelo{' '}
            <b>COMPRADOR</b>, conforme descrito no pedido. Os entregadores não estão autorizados a
            instalar, montar, desmontar, mover, ligar, testar ou realizar qualquer tarefa que
            implique em manuseio dos produtos entregues, sob qualquer hipótese, exceto quando este
            serviço tenha sido previamente contratado pelo Comprador.
          </p>

          <p>
            5.12.5. Caso seja constatado que o(s) produto(s) e/ou serviço(s) está(ão) em desacordo
            com o contratado, o <b>COMPRADOR</b> deverá recusar o recebimento do pedido, registrar o
            motivo e comunicar a Celebrar sobre o ocorrido.
          </p>
        </section>

        <section ref={section6ref}>
          <h2>6. PRODUTOS DE FORNECEDORES DIVULGADOS NA PLATAFORMA DA CELEBRAR</h2>

          <p>
            6.1. Ao comprar um produto de qualquer um dos Fornecedores, você está ciente que
            pagamentos e eventuais estornos/ressarcimentos serão realizados exclusivamente através
            do Plataforma da Celebrar.
          </p>

          <p>
            6.2. O(s) produto(s) dos Fornecedores adquiridos só poderão ser devolvidos conforme a
            política de devolução correspondente. A receptividade dos produtos pelo Fornecedores
            deve ser verificada conforme publicada nos seus documentos que determinam a troca,
            entrega e devolução.
          </p>

          <p>
            6.3. O <b>COMPRADOR</b> deverá verificar as informações de cado FORNECEDOR referentes à
            entrega dos produtos, política de retorno/trocas/devolução, informações de serviço ao
            consumidor, disponibilizados antes da finalização da aquisição do produto/serviço, ou
            ainda, disponibilizadas pelos Fornecedores. Assim, é esperado que o <b>COMPRADOR</b>{' '}
            esteja ciente e de acordo com as regras de comércio e navegação de ambiente digital
            estabelecidas pelos Fornecedores quando concluir a aquisição de produto e/ou serviço.
          </p>
        </section>

        <section ref={section7ref}>
          <h2>7. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</h2>

          <p>
            7.1. O uso comercial da expressão Celebrar como marca, nome empresarial ou nome de
            domínio, bem como os conteúdos das telas do Plataforma da Celebrar, assim como os
            programas, bancos de dados, redes, arquivos que permitem que o Usuário acesse e use
            através de sua conta são de propriedade da Celebrar e estão protegidos pelas leis e
            tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos
            industriais, sendo vedado o uso pelo Usuário, salvo mediante prévia autorização por
            escrito.
          </p>

          <p>
            7.2. Ao acessar a página da Celebrar, o Usuário declara que irá respeitar todos os
            direitos de propriedade intelectual e os decorrentes da proteção de marcas registradas
            da mesma, bem como de todos os direitos referentes a terceiros que porventura estejam,
            ou estiveram, de alguma forma, disponíveis na Plataforma da Celebrar. O simples acesso
            ao Plataforma da Celebrar não confere ao Usuário qualquer direito de uso dos nomes,
            títulos, palavras, frases, marcas, patentes, obras literárias, artísticas,
            lítero-musicais, imagens, vídeos, símbolos, entre outras, que nele estejam, ou
            estiveram, disponíveis; muito menos dos Fornecedores relacionados no Plataforma da
            Celebrar.
          </p>

          <p>
            7.3. A reprodução dos conteúdos descritos anteriormente está proibida, salvo mediante
            prévia autorização por escrito da Celebrar.
          </p>

          <p>
            7.4. É permitido fazer somente o arquivo temporário do Plataforma da Celebrar, sendo
            vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que
            contrarie a realidade para o qual foi concebido, conforme definido neste Termo. São
            igualmente proibidas a reprodução, distribuição, divulgação, adaptação, modificação,
            utilização, edição, disponibilização em qualquer meio de comunicação etc., total ou
            parcial, dos textos, figuras, gráficos que compõem o presente Plataforma, sem prévia e
            expressa autorização da Celebrar, sendo permitida somente a impressão de cópias para uso
            e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real
            entendimento de seu conteúdo e objetivo.
          </p>

          <p>
            7.5. Qualquer outro tipo de utilização de material autorizado, inclusive para fins
            editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso
            consentimento da Celebrar e respectivos Fornecedores presentes na Plataforma da
            Celebrar.
          </p>

          <p>
            7.6. O Usuário assume toda e qualquer responsabilidade pela utilização indevida das
            informações, textos, gráficos, marcas, obras, imagens, vídeos, enfim, de todo e qualquer
            direito de propriedade intelectual ou industrial do Plataforma da Celebrar ou de seus
            Fornecedores, tanto de caráter civil quanto criminal.
          </p>

          <p>
            7.7. Qualquer utilização não contemplada na presente autorização será considerada como
            uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei 9.610, de 19 de
            fevereiro de 1998, que protege os direitos autorais no Brasil.
          </p>

          <p>
            7.8. As fotos e imagens utilizadas no Plataforma da Celebrar podem não refletir seu
            tamanho original ou situação atual do cenário reproduzido, sendo meramente ilustrativas.
          </p>
        </section>

        <section ref={section8ref}>
          <h2>8. LIMITE DE GARANTIA E DE RESPONSABILIDADE DA CELEBRAR</h2>

          <p>
            8.1. No caso de cancelamento de orçamento a pedido do <b>COMPRADOR</b>, a operação será
            anulada e eventuais reembolsos, multas e outros valores serão repassados de acordo com
            as regras deste Contrato.
          </p>

          <p>
            8.2. Destacamos que a Celebrar não se responsabiliza pela eventual reprovação de crédito
            no momento de realização da compra, pois trata-se de procedimentos da respectiva
            Administradora do Cartão de Crédito utilizado pelo <b>COMPRADOR</b> no momento da
            compra. Informamos também que a Celebrar não tem conhecimento do motivo da reprovação de
            crédito e de outros motivos da eventual reprovação. Caso ocorra a reprovação de sua
            compra por essas razões, por favor, entre em contato com a administradora do cartão
            utilizado no momento da compra para solucionar esse incidente.
          </p>

          <p>
            8.3. Do mesmo modo, a Celebrar não se responsabiliza por qualquer dano, prejuízo ou
            perda no equipamento do COMPRADOR causado por falhas no sistema, no servidor ou na
            conexão decorrentes de condutas de terceiros, incluindo ações de softwares maliciosos
            como vírus, cavalo de tróia, etc. que possam, de algum modo, danificar o equipamento ou
            conexão do COMPRADOR em decorrência do acesso, utilização ou navegação no Plataforma
            Celebrar, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos
            nele contidos.
          </p>
        </section>

        <section ref={section9ref}>
          <h2>9. COMUNICAÇÕES</h2>

          <p>
            9.1. Todas as comunicações previstas neste Contrato serão feitas por escrito e
            consideradas recebidas na data da sua transmissão, se por e-mail, e na data do efetivo
            recebimento pela Parte comunicada, em seu endereço, se enviadas por qualquer outro meio.
          </p>

          <p>
            9.2. As comunicações para o <b>COMPRADOR</b> serão enviadas para os endereços previstos
            no formulário preenchido pelo FORNECEDOR ao registrar-se na Plataforma da Celebrar.
          </p>

          <p>9.3. As comunicações para a Celebrar serão enviadas para os seguintes endereços:</p>
          <ul>
            <li>
              I. Endereço: Av. Interlagos, nº 800 Jardim Marajoara, São Paulo/SP CEP 04660-000
            </li>
            <li>II. E-mail: camila@celebrar.com.br</li>
          </ul>

          <p>
            9.4. Qualquer alteração de endereço e/ou de destinatário deverá ser imediatamente
            comunicada por escrito pela Parte interessada à outra Parte.
          </p>
        </section>

        <section ref={section10ref}>
          <h2>10. INFORMAÇÕES</h2>

          <p>
            10.1. A Celebrar envidará seus melhores esforços no sentido de manter as informações que
            disponibilizar em seu Plataforma precisas, atualizadas e completas, o quanto possível.
            No entanto, não se responsabiliza por imprecisão, erro, fraude, inexatidão,
            insuficiência ou divergência nas informações, dados, imagens, vídeos ou outros conteúdos
            relacionados às comunicações.
          </p>

          <p>
            10.2. O COMPRADOR está ciente de que, no que se refere aos produtos e/ou serviços
            oferecidos por Fornecedores, as informações constantes na Plataforma são de exclusiva
            responsabilidade do Fornecedor, inclusive as informações relativas às respostas e
            perguntas trocadas entre o COMPRADOR e os Fornecedores sobre os produtos e/ou serviços
            por eles divulgados no Plataforma da Celebrar.
          </p>
        </section>

        <section ref={section11ref}>
          <h2>11. MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES</h2>

          <p>
            11.1. Os Termos e Condições de Uso do Plataforma Celebrar estão sujeitos a constante
            melhoria e aprimoramento. Assim, a CELEBRAR se reserva o direito de modificar a qualquer
            momento, de forma unilateral, o presente Termos e Condições de Uso. Os novos Termos e
            Condições entrarão em vigor após 10 (dez) dias contados de sua publicação no Plataforma.
            Caso os Usuários e Compradores não estejam de acordo com as novas regras, deverá entrar
            em contato com os canais de atendimento da Celebrar, hipótese em que se considerará
            rescindido seu vínculo contratual com a Celebrar, devendo, no entanto, ser cumpridas
            todas as obrigações assumidas até o momento da efetiva rescisão.
          </p>

          <p>
            11.2 Ao navegar por esta Plataforma Celebrar, você aceita guiar-se pelos Termos e
            Condições de Uso do Plataforma Celebrar que se encontram vigentes na data e, portanto,
            deve verificar os mesmos previamente cada vez que visitar este no Plataforma Celebrar.
          </p>
        </section>

        <section ref={section12ref}>
          <h2>12. DISPOSIÇÕES GERAIS</h2>

          <p>
            12.1. Ao aceitar o presente Contrato por qualquer meio de confirmação digital
            disponibilizado pela Celebrar, o <b>COMPRADOR</b> declara estar absolutamente de acordo
            com todas as disposições do presente Contrato, tornando-se obrigado a cumprir o presente
            Contrato em sua integralidade, sob as penas da lei vigente e do presente Contrato.
          </p>

          <p>
            12.2. O não exercício de quaisquer direitos previstos neste Contrato, por qualquer das
            Partes, não poderá ser considerado precedente ou autorizador de modificação do que se
            encontra aqui pactuado, representando mera tolerância do titular de tal direito, que
            poderá exercê-lo quando julgar conveniente.
          </p>

          <p>
            12.3. A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do
            presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco
            impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
          </p>

          <p>
            12.4. O <b>COMPRADOR</b> autoriza expressamente a Celebrar a comunicar-se com este
            através de todos os canais disponíveis, incluindo correio eletrônico (e-mail), celular,
            SMS, entre outros, ficando ressaltado que a principal via de informação para o Usuário é
            a Plataforma da Celebrar.
          </p>

          <p>
            12.5. A <b>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA</b> é empresa que respeita seu
            consumidor, disponibilizando a seus Compradores canais de atendimento para tratamento
            eficiente de dúvidas, reclamações e sugestões, sempre em respeito ao Código de Defesa do
            Consumidor (Lei 8078/1990) e à legislação em vigor. Além disso, a Celebrar reafirma seu
            compromisso de cooperação com as autoridades e demais órgãos públicos, assegurando a
            seriedade no uso da Internet no Brasil e o fomento ao crescimento do Comércio
            Eletrônico.
          </p>

          <p>
            12.6. Os Termos e Condições de Uso aqui descritos são regidos pela legislação da
            República Federativa do Brasil. Seu texto deverá ser interpretado no idioma português e
            os Usuários do Plataforma da Celebrar se submetem ao Foro do domicílio de seus
            consumidores.
          </p>
        </section>

        <h1>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA.</h1>
      </StyledTerms>

      <Footer />
    </TermsPage>
  );
}
