import React, { useEffect, useState } from 'react';
import { EscalePage } from '../styles';
// Categories images
import categoryImage1 from '../../../assets/images/escale/categories/category-page-1.jpg';
import categoryImage2 from '../../../assets/images/escale/categories/category-page-2.jpg';
import categoryImage3 from '../../../assets/images/escale/categories/category-page-3.jpg';
import categoryImage4 from '../../../assets/images/escale/categories/category-page-4.jpg';
import categoryImage5 from '../../../assets/images/escale/categories/category-page-5.jpg';


function EscaleCategoryPage({ section }){

    const [ sectionImg, setSectionImg ] = useState();

    useEffect( () => {

        window.scrollTo( 0, 0);

        switch( section ){
            case "Inicio da Escalada":
                setSectionImg( categoryImage1 );
            break;

            case "Escale Além do Topo":
                setSectionImg( categoryImage2 );
            break;

            case "Meu pet Escale":
                setSectionImg( categoryImage3 );
            break;

            case "Orgulho da minha escalada":
                setSectionImg( categoryImage5 );
            break;

            default: 

            break;
        }

    }, []);

    return(
        <EscalePage.CategoryPage >
            <figure>
                <img src={ sectionImg && sectionImg } alt="" />
            </figure>
            <div className="container">
                <h1> { section } </h1>
            </div>            
        </EscalePage.CategoryPage>
    )
}

export default EscaleCategoryPage;