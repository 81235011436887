import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { useAsync } from '../../../../utils';

export function useCategories() {
  const { updateUserData } = useAuthContext();
  const [userCategories, setUserCategories] = useState([]);

  const { run, data, isLoading } = useAsync({ status: 'pending' });

  useEffect(() => {
    run(axios.get(`/api/supplier/categories`).then(response => response.data));
  }, [run]);

  useEffect(() => {
    if (data) {
      setUserCategories(data.user_categories);
    }
  }, [data, updateUserData]);

  useEffect(() => {
    if (userCategories.length > 0) {
      updateUserData({ user_categories: userCategories });
    }
  }, [updateUserData, userCategories]);

  const categories = data?.categories || [];

  const groupedCategories = buildCategoryTree(categories);

  const level3categories = [];
  groupedCategories.forEach(mainCategory => {
    mainCategory.childrenCategories.forEach(level2category => {
      level2category.childrenCategories.forEach(level3category => {
        level3categories.push(level3category);
      });
    });
  });

  async function addCategory(categoryId) {
    const oldCategories = [...userCategories];
    const newCategories = [categoryId].filter(c => !oldCategories.includes(c));
    setUserCategories([...oldCategories, ...newCategories]);
    try {
      const res = await axios.post('/api/supplier/categories/add', {
        categories: newCategories,
      });
      setUserCategories(res.data.user_categories);
    } catch (err) {
      console.log(err.message);
      setUserCategories(oldCategories);
      throw new Error(err.message);
    }
  }

  async function removeCategory(categoryId) {
    const oldCategories = [...userCategories];
    const categoriesToRemove = [categoryId];
    const newCategories = oldCategories.filter(c => !categoriesToRemove.includes(c));
    setUserCategories(newCategories);
    try {
      const res = await axios.post('/api/supplier/categories/remove', {
        categories: categoriesToRemove,
      });
      setUserCategories(res.data.user_categories);
    } catch (err) {
      console.log(err.message);
      setUserCategories(oldCategories);
      throw new Error(err.message);
    }
  }

  return {
    groupedCategories,
    userCategories,
    level3categories,
    isLoadingCategories: isLoading,
    addCategory,
    removeCategory,
  };
}

function buildCategoryTree(categories, rootId = 0) {
  const tree = [];
  categories.forEach(c => {
    if (c.parentId === rootId) {
      const element = { id: c.id, name: c.name };
      const children = buildCategoryTree(categories, c.id);
      if (children.length > 0) {
        element.childrenCategories = children;
      }
      tree.push(element);
    }
  });
  return tree;
}
