import styled, { keyframes } from 'styled-components';

const Anime = keyframes `
    0%{
        opacity: 1;
    }

    28%{
        opacity: 1;
    }

    29%{
        opacity: 0;
    }

    30%{
        opacity: 1;
    }

    31%{
        opacity: 0;
    }

    32%{
        opacity: 1;
    }

    50%{
        opacity: 1;
    }

    51%{
        opacity: 1;
    }

    52%{
        opacity: 0;
    }

    53%{
        opacity: 1;
    }

    68%{
        opacity: 1;
    }

    78%{
        opacity: 1;
    }

    79%{
        opacity: 0;
    }

    80%{
        opacity: 1;
    }

    81%{
        opacity: 0;
    }

    82%{
        opacity: 1;
    }

    95%{
        opacity: 1;
    }
`;

export const ErrorContainer = styled.div `
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: var(--color-bg-404);

    h1{
        font-size: 10rem;
        font-weight: 300;
        margin: 0;
        color: var(--color-secondary);
        animation: ${Anime} 10s ease-out infinite;
        text-align: center;
    }

    h2{
        color: var(--color-secondary);
        font-weight: 300;
        text-align: center;
        animation: ${Anime} 10s ease-out infinite;
    }

    .btn{
        margin: 1rem auto;
        padding: 15px 0;
    }

    @media (min-width: 800px){

        h1{
            font-size: 15rem;   
        }
    }
`;