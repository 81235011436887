import React from 'react';
import Header from '../../Components/Header';
import { ButtonLink } from '../../Components/common/Buttons/ButtonLink';
import { StyledTerms, TermsPage } from './styles';
import Footer from '../../Components/Footer';
import { Link } from 'react-router-dom';

export default function SupplierTerms() {
  const section1ref = React.useRef(null);
  const section2ref = React.useRef(null);
  const section3ref = React.useRef(null);
  const section4ref = React.useRef(null);
  const section5ref = React.useRef(null);
  const section6ref = React.useRef(null);
  const section7ref = React.useRef(null);
  const section8ref = React.useRef(null);
  const section9ref = React.useRef(null);
  const section10ref = React.useRef(null);
  const section11ref = React.useRef(null);
  const section12ref = React.useRef(null);
  const section13ref = React.useRef(null);
  const section14ref = React.useRef(null);
  const section15ref = React.useRef(null);
  const section16ref = React.useRef(null);
  const section17ref = React.useRef(null);
  const section18ref = React.useRef(null);
  const section19ref = React.useRef(null);
  const section20ref = React.useRef(null);
  const section21ref = React.useRef(null);
  const section22ref = React.useRef(null);
  const section23ref = React.useRef(null);
  const section24ref = React.useRef(null);

  const scrollEffect = targetRef => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <TermsPage>
      <Header bgBlack />

      <TermsPage.Nav>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section1ref)}>
          1 - GLOSSÁRIO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section2ref)}>
          2 - CADASTRO DO FORNECEDOR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section3ref)}>
          3 - USO DA PLATAFORMA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section4ref)}>
          4 - CADASTRO DOS PRODUTOS E SERVIÇOS DO FORNECEDOR NA PLATAFORMA
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section5ref)}>
          5 - UTILIZAÇÃO DA PLATAFORMA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section6ref)}>
          6 - MULTAS POR DESCUMPRIMENTO DO CONTRATO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section7ref)}>
          7 - OBRIGAÇÕES DO FORNECEDOR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section8ref)}>
          8 - SUBCONTRATAÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section9ref)}>
          9 - OBRIGAÇÕES DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section10ref)}>
          10 - REMUNERAÇÃO DO FORNECEDOR E DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section11ref)}>
          11 - CASO FORTUITO E FORÇA MAIOR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section12ref)}>
          12 - DENÚNCIA E RESCISÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section13ref)}>
          13 - QUESTÕES SECURITÁRIAS
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section14ref)}>
          14 - DIREITO DE FISCALIZAÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section15ref)}>
          15 - DESVINCULAÇÃO TRABALHISTA
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section16ref)}>
          16 - SIGILO E CONFIDENCIALIDADE
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section17ref)}>
          17 - DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section18ref)}>
          18 - DO DIREITO DE IMAGEM
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section19ref)}>
          19 - LIMITE DE GARANTIA E DE RESPONSABILIDADE DA CELEBRAR
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section20ref)}>
          20 - CONDIÇÕES DE SUSTENTABILIDADE E CIDADANIA
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section21ref)}>
          21 - COMPLIANCE E ANTICORRUPÇÃO
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section22ref)}>
          22 - COMUNICAÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section23ref)}>
          23 - MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES
        </ButtonLink>
        <ButtonLink style={{ color: 'black' }} onClick={() => scrollEffect(section24ref)}>
          24 - DISPOSIÇÕES GERAIS
        </ButtonLink>
      </TermsPage.Nav>

      <StyledTerms>
        <h1>CELEBRAR</h1>

        <h1>TERMOS DE USO E CONDIÇÕES GERAIS DE VENDA PELOS FORNECEDORES</h1>

        <section>
          <p>
            Estes são os Termos de Uso e Condições Gerais de Contratação (“<u>Contrato</u>” ou “
            <u>Termos de Uso</u>”) celebrado entre você, o <b>FORNECEDOR</b>, e a CELEBRAR
            TECNOLOGIA E INTERMEDIAÇÕES LTDA, sociedade de responsabilidade limitada, estabelecida
            no Brasil, inscrita no CNPJ sob o nº 28.282.186/0001-38, com sede na Avenida Interlagos
            nº 800, Jardim Marajoara, na Cidade de São Paulo, Estado de São Paulo, CEP: 04660-000,
            adiante denominada "<b>CELEBRAR</b>".
          </p>

          <p>
            Mediante aceite eletrônico na página e/ou ambiente digital disponibilizado pela
            <b>CELEBRAR</b>, o usuário concorda com todos os termos e condições aqui previstos, os
            quais constituem acordo integral entre o <b>FORNECEDOR</b> e a <b>CELEBRAR</b> com
            relação aos serviços de cadastro de seus dados em nosso sistema e na abertura,
            manutenção e/ou encerramento da sua conta como fornecedor de produtos e/ou serviços em
            nossa plataforma.
          </p>

          <p>
            Os <Link to="/termos/plataforma">Termos de Uso da Plataforma</Link>, a{' '}
            <Link to="/termos/politica-privacidade">Política de Privacidade</Link> e os{' '}
            <Link to="/termos/comprador">Termos de Uso e Condições Gerais de Compra</Link> integram
            o presente Termo, considerados partes inseparáveis deste Termo.
          </p>

          <p>
            <b>
              CLICANDO NA OPÇÃO “<u>ACEITO</u>”, VOCÊ TOMA CONHECIMENTO DO INTEIRO TEOR DESTE
              INSTRUMENTO, BEM COMO DOS TERMOS E POLÍTICAS QUE OS INTEGRAM, E CONCORDA COM ELE SEM
              RESSALVAS, SE COMPROMETENDO A CUMPRIR AS OBRIGAÇÕES AQUI DISPOSTAS.
            </b>
          </p>

          <p>
            O aceite deste Contrato é feito no final da leitura deste Instrumento. Você não é
            obrigado a fazê-lo, mas, sem ele, você não terá acesso aos nossos Serviços. Leia com
            atenção e aceite os termos apenas se estiver de acordo com as nossas condições. Em caso
            de dúvidas, entre em contato pelos seguintes canais:
          </p>

          <p>
            <b>Por e-mail:</b> eventos@celebrar.com.br
          </p>
          <p>
            <b>Por celular ou WhatsApp:</b> +55 11 98822-0134
          </p>
        </section>

        <section ref={section1ref}>
          <h2>1. GLOSSÁRIO</h2>
          <ul>
            <li>
              <b>CELEBRAR:</b> é a <b>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA</b>, inscrita no
              CNPJ/ME sob o nº 28.282.186/0001-38, endereço da sede: Av. Interlagos, 800, C2 233,
              Jd. Marajoara, cidade de São Paulo, Estado de São Paulo, CEP 04660-000.
            </li>
            <li>
              <b>FORNECEDOR:</b> pessoa Jurídica que anuncia e comercializa seus produtos ou
              serviços por meio da Plataforma da <b>CELEBRAR</b>, únicos responsáveis pela
              composição e cumprimento da oferta, entrega, suporte, atendimento ao Comprador e
              procedimentos que estejam diretamente ligados com a entrega dos produtos e/ou serviços
              respectivos. Pode ser denominado neste Contrato como "<b>FORNECEDOR</b>" e/ou "
              <b>FORNECEDORES</b>".
            </li>
            <li>
              <b>Plataforma Celebrar:</b> é um ambiente online que conecta pessoas que precisam de
              serviços relacionados a organização, preparo e assistência a eventos ("Clientes") com
              pessoas que prestam serviços relacionados à organização, preparo, execução e
              assistência a eventos.
            </li>
            <li>
              <b>"Serviço" ou "Serviços":</b> todos os serviços oferecidos pelo <b>FORNECEDOR</b> e
              que tenham sido devidamente cadastrados pelo <b>FORNECEDOR</b> na Plataforma Celebrar.
            </li>
          </ul>
        </section>

        <section ref={section2ref}>
          <h2>2. CADASTRO DO FORNECEDOR</h2>
          <p>
            2.1. A conta que o <b>FORNECEDOR</b> terá na Plataforma Celebrar é de titularidade única
            e exclusiva e será movimentada exclusivamente por ele ou seu representante em ambiente
            digital.
          </p>
          <p>
            2.2. Para se tornar um <b>FORNECEDOR</b> e obter acesso à Conta Celebrar, o{' '}
            <b>FORNECEDOR</b> precisa:
          </p>
          <ul>
            <li>a) Ler e concordar com estes termos;</li>
            <li>
              b) Ler e concordar com os <Link to="/termos/plataforma">Termos de Uso do Site</Link> e
              a nossa <Link to="/termos/politica-privacidade">Política de Privacidade</Link>;
            </li>
            <li>c) Preencher os formulários digitais;</li>
            <li>
              d) Encaminhar a documentação de identificação solicitada pela <b>CELEBRAR</b>.
            </li>
          </ul>
          <p>
            2.3. Os documentos e informações mínimas exigidas para cadastro poderão variar e serão
            informados pela <b>CELEBRAR</b> ao <b>FORNECEDOR</b> no momento de seu cadastro.
          </p>
          <p>
            2.4. Os dados de login e a senha de acesso do <b>FORNECEDOR</b> são sigilosos, pessoais
            e intransferíveis, e não deverão ser compartilhados pelo <b>FORNECEDOR</b> com terceiros
            em qualquer hipótese, sendo de sua inteira responsabilidade a sua utilização.
          </p>
          <p>
            2.5. O <b>FORNECEDOR</b>, ao preencher o cadastro na plataforma, assume integral
            responsabilidade pelos dados fornecidos e se compromete a mantê-los permanentemente
            atualizados e a enviar à <b>CELEBRAR</b> informações e esclarecimentos sempre que
            solicitado.
          </p>
          <p>
            2.6. A <b>CELEBRAR</b> reserva o direito de excluir qualquer inscrição suspeita de
            fraude, somente procedendo outra inscrição mediante ao envio documental ao nosso canal
            de comunicação e análise do perfil devidamente comprovados pelo <b>FORNECEDOR</b> e
            checados pelo nosso controle de segurança e qualidade, autenticando assim a inscrição.
            Reservando-se o direito de recusar e excluir qualquer inscrição previamente aceita, mas
            que seja descoberto algum fato superveniente impeditivo sem prévio aviso à parte.
          </p>
          <p>
            2.6.1. A qualquer tempo a <b>CELEBRAR</b> reserva se o direito de suspender qualquer{' '}
            <b>FORNECEDOR</b> da plataforma que incorrer em qualquer ato ilícito ou que atente
            contra a moral e os bons costumes da empresa e seus funcionários, impossibilitando a
            este o direito de indenização ou dano moral conforme previsto no código civil.
          </p>
          <p>
            2.7. Análise do candidato como <b>FORNECEDOR</b>. A abertura e a manutenção da conta
            está condicionada à análise e aprovação, pela <b>CELEBRAR</b> , das informações
            cadastrais fornecidas pelo <b>FORNECEDOR</b>, bem como dos demais critérios adotados
            pela CELEBRAR, podendo a Celebrar recusar a abertura e manutenção ou suspender
            temporariamente o acesso do <b>FORNECEDOR</b> à Plataforma Celebrar mediante a
            verificação ou não de seus critérios de admissibilidade, caso o <b>FORNECEDOR</b> deixe
            de atualizar algum documento obrigatório exigido pela Celebrar e/ou caso o{' '}
            <b>FORNECEDOR</b> violar o previsto nos Termos e Políticas da
            <b>CELEBRAR</b>.
          </p>
          <p>
            2.8. O <b>FORNECEDOR</b>, ao preencher o cadastro na <b>CELEBRAR</b>, assume integral
            responsabilidade pelos dados fornecidos e se compromete a mantê-los permanentemente
            atualizados e a enviar à Celebrar informações e esclarecimentos sempre que solicitado.{' '}
          </p>
          <p>
            2.9. A <b>CELEBRAR</b> verificará periodicamente a veracidade das informações cadastrais
            requeridas do <b>FORNECEDOR</b> e poderá exigir, independente de motivo, que o{' '}
            <b>FORNECEDOR</b> comprove novamente qualquer informação já apresentada.
          </p>
          <p>
            2.10. Fica desde já advertido que ao preencher o cadastro todas as informações
            preenchidas são de inteira responsabilidade do <b>FORNECEDOR</b> de forma civil e
            criminal, e que após a verificação, somente serão validados a inscrição ao serem
            preenchidos todos os campos do questionário. A constatação de qualquer informação falsa
            ou desconexos com o perfil do <b>FORNECEDOR</b> implicam no cancelamento da inscrição e
            na impossibilidade de prosseguir com o cadastro.
          </p>
        </section>

        <section ref={section3ref}>
          <h2>3. USO DA PLATAFORMA CELEBRAR</h2>

          <p>
            3.1. O <b>FORNECEDOR</b> deverá utilizar o serviço prestado pela <b>CELEBRAR</b>{' '}
            observando as seguintes responsabilidades, sem prejuízo de outras recomendações da{' '}
            <b>CELEBRAR</b>:
          </p>
          <ul>
            <li>
              a) Observar as cláusulas e condições deste Contrato e das demais regras de prestação
              de serviços de qualquer natureza que eventualmente sejam celebradas entre a{' '}
              <b>CELEBRAR</b> e o <b>FORNECEDOR</b>; e
            </li>
            <li>
              b) Abster-se de realizar negócios jurídicos ilícitos, seja em virtude de Lei,
              Regulamentação ou outros.
            </li>
          </ul>

          <p>
            3.2. O <b>FORNECEDOR</b> não poderá, em nenhuma hipótese:
          </p>
          <ul>
            <li>
              a) Desrespeitar a Lei, seja a brasileira ou a do local onde esteja sendo utilizado o
              serviço;
            </li>
            <li>
              b) Obter ou tentar obter acesso não autorizado a outros sistemas ou redes de
              computadores conectados à <b>CELEBRAR</b>; e/ou
            </li>
            <li>
              c) Interferir ou interromper o serviço ou os servidores ou redes conectadas ao
              serviço.
            </li>
          </ul>
          <p>
            3.3. Todo e qualquer serviço a que o <b>FORNECEDOR</b> tiver acesso por força deste
            Contrato será de uso exclusivo do <b>FORNECEDOR</b>, que não os poderá comercializar,
            repassar ou ceder a terceiros a qualquer título sem prévia e expressa autorização da
            <b>CELEBRAR</b>.
          </p>
        </section>

        <section ref={section4ref}>
          <h2>4. CADASTRO DOS PRODUTOS E SERVIÇOS DO FORNECEDOR NA PLATAFORMA</h2>
          <p>
            4.1. Os cadastros de produtos fornecidos e/ou serviços prestados pelo <b>FORNECEDOR</b>{' '}
            são sempre realizados pelo próprio <b>FORNECEDOR</b> e são de sua inteira
            responsabilidade.
          </p>

          <p>
            4.2. Todos os produtos e serviços deverão ser descritos pelo <b>FORNECEDOR</b> de forma
            clara e concisa, de forma que uma pessoa sem conhecimento técnico tenha a capacidade de
            entender o que está adquirindo.
          </p>

          <p>
            4.3. O cadastro de produtos fornecidos/fabricados pelo <b>FORNECEDOR</b> deve detalhar:
          </p>
          <ul>
            <li>a) O Preço unitário do Produto descrito;</li>
            <li>
              b) o conteúdo do produto (quantidade de partes envolvidas, detalhamento de cores e
              acessórios que compõe uma única unidade do produto);
            </li>
            <li>
              c) material utilizado no produto, especialmente em caso de fornecimento de alimentos,
              com detalhamento de todos os ingredientes;
            </li>
            <li>d) Dimensões de cada unidade do Produto;</li>
            <li>e) Peso de cada unidade do Produto;</li>
            <li>f) Cuidados de manuseio e armazenamento do produto;</li>
            <li>g) Prazo para confecção do produto;</li>
            <li>h) Prazo de entrega do produto;</li>
            <li>i) Prazo de validade do produto;</li>
            <li>
              j) Avisos de risco relacionados ao produto (por ex. materiais cortantes, materiais não
              digestíveis, materiais nocivos à saúde se ingeridos, materiais perigosos para
              crianças).
            </li>
          </ul>

          <p>
            4.4. O cadastro de serviços prestados pelo <b>FORNECEDOR</b> deve detalhar:
          </p>
          <ul>
            <li>a) O preço do Serviço descrito;</li>
            <li>b) A forma de execução do Serviço;</li>
            <li>c) Duração da prestação dos Serviços;</li>
            <li>d) Materiais e equipamentos utilizados na prestação dos serviços;</li>
            <li>e) Equipamentos inclusos no preço dos Serviços;</li>
            <li>f) Materiais necessários aos Serviços;</li>
            <li>g) Fotos de referência da entrega dos serviços, quando aplicável</li>
          </ul>
          <p>
            4.5. O <b>FORNECEDOR</b> deverá preencher de forma completa todos os campos de indexação
            dos produtos e serviços que desejar fornecer, sendo que o cadastro inadequado poderá
            ensejar na suspensão do cadastro do <b>FORNECEDOR</b> até a sua regularização.
          </p>
          <p>
            4.6. A Celebrar não se responsabiliza por qualquer prejuízo que o <b>FORNECEDOR</b>{' '}
            incorra em decorrência de cadastro de produtos ou serviços de forma incompleta ou
            incorreta realizado pelo <b>FORNECEDOR</b>.
          </p>
          <p>
            4.7. O <b>FORNECEDOR</b> garante que os serviços serão totais, completos e suficientes
            para assegurar que todas as premissas propostas nos Serviços sejam devidamente entregues
            para <b>CELEBRAR</b> e/ou para os Clientes da <b>CELEBRAR</b>.
          </p>
        </section>

        <section ref={section5ref}>
          <h2>5. UTILIZAÇÃO DA PLATAFORMA CELEBRAR</h2>

          <p>
            5.1. Para utilizar a Plataforma Celebrar, o <b>FORNECEDOR</b> deverá:
          </p>
          <ul>
            <li>a) acessar a Plataforma Celebrar através do link: </li>
            <li>
              b) acessar a área de orçamentos, onde estão disponíveis os pedidos de orçamento feitos
              por clientes da Celebrar que aguardam atendimento;
            </li>
            <li>
              c) ler com atenção o pedido de atendimento de interesse do <b>FORNECEDOR</b>;
            </li>
            <li>
              d) caso o <b>FORNECEDOR</b> queira enviar orçamento à uma proposta de orçamento, este
              deverá necessariamente:
              <ul>
                <li>
                  a. Utilizando um modelo de orçamento disponibilizado pela Plataforma Celebrar:
                  <ul>
                    <li>
                      i. Selecionar o modelo de orçamento:
                      <ul>
                        <li>
                          1. Selecionar os itens (produtos/serviços) que já tenha cadastrado na
                          Plataforma da Celebrar que serão incluídos no orçamento;
                        </li>
                        <li>2. Revisar os itens incluídos;</li>
                        <li>
                          3. Incluir os itens (produtos/serviços) que porventura não estejam
                          previamente cadastrados na Plataforma Celebrar;
                        </li>
                        <li>
                          4. Verificar se é necessário editar qualquer informação do orçamento
                          preparado;
                        </li>
                        <li>5. Enviar orçamento.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  b. Não utilizando um modelo de orçamento disponibilizado pela Plataforma Celebrar:
                  <ul>
                    <li>
                      1. Criar um Formulário de orçamento:
                      <ul>
                        <li>a. Inserir descrição dos itens (produtos/serviços);</li>
                        <li>b. Inserir Preço detalhado de cada item (produtos/serviços);</li>
                      </ul>
                    </li>
                    <li>2. Enviar orçamento.</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              e) Aprovado o orçamento, a Celebrar informará o <b>FORNECEDOR</b> da aprovação, e este
              deverá enviar, via plataforma, nota fiscal dos produtos/serviços a serem prestados.
            </li>
          </ul>

          <p>
            5.2. O <b>FORNECEDOR</b> reconhece que só poderá cadastrar itens (produtos/serviços) na
            Plataforma Celebrar que tenha capacidade técnica para fornecer e que seja adequada às
            atividades econômicas que o <b>FORNECEDOR</b> tiver registrada em seu Cadastro Nacional
            de Pessoa Jurídica perante a Junta Comercial.
          </p>
        </section>

        <section ref={section6ref}>
          <h2>6. MULTAS POR DESCUMPRIMENTO DO CONTRATO</h2>

          <p>
            6.1. Será aplicada uma multa ao <b>FORNECEDOR</b> nos seguintes casos:
          </p>

          <p>
            6.1.1. Falha na entrega de Serviços exatamente da forma como foram contratados, sendo
            que, caso tratar-se de Serviços de fornecimento de bens de qualquer natureza, não
            entrega de exatamente o tipo e quantidade de bens contratados: multa de 20% (vinte por
            cento) sobre o valor da venda dos serviços (valor da venda + taxa de serviço);
          </p>

          <p>
            6.1.2. Atraso na entrega dos serviços superior a 20 (vinte) minutos na entrega ou na
            montagem de quaisquer estruturas necessárias na data do evento do Cliente: multa de 50%
            (cinquenta por cento) sobre o valor da venda dos serviços (valor do repasse + taxa de
            serviço).
          </p>

          <p>
            6.1.3. Cancelamento de entrega de Serviços Confirmados com menos de 15 (quinze) dias
            úteis antes da data agendada para a entrega: multa de 100% (cem por cento) sobre o valor
            total da venda, além dos custos havidos na substituição do <b>FORNECEDOR</b> para a
            entrega do serviço;
          </p>

          <p>
            6.1.3.1. Em qualquer caso de aplicação da multa acima, em caso de pagamento adiantado
            deverá devolver quaisquer valores recebidos do <b>COMPRADOR</b> além de dever o valor da
            multa definida acima.
          </p>

          <p>
            6.1.4. Falta de ética e/ou profissionalismo por parte <b>FORNECEDOR</b> na execução dos
            Serviços: multa de 50% (cinquenta por cento) sobre o valor total dos Serviços
            contratados;
          </p>

          <p>
            Por falta de ética e profissionalismo entende-se como a ocorrência comprovada de
            práticas elencadas a seguir:
          </p>
          <ul>
            <li>a) difundir informações mentirosas ou difamatórias sobre um colega;</li>
            <li>
              b) ofender alguém a respeito da religião, gênero, cor, etnia, orientação sexual etc.;
            </li>
            <li>
              c) não honrar contratos, pagamentos e entregas nas relações com clientes e
              fornecedores;
            </li>
            <li>d) trocar influência por favores sexuais ou financeiros;</li>
            <li>e) procrastinar na realização e na entrega das tarefas;</li>
            <li>f) furtar dinheiro ou materiais da empresa, até mesmo uma simples caneta;</li>
            <li>g) vestir-se de maneira inapropriada ou debochar de alguém pela sua aparência;</li>
            <li>h) adulterar mercadorias, preços e prazos de validade;</li>
            <li>i) fazer propaganda enganosa e divulgar resultados falsos da empresa;</li>
            <li>j) cobrar por serviços desnecessários;</li>
            <li>
              k) jogar lixo em local inapropriado e não contribuir para a manutenção da limpeza do
              ambiente;
            </li>
            <li>
              l) chegar atrasado ou pedir alguém para assinar o ponto por você ou assinar pelo
              outro;
            </li>
            <li>m) sonegar impostos, fraudar documentos ou de qualquer forma infringir a lei.</li>
          </ul>

          <p>
            6.2. A contratação do <b>FORNECEDOR</b> é sempre feita na modalidade white label, ou
            seja, durante a execução do serviço o <b>FORNECEDOR</b> não poderá exibir sua marca ou
            sua razão social de qualquer forma, seja no uniforme, na estrutura montada, em projeção,
            como etiqueta ou impressão em qualquer parte do produto, salvo se solicitado por escrito
            pelo Cliente ou pela <b>CELEBRAR</b> ao <b>FORNECEDOR</b>.
          </p>

          <p>
            6.2.1. O fornecimento de produtos e/ou execução de serviços pelo <b>FORNECEDOR</b> com
            exibição de sua marca, nos termos do item 6.2 será considerado como patrocínio do
            <b>FORNECEDOR</b> ao Cliente, que não poderá cobrar pelo fornecimento de produtos ou
            serviços nesses casos e, em caso de pagamento adiantado deverá devolver quaisquer
            valores recebidos do Cliente.
          </p>
        </section>

        <section ref={section7ref}>
          <h2>7. OBRIGAÇÕES DO FORNECEDOR</h2>

          <p>
            7.1. Sem prejuízo das demais obrigações previstas neste instrumento, o <b>FORNECEDOR</b>{' '}
            obriga-se a:
          </p>
          <ul>
            <li>I. Cumprir com o disposto neste Contrato e na legislação vigente;</li>
            <li>
              II. Fornecer informações claras e precisas acerca dos produtos que serão
              disponibilizados para venda na Plataforma da Celebrar;
            </li>
            <li>
              III. Manter sempre atualizada a relação de produtos e serviços cadastrados na
              Plataforma da Celebrar, especialmente em relação a disponibilidade ou não destes
              produtos e serviços;
            </li>
            <li>
              IV. Disponibilizar ao Cliente da <b>CELEBRAR</b> uma equipe de profissionais
              especializados quando houver prestação de serviços;
            </li>
            <li>
              V. Estar legalmente habilitada para exercer as atividades oferecidas na Plataforma da
              Celebrar;
            </li>
            <li>
              VI. Responsabilizar-se inteiramente pela prestação do serviço nos termos e condições
              oferecidos na Plataforma da Celebrar;
            </li>
            <li>
              VII. Cumprir com todas as obrigações decorrentes da mão-de-obra empregada na prestação
              dos serviços oferecidos pelo <b>FORNECEDOR</b> através da Plataforma Celebrar, sejam
              elas de que natureza for, inclusive natureza fiscal, tributária, previdenciária,
              trabalhista, civil, penal, ambiental ou administrativa;
            </li>
            <li>
              VIII. Cumprir integralmente o CONTRATO, por si, seus sócios, representantes,
              empregados, ou quaisquer pessoas a ela vinculada, sob pena de rescisão imediata do
              CONTRATO;
            </li>
            <li>
              IX. Praticar todo e qualquer ato a fim de dar cabal cumprimento aos Serviços,
              inclusive realizar viagens quando comprovadamente necessárias;
            </li>
            <li>X. Prestar os Serviços observando as datas, os prazos e as condições acordadas;</li>
            <li>
              XI. Prestar as atividades, de forma diligente, independente e zelosa, sempre dentro
              dos padrões de qualidade exigidos pela <b>CELEBRAR</b> e pelo Cliente, bem como nos
              prazos e termos pactuados neste instrumento;
            </li>
            <li>
              XII. Envidar seus melhores esforços na consecução dos Serviços, bem como, executar os
              Serviços dentro da boa técnica utilizada para as atividades aplicáveis à mesma
              espécie;
            </li>
            <li>
              XIII. Observar todas as prescrições relativas às Leis Trabalhistas, Previdenciárias
              Assistenciais e securitárias relativas ao pessoal empregado, contratado ou
              subcontratado do <b>FORNECEDOR</b>, assumindo todas as obrigações como única
              empregadora e responsável, com total isenção da <b>CELEBRAR</b> e de seus Clientes;
            </li>
            <li>
              XIV. Manter a <b>CELEBRAR</b> isenta de qualquer atuação, notificação, intimação,
              reclamação e/ou condenação decorrente da compra e venda dos Produtos e/ou Serviços,
              bem como decorrentes de vícios redibitórios, evicção e defeitos nos Produtos vendidos;
            </li>
            <li>
              XV. Aceitar a denunciação à lide caso a <b>CELEBRAR</b> seja envolvida em algum
              processo, judicial ou administrativo, referente à Intermediação e/ou compra e venda
              dos produtos e/ou serviços, sem prejuízo de direito de regresso;
            </li>
            <li>
              XVI. Cumprir, durante a execução dos Serviços, objeto deste CONTRATO, toda a
              legislação aplicável à espécie, seja federal, estadual ou municipal, bem como todas as
              determinações e resoluções dos órgãos da Administração Pública competentes e demais
              entidades de fiscalização, responsabilizando-se, única e integralmente, por todo e
              qualquer ônus decorrente da inobservância destes preceitos;
            </li>
            <li>
              XVII. Fornecer à CELEBRAR e aos seus Clientes, se e quando solicitado por estes, cópia
              autenticada dos seguintes documentos, dentro do prazo estipulado pela CELEBRAR para a
              sua entrega:
              <ul>
                <li>
                  a) cópia das Guias de Recolhimento - GRPS do INSS, devidamente preenchidas, nos
                  moldes da legislação previdenciária, atreladas à Nota Fiscal/Fatura;
                </li>
                <li>b) cópia da Guia de Recolhimento do FGTS;</li>
                <li>
                  c) cópia das fichas de registro – dos funcionários atuais e por ocasião de novas
                  contratações;
                </li>
                <li>d) cópia do Comprovante do Pagamento do PIS;</li>
                <li>
                  e) cópia da folha de pagamento específica do pessoal vinculado à prestação dos
                  Serviços;
                </li>
                <li>f) cópia dos termos de rescisão de contrato de trabalho – quando houver;</li>
                <li>g) cópia dos recibos de férias – quando houver;</li>
                <li>h) cópia das GFIP´s;</li>
                <li>i) cópia dos recibos de pagamento;</li>
                <li>
                  j) cópia de documento comprobatório de recolhimento de contribuição sindical –
                  quando houver;
                </li>
                <li>
                  k) cópia dos contratos de trabalho do pessoal vinculado à prestação dos Serviços –
                  dos funcionários atuais e por ocasião de novas contratações; e
                </li>
                <li>
                  l) quaisquer outros documentos de natureza trabalhista e previdenciária que
                  interessar à CELEBRAR e/ou seus Clientes.
                </li>
              </ul>
            </li>
            <li>
              XXV. Autorizar formalmente, se necessário, que a CELEBRAR ou seus Clientes retenha,
              para garantir eventuais pagamentos a que for obrigada para com os funcionários do{' '}
              <b>FORNECEDOR</b> ou para se ressarcir de valores a que tenha direito por força deste
              CONTRATO, qualquer valor que ainda tenha direito o <b>FORNECEDOR</b> a receber;
            </li>
            <li>
              XIX. Independente do disposto no item acima, a CELEBRAR reterá os tributos necessários
              que, por lei, forem definidos como de sua competência, incluindo, mas não se limitando
              aos 11% (onze por cento) do INSS;
            </li>
            <li>XX. Arcar com os custos de alimentação e transporte do seu pessoal;</li>
            <li>
              XXI. Refazer ou corrigir, às suas expensas, os Serviços que tenham sido prestados pela
              mesma com erro ou imperfeição técnica, respondendo, ainda, por eventuais falhas e/ou
              erros na execução das atividades constatados no prazo de até 01 (um) ano após a
              conclusão dos Serviços e mediante expressa comunicação da CELEBRAR e/ou de seus
              Clientes à <b>FORNECEDOR</b>. A irregularidade deverá ser sanada no prazo máximo de 03
              (três) meses. Na impossibilidade da correção, o <b>FORNECEDOR</b> deverá indenizar a
              CELEBRAR e/ou seus Clientes pelo dobro do valor correspondente a ditos reparos;
            </li>
            <li>
              XXII.Dar a correta destinação dos resíduos gerados por meio da prestação de Serviços,
              objeto do presente instrumento, devendo cumprir as determinações e indicações de
              locais oferecidos pela CELEBRAR e, ao final da prestação de Serviços, realizar o
              descarte dos detritos em conformidade com a legislação ambiental e demais normas
              correlatas aplicáveis;
            </li>
            <li>XXIII. Observar os regimentos e normas internas da CELEBRAR e de seus Clientes;</li>

            <li>
              III. Fornecer informações claras e precisas acerca dos serviços, bem como as condições
              de prestação dos serviços que serão disponibilizados para venda na Plataforma da
              Celebrar;
            </li>
            <li>
              V. Providenciar e responsabilizar-se pela entrega dos produtos ao Cliente da Celebrar;
            </li>
            <li>
              IX. Fornecer informações claras e precisas acerca dos produtos que serão
              disponibilizados para venda na Plataforma da Celebrar;
            </li>
            <li>
              XIV. Fornecer, acompanhado dos respectivos documentos de cobrança oriundos deste
              CONTRATO, relatório detalhado sobre todas as atividades por ela desenvolvidas na
              prestação dos Serviços, incluindo informações quanto aos contatos realizados,
              notadamente com fornecedores e clientes, e quanto às operações geradas, incluindo os
              respectivos custos e resultados;
            </li>
            <li>
              XIX. Manter a CELEBRAR isenta de qualquer atuação, notificação, intimação, reclamação
              e/ou condenação decorrente da reparação dos danos causados aos consumidores por
              defeitos relativos à prestação dos serviços ofertados, bem como por informações
              insuficientes ou inadequadas sobre sua fruição e riscos;
            </li>
            <li>
              XXII. Utilizar, na execução dos Serviços sob tutela do presente CONTRATO, somente
              empregados em situação regular quanto ao vínculo empregatício;
            </li>
            <li>
              XXIII. Isentar e manter a CELEBRAR e seus Clientes livre de qualquer reclamação ou
              punição, resultante da inobservância das suas obrigações e deveres para com seus
              prepostos e/ou funcionários, contratados ou subcontratados, cumprindo o pontual
              recolhimento das contribuições dos tributos incidentes sobre a prestação de Serviços
              que for de sua competência tributária;
            </li>
            <li>
              XXVI. Oferecer somente produtos e/ou serviços que estejam adequados e enquadrados nas
              atividades econômicas registradas no Cadastro Nacional de Pessoas Jurídicas;
            </li>
          </ul>

          <p>
            7.2. O <b>FORNECEDOR</b> garante que oferecerá treinamento à <b>CELEBRAR</b> e seus
            Clientes, sem ônus, caso for necessário para a operação dos Serviços, objeto do
            CONTRATO.
          </p>

          <p>
            7.3. Sem prejuízo das demais obrigações acima mencionadas, o <b>FORNECEDOR</b> declara e
            garante que:
          </p>
          <ul>
            <li>
              a) É sociedade legalmente constituída e existente de acordo com a lei brasileira, está
              devidamente credenciada e habilitada para exercer o ramo de sua atividade, assim como
              o pessoal alocado para o cumprimento do objeto deste CONTRATO, e está em situação
              regular, de acordo com a legislação aplicável, inclusive no que se refere às
              necessárias licenças e autorizações.
            </li>
            <li>
              b) Possui, em seu nome, todas as autorizações, licenças, aprovações, certificados,
              permissões e autorizações estaduais, federais e municipais, materialmente exigidos por
              lei para prestar, viabilizar e/ou permitir a prestação dos Serviços objeto do presente
              CONTRATO.
            </li>
            <li>
              c) Está absolutamente em dia com todas as suas obrigações, notadamente com aquelas de
              natureza fiscal, previdenciária e trabalhista.
            </li>
            <li>
              d) Tem capacidade para celebrar o presente CONTRATO e o faz, neste ato, de forma
              consciente, sem qualquer coação e/ou vício de consentimento, bem como, tem capacidade
              técnica, operacional, logística, comercial e financeira já instaladas e todas ora
              suficientes para suportar a finalidade e os efeitos do presente CONTRATO,
              comprometendo-se a cumpri-lo em sua integralidade.
            </li>
            <li>
              e) Inexiste qualquer restrição administrativa, contratual ou judicial, notadamente por
              razões de proteção ambiental e de zoneamento urbano, que se aplique diretamente ao
              exercício do CONTRATO pelo <b>FORNECEDOR</b>.
            </li>
            <li>
              f) A celebração do presente CONTRATO e o cumprimento pelo <b>FORNECEDOR</b> das
              obrigações aqui previstas não são conflitantes com qualquer contrato com o qual o{' '}
              <b>FORNECEDOR</b> esteja vinculada, nem resultarão em qualquer violação de tais
              contratos, conflito ou violação que afetem, significativa e adversamente, a capacidade
              do <b>FORNECEDOR</b> de cumprir suas obrigações, nos termos do presente CONTRATO.
            </li>
          </ul>

          <p>
            7.4. As Partes concordam que a CELEBRAR fica isenta de qualquer atuação, notificação,
            intimação, reclamação e/ou condenação decorrente da Intermediação objeto deste Contrato.
          </p>

          <p>
            7.5. O <b>FORNECEDOR</b> fica expressamente proibido de fornecer qualquer produto ou
            serviço anunciados na Plataforma da Celebrar para Comprador ou Compradores que tenham
            tomado conhecimento do <b>FORNECEDOR</b> e/ou que tenham adquirido produtos e/ou
            serviços do <b>FORNECEDOR</b> pela Plataforma da Celebrar e/ou aplicativo da CELEBRAR.
          </p>

          <p>
            7.5.1. Caso a CELEBRAR tome conhecimento do descumprimento do item 6.5 por parte do{' '}
            <b>FORNECEDOR</b>, a CELEBRAR, a seu exclusivo critério poderá aplicar uma multa de duas
            vezes o valor do produto e/ou serviço vendido pelo <b>FORNECEDOR</b> for da Plataforma
            da Celebrar e/ou aplicativo da CELEBRAR, bem como excluir o Cliente e seus produtos e/ou
            serviços da Plataforma da Celebrar e do aplicativo.
          </p>

          <p>
            7.5.2. O <b>FORNECEDOR</b> informará à CELEBRAR, no prazo máximo de 24 (vinte e quatro)
            horas, acerca de qualquer fato, ato, omissão ou evento que possa de forma material e
            adversa, direta ou indiretamente, afetar os negócios relacionados ao CONTRATO ou à
            implementação das operações previstas neste CONTRATO.
          </p>
        </section>

        <section ref={section8ref}>
          <h2>8. SUBCONTRATAÇÃO</h2>

          <p>
            8.1.{' '}
            <b>
              A SUBCONTRATAÇÃO DOS SERVIÇOS OBJETOS DESTE CONTRATO PELO FORNECEDOR É TERMINANTEMENTE
              PROIBIDA
            </b>
            , exceto quando houver autorização prévia, expressa e por escrito da CELEBRAR.
          </p>

          <p>
            8.1.1. A violação do item anterior autoriza a <b>CELEBRAR</b> aplicar multa de
            R$50.000,00 (cinquenta mil reais), sem prejuízo da cobrança de indenização caso o
            prejuízo seja superior ao valor estabelecido, nos termos do art. 416, parágrafo único,
            do Código Civil.
          </p>

          <p>
            8.2. Tal aprovação não desonera, porém, em nenhuma hipótese, o <b>FORNECEDOR</b> de
            qualquer de suas obrigações ou responsabilidades decorrentes deste CONTRATO, ficando e
            permanecendo completa e solidariamente responsável por todos os Serviços executados e
            quaisquer dos subcontratados, como se os Serviços executados por estes últimos tivessem
            sido realizados diretamente pelo <b>FORNECEDOR</b>, nos termos dos artigos 265¹ e 942,
            parágrafo único², ambos do Código Civil.
          </p>

          <aside>
            <p>
              ¹ Art. 265. A solidariedade não se presume; resulta da lei ou da vontade das partes.
            </p>
            <p>
              ² Art. 942. Os bens do responsável pela ofensa ou violação do direito de outrem ficam
              sujeitos à reparação do dano causado; e, se a ofensa tiver mais de um autor, todos
              responderão solidariamente pela reparação. Parágrafo único. São solidariamente
              responsáveis com os autores os co-autores e as pessoas designadas no art. 932.
            </p>
          </aside>

          <p>
            8.3. Para a subcontratação de atividades que <b>FORNECEDOR</b> deseja fornecer a
            clientes da <b>CELEBRAR</b>, o <b>FORNECEDOR</b> se compromete a apresentar à{' '}
            <b>CELEBRAR</b>, os seguintes documentos da empresa a ser subcontratada:
          </p>
          <ul>
            <li>a) Proposta comercial;</li>
            <li>b) Certidões de regularidade perante os órgãos fiscalizadores;</li>
            <li>
              c) Documentação securitária, incluindo a apólice, cuja cobertura deverá ser igual ou
              superior ao valor do serviço prestado, contemplando cobertura para a responsabilidade
              de terceiros.
            </li>
          </ul>

          <p>
            8.4. O <b>FORNECEDOR</b> declara que toda a relação com terceiros, objeto de qualquer
            subcontratação, será devidamente formalizada através da assinatura de contratos
            específicos, o qual será apresentado sempre que solicitado pela <b>CELEBRAR</b>.
          </p>

          <p>
            8.5. Fica desde já certo e estabelecido que toda e qualquer subcontratação deverá ser
            antecedida de <b>prévia submissão à avaliação da CELEBRAR</b>, principalmente no que
            tange ao objeto e capital social para a garantia do cumprimento integral da avença ora
            estabelecida.
          </p>

          <p>
            8.6. Na ocorrência de faturamento direto, ou seja, a CELEBRAR realizar o pagamento
            diretamente ao subcontratado, o <b>FORNECEDOR</b> deverá, detalhar previamente, em até
            24 horas da contratação do Serviço por qualquer Cliente, e por escrito toda a sua
            composição do preço relativa ao objeto deste CONTRATO.
          </p>
        </section>

        <section ref={section9ref}>
          <h2>9. OBRIGAÇÕES DA CELEBRAR</h2>

          <p>
            9.1. A <b>CELEBRAR</b> obriga-se a: (i) cumprir com o disposto neste Contrato e na
            legislação vigente; (ii) realizar o pagamento pelo fornecimento de produtos e serviços
            realizados pelo <b>FORNECEDOR</b> em integral cumprimento aos termos deste Contrato.
          </p>
        </section>

        <section ref={section10ref}>
          <h2>10. REMUNERAÇÃO DO FORNECEDOR E DA CELEBRAR</h2>

          <p>
            10.1. O pagamento pelos produtos e serviços oferecidos pelo <b>FORNECEDOR</b> na
            Plataforma Celebrar serão realizados diretamente ao <b>FORNECEDOR</b> e somente serão
            realizados após a execução do fornecimento dos produtos e/ou serviços pelo{' '}
            <b>FORNECEDOR</b>, bem como após o recebimento e aceitação da nota fiscal referente a
            tal fornecimento.
          </p>

          <p>
            10.2. A <b>CELEBRAR</b> somente efetuará pagamentos por meio de transferência bancária à
            <b>FORNECEDOR</b>, sendo de inteira responsabilidade do <b>FORNECEDOR</b> o fornecimento
            correto dos dados bancários para pagamento. O comprovante de transferência de pagamento
            para a conta bancária do <b>FORNECEDOR</b> servirá como comprovante de quitação.
          </p>

          <p>
            10.3. O <b>FORNECEDOR</b> fica terminantemente proibido de emitir boletos contra a{' '}
            <b>CELEBRAR</b>, ficando essa absolutamente obrigada a cancelar qualquer boleto
            porventura emitido e arcar com todo e qualquer custo decorrente deste cancelamento.
          </p>

          <p>
            10.4. Todos os serviços prestados pela <b>CELEBRAR</b> ao <b>FORNECEDOR</b> serão
            remunerados de acordo com as tarifas e/ou preços negociados e aceitos pelo{' '}
            <b>FORNECEDOR</b> (“ Remuneração ”), de acordo com as condições e valores estabelecidos
            de comum acordo entre as Partes disponibilizadas pela <b>CELEBRAR</b> em sua Plataforma
            Celebrar, ou por meio de representantes e parceiros da CELEBRAR.
          </p>
          <p>
            10.4.1. A Remuneração em favor da <b>CELEBRAR</b> será sempre retida dos valores
            eventualmente recebidos do Cliente pelo serviço realizado pelo <b>FORNECEDOR</b>{' '}
            contratado via Plataforma Celebrar.
          </p>
          <p>
            10.4.2. Além da taxa de serviço da <b>CELEBRAR</b>, será repassado ao <b>FORNECEDOR</b>{' '}
            a tarifa de transferência da remuneração, conforme descrito a seguir:
            <ul>
              <li>
                <b>Transferência para outros bancos:</b> R$ 3,99 por TED enviada
              </li>
              <li>
                <b>Transferência via chave Pix:</b> R$ 0,50 por transferência
              </li>
            </ul>
          </p>

          <p>
            10.4.3. A <b>CELEBRAR</b> poderá efetuar reajuste dos valores de sua Remuneração e/ou a
            forma de Remuneração, mediante aviso prévio ao <b>FORNECEDOR</b>, por e-mail ou
            divulgação prévia na Plataforma Celebrar.
          </p>

          <p>
            10.4.4. Caso o <b>FORNECEDOR</b> não concorde com as novas condições de Remuneração,
            este poderá solicitar esclarecimentos adicionais e/ou o encerramento da relação
            contratual estabelecida por meio do presente Contrato, sem qualquer tipo de ônus. O
            silêncio do <b>FORNECEDOR</b> e/ou a manutenção do Contrato pelo <b>FORNECEDOR</b> será
            interpretado como plena anuência aos novos valores.
          </p>
          <p>
            10.5. Eventual atraso no pagamento de qualquer quantia devida total ou parcialmente pelo{' '}
            <b>FORNECEDOR</b> à <b>CELEBRAR</b> implicará multa moratória de 2% (dois por cento),
            incidente sobre o valor devido e não pago, acrescido de juros de mora de 1% (um por
            cento) ao mês e de atualização monetária com base no IPC/FGV, calculados pro rata die,
            ou na falta deste, por outro índice que legalmente o substitua.
          </p>
        </section>

        <section ref={section11ref}>
          <h2>11. CASO FORTUITO E FORÇA MAIOR</h2>

          <p>
            11.1. Não caberá responsabilidade a qualquer das Partes pelo não cumprimento de suas
            obrigações contratuais, no todo ou em parte, em decorrência de caso fortuito ou força
            maior que impeça, temporária ou definitivamente, o cumprimento de quaisquer obrigações,
            nos termos do artigo 393³, do Código Civil.
          </p>
          <aside>
            <p>
              ³ Art. 393. O devedor não responde pelos prejuízos resultantes de caso fortuito ou
              força maior, se expressamente não se houver por eles responsabilizado.
            </p>
          </aside>

          <p>
            11.2. A Parte que ficar temporariamente impossibilitada de cumprir com suas obrigações,
            no todo ou em parte, em decorrência de caso fortuito ou força maior, deverá comunicar
            este fato prontamente à outra Parte e ratificar, por escrito, a comunicação em até 02
            (dois) dias úteis, informando os efeitos danosos do evento e as medidas que pretende
            tomar para superá-lo.
          </p>

          <p>
            11.3. Constatada a ocorrência de caso fortuito ou de força maior, ficarão suspensas,
            enquanto perdurar o evento, as obrigações que as Partes ficarem impedidas de cumprir,
            comprometendo-se a retomar tão logo cesse a ocorrência em questão.
          </p>

          <p>
            11.4. Não serão considerados eventos de caso fortuito ou de força maior as paralisações
            decorrentes de greves e congêneres por exclusiva, notória e inquestionável
            responsabilidade dos <b>FORNECEDORES</b>, tais como as greves que resultem da falta de
            pagamento dos empregados por parte dos <b>FORNECEDORES</b>.
          </p>

          <p>
            11.5. Se o evento de caso fortuito ou de força maior se prolongar por mais de 60
            (sessenta) dias, contados da data de sua ocorrência, qualquer das Partes terá o direito
            de rescindir o presente Contrato, sem a imposição de qualquer ônus.
          </p>
        </section>

        <section ref={section12ref}>
          <h2>12. DENÚNCIA E RESCISÃO</h2>

          <p>
            12.1. Qualquer uma das partes poderá denunciar este CONTRATO, a qualquer tempo, e sem
            qualquer ônus, seja de que natureza for, mediante notificação neste sentido, a ser
            enviado por escrito à outra parte, com 10 (dez) dias de antecedência.
          </p>

          <p>
            12.1.1. Decorrido o prazo previsto no item 12.1, o contrato será considerado resilido e
            encerrado.
          </p>

          <p>
            12.2. O presente Contrato será considerado rescindido de pleno direito,
            independentemente de notificação judicial ou extrajudicial, na hipótese de
            descumprimento de qualquer condição aqui estabelecida não remediada após 10 (dez) dias
            do recebimento da notificação identificando tal falha.
          </p>

          <p>
            12.3. A rescisão do Contrato, por qualquer motivo que seja, não afasta ou extingue a
            obrigação do <b>FORNECEDOR</b> cumprir com o fornecimento de produtos e/ou serviços
            referentes a todos os orçamentos enviados pelo <b>FORNECEDOR</b> e aceitos por Clientes.
          </p>

          <p>
            12.4. A rescisão do Contrato, por qualquer motivo que seja, não afasta ou extingue a
            obrigação do <b>FORNECEDOR</b> cumprir com o pagamento de multas, custos e prejuízos
            porventura incorridos por força deste Contrato e/ou da execução do fornecimento de
            Produtos e/ou serviços a Clientes da Celebrar.
          </p>

          <p>
            12.5. As obrigações previstas nas cláusulas 5, 18, 19, 21 e 22 sobrevivem à rescisão ou
            extinção deste Contrato, permanecendo em vigor até o estrito e completo cumprimento pelo{' '}
            <b>FORNECEDOR</b> de qualquer obrigação ou garantia prevista nas referidas cláusulas.
          </p>
        </section>

        <section ref={section13ref}>
          <h2>13. QUESTÕES SECURITÁRIAS</h2>

          <p>
            13.1. O <b>FORNECEDOR</b> se compromete a adquirir seguro que cubra, dentre outras
            avenças aplicáveis, as questões relativas ao transporte e responsabilidade civil por
            danos, a fim de que a CELEBRAR não seja prejudicada por quaisquer danos causados pelo{' '}
            <b>FORNECEDOR</b> à CELEBRAR e/ou a seus Clientes.
          </p>

          <p>
            13.2. O <b>FORNECEDOR</b> se obriga a manter seguro com apólice que, no mínimo, cubra os
            valores totais da obrigação assumida, bem como os respectivos riscos. Fica desde já
            certo e estabelecido que a manutenção de apólice de seguro pelo <b>FORNECEDOR</b> não a
            eximirá de qualquer responsabilidade, inclusive no que se refere a valores e/ou
            coberturas que eventualmente superarem aqueles abrangidos pela apólice de seguro por ela
            contratada.
          </p>

          <p>
            13.3. O <b>FORNECEDOR</b>, sob pena de inadimplência no presente instrumento,
            compromete-se a apresentar cópia da apólice de seguro contratada, bem como suas
            eventuais e sucessivas renovações.
          </p>
        </section>

        <section ref={section14ref}>
          <h2>14. DIREITO DE FISCALIZAÇÃO</h2>

          <p>
            14.1. Todas as obrigações previstas neste Contrato poderão ser inspecionadas pela
            CELEBRAR, devidamente acompanhadas por um preposto nomeado pelo FORNECEDOR para este
            fim.
          </p>
          <p>
            14.1.1. Não serão aceitos como Serviços executados, ou como fase concluída, aqueles que
            não estiverem completos na data prevista para sua finalização.
          </p>
          <p>
            14.1.2. A inspeção e a fiscalização dos Serviços pela CELEBRAR não exime o{' '}
            <b>FORNECEDOR</b> de qualquer responsabilidade pela qualidade do trabalho executado.
          </p>
          <p>
            14.1.3. A CELEBRAR poderá, a seu critério: (a) recusar ou sustar qualquer execução de
            Serviço em desacordo com as disposições previstas neste CONTRATO, ou que atente contra a
            segurança pessoal ou dos bens da CELEBRAR e/ou de terceiros; (b) sustar o pagamento
            total ou parcial de qualquer fatura no caso de inobservância das condições previstas
            neste instrumento ou de obrigações legais, bastando uma mera comunicação por escrito da
            CELEBRAR à <b>FORNECEDOR</b>, fundamentando a causa da suspensão.
          </p>
        </section>

        <section ref={section15ref}>
          <h2>15. DESVINCULAÇÃO TRABALHISTA</h2>

          <p>
            15.1. O presente <b>CONTRATO</b> tem natureza civil, não criando qualquer vínculo
            empregatício, representação comercial, “joint venture” ou sociedade entre a CELEBRAR, os{' '}
            <b>FORNECEDORES</b> e o pessoal utilizado, empregado/subcontratado ou terceiro
            relacionado ao <b>FORNECEDOR</b>, cabendo à última, todas as responsabilidades
            trabalhistas, securitárias, previdenciárias e fiscais, inclusive aquelas oriundas de
            modificações na legislação em vigor, concernente aos seus empregados e/ou subcontratados
            envolvidos na execução do presente CONTRATO, vinculados direta ou indiretamente ao{' '}
            <b>FORNECEDOR</b>, devendo o <b>FORNECEDOR</b> reembolsar a CELEBRAR e/ou os Clientes,
            no prazo de 10 (dez) dias úteis, de quaisquer despesas que esta tenha sido obrigada a
            desembolsar em decorrência de reclamações trabalhistas, ações judiciais diversas e
            processos administrativos, de qualquer natureza, inclusive os relativos a acidente do
            trabalho, promovidos pelas pessoas mencionadas nesta Cláusula, devendo todos os valores
            ser devidamente corrigidos com base no IGP-M, desde a data do desembolso pela CELEBRAR
            e/ou os Clientes até a data do pagamento pelo <b>FORNECEDOR</b>.
          </p>

          <p>
            15.2. O <b>FORNECEDOR</b> deverá, quando for o caso, atender expressamente ao quanto
            contido na legislação previdenciária, obrigando-se a encaminhar suas Notas
            Fiscais/Faturas com o devido destaque dos valores de mão-de-obra e materiais,
            viabilizando a CELEBRAR e/ou os Clientes efetuar o recolhimento previdenciário previsto
            na legislação pertinente.
          </p>

          <p>
            15.3. A imprecisão, erro e/ou inverdade averiguados nas informações prestadas pelo{' '}
            <b>FORNECEDOR</b>, relativas à legislação aplicável, que ocasionem autuações e/ou ações
            judiciais contra a CELEBRAR e/ou os Clientes, serão de integral responsabilidade do{' '}
            <b>FORNECEDOR</b>, que se obriga a ressarcir à CELEBRAR e/ou os Clientes toda e qualquer
            importância eventualmente por ela despendida em decorrência dessas ocorrências, no prazo
            de 10 (dez) dias da comunicação da CELEBRAR e/ou dos Clientes.
          </p>
        </section>

        <section ref={section16ref}>
          <h2>16. SIGILO E CONFIDENCIALIDADE</h2>

          <p>
            16.1. As Partes reconhecem que todas as informações transferidas ou divulgadas por força
            deste Contrato ("
            <b>
              <u>Informações Confidenciais</u>
            </b>
            ") têm valor comercial e por isso receberão tratamento confidencial. As Partes se
            comprometem por seus administradores, empregados e contratados a não divulgar a
            quaisquer terceiros as Informações Confidenciais.
          </p>

          <p>
            16.2. As Partes obrigam-se, ainda, a dar ciência a seus prepostos e seus empregados do
            caráter sigiloso das Informações Confidenciais, bem como responder pelas consequências
            advindas da inobservância por estes da obrigação de sigilo ora estabelecida.
          </p>

          <p>
            16.3. A obrigação do tratamento confidencial acima prevista não se aplicará nas
            hipóteses em que as Informações Confidenciais:
          </p>
          <ul>
            <li>a) já forem anteriormente e comprovadamente conhecidas pela Parte;</li>
            <li>
              b) venham a ser comprovadamente recebidas de terceiro legalmente autorizado ou outras
              fontes, sem qualquer violação de regra legal ou contratual, pela Parte;
            </li>
            <li>
              c) tenham sido expressamente excluídas do escopo da obrigação de confidencialidade
              pela Parte Contrária;
            </li>
            <li>
              d) tenham sido comprovadamente divulgadas por terceiros, sem restrições similares;
            </li>
            <li>
              e) devam ser divulgadas por força de autoridade judicial ou administrativa competente,
              sendo certo que em tal hipótese a divulgação deverá ser feita exclusiva e
              necessariamente nos termos e limites da ordem recebida; e
            </li>
            <li>
              f) forem de domínio público, ou vierem a se tornar de domínio público, sem qualquer
              violação dos termos deste Contrato.
            </li>
          </ul>
        </section>

        <section ref={section17ref}>
          <h2>17. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</h2>

          <p>
            17.1. O uso comercial da expressão CELEBRAR como marca, nome empresarial ou nome de
            domínio, bem como os conteúdos das telas do Plataforma Celebrar, assim como os
            programas, bancos de dados, redes, arquivos que permitem que o <b>FORNECEDOR</b> acesse
            e use através de sua conta são de propriedade da CELEBRAR e estão protegidos pelas leis
            e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos
            industriais, sendo vedado o uso pelo <b>FORNECEDOR</b>, salvo mediante prévia
            autorização por escrito.
          </p>

          <p>
            17.2. Ao acessar a página da CELEBRAR (www.celebrar.com.br e www.celebrar.co), o{' '}
            <b>FORNECEDOR</b> declara que irá respeitar todos os direitos de propriedade intelectual
            e os decorrentes da proteção de marcas registradas da mesma, bem como de todos os
            direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma,
            disponíveis no Plataforma Celebrar. O simples acesso ao Plataforma Celebrar não confere
            ao Usuário qualquer direito de uso dos nomes, títulos, palavras, frases, marcas,
            patentes, obras literárias, artísticas, lítero-musicais, imagens, vídeos, símbolos,
            entre outras, que nele estejam, ou estiveram, disponíveis; muito menos dos{' '}
            <b>FORNECEDORES</b> relacionados no Plataforma Celebrar.
          </p>

          <p>
            17.3. A reprodução dos conteúdos descritos anteriormente está proibida, salvo mediante
            prévia autorização por escrito da CELEBRAR.
          </p>

          <p>
            17.4. É permitido fazer somente o arquivo temporário do Plataforma Celebrar, sendo
            vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que
            contrarie a realidade para o qual foi concebido, conforme definido neste Termo. São
            igualmente proibidas a reprodução, distribuição, divulgação, adaptação, modificação,
            utilização, edição, disponibilização em qualquer meio de comunicação etc., total ou
            parcial, dos textos, figuras, gráficos que compõem o presente Plataforma, sem prévia e
            expressa autorização da CELEBRAR, sendo permitida somente a impressão de cópias para uso
            e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real
            entendimento de seu conteúdo e objetivo.
          </p>

          <p>
            17.5. Qualquer outro tipo de utilização de material autorizado, inclusive para fins
            editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso
            consentimento da CELEBRAR e respectivos <b>FORNECEDOR</b> presentes na Plataforma
            Celebrar.
          </p>

          <p>
            17.6. O <b>FORNECEDOR</b> assume toda e qualquer responsabilidade pela utilização
            indevida das informações, textos, gráficos, marcas, obras, imagens, vídeos, enfim, de
            todo e qualquer direito de propriedade intelectual ou industrial da Plataforma Celebrar
            ou de seus <b>FORNECEDORES</b>, tanto de caráter civil quanto criminal.
          </p>

          <p>
            17.7. Qualquer reutilização do material autorizado deverá ser objeto de uma nova
            autorização da CELEBRAR. Seus <b>FORNECEDORES</b> possuem orientações próprias,
            portanto, o interessado deverá conhecer as políticas de uso de material protegido por
            propriedade intelectual deste.
          </p>

          <p>
            17.8. A autorização para utilização do material solicitado não poderá ser transferida a
            terceiros, mesmo que vinculados à <b>FORNECEDOR</b> por alguma razão, salvo se mediante
            prévia autorização por escrito da CELEBRAR ou de seus outros <b>FORNECEDORES</b>,
            conforme o caso. A utilização do material não autoriza o <b>FORNECEDOR</b> a expor
            terceiros ao ridículo, criar uma obra de caráter ilegal, difamatória, obscena ou imoral,
            que possa violar a ética, a moral e os bons costumes, sob pena de arcar com as
            penalidades aplicáveis pela legislação vigente.
          </p>

          <p>
            17.9. Qualquer utilização não contemplada na presente autorização será considerada como
            uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei 9.610, de 19 de
            fevereiro de 1998, que protege os direitos autorais no Brasil.
          </p>

          <p>
            17.10. A eventual retirada de qualquer anúncio, artigo, vídeo, notícia ou fotografia
            reproduzido pelo Plataforma Celebrar em decorrência de alguma reclamação, deverá ser
            sempre compreendida como uma demonstração de nossa intenção de evitar pendências com
            relação a este assunto e, jamais, como reconhecimento de qualquer infração pela CELEBRAR
            a direito de terceiro ou de possuir culpa sobre o ocorrido.
          </p>

          <p>
            17.11. As fotos e imagens utilizadas no Plataforma Celebrar podem não refletir seu
            tamanho original ou situação atual do cenário reproduzido, sendo meramente ilustrativas.
          </p>

          <p>
            17.12. O <b>FORNECEDOR</b>, na execução do objeto do CONTRATO se obriga a não infringir
            quaisquer direitos de propriedade intelectual, dentre eles, sem a estes ser limitados a
            marcas, patentes, segredo industrial ou qualquer direito autoral.
          </p>

          <p>
            17.13. O <b>FORNECEDOR</b> concorda:
          </p>
          <ul>
            <li>
              a) em defender, isentar e indenizar a CELEBRAR de quaisquer reclamações por violação
              de direitos de propriedade intelectual, dentre eles, patentes, marcas, direitos
              autorais, desenhos industriais, ou pela má utilização ou apropriação indébita de
              segredo industrial e por danos e despesas incorridos (incluindo honorários
              advocatícios razoáveis e outros honorários profissionais), de qualquer forma
              relacionados aos Serviços objeto deste CONTRATO;
            </li>
            <li>
              b) que tendo este CONTRATO, por objeto, a criação de obra/trabalho sujeito às
              disposições que regem os direitos autorais ou a propriedade intelectual, o resultado
              de tal criação será considerado “
              <b>
                <u>obra por encomenda</u>
              </b>
              ”, na forma definida na legislação vigente, sendo certo que o valor devido pela
              CELEBRAR à <b>FORNECEDOR</b>, no tocante à transferência definitiva destes direitos,
              já se encontram contemplados no preço total aqui definido. Havendo qualquer
              impedimento para que tal resultado seja classificado como obra por encomenda, o{' '}
              <b>FORNECEDOR</b>, desde já, cede à CELEBRAR todos os direitos, a titularidade e a
              participação nos direitos autorais, morais, de propriedade industrial, ou de qualquer
              outra natureza, que recaiam ou venham a recair sobre o referido resultado, sem
              quaisquer ônus adicionais aos valores pagos pela CELEBRAR, tendo em vista a proposta
              comercial.
            </li>
          </ul>

          <p>
            17.14. Será de exclusiva responsabilidade do <b>FORNECEDOR</b> o controle dos softwares
            eventualmente utilizados na execução deste CONTRATO. A CELEBRAR não será
            responsabilizada por quaisquer violações de direito autoral, incluindo desrespeito à
            propriedade intelectual de softwares, que vierem a ser cometidas pelo <b>FORNECEDOR</b>,
            ou por qualquer de seus empregados ou subcontratados. O <b>FORNECEDOR</b> (a)
            compromete-se a isentar a CELEBRAR de qualquer responsabilidade em reclamação ou em ação
            judicial que venha a ser movida contra a CELEBRAR, em decorrência da violação dos
            direitos acima previstos; (b) obriga-se a arcar e/ou reembolsar à CELEBRAR quaisquer
            custos e/ou despesas judiciais e/ou extrajudiciais, inclusive honorários advocatícios,
            sem prejuízo de responder por eventuais perdas e danos decorrentes do evento.
          </p>
        </section>

        <section ref={section18ref}>
          <h2>18. DO DIREITO DE IMAGEM</h2>

          <p>
            18.1. O FORNECEDOR autoriza a utilização de sua imagem, som da voz e/ou seu nome pela
            CELEBRAR para fins publicitários e/ou comerciais, de maneira gratuita, não onerosa e por
            prazo indeterminado.
          </p>

          <p>
            18.1.1. A CELEBRAR poderá praticar os seguintes atos relacionados com a imagem, som da
            voz e/ou nome dos <b>FORNECEDORES</b>, seus funcionários, sócios e/ou qualquer equipe
            que estiver presente em eventos dos Clientes da CELEBRAR:
          </p>
          <ul>
            <li>
              a) Captar por intermédio de qualquer mecanismo ou meio tecnológico disponível para
              tanto;
            </li>
            <li>
              b) Editar, reeditar, tratar, modificar, alterar, recortar, compilar, agrupar ou de
              qualquer modo complementar o conteúdo captado;
            </li>
            <li>
              c) Transferir, migrar, deslocar, alterar ou de qualquer forma mudar o formato ou
              extensão do suporte ao qual o conteúdo tenha sido capturado;
            </li>
            <li>
              d) Transmitir o suporte que contenha conteúdo autorizado através de qualquer meio,
              seja eletrônico, digital, magnético, fibra ótica ou qualquer outro que venha ser
              inventado;
            </li>
            <li>
              e) Armazenar, agrupar ou de qualquer forma organizar o suporte em que esteja inserido
              o conteúdo autorizado, seja em banco de dados, servidores internos, externos, de
              maneira integral ou fracionada;
            </li>
            <li>
              f) Utilizar, reproduzir, veicular, publicar, compartilhar ou distribuir em mídia
              impressa ou digital, em formato físico, em mídias de comunicação de massa e/ou pela
              Internet, podendo o conteúdo autorizado ser disponibilizado em mídias sociais, sites
              de compartilhamento de imagens, vídeos ou de arquivo de som, seja através de
              aplicativos, arquivos executáveis, editáveis ou não, wallpapers (papel de parede de
              computador, tablete, smartphone e celular), seja por intermédio de computadores
              pessoais, celulares, smartphones, tablets, laptops ou qualquer outro dispositivo que
              possa reproduzir, armazenar, compartilhar, editar ou receber tal conteúdo.{' '}
            </li>
          </ul>

          <p>
            18.2. A CELEBRAR declara que estão ressalvados os direitos dos <b>FORNECEDORES</b> sobre
            a integridade da sua honra, reputação, boa fama ou a respeitabilidade, sendo que o uso
            de sua imagem, som da voz ou nome devem ser feitos apenas nos limites acordados neste
            Termo e da legislação nacional vigente.
          </p>

          <p>
            18.3. Os <b>FORNECEDORES</b> eximem, por meio deste CONTRATO, a CELEBRAR de qualquer
            responsabilidade pelo uso indevido de sua imagem, som da voz ou nome por terceiros
            devido a possibilidade de compartilhamento de conteúdo em ambientes de terceiros,
            trazida pelas novas tecnologias e pela Internet.
          </p>

          <p>
            18.4. Não serão devidos às FORNECEDORES nenhum valor adicional a título de royalties,
            licenciamento ou qualquer outra remuneração além daquelas já acordadas no presente Termo
            em razão da transferência da Propriedade Intelectual.
          </p>
        </section>

        <section ref={section19ref}>
          <h2>19. LIMITE DE GARANTIA E DE RESPONSABILIDADE DA CELEBRAR</h2>

          <p>
            19.1. No caso de cancelamento de orçamento a pedido do Cliente, a operação será anulada
            e eventuais reembolsos, multas e outros valores serão repassados de acordo com as regras
            deste Contrato.
          </p>

          <p>
            19.2. A CELEBRAR não se responsabilizará por qualquer prejuízo causado pelo{' '}
            <b>FORNECEDOR</b>, a qualquer terceiro, sendo que, em caso a CELEBRAR seja envolvida por
            terceiros em discussões judiciais ou extrajudiciais, o <b>FORNECEDOR</b> envidará seus
            melhores esforços para remover a CELEBRAR das discussões e arcará com todo e qualquer
            custo incorrido pela CELEBRAR em decorrência do seu envolvimento, incluindo, mas não se
            limitando a honorários advocatícios, condenações judiciais, custas judiciais etc.
          </p>
        </section>

        <section ref={section20ref}>
          <h2>20. CONDIÇÕES DE SUSTENTABILIDADE E CIDADANIA</h2>

          <p>
            20.1. As PARTES se comprometem a cumprir todas as leis, normas e demais cominações
            legais relativas à política nacional do meio ambiente, proferidas em esfera executiva,
            legislativa ou administrativa, em âmbito federal, estadual ou municipal, notadamente às
            práticas da utilização sustentável de recursos de origem legal e o gerenciamento e
            destinação correta de resíduos.
          </p>

          <p>
            20.2. As PARTES se comprometem a cumprir todas as imposições legais relativas à
            segurança e medicina do trabalho, assim como as convenções e acordos trabalhistas e
            sindicais aplicáveis a seus colaboradores.
          </p>

          <p>
            20.3. As PARTES se obrigam a não contratar mão-de-obra que utilize a exploração de
            trabalho forçado; utilizar trabalhadores em condições análogas à de escravo; utilizar
            trabalho realizado por crianças menores de dezesseis anos de idade, salvo na condição de
            aprendiz, a partir dos catorze anos de idade; e não empregar trabalhadores com menos de
            dezoito anos em locais prejudiciais à sua formação e desenvolvimento, assim como locais
            de serviços perigosos e/ou insalubres, trabalho noturno (horário compreendido entre às
            22h e 5h).
          </p>

          <p>
            20.4. As PARTES, em nenhuma hipótese, permitirão que menores de dezoito anos possuam
            jornada de trabalho que impossibilite ou prejudique a frequência escolar.
          </p>

          <p>
            20.5. As PARTES se comprometem a não adotar ou apoiar práticas de discriminação negativa
            e/ou injuriosa, relacionada à raça, cor, sexo, religião, opção sexual, origem ou
            qualquer outra característica pessoal de seus profissionais.
          </p>

          <p>
            20.6. As PARTES se obrigam a manter as suas instalações dentro dos padrões mínimos
            definidos pela legislação aplicável à espécie.
          </p>

          <p>
            20.7. As PARTES responsabilizam-se por si e por terceiros que porventura vierem a
            utilizar no curso da relação comercial instituída, devendo transmitir-lhes as “
            <b>
              <u>Condições de Sustentabilidade e Cidadania</u>
            </b>
            ” previstas acima.
          </p>

          <p>
            20.8. A CELEBRAR, verificando o descumprimento do <b>FORNECEDOR</b> e dos terceiros por
            esta utilizados, das obrigações aqui previstas, poderá extinguir esse instrumento
            imediatamente, sem a necessidade de notificação prévia ou demais formalidades, sem
            prejuízo da aplicação das penalidades previstas neste instrumento e apuração das perdas
            e danos que vier a incorrer.
          </p>
        </section>

        <section ref={section21ref}>
          <h2>21. COMPLIANCE E ANTICORRUPÇÃO</h2>

          <p>
            21.1. As Partes declaram conhecer as normas de prevenção à corrupção previstas na
            legislação brasileira, dentre elas, a Lei de Improbidade Administrativa (Lei nº
            8.429/1992) e a Lei nº 12.846/2013 e seus regulamentos (em conjunto, “
            <b>
              <u>Leis Anticorrupção</u>
            </b>
            ”) e se comprometem a cumpri-las fielmente, por si e por seus sócios, administradores e
            colaboradores, bem como exigir o seu cumprimento pelos terceiros por elas contratados.
          </p>

          <p>
            21.2. Adicionalmente, cada uma das Partes declara que tem e manterá até o final da
            vigência deste contrato um código de ética e conduta próprio, cujas regras se obriga a
            cumprir fielmente.
          </p>

          <p>
            21.3. Sem prejuízo da obrigação de cumprimento das disposições de seus respectivos
            código de ética e conduta, ambas as Partes desde já se obrigam a, no exercício dos
            direitos e obrigações previstos neste Contrato e no cumprimento de qualquer uma de suas
            disposições: (i) não dar, oferecer ou prometer qualquer bem de valor ou vantagem de
            qualquer natureza a agentes públicos ou a pessoas a eles relacionadas ou ainda quaisquer
            outras pessoas, empresas e/ou entidades privadas, com o objetivo de obter vantagem
            indevida, influenciar ato ou decisão ou direcionar negócios ilicitamente e (ii) adotar
            as melhores práticas de monitoramento e verificação do cumprimento das leis
            anticorrupção, com o objetivo de prevenir atos de corrupção, fraude, práticas ilícitas
            ou lavagem de dinheiro por seus sócios, administradores, colaboradores e/ou terceiros
            por elas contratados.
          </p>

          <p>
            21.4. A comprovada violação de qualquer das obrigações previstas nesta cláusula é causa
            para a rescisão unilateral deste Contrato, sem prejuízo da cobrança das perdas e danos
            causados à parte inocente.
          </p>
        </section>

        <section ref={section22ref}>
          <h2>22. COMUNICAÇÕES</h2>

          <p>
            22.1. Todas as comunicações previstas neste Contrato serão feitas por escrito e
            consideradas recebidas na data da sua transmissão, se por e-mail, e na data do efetivo
            recebimento pela Parte comunicada, em seu endereço, se enviadas por qualquer outro meio.
          </p>

          <p>
            22.2. As comunicações para o <b>FORNECEDOR</b> serão enviadas para os endereços
            previstos no formulário preenchido pelo <b>FORNECEDOR</b> ao registrar-se na Plataforma
            da Celebrar.
          </p>

          <p>22.3. As comunicações para a CELEBRAR serão enviadas para os seguintes endereços:</p>
          <ul>
            <li>
              I. Endereço: Av. Interlagos, nº 800 Jardim Marajoara, São Paulo/SP CEP 04660-000;
            </li>
            <li>II. E-mail: camila@celebrar.co</li>
          </ul>

          <p>
            22.4. Qualquer alteração de endereço e/ou de destinatário deverá ser imediatamente
            comunicada por escrito pela Parte interessada à outra Parte.
          </p>
        </section>

        <section ref={section23ref}>
          <h2>23. MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES</h2>

          <p>
            23.1. Os Termos e Condições de Uso do Plataforma Celebrar estão sujeitos a constante
            melhoria e aprimoramento. Assim, a CELEBRAR se reserva o direito de modificar a qualquer
            momento, de forma unilateral, o presente Termos e Condições de Uso. Os novos Termos e
            Condições entrarão em vigor após 10 (dez) dias contados de sua publicação no Plataforma.
            Caso os Usuários e Compradores não estejam de acordo com as novas regras, deverá entrar
            em contato com os canais de atendimento da CELEBRAR, hipótese em que se considerará
            rescindido seu vínculo contratual com a CELEBRAR, devendo, no entanto, ser cumpridas
            todas as obrigações assumidas até o momento da efetiva rescisão.
          </p>

          <p>
            23.2. Ao navegar por esta Plataforma Celebrar, você aceita guiar-se pelos Termos e
            Condições de Uso do Plataforma Celebrar que se encontram vigentes na data e, portanto,
            deve verificar os mesmos previamente cada vez que visitar este no Plataforma Celebrar.
          </p>
        </section>

        <section ref={section24ref}>
          <h2>24. DISPOSIÇÕES GERAIS</h2>

          <p>
            24.1. O presente Contrato é celebrado em caráter irrevogável e irretratável, obrigando
            as Partes, entre si, bem como seus herdeiros ou sucessores, a qualquer título.
          </p>

          <p>
            24.2. Ao aceitar o presente Contrato por qualquer meio de confirmação digital
            disponibilizado pela CELEBRAR, o <b>FORNECEDOR</b> declara estar absolutamente de acordo
            com todas as disposições do presente Contrato, tornando-se obrigado a cumprir o presente
            Contrato em sua integralidade, sob as penas da lei vigente e do presente Contrato.
          </p>

          <p>
            24.3. O não exercício de quaisquer direitos previstos neste Contrato, por qualquer das
            Partes, não poderá ser considerado precedente ou autorizador de modificação do que se
            encontra aqui pactuado, representando mera tolerância do titular de tal direito, que
            poderá exercê-lo quando julgar conveniente.
          </p>

          <p>
            24.4. A renúncia, novação ou alteração de qualquer dos termos deste Contrato somente
            será considerada válida e eficaz se formalizada por meio de aditivo escrito e assinado
            por ambas as partes.
          </p>

          <p>
            24.5. A CELEBRAR proíbe o pagamento de qualquer valor visando facilitação ou propina em
            benefício de qualquer corporação pública no Brasil e/ou no exterior, partido político ou
            para qualquer pessoa ou entidade. Em caso de conhecimento ou suspeita de violação do
            disposto neste item, a CELEBRAR deve ser imediatamente notificada, por escrito, e caso
            seja comprovado que o <b>FORNECEDOR</b> descumpriu com o disposto neste item, a CELEBRAR
            poderá rescindir o presente Contrato e cobrar do <b>FORNECEDOR</b>, multa equivalente
            aos danos que venham a ser causados para a CELEBRAR em razão do seu descumprimento. O
            CONTRATANTE concorda em adotar um compromisso quanto aos assuntos referentes à conduta
            ética e aceita as políticas estabelecidas pela CELEBRAR, encarregando-se de notificá-la
            em caso de conhecimento ou suspeita de violação de tais disposições.
          </p>

          <p>
            24.6. O presente Contrato representa a totalidade dos acordos havidos entre as Partes no
            que diz respeito ao seu objeto, prevalecendo sobre qualquer outro acordo, sob qualquer
            forma, celebrado anteriormente pelas Partes.
          </p>

          <p>
            24.7. Qualquer alteração neste Contrato somente será admitida e considerada válida se
            realizada mediante a celebração de Termo Aditivo, por escrito, devidamente firmado pelas
            Partes.
          </p>

          <p>
            24.8. O <b>FORNECEDOR</b> reconhece expressamente que não poderá, nem por si, nem por
            seus diretores, empregados, eventuais subcontratados ou prepostos, firmar qualquer
            documento ou assumir obrigações em nome da CELEBRAR, salvo quando expressamente
            autorizado pela CELEBRAR e nos estritos limites de tal autorização.
          </p>

          <p>
            24.9. Este Contrato constitui o total entendimento entre as Partes e substitui todas as
            cartas, fax, e-mails, entendimentos e contratos anteriores, relativos ao objeto aqui
            tratado.
          </p>

          <p>
            24.10 As Partes poderão requerer judicialmente a execução específica deste Contrato, ou
            de qualquer parte dele constante, conforme as disposições aplicáveis do Código de
            Processo Civil.
          </p>

          <p>
            24.1.1. As Partes elegem o Foro da Comarca de São Paulo, Estado de São Paulo, como o
            único competente para dirimir quaisquer controvérsias oriunda deste Contrato, com
            expressa renúncia a qualquer outro, por mais privilegiado que seja ficando facultado à
            CELEBRAR em optar, a seu exclusivo critério, pelo foro do domicílio de qualquer das
            Partes.
          </p>
        </section>

        <h1>CELEBRAR TECNOLOGIA E INTERMEDIAÇÕES LTDA.</h1>
      </StyledTerms>

      <Footer />
    </TermsPage>
  );
}