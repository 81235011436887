import React from 'react';
import styled from 'styled-components';
import { FormTextInput } from '../common/FormTextInput';
import { Load } from '../Loader/styles';

export function InputWithStatus({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  label,
  inputId,
  ...props
}) {
  return (
    <Wrapper className="input__field">
      <FormTextInput isError={isError} errorMessage={errorMessage} {...props} />

      <label htmlFor={inputId}>
        {label}
      </label>
      <IconWrapper>
        {isLoading ? (
          <Load.SmallIcon
            style={{
              width: '80%',
              height: '80%',
              borderWidth: '2px',
              lineHeight: 0,
              animationTimingFunction: 'linear',
              borderTopColor: 'transparent',
            }}
          />
        ) : isSuccess ? (
          <CheckIcon style={{ color: 'green', width: '100%', height: '100%' }} />
        ) : isError ? (
          <ErrorIcon
            style={{ color: 'var(--color-red)', width: '100%', height: '100%' }}
          />
        ) : null}
      </IconWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 1.6rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
`;

const CheckIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
    {...props}
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

const ErrorIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
    {...props}
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);
