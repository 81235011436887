import React from 'react';
import { EscalePage } from '../styles';
// Categories images
import categoryBg1 from '../../../assets/images/escale/categories/category-1.jpg';
import categoryBg2 from '../../../assets/images/escale/categories/category-2.jpg';
import categoryBg3 from '../../../assets/images/escale/categories/category-3.jpg';
import categoryBg4 from '../../../assets/images/escale/categories/category-4.jpg';
import categoryBg5 from '../../../assets/images/escale/categories/category-5.jpg';
import escaleGraphism from '../../../assets/images/escale/grafismo_escale.svg';

function EscaleCategories({ setFilter }){
    return(
        <EscalePage.Categories >
            <div className="container">
                <EscalePage.PageTitle >
                    <hr /> Categorias
                </EscalePage.PageTitle>

                <img
                    src={ escaleGraphism }
                    style={
                        { 
                            position: "absolute",
                            left: "-2rem",
                            top: "2rem",
                            zIndex: 9
                        }
                    }
                    alt=""
                />

                <EscalePage.CategoriesGrid >
                    <EscalePage.Category
                        style={ { backgroundImage: `url(${ categoryBg1 })` } }
                        onClick={ () => setFilter("Inicio da Escalada") }
                    >
                        <div>
                            <p> Inicio da Escalada </p>
                        </div>
                    </EscalePage.Category>

                    <EscalePage.Category
                        style={ { backgroundImage: `url(${ categoryBg2 })` } }
                        onClick={ () => setFilter("Escale Além do Topo") }
                    >
                        <div>
                            <p> Escale Além do Topo </p>
                        </div>
                    </EscalePage.Category>
                </EscalePage.CategoriesGrid>


                <EscalePage.CategoriesGrid >
                    <EscalePage.Category
                        style={ { backgroundImage: `url(${ categoryBg3 })` } }
                        onClick={ () => setFilter("Meu pet Escale") }
                    >
                        <div>
                            <p> Meu pet Escale </p>
                        </div>
                    </EscalePage.Category>

                    <EscalePage.Category
                        style={ { backgroundImage: `url(${ categoryBg5 })` } }
                        onClick={ () => setFilter("Orgulho da minha escalada") }
                    >
                        <div>
                            <p> Orgulho da minha escalada </p>
                        </div>
                    </EscalePage.Category>
                </EscalePage.CategoriesGrid>
            </div>
        </EscalePage.Categories>
    )
}

export default EscaleCategories;