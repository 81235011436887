import React from 'react';
import axios from 'axios';
import { useAsync } from '../../../../utils';

export function useAlertData(alertId) {
  const { run, data, isLoading } = useAsync({ status: 'pending' });

  React.useEffect(() => {
    run(axios.get(`/api/supplier/alert/${alertId}`).then(response => response.data));
  }, [run, alertId]);

  return {
    alertName: data?.alert.name,
    isLoadingAlertData: isLoading,
    isDataEmpty: data && Object.keys(data).length === 0,
    submittedOffersCount: data?.submittedOffersCount,
    deliveryDate: data?.alert.delivery_estimated_at ?? data?.alert.delivery_date,
    alertQuantity: data?.alert.quantity,
    alertPriceMethod: data?.alert.price_method,
    alertCategories: data?.alert.categories,
  };
}
