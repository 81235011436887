import React, { Fragment } from 'react';
import { formatDate } from '../../../utils/dateFunctions';
import { toBraCurrency } from '../../../utils/currencyFunctions';
import { SalesPanelPage } from './styles';
import { useHistory } from 'react-router-dom';

function NewContractCard({ data }) {
  const {
    id,
    sale_code,
    delivery_estimated_at,
    items,
    invoice_requests,
    total_supplier_sale_price,
  } = data;
  const deliveryDate = formatDate(delivery_estimated_at);
  const invoicePrice = toBraCurrency(total_supplier_sale_price);

  const { push } = useHistory();

  const invoicePending = invoice_requests[0].status_id === 1;
  const invoiceSubmitted = invoice_requests[0].status_id === 2;
  const invoiceNeedsReview = invoice_requests[0].status_id === 3;

  return (
    <SalesPanelPage.Card>
      <SalesPanelPage.CardHeader>
        <h5 className="txt--black">{`#${sale_code}`}</h5>
        <p>Data do evento: {deliveryDate}</p>
      </SalesPanelPage.CardHeader>

      {items.length > 0 ? (
        <SalesPanelPage.ItemsList>
          Itens vendidos:
          {items?.map(({ name, id }) => (
            <li key={id}>{name}</li>
          ))}
        </SalesPanelPage.ItemsList>
      ) : null}

      <SalesPanelPage.CardMessage>
        {invoicePending ? (
          <p>
            <span className="txt--red txt--bold">Atenção!</span>
            <br />
            Para confirmar a venda, envie a sua nota fiscal no valor de{' '}
            <span className="txt--secondary txt--bold">{invoicePrice}</span>
          </p>
        ) : null}

        {invoiceSubmitted ? (
          <p>
            Valor do repasse: <span className="txt--secondary txt--bold">{invoicePrice}</span>
          </p>
        ) : null}

        {invoiceNeedsReview ? (
          <Fragment>
            <p>
              Valor do repasse: <span className="txt--secondary txt--bold">{invoicePrice}</span>
            </p>
            <p>
              <span className="txt--red txt--bold">Atenção!</span>
              <br />A nota que você enviou precisa de correções.
            </p>
          </Fragment>
        ) : null}
      </SalesPanelPage.CardMessage>

      {invoicePending ? (
        <button
          className="txt--primary"
          onClick={() => push(`/fornecedor/confirmacoes-de-venda/${id}`)}
        >
          <span style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Enviar nota fiscal</span>
        </button>
      ) : null}

      {invoiceSubmitted ? (
        <button style={{ cursor: 'default' }} disabled={true}>
          <span style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Aguardando aprovação da NF</span>
        </button>
      ) : null}

      {invoiceNeedsReview ? (
        <button
          className="txt--primary"
          onClick={() => push(`/fornecedor/confirmacoes-de-venda/${id}`)}
        >
          <span style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Enviar nova nota fiscal</span>
        </button>
      ) : null}
    </SalesPanelPage.Card>
  );
}

export default NewContractCard;
