import React from 'react';
import { formatDate, formatDateAndTime, toBraCurrency } from '../../../utils';
import { SalesPanelPage } from '../SalesPanel/styles';

export const ContractDetailsModalContent = ({ contract }) => (
  <>
    <h2 className="txt--secondary margin--bottom-1">Evento #{contract.sale_code}</h2>
    <p>Data do evento: {formatDate(contract.delivery_estimated_at)}</p>

    <div className="alerts__fieldset">
      <span className="fieldset__title">Detalhes da venda</span>
      <span className="fieldset__description" />

      <div className="alerts__prices">
        <div className="alerts__price txt--black">
          <p> Valor de venda </p>
          <h6> {toBraCurrency(contract.total_sale_price)} </h6>
        </div>

        <div className="alerts__price" style={{ color: 'var(--color-primary)' }}>
          <p> Valor do repasse </p>
          <h6>{toBraCurrency(contract.total_supplier_sale_price)}</h6>
        </div>
      </div>
    </div>
    <div className="alerts__fieldset">
      <span className="fieldset__title">Serviços vendidos</span>
      <span className="fieldset__description" />
      {contract.items?.map(item => (
        <SalesPanelPage.ItemDescription>
          <h4>
            {' '}
            {item.name} (quantidade: {item.quantity})
            <span> - Data e hora de entrega: {formatDateAndTime(item.delivery_estimated_at)}</span>
          </h4>
          <div>
            <p>{item.description}</p>

            <p>Observações: {item.obs}</p>

            <ul class="item__prices">
              <li>
                Valor da venda por unidade: <span>{toBraCurrency(item.sale_price)}</span>
              </li>
              <li>
                Valor total do serviço: <span>{toBraCurrency(item.final_sale_price)}</span>
              </li>
              <li>
                Valor do repasse: <span>{toBraCurrency(item.final_supplier_sale_price)}</span>
              </li>
            </ul>
          </div>
        </SalesPanelPage.ItemDescription>
      ))}
    </div>

    <div className="alerts__fieldset">
      <span className="fieldset__title">Mais detalhes</span>
      <span className="fieldset__description" />
      <SalesPanelPage.DetailsSection>
        {contract.infos?.map(({ description }) => (
          <p>{description}</p>
        ))}
      </SalesPanelPage.DetailsSection>
    </div>
  </>
);
