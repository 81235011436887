import React from 'react';
import styled from 'styled-components';
import Header from '../../../Components/Header';
import SuccessImg from '../../../assets/images/proposals/sucess.png';
import { Button } from '../../../Components/common/Buttons/Button';
import { useHistory } from 'react-router-dom';

export function RegistrationFinished() {
  const history = useHistory();

  return (
    <>
      <Header buyerPage hideButtons />
      <FinishRegistrationStyles>
        <img src={SuccessImg} alt="" />
        <h1>Cadastro completo!</h1>
        <p>Parabéns, agora você pode enviar seus orçamentos para as oportunidades disponíveis</p>
        <Button variant="purple" onClick={() => history.push('/fornecedor/painel-de-vendas')}>
          Acessar painel de vendas
        </Button>
      </FinishRegistrationStyles>
    </>
  );
}

const FinishRegistrationStyles = styled.div`
  background-color: var(--color-primary-light);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  img {
    width: 12rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: 'Estate', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
`;
