import styled from 'styled-components';

export const WalletPage = styled.div`
  /* Display & Box Model */
  width: 100%;
`;

WalletPage.Header = styled.header`
  /* Display & Box Model */
  width: 100%;
  height: 370px;
  margin-bottom: 2rem;

  /* Colors & Background*/
  background-color: var(--color-green);
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: right;
  color: var(--color-white);

  /* Position */
  position: relative;

  h1 {
    /* Display & Box Model */
    margin-bottom: 2rem;

    /* Text */
    font-size: 2rem;
    font-weight: 700;
  }

  h2 {
    /* Display & Box Model */
    /* margin-bottom: 2rem; */

    /* Text */
    font-size: 4rem;
    font-weight: 700;
  }

  h1,
  h2,
  p {
    text-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.6);
  }

  :last-child {
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.6);
  }

  @media (min-width: 600px) {
    h1,
    h2,
    p {
      text-shadow: none;
    }

    :last-child {
      box-shadow: none;
    }
  }
`;

WalletPage.HeaderBlur = styled.div`
  /* Display & Box Model */
  width: 100%;
  height: 100%;

  /* Colors & Backgound*/
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), transparent);
  backdrop-filter: blur(3px);

  /* Position */
  position: absolute;
  top: 0;
  left: 0;

  @media (min-width: 600px) {
    display: none;
  }
`;

WalletPage.Subtitle = styled.h3`
  /* Display & Box Model */
  margin: 2rem 0;

  /* Text */
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;
`;

WalletPage.Balance = styled.h4`
  /* Display & Box Model */
  margin-bottom: 2rem;

  /* Colors */
  color: var(--color-green);

  /* Text */
  font-size: 3rem;
  font-weight: 700;
`;

WalletPage.ModalBtns = styled.div`
  /* Display & Box Model */
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    margin: 0;
  }
`;

/*====================================
        Events (balance receivable)
=====================================*/

WalletPage.Events = styled.ul`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 2rem;
`;

WalletPage.Event = styled.li`
  /* Display & Box Model */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  /* Colors */
  background-color: var(--color-white);

  /* Others */
  border-left: 0.8rem solid var(--color-blue-light);
  border-radius: 0.3rem;
  box-shadow: var(--shadow);

  p {
    display: inline-block;
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 600;

    span {
      /* Display & Box Model */
      display: block;
      margin-bottom: 0.5rem;

      /* Text */
      font-size: 0.8rem;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    p {
      margin: 0;
      margin-bottom: 0.5rem;
      background-color: var(--color-yellow-light);
      font-size: 0.8rem;
      font-weight: 400;
      padding: 4px 12px;
      border-radius: 16px;

      &.available {
        background-color: var(--color-green);
        color: var(--color-white);
      }

      &.confirmed {
        background-color: var(--color-blue-dark);
        color: var(--color-white);
      }

      &.invoice-approved {
        background-color: var(--color-blue-dark);
        color: var(--color-white);
      }
    }
  }

  @media (min-width: 600px) {
    p {
      margin-right: 1rem;
    }
  }
`;

/*====================================
          Bank Accounts
=====================================*/

WalletPage.Banks = styled.ul`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: 600px) {
    border-left: 1px solid var(--color-border);
    padding: 0 0 2rem 2rem;
  }
`;

WalletPage.BankAccount = styled.li`
  /* Display & Box Model */
  width: 100%;
  display: flex;

  /* Colors */
  background-color: var(--color-white);

  /* Position */
  position: relative;

  /* Others */
  box-shadow: var(--shadow);
  border-radius: 0.3rem;
  overflow: hidden;

  p {
    display: inline-block;
    font-weight: 600;
    margin: 0 2rem 1rem 0;

    span {
      /* Display & Box Model */
      display: block;
      margin-bottom: 0.5rem;

      /* Text */
      font-size: 0.8rem;
    }
  }
`;

WalletPage.BankAccount.Bank = styled.div`
  /* Display & Box Model */
  min-width: 6rem;
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-color: var(--color-purple-dark);
  color: var(--color-white);

  p {
    margin: 0;
  }
`;

WalletPage.BankAccount.Content = styled.div`
  /* Display & Box Model */
  padding: 1rem;
`;

WalletPage.BankAccount.Delete = styled.span`
  /* Colors */
  color: var(--color-red);

  /* Position */
  position: absolute;
  top: 1rem;
  right: 1rem;

  /* Text */
  font-size: 0.8rem;

  /* Others */
  cursor: pointer;
`;

/*====================================
        Modal Bank Accounts
=====================================*/

WalletPage.ModalBankAccount = styled.ul`
  /* Display & Box Model */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;

  li {
    /* Display & Box Model */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    /* Colors */
    background-color: var(--color-gray-light);

    /* Others */
    border: 1px solid var(--color-border);
    border-radius: 0.3rem;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    li {
      flex-direction: row;
    }
  }
`;

WalletPage.ModalBankAccount.Radio = styled.div`
  /* Display & Box Model */
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-color: var(--color-border);

  /* Positon */
  position: relative;
  z-index: 9;

  /* Others */
  border-radius: 50%;
  cursor: pointer;

  ::before {
    /* Display & Box Model */
    content: '';
    width: 1rem;
    height: 1rem;

    /* Colors */
    background-color: var(--color-white);

    /* Position */
    position: absolute;

    /* Others */
    border-radius: 50%;
  }

  &.active {
    /* Colors */
    background-color: var(--color-primary-light);

    ::after {
      /* Display & Box Model */
      content: '\\2714';
      /* width: 0.5rem;
      height: 0.5rem; */

      /* Colors */
      /* background-color: var(--color-primary-dark); */
      color: var(--color-primary-dark);

      /* Position */
      position: absolute;

      /* Others */
      border-radius: 50%;
    }
  }
`;

WalletPage.Warning = styled.span`
  /* Colors */
  color: var(--color-red);

  /* Text */
  font-weight: 600;
`;
