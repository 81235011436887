import React from 'react';
import { toBraCurrency } from '../../utils';
// Styles & Images
import { Item } from './styles';

function OfferItemPrintVersion({ item }) {
  return (
    <Item.Print>
      <Item.Print.Title>
        <h3>{item.name}</h3>
        {item.supplier_name ? <p>Fornecido por: {item.supplier_name}</p> : null}
      </Item.Print.Title>

      <Item.Print.Description>{`${item.description}${
        item.obs ? `\n\nObservações:\n${item.obs}` : ''
      }`}</Item.Print.Description>

      <Item.Print.Photos>
        {item.photos.map(photo => {
          return <img src={photo.url} alt="" key={photo.id} />;
        })}
      </Item.Print.Photos>

      <Item.Print.Prices>
        {item.price_method ? (
          <div>
            <label>Modo de cobrança</label>
            <p>{item.price_method}</p>
          </div>
        ) : null}
        {item.quantity ? (
          <div>
            <label>Quantidade</label>
            <p>{item.quantity}</p>
          </div>
        ) : null}
        {item.price_method ? (
          <div>
            <label>Valor {item.price_method.toLowerCase()}</label>
            <p>{toBraCurrency(item.buyer_price)}</p>
          </div>
        ) : (
          <div>
            <label>Valor unitário</label>
            <p>{toBraCurrency(item.buyer_price)}</p>
          </div>
        )}
        <div className="total-value">
          <label>Valor total</label>
          <p>{toBraCurrency(item.final_buyer_price)}</p>
        </div>
      </Item.Print.Prices>
    </Item.Print>
  );
}

export default OfferItemPrintVersion;
