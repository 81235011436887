import React from 'react';
import { useHistory } from 'react-router-dom';
import ModalContact from '../../Components/ModalContact';
import { useAuthContext } from '../../Contexts/AuthContext';

import { ErrorContainer } from './styles';

function Error() {
    const { user, setLoginRedirect } = useAuthContext();
    const { push } = useHistory();
    return (
        <div>
            <ErrorContainer>
                <div className="container">
                    <ModalContact />
                    <h1> 404 </h1>
                    <h2> Página não encontrada </h2>
                    <button
                        className="btn"
                        onClick={() => {
                            if (user) {
                                setLoginRedirect(true);
                            } else {
                                push('/');
                            }
                        }}
                    >
                        <span>Voltar para home</span>
                    </button>
                </div>
            </ErrorContainer>
        </div>
    );
}

export default Error;
