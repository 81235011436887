import styled, { keyframes } from 'styled-components';
import tw from 'tailwind.macro';

const loadAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const CompleteRegistrationPageStyles = styled.div`
  display: flex;
  padding-top: 6rem;
  width: 100%;
  overflow: hidden;

  h3 {
    ${tw`
        font-bold	
        text-2xl
        mb-3
        `}
    font-family: 'Estate'
  }

  form {
    padding: 2rem 2px 0;
  }

  .inputValues {
    ${tw`
        mt-4
        flex
        flex-wrap
        pb-4
    `}
    column-gap: 2rem;
  }

  .spinner {
    animation: ${loadAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export const CompanyStepStyled = styled.div`
  .recommendation {
    margin: 2rem 0 4rem;
  }
`;

export const AddressStepStyled = styled.div.attrs({
  className: 'w-full h-screen flex flex-col items-center justify-center',
})`
  .cep {
    max-width: 12vw;
  }
  .street {
    max-width: 38vw;
  }
  .number {
    max-width: 10vw;
  }
  .state {
    max-width: 7vw;
  }
  .city {
    max-width: 20vw;
  }
  .district {
    max-width: 20vw;
  }
  .complement {
    max-width: 64.5vw;
  }

  @media (max-width: 1000px) {
    .cep,
    .street,
    .number,
    .state,
    .city,
    .district,
    .complement {
      max-width: 100vw !important;
    }
  }
`;

export const RecommendationsStepStyled = styled.div.attrs({
  className: 'w-full h-screen flex flex-col items-center justify-center',
})`
  .service {
    max-width: 39vw;
  }
  .date {
    max-width: 23vw;
  }
  .name {
    max-width: 23vw;
  }
  .email {
    max-width: 23vw;
  }
  textarea {
    width: 65vw;
    height: 20vh;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    margin-top: 2rem;
    button {
      flex-shrink: 0;
      margin: 0;
      width: 22rem;
    }
  }

  @media (max-width: 886px) {
    .service,
    .date,
    .number,
    .name,
    .email {
      max-width: 85vw !important;
    }
  }
`;

export const ProgressBarStyled = styled.div`
  width: 20rem;
  height: 18rem;
  position: relative;
  flex-shrink: 0;

  > div {
    ${tw`
          absolute
          `}
    top:0;
    right: 3rem;

    &::after {
      ${tw`
                absolute	
              `}
      z-index:0;
      content: '';
      top: 2rem;
      right: 1rem;
      width: 1px;
      height: 12rem;
      background-color: black;
    }
  }

  .step {
    ${tw`
          flex
          justify-end	
          items-center
          my-8
          relative
        `}

    p {
      ${tw`
            mr-4	
          `}
      color: var(--color-gray-medium)
    }
    .step-text {
      ${tw`
            font-black
            text-lg
          `}
      color: var(--color-blue-dark);
    }
    div.step-number {
      ${tw`
            font-black
            text-lg
          `}
      color: var(--color-white);
      background-color: var(--color-blue-dark);
      width: 2rem;
      height: 2rem;
    }
    div {
      ${tw`
            flex
            justify-center
            items-center
          `}
      width:2rem;
      height: 2rem;
      color: var(--color-white);
      background-color: var(--color-gray-medium);
      border-radius: 50%;
      z-index: 10;
    }
  }
  .lasted div {
    background-color: var(--color-purple-light);
  }
  .lasted p {
    color: var(--color-purple-light);
  }

  @media (max-width: 800px) {
    ${tw`
          hidden
        `}
    &::after {
      ${tw`
              hidden	
            `}
    }
  }
`;
