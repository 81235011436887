import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { AppProviders } from './Contexts';

import './assets/styles/new-global.css';
import './assets/styles/grid.css';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppProviders>
                <Routes />
            </AppProviders>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
