import styled from "styled-components";
import tw from "tailwind.macro";

export const CategoriesBodyStyled = styled.div.attrs({
  className: "w-full h-screen flex flex-col items-center justify-center",
})`
  & {
    .asideCategory {
      ${tw`
        flex
        justify-end
      `}
      top: 2rem; /* altura que vai parar antes do topo */
      position: sticky;
    }
    .cardCategory p,
    .card p {
      ${tw`
      font-bold
      `}
    }
    .img {
      ${tw`
        m-auto
        mb-4
        `}
      width: 50px;
      height: 50px;
      background-color: #c0c0c0;
    }
    .button {
      ${tw`
        pb-6
        flex
        justify-center
      `}
    }
    section {
      ${tw`
        mt-12
      `}
    }

    .title-sub {
      ${tw`
        mt-4
        mb-8
        text-xl
      `}
      border-bottom: 2px solid var(--color-gray)
    }
    .cardCategory button {
      ${tw`
        mt-3
        w-full
        py-1
      `}
      border: 1px solid;
    }

    .cardCategory p {
      ${tw`
        pl-2
      `}
    }
    .cardCategory {
      ${tw`
        mb-10
        mx-2
        relative
      `}
      max-width: 300px;

      @media (min-width: 1400px){
        max-width: 350px;
      }

      h4 {
        ${tw`
        text-gray-600
        my-2
        flex
        items-center
      `}
        font-size: 0.8rem;

        svg {
          margin-right: 0.5rem;
        }
      }

      @media (max-width: 816px) {
        padding: 0.25rem;
      }
    }
    .img-card {
      ${tw`
        flex
        justify-center
        items-center
        mb-4
        relative
      `}
      border-radius: 0.3rem;
      overflow: hidden;

      ::before {
        content: "";
        width: 5rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: transparent;
        color: var(--color-white);
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5rem;
        padding-left: 1.5rem;
        box-sizing: border-box;
        clip-path: polygon(0 0, 100% 0, 100% 100%);
      }

      &.added {
        ::before {
          content: "\\2714";
          background-color: var(--color-green);
        }
      }

      img {
        ${tw`
          m-0
        `}
        width:100%;
      }
    }
  }
`;
export const CategoriesHeaderStyled = styled.div.attrs({
  className: "w-full h-screen flex flex-col items-center justify-center",
})`
  & {
    .icon {
      ${tw`
        flex
        justify-center
      `}
    }
    .icon svg {
      ${tw`
        w-16
        h-16
        `}
    }
    .category {
      ${tw`
      flex
      flex-col
      justify-center
      py-2
      cursor-pointer
     `}
      width: 7rem;
    }
    .category p {
      ${tw`
        flex
        items-end
        mt-2
        font-bold
        text-center
        text-white
     `}
      font-size: 0.74rem
    }
    .categories {
      ${tw`
        flex
        justify-between
        mt-6
        ml-0
      `}
    }
    .active {
      ${tw`
       text-black
       `}
      border-bottom: 0.2rem solid var(--color-yellow-light);
      border-radius: 0.2rem 0.2rem 0rem 0rem;
      color: var(--color-yellow-light);
      padding-bottom: 0.6rem;
      background-color: var(--color-blue-light);
    }

    .asideCategoryMobile {
      ${tw`
          hidden
        `}
    }
    /* ---> Mobile */
    @media (max-width: 1318px) {
      .categories {
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 83vw;
      }

      *::-webkit-scrollbar-track {
        /* background-color: var(--color-primary-dark); */
      }

      *::-webkit-scrollbar {
        background-color: transparent;
        transition: 0.3s;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #9614c36e;
        border: solid 1px var(--color-primary);
        margin-top: 2rem;
        border-radius: 10px;
        height: 2rem !important;
      }
    }
    @media (max-width: 768px) . {
      .category {
        ${tw`
          px-4
          mb-3
        `}
        width:7rem !important;
      }
    }
  }
`;

export const CategoriesAsideStyled = styled.div.attrs({
  className: "w-full h-screen flex flex-col items-center justify-center",
})`
  & {
    span {
      ${tw`
        cursor-pointer
        text-red-600
      `}
    }

    .card .contentAside {
      ${tw`
        p-8
        h-56
        overflow-auto
        items-center
      `}
    }
    .contentAside .container {
      ${tw`
        h-40
        flex
        items-center
      `}
    }
    .contentAside .contentCard {
      ${tw`
      flex
      items-center
      my-2
      `}
    }
    .contentAside .content {
      ${tw`
        flex
        items-center
        w-52
        text-center
      `}
    }
    .contentAside p,
    .contentAside span {
      ${tw`
        ml-4
     `}
    }
    .content-card span {
      ${tw`
        ml-4
        my-1
        cursor-pointer
        text-red-600
        text-sm
      `}
    }

    .img-card-aside {
      ${tw`
        w-16
        p-2
        rounded
      `}
      height: 4rem;
      background-color: #c0c0c0;
    }
    /* Card Category */

    .card {
      ${tw`
        rounded
      `}
      box-shadow:0px 2px 5px 1px #4448;
      width: 31rem;
    }
    .categoryHeader {
      ${tw`
        py-3
      `}
      background-color: green;
    }

    .asideCategory .content {
      ${tw`
        text-center
        flex
        justify-center
        items-center
        h-32
        w-full
      `}
    }
    .asideCategory .content-card {
      ${tw`
        flex
        items-center
        self-end
        h-20
      `}
    }

    .asideCategory .inicialText {
      ${tw`
        ml-4
        w-56
        text-sm
        `}
    }
    .asideCategoryMobile {
      ${tw`
          hidden
        `}
    }

    /* ---> Mobile */
    @media (max-width: 768px) {
      span {
        ${tw`
          ml-4
        `}
      }
      .contentAside .selectedAside {
        ${tw`
          px-1
          overflow-auto
          `}
      }
      .textAsideMobile {
        ${tw`
          flex
          justify-center
          items-start
          flex-col
        `}
      }
      .cardAsideMobile {
        ${tw`
          my-1
          flex
        `}
      }

      .card {
        ${tw`
          hidden
        `}
      }
      .asideCategoryMobile {
        ${tw`
          block
        `}
      }

      /*Slide off canvas category */
      .buttonAside {
        position: fixed;
        bottom: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        height: 3rem;
        background-color: var(--color-primary);
        transition: transform 250ms ease;
        cursor: pointer;
        z-index: 200;
      }
      #menu {
        display: none;
        &:checked ~ .menu {
          background-color: transparent;
          transition: transform 250ms ease;
        }
        &:checked ~ .contentAside {
          right: 0px;
          transition: 0.5s;
        }
      }
      .contentAside {
        position: fixed;
        top: 0;
        right: -100vw;
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        background-color: #fff;
        transition: 0.5s;
        z-index: 0;
      }
      .contentAside nav {
        ${tw`
          font-bold	
          text-white	
          text-center
          py-3
        `}
        background-color: var(--color-green) !important;
      }
      .contentAside section {
        ${tw`
        mt-0
        text-center
        p-2
      `}
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height: 87vh;
      }
    }
  }
`;
