import React, { useEffect } from 'react';
import axios from 'axios';
import FeedbackChannel from '../FeedbackChannel';
import SupplierMenu from '../Menu';
import { useValidateForm } from '../../../Components/Utils/useValidateForm';
import Modal from '../../../Components/Modal';
import Alert from '../../../Components/Alert';
import { useAsync } from '../../../utils';
import { useAuthContext } from '../../../Contexts/AuthContext';
import SuccessAlert from '../../../Components/SuccessAlert';
import { UserAccountAside } from './UserAccountAside';
// Styles & Images
import { SupplierPage } from '../styles';
import { UserPage } from './styles';
// import { RegistrationStepPage } from '../RegistrationSteps/styles';
import { Load } from '../../../Components/Loader/styles';
import { CloseIcon } from '../../../Components/Icons';
import { FormSubHeader } from '../../../Components/common/FormSubHeader';

export function UserContacts() {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [job, setJob] = React.useState('');
  const [contacts, setContacts] = React.useState([]);

  // Load saved contacts
  const {
    run: runGetSavedContacts,
    data,
    isLoading,
    isError: isLoadError,
  } = useAsync({ status: 'pending' });
  useEffect(() => {
    runGetSavedContacts(
      axios.get('/api/supplier/get-contacts').then((response) => response.data)
    );
  }, [runGetSavedContacts]);
  useEffect(() => {
    setContacts(data?.contacts);
  }, [data]);

  const { user, updateUserData, signOut } = useAuthContext();
  const { start } = useValidateForm();

  const {
    run: runSubmit,
    isLoading: isSubmitting,
    isError: isSubmitError,
    isSuccess,
  } = useAsync();
  function handleSubmitContacts(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    runSubmit(
      axios
        .post('/api/supplier/register-contacts', {
          phone: formData.get('phone'),
          contacts,
        })
        .then(() => successAlertRef.current.open())
    );
  }

  const successAlertRef = React.useRef();

  function setNewContact() {
    if (name && email && job) {
      setContacts([...contacts, { name: name, email: email, job: job }]);
      setName('');
      setEmail('');
      setJob('');
    }
  }

  function removeContact(email) {
    setContacts(contacts.filter((e) => e.email !== email));
  }

  return (
    <SupplierPage>
      <SupplierMenu />
      <FeedbackChannel />

      <SupplierPage.Container>
        <div className="row">
          <UserAccountAside />

          <div className="col m9 s12">
            {isLoading ? (
              <div style={{ height: '70vh' }}>
                <Load.CenterIcon>
                  <Load.SmallIcon />
                </Load.CenterIcon>
              </div>
            ) : isLoadError ? (
              <div
                style={{
                  height: '30vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'var(--color-red)',
                  fontWeight: 'bold',
                }}
              >
                <p>Erro ao carregar a página</p>
              </div>
            ) : (
              <SupplierPage.Box style={{ padding: '2rem' }}>
                <UserPage.Title>
                  <h3>Meus contatos</h3>
                  <p>
                    Adicione mais contatos que poderão receber os e-mails de notificações
                    da Celebrar.
                  </p>
                </UserPage.Title>
                <form onSubmit={handleSubmitContacts}>
                  <div className="row">
                    <div className="col m6 s12">
                      <div className="input__field">
                        <label htmlFor="phone"> Telefone de contato * </label>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder="Telefone da empresa"
                          className="input"
                          defaultValue={data?.phone}
                          required
                          minLength="9"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <FormSubHeader
                      title="Lista de contatos (opcional)"
                      subtitle="Ao adicionar contatos à sua lista essas pessoas também receberão as notificações por e-mail da Celebrar que chegarem para você"
                    />

                    <div className="col m6 s12">
                      <div className="input__field">
                        <label htmlFor="">Nome do contato*</label>
                        <input
                          type="text"
                          className="input"
                          name="contact_name"
                          placeholder="Nome"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col m6 s12">
                      <div className="input__field">
                        <label htmlFor="">E-mail do contato*</label>
                        <input
                          type="email"
                          className="input"
                          name="contact_email"
                          placeholder="E-mail"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col m6 s12">
                      <div className="input__field">
                        <label htmlFor="">Cargo do contato*</label>
                        <input
                          type="text"
                          className="input"
                          name="contact_job"
                          placeholder="Cargo"
                          value={job}
                          onChange={(event) => setJob(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col m6 s12">
                      <div className="input__field">
                        <button
                          onClick={setNewContact}
                          type="button"
                        >
                          Adicionar contato +
                        </button>
                      </div>
                    </div>
                  </div>

                  <table cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Cargo</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {contacts?.length > 0 &&
                        contacts.map((contact, index) => {
                          return (
                            <tr key={index}>
                              <td>{contact.name}</td>
                              <td>{contact.email}</td>
                              <td>{contact.job}</td>
                              <td
                                className="txt--red"
                                onClick={() => removeContact(contact.email)}
                              >
                                <CloseIcon bgColor="red" />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <div style={{ paddingTop: '2rem' }}>
                    <button className="btn" type="submit" disabled={isLoading}>
                      <span>{isLoading ? 'Enviando...' : 'Salvar'}</span>
                    </button>
                  </div>
                </form>
              </SupplierPage.Box>
            )}
          </div>
        </div>

        <SuccessAlert ref={successAlertRef}>Dados atualizados com sucesso!</SuccessAlert>
      </SupplierPage.Container>
    </SupplierPage>
  );
}
