import styled, { keyframes } from 'styled-components';
import Bg from '../../../assets/images/Bg-proposal.png';

export const ProposalHeader = styled.div`
    width: 100%;
    background-color: var(--color-black);
    padding: 0.5rem 0;

    .header__logo {
        display: flex;
        align-items: center;
    }

    .logo {
        width: 4rem;

        img {
            width: 100%;
        }
    }

    h3 {
        color: var(--color-text-in-black);
        font-size: 1.2rem;
        font-weight: 200;
        border-left: solid 0.1rem var(--color-text);
        padding-left: 1rem;
        margin-left: 1rem;
    }

    h6 {
        font-size: 1rem;
        margin: 1rem auto;
    }

    .header__text {
        color: var(--color-text-in-black);
        text-align: center;
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    .pageheader__title--sub {
        margin-top: 2rem;
        font-size: 1.2rem;
        color: var(--color-secondary);
        text-align: center;
    }

    .input__field {
        display: none;
    }

    @media only screen and (min-width: 800px) {
        height: 15rem;
        background-image: url(${Bg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        .header__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .header__text {
            text-align: left;
            font-size: 1rem;
        }

        .pageheader__title--sub {
            font-size: 2rem;
            margin-top: 2rem;
            text-align: left;
        }

        .input__field {
            display: block;
        }
    }

    @media only print {
        -webkit-print-color-adjust: exact !important;
        height: 15rem;
        background-image: url(${Bg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        .header__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .header__text {
            text-align: left;
            font-size: 1rem;
        }

        .pageheader__title--sub {
            font-size: 2rem;
            margin-top: 2rem;
            text-align: left;
        }

        .input__field {
            display: block;
        }
    }
`;

export const HeaderText = styled.div`
    label {
        color: var(--color-text-in-primary);
    }

    @media only screen and (min-width: 800px) {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 1rem;
    }

    @media only print {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 1rem;
    }
`;

export const DeadLine = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-in-primary);
    margin: 1rem auto 2rem auto;
    font-size: 1rem;

    .deadline__alert {
        width: 2rem;
        height: 2rem;
        background-color: var(--color-black);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        border: solid 0.1rem var(--color-white);
    }

    p {
        font-weight: 600;
        margin-left: 1rem;
        color: var(--color-white);
        text-shadow: 0 0.1rem 0.5rem var(--color-black);
    }

    @media only screen and (min-width: 800px) {
        margin-top: -0.5rem;
        float: right;
    }
`;

/*=======================================
                Proposal
========================================*/

export const ProposalPage = styled.div`
    width: 100%;
    background-color: var(--color-gray-light);

    @media only print {
        -webkit-print-color-adjust: exact !important;
    }
`;

ProposalPage.Section = styled.section`
    margin: 1rem auto;

    @media only screen and (min-width: 800px) {
        margin: 2rem auto;
    }
`;

ProposalPage.Title = styled.h4`
    border-bottom: solid 0.1rem var(--color-border);
    padding: 1rem 0;
    font-size: 1.6rem;
    font-weight: 300;

    @media only screen and (min-width: 800px) {
        font-size: 2rem;
    }
`;

ProposalPage.Item = styled.div`
    width: 100%;
    background-color: var(--color-white);
    margin: 2rem auto;
    box-shadow: var(--shadow);
    border-radius: 0.1rem;
    position: relative;

    &.item--added {
        border: solid 0.2rem var(--color-secondary);
    }

    @media only screen and (min-width: 800px) {
        &.item--photo {
            display: grid;
            grid-template-columns: 30% 70%;
        }
    }

    @media only print {
        /* page-break-after: always; */

        &.item--photo {
            display: grid;
            grid-template-columns: 30% 70%;
        }

        &.item--added {
            border: none;
        }
    }
`;

ProposalPage.ItemPhotos = styled.figure`
    width: 100%;
    height: 18rem;
    position: relative;
    background-color: var(--color-gray);
    border: solid 0.5rem var(--color-white);
    box-shadow: var(--shadow);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        width: 100%;
    }

    button {
        position: absolute;
        bottom: 0;
        border: none;
        padding: 0.5rem;
        z-index: 9;
        right: 0;
        background-color: var(--color-secondary);
        color: var(--color-white);
        font-family: 'Work Sans', sans-serif;
        cursor: pointer;
    }

    button:nth-child(1) {
        left: 0;
        right: auto;
    }

    @media only print {
        button {
            display: none;
        }
    }
`;

ProposalPage.Buttons = styled.div`
    text-align: center;

    button {
        border: none;
        padding: 1rem;
        color: var(--color-white);
        font-family: 'Work Sans', sans-serif;
        cursor: pointer;
        margin-bottom: 1rem;
        width: 100%;

        &.add {
            background-color: var(--color-primary);
        }

        &.remove {
            background-color: var(--color-red);
        }

        span {
            font-size: 1rem;
        }
    }

    button[disabled] {
        &.add {
            /* Color */
            background-color: var(--color-gray) !important;

            /* Other */
            cursor: auto;
        }
    }

    @media only screen and (min-width: 800px) {
        position: absolute;
        right: 0;
        top: 1rem;

        button {
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
            width: auto;
        }
    }

    @media only print {
        display: none;
    }
`;

ProposalPage.ItemTexts = styled.div`
    width: 100%;
    padding: 1rem;

    h3 {
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 1rem;

        span {
            font-size: 0.8rem;
        }
    }

    @media (min-width: 800px) {
        h3 {
            width: 60%;
        }
    }
`;

ProposalPage.ItemDescription = styled.textarea`
    width: 100%;
    border: none;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    background-color: transparent;
    font-family: inherit;

    @media only print {
        overflow-wrap: initial;
    }
`;

ProposalPage.Prices = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: solid 0.1rem var(--color-border);
    padding: 1rem 0;

    .item__quantity {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item__quantity--print {
        display: none;
    }

    input {
        width: 5rem;
        text-align: center;
        margin: 0;
    }

    button {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f8f8f8;
        border: solid 0.1rem var(--color-border);
        cursor: pointer;

        span {
            font-size: 1.6rem;
            margin-bottom: 0;
        }
    }

    @media only screen and (min-width: 800px) {
        flex-direction: row;
    }

    @media only print {
        flex-direction: row;

        .item__quantity--print {
            display: block;
        }

        .item__quantity {
            button,
            input {
                display: none;
            }
        }
    }
`;

ProposalPage.ItemNumber = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;

    &.subtotal {
        h6 {
            font-weight: 700;
        }

        color: var(--color-primary);
        font-weight: 700;
    }

    h6 {
        font-size: 0.9rem;
        font-weight: 300;
        margin-bottom: 1rem;
    }

    @media only screen and (min-width: 800px) {
        margin: auto;
    }
`;

ProposalPage.ModalImage = styled.div`
    width: 100%;
    max-height: 25rem;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }

    button {
        border: none;
        padding: 0.5rem;
        background-color: var(--color-secondary);
        color: var(--color-white);
        font-family: 'Work Sans', sans-serif;
        cursor: pointer;
    }

    @media only screen and (min-width: 800px) {
        img {
            height: 25rem !important;
            width: auto;
        }
    }
`;

/*=======================================
            Proposal Cart
========================================*/

const cartAnimation = keyframes`
    0%{
        transform: translateX(0.3rem);
    }

    25%{
        transform: translateX(-0.3rem);
    }

    50%{
        transform: translateX(0.3rem);
    }

    75%{
        transform: translateX(-0.3rem);
    }
`;

ProposalPage.Cart = styled.div`
    h3 {
        font-weight: 300;
        font-size: 1.2rem;
        margin-bottom: 2rem;
        text-align: center;
        border-bottom: solid 0.1rem var(--color-border);
    }

    h4 {
        font-size: 1.2rem;
        font-weight: 300;
        margin: 1rem auto;
        text-align: center;
    }

    h4:nth-child(3) {
        font-weight: 300;
        color: var(--color-primary);
    }

    @media only screen and (min-width: 800px) {
        h3 {
            font-size: 2rem;
        }
    }
`;

ProposalPage.CartButton = styled.button`
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    border: none;
    padding: 1rem 0;
    background-color: var(--color-black);
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
    font-size: 1rem;
    color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;

    &.cart--animation {
        animation: ${cartAnimation} 0.3s linear;
    }

    svg {
        margin-right: 1rem;
        width: 2rem;
        fill: var(--color-secondary);
    }

    div {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: var(--color-secondary);
        color: var(--color-white);
        margin-left: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width: 800px) {
        width: auto;
        position: fixed;
        top: 50%;
        bottom: auto;
        left: auto;
        right: 0;
        border: none;
        padding: 1rem 1.5rem;
        flex-direction: column;
        font-size: 0.9rem;

        svg {
            margin: 0 0 0.5rem 0;
            width: 3rem;
            fill: var(--color-secondary);
        }

        div {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            background-color: var(--color-secondary);
            color: var(--color-white);
            position: absolute;
            bottom: 1.5rem;
            right: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media only print {
        display: none;
    }
`;

export const ProposalFooter = styled.div`
    width: 100%;
    padding: 1rem 0;
    padding-bottom: 4rem;
    margin-top: 5rem;

    .accordion {
        margin: 2rem auto;
        background-color: var(--color-white);
        box-shadow: var(--shadow);
        cursor: pointer;

        &.open {
            .accordion__text {
                height: auto;
                padding: 1rem;
                opacity: 1;
                margin: 0.3rem auto;
            }

            .accordion__title {
                span {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .accordion__title {
        font-weight: 200;
        font-size: 1rem;
        display: flex;
        align-items: center;
        background-color: var(--color-black);
        color: var(--color-title-in-black);

        span {
            display: block;
            padding: 1rem 0;
            text-align: center;
            background-color: var(--color-secondary);
            width: 3rem;
            margin-right: 1rem;

            img {
                width: 1rem;
                transition: all 0.3s ease-out;
            }
        }
    }

    .accordion__text {
        height: 0rem;
        overflow: hidden;
        font-weight: 200;
        line-height: 2;
        transition: all 0.3s ease-out;
        opacity: 0;

        a {
            text-decoration: underline;
        }
    }

    @media only print {
        display: none;
    }
`;

/*=======================================
        Expired Proposal Alert
========================================*/

ProposalPage.ExpiredAlert = styled.div`
    /* Display & Box Model */
    width: 100%;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 2rem;

    /* Color */
    background-image: linear-gradient(to top, #000 60%, transparent);

    /* Position */
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;

    h3 {
        /* Color */
        color: var(--color-white);

        /* Text */
        font-size: 2rem;
        font-weight: 300;
    }
`;
